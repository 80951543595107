/* References Document Link CSS */
.references-dropdown .category-references{
    position: relative;
}

.references-dropdown.breadcrumb-title .dropdown{
    margin-right: 30px;
}
.category-references{
    position: absolute;
    right: 10px;
    left: auto;
}
.category-references .dropdown-toggle{
    color: #637381;
    font-family: 'graphik';
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.25px;
}
.category-references .dropdown-toggle:after{
    border-bottom: 2px solid #637381;
    border-right: 2px solid #637381;
}
.category-references .dropdown.show .dropdown-toggle:after{
    transform: rotate(226deg);
    top: 5px
}
.category-references .dropdown-menu{
    right: -8px;
    left: auto !important;
    width: 105px;
    min-width: 105px;
    padding: 8px 0;
    margin-top: 5px;
}
.reference-actions .dropdown-menu{
    right: 0px;
    width: 105px;
    min-width: 105px;
    padding: 8px 0;
    margin-top: 5px;
}
.category-references .dropdown-item,
.reference-actions .dropdown-item{
color: #637381 !important;
font-family: graphik;
font-size: 14px;
font-weight: normal;
cursor: pointer;
padding: 0.4rem 0.7rem;
}
.category-references .dropdown-item i,
.reference-actions .dropdown-item i{
    margin-right: 5px;
    font-size: 14px;
}
.category-references .dropdown-item:hover,
.reference-actions .dropdown-item:hover{
    color: #637381;
    background-color: #f4f6f8;
}
.refernce-modal .modal-dialog{
    max-width: 848px;
}
.addnew-reference{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 450px;
}
.addnew-reference p{
color: rgb(99, 115, 129);
font-family: Graphik-Medium;
font-size: 14px;
font-weight: 500;
letter-spacing: -0.25px;
}
.addref-button button{
background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
border-radius: 16px;
border: 1.5px solid rgb(1, 142, 255);
height: 32px;
width: 120px;
color: white;
font-family: Graphik-Medium;
font-size: 12px;
font-weight: 500;
letter-spacing: -0.25px;
margin-top: 20px;
}
.addref-button .reference-button{
    outline: none !important;
    box-shadow: none !important;
}
.ref-detail-section .delete-ref i{
    color: #d5002f;
}
.delete-ref{
    cursor: pointer;
    color: #d5002f !important;
    font-size: 12px;
}
.ref-detail-fields{
    margin-bottom: 15px;
}
.ref-detail-fields .form-group{
    margin-bottom: 10px;
}
.ref-detail-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.ref-detail-heading h6{
color: rgb(33, 43, 54);
font-family: Graphik-Medium;
font-size: 16px;
font-weight: 500;
letter-spacing: -0.33px;
}
.ref-detail-fields{
border-radius: 8px;
border: 1px solid rgb(223, 227, 232);
padding: 15px;
}
.flex-btns.addrefence-btns{
    justify-content: flex-end;
}
.reference-form-details{
    max-height: 400px;
    overflow-y: auto;
    padding-right: 10px;
}
.reference-search{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

    .reference-search p {
        width: 60%;
        color: rgb(99, 115, 129);
        font-family: graphik;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: -0.21px;
        line-height: 16px;
        margin-bottom: 0;
        margin-right: 10px;
    }

    .reference-search .searchFilter {
        width: 25%;
    }

        .reference-search .searchFilter input[type="search"] {
            width: 95% !important;
            height: 32px;
        }

    .reference-search .addref-button {
        width: 15%;
    }

        .reference-search .addref-button button {
            margin-top: 0;
        }
        .references-viewlist-header {
            margin-top: 15px;
        }
        .references-viewlist-header .references-view-item{
            box-shadow: none;
            border-bottom: 1px solid #dfe3e8;
        }
.references-viewlist {
    margin-top: 0px;
    height: 420px;
    overflow-y: auto;
}
.references-view-item{
    display: flex;
    padding: 15px 10px;
    align-items: center;
    height: 64px;
    background: rgb(255, 255, 255);
    border-radius: 0px;
    box-shadow: 0px -1px 0px 0px rgb(223, 227, 232), 0px 1px 0px 0px rgb(223, 227, 232);
    border-bottom: 1px solid transparent;
}
.references-viewlist-header .references-view-item:hover{
background: transparent;
box-shadow: none;
border-bottom: 1px solid #dfe3e8;
}
.references-view-item:hover{
background: rgb(255, 255, 255);
border-radius: 0px;
box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.16);
border-bottom: 1px solid transparent;
}
 .references-view-item .reference-description{
width: 50%;
margin-right: 15px;
}
.reference-description .description-span a{
    color: rgb(99, 115, 129);
    word-break: break-word;
}
.reference-description .description-span a:hover{
    color: #018eff;
}
.references-view-item:hover .description-span{
    color: #018eff;
}

.references-view-item .reference-profile {
    width: 23%;
    margin-right: 15px;
}

.references-view-item .reference-date {
    width: 22%;
    margin-right: 15px;
}

.references-view-item .reference-actions {
    width: 2%;
    position: relative;
}

.references-view-item h6 {
    color: rgb(33, 43, 54);
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.25px;
    margin-bottom: 0;
}
.references-view-item p{
color: rgb(99, 115, 129);
font-family: graphik;
font-size: 14px;
font-weight: normal;
letter-spacing: -0.31px;
display: flex;
line-height: 16px;
 align-items: center;
 margin-bottom: 0;
}
.references-view-item p span i{
    margin-right: 10px;
}
.references-view-item p .description-span{
    width: 85%;
}
.references-view-item.active-profile p .description-span{
    color: #018eff;
}

.references-view-item .reference-actions p{
    display: none;
}
.references-view-item .user-detail-wrapper p{
    display: block !important;
}

.references-view-item.active-profile .reference-actions p{
    display: block;
    cursor: pointer;
}
.references-view-item:hover .description-span a{
    color: #018eff;
}
.flex-btns .btn {
    height: 48px;
    width: 120px;
}
.addfile-icon img {
    margin-bottom: 24px;
}

.ref-detail-heading .fa-plus.disabled{
color: rgba(33,43,54,.75);
cursor:default;
 }
 .ref-detail-heading .fa-plus{
     cursor: pointer;
 }
 .reference-name{
    width: 40%;
    margin-right: 15px;
    word-break: break-all;
    }
    .m-r-20{
        margin-right: 20px;
            }
            .infoicon-changes{
              font-size: 18px;
              color:#637381 !important;
            }
            .user-detail-wrapper{
              position: absolute;
              display: flex !important;
            }
            .user-details-show{
              position: absolute;
              top: 25px;
              min-height: 141px;
              width: 177px;
              left: -130px;
              z-index: 1000;
              min-width: 10rem;
              padding: 10px;
              margin: .125rem 0 0;
              font-size: 1rem;
              color: #212529;
              text-align: left;
              list-style: none;
              /* background-color: #fff; */
              background-clip: padding-box;
              border: 1px solid rgba(0,0,0,.15);
              border-radius: .25rem;
              background: rgb(255, 255, 255);
              box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
            }
            .m-t-20n{
              margin-top: -20px;
            }
            .user-details-show > p:last-of-type {
              padding-top: 20px;
            }
            .user-details-show > p {
             padding-top: 5px;
              font-family: Graphik-Medium;
              font-size: 12px;
              font-weight: 500;
              color: rgb(33, 43, 54);
            }
            .user-align{
              padding-top: 10px;
            }
            .user-align > p {
              position: relative;
            }
            .user-align > p > span:last-of-type {
              position: absolute;
              word-break: break-all;
              /* margin-top: 5px; */
            }
            .user-align > p > span > i {
              font-size: 24px;
            }
            .add-button-pos{
                position: absolute;
                right: 40px;
                font-size: 14px;
                cursor: pointer;
            }
/* References Dropdown new layout css starts here */
.category-references.dashboard-category-references .dropdown-menu {
    width: 440px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 8px;   
    z-index: 123;
    transform: translate3d(0px, 0px, 0px) !important;
    /* transform: none !important; */
    right: 0;
    top: 20px !important; 
}
.dashboard-category-references .dropdown-menu .add-references{
    display: flex;
    justify-content: flex-end;
    color: rgb(99, 115, 129);
    font-size: 12px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.25px;
}
.dashboard-category-references .dropdown-menu .add-references:hover,
.dashboard-category-references .dropdown-menu .add-references:active,
.dashboard-category-references .dropdown-menu .add-references:hover i{
    color: rgb(1, 142, 255);
}
.dashboard-category-references .dropdown-menu .add-references span i{ 
    font-size: 14px;
    margin-right: 8px;
    color: rgb(99, 115, 129);
}
.reference-links {
    border: 1px solid rgb(196, 205, 213);
    position: relative;
}
.reference-links::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url('../Images/link-normal.svg') no-repeat;
    width: 15px;
    height: 15px;
    display: inline-flex;
}
.dashboard-category-references .dropdown-menu .dropdown-item:hover .reference-links {
    border: 1px solid rgb(1, 142, 255);
}
.dashboard-category-references .dropdown-menu .dropdown-item:hover .reference-links::before {
    background: url('../Images/link-blue.svg') no-repeat;
}
.dashboard-category-references .dropdown-menu .dropdown-item .references-actions span{
    width: 22px;
    cursor: pointer;
    height: 32px;
    margin-left: 8px;
}
.dashboard-category-references .dropdown-menu .dropdown-item .references-actions span:last-child {
    padding-right: 0;
}
.category-references.dashboard-category-references .dropdown-menu .dropdown-item,
.category-references.dashboard-category-references .dropdown-menu .dropdown-item a{
    display: flex;
    align-items: center;
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 0;
    justify-content: space-between;
    color: rgb(99, 115, 129);
  }
  .dashboard-category-references .dropdown-menu .dropdown-item .selected-mor-date{
    color: rgb(145, 158, 171);
    font-size: 12px;
    font-family: 'graphik';
    font-weight: normal;
  }
  .dashboard-category-references .references-overflow {
    max-height: 360px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0rem 0.6rem;
  }
  .category-references.dashboard-category-references .dropdown-menu .dropdown-item:hover,
  .category-references.dashboard-category-references .dropdown-menu .dropdown-item:hover a{ 
    background: transparent ;
    color: rgb(1, 142, 255) !important;
  }
  .category-references.dashboard-category-references .dropdown-menu .dropdown-item .reference-icons{
    height: 32px;
    width: 32px;
    display: inline-block;
    border-radius: 4px;
    position: relative;
    margin-right: 1rem;
  }
  .category-references.dashboard-category-references .dropdown-menu .dropdown-item .reference-icons img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  .category-references.dashboard-category-references .dropdown-menu .dropdown-item>div {
      display: flex;
      align-items: center;
  }
.references-actions .edit-icon,
.references-actions .delete-icon {
    visibility: hidden;
}
.references-overflow .dropdown-item:hover .references-actions .edit-icon,
.references-overflow .dropdown-item:hover .references-actions .delete-icon {
    visibility: visible;
}
.reference-icons.pdf-color {background: linear-gradient(-225deg, rgb(170, 0, 37) 0%, rgb(213, 0, 47) 100%);}
.reference-icons.img-color {background:linear-gradient(-225deg, rgb(190, 130, 255) 0%, rgb(235, 6, 117) 100%);} 
.reference-icons.video-color {background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(2, 186, 255) 100%);}
.reference-icons.docx-color {background: linear-gradient(-45deg, rgb(0, 186, 255) 0%, rgb(0, 65, 240) 100%);}
.reference-icons.folder-color {background: linear-gradient(-225deg, rgb(193, 94, 11) 0%, rgb(244, 147, 66) 100%);}
.reference-icons.excel-color {background: linear-gradient(-225deg, rgb(82, 134, 38) 0%, rgb(126, 195, 68) 100%);}
.reference-icons.pblx-color {background: linear-gradient(-225deg, rgb(230, 176, 25) 0%, rgb(255, 200, 44) 100%);}
.reference-icons.docs-color {background: linear-gradient(-225deg, rgb(99, 115, 129) 0%, rgb(145, 158, 171) 100%);}
.reference-icons.ppt-color {background: linear-gradient(-225deg, rgb(171, 87, 90) 0%, rgb(249, 130, 133) 100%);}
.dropdown-menu .edit-icon, .dropdown-menu .delete-icon{
    position: relative;
  }
  .dropdown-menu .edit-icon::before{
    position: absolute;
    content: '';
    display: inline-flex;
    background:url('../Images/edit-normal.svg') no-repeat;
    width: 14px;
    height: 14px;
    left: 7px;
    top: 8px;
  }
  .dropdown-menu .edit-icon:hover::before{
    background:url('../Images/edit-gradient.svg') no-repeat;
  }
  .dropdown-menu .delete-icon::before{
    position: absolute;
    content: '';
    display: inline-flex;
    background:url('../Images/delete-normal.svg') no-repeat;
    width: 14px;
    height: 14px;
    left: 7px;
    top: 8px;
  }
  .dropdown-menu .delete-icon:hover::before{
    background:url('../Images/delete-gradient.svg') no-repeat;
  }
  .dropdown-menu .summary-icon::before{
    position: absolute;
    content: '';
    display: inline-flex;
    background:url('../Images/summary-normal.svg') no-repeat;
    width: 14px;
    height: 14px;
    left: 7px;
    top: 8px;
  }
  .dropdown-menu .summary-icon:hover::before{
    background:url('../Images/summary-gradient.svg') no-repeat;
  }
  .dropdown-menu .reference-info-tooltip {
    background: rgb(255, 255, 255) !important;
    border-radius: 16px;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.15);
    min-height: 186px;
    width: 256px;
    opacity: 1;
    margin-left: 0px;
    pointer-events: unset;
    cursor: default;
  }
  .reference-info-tooltip .reference-info {
    padding: 6px 0px;
  }
  .reference-info-tooltip .reference-info h2{
    color: rgb(33, 43, 54);
    font-size: 12px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 18px;
    margin: 0;
  }
  .reference-info-tooltip .reference-info p{
    color: rgb(99, 115, 129);
    font-size: 12px;
    font-family: 'graphik';
    font-weight: normal;
    letter-spacing: -0.25px;
    line-height: 18px;
    white-space: normal;
    margin-bottom: 10px;
  }
  .__react_component_tooltip.type-dark.place-left.reference-info-tooltip:after {
    border-left-color: #fdfdfd;
  }
  .reference-info-tooltip .reference-info .info-date p {
      margin-bottom: 6px !important;
  }
  /* References Dropdown new layout css ends here */
