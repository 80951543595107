.mention {
  color: #018eff;
  text-decoration: none;
}

.mentionSuggestions {
  max-height: 120px;
  overflow-y: auto;
  border: 1px solid #eee;
  background: #fff;
  border-radius: 2px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  position: absolute;
  z-index: 11;
  /* bottom: 16.3% !important;
  z-index: 11;
  left: 0% !important;
  top: initial !important; */
}

.mentionSuggestionsEntryContainer {
  display: table;
  width: 100%;
}

.mentionSuggestionsEntryContainerLeft, .mentionSuggestionsEntryContainerRight {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
}

.mentionSuggestionsEntry {
  padding: 7px 10px 3px 10px;
  transition: background-color 0.4s cubic-bezier(.27, 1.27, .48, .56);
}

.mentionSuggestionsEntry:active {
  background-color: #cce7ff;
  /* rgb(244, 246, 248) */
}

.mentionSuggestionsEntryFocused {
  composes: mentionSuggestionsEntry;
  background-color: rgb(244, 246, 248)
}

.mentionSuggestionsEntryTitle {
  color: rgb(21, 24, 27);
  font-family: Graphik-Regular;
  font-size: 10px;
  font-weight: normal;
  height: 18px;
  letter-spacing: 0px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentionSuggestionsEntryText {
  color: rgb(21, 24, 27);
  font-family: Graphik-Regular;
  font-size: 14px;
  font-weight: 200;
  height: 18px;
  letter-spacing: 0px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.mentionSuggestionsEntryContainerLeft [data-initials]:before {
  display: block;
  width: 30px;
  height: 30px;
  margin-top: 3px;
  border: 1px solid rgba(33, 43, 54, 0.15);
  line-height: 31px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 200;
}

.mentionSuggestionsEntryTitle {
  font-size: 70%;
  color: #a7a7a7;
}

.mentionSuggestionsEntryAvatar {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}