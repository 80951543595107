/* ===================================================== ALL Custom Styles Are included in this css file. This file is for - All static HTML layout, structure & elements - All Custom styles may overwrite any plugin CSS styles. Note: For UI integration to any platform DONOT EDIT ANY OF THE CODE IN THIS CSS. IF custom styles required while UI integration then add new css code in separate CSS files & link to pages. ===================================================== */
/* html, body {
    height: 100%;
} */
body {
    /* min-height: 100%; */
    background: #f9fafb;
}
* {
    padding: 0;
    margin: 0;
}
li {
    list-style: none;
}
a:hover {
    text-decoration: none;
}
.btn, .btn:focus, input:focus {
    outline: 0 !important;
}
.arrow {
    background: url(../Images/arrow-down.png) no-repeat 95% 50% #fff !important;
    background-size: 26px auto !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: #999 !important;
    text-indent: 0.01px;
    text-overflow: '';
}
/* ==================== Header ==================== */
header {
   /* background: linear-gradient(90deg, #01bbff 0%, #0b4cff 100%);
    */
    padding-top: 5px;
    width: 100% 
}
.headerMain {
    background: #fff;
}
header .col {
    float: left;
}
header h4 {
    color: #2d3a4b;
    font-family: 'Graphik-Bold';
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.25px;
   /* padding: 10px 0 0 0;
    */
}
.logo50px {
    height: 50px;
    display: inline-block;
    width: 50px;
}
header h4 img {
    margin: 0 10px 0 0;
}
header h4 span {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: 400;
    height: 13px;
    letter-spacing: -0.16px;
    line-height: 13px;
    display: block;
    text-transform: uppercase;
    opacity: .7;
}
.hd-menu {
    float: right;
}
.hd-menu li {
    float: left;
}
.hd-menu > li {
    padding: 15px 15px 0 0;
}
.hd-menu li img {
    height: 40px;
    width: 40px;
}
.hd-menu > li.user {
    padding: 15px 0 0 0;
}
.hd-menu > li {
    position: relative;
}
.notify span {
    position: absolute;
    background: red;
    padding: 1px 0 0 0;
    height: 19px;
    width: 22px;
    border-radius: 50%;
    color: #fff;
    font-size: 11px;
    text-align: center;
    top: 17px;
    left: 6px;
    font-family: 'Graphik-Medium';
    font-weight: 500;
}
.notify a img {
    height: 38px;
    width: 38px;
}
.pageContainer {
    margin: 0;
    position: relative;
    clear: both;
}
.contentCol {
    padding: 0;
    margin: 0 0 0 0;
    width: 100%;
}
.contentMain {
    padding: 0 0 15px 0;
}
.user .dropdown-menu {
    position: absolute;
    transform: translate3d(-5px, 47px, 0px);
    top: 13px !important;
    left: -35px !important;
    min-width: 130px;
}
/* ==================== Header Ends ==================== */
/* ==================== Slider Menu LS ==================== */
.bgw {
    background: #3D3643;
    position: absolute;
    height: 100%;
    z-index: 9;
}
.sliderNav {
    background: #3D3643;
    margin: 0 0 0 0;
    width: 100px;
}
.sliderNav li a {
    height: 123px;
    text-transform: uppercase;
    display: block;
    color: #FFFFFF;
    font-family: 'Graphik-Regular';
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 14px;
    text-align: center;
    padding: 28px 0 0 0;
}
.sliderNav li a img {
    width: 30px;
    margin: 0 0 10px 0;
}
.sliderNav li a b {
    display: block;
    font-weight: normal;
}
.sliderNav li a:hover, .sliderNav li a.active {
    background: #26212A;
}
/* ==================== Slider Menu LS Ends ==================== */
/* ==================== Filter ==================== */
.contentHd {
    background: #fff;
    padding-right: 15px;
}
.filterBlock {
    padding: 20px 30px 0 30px;
    background: #fff;
    box-shadow: inset 5px 5px 20px 2px rgba(153,153,153,0.25);
    height: 80px;
}
.filterBlock h5 {
    color: #FD7800;
    margin: 8px 0 0 0;
    font-family: 'Graphik-Semibold';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
}
.filterBlock h5 img {
    float: left;
    height: 14px;
    width: 13px;
    margin: 1px 6px 0 0;
}
.filterBlock .dropdown-toggle {
    background: #ffffff;
    border-radius: 25px 25px 25px 25px;
    border: 1px solid #dce2e7;
    height: 40px;
    color: #7b8e98;
    font-family: 'Graphik-Semibold-Web';
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 10px;
    text-align: left;
    text-transform: uppercase;
    min-width: 230px;
    padding-top: 10px;
}
.filterBlock .dropdown-toggle img {
    float: right;
    margin-top: -6px;
}
.filterBlock .dropdown-toggle::after {
    display: none;
    border-top: .8em solid #42B4EE;
    border-right: .5em solid transparent;
    border-bottom: 0;
    border-left: .5em solid transparent;
    margin-left: 5px;
    vertical-align: -1px;
}
.filterBlock .dropdown-menu {
    min-width: 230px;
}
.filterBlock .btn-primary:hover, 
.filterBlock .btn-primary:focus, 
.filterBlock .btn-primary:active, 
.filterBlock .btn-primary:focus-within, 
.filterBlock .btn-primary:visited, 
.show > .btn-primary.dropdown-toggle, 
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus {
    background-color: rgb(51, 164, 255);
    border-color: rgb(51, 164, 255);
    box-shadow: none;
    outline: none;
    color: #fff;
}
.filterBlock .dropdown {
    float: left;
    margin: 0 15px 10px 0;
}
.filterBlock .btn-link {
    color: #666666;
    font-family: 'Graphik-Medium';
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0;
    text-transform: uppercase;
    float: right;
    margin-right: -15px;
}
.btn-link img {
    height: 20px;
    width: 20px;
    margin: -5px 0 0 5px;
}
/* ==================== Filter Ends ==================== */
.incidents h2 {
    color: #2d3a4b;
    font-family: 'Graphik-Bold-Web';
    font-size: 22px;
    font-weight: 700;
    height: 22px;
    letter-spacing: -0.25px;
    line-height: 22px;
    margin: 0;
}
.incidentsItems {
    padding: 0 0 0 0;
    background: #fff;
    border-radius: 6px;
    float: left;
    width: 100%;
    margin: 10px 0 0 0;
}
.divider {
    border-right: solid 1px #ccc;
    float: left;
    height: 46px;
}
.incidents h3 {
    font-family: 'Graphik-Medium';
    font-weight: 500;
    float: left;
    margin: 0 0 0 -27px;
    color: #000000;
    cursor: default !important;
    font-size: 48px;
    letter-spacing: -1.5px;
    line-height: 42px;
    padding: 10px 10px 10px 60px;
    position: relative;
    height: 100px;
    min-width: 26.36%;
    background-repeat: no-repeat;
}
/* .incidents h3:last-child {
    margin: 0;
}
*/
.incidentsItems .active h3 {
    background-image: url(../Images/journey-itemarrow-default.png);
    background-repeat: no-repeat;
    background-position: -180px top;
}
.incidentsItems .itemsset h3 img {
    float: left;
    margin: -2px 5px 0 0;
}
.itemsset {
    box-shadow: 0 0 6px #ddd;
    float: left;
    width: 100%;
}
.itemsset.active {
    background-image: url(../Images/journey-itemarrow-activeborderouter.png);
    background-repeat: no-repeat;
    background-position: right top;
}
.previous {
    z-index: 9;
}
.incidents h3:hover {
    cursor: pointer;
}
.incidents h3:first-child {
    margin-left: 0;
    padding-left: 30px;
}
.incidents h3:before {
    content: "";
    position: absolute;
    right: -13px;
    top: 0px;
    bottom: 0;
    cursor: default !important;
    background-image: url("../Images/journey-itemarrow.png");
    background-repeat: no-repeat;
    height: 0;
    height: 100px;
    width: 54px;
    z-index: 99;
}
.incidents h3:last-child:before {
    background: none;
}
.incidents h3:hover:last-child:before {
    background: none;
    border-right: 3px solid #018fff;
    right: -2px;
}
.incidents h3:hover {
    background-image: url("../Images/journey-itemarrow-activeborder.png");
    background-repeat: no-repeat;
}
.incidents h3:hover:before {
    background-image: url("../Images/journey-itemarrowpointer-activeborder.png");
    background-repeat: no-repeat;
}
h3.text-danger {
    color: #A23030;
}
h3.text-success {
    color: #1C9E2D;
}
h3.text-warning {
    color: #E9B466;
}
.incidents h3 .item-span {
    display: block;
    text-align: left;
    color: #018fff;
    font-family: Graphik-Semibold;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 12px;
    margin-bottom: 20px;
}
.incidents h3 .item-span > b {
    font-family: Graphik-Medium;
    font-weight: 500;
    font-size: 14px;
    color: #7b8e98;
    border-left: solid 2px #666;
    padding: 0 0 0 5px;
    margin: 0 0 0 5px;
}
.incidents h3 .item-number {
    font-family: Graphik-Medium;
    font-size: 26px;
    color: #000;
}
.incidents h3 .item-number > b {
    font-family: Graphik-Medium;
    font-size: 16px;
    color: #7b8e98;
    margin: 0 6px;
    font-weight: 500;
}
.BusOutcomes {
    background: #ffffff;
    padding: 20px 16px;
    -webkit-box-shadow: inset 0px 10px 15px 0px rgba(156,156,158,.25);
    -moz-box-shadow: inset 0px 10px 15px 0px rgba(156,156,158,.25);
    box-shadow: inset 0px 10px 15px 0px rgba(156,156,158,.25);
}
.BusOutcomes h4 {
    color: #2d3a4b;
    font-family: "Graphik-Semibold";
    font-size: 22px;
}
.BusOutcomesItems {
    float: left;
   /* margin: 0 75px 0 0;
    */
}
.BusOutcomesItems span {
    color: #7b8e98;
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.25px;
}
.BusOutcomesItems h5 {
    color: #333333;
    margin: 5px 0 0 0;
    font-family: Graphik-Medium;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.35px;
    line-height: 28px;
}
.dataTab {
    margin: 0;
}
.dataTab .nav-tabs {
    margin: 0 0 0 0;
    background: #fff;
    padding: 10px 0 0 30px;
    border-bottom: none;
    -webkit-box-shadow: 0px 10px 16px -7px rgba(163,163,163,.25);
    -moz-box-shadow: 0px 10px 16px -7px rgba(163,163,163,.25);
    box-shadow: 0px 10px 16px -7px rgba(163,163,163,.25);
}
.dataTab .nav-tabs .nav-item .nav-link {
    border: none;
    padding: 10px 0;
    color: #7b8e98;
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    background: #fff;
}
.dataTab .nav-tabs .nav-item {
    margin-right: 40px;
}
.dataTab .nav-tabs .nav-item.show .nav-link, .dataTab .nav-tabs .nav-link.active, .dataTab .nav-tabs .nav-link:focus, .dataTab .nav-tabs .nav-link:hover {
    border: none;
    border-bottom: solid 5px #018fff;
    color: #000000;
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    background: #fff;
}
.box {
    background: #FFFFFF;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #E7E7E7;
   /* min-height: 462px;
    */
    box-shadow: 0 0 10px #ddd;
    margin: 25px 0;
}
.row-section {
    background: #fff;
    padding: 20px 15px 10px 15px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    width:100%;
    display: inline-block;
}
.grid-heading {
    font-size: 16px;
    font-family: graphik-medium;
    margin: 0 0 14px;
    letter-spacing: -0.25px;
    color: #212b36;
}
.box-md {
    background: #FFFFFF;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #E7E7E7;
    height: 270px;
    box-shadow: 0 0 10px #ddd;
    padding: 25px 20px;
    overflow: hidden;
}
.box-md-hd {
    color: #000000;
    font-family: "Graphik-medium";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.box-md-hd img {
    width: 24px;
    height: auto;
    margin: -2px 5px 0 0;
}
.outcome-list h5 {
    font-size: 20px;
    font-family: Graphik-Semibold;
    color: #000000;
}
.appliednowItem {
    float: left;
    margin: 10px 0;
    width: 50%;
    padding: 0 10px;
}
.appliednowItem i {
    height: 57px;
    width: 8px;
    display: block;
    float: left;
    border-radius: 10px;
    background: #00baff;
    margin: 0 15px 0 0;
}
.appliednowItem h5 {
    color: #000000;
    font-family: "Graphik-Medium";
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.29px;
    margin: 0 0 5px 0;
}
.appliednowItem h3 {
    font-size: 28px !important;
    font-weight: 500;
    font-family: "graphik-medium" !important;
}
.appliednowItem h3 img {
    margin: -5px 0 0 10px;
}
.appliednowItem p {
    color: #7b8e98;
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    height: 12px;
    letter-spacing: -0.25px;
    line-height: 12px;
    width: 191px;
    margin: 5px 0 0 0;
}
.appliednowItem p b {
    color: #7ec344;
    font-weight: 500;
    margin: 0 5px 0 0;
}
.mapBg {
    background-image: url(../Images/worldMap.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.pt-15 {
    padding-top: 15px;
}
.p-15 {
    padding: 15px;
}
.pl-30 {
    padding-left: 30px;
}
.pr-30 {
    padding-right: 30px;
}
.ico-36px {
    width: 36px;
}
.map-bg {
    padding: 30px;
}
.m-0 {
    margin: 0;
}
.box-hd {
    width: 100%;
    margin-bottom: 30px;
}
.gocForm .main-data .row:last-child .form-group {
    margin-bottom: 8px;
}
.box-hd .btn-primary {
    background: #f2f9ff;
    border-radius: 25px 25px 25px 25px;
    border: 1.5px solid #018fff;
    height: 40px;
    min-width: 300px;
    color: #354152;
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    letter-spacing: -0.25px;
    line-height: 12px;
}
.box-hd .btn-primary .selecteditem {
    color: #018fff;
    margin: 0 0 0 5px;
}
.box-hd .dropdown-menu {
    min-width: 300px;
    padding: 10px;
    top: 10px;
    box-shadow: 0px 5px 20px 0px #999;
}
.box-hd .dropdown-menu a {
    color: #354152;
    font-family: Graphik-Regular-Web;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.25px;
    line-height: 22px;
    border-radius: 3px;
}
.box-hd .dropdown-item.focus, .box-hd .dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #018fff;
}
.box-hd h4 {
    color: #737683;
    font-family: 'Graphik-Regular-Web';
    font-size: 18px;
    font-weight: 400;
    float: left;
}
.box-hd h4 small {
    color: #ADADAD;
    font-family: 'Graphik-Regular-Web';
    font-size: 12px;
    font-weight: 400;
    border-left: solid 1px #ccc;
    padding-left: 10px;
    margin-left: 10px;
}
.box-hd h4 .num {
    background: #083358;
    border-radius: 3px 3px 3px 3px;
    height: 25px;
    width: 25px;
    padding: 5px;
    line-height: 9px;
    font-size: 12px;
    color: #fff;
    font-family: 'Graphik-Bold-Web';
    font-weight: 700;
}
.box-data, .box-foot {
    clear: both;
}
.box-data img {
    width: 100%;
    height: 100%;
}
.box-data.box-data-table {
    height: 250px;
    margin-left: -15px;
    margin-right: -15px;
}
.row.datatable-block {
    margin-left: 0;
    margin-right: 0;
}
.box-data-table-main {
    min-height: 350px;
}
.box-data-table-main .box-foot {
    padding: 0;
}
.box-data .table thead th {
    vertical-align: top;
}
.box-data .table thead th, .box-data .table td {
    font-size: 12px;
    padding: 10px;
    color: #737683;
}
.datatable-block {
    overflow: auto;
    border-bottom: solid 1px #ddd;
    max-height: 235px;
}
.box-foot {
    color: #ADADAD;
    font-family: 'Graphik-Regular-Web';
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
}
.box-foot b {
    font-family: 'Graphik-Medium';
    color: #363B4E;
    font-weight: 500;
}
.graphyearNav {
    float: right;
}
.graphyearNav ul > li {
    float: left;
    height: 25px;
    text-align: center;
    margin: 0 5px 0 0;
}
.graphyearNav ul > li > a {
    color: #FFFFFF;
    padding: 4px 5px 0 5px;
    font-family: 'Graphik-Medium';
    font-size: 12px;
    font-weight: 500;
    line-height: auto;
    display: block;
    background: #C4C4C4;
    height: 25px;
    border-radius: 3px;
    min-width: 25px;
    text-align: center;
}
.graphyearNav ul > li > a.nextprev {
    font-size: 17px;
    padding-top: 0;
}
.filterBlock .dropdown-menu a {
    font-size: 12px;
    font-family: 'Graphik-Regular-Web';
    font-weight: 400;
    border-radius: 4px;
}
.filterBlock .dropdown-menu {
    padding: 7px;
}
.filterBlock .dropdown-menu a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #018fff;
}
a.nextprev img {
    height: 15px;
    width: 9px;
}
.graphyearNav ul li a.addmenu {
    background: #083358;
    padding-top: 4px;
    line-height: 9px;
    font-size: 18px;
}
.progresslink, .region {
    float: left;
    margin: 20px 15px 0 0;
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 12px;
    color: #7b8e98;
}
.dot-violet {
    background: #c09bd1;
    height: 15px;
    width: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin: -2px 5px 0 5px;
    float: left;
}
.dot-green {
    background: #7ec344;
    height: 15px;
    width: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin: -2px 5px 0 5px;
    float: left;
}
.dot-rose {
    background: #f98285;
    height: 15px;
    width: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin: -2px 5px 0 5px;
    float: left;
}
.dot-blue {
    background: #018fff;
    height: 15px;
    width: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin: -2px 5px 0 5px;
    float: left;
}
.search input {
    background: transparent;
    border-radius: 3px;
    border: 1px solid #DCE2E7;
    background-color: #ffffff;
    font-size: 10px;
    color: #666666;
    margin: 0 10px 0 0;
    width: 255px;
    height: 35px;
    padding-left: 30px;
    font-family: 'Graphik-Medium';
    font-weight: 500;
}
.search button {
    width: 30px;
    border: none;
    height: 30px;
    position: absolute;
    left: 0;
    background: none;
    box-shadow: none;
    top: 0;
    background-color: transparent;
    outline: 0;
}
.ico20px {
    width: 24px;
    height: 25px;
}
.search {
    position: relative;
    display: block;
}
.rowsperpage b {
    color: #737683;
}
.rowsperpage select {
    border: none;
    color: #737683;
    width: 45px;
    margin: 0 5px 0 0;
}
.paginationArrow a {
    width: 18px;
    display: inline-block;
    text-align: center;
    margin: 0 5px;
}
.paginationArrow a img {
    width: 10px;
    height: 13px;
}
/* ==================== FOOTER ==================== */
footer {
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 0px -1px 0px 0px #e3eaed;
    height: 65px;
    padding: 15px 30px;
    margin: 0 !important;
    position: fixed;
    width: 100%;
    bottom: 0;
}
.copyright {
    color: #919eab;
    font-size: 12px !important;
    font-family: graphik;
    text-align: right;
    padding: 15px 0;
    margin: 0;
    letter-spacing: -0.19px;
}
.footlinks {
    padding: 5px 0 0 0;
}
.footlinks a {
    color: #7b8e98;
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 19px;
    margin: 0 15px;
}
.footlinks a:hover {
    color: #282828;
}
/* custom toggle button */
.switch {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 15px;
    margin: 1px 0 0 10px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    width: 25px;
}
.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: #4CD864;
}
input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}
.talent {
    margin: 1.5em 0px 15px;
    padding: 15px;
}
/* Model */
.modal-backdrop.show {
    background-color: rgba(255, 255, 255, 0.7);
    /* filter: blur(5px); */
}
.intellOJModel {
    background: #ffffff;
    border-radius: 15px 15px 15px 15px;
    box-shadow: 0px 2px 35px 0px #999;
}
.intellOJModel .modal-content {
    background-clip: unset;
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}
.intellOJModel .modal-header {
    border-bottom: none;
}
.intellOJModel .modal-footer {
    border-top: none;
}
.rwt__tablist:not([aria-orientation="vertical"]) {
    display: flex;
    border-bottom: 0 none;
    flex-direction: row;
}
.rwt__tab {
    padding: 0;
    margin-right: 40px;
    color: #919eab;
    font-size: 14px;
    letter-spacing: -0.25px;
    font-family: graphik-medium;
    position: relative;
    padding-bottom: 10px;
    flex-direction: column;
    display: flex;
    align-items: center;
}
/* .rwt__tab[aria-selected="true"]:after {
    display: none;
} */
.rwt__tab:after {
    content: "";
    background: #018eff;
    width: 0;
    height: 5px;
    border-radius: 3px;
    position: absolute;
    bottom: 0;
}
.rwt__tab[aria-selected="true"]:after, .rwt__tab:hover:after {
    -webkit-transition: width .5s;
    transition: width .5s;
    width: 100%;
    border-bottom: 0 none !important;
    bottom: 0 !important;
}
.rwt__tab {
    color: #919eab;
}
.rwt__tab[aria-selected="true"], .rwt__tab:hover {
    color: #018eff;
}
.rwt__tab:hover, .rwt__tab:focus {
    background-color: transparent !important;
}
.rwt__tablist {
    margin-bottom: 16px;
}
.contentMain {
    padding: 0;
}
.contentHd {
    background: #fff;
    padding-right: 0;
}
.incidents.row, .BusOutcomes.row {
    margin-right: 0;
    margin-left: 0;
}
/* .incidents.row {
    min-height: 195px;
}
*/
.row.m-0.pt-15 {
    margin-left: 0;
    margin-right: 0;
}
.incidents h2 {
    margin-top: 10px;
    margin-bottom: 10px;
}
.mapBg .outcome-highlight {
    background-color: transparent;
    border-radius: 10px;
    box-shadow: none;
    padding: 20px 25px 10px;
    margin: 10px 0 20px;
}
.box-md-hd h5 {
    padding: 0 0 0 25px;
}
.talent .outcome-divider {
    display: block;
    width: 100%;
   /* vertical-align: top;
    */
   /* padding: 0 10px 0 18px;
    */
   /* position: relative;
    */
    margin: 10px;
    background: none;
    border-radius: none;
    border: none;
    box-shadow: none;
    padding: 0px 15px;
    float: left;
    position: relative;
}
/* .talent .outcome-divider p {
    margin: 0 0 0 5px;
    font-family: Graphik-Semibold;
    letter-spacing: -0.25px;
    font-size: 18px;
    color: #000;
    word-break: break-word;
    width: auto;
}
*/
/* .talent .outcome-divider:before {
    position: absolute;
    content: "";
    width: 8px;
    height: 58px;
    border-radius: 15px;
    left: 0px;
    top: 0px;
}
*/
.outcome-divider h3 {
    font-size: 28px !important;
    font-weight: 500;
    font-family: "graphik-medium" !important;
}
.talent .outcome-row {
    display: block;
    height: 52px;
}
.skill .outcome-divider {
    width: 30%;
}
.center-text {
    font-family: "Graphik-Semibold";
    margin-top: 25px;
}
.text-blue {
    color: #00baff;
}
/* .navbar-nav{
    display: inline-block;
}
.nav > li{
    display: inline-block;
}
*/
.UploadImageInput{
    position:relative;
    z-index: 2;
    width: 50px;
    height:50px;
    opacity: 0;
}

.virtualuploadimage{
    position:absolute;
    z-index: 0;
    left: 1rem;
    top:0%;
    width:50px;
    height:50px;
    
}
.rdw-editor-main {
        height: 100%;
        overflow: auto;
        box-sizing: border-box;
        z-index: 0;
    }
@media(min-width: 767px) {
    .headerMain {
        height: 70px;
   }
}
@media screen and (width: 1366px) and (height: 768px) {
    .incidents h3 {
        min-width: 26.6% !important;
   }
}
