/* DD Enhancement CSS Start*/
.ddcontainer-tabs .nav-tabs .nav-link {
  border: none;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  position: relative;
  padding: .5rem 0;
  margin-right: 32px;
}

  .ddcontainer-tabs .nav-tabs .nav-link.active {
      color: #212b36 !important;
      font-weight: 500;
      border-bottom: none;
  }

      .ddcontainer-tabs .nav-tabs .nav-link.active:after {
          content: " ";
          position: absolute;
          bottom: -8px;
          left: 0;
          width: 100%;
          border-radius: 4px;
          height: 8px;
          background-color: #018eff;
      }

.ddcontainer-tabs .tab-content {
  background: transparent;
}

.ddcontainer-tabs .nav-tabs {
  margin-bottom: 2rem;
}

.ddinsights-table .owl-carousel.owl-drag .owl-item {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
  height: 100px;
  width: 220px !important;
}

  .ddinsights-table .owl-carousel.owl-drag .owl-item .item {
      height: 100%;
  }

  .ddinsights-table .owl-carousel.owl-drag .owl-item .ddinsights-block {
      padding: 0.6rem 1.5rem;
  }

.ddinsights-table {
  margin-bottom: 2rem;
}

.ddinsights-block h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  letter-spacing: -0.27px;
  line-height: 16px;
}

.ddinsights-block p {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 16px;
}

.ddinsights-block.source-fieldblock h6 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.31px;
  height: auto;
  text-transform: uppercase;
}

.source-fieldblock .fieldsource-digit {
  display: flex;
  align-items: flex-start;
}

.source-fieldblock .fieldsource-digit-numeric {
  color: rgb(117, 0, 192);;
  font-family: Graphik-Semibold;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 24px;
}

.source-fieldblock .fieldsource-digittext {
  color: rgb(117, 0, 192);;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-left: 6px;
  line-height: 28px;
}

.doughnut-insight .ddinsights-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-columnlayout .ddinsights-item.doughnut-insight {
  width: 50%;
}

.doughnut-insight .doughnut-name {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 16px;
  width: 50%;
}

.doughnut-insight .rd-progress > span {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 21px;
  font-weight: 600;
  letter-spacing: -0.36px;
}

.ddinsights-table .owl-carousel.owl-drag .threefield-insight .ddinsights-block,
.threefield-insight .ddinsights-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.8rem;
}

.threefield-insight .threeinsight-description {
  border-right: 1px solid #dfe3e8;
  margin-right: 7px;
  padding-right: 3px;
  min-width: 60%;
}

  .threefield-insight .threeinsight-description h6 {
      font-family: Graphik-Medium;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.27px;
      line-height: 14px;
      height: 40px;
  }

  .threefield-insight .threeinsight-description p {
      color: rgb(99, 115, 129);
      font-family: Graphik-Medium;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.21px;
      line-height: 14px;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 90px;
  }

.threefield-insight .threeinsight-percent {
  color: rgb(117, 0, 192);
  font-family: Graphik-Semibold;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.36px;
}

  .threefield-insight .threeinsight-percent .fieldsource-digittext {
      font-size: 14px;
  }

.twofield-insight .twoinsight-description {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  height: 28px;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-bottom: 8px;
}

.twofield-insight .twoinsight-percent {
  color: rgb(117, 0, 192);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.42px;
}

.dataTypeProcessed .doughnut-insight .ddinsights-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.ht-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

  .ht-title .addData {
      color: rgb(1, 142, 255);
      font-size: 12px;
      font-family: Graphik-Medium;
      font-weight: 500;
      letter-spacing: -0.25px;
      line-height: 14px;
      cursor: pointer;
  }

      .ht-title .addData i {
          color: rgb(1, 142, 255);
      }

.ddinsights-table .owl-carousel .owl-stage-outer {
  padding: 0;
  margin: 0;
  /* overflow: initial; */
}

.dd-benchmarkslist .benchmark-insights {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  box-shadow: 0 2px 20px 0 rgba(0,0,0,.1);
  height: 149px;
  width: 300px !important;
  margin-bottom: 2rem;
  margin-right: 25px;
  padding: 1rem;
  position: relative;
}

  .dd-benchmarkslist .benchmark-insights:before {
      position: absolute;
      content: "";
      background: #919eab;
      width: 4px;
      height: 100%;
      border-radius: 4px 0px 0px 4px;
      left: 0;
      top: 0;
  }

  .dd-benchmarkslist .benchmark-insights.grey-border:before {
      background: #919eab;
  }

  .dd-benchmarkslist .benchmark-insights.green-border:before {
      background: #7ec344;
  }

  .dd-benchmarkslist .benchmark-insights.red-border:before {
      background: #d5002f;
  }

  .dd-benchmarkslist .benchmark-insights.orange-border:before {
      background: #FFC82C;
  }

.benchmark-insights .benchmark-tilecard h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  letter-spacing: -0.29px;
  line-height: 16px;
  overflow: hidden;
}
.dd-benchmarkslist .benchmark-insights.grey-border:before{
background: #919eab;
}
.dd-benchmarkslist .benchmark-insights.green-border:before{
background: #7ec344;
}
.dd-benchmarkslist .benchmark-insights.red-border:before{
background: #d5002f;
}
.dd-benchmarkslist .benchmark-insights.orange-border:before{
background: #FFC82C;
}
.benchmark-insights .benchmark-tilecard h6{
color: rgb(33, 43, 54);
font-family: Graphik-Medium;
font-size: 14px;
font-weight: 500;
height: 32px;
letter-spacing: -0.29px;
line-height: 16px;
overflow: hidden;
}
.benchmark-actualvalue .benchmark-digitvalue{
color: rgb(33, 43, 54);
font-family: Graphik-Semibold;
font-size: 24px;
font-weight: 600;
letter-spacing: -0.35px;
}
.benchmark-actualvalue .benchmark-digittext{
font-size: 18px;
}
.dd-benchmarkattribute{
display: flex;
position: absolute;
  bottom: 16px;
  width: calc(100% - 32px);
}

  .dd-benchmarkattribute .quartile-text {
      width: 50%;
      margin-right: 5px;
  }

.dd-benchmarkattributetext p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-bottom: 0;
}

.dd-benchmarkattributetext span {
  color: rgb(99, 115, 129);
  font-family: Graphik-Semibold;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: -0.25px;
}

.dd-benchmarkslist {
  display: flex;
  flex-wrap: wrap;
}

.benchmark-insights .card-overlay {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.benchmark-insights:hover .card-overlay {
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius: 0px;
}

.owl-item .diverse-insight .card-overlay {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.diverse-insight:hover .card-overlay {
  opacity: 0.97;
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius: 0px;
}

.empty-ddinsight {
  display: flex;
  height: 96px;
  max-width: 252px;
  align-items: center;
  justify-content: center;
}


.ddrwidget-col.ddrinsights-widget .widget-activated {
  text-align: left;
}

.ddrwidget-col.ddrinsights-widget h6 {
  font-size: 14px;
  font-weight: 600;
}

.ddrinsights-widget .outcome-divider .ddinsights-block p {
  color: #637381;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -.25px;
  line-height: 16px;
}

.threeinsight-descriptiontooltip {
  position: relative;
  display: block;
}

  .threeinsight-descriptiontooltip span {
      display: none;
  }

  .threeinsight-descriptiontooltip:hover span {
      position: absolute;
      background: rgb(33, 43, 54);
      color: rgb(255, 255, 255);
      font-family: Graphik-Medium;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.21px;
      line-height: 11px;
      width: 140px;
      padding: 6px;
      min-height: 28px;
      right: -110px;
      align-items: center;
      display: flex;
      border-radius: 8px;
      top: -12px;
      z-index: 999 !important;
      height: 45px;
  }

      .threeinsight-descriptiontooltip:hover span::after {
          content: "";
          position: absolute;
          top: 34%;
          left: -8px;
          margin-left: -5px;
          border: 7px solid transparent;
          border-top-color: #000;
          transform: rotate(90deg);
      }

      .trendGraph-title{
        justify-content: center !important;
      }
      .dd-chartlegend{
        display: flex;
        justify-content: center;
      }
      .dd-chartlegend ul{
        display: flex;
      }
      .dd-chartlegend ul li{
        margin-right: 20px;
        color: rgb(99, 115, 129);
      font-family: graphik;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: -0.33px;
      display: flex;
      align-items: center;
      }
      .dd-chartlegend li span{
        margin-right: 6px;
      }
      .dd-chartlegend .actual-legendvalue{
        height: 4px;
          width: 30px;
          background: rgb(161, 0, 255);
      }
      .dd-chartlegend .leading-legendvalue{
      color: #a100ff;
      font-size: 23px;
          position: relative;
          top: -6px;
      }
      .dd-chartlegend .industry-legendvalue{
        color: #00baff;
        font-size: 23px;
          position: relative;
          top: -6px;
      }
      .empty-ddinsight{
        display: flex;
        height: 96px;
        max-width: 252px;
        align-items: center;
        justify-content: center;
        max-width: 252px;
      }
      /* New Widget Popup CSS Added */
      .widget-popnew .doughnutwidget-insight{
          display: flex;
         align-items: center;
      }
      .widget-popnew .doughnutwidget-layout .item .item-left{
          padding-right: 1rem;
          padding: 0;
      }
      .widget-popnew .ddrwidget-col.ddrinsights-widget .twofield-insight h6{
           color: rgb(33, 43, 54);
           font-family: Graphik-Semibold;
           font-size: 20px;
           font-weight: 600;
           letter-spacing: -0.42px;
           line-height: 16px;
      }
      .widget-popnew .ddrwidget-col.ddrinsights-widget .twofield-insight p,
      .widget-popnew .ddrwidget-col.ddrinsights-widget .source-fieldblock p{
          color: rgb(33, 43, 54);
          font-family: Graphik-Medium;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 16px;
          margin-bottom: 0;
      }
      .widget-popnew .ddrwidget-col.ddrinsights-widget .source-fieldblock h6{
          color: rgb(33, 43, 54);
         font-family: Graphik-Semibold;
         font-size: 16px;
         font-weight: 600;
         letter-spacing: -0.33px;
         line-height: 16px;
      }
     .widget-popnew .threefield-insight .threeinsight-description{
         border-right: none;
         width: 50%;
     }
     .widget-popnew .threefield-insight .threeinsight-description h6{
      color: #212b36;
      font-family: Graphik-Semibold;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -.42px;
      line-height: 24px;
      height: auto;
     }
     .widget-popnew .threefield-insight .threeinsight-description h6 span{
      font-family: Graphik-Medium;
   font-size: 14px;
   font-weight: 500;
   letter-spacing: -.29px;
   line-height: 20px;
     }
     .widget-popnew .threefield-insight .threeinsight-description p{
      color: #212b36;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
     }
     .widget-popnew .threefield-insight .item-right span{
      color: #637381;
      font-family: graphik;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 16px;
     }

/* DD Enhancement phase2 CSS Start here*/
.fetch-data-container {
  display: flex;
  align-items: center;
  margin: 0.75rem 0 1.875rem;
}
.fetch-data-label {
  /* width: 108px;
height: 17px; */
  color: rgb(109, 114, 120);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.29px;
}
.fetch-category-list {
  /* margin-top: 3px; */
  margin-left: 25px;
}
.fetch-category-list label.form-check {
  padding-left: 1.5rem;
  display: inline;
}
.fetch-category-list .form-check .checkmark {
  height: 24px;
  width: 24px;
}
.fetch-category-list .form-check input[type="radio"] + .checkmark:after {
  margin-left: -8px;
  margin-top: -8px;
  height: 16px;
  width: 16px;
}
.fetch-category-list .form-check-label {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  vertical-align: middle;
  position: relative;
  margin-left: 8px;
  line-height: 16px;
  bottom: 0px;
}
.fetch-category-list .form-check-label.active {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.form-check mr-3 .disabled{
  opacity: .5;
  pointer-events:none;
}
.dd-info-message {
  color: rgb(99, 115, 129);
  font-family: 'graphik';
  font-size: 11px;
  font-weight: normal;
}
.dd-info-message i{
  margin-right:4px;
  color: rgb(99, 115, 129);;
}
.dd-benchmarkslist .pulse-dashboard .select-filter-msg  {
    padding-top: 0%;
    min-height: unset;
}
.peer-graph-tooltip {
  position: relative;
  display: inline-block;
  font-size: 10px;
  cursor: pointer;
}

.peer-graph-tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.peer-graph-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.peer-graph-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}