.clientstory {
  padding: 0 1rem 1rem;
  margin-top: 50px;
}
.pagename-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagename-heading h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-bottom: 0;
}
.clientstory .btn-gradientbg {
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(117, 0, 192) 52%,
    rgb(70, 0, 115) 100%
  );
  border-radius: 20px;
  height: 32px;
  color: #fff !important;
  min-width: 96px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  outline: none !important;
  box-shadow: none;
  border: none;
}
.clientstory .btn-gradientbg:disabled,
.clientstory .btn-gradientbg:disabled:hover {
  opacity: 0.25;
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(117, 0, 192) 52%,
    rgb(70, 0, 115) 100%
  );
}
.clientstory .btn-gradientbg:hover {
  background: linear-gradient(-225deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%);
}
.common-tablayout .nav-tabs .nav-link {
  border: none;
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  position: relative;
  display: inline-block;
  padding: 0.8rem 0;
  margin-right: 32px;
  cursor: pointer;
}
.common-tablayout .nav-tabs .nav-link.active,
.common-tablayout .nav-tabs .nav-link:hover {
  color: #018eff !important;
  font-weight: 500;
  border-bottom: none;
}
.common-tablayout .nav-tabs .nav-link:after {
  content: "";
  display: block;
  margin: auto;
  height: 4px;
  border-radius: 4px;
  position: relative;
  bottom: -10px;
  width: 0px;
  background-color: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}
.common-tablayout .nav-tabs .nav-link.active:after,
.common-tablayout .nav-tabs .nav-link:hover:after {
  background-color: #018eff;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  height: 4px;
}

.common-tablayout .tab-content {
  background: transparent;
  padding: 6px 0 1rem 0;
}
.journeyinitiative-tabs .nav-tabs {
  margin-bottom: 1.2rem;
  border-bottom: 1px solid rgb(223, 227, 232);
  padding: 0 1rem;
}
.tablayout-content {
  min-height: 400px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
}
.tablayout-content h6 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 1px 0px 0px rgb(223, 227, 232);
  border-radius: 8px 8px 0px 0px;
  padding: 1rem;
  margin-bottom: 0px;
}
.draggdrop-container {
  margin: 10px 10px 0px;
  max-height: 400px;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 65px;
}
.dragg-wrapper {
  margin: 0;
  padding: 0 90px 0px 42px;
  position: relative;
}
.dragg-wrapper .dragg-list .dragg-list-right .form-group.border-group textarea {
  min-height: 91px;
}
.dragg-wrapper .dragg-list .dragg-list-right textarea {
  resize: none;
}
.dragg-wrapper .dragg-list {
  margin-bottom: 9px;
}
.clientstory-section .dragg-wrapper .dragg-list {
  align-items: center;
}
.dragg-wrapper .dragg-icon {
  position: absolute;
  top: 0px;
  bottom: 15px;
  margin: auto;
  display: inline-block;
  height: 23px;
  left: 16px;
  cursor: row-resize;
}
.dragg-wrapper .dragg-icon i {
  color: rgb(99, 115, 129);
}
.dragg-wrapper .add-delete-actions {
  display: block;
  position: absolute;
  right: 14px;
  width: auto;
  height: 24px;
  top: 0px;
  bottom: 18px;
  margin: auto;
}
.dragg-wrapper .add-delete-actions.single-action {
  right: 46px;
}
.dragg-wrapper .add-delete-actions .delete-icon {
  width: 22px;
  height: 24px;
  background: url("../Images/delete-icon.svg") no-repeat;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}
.dragg-wrapper .add-delete-actions .add-icon {
  width: 24px;
  height: 24px;
  background: url("../Images/add-icon.svg") no-repeat;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}
.add-delete-actions span.disabled {
  opacity: 0.2;
}
.map-bgimage {
  background: url("../Images/map-bgimage.png") no-repeat;
  background-position: center;
  min-height: 528px;
  display: flex;
  align-items: center;
}
.mapconfiguration-message {
  background: rgb(255, 255, 255);
  border-radius: 2px;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.11);
  min-height: 231px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 637px;
  margin: auto;
}
.mapconfiguration-message h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.35px;
}
.tablayout-content .mapconfiguration-message h5 i {
  color: #018eff;
  margin-right: 24px;
}
.common-formgrouplabel .initiative-dropdown .dropdown .dropdown-toggle {
  font-family: graphik;
  font-size: 16px;
  line-height: 20px;
  background: transparent;
}
.common-formgrouplabel .dropdown .dropdown-toggle.btntoggle-itemactive {
  color: rgb(8, 12, 15) !important;
}
.dragdrop-footer {
  background: rgb(249, 250, 251);
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid rgb(223, 227, 232);
  padding: 1rem 2rem;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.empty-space {
  min-height: 20px;
}

#disabled {
  cursor: not-allowed;
}

.add-story-btn {
  background: linear-gradient(-225deg, rgba(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
  border-radius: 20px;
  height: 32px;
  color: #fff;
  min-width: 180px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  outline: none !important;
  box-shadow: none;
  border: none;
  margin-top: 13%;
  margin-left: 40%;
}

.default-msg {
  font-family: Graphik-Medium;
  font-weight: 500;
  min-height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.35px;
  color: rgb(33, 43, 54);
}

.dragg-list-right .form-group.border-group {
  position: relative;
}
.dragg-list-right .form-group.border-group .suffix-text {
  right: 8px;
  font-family: "graphik";
}

.draggdrop-container .react-datepicker-wrapper,
.draggdrop-container .datepicker-label-wrapper > label {
  width: 100%;
}
.boi-tablayoutspace {
  padding: 10px;
}
.offeringlist-dropdownslist {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.offeringlist-dropdownslist .dropdown-toggle {
  min-width: 200px;
}
.offeringlist-dropdownslist.clientstory-boi-map .dropdown-toggle {
  min-width: unset;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 28px;
}
.purpleborder-dropdown .dropdown-toggle {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255) !important;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0.75rem 2rem 0.75rem 1rem;
  text-align: left;
  outline: none !important;
  box-shadow: none !important;
  position: relative;
}
.purpleborder-dropdown .dropdown-toggle:disabled {
  background: rgb(255, 255, 255);
  color: rgb(161, 0, 255);
  pointer-events: none;
}
.purpleborder-dropdown .dropdown-toggle:hover,
.purpleborder-dropdown .dropdown-toggle:active,
.purpleborder-dropdown .show .dropdown-toggle {
  background: rgb(255, 255, 255) !important;
  border: 1.5px solid rgb(161, 0, 255) !important;
  color: rgb(161, 0, 255) !important;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2) !important;
}
.purpleborder-dropdown .dropdown-toggle:focus {
  box-shadow: none !important;
  background: rgb(255, 255, 255) !important;
  border: 1.5px solid rgb(161, 0, 255) !important;
  color: rgb(161, 0, 255) !important;
}
.purpleborder-dropdown .dropdown-toggle:after {
  border-bottom: 2px solid rgb(161, 0, 255);
  border-right: 2px solid rgb(161, 0, 255);
  position: absolute;
  right: 1rem;
  top: 14px;
}
.purpleborder-dropdown.clientstory-boi-map .dropdown-toggle:after {
  right: 0.7rem;
}

.purpleborder-dropdown .dropdown-toggle:hover:after,
.purpleborder-dropdown .dropdown-toggle:focus:after,
.purpleborder-dropdown .show .dropdown-toggle:after {
  border-bottom: 2px solid rgb(161, 0, 255);
  border-right: 2px solid rgb(161, 0, 255);
}
.clientstory-boi-map .form-check-label {
  bottom: unset;
  white-space: normal;
}
.purpleborder-dropdown.clientstory-boi-map .dropdown-menu {
  min-width: 156px;
}
.offeringlist-dropdownslist #nested-dropdown-container li,
.offeringlist-dropdownslist #nested-dropdown-container li .dropdown-item {
  color: #637381;
  font-size: 14px;
  font-family: graphik;
  font-weight: 400;
}
.offeringlist-dropdownslist #nested-dropdown-container li:hover,
.offeringlist-dropdownslist #nested-dropdown-container li:hover .dropdown-item {
  color: #15181b;
  font-family: Graphik-Medium;
}
.offeringlist-dropdownslist.clientstory-boi-map #nested-dropdown-container li .dropdown-item:hover {
  color: #fff !important;
  font-family: Graphik-Medium;
}
.clientstory-boi-map .searchFilter input[type="search"] {
  width: 100% !important;
}
.metric-dropdown .dropdown-item {
  position: relative;
}
.metric-dropdown .dropdown-item::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 6px;
  margin: auto;
  display: inline-flex;
  width: 4px;
  border-radius: 6px;
  height: 60%;
}
.metric-dropdown .dropdown-item.red-color::before {
  background-color: #d5002f;
}
.metric-dropdown .dropdown-item.amber-color::before {
  background-color: #f58430;
}
.metric-dropdown .dropdown-item.green-color::before {
  background-color: #7ec344;
}
.metric-dropdown .dropdown-item.gray-color::before {
  background-color: #708a9c;
}
.subffering-clientstory .dropdown-menu.show {
  max-width: 340px;
  min-width: 210px;
}
.tab-content p.search-no-items {
  font-family: "graphik" !important;
}
.metrics-clientstory .dropdown-menu.show {
  right: 0 !important;
  width: 320px;
  z-index: 3;
}
.clientstory-boi-map .metric-dropdown .form-check-label {
  white-space: normal;
  margin: 0;
}
.subffering-clientstory.subffering-clientstory-wrapper .dropdown-menu.show {
  width: 368px;
}
.subffering-clientstory-wrapper #nested-dropdown-container li .dropdown-item {
  white-space: normal;
}
.subffering-clientstory.subffering-clientstory-wrapper #nested-dropdown-container li > span {
  color: #637381;
  font-family: graphik;
}
.subffering-clientstory.subffering-clientstory-wrapper #nested-dropdown-container li > span:hover {
  color: #15181b !important;
  font-family: Graphik-Medium;
}
.subffering-clientstory.subffering-clientstory-wrapper
  #nested-dropdown-container
  li.nested-li:hover
  .nested-ul
  li
  .dropdown-item {
  color: #637381;
  font-family: graphik;
}
.subffering-clientstory-wrapper #nested-dropdown-container li .dropdown-item.active,
.subffering-clientstory.subffering-clientstory-wrapper #nested-dropdown-container li:hover {
  color: #fff !important;
}
/* overview-key-milestones new changes css starts here */
.overview-key-milestones {
  /*  background: url('../Images/overview-key-milestones.svg') no-repeat center center; */
  /* background: url('../Images/overview-key-milestones.svg') no-repeat center center; */
  width: 100%;
  height: 100%;
  display: block;
  background-size: 100% 100%;
}
.custom-icon-dropdowns .dropdown-toggle,
.custom-icon-dropdowns .dropdown-toggle:focus,
.custom-icon-dropdowns .dropdown-toggle:hover,
.custom-icon-dropdowns .dropdown-toggle:focus:active {
  width: 112px;
  height: 32px;
  background: linear-gradient(90deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%);
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  box-shadow: none;
  padding: 0 20px;
}
.custom-icon-dropdowns .dropdown-toggle:hover {
  background: linear-gradient(-225deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%) !important;
}
.custom-icon-dropdowns .dropdown-toggle::after {
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  top: -2px;
  right: -9px;
}
.custom-icon-dropdowns .dropdown-menu a {
  padding-left: 36px;
  color: #637381;
  font-family: graphik;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  background: transparent;
  padding: 0.75rem 0.5rem 0.75rem 2rem;
  display: flex;
  position: relative;
  align-items: center;
}
.custom-icon-dropdowns .dropdown-menu a:hover,
.custom-icon-dropdowns .dropdown-menu a.active {
  background: #f4f6f8;
  border-radius: 0;
  font-weight: 500;
}
.custom-icon-dropdowns .dropdown-menu a.client-overview::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate(-50%, -50%);
  background: url("../Images/client-overview-icon.svg") no-repeat center;
  width: 16px;
  height: 16px;
  display: inline-flex;
}
.custom-icon-dropdowns .dropdown-menu a.key-outcomes::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate(-50%, -50%);
  background: url("../Images/keymilestone-icon.svg") no-repeat center;
  width: 10px;
  height: 16px;
  display: inline-flex;
}
.custom-icon-dropdowns .dropdown-menu a.key-outcomes::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate(-50%, -50%);
  background: url("../Images/ClientStoryIcons/keyoutcomesIcon.svg") no-repeat center;
  width: 16px;
  height: 16px;
  display: inline-flex;
}

.story-carousel-wrapper .custom-tilecard .tilecard-wrapper .owl-carousel .owl-item {
  height: 126px;
  width: 352px !important;
}
.story-carousel-wrapper .custom-tilecard .tilecard-wrapper .owl-carousel .owl-item {
  margin-top: 1.5rem;
}
.story-carousel-wrapper .owl-carousel .owl-nav [class*="owl-"] {
  margin-top: -16px;
}
.story-carousel-wrapper .owl-carousel .owl-nav .owl-prev {
  left: 5px;
}
.story-carousel-wrapper .owl-carousel .owl-nav .owl-next {
  right: 5px;
}
.story-carousel-wrapper .tilecard-wrapper .owl-carousel .owl-item .item {
  padding: 1rem 0.25rem 1rem 1rem;
}
.story-carousel-wrapper .tilecard-wrapper .owl-carousel .owl-item .item img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.story-carousel-wrapper .tilecard-wrapper .owl-carousel .owl-item .item .regions-data {
  display: flex;
}
.story-carousel-wrapper .tilecard-wrapper .owl-carousel .owl-item .item .regions-data span {
  line-height: 14px;
  display: inline-flex;
  margin: 0px 8px 6px 0px;
}
.regions-data-wrapper {
  width: 100%;
}
.story-carousel-wrapper .tilecard-wrapper .owl-carousel .owl-item .item .regions-label {
  color: rgb(33, 43, 54);
  font-family: "graphik";
  font-size: 12px;
  font-weight: normal;
  height: 12px;
  letter-spacing: -0.21px;
  justify-content: flex-end;
  width: 38%;
}
.story-carousel-wrapper .tilecard-wrapper .owl-carousel .owl-item .item .regions-value {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: normal;
  height: 12px;
  letter-spacing: -0.21px;
  width: 62%;
  margin-right: 0px;
  word-break: break-all;
}
.steps-wrapper {
  display: flex;
  width: 86%;
  height: 376px;
  margin: 0 auto;
  padding-bottom: 5rem;
  align-items: center;
  justify-content: center;
}
.steps-wrapper .step {
  height: 38px;
  width: 25%;
  position: relative;
}
.steps-wrapper .__react_component_tooltip.place-bottom {
  margin-top: 4px;
}
.steps-wrapper .__react_component_tooltip.place-top {
  margin-top: -2px;
}
.steps-wrapper .menulist-tooltips,
.regions-data .menulist-tooltips,
.add-delete-actions .menulist-tooltips {
  width: auto;
  max-width: 186px;
  border-radius: 8px;
}
.add-delete-actions .menulist-tooltips {
  max-width: 164px;
}
.step::before,
.step::after {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
}
.step::after {
  right: -19px;
  z-index: 1;
}
.step::before {
  border-left: 19px solid #fff;
}
.step-year:before {
  position: absolute;
  content: "";
  width: 2px;
  height: 78px;
  left: 0;
  right: 0;
  margin: auto;
}
.step:nth-child(odd) .step-year:before {
  bottom: -83px;
}
.step:nth-child(even) .step-year:before {
  top: -83px;
}
.step .step-details .step-name::before {
  content: "";
  display: inline-block;
  height: 1px;
  width: 46px;
  bottom: 7px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.step1-color,
.step1-color .step-year:before,
.step1-color .step-name::before {
  background: #b1db8e;
}
.step1-color::after {
  border-left: 19px solid #b1db8e;
}
.step2-color,
.step2-color .step-year:before,
.step2-color .step-name::before {
  background: #0071cc;
}
.step2-color::after {
  border-left: 19px solid #0071cc;
}
.step3-color,
.step3-color .step-year:before,
.step3-color .step-name::before {
  background: #00baff;
}
.step3-color::after {
  border-left: 19px solid #00baff;
}
.step4-color,
.step4-color .step-year:before,
.step4-color .step-name::before {
  background: #a055f5;
}
.step4-color::after {
  border-left: 19px solid #a055f5;
}
.step5-color,
.step5-color .step-year:before,
.step5-color .step-name::before {
  background: #f580c0;
}
.step5-color::after {
  border-left: 19px solid #f580c0;
}
.step6-color,
.step6-color .step-year:before,
.step6-color .step-name::before {
  background: #f49342;
}
.step6-color::after {
  border-left: 19px solid #f49342;
}
.step7-color,
.step7-color .step-year:before,
.step7-color .step-name::before {
  background: #ffc82c;
}
.step7-color::after {
  border-left: 19px solid #ffc82c;
}
.step-year {
  position: absolute;
  width: 74px;
  height: 74px;
  background: rgb(255, 255, 255);
  font-size: 18px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  left: 9px;
  right: 0;
  margin: auto;
}
.step:nth-child(odd) .step-year {
  bottom: 116px;
}
.step:nth-child(even) .step-year {
  top: 116px;
}
.step1-color .step-year {
  border: 5px solid #b1db8e;
  box-shadow: 0px 2px 4px 0px rgb(177, 219, 142, 0.5);
  color: #b1db8e;
}
.step2-color .step-year {
  border: 5px solid #0071cc;
  box-shadow: 0px 2px 4px 0px rgba(0, 113, 204, 0.5);
  color: #0071cc;
}
.step3-color .step-year {
  border: 5px solid #00baff;
  box-shadow: 0px 2px 4px 0px rgb(0, 186, 255, 0.5);
  color: #00baff;
}
.step4-color .step-year {
  border: 5px solid #a055f5;
  box-shadow: 0px 2px 4px 0px rgb(160, 85, 245, 0.5);
  color: #a055f5;
}
.step5-color .step-year {
  border: 5px solid #f580c0;
  box-shadow: 0px 2px 4px 0px rgb(245, 128, 192, 0.5);
  color: #f580c0;
}
.step6-color .step-year {
  border: 5px solid #f49342;
  box-shadow: 0px 2px 4px 0px rgb(244, 147, 66, 0.5);
  color: #f49342;
}
.step7-color .step-year {
  border: 5px solid #ffc82c;
  box-shadow: 0px 2px 4px 0px rgb(255, 200, 44, 0.5);
  color: #ffc82c;
}
.step .step-details {
  text-align: center;
  position: absolute;
  left: 9px;
  border: none;
  right: 0;
  margin: auto;
}
.step:nth-child(odd) .step-details {
  top: 58px;
}
.step:nth-child(even) .step-details {
  bottom: 58px;
}
.step .step-details .step-name {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.27px;
  line-height: 22px;
  padding-bottom: 10px;
  position: relative;
}
.step .step-details .step-description,
.step .step-details .step-description p,
.step .step-details .step-description li,
.step .step-details .step-description ul li span {
  color: rgb(145, 158, 171) !important;
  background-color: transparent !important;
  font-size: 12px !important;
  font-family: "graphik" !important;
  font-weight: normal;
  text-align: left;
  letter-spacing: -0.25px;
  line-height: 14px;
}
.step-description ul li {
  position: relative;
  padding-left: 5px;
}
.step-description ul li::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0px;
  display: inline-flex;
  background: rgb(99, 115, 129);
  height: 4px;
  width: 4px;
  border-radius: 50%;
}
.step:nth-child(odd) .step-details::before,
.step:nth-child(even) .step-details::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  top: -24px;
  transform: rotate(90deg);
  left: 0px;
  right: 0;
  margin: auto;
}
.step:nth-child(even) .step-details::before {
  transform: rotate(-90deg);
  bottom: -24px;
  top: unset;
}
.step1-color .step-details::before {
  border-left: 14px solid #b1db8e;
}
.step2-color .step-details::before {
  border-left: 14px solid #0071cc;
}
.step3-color .step-details::before {
  border-left: 14px solid #00baff;
}
.step4-color .step-details::before {
  border-left: 14px solid #a055f5;
}
.step5-color .step-details::before {
  border-left: 14px solid #f580c0;
}
.step6-color .step-details::before {
  border-left: 14px solid #f49342;
}
.step7-color .step-details::before {
  border-left: 14px solid #ffc82c;
}
.draggdrop-container .common-formgrouplabel .form-group.border-group.disabled .form-control {
  color: rgb(124 132 139);
}
.nostory-data {
  padding-top: 5rem;
}

.step .step-details .step-description ul li,
.step .step-details .step-description p,
.step .step-details .step-description p span,
.step .step-details .step-description li span {
  margin-left: 0 !important;
  background-color: transparent !important;
  color: rgb(145, 158, 171) !important;
  white-space: normal;
  word-wrap: break-word;
}
/* .step .step-details .step-description p{
  padding-left: 5px;
} */
.step .step-details .step-description {
  max-height: 142px;
  overflow-y: scroll;
  overflow-x: hidden;
  cursor: default;
}
.step .step-details .step-description ul {
  margin-bottom: 0px !important;
}

/* overview-key-milestones new changes css starts here */

/* User Story 151469: Client Story Enhancement - Key outcomes delivered starts here */
.dropdown-menu.outcomes-delivered.show {
  min-width: 220px;
}
.overview-milestone-wrapper {
  width: 100%;
  display: flex;
}
.overview-milestone-wrapper .story-carousel-wrapper {
  width: 100%;
  background: url("../Images/overview-key-milestones.svg") no-repeat center center;
  background-size: 100% 100%;
}
.overview-milestone-wrapper .outcomes-delivered-wrapper {
  width: 25%;
  background: rgb(255, 255, 255);
  border-radius: 0px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  height: max-content;
  min-height: 126px;
  margin: 1.5rem 1rem;
  padding: 1.5rem 0.5rem 2rem 1rem;
  position: relative;
}
.overview-milestone-wrapper .steps-wrapper {
  width: 94%;
}
.outcomes-delivered-wrapper h2 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.21px;
}
.outcomes-delivered-wrapper ul {
  overflow-y: scroll;
  max-height: 420px;
  padding-right: 0.5rem;
  margin-bottom: 0px;
}
.outcomes-delivered-wrapper ul li {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: 0px;
  position: relative;
  padding-left: 0.9rem;
}
.outcomes-delivered-wrapper ul li::before {
  content: "";
  position: absolute;
  background: rgb(33, 43, 54);
  height: 6px;
  width: 6px;
  left: 0;
  top: 5px;
  border-radius: 50%;
  display: inline-flex;
}
.outcomes-delivered-wrapper .view-more {
  position: absolute;
  bottom: 6px;
  right: 0;
  left: 0;
  margin: 0 auto;
  color: rgb(1, 142, 255);
  font-size: 12px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.outcomes-delivered-wrapper .view-more i {
  font-size: 18px;
}
.next-down,
.next-down.disabled {
  transform: rotate(180deg);
}
.view-more span img {
  cursor: pointer;
}
.view-more span img.disabled {
  cursor: none;
}
.overview-milestone-wrapper .default-msg {
  background: url("../Images/overview-key-milestones.svg") no-repeat center center;
  width: 100%;
  background-size: 100% 100%;
}
.default-msg.no-keyOutcomes-wrapper {
  width: 100%;
}

/* User Story 151469: Client Story Enhancement - Key outcomes delivered ends here */

.client-story-references {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.client-story-references .category-references {
  position: relative;
  left: unset;
  right: unset;
}

.story-carousel-wrapper
  .tilecard-wrapper
  .owl-carousel
  .owl-item
  .item
  .regions-data
  span.other-comments-value {
  height: auto;
  margin-bottom: 6px;
  line-height: normal;
}
.dadatepicker-label-wrapper .datepicker-label {
  width: 100%;
}
.item>span i {
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  cursor: pointer;
} 
.item>span.addition-info-tooltip .__react_component_tooltip{
  width: 260px;
  min-height: 118px;
  max-height: 118px;
  padding: 0.25rem 0.5rem !important;
  line-height: 16px;
}
.clientstory-card-tooltip span ul li {
  list-style: disc;
  margin-left: 0.5rem;
}