.privacy-notice-popup-backdrop {
  z-index: 9998;
}

.privacy-notice-popup.modal.show {
  z-index: 9999;
}

.privacy-notice-popup .modal-dialog {
  width: 600px;
  max-width: none;
}

.privacy-notice-popup .modal-content {
  border-radius: 0;
  background: #fff;
  border: none;
}

.privacy-notice-popup .modal-header {
  padding: 0 1rem !important;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background: linear-gradient(to right, #4a0480, #610dad, #741ab0);
  height: 3.5rem;
  border-radius: 0;
}

.privacy-notice-popup .modal-header img {
  height: 2rem;
  filter: invert(100%);
}

.privacy-notice-popup .modal-header div {
  margin: 0 1rem;
  border: 1px solid white;
  height: 100%;
  width: 1px;
}

.privacy-notice-popup .modal-header h6 {
  margin: 0;
  color: #fff;
  font-family: "Graphik-Medium";
}

.privacy-notice-popup .modal-body {
  padding: 1rem 1rem 2rem !important;
  margin: 0;
  max-height: 60vh;
  overflow-y: auto;
}

.privacy-notice-popup-content {
  font-size: 0.75rem;
  color: #383736;
  background: #f3f3f3;
  padding: 1.5rem;
  font-family: "Graphik-Semibold";
}

.privacy-notice-popup-content a,
.privacy-notice-popup-content a:hover {
  color: #106ffb;
  text-decoration: underline !important;
  font-family: "Graphik-Semibold" !important;
}

.privacy-notice-popup-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 0.75rem;
}

.privacy-notice-popup-buttons button {
  border: 1px solid #be69f5;
  background-color: #e9e3ec;
  display: flex;
  padding: 0.4rem 0.7rem;
  font-size: 0.75rem;
  font-family: "Graphik-Medium";
}
