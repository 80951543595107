.story-content{
    margin-top: -30px;
    padding: 2rem 1em;
    background-color: #fff;
    height: calc(100vh - 130px);

}
.story-content h3{
    color: #212b36;
    font-family: Graphik-Medium;
    font-size: 20px;
    font-weight: 500;
}
.story-tabs{
    margin: 10px 0;
    position: relative;

}
.story-tabs .nav-tabs .nav-link{
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    color: #637381;
    position: relative;
    padding: 1rem 0rem;
    margin-right: 2rem;
    border: none;
}
.story-tabs .nav-tabs .nav-link.active{
    border-bottom: none;
    border: none;
    color: #212b36 !important;
}
.story-tabs .nav-tabs .nav-link.active:after{
    content: " ";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    border-radius: 4px;
    height: 8px;
    background-color: #018eff;
}

.story-tabs .nav-tabs{
    border-bottom: 1px solid #dfe3e8;
}
.storysub-tabs {
    display: flex;
}
.storysub-tabs .contact-info{
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}
.storysub-tabs .contact-info p{
    color: rgb(99, 115, 129);
font-family: Graphik-Medium;
font-size: 14px;
font-weight: 500;
}
.storysub-tabs .nav-tabs{
    display: block;
    background: #fff;
    border-bottom: none;
    padding-top: 15px;
    padding-right: 16px;
    border-right: 1px solid #dfe3e8;
    overflow: auto;
    height: calc(100vh - 246px);
    position: absolute;
    width: 256px;
}
.storysub-tabs .nav-tabs .nav-link{
    padding: 16px 10px;
    display: block;
    width: 100%;
    min-width: 240px;
    color: #212b36;
}
.storysub-tabs .nav-tabs .nav-link i{
    color: #212b36;
    position: relative;
    margin-right: 6px;
    font-size: 16px;
}
.storysub-tabs .nav-tabs .nav-link:hover{
    background: rgba(255, 255, 255, 0.2)
    linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%) !important;
    border-radius: 4px;
    /* color: #fff !important; */
}
.storysub-tabs .nav-tabs .nav-link:hover i{
    color: #fff !important;
}
.storysub-tabs .nav-tabs .nav-link.active{
    background: rgba(255, 255, 255, 0.2)
    linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%) !important;
    border-radius: 4px;
    /* color: #fff !important; */
}
.story-tabs .storysub-tabs .nav-tabs .nav-link.active:after{
    display: none;
}
.storysub-tabs .nav-tabs .nav-link.active i{
    color: #fff !important;
}
.storysub-tabs .tab-content{
    width: calc(100% - 256px);
    left: 256px;
    position: relative;
}
.storysub-tabs .storysub-content{
    padding: 6px 25px;
    flex-wrap: wrap;
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfe3e8;
}
.storysub-tabs .story-links{
   width: 70%;
}
.storysub-tabs .story-links ul{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    justify-content: flex-end;
    margin: 0;
}
.storysub-tabs .story-links li{
    text-align: center;
    width: 33%;
}
.storysub-tabs .story-links span{
    display: block;
    color: rgb(1, 142, 255);
font-family: Graphik-Medium;
font-size: 10px;
font-weight: 500;
line-height: 12px;
}
.storysub-tabs .story-links span svg{
    font-size: 12px !important;
}

.storysub-tabs .story-header{
    width: 30%;
    font-family: Graphik-Medium;
font-size: 16px;
font-weight: 500;
color: #212b36;
}
.storysub-tabs .story-links .password-txt{
    color: #637381;
font-family: graphik;
font-size: 12px;
font-weight: 400;
letter-spacing: -.25px;
}
.storysub-content.upcoming-offering .story-header{
    color:#919eab;
}
.storysub-content.upcoming-offering .story-links li{
    color:#919eab;
    font-family: Graphik-Medium;
font-size: 12px;
font-weight: 500;
}
.search-nav .nav-item.logged-img a.nav-link:not(.dropdown-toggle):after{
    display: none;
}
.story-nav{
    margin-left: auto;
}
.story-nav .nav-link {
    padding: 0.5rem 0rem 0.3rem !important;
}


/* Offering Contacts Start */
.offeringContacts .contactsList {
    padding: 16px 24px;
    border-bottom: 1px solid #dfe3e8;
}
.offeringContacts .contactsList:last-child {
    border-bottom: none;
    margin-bottom: 40px;
}
.contactsList .contactsHead {
    padding-bottom: 12px;
}
.contactsList .contactsHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.contactsList .contactsInfo {
    display: flex;
    align-items: center;
    width: 100%;
}
.contactsInfo .leadName{
    width: 20%;
}
.contactsList .contactsHead h2{
    color: rgb(33, 43, 54);
    font-size: 16px;
    font-family: graphik-medium;
    font-weight: 500;
    letter-spacing: -0.35px;
    margin: 0;
}
.contactsHead p {
    margin: 0;
}
.contactsList .contactsInfo p{
    color: rgb(99, 115, 129);
    font-size: 14px;
    font-family: graphik;
    font-weight: normal;
    letter-spacing: -0.29px;
    margin: 0;
    padding-bottom: 4px;
}
.contactsList .contact-icons svg{
    color:#018eff;
    margin-left: 20px;
    height: 14px;
}

.contactsList .contactsInfo .leadEmail {
    font-family: graphik-medium;
}
.addOfferingContacts {
    padding: 20px 40px;
    margin-bottom: 2rem;
}
.addOfferingContacts .addContact{
    color: rgb(33, 43, 54);
    font-size: 20px;
    font-family: graphik-medium;
    font-weight: 500;
    letter-spacing: -0.35px;
    line-height: 20px;
    margin: 0;
    padding-bottom: 10px;
}
.addOfferingContacts .addContactForm label{
    color: rgb(99, 115, 129);
    font-size: 11px;
    font-family: graphik-medium;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 11px;
}
.addOfferingContacts .addContactForm #subOffering{
    padding-left: 8px;
}
 
.addOfferingContacts .addContactForm .form-control {
    height: 46px;
    margin-bottom: 8px;
    color: rgb(33, 43, 54);
    font-size: 16px;
    font-family: graphik;
    font-weight: normal;
    letter-spacing: -0.35px;
    line-height: 16px;
}
.addOfferingContacts .addContactForm .form-control:focus {
    box-shadow: none;
}
.contactBtns {    
    margin-top: 10px;
}
.contactBtns button {
    width: 120px;
    height: 48px;
    border-radius: 24px;
    margin-left: 24px;
}
.contactBtns button.cancel, .contactBtns button.add{
color: rgb(99, 115, 129) !important;
font-size: 16px;
font-family: graphik-medium;
font-weight: 500;
text-align: center;
letter-spacing: -0.35px;
line-height: 16px;
background: #fff !important;
}
 
.contactBtns button.cancel:hover, .contactBtns button.cancel:active, .contactBtns button.cancel:focus{
    color: rgb(99, 115, 129) !important;
    background: #fff !important;
    box-shadow: none !important;
}
.contactBtns button.add, .contactBtns button.add:focus, .contactBtns button.add:active,  .contactBtns button.add:hover{
    color: rgb(255, 255, 255) !important;
    background-color: rgb(1, 142, 255) !important;
    box-shadow: none !important;
}
.offering-btn{
    position: absolute;
    right: 12px;
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    color: #008eff;
    top: 1rem;
    cursor: pointer;
    background-color: #fff !important;
}
.offering-status span{
    border-radius: 20px;
    border: 1px solid rgb(0, 142, 255);
    height: 24px;
    width: 74px;
    color: #008eff;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
    top: -2px;
    position: relative;
}
.offering-status span i{
    margin-right: 10px;
}
.offering-btn.btn-outline-primary{
    border-radius: 20px;
    border: 1px solid rgb(1, 142, 255);
    height: 40px;
    width: 124px;
    font-size: 14px;
    top: 0px;
}
.offering-btn.btn-outline-primary:hover,
.offering-btn.btn-outline-primary:active,
.offering-btn.btn-outline-primary:focus{
    background-color: #fff !important;
    box-shadow: none !important;
    color: rgb(1, 142, 255) !important;
    
}
.disableAddBtn {
    display: none !important;
}
.storysub-tabs .story-links li.disabled span{
    color: #919eab;
    pointer-events: none;
    opacity: .9;
}
.offering-navtab{
    display: flex;
    align-items: center;
}
.offering-navtab img{
    margin-right: 5px;
}

/* Demo Enhancenemnt CSS Start */
.storymain-content{
    padding: 1rem;
    padding: 0.5rem;
    height: calc(100vh - 246px);
    overflow: auto;
    margin: 0.5rem;
}
.storycontent-table{
    display: flex;
    flex-wrap: wrap;
}
.storycontent-row{
    width: 100%;
    display: flex;
}
.storycontent-cell{
    box-sizing: border-box;
    flex-grow: 1;
    padding: 12px 8px;
    /* overflow: hidden; */
    list-style: none;
}
.storycontent-cell .__react_component_tooltip{
    max-width: 180px;
    white-space: break-spaces;
}
.offering-cell  { width: 25%; }
.demo-cell  { width: 23%; }
.password-cell  { width: 20%; }
.userguide-cell  { width: 11%; }
.videolink-cell  { width: 10%; }
.storylinks-cell  { 
    width: 12%;
    padding-right: 24px;
    display: flex;
    justify-content: flex-end;
 }
.comingsoon-cell  { width: 65%; }
.storycontent-header{
    background-color: rgb(227, 209, 255);
    border-radius: 4px;
}
.storycontent-header .storycontent-cell,
.storycontent-data .storycontent-cell.offering-cell,
.storycontent-accordion .card-link{
    color: rgb(21, 24, 27);
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
}
.storycontent-data:hover{
    background: linear-gradient(
-225deg
,rgba(161,0,255,.05),rgba(0,186,255,.05));
}
.storycontent-accordion .card-body .storycontent-data{
    background: rgba(0,186,255,.05);
}
.storycontent-data .storycontent-cell{
font-family: graphik;
font-size: 14px;
font-weight: normal;
letter-spacing: 0px;
}
.storycontent-data{
    border-bottom: 1px solid rgb(196, 205, 213);
}
.storycontent-cell.offering-cell{
    padding: 12px 12px 12px 30px;
}
.storycontent-data .demo-cell{
    color: rgb(1, 142, 255);
    overflow: hidden;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
}
.storycontent-data .password-cell{
    color: rgb(99, 115, 129);
    overflow: hidden;
    white-space: nowrap;
    /* text-overflow: ellipsis; */
}
.storycontent-data .password-cell span{
    font-family: graphik;
}
.password-cell img{
    cursor: pointer;
}
.storycontent-data .password-cell.password-copied{
    color: rgb(21, 24, 27);
font-family: Graphik-Medium;
}
.storycontent-table .dropdown-toggle{
    background: transparent !important;
    padding: 0;
    border: none;
    color: rgb(21, 24, 27) !important;
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    outline: none !important;
    box-shadow: none !important;
}
.storycontent-table .dropdown-toggle:after{
    border-bottom: 2px solid rgb(21, 24, 27);
    border-right: 2px solid rgb(21, 24, 27);
}
.storycontent-cell .dropdown-item-text{
    padding: .75rem .75rem;
}
.storycontent-table .dropdown-item {
    color: #212b36;
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -.25px;
    padding: .75rem .75rem;
    white-space: normal;
}
.storycontent-table .dropdown-menu{
    border-radius: 8px;
}
.storycontent-table .dropdown-menu .dropdown-item:hover {
    color: #212b36 !important;
    font-family: Graphik-Medium;
    background: linear-gradient(
      -225deg,
      rgba(161, 0, 255, 0.1) 0%,
      rgba(0, 186, 255, 0.1) 100%
    );
    border-radius: 4px;
  }
  .storycontent-table .dropdown-menu .dropdown-item.active{
    color: #018eff !important;
    font-family: Graphik-Medium;
    border-radius: 0px;
    background: transparent;
  }
  .storycontent-cell.storylinks-cell img,
  .storyaccordion-right img{
      padding: 0 6px;
      cursor: pointer;
  }
  .storycontent-cell.storylinks-cell img.greylink-image,
  .storyaccordion-right img.greylink-image{
    cursor: auto;
  }

  .storycontent-cell.storylinks-cell{
    min-width: 127px;
}
  .storycontent-accordion{
      width: 100%;
  }
  .storycontent-accordion .card{
      border: none;
      border-bottom: 1px solid rgb(196, 205, 213) !important;
  }
  .storycontent-accordion .card-header{
    background: linear-gradient(-225deg, rgba(161, 0, 255, 0.05) 0%, rgba(0, 186, 255, 0.05) 100%);
    border-radius: 0px;
    padding: 12px 24px 12px 30px;
    display: flex;
    justify-content: space-between;
  }
  .storycontent-accordion .card-link{
      width: 88%;
  }
  .storycontent-accordion .card-body{
      padding: 0;
  }
  .storycontent-accordion .card-body .storycontent-data:last-child{
      border-bottom: none;
  }
.storycontent-accordion .card-link::before,
.storycontent-accordion .card-link[aria-expanded="false"]::before {
  content: "\F078";
  position: absolute;
  top: 45%;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: rgb(21, 24, 27);
  line-height: 2px;
  left: -18px;
  transform: rotate(0deg);
  transition: all 0.2s ease 0.2s;
  display: block;
}
.storycontent-accordion .card-link.collapsed::before,
.storycontent-accordion .card-link[aria-expanded="false"]::before{
  transform: rotate(-90deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}
.comingsoon-cell{
color: rgb(145, 158, 171);
font-family: graphik;
font-size: 14px;
font-weight: normal;
letter-spacing: 0px;
}
.addDemoModal .modal-dialog{
    max-width: 625px;
}
.addDemoModal .modal-header{
    padding: 24px 24px 8px;
}
.addDemoModal .modal-header .close{
    top:26px;
    right: 24px;
}
.addDemoModal .modal-title{
color: rgb(21, 24, 27);
font-family: Graphik-Semibold;
font-size: 16px;
font-weight: 600;
letter-spacing: -0.35px;
line-height: 20px;
}
.addDemoModal .modal-body{
    padding: 1em 1.5em;
}
.demo-form .form-group.border-group{
    border-radius: 8px;
    border: 1.5px solid #ccc;
}
.demo-form .form-group.border-group.disabled{
    background: rgb(239, 242, 245);
    border: 1px solid rgb(231, 234, 238);
}
.linkoverflow-class .form-control{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.deletenotification-body{
    display: flex;
}
.deletenotification-icon{
margin-right: 40px;
}
.deletenotification-body p{
color: rgb(99, 115, 129);
font-family: graphik;
font-size: 16px;
font-weight: normal;
letter-spacing: 0px;
line-height: 24px;
}
.addDemoModal .modal-footer .btn{
font-family: Graphik-Medium;
font-size: 12px;
font-weight: 500;
letter-spacing: -0.25px;
line-height: 12px;
padding: 9px 16px;
outline: none !important;
box-shadow: none !important;
}
.addDemoModal .modal-footer .btn.btn-danger{
    background: rgb(213, 0, 47);
}
.offering-droplistwrapper{
    max-height: 230px;
    overflow-y: auto;
}
.passwordcopy-notification{
    display: flex;
    align-items: center;
}
.passwordcopy-notification img{
    width: 18px;
    height: 18px;
    margin-right: 10px;
}
.passwordcopy-notification h6{
color: rgb(21, 24, 27);
font-family: Graphik-Medium;
font-size: 14px;
font-weight: 500;
letter-spacing: -0.25px;
line-height: 18px;
margin-bottom: 0;
}

.storycontent-cell.videolink-cell a img,
.storycontent-cell.userguide-cell a img{
    cursor: pointer;
}
.passwordcopy-notification{
    position: fixed;
    top: 74px;
    left :0;
    right: 0;
    margin: auto;
    background: rgb(255, 255, 255);
border-radius: 16px;
border: 1px solid rgb(231, 234, 238);
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
padding: 12px;
width: 260px;
}
.passwordCopy{
  overflow-wrap: break-word;
  word-wrap: break-word;
}
/* Demo Enhancenemnt CSS End */