/* BAT NEW ENHANCEMENT START */
.modal-dialog.modal-80w {
  width: 80vw;
  max-width: none !important;
}

.modal-dialog.modal-90w {
  width: 90vw;
  max-width: none !important;
}

.modal-dialog.modal-95w {
  width: 95vw;
  max-width: none !important;
}
.modal-dialog.modal-50w {
  width: 50vw;
  max-width: none !important;
}
.align-dialog-up {
  align-items: flex-start !important;
}
.batOffering-category {
  display: flex;
  justify-content: space-between;
  padding: 11px 24px 0px 24px;
  margin-top: -12px;
}
.categoryView {
  padding: 16px !important;
}
.batOffering-category-container {
  display: flex;
  margin-top: 15px;
}
.batOffering-category-containerA {
  display: flex;
  margin-top: 9px;
}
.batOffering-category-list {
  margin-top: 3px;
  margin-left: 10px;
}
.batOffering-category-label {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.batOffering-category-list .form-check-label {
  color: rgb(99, 115, 129);
  font-size: 15px;
  /* font-family: Graphik-Regular; */
  font-weight: normal;
  letter-spacing: -0.35px;
  bottom: 3px;
}
.batOffering-category-list .form-check-label-active {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  vertical-align: middle;
  position: relative;
  margin-left: 4px;
  bottom: 3px;
}
.offering-search {
  display: flex;
  justify-content: flex-end;
}
.searchBtn .form-control-feedback {
  padding: 3px 2px 5px 20px;
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.searchBtn input {
  min-width: 290px;
  min-height: 40px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 20px;
}
.searchBtn .form-group {
  margin: 0;
}
.searchBtn .form-control {
  padding-left: 2.675rem;
}

.batOffering-list-container .offering-name {
  /* width: 187px;
    height: 20px; */
  /* color: rgb(33, 43, 54); */
  color: #9005b3;
  font-size: 18px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.5px;
  /* line-height: 20px; */
}
.batOffering-list-container .analysisFull:hover {
  cursor: pointer;
  border: 2px solid linear-gradient(#a100ff 0%, #00baff 100%);
  box-shadow: 0px 2px 24px 0px rgba(33, 43, 54, 0.25);
}

.subOfferingSelectionModal .justify-content-center.modal-header {
  border-bottom: 1px solid #dfe3e8;
  padding: 20px 32px;
}
.subOfferingSelectionModal .modal-content {
  height: 80vh;
  max-height: none !important;
}
.subOfferingSelectionModal .modal-header .close {
  left: 16px;
  top: 21px;
}
.subOfferingSelectionModal .subOffering-name {
  text-align: center;
  padding-top: 1.5rem;
}
.subOfferingSelectionModal .modal-title,
.subOfferingSelectionModal .subOffering-name p {
  display: inline-block;
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 16px;
  letter-spacing: -0.35px;
  line-height: 16px;
  /* width: 162px; */
  font-weight: 500;
  text-align: center;
}
.subOfferingSelectionModal .subOffering-name p {
  padding-top: 1.5rem;
}
.subOfferingSelectionModal .subOffering-options {
  display: flex;
  justify-content: center;
  /* justify-content: space-around; */
  padding-top: 3rem;
  flex-wrap: wrap;
}
.subOfferingSelectionModal .subOffering-options .optionLabel-wrap {
  background: linear-gradient(to right, #a100ff 0%, #00baff 100%);
  border-radius: 22px;
  /* padding: 3px 2px 5px 2px; */
  cursor: pointer;
  display: inline-block;
  padding: 2px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.subOfferingSelectionModal .subOffering-options .optionLabel {
  background: rgb(255, 255, 255);
  border: none !important;
  border-radius: 20px;
  color: #a100ff;
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  padding: 7px 15px;
  /* line-height: 26px;
    padding: 0px 5px; */
  display: flex;
  align-items: center;
}
.subOfferingSelectionModal .subOffering-options span.active,
.subOfferingSelectionModal .subOffering-options .optionLabel-wrap .optionLabel:hover {
  background: linear-gradient(to right, #a100ff 0%, #00baff 100%);
  border-radius: 20px;
  border: none;
  color: rgb(255, 255, 255) !important;
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  padding: 7px 15px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
.newbatOffering {
  margin-left: 0.5rem;
}
.newbatOffering a.backPush {
  padding-top: 3px;
  padding-bottom: 3px;
}
.newbatOffering .divider {
  margin: 0px 18px 0px 6px;
}
.BatCommonHeader .newbatOffering .divider {
  margin: 0px 18px 0px 0px;
}
.offeringCategoryModal .justify-content-center.modal-header,
.clientVsConsolidatedModal .justify-content-center.modal-header {
  border-bottom: 1px solid #dfe3e8;
  padding: 20px 32px 5px;
}
.offeringCategoryModal .modal-header .close,
.clientVsConsolidatedModal .modal-header .close {
  left: 21px;
  top: 29px;
}
.offeringCategoryModal .offeringName {
  border-right: 1px solid #dfe3e8;
}
.clientVsConsolidatedModal .subOfferingCatgy {
  border-right: none;
}
.offeringCategoryModal label.form-check {
  display: block;
}
.offeringCategoryModal .offeringName span.offering-label {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
  line-height: 2.5rem;
  padding: 0px 5px;
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}
.offering-span {
  padding: 5px 0px;
  margin-bottom: 5px;
}
.offeringName .rwt__tab {
  padding-bottom: 0px;
}
.offering-label {
  display: block !important;
  text-align: initial;
}
.offeringCategoryModal .offeringName span.offering-label img {
  height: 19px;
  width: 19px;
  margin-right: 8px;
}
.offeringCategoryModal .offeringName span.offering-label.active,
.offeringCategoryModal .offeringName span.offering-label:hover,
.offeringCategoryModal .offeringName div.offering-span.active,
.offeringCategoryModal .offeringName div.offering-span:hover,
.clientVsConsolidatedModal .active,
.clientVsConsolidatedModal li:hover,
.assessmentOfferingModal li:hover,
.assessmentOfferingModal .active {
  color: white !important;
  background: #a100ff;
  border-radius: 4px;
}
.offeringCategoryModal .offeringName ul {
  line-height: 3rem;
}
.offeringCategoryModal .offeringName i {
  margin-right: 0.5rem;
}
.offeringCategoryModal .subOfferingName label {
  line-height: 3rem;
}
.offeringCategoryModal .offeringName,
.offeringCategoryModal .subOfferingName {
  padding-top: 1rem;
  min-height: 27rem;
}
.offeringCategoryModal .subOfferingName {
  padding-left: 2rem;
}
.offeringCategoryModal .subOfferingName .form-check-label {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  cursor: pointer;
}
.offeringCategoryModal .subOfferingName .form-check-label-active {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  cursor: pointer;
}
.offeringCategoryModal .subOfferingName span.checkmark {
  margin-top: 1rem;
}
.offeringCategoryModal .modal-body {
  padding: 0em 2em;
  min-height: 450px;
}
.offeringCategoryModal .offeringName .rwt__tablist[aria-orientation="vertical"] {
  border-right: none;
}
.offeringCategoryModal
  .offeringName
  .rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  border-right: none;
}
.offeringCategoryModal .offeringName .rwt__tab:after {
  content: "";
  position: absolute;
  bottom: 0;
  background: transparent !important;
  color: rgb(255, 255, 255);
  border-radius: 4px;
}
.offeringCategoryModal .offeringName .rwt__tablist:not([aria-orientation="vertical"]) {
  display: inherit;
  border-bottom: 0 none;
  flex-direction: column;
}
.offeringCategoryModal .offeringName .rwt__tab[aria-selected="true"]:after {
  content: "";
  position: absolute;
  background: transparent;
}
.offeringCategoryModal .modal-header .rwt__tablist {
  justify-content: center;
}
.batOffering-category-title {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.36px;
  /* padding-bottom: 32px; */
  padding-bottom: 20px;
  /* line-height: 30px; */
}
.batView.offering-details-screen .batOffering-category-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.batOffering-subOffering-title-wrap {
  background: linear-gradient(to right, #a100ff 0%, #00baff 100%);
  border-radius: 22px;
  margin-right: 12px;
  padding: 2px;
}
/* .batOffering-subOffering-title span {
    background: rgb(255, 255, 255);
} */
.batOffering-subOffering-title-wrap .subOffering-title-box {
  color: linear-gradient(to right, #a100ff 0%, #00baff 100%);
  font-family: graphik-medium;
  border: none;
  border-radius: 20px;
  padding: 13px 17px 11px;
  letter-spacing: -0.25px;
  line-height: 16px;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  background: rgb(255, 255, 255);
}
.analysisFull-wrap {
  margin: 12px 0;
  padding: 2px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}
.analysisFull-wrap:hover,
.analysisFull-wrap:active,
.analysisFull-wrap.active {
  position: relative;
  background: linear-gradient(#a100ff 0%, #00baff 100%);
  padding: 2px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 2px 24px 0px rgba(33, 43, 54, 0.25);
}
.analysisFull-wrap .analysisFull {
  margin: 0px !important;
  box-shadow: none;
}
.analysisFull-wrap .analysisFull:hover {
  border: 2px solid transparent;
  box-shadow: none;
}
.subOffering-title-text {
  color: #8a1bff;
}
.optionLabel-text:hover {
  -webkit-text-fill-color: rgb(255, 255, 255);
}

/* Assessment style changes */
.assessment-dropdown-btn {
  margin-top: 5px;
  margin-bottom: 1rem;
  display: flex;
}
.assessment-dropdown-btn .dropdown .btn,
.viplink-btn {
  padding: 0.575rem 1.5rem;
  border-radius: 1.25rem;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(117, 0, 192) 52%,
    rgb(70, 0, 115) 100%
  ) !important;
}
.assessment-dropdown-btn .dropdown .btn:hover,
.assessment-dropdown-btn .dropdown.show .dropdown-toggle,
.assessment-dropdown-btn .dropdown.show .dropdown-toggle:focus,
.assessment-dropdown-btn .dropdown.show .dropdown-toggle:hover,
.viplink-btn:hover {
  background: linear-gradient(-225deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%) !important;
}
.assessment-dropdown-btn .dropdown .dropdown-toggle:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  height: 10px;
  width: 10px;
}
.newbatOffering .assessment-dropdown-btn .dropdown .dropdown-toggle:after {
  border-bottom: 2px solid #8a1bff;
  border-right: 2px solid #8a1bff;
}
.assessment-dropdown-btn .show > .btn-primary.dropdown-toggle {
  border: none !important;
  color: #fff;
}
.assessment-dropdown-btn .dropdown.btn.focus,
.assessment-dropdown-btn .dropdown.btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.assessment-dropdown-btn .dropdown.btn-primary.focus,
.assessment-dropdown-btn .dropdown.btn-primary:focus {
  box-shadow: none !important;
}
.assessment-dropdown-btn .dropdown .btn-primary:not(:disabled):not(.disabled).active:focus,
.assessment-dropdown-btn .dropdown .btn-primary:not(:disabled):not(.disabled):active:focus,
.assessment-dropdown-btn .show > .btn-primary.dropdown-toggle:focus,
.assessment-dropdown-btn .btn-primary.focus,
.assessment-dropdown-btn .btn-primary:focus {
  box-shadow: none;
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
  color: rgb(255, 255, 255);
}
.assessment-dropdown-btn .dropdown .btn-primary:hover,
.assessment-dropdown-btn .dropdown .btn-primary {
  border: none !important;
}

.bat-offering-details-screen .assessment-dropdown-btn .btn-primary.focus,
.bat-offering-details-screen .assessment-dropdown-btn .btn-primary:focus {
  box-shadow: none;
  background: rgb(255, 255, 255) !important;
  color: #8a1bff !important;
}
.assessment-dropdown-btn .dropdown .dropdown-item {
  padding: 0.4rem 0.5rem;
}
.assessment-dropdown-btn .dropdown .dropdown-item i {
  margin-right: 8px;
}
.assessment-dropdown-btn .dropdown .dropdown-item:focus,
.assessment-dropdown-btn .dropdown .dropdown-item.active,
.assessment-dropdown-btn .dropdown .dropdown-item:active,
.assessment-dropdown-btn .dropdown .dropdown-item:hover {
  color: rgb(33, 43, 54);
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  background: -webkit-linear-gradient(
    -225deg,
    rgba(161, 0, 255, 0.1) 0%,
    rgba(0, 186, 255, 0.1) 100%
  );
  background: -ms-linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  /* opacity: 10%; */
}
.takeAssessment-view {
  padding: 0px 32px;
}
.takeAssessment-view .assessment-header button,
.flowButton {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(1, 142, 255);
  border-radius: 20px;
  color: rgb(1, 142, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  margin-right: 1.2rem;
  padding: 0.475rem 1.45rem;
  outline: none !important;
}

.takeAssessment-view .assessment-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(196, 205, 213);
  padding-bottom: 1rem;
}
.takeAssessment-view .assessment-header .title {
  color: rgb(33, 43, 54);
  font-size: 24px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
}
.takeAssessment-view .assessment-body {
  margin-top: 20px;
}
/* .takeAssessment-view .assessment-body .assessment-tab {
  background: #fff;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  padding: 20px 40px 20px 50px;
  margin-right: 0px !important;
  min-width: 33.7%;
  align-items: flex-start;
  position: relative;
} */
/* .takeAssessment-view .assessment-body .rwt__tablist {
  margin-bottom: 22px;
  box-shadow: 7px 2px 16px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: #fff;
} */
.takeAssessment-view .assessment-body .assessment-tab:after {
  background: none !important;
}
.takeAssessment-view .assessment-body .assessment-tab:hover,
.takeAssessment-view .assessment-body .assessment-tab:active,
.takeAssessment-view .assessment-body .assessment-tab.active,
.takeAssessment-view .assessment-body .assessment-tab:focus {
  color: rgb(1, 143, 255);
  background-color: #fff !important;
  font-weight: 900;
}
.takeAssessment-view .assessment-body .visited {
  color: rgb(126, 195, 68);
  font-weight: 900;
}
/* .takeAssessment-view .assessment-body .tabBar:after {
  top: 10% !important;
  content: "";
  position: absolute;
  right: 0 !important;
  left: auto !important;
  width: 53px !important;
  height: 53px;
  background: #999;
  -webkit-transform: rotate(136deg);
  -moz-transform: rotate(136deg);
  -o-transform: rotate(136deg);
  -ms-transform: rotate(136deg);
  transform: rotate(136deg);
  box-shadow: -5px -4px 6px -4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -5px -4px 6px -4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -5px -4px 6px -4px rgba(0, 0, 0, 0.1);
  z-index: 99;
} */
.takeAssessment-view .assessment-body .client-details {
  justify-content: center;
  margin: 32px 0;
}

.nextButton {
  background: rgb(1, 142, 255);
  color: rgb(255, 255, 255);
}
.takeAssessment-view .assessment-body .rightMargin {
  margin-right: -8px;
  padding-right: 20px;
}
.takeAssessment-view .offering-list .analysisFull-wrap {
  height: 100%;
  margin: 0 0 12px;
}
.takeAssessment-view .offering-list .analysisFull {
  height: 100%;
}
.takeAssessment-view .assessment-body .analysisFull {
  padding: 19px 12px;
  display: flex;
}
.takeAssessment-view .assessment-body .analysisFull .analysisFull-header {
  display: flex;
}
.takeAssessment-view .assessment-body .analysisFull .analysisTitle {
  margin: 1px 10px;
}
.takeAssessment-view .assessment-body .button-space {
  display: flex;
  justify-content: space-between;
  padding: 60px 100px;
}
.takeAssessment-view .assessment-body .analysisFull .subOffering-list {
  margin: 26px 0px 26px 21px;
}
.takeAssessment-view .assessment-body .analysisFull .subOffering-list label,
.geography-container .country-list label {
  color: rgb(34, 32, 32);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  /* vertical-align: top; */
  margin-bottom: 0;
}
.takeAssessment-view .assessment-body .analysisFull .subOffering-list input[type="checkbox"],
.geography-container .check-group input[type="checkbox"] {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}
.takeAssessment-view .assessment-body .offering-list {
  display: flex;
  width: 100%;
  justify-content: center;
}
.takeAssessment-view .assessment-body .offering-list .subOffering-list .check-group,
.geography-container .check-group {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.takeAssessment-view .assessment-body .geography-container {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 30px 20px;
  min-height: 260px;
  padding-top: 35px;
}
.takeAssessment-view .assessment-body .geography-container .continent-list {
  display: flex;
  justify-content: center;
}
.takeAssessment-view .assessment-body .geography-container .continent-list .country-list {
  display: flex;
  flex-direction: column;
  padding-left: 25px;
}
.continent-label {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-bottom: 0;
}
.takeAssessment-view .assessment-body .box-group {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  padding: 0 10px 10px;
  margin-bottom: 16px !important;
  color: rgb(99, 115, 129);
}
.takeAssessment-view .assessment-body .box-group:focus,
.takeAssessment-view .assessment-body .box-group:focus-within,
.highlight-box {
  background: rgb(244, 251, 255) !important;
  border: 1.5px solid rgb(1, 142, 255) !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: rgb(1, 142, 255) !important;
}
.highlight-box input {
  background: rgb(244, 251, 255);
}

.box-group select.form-control {
  margin-left: -4px;
  width: calc(100% + 4px);
}
.takeAssessment-view .assessment-body .box-group .form-label {
  /* color: rgb(99, 115, 129); */
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.takeAssessment-view .assessment-body .box-group:focus .form-label {
  color: rgb(1, 142, 255);
}
.takeAssessment-view .assessment-body .box-group .form-control {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  padding: 0;
  border: 0;
  height: auto;
}
.takeAssessment-view .assessment-body .box-group .form-control:focus {
  box-shadow: none;
}
.takeAssessment-view .assessment-body .box-group input[type="text"] {
  height: 17px;
}
.client-logo-img {
  /* background-image: transparent url(../Images/bat_icons/UploadLogo.svg); */
  background: transparent url("./../../Images/bat_icons/UploadLogo.svg") no-repeat scroll 0px 0px;
  width: 21px;
  content: "";
  height: 28px;
  position: absolute;
  top: 14%;
  cursor: pointer;
  left: 4rem;
  /* left: 96.5%;
    top: 70px; */
}
.logo-label {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0px;
  position: absolute;
  top: 30%;
  padding: 4px 22px;
}

/* New BAT landing page header */
.bat-offering-details-screen {
  padding: 14px 24px !important;
}
.bat-offering-details-screen .analyticsFilterHeader {
  display: flex;
  justify-content: space-between;
}
.newbatOffering a.backPush {
  border: 1.5px solid rgb(0, 142, 255);
  color: rgb(0, 142, 255);
  padding: 7px 17px;
}
.newbatOffering a.backPush svg {
  color: rgb(0, 142, 255);
}
.bat-offering-details-screen .newbatOffering .batOfferingOptions {
  background: rgb(255, 255, 255);
  margin-right: 12px;
  cursor: pointer;
}
.bat-offering-details-screen .newbatOffering .batOfferingOptions .batOfferingOptions-span,
.quartileSpan .optionSpan {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 12px 17px 12px;
  border: 1px solid #c4cdd5;
  border-radius: 20px;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.batOfferingOptions-span img {
  margin-right: 5px;
}
.batOfferingOptions span.active,
.batOfferingOptions span:hover,
.contract-dropdown-section .dropdown .btn-primary:hover,
.contract-dropdown-section .dropdown .btn-primary.active,
.contract-dropdown-section .show > .btn-primary.dropdown-toggle {
  background: rgba(1, 142, 255, 0.04);
  border: 1px solid rgb(1, 142, 255) !important;
}
.batOfferingOptions span.no-border {
  border: none !important;
}
.bat-offering-details-screen .assessment-dropdown-btn .dropdown {
  background: linear-gradient(to right, #a100ff 0%, #00baff 100%);
  border-radius: 20px;
  padding: 2px;
}
.bat-offering-details-screen .assessment-dropdown-btn .dropdown .btn,
.bat-offering-details-screen .assessment-dropdown-btn .dropdown .btn:hover,
.bat-offering-details-screen .assessment-dropdown-btn .dropdown .btn:focus,
.bat-offering-details-screen .assessment-dropdown-btn .dropdown .btn:focus:active {
  background: rgb(255, 255, 255) !important;
  color: #8a1bff;
}
.bat-offering-details-screen .assessment-dropdown-btn .dropdown .dropdown-menu a {
  border: none !important;
  border-radius: 3px;
  font-family: graphik;
  font-size: 14px;
  letter-spacing: -0.25px;
  color: #212b36;
}
.bat-offering-details-screen .assessment-dropdown-btn {
  margin: 0px !important;
}
.bat-offering-details-screen .dropdown-arrow-img {
  background: transparent url("./../../Images/angle-right.svg") no-repeat scroll 0px 0px;
  width: 10px;
  content: "";
  height: 6px;
  position: absolute;
  top: 14%;
  cursor: pointer;
  transform: rotate(90deg);
}
/* Assessment Offering selection modal */
.assessmentOfferingModal .justify-content-center.modal-header,
.clientVsConsolidatedModal .justify-content-center.modal-header {
  padding: 28px 32px !important;
}
.assessmentOfferingModal .modal-header .close,
.clientVsConsolidatedModal .modal-header .close {
  /* top: 35px !important; */
  top: 32px !important;
}
.assessmentOfferingModal .offering-assessment,
.clientVsConsolidatedModal .header-modal {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
}
.assessmentOfferingModal .offeringName .rwt__tablist:not([aria-orientation="vertical"]) {
  padding-left: inherit;
}
.assessmentOfferingModal .offeringName .offering-label img {
  height: 21px;
  width: 21px;
  margin-right: 5px;
}
.label-name {
  text-align: initial;
  word-break: break-word;
}
.assessmentOfferingModal .assessment-suboffering {
  background: rgb(255, 255, 255);
  border-radius: 0px;
  border-bottom: 1px solid rgb(223, 227, 232);
  padding: 0.75rem 2rem;
  /* padding-left: 2rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.assessmentOfferingModal .assessment-suboffering .assessment-suboffering-name {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  cursor: pointer;
}
.assessmentOfferingModal .subOfferingName {
  padding-right: 0rem;
  padding-top: 0em;
  padding-left: 0;
}
.assessmentOfferingModal .assessment-suboffering .assessment-suboffering-percent {
  display: inline-flex;
}
.assessmentOfferingModal .assessment-suboffering .assessment-suboffering-percent .percent-label {
  color: rgb(75, 88, 100);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.43px;
  margin-right: 10px;
}
.assessmentOfferingModal .assessment-suboffering .assessment-suboffering-percent .progress {
  width: 11rem;
  height: 0.75rem;
  margin-top: 3px;
  border-radius: 0.5rem;
  color: rgb(75, 88, 100);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.36px;
}
.assessmentOfferingModal
  .assessment-suboffering
  .assessment-suboffering-percent
  .progress
  .progress-bar {
  background: linear-gradient(to right, #e9c6fc 0%, #a100ff 100%);
  border-radius: 0.5rem;
  color: rgb(75, 88, 100);
}
.assessmentOfferingModal .modal-body {
  padding-right: 0em;
}
.assessmentOfferingModal .assessment-suboffering .added-subOffering {
  color: rgb(82, 134, 38);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-right: 0.5rem;
}
.assessmentOfferingModal .assessment-suboffering .add-subOffering-button .btn-primary {
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(1, 142, 255);
  border-radius: 16px;
  color: rgb(1, 142, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  margin-right: 0.4rem;
}
.assessmentOfferingModal .assessment-suboffering .add-subOffering-button .btn-primary.focus,
.assessmentOfferingModal .assessment-suboffering .add-subOffering-button .btn-primary:focus {
  box-shadow: 0 0 0 0.1rem rgba(38, 143, 255, 0.5);
}
.clientPerformanceView .tilecard-top {
  margin-top: -1rem;
}
.clientPerformanceView .edit-btn,
.clientPerformanceView .performance-edit-btn .btn-primary {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(1, 142, 255);
  border-radius: 20px;
  color: rgb(1, 142, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  padding: 8px 30px;
  outline: none;
}
.clientPerformanceView .edit-btn {
  /* margin-right: 1rem; */
}
.clientPerformanceView .performance-edit-btn .dropdown-toggle:after {
  border-bottom: 2px solid rgb(1, 142, 255);
  border-right: 2px solid rgb(1, 142, 255);
}
.performance-edit-btn .dropdown-item {
  color: rgb(99, 115, 129);
}
.performance-edit-btn .dropdown-menu {
  min-width: 8rem;
  padding: 0.6rem 1rem;
}
.performance-edit-btn .dropdown-item.active,
.performance-edit-btn .dropdown-item:active,
.performance-edit-btn .dropdown-item:hover {
  color: rgb(99, 115, 129);
  background-color: rgba(196, 205, 213, 0.2);
}
.clientPerformanceView .performance-edit-btn {
  display: flex;
  justify-content: flex-end;
}
.top-40 {
  padding: 16px 25px;
}

.clientVsConsolidatedModal .offeringName ul,
.assessmentOfferingModal .offeringName ul {
  margin-top: 0.5rem;
  line-height: initial;
}
.clientVsConsolidatedModal .offeringName ul li,
.assessmentOfferingModal .offeringName ul li {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.clientVsConsolidatedModal li img,
.assessmentOfferingModal li img {
  height: 24px;
  width: 24px;
  margin-right: 8px;
  margin-top: -3px;
}
.clientVsConsolidatedModal .offeringName .category-name,
.assessmentOfferingModal .offeringName .category-name {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

.subOffering-list li .logo,
.clientVsConsolidatedModal li .logo {
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
}
.clientVsConsolidatedModal .selected {
  background: rgba(161, 0, 255, 0.1);
  border-radius: 4px;
}
/* Client logo Upload */
.clientlogo-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.clientlogo-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.clientlogo-wrapper .btn {
  background: rgb(249, 250, 251);
  border: 1px solid rgb(213, 218, 223);
  height: 128px;
  width: 128px;
  padding: 8px 20px;
  border-radius: 100%;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  color: #637381;
}
.clientlogo-wrapper img {
  display: block;
  margin: 0 auto 10px;
}
.newOpportunity {
  width: 110px;
  height: 35px;
  padding-top: 5px;
  position: relative;
  background: #e5f3da;
  color: #7ec344;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  text-align: center;
  margin-top: 5px;
  margin-right: 1rem;
}
.newOpportunity:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 10px solid #fff;
  border-left: 55px solid transparent;
  border-right: 55px solid transparent;
}
.assessment-tab[aria-selected="true"]:after,
.assessment-tab:hover:after {
  width: auto !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  left: auto !important;
}
.searchBtn .form-control:focus {
  box-shadow: none !important;
}
.bat-offering-details-screen .assessment-dropdown-btn .dropdown-toggle:after {
  border-bottom: 2px solid #8a1bff;
  border-right: 2px solid #8a1bff;
}
.batOfferingOptions-span svg {
  margin-right: 5px;
}
/* .offeringCategoryModal .btn-primary:not(:disabled):not(.disabled).active:focus,
.offeringCategoryModal .btn-primary:not(:disabled):not(.disabled):active:focus
{
    box-shadow: none;
} */
.offeringName .disabled {
  pointer-events: none;
  opacity: 0.3;
}
.assessmentOfferingModal .offeringName .rwt__tab {
  width: 100%;
  align-items: inherit;
}
/* .assessmentOfferingModal .offering-assessment {
  margin-bottom: 1rem;
} */
/* .offering-label .logo img:hover,
.subOffering-list .logo img:hover,
.active .logo img {
    filter:  brightness(0) invert(1);

} */
.assessmentOfferingModal .offeringName {
  padding-top: 0rem !important;
}
.assessment-suboffering-subname {
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  pointer-events: none;
}
.percent-sublabel {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.child-metric-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bullseye-icon {
  margin-top: 1.25rem;
  margin-right: 0.5rem;
}
/* View Assesment Styles starts here */
.view-assessment {
  position: relative;
  padding: 24px;
}
.view-assessment-header {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  position: relative;
}
.header-list .backBtn {
  border-radius: 20px;
  border: 1.5px solid #008eff;
  height: 40px;
  width: 96px;
  top: -2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 14px;
  color: #008eff;
  cursor: pointer;
  position: relative;
}
.header-list .backBtn:hover {
  color: #fff;
  background-color: #008eff;
}
.header-list .backBtn i {
  margin-right: 8px;
}
.header-list-right {
  display: flex;
  align-items: center;
}
.assessText {
  color: rgb(33, 43, 54);
  font-size: 24px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 30px;
}

.takeAssessBtn {
  width: 162px;
  height: 36px;
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  color: rgb(161, 0, 255);
  letter-spacing: -0.25px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  line-height: 36px;
  display: inline-block;
}
.wrapBtn {
  background: -webkit-linear-gradient(left, #a100ff 0%, #00baff 100%);
  background: linear-gradient(to right, #a100ff 0%, #00baff 100%);
  border-radius: 22px;
  height: 40px;
  display: inline-block;
  padding: 2px;
  margin-left: 16px;
}
.header-list-right .searchFilter input[type="search"] {
  background: #fff
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAIAAABL1vtsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAJUSURBVDhPnZPfb9JQFMcPraysrjAmjIQSNohOQF4gBk1EnPFhTxr9J01MTGaiMVFjRDZHgmT4QJSBQpgDAbtWVgYrpcFbejFQVwx+Hnrv+d7e7/11jknqy6Bj0BX5RlWQACgn61y+yFAmPHIuUxaK8CW5Vy4J06Ym0s5evhcPOi5gQccfC7n2KfU6L6Kl0RyKWXJZFwD63eMO11PUcRMduBPf9NBqfxrNQml+fLN9cAZAOq5e24r4mckFO9XdnXz+WAaT2Xfr7ta63kW1kMqpx3u8ZPDHCLmWefu8eAYLK5v3E4FFrGoQAPV0jkf7d0WM5iPMbCwRd5HQ59PZMtbGEFCtlHoAi+5YyGi+Bh2+4bEDSIdHhSGWNAiuJaLrYrw+FivGWP2+ZYCh2GhgQYPgTtAtgtXu1OKZ2OxL6CuLp1qIQXcxBzRlxr0JCIZW1e5pW4tnoggdNYnI6XUJ1smgRmjW1aSazfBrpYUahvVoMYYAr9OFSqBVTv8YZaExXLZSQ2+x6g6gvJ2AgIXg7aAFXVJh50MBva4B3aPMK5RaYAlHNyisYdRjOSKRMDpNn0++eLevqzEVmTtIPX1fF4dAr29EL5FYHjMus0F992Umf6J2abvTv7bCrrrpXv2n8KvwrcmNdkdRpCQpwHgePbiunn3MRLEPxUo2kyyK0nTyqVBM+GYsKueeoFJC4bTLhIXGoH1YrZa/i10ttDB+79oVt220e0X4nHq239a5/GXxD85xmdcCoXeZL8FHkPZQ4mHUpj5thyvz/7MLDUUo5kp0KOahfwPCFwI2lQhyzAAAAABJRU5ErkJggg==)
    no-repeat scroll 20px !important;
  padding: 5px 30px 5px 40px !important;
  background-size: 14px !important;
  font-size: 14px;
  height: 40px;
  line-height: 13px;
  width: 280px !important;
}
.view-assessment-accordion,
.metric-accordion {
  position: relative;
  padding-top: 24px;
}
.metric-accordion {
  position: relative;
}
.view-assessment-accordion #accordion,
.metric-accordion .metricView-main-accordion {
  border: none;
  max-height: none;
}
.view-assessment-accordion #accordion .card,
.metric-accordion .metricView-main-accordion .card {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  margin-bottom: 12px;
  padding: 2px;
}
.view-assessment-accordion #accordion .card .card-header,
.metric-accordion .metricView-main-accordion .card .card-header {
  border: none;
  background: rgb(255, 255, 255);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px 12px 54px;
}
.view-assessment-accordion #accordion .card .card-body {
  padding: 0 20px 0px 46px;
  position: relative;
}
.header-content .client-icon,
.client-no-icon {
  width: 32px;
  height: 32px;
  margin-right: 20px;
  background: linear-gradient(-180deg, rgb(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
  display: inline-block;
  border-radius: 50%;
}

.client-icon-img {
  width: 32px;
  height: 32px;
  margin-right: 20px;
}
.header-content {
  display: flex;
  align-items: center;
}
.header-content h6 {
  margin: 0;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
}
.header-content p {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  margin: 0;
}
.header-right-info span {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 20px;
}
.header-right-info {
  display: flex;
  align-items: center;
}

.header-right-info span {
  margin-left: 18px;
}
.card-header .card-link {
  position: relative;
}

.card-link::before,
.card-link.collapsed::before {
  content: "\F0DA";
  position: absolute;
  top: 45%;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #637381;
  line-height: 2px;
  left: -22px;
  transform: rotate(0deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}
.view-assessment-accordion .card-link::before,
.view-assessment-accordion .card-link.collapsed::before,
.metric-accordion .card-link::before,
.metric-accordion .card-link.collapsed::before {
  display: block;
}
.card-link::before {
  transform: rotate(90deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}
.card-body .offerings-container {
  padding: 20px 0 0 0;
}
.card-body .offerings-container h5 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.29px;
  margin: 0 0 0 8px;
  padding-bottom: 12px;
}
.offerings-list-group .list-item h4 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.3px;
  margin: 0px;
  line-height: 16px;
}
.offerings-list-group .list-item .last-modified,
.offerings-list-group .list-item .data-capture {
  color: rgb(99, 115, 129);
  font-size: 10px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin: 0;
}
.offerings-list-group .list-item .data-capture {
  padding-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.offerings-list-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.offerings-list-group .wrapBox {
  width: calc(25% - 16px);
  margin: 8px;
}
.offerings-list-group .list-item {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 15px 14px;
  position: relative;
  cursor: pointer;
}

.offerings-list-group .wrapBox:hover {
  background: -webkit-linear-gradient(left, #a100ff 0%, #00baff 100%);
  background: linear-gradient(to right, #a100ff 0%, #00baff 100%);
  border-radius: 10px;
  display: inline-block;
  padding: 2px;
}
.offerings-list-group .wrapBox:hover .list-item {
  padding: 8px 13px 12px;
}
.hover-delete-icon {
  display: none;
}
.offerings-list-group .wrapBox:hover .list-item .hover-delete-icon {
  display: inline-block;
  color: #008eff;
  position: absolute;
  right: 13px;
  top: 4px;
}
.react-progress-bar {
  width: 66%;
}
.react-progress-bar .progress {
  height: 10px;
  align-items: center;
}
.react-progress-bar .progress .progress-bar {
  height: 10px;
  /* color: rgb(255, 255, 255); */
  color: rgb(75, 88, 100);
  font-size: 9px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.36px;
  display: flex;
  align-items: center;
  flex-direction: inherit;
}
.react-progress-bar .greenGradient .progress-bar {
  background: linear-gradient(0deg, #b2dcff 0%, #018eff 100%);
  background: -webkit-linear-gradient(0deg, #b2dcff 0%, #018eff 100%);
  background: -ms-linear-gradient(0deg, #b2dcff 0%, #018eff 100%);
}
.no-assessment {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
}
.no-assessment .addedyet {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 24px;
}
.no-assessment .addedyet::before {
  background: rgb(244, 246, 248);
  height: 94px;
  width: 94px;
  content: "";
  display: block;
  border-radius: 50%;
  margin: auto;
}
.no-assessment .continue {
  color: rgb(1, 142, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.21px;
  line-height: 14px;
  cursor: pointer;
}
/* View Assesment Styles ends here */
/* .batView .gray-clr .outcome-divider  */
.applyGreen .outcome-indicate::before,
.applyGreen .progress-bar {
  background: #7ec344 !important;
}
.applyRed .outcome-indicate::before {
  background: #d5002f !important;
}
.applyAmber .outcome-indicate::before {
  background: #ffc82c !important;
}
/* Metric-level-view style Start */
.Metric-level-view .batOfferingOptions-span img {
  margin-top: -6px;
}
.Metric-level-view .newbatOffering a.backPush {
  border: 1px solid #c4cdd5;
  color: #637381;
  padding: 8px 17px;
}
.Metric-level-view .newbatOffering a.backPush svg {
  color: rgb(145, 158, 171);
}
.metric-offering-subOffering {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.offering-suboffering-name {
  color: rgb(33, 43, 54);
  font-size: 24px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 24px;
}
.Metric-level-view .suboffering-percent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Metric-level-view .percent-label {
  color: rgb(75, 88, 100);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.43px;
  line-height: 14px;
}
.px-24 {
  padding: 24px 12px;
}
.metric-tab-container {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 16px;
  padding: 24px 16px 24px 32px;
  min-height: 707px;
  position: relative;
}
.tab-header {
  display: flex;
  justify-content: space-between;
}
.metric-tab-container .tab-header .rwt__tab {
  font-weight: 500;
  padding-bottom: 24px;
  line-height: 14px;
}
.metric-tab-container .tab-header .rwt__tab:after {
  height: 8px;
  border-radius: 4px;
}
.no-margin {
  margin: 0 !important;
}
.metric-box {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
  padding: 1rem !important;
  margin: 12px 0px;
}
.metric-box h4 {
  /* width: 262px;
height: 32px; */
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.29px;
  margin-bottom: 0;
  min-height: 40px;
}
.metric-value {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  padding: 0px 9px 4px 16px;
  margin: 0 !important;
}
.computed-view-box-wrap .metric-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.metric-value .form-label {
  color: rgb(99, 115, 129);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-bottom: 0;
}
.metric-value input {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  border: none;
  line-height: 24px;
  height: 24px;
}

.box-BOI {
  margin: 12px 0px;
}
.view-divider {
  border: 0;
  margin-right: 8px;
  width: 8px;
  height: 112px;
  background: rgb(145, 158, 171);
  border-radius: 4px;
}
.view-box {
  width: 100%;
}
.view-box .view-box-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* width: 262px; */
  min-height: 42px;
  margin-bottom: 9px;
}
.view-box-title h5 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  margin-bottom: 0;
  padding-right: 10px;
}
.view-box-title img {
  cursor: pointer;
}
.metric-card-body {
  display: flex;
  flex-wrap: wrap;
}
.card-header .suboffering-percent {
  width: 24%;
}
.Metric-level-view .card-body {
  display: flex;
  flex-wrap: wrap;
}
/* .box-BOI::before {
  position: absolute;
  content: "";
  background: rgb(145, 158, 171);;
  width: 8px;
  height: 100%;
  border-radius: 4px;
  left: 0;
} */
.view-box::before {
  position: absolute;
  content: "";
  background: linear-gradient(-225deg, rgb(249, 250, 251) 0%, rgb(196, 205, 213) 100%);
  width: 8px;
  height: 100%;
  border-radius: 4px;
  left: -4px;
}
.computed-view-box-wrap .view-box::before {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
}
.view-box-wrap {
  margin-right: 10px;
}
.computed-view-box-wrap {
  margin-right: 10px;
}
.metric-progress {
  margin-top: 2px;
}
.metric-progress .progress {
  height: 5px;
}
.view-box .disabled {
  background: rgb(249, 250, 251);
  opacity: 1;
}
.view-box .disabled .form-label {
  color: rgb(145, 158, 171) !important;
}
.view-box .disabled input:disabled {
  background: rgb(249, 250, 251) !important;
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.85px;
}
.highlight-box .form-label {
  color: rgb(1, 142, 255);
}
.parameterPopupModal .metric-value label {
  margin-bottom: 0;
}
.parameterPopupModal .metric-value {
  margin-bottom: 16px !important;
}
.parameterPopupModal .modal-footer,
.confirmationModal .modal-footer {
  padding: 16px 30px;
  display: flex;
}
.parameterPopupModal .modal-footer > :not(:last-child),
.confirmationModal .modal-footer > :not(:last-child) {
  margin-right: 1.25rem;
}
.active-box {
  background: rgb(255, 255, 255) !important;
  border: 1.5px solid rgb(213, 0, 47) !important;
}
.active-box .form-label {
  color: rgb(170, 0, 37) !important;
}
.parameterPopupModal .formula-text {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
}
.confirmationModal .modal-body {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin: 15px 0px 22px;
}
.parameterPopupModal .required {
  color: rgb(213, 0, 47);
}
.box-BOI .highlight-box {
  background: rgb(244, 251, 255);
  border: 1.5px solid rgb(1, 142, 255);
  color: rgb(1, 142, 255) !important;
}
.analysisFull.grayed-out,
.analysisFull.grayed-out:hover {
  pointer-events: none;
}
.analysisFull-wrap.grayed-out,
.analysisFull-wrap.grayed-out:hover {
  cursor: default;
  opacity: 0.5;
  border-color: transparent;
  pointer-events: none;
}
.disabled,
button.flowButton:disabled {
  pointer-events: none;
  opacity: 0.5;
}
.card-header .card-link {
  cursor: pointer;
}
.metric-accordion .metric-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.metric-offering-subOffering .suboffering-percent {
  width: 19.4rem;
  margin-top: 16px;
}
.react-progress-bar {
  position: relative;
}
.progress-percent {
  position: absolute;
  left: 50%;
  font-size: 0.75rem;
  top: 50%;
  line-height: 10px;
  transform: translate(-50%, -50%);
}
.invalidMsg {
  color: rgb(213, 0, 47);
  font-family: Graphik;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: -0.25px;
  margin-top: 5px;
}
.benchmark-dropdown .dropdown-toggle,
.view-dropdown .dropdown-toggle {
  color: #637381 !important;
  font-family: graphik-medium;
  border: 1px solid #c4cdd5;
  border-radius: 20px;
  padding: 11px 17px 11px;
  letter-spacing: -0.25px;
  line-height: 16px;
  font-size: 14px;
  display: inline-block;
  margin-right: 12px;
  cursor: pointer;
  background: #fff;
  box-shadow: none !important;
}
.benchmark-dropdown .dropdown-toggle .active,
.benchmark-dropdown .dropdown-toggle:hover,
.view-dropdown .dropdown-toggle .active,
.view-dropdown .dropdown-toggle:hover,
.view-dropdown .btn-primary:not(:disabled):not(.disabled):active {
  background: rgba(1, 142, 255, 0.04);
  border: 1px solid rgb(1, 142, 255);
}
.benchmark-dropdown .dropdown-toggle:after,
.view-dropdown .dropdown-toggle::after {
  display: none;
}
.benchmark-dropdown .dropdown-item:hover,
.benchmark-dropdown .dropdown-item.active,
.view-dropdown .dropdown-item:hover,
.view-dropdown .dropdown-item.active {
  color: white !important;
  background: #018eff;
  border-radius: 4px;
}
.benchmark-dropdown .show > .btn-primary.dropdown-toggle,
.view-dropdown .show > .btn-primary.dropdown-toggle {
  color: #7b8e98;
  background-color: #fff;
  border-color: #0062cc;
}
.benchmark-dropdown .dropdown-item,
.view-dropdown .dropdown-item {
  color: #637381 !important;
  background: #fff;
  border: none !important;
  padding: 10px;
  border-radius: 0;
  margin-bottom: 5px;
}
.analyticsFilter .withLabel {
  display: flex;
}
.al-1 {
  align-items: center;
}
.refreshBtn {
  cursor: pointer !important;
  pointer-events: auto;
  position: absolute;
  top: 66px;
  right: 55px;
}

/* Metric-level-view style End */

/* BAT Landing page category ribbion Style start */
.simulationView .batOffering-category {
  align-items: center;
  margin-top: 0;
}
.simulationView .assessment-dropdown-btn {
  margin: 0;
}
.simulationView .batOffering-category-containerA {
  display: flex;
  margin-top: 0;
  align-items: center;
}
.simulationView .batOffering-category-list {
  margin-top: 7px;
}
.simulationView .offering-search {
  margin-bottom: -20px;
}

/* BAT Landing page category ribbion Style end */
.multipleToggleGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.multipleToggle {
  border-left: 1px solid #ccc;
  padding-left: 24px;
  margin-left: 16px;
}
div.multipleToggle:nth-child(1) {
  border-left: none;
  padding-left: 0;
  margin-left: 0;
}
.industry-legend-container {
  margin-top: 25px;
}
.industry-legend {
  margin-top: 5px !important;
}
.metric-parameters-modal .invalidMsg {
  margin-top: -11px;
  margin-bottom: 16px;
}
.button-end {
  justify-content: flex-end !important;
}
.assessment-body .button-space .flowButton {
  display: flex;
  align-items: center;
}
.duplicateAssessmentModal .duplicateAssessment {
  font-size: 14px;
  font-family: graphik;
  color: #212b36;
  margin: 5px;
}
.duplicateAssessmentModal .duplicateAssessment span {
  color: #007bff !important;
  margin: 5px;
  cursor: pointer;
}
.modal-dialog.modal-50w {
  width: 50vw;
  max-width: none !important;
}
.autocomplete-client {
  height: 17px;
}
.autocomplete-client div {
  /* width: 100%; */
  display: block !important;
  color: rgb(33, 43, 54);
  z-index: 1;
}
.autocomplete-client input {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  padding: 0;
  border: 0;
  line-height: 24px;
  height: 24px;
  width: 100%;
  position: relative;
  top: -8px;
}
.autocomplete-client .autocomplete-clientname {
  padding: 5px;
}
.legend-dash {
  border-left: 2px dashed;
  font-size: 16px;
  margin-right: 4px;
}
.batOfferingOptions-span-noIcon {
  padding: 4px 17px !important;
}
.client-no-icon {
  margin-right: 10px !important;
}
.bat-info-icon {
  cursor: pointer;
  height: 14px;
  width: 14px;
  margin-top: 5px;
  font-size: 15px;
  color: #919eab !important;
}
.metric-icons-group {
  width: 7%;
}
.batOfferingOptions-span-withIcon {
  padding: 8px 17px !important;
}
.metric-box .param-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 42px;
}
.param-header h4 {
  padding-right: 10px;
  word-break: break-word;
}
.param-header i {
  margin: 0px;
}
/* ISCO Style starts here */
.favorite-btn {
  background: none;
  border: 0 none;
  position: relative;
  font-size: 14px;
  padding: 5px;
  color: #c4cdd5;
  margin-left: auto;
  transition: transform 0.2s, color 0.5s, opacity 0.5s;
}
.favorite-btn .active {
  color: rgb(161, 0, 255);
}
.favorite-btn .fa-star-o:before {
  content: "\F006";
}
.favourite-section .favourite-title {
  margin: 10px 0;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 20px;
}
.otherOfferingAccordion {
  margin-top: 12px;
}
.otherOfferingAccordion .card,
.otherOfferingAccordion .card-header {
  border: none;
  background: transparent;
}
.otherOfferingAccordion .card-link,
.metric-accordion .card-link {
  cursor: pointer;
  position: relative;
}
.otherOfferingAccordion .card-link::before,
.otherOfferingAccordion .card-link.collapsed::before,
.metric-accordion .metricView-main-accordion .card-link::before,
.metric-accordion .metricView-main-accordion .card-link.collapsed::before,
.metric-accordion .metricView-sub-accordion .card-link::before,
.metric-accordion .metricView-sub-accordion .card-link.collapsed::before {
  content: "\F0DA";
  position: absolute;
  top: 50%;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #637381;
  line-height: 2px;
  left: 22px;
  transform: rotate(0deg);
  transition: all 0.2s ease 0.2s;
  display: block;
}
.otherOfferingAccordion .card-link::before {
  transform: rotate(90deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}
.otherOfferingAccordion .card {
  margin-left: -20px;
}
.otherOfferingAccordion .card-header {
  padding-left: 2.5rem;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 20px;
}
.otherOfferingAccordion .card-body {
  padding-top: 12px;
}
/* Change color of progress bar in BAT */
.Metric-level-view .suboffering-percent .progress,
.view-assessment-accordion .progress,
.assessmentOfferingModal .assessment-suboffering .assessment-suboffering-percent .progress {
  background-image: linear-gradient(to right, #fafafa 0%, #e7e7e7 100%);
}
/* Metric View */
.metric-accordion .metricView-main-accordion {
  border: none !important;
  max-height: none;
}
.metric-accordion .metricView-main-accordion .card .collapsed:hover {
  background: linear-gradient(
    to right,
    rgba(161, 0, 255, 0.1) 0%,
    rgba(0, 186, 255, 0.1) 100%
  ) !important;
}
.metric-accordion .metricView-main-accordion .card .card-header {
  padding-left: 45px;
  height: 68px;
}
.metric-accordion .metricView-main-accordion .card {
  padding: 0px;
  border: none;
  margin-bottom: 16px;
  border-radius: 8px !important;
}
.metric-accordion .metricView-main-accordion .card .card-body {
  padding: 0 20px 12px 46px;
  position: relative;
}
.metric-accordion .metricView-main-accordion .card-link::before {
  transform: rotate(90deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}
.metric-accordion .metricView-sub-accordion {
  width: 100%;
}
.metric-accordion .metricView-sub-accordion .card .card-header {
  padding: 12px 20px 12px 30px;
  height: 40px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.25px;
}
.metric-accordion .metricView-sub-accordion .card-link::before,
.metric-accordion .metricView-sub-accordion .card-link.collapsed::before {
  left: 10px;
}
.metric-accordion .metricView-sub-accordion .card-link::before {
  transform: rotate(90deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}
.metricView-main-accordion .header-content {
  width: 76%;
}
.metricView-main-accordion .metricView-sub-accordion .header-content {
  width: 100%;
}
.metricView-main-accordion .active .header-content {
  width: 76%;
}
.metricView-main-accordion .active .header-content h6 {
  background: linear-gradient(-225deg, #a100ff, #018eff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.metric-accordion .metricView-main-accordion .active.card-link::before,
.metric-accordion .metricView-main-accordion .active.card-link.collapsed::before {
  color: rgb(161, 0, 255);
}
.metric-accordion .metricView-sub-accordion .card {
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 8px;
}
.metric-accordion .metricView-sub-accordion .card .collapsed:hover {
  background: transparent !important;
}
.metric-container .react-progress-bar .greenGradient .progress-bar,
.view-assessment-accordion .react-progress-bar .greenGradient .progress-bar {
  background: linear-gradient(to right, #cce8b4 0%, #b2e189 100%);
  background: -ms-linear-gradient(to right, #cce8b4 0%, #b2e189 100%);
}
.metric-container .react-progress-bar .redGradient .progress-bar,
.view-assessment-accordion .react-progress-bar .redGradient .progress-bar {
  background: linear-gradient(to right, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
  background: -ms-linear-gradient(to right, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
}
.collapse-btn-wrap {
  text-align: right;
  line-height: 12px;
}
.collapse-btn-wrap .collapse-btn {
  color: #a100ff;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
  border: none;
  background: transparent;
  outline: none !important;
}
.bat-accordion-view .bat-category-accordion {
  /* margin-top: 12px; */
}
.bat-category-accordion .bat-main-accordion .card,
.bat-category-accordion .bat-main-accordionn .card-header {
  border: none;
  background: transparent;
}

.bat-category-accordion .bat-main-accordion .card-link,
.bat-category-accordion .bat-main-accordion .bat-sub-accordion .card-link {
  cursor: pointer;
  position: relative;
}
.bat-category-accordion .bat-main-accordion .card-link::before,
.bat-category-accordion .bat-main-accordion .card-link.collapsed::before {
  content: "\F0DA";
  position: absolute;
  top: 50%;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #637381;
  line-height: 2px;
  left: 25px;
  transform: rotate(0deg);
  transition: all 0.2s ease 0.2s;
  display: block;
}
.bat-category-accordion .bat-main-accordion .bat-sub-accordion .card-link::before,
.bat-category-accordion .bat-main-accordion .bat-sub-accordion .card-link.collapsed::before {
  content: "\F0DA";
  position: absolute;
  top: 50%;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #637381;
  line-height: 2px;
  left: 45px;
  transform: rotate(0deg);
  transition: all 0.2s ease 0.2s;
  display: block;
}
.bat-category-accordion .bat-main-accordion .card-link::before,
.bat-category-accordion .bat-main-accordion .bat-sub-accordion .card-link::before {
  transform: rotate(90deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}
.bat-category-accordion .bat-main-accordion .card {
  margin-left: -20px;
}
.bat-category-accordion .bat-main-accordion .card-header {
  padding-left: 2.5rem;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 20px;
  background: transparent;
  border: none;
}
.bat-category-accordion .bat-main-accordion .bat-sub-accordion .card-header {
  padding-left: 4rem;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: 20px;
  background: transparent;
  border: none;
}
.bat-category-accordion .bat-main-accordion .card-body {
  padding-top: 2px;
}
.bat-category-accordion .bat-main-accordion .bat-sub-accordion .card-body {
  padding-top: 2px;
  padding-left: 60px;
}
.clientPerformanceView .batOffering-category-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bat-accordion-only-level-one .card-body {
  margin-left: 25px;
}
.clientVsConsolidatedModal .offeringName.clientName .searchBtn input {
  min-width: inherit;
}
.line-graph-legend-span {
  display: inline-block;
  margin-right: 5px;
  margin-left: 20px;
  width: 3.125rem;
  height: 0.625rem;
  border-radius: 12px;
}
.bat-chart-modal .bat-options-container {
  align-items: center;
}
.bat-chart-modal .form-group.dcwise-checkbox {
  margin: 0;
}
.searchBtn-result-ul {
  max-width: 370px;
  overflow-y: auto;
}
/* ISCO Style ends here */

/* TVE CSS starts here */
.analyticsFilterHeader .newbatOffering .fa-filter {
  font-weight: 500;
  margin-left: 0.5rem;
}
.quartileSpan {
  background: transparent;
  cursor: pointer;
  margin-left: 16px;
}
.tve-box {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0 !important;
  margin: 12px 10px 12px 0px;
  max-width: 24% !important;
}
.metric-accordion .tve-box {
  margin: 12px 10px 12px 0px;
}
.tve-box .tve-box-title {
  min-height: 32px;
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 16px;
  margin-bottom: 8px;
}
.tve-box .tve-box-value {
  display: flex;
  align-items: center;
}
.tve-box .metric-unit {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-left: 4px;
}
.tve-icon-group {
  border-top: 1px solid rgb(223, 227, 232);
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-top: 16px; */
  /* margin: 16px -12px 0px -12px; */
}
.tve-icon-group span {
  /* padding: 10px 14px; */
}
.tve-icon-group .vm_tooltip:hover,
.tve-icon-group .calculator_tooltip:hover,
.tve-icon-group .cardInfo_tooltip:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
}
.tve-icon-group img {
  height: 15.4px;
  width: 15.4px;
  /* margin-right: 16px; */
}
.tve-icon-group img.enlarged {
  height: 20px;
  width: 20px;
}
.tve-icon-group img:last-child {
  margin-right: 0;
}
.tve-box:before {
  position: absolute;
  content: "";
  background: linear-gradient(-225deg, rgb(249, 250, 251) 0%, rgb(196, 205, 213) 100%);
  background: -ms-linear-gradient(-225deg, rgb(249, 250, 251) 0%, rgb(196, 205, 213) 100%);
  width: 4px;
  height: 100%;
  border-radius: 2px;
  left: 0px;
  top: 0;
}
.tve-box.tve-box-green:before {
  background: linear-gradient(
    -225deg,
    rgb(184, 214, 158) 0%,
    rgb(126, 195, 68) 51%,
    rgb(126, 195, 68) 100%
  );
  background: -ms-linear-gradient(
    -225deg,
    rgb(184, 214, 158) 0%,
    rgb(126, 195, 68) 51%,
    rgb(126, 195, 68) 100%
  );
}
.tve-box.tve-box-red:before {
  background: linear-gradient(
    -225deg,
    rgb(242, 178, 192) 0%,
    rgb(213, 0, 47) 52%,
    rgb(170, 0, 37) 100%
  );
  background: -ms-linear-gradient(
    -225deg,
    rgb(242, 178, 192) 0%,
    rgb(213, 0, 47) 52%,
    rgb(170, 0, 37) 100%
  );
}
.tve-box-container {
  /* padding-left: 4px; */
  padding: 16px 12px 16px 16px;
}

.value-box,
.close-icon {
  position: relative;
  /* padding: 5px; */
}
.value-box {
  width: 100%;
  border: 1px solid #ccc;
  outline: 0;
  border-radius: 4px;
  /* width: 134px;
height: 16px; */
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.31px;
  line-height: 16px;
  padding: 9px 32px 9px 8px;
}
.value-box:focus {
  background: rgb(244, 251, 255);
  border: 1.5px solid rgb(1, 142, 255);
}
.close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}
.close-icon:after {
  content: "X";
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 1px;
  bottom: 0;
  margin: auto;
  padding: 2px;
  border-radius: 50%;
  text-align: center;
  color: rgb(99, 115, 129);
  font-weight: normal;
  font-size: 10px;
  cursor: pointer;
}
.value-box:not(:valid) ~ .close-icon {
  display: none;
}

.vm_tooltip,
.calculator_tooltip,
.cardInfo_tooltip {
  position: relative;
  display: inline-block;
  padding: 10px 14px;
  cursor: pointer;
}

.vm_tooltip .vm_tooltiptext,
.vm_tooltip .vm_tooltiptext-nodata,
.calculator_tooltip .calculator_tooltiptext,
.cardInfo_tooltip .cardInfo_tooltiptext {
  visibility: hidden;
  width: max-content;
  /* width: 120px; */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 8px;
  /* width: 119px;
height: 11px; */
  color: rgb(255, 255, 255);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 11px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}
.vm_tooltip .vm_tooltiptext::after,
.vm_tooltip .vm_tooltiptext-nodata::after,
.calculator_tooltip .calculator_tooltiptext::after,
.cardInfo_tooltip .cardInfo_tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.vm_tooltip:hover .vm_tooltiptext,
.vm_tooltip:hover .vm_tooltiptext-nodata,
.calculator_tooltip:hover .calculator_tooltiptext,
.cardInfo_tooltip:hover .cardInfo_tooltiptext {
  visibility: visible;
}
.calculator_tooltip .calculator_tooltiptext {
  left: 33%;
}
.vm_tooltip .vm_tooltiptext {
  left: 60%;
}
.cardInfo_tooltip .cardInfo_tooltiptext {
  margin-left: -57px;
}
.vm_tooltip-detail {
  margin-top: 5px;
}
.vm_tooltip-detail .vm_tooltip-label {
  font-size: 9px;
  line-height: 18px;
}
.vm_tooltip .vm_tooltiptext-nodata {
  width: 123px;
  text-align: left;
}
.vm_tooltip .vm_tooltiptext-nodata .vm_tooltip-detail {
  font-size: 9px;
}
.batOffering-category-title .header {
  font-family: Graphik-Semibold;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.44px;
  line-height: 20px;
}
.batOffering-contractSpan {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: right;
  letter-spacing: -0.35px;
  line-height: 20px;
}
.batOffering-tab-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.batOffering-tab-holder .nav {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(194, 205, 214);
  border-bottom: 1px solid rgb(194, 205, 214) !important;
  margin: 0;
}
.batOffering-tab-holder .nav-link {
  padding: 12px 24px;
  margin: 0;
  color: rgb(95, 115, 131);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}
.batOffering-tab-holder .tablist:not([aria-orientation="vertical"]) .nav-link[aria-selected="true"],
.batOffering-tab-holder .nav-link[aria-selected="true"],
.batOffering-tab-holder .nav-link:hover,
.batOffering-tab-holder .nav-link:focus,
.batOffering-tab-holder .nav-link[aria-selected="false"].nav-link:hover {
  color: rgb(0, 142, 255);
  background-color: rgb(233, 246, 255) !important;
  border-radius: 4px 0px 0px 4px;
}
.bat-tab-content.tab-content {
  background: transparent;
}
.tve-card-container {
  width: 360px !important;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  border-radius: 8px;
}
.tve-card-wrap::before {
  position: absolute;
  content: "";
  /* background: linear-gradient(-225deg, rgb(249, 250, 251) 0%, rgb(196, 205, 213) 100%); */
  background: rgb(145, 158, 171);
  width: 4px;
  height: 100%;
  border-radius: 4px 0px 0px 4px;
  left: 0px;
}
.tve-card-wrap.tve-card-wrap-green::before {
  background: rgb(126, 195, 68);
}
.tve-card-wrap.tve-card-wrap-red::before {
  background: rgb(213, 0, 47);
}
.tve-card-wrap.tve-card-wrap-amber::before {
  background: rgb(255, 200, 44);
}
.tve-card-container .tve-card {
  padding: 16px 8px 16px 16px;
  position: relative;
}
.value-row,
.value-row-B {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.tve-card-title {
  min-height: 32px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  height: 16px;
  letter-spacing: 0px;
  line-height: 16px;
  margin-bottom: 2px;
}
.tve-card-container .text-label {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: "graphik";
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0px;
  margin-bottom: 3px;
}
.tve-card-container .text-value {
  color: rgb(99, 115, 129);
  font-family: Graphik-Semibold;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 28px;
  /* width: fit-content; */
}
.value-section {
  /* width: 50%; */
  margin-bottom: 10px;
  height: 40px;
}
.value-section.bold-label {
  height: fit-content;
}
.amber-highlight {
  background: rgb(255, 222, 129);
  color: rgb(33, 43, 54);
  border-radius: 4px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  width: auto;
  display: inline-block;
  right: inherit;
  left: inherit;
}
.red-highlight {
  /* width: fit-content; */
  width: auto;
  display: inline-block;
  right: inherit;
  left: inherit;
  background: rgb(219, 92, 120);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
}
.green-highlight {
  /* width: fit-content; */
  width: auto;
  display: inline-block;
  right: inherit;
  left: inherit;
  background: rgb(126, 195, 68);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
}
.grey-highlight {
  /* width: fit-content; */
  width: auto;
  display: inline-block;
  right: inherit;
  left: inherit;
  background: rgb(145, 158, 171);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
}

.textValue.amber-highlight {
  /* width: fit-content; */
  width: auto;
  display: inline-block;
  right: inherit;
  left: inherit;
  padding: 0px 5px;
}

.text-label-B {
  color: rgb(65, 75, 84);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.27px;
  line-height: 14px;
}
.text-value-B {
  color: rgb(99, 115, 129);
  font-family: Graphik-Semibold;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.31px;
  line-height: 16px;
}
.value-row-B {
  margin-bottom: 14px;
}
.value-row-c .text-value-B {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.value-case-view .tilecard-border .owl-carousel.owl-drag .owl-item {
  width: 298px !important;
}
.value-case-view .fa-angle-double-up {
  margin-right: 5px;
}
.tve-card:hover .card-overlay {
  border-radius: 8px;
  transform: scale(1);
}
.benchmarkModal .modal-header {
  padding: 35px 8px;
  border-bottom: 1px solid rgb(223, 227, 232);
}
.benchmarkModal .header-modal {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.35px;
  line-height: 16px;
}
.benchmarkModal .col-heading {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 14px;
  padding: 24px;
  border-bottom: 1px solid rgb(223, 227, 232);
}
.benchmarkModal ul li label {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-bottom: 0;
  cursor: pointer;
}
/* .benchmarkModal ul li input[type="radio"] {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  cursor: pointer;
} */
.benchmarkModal .modal-body {
  padding: 0;
}
.benchmarkModal .benchmark-col {
  padding: 0;
  border-right: 1px solid rgb(223, 227, 232);
}
.benchmarkModal ul li {
  display: flex;
  align-items: center;
  padding: 14px 24px;
  margin-bottom: 4px;
  margin-top: 2px;
}
.benchmarkModal .modal-header .close {
  top: 28px;
  right: 24px;
}
.benchmarkModal .modal-body .row {
  margin: 0;
}
.benchmarkModal .col-lg-6 ul li {
  margin-left: 16px;
  padding: 17px 24px 17px 8px;
}
.benchmarkModal .col-lg-6 ul li:hover,
.benchmarkModal .col-lg-6 ul li.selected {
  background: rgb(1, 142, 255);
  border-radius: 4px;
  width: 252px;
}
.benchmarkModal .col-lg-6 ul li:hover label,
.benchmarkModal .col-lg-6 ul li.selected label {
  color: rgb(255, 255, 255);
}
.benchmarkModal .benchmark-col ul {
  max-height: 450px;
  overflow-y: auto;
}
.value-case-view button.card-details {
  background-image: url("./../../Images/tve_Icons/VolumetricDetails.svg");
}
.batView .value-case-view .card-overlay {
  padding-top: 0;
}
.value-case-view .card-overlay button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 0 none;
  margin: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.value-case-view .card-overlay button:hover span {
  -webkit-animation: a 0.5s forwards;
  animation: a 0.5s forwards;
}
.value-case-view .card-overlay button span {
  position: absolute;
  background: #1c262f;
  padding: 5px 8px 4px;
  border-radius: 2px;
  color: #fff;
  bottom: 50px;
  transform: scale(0);
  opacity: 0;
  -webkit-animation: b 0.5s forwards;
  animation: b 0.5s forwards;
  font-size: 11px;
  width: 124px;
  left: -38px;
}
.value-case-view .card-overlay button span:after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #1c262f;
  position: absolute;
  left: calc(50% - 10px);
  bottom: -5px;
}
.card-bullseye-span {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
/* .value-case-view .owl-carousel .owl-stage {
  width: 630px !important;
} */
.tilecard-border .value-case-view .owl-carousel.owl-drag .owl-item {
  width: 360px !important;
  margin-bottom: 2rem;
}
/* .metric-accordion .card-body {
  overflow: hidden;
} */
/* tve graph modal css starts here */
.totalValueEnable-fieldSection .fieldSection-title {
  color: rgb(117, 0, 192);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-bottom: 8px;
}
.opportunity-section .textValue {
  color: rgb(255, 255, 255);
  font-size: 22px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.79px;
  margin-bottom: 24px;
}
.opportunity-section .textValue span {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.sales-text {
  color: rgb(99, 115, 129);
  font-family: Graphik-Semibold;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: -0.46px;
  /* margin-bottom: 10px; */
}
.sales-text span {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.33px;
  line-height: 20px;
}
/* .tve-value-section {
  margin-bottom: 24px;
} */
.value-box-tve {
  width: 80%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.31px;
  line-height: 16px;
  padding: 9px 8px;
  /* margin-bottom: 16px; */
  margin-right: 8px;
}
.value-box-tve:focus {
  background: rgba(1, 143, 255, 0.05);
  border: 1.5px solid rgb(1, 143, 255);
  border-radius: 4px;
}
.totalValueEnabledkModal .modal-header {
  padding: 32px 32px 30px;
  border-bottom: 1px solid rgb(223, 227, 232);
}
.totalValueEnabledkModal .header-modal {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.35px;
  line-height: 20px;
}
.totalValueEnabledkModal .modal-header .close {
  top: 24px;
  right: 24px;
}
.totalValueEnabledkModal .modal-body {
  padding: 16px 24px 24px;
}
.deepDive-btn {
  background: rgb(255, 255, 255);
  border: 1.5px solid #a100ff;
  border-radius: 16px;
  color: #a100ff;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
  padding: 8px;
  width: 14.7%;
  cursor: pointer;
}
.deepDive-btn-wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.tve-canvas-container {
  min-height: 427px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
}

.deep-dive-view {
  padding: 24px 22px 29px;
}
.valuation-year-title {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
}
.valuation-year-title input[type="radio"] {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-left: 2px;
}
.valuation-year-title label {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 0;
}
.each-year-section {
  margin-bottom: 32px;
}
.each-year-section input {
  width: 26%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.31px;
  line-height: 16px;
  padding: 9px 8px;
}
.each-year-section span {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-left: 8px;
}
.topQuartile-section span,
.clientValue-section span,
.contractPeriod-section span {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
}
.yearwise-value-section {
  margin: 0 !important;
}
.value-box-yearwise {
  width: 134px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.31px;
  line-height: 16px;
  padding: 10px 8px;
}
.yearwise-value-section span {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-left: 6px;
}
.yearwise-value-section .col-lg-4 {
  padding: 0;
}
.totalValueEnabledkModal .disabled {
  background: transparent;
}
.tve-container-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
}
.tve-header-title {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 18px;
}
.tve-container-btns .save-btn {
  min-width: 71px;
  height: 32px;
  background: #a100ff;
  border-radius: 16px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-left: 8px;
  border: none;
  outline: none;
}
.tve-container-btns .btn-default {
  min-width: 71px;
  height: 32px;
  background: rgb(255, 255, 255);
  /* border: 1.5px solid #a100ff; */
  border-radius: 16px;
  outline: none;
  /* color: #a100ff; */
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
}
.tve-graph-container {
  padding: 10px;
}


.analyticsFilter .chooseOption .calenderSpan span {
  padding: 12px 17px;
}
.metric-accordion .accordion > .card {
  overflow: visible;
}

/* contract dropdown css starts here */
.contract-dropdown-section {
  margin-left: 12px;
}
.contract-dropdown-section .dropdown .btn-primary {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 16px;
  height: 32px;
  padding: 0.625rem 2rem 0.625rem 1rem;
  border: none;
  color: #fff;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  outline: none !important;
  box-shadow: none !important;
  display: flex;
  position: relative;
}
.contract-dropdown-section .dropdown .btn-primary:hover,
.contract-dropdown-section .dropdown .btn-primary.active,
.contract-dropdown-section .dropdown .btn-primary:active,
.contract-dropdown-section .dropdown .btn-primary:focus,
.contract-dropdown-section .show > .btn-primary.dropdown-toggle,
.contract-dropdown-section .btn-primary:not(:disabled):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.35);
  border: none !important;
  color: #fff;
}
.contract-dropdown-section .btn-primary.dropdown-toggle:after {
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}
.contract-dropdown-section .dropdown-menu .dropdown-item {
  color: rgb(99, 115, 129);
  font-family: graphik-medium;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  letter-spacing: -0.25px;
  line-height: 16px;
  font-size: 14px;
  display: inline-block;
  margin-right: 0;
  cursor: pointer;
  text-align: center;
  margin-bottom: 5px;
}
.contract-dropdown-section .dropdown-menu .dropdown-item:hover,
.contract-dropdown-section .dropdown-menu .dropdown-item.active {
  color: #fff !important;
  background: rgba(1, 142, 255, 1);
}
/* contract dropdown css ends here */
.benchmarkModal .form-check-label,
.totalValueEnabledkModal .form-check-label {
  bottom: -3px;
  margin-left: 8px;
}
.benchmarkModal .form-check .checkmark,
.totalValueEnabledkModal .form-check .checkmark {
  height: 20px;
  width: 20px;
}
.benchmarkModal .form-check input[type="radio"] + .checkmark:after,
.totalValueEnabledkModal .form-check input[type="radio"] + .checkmark:after {
  height: 12px;
  width: 12px;
  margin-left: -6px;
  margin-top: -6px;
}
.batView .tilecard-border .owl-carousel .owl-nav .owl-prev:before {
  transform: rotate(0deg);
}
.check-group label.form-check {
  padding-left: 1.5rem;
}
.tve-tooltip-content {
  padding: 8px;
}
.tve-tooltip-content .tve-datalabel {
  line-height: 12px;
  color: rgb(33, 43, 54);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.21px;
  margin-bottom: 8px;
}
.tve-tooltip-content .tve-datalabel:last-child {
  margin-bottom: 0px;
}
.tve-tooltip-content .tve-datalabel .tve-datalabel-value {
  color: rgb(117, 0, 192);
}
/* TVE CSS ends here */
/* VIP LINK CSS Starts here */
.viplink-btn {
  border: none;
  outline: none;
  margin-left: 24px;
}
.viplink-btn,
.viplink-btn:hover {
  color: rgb(255, 255, 255);
}
/* VIP LINK CSS ends here */

/* TVE Enhancement css starts here  */
.info-popup .info-icon {
  margin-right: 24px;
  cursor: pointer;
}
#Info-Overlay-popover {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  border-radius: 8px;
  border: none;
  width: 22.6%;
  z-index: 0;
  margin-left: 24px !important;
}
#Info-Overlay-popover.popover .arrow {
  left: -20px !important;
}
#Info-Overlay-popover .popover-body {
  padding: 16px 14px 16px 22px;
}
#Info-Overlay-popover .info-popup-content .additional-details {
  margin-bottom: 27px;
}
#Info-Overlay-popover .info-popup-content .calculate-metric {
  margin-bottom: 18px;
}
#Info-Overlay-popover .info-popup-content .info-title {
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
}
#Info-Overlay-popover .info-popup-content .info-desc {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 16px;
  margin-top: 4px;
  padding-right: 32px;
}
#Info-Overlay-popover .info-popup-content .form-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
#Info-Overlay-popover .info-popup-content .checkmark:after {
  left: 6px;
  top: 1.5px;
  width: 6px;
  height: 12px;
  border: 1px solid #ffffff;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  position: absolute;
}
#Info-Overlay-popover .info-popup-content .form-check-label {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-left: 8px;
  bottom: -2px;
}
#Info-Overlay-popover .info-popup-content img {
  margin-right: 16px;
}
#Info-Overlay-popover .info-popup-content .info-popup-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#Info-Overlay-popover .info-popup-btns .warning-check {
  display: inherit;
  margin-top: -6px;
}
#Info-Overlay-popover .info-popup-content .got-it-btn-wrap {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(1, 142, 255) 100%);
  border-radius: 16px;
  padding: 2px;
  width: auto;
  right: inherit;
  left: inherit;
  display: inline-block;
  /* margin-right: 43px; */
}
#Info-Overlay-popover .info-popup-content .got-it-btn {
  /* width: 63px;
height: 32px; */
  background: rgb(255, 255, 255);
  border-radius: 16px;
  color: rgb(161, 0, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
  outline: none;
  border: none;
  padding: 8px 14px;
  margin: 0;
}
.value-case-view-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0px 12px;
}
.value-case-view-filter .filter-wrap {
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 2px;
  width: 48.4%;
  cursor: pointer;
}
.value-case-view-filter .filter-wrap:hover,
.value-case-view-filter .filter-wrap.active {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
}
.value-case-view-filter .filter-wrap.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.value-case-view-filter .value-case-view-filter-tab {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0px 40px 0px 22px;
  border-radius: 8px;
  justify-content: space-between;
}
.value-case-view-filter .value-case-view-filter-tab img {
  height: 92px;
}
.value-case-view-filter .filter-wrap.active .value-case-view-filter-tab {
  background: transparent;
}
.value-case-view-filter-tab h6 {
  color: rgb(46, 56, 66);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
  margin-bottom: 16px;
}
.value-case-view-filter-tab .filter-range-value {
  font-size: 30px;
  color: rgb(117, 0, 192);
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 30px;
}
.filter-wrap.active .value-case-view-filter-tab .filter-range-value,
.filter-wrap.active .value-case-view-filter-tab h6 {
  color: rgb(249, 250, 251);
}
.text-value .fa-arrow-circle-up {
  font-size: 15px;
  margin-right: 6px;
  color: rgb(99, 115, 129);
}
.grey-arrow .fa-arrow-circle-up {
  color: rgb(99, 115, 129);
}
.green-arrow .fa-arrow-circle-up {
  color: rgb(126, 195, 68);
}
.amber-arrow .fa-arrow-circle-up {
  color: rgb(255, 222, 129);
}
.red-arrow .fa-arrow-circle-up {
  color: rgb(219, 92, 120);
}
.tve-metric-box-section {
  width: 18.54%;
  margin-right: 14px;
}
.tve-metric-box-section:last-child {
  margin-right: 0;
}
.value-box-wrap {
  padding: 16px 14px;
  /* width: 146px;
height: 206px; */
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
}
.tve-metric-value {
  padding: 10px 16px 13px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  margin-bottom: 16px;
  color: rgb(99, 115, 129);
}
.tve-form-label {
  line-height: 11px;
  margin-bottom: 6px;
}
.tve-metric-value .form-label {
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin: 0;
}
.tve-metric-value:focus,
.tve-metric-value:focus-within {
  background: rgb(244, 251, 255);
  border-radius: 4px;
  border: 1.5px solid rgb(1, 142, 255);
  color: rgb(1, 142, 255);
}
.tve-metric-value input[type="text"] {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 0px;
  width: 100%;
  border: none;
  outline: none;
  display: block;
}
.tve-metric-value:focus input[type="text"],
.tve-metric-value:focus-within input[type="text"] {
  background: rgb(244, 251, 255);
}
.tve-enabled-text {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.31px;
  line-height: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  word-break: break-all;
}
.tve-enabled-box .text-label {
  color: rgb(65, 75, 84);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  /* letter-spacing: 0.43px; */
  line-height: 12px;
  margin-bottom: 4px;
}
.year-label {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
  margin-bottom: 8px;
}
.tve-up-arrow {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid #e9f6ff;
}
.applyPurple {
  color: #7500c0;
}
/* TVE Enhancement css ends here  */

/* Assessment collaboration CSS starts here  */
.collab-card-body .wrapBox {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.collab-card-body .offerings-list-group .list-item {
  box-shadow: none;
  border-radius: 8px 8px 0px 0px;
}
.collab-card-body .list-footer {
  border-top: 1px solid rgb(223, 227, 232);
  background: #fff;
  height: 37px;
  padding: 5px 16px;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collab-card-body .list-footer .bg-user {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  height: 27px;
  width: 27px;
  border-radius: 50%;
  margin-left: 5px;
  display: inline-block;
  text-align: center;
  /* vertical-align: bottom; */
}
.collab-card-body .list-footer .bg-user:first-child {
  margin-left: 9px;
}
.collab-card-body .list-footer .userid-span {
  color: rgb(33, 43, 54);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
}
.collab-card-body .list-footer .bg-user .userid-span {
  display: inherit;
}
.footer-left .fa-user {
  color: rgb(1, 142, 255);
  font-size: 14px;
}
.excess-users {
  color: rgb(99, 115, 129);
  font-size: 10px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.25px;
  margin-left: 10px;
}
/* Assessment Collaboration Link Start*/
/* Assessment Collaboration Link Start*/
.shareassessment-dropdown .dropdown-menu {
  right: 0 !important;
  width: 125px;
  left: -30px !important;
  top: 0px !important;
  min-width: 125px;
  padding: 8px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.shareassessment-dropdown .infomore-icon span {
  cursor: pointer;
}
.shareassessment-dropdown .dropdown-item {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  cursor: pointer;
}
.shareassessment-dropdown .dropdown-item i {
  margin-right: 5px;
  color: rgb(99, 115, 129);
}
.shareassessment-dropdown .dropdown-item:hover {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.2)
    linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 4px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
}
.shareassessment-modal .modal-dialog {
  max-width: 598px;
}
.shareassessment-modal .modalheader-title h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
  margin-bottom: 5px;
}
.shareassessment-modal .incentive-count {
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
}
.shareassessment-modal .modal-body {
  padding: 0;
}
.shareassessment-modal .modalheader-title {
  padding: 1rem 2rem;
  margin-top: 10px;
}
.shareassessment-modal .assessment-components {
  padding: 1rem 2rem;
  border-top: 1px solid #dfe3e8;
}
.assessment-selectall .form-check .checkmark {
  border-radius: 4px;
  border: 1px solid rgb(1, 142, 255);
  height: 20px;
  width: 20px;
}
.assessment-selectall {
  margin-bottom: 1rem;
  padding: 0rem 0.8rem;
}
.assessment-selectall .form-check-label {
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 2px;
}
.assessment-selectall .checkmark:after {
  left: 6px;
  top: 2px;
}
.shareassessments-list li {
  border-radius: 5px;
  border: 1px solid rgb(223, 227, 232);
  height: 56px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem 0.8rem;
  margin-right: 1rem;
}
.shareassessments-list li.labelselected {
  background: rgb(244, 251, 255);
  border: 1.5px solid rgb(1, 142, 255);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}
.shareassessments-list {
  height: 400px;
  overflow-y: auto;
}
.shareassessments-list .form-check .checkmark {
  border-radius: 4px;
  border: 1px solid rgb(1, 142, 255);
  height: 14px;
  width: 14px;
}
.shareassessments-list .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4.5px;
  height: 8px;
}
.assessmentcount-footer.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}
.assessmentcount-footer .modal-btn .btn {
  height: 40px;
}
.view-assessment-accordion #accordion {
  overflow: visible;
}
.selected-assessmentcount span {
  color: rgb(145, 158, 171);
  font-family: "graphik";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
}
.assessmentinfo-fields {
  display: flex;
  padding: 0 2rem;
  width: 100%;
  margin-bottom: 1rem;
}
.assessmentinfo-fields .people-assessmentinfo {
  width: 70%;
  margin-right: 10px;
}
.assessmentinfo-fields .permission-assessmentinfo {
  width: 30%;
}
.permission-assessmentinfo .custom-dropdown-search ul {
  max-height: none;
  overflow: auto;
}
.shareassessment-modal .modal-body {
  padding: 0;
}
.shareassessment-modal .modalheader-title {
  padding: 1rem 2rem;
  margin-top: 10px;
}
.shareassessment-modal .assessment-components {
  padding: 1rem 2rem;
  border-top: 1px solid #dfe3e8;
}
.assessment-selectall .form-check .checkmark {
  border-radius: 4px;
  border: 1px solid rgb(1, 142, 255);
  height: 20px;
  width: 20px;
}
.assessment-selectall {
  margin-bottom: 1rem;
  padding: 0rem 0.8rem;
}
.assessment-selectall .form-check-label {
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 2px;
}
.assessment-selectall .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
}
.shareassessments-list li {
  border-radius: 5px;
  border: 1px solid rgb(223, 227, 232);
  height: 56px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem 0.8rem;
  margin-right: 1rem;
}
.shareassessments-list li.labelselected {
  background: rgb(244, 251, 255);
  border: 1.5px solid rgb(1, 142, 255);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}
.shareassessments-list {
  height: 400px;
  overflow-y: auto;
}
.shareassessments-list label.form-check {
  display: block;
}
.shareassessments-list .form-check .checkmark {
  border-radius: 4px;
  border: 1px solid rgb(1, 142, 255);
  height: 14px;
  width: 14px;
  top: 9px;
}
.view-assessment-accordion #accordion {
  overflow: visible;
}
.selected-assessmentcount span {
  color: rgb(145, 158, 171);
  font-family: "graphik";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
}
.assessmentinfo-fields {
  display: flex;
  padding: 0 2rem;
  width: 100%;
  margin-bottom: 1rem;
}
.assessmentinfo-fields .people-assessmentinfo {
  width: 70%;
  margin-right: 10px;
}
.assessmentinfo-fields .permission-assessmentinfo {
  width: 30%;
}
.permission-assessmentinfo .custom-dropdown-search ul {
  max-height: none;
  overflow: auto;
}
/* Assessment Collaboration Link ends here */
.footer-right .infomore-icon img {
  height: 3px;
}
.userlist-tooltip {
  position: relative;
  display: inline-block;
}
.userlist-tooltip .userlist-tooltiptext {
  visibility: hidden;
  width: 270px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
  color: #fff;
  text-align: center;
  padding: 7px 10px;
  position: absolute;
  z-index: 1000;
  top: 190%;
  left: 50%;
  margin-left: -126px;
}

.userlist-tooltip .userlist-tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent rgb(255, 255, 255) transparent;
}
.userlist-tooltip:hover .userlist-tooltiptext {
  visibility: visible;
}
.bg-user.userlist-tooltip .userlist-tooltiptext {
  top: 120%;
  margin-left: -88px;
  width: 270px;
}
.bg-user.userlist-tooltip .userlist-tooltiptext ul {
  padding: 0;
}
.bg-user.userlist-tooltip .list-user-permission {
  padding-left: 5px;
}
.list-user-name {
  color: rgb(99, 115, 129);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  /* line-height: 12px; */
  /* width: 50%; */
  display: inherit;
  word-break: break-all;
  width: 124px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  text-align: left;
}
.list-user-permission {
  color: rgb(99, 115, 129);
  font-size: 10px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  width: 50%;
  padding-left: 30px;
  display: inherit;
}
.userlist-tooltiptext ul {
  margin: 0;
  overflow-y: auto;
  max-height: 104px;
  padding: 0px 9px;
}
.userlist-tooltiptext li {
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 12px;
  margin-top: 10px;
}
.userlist-tooltiptext li:first-child {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .sharedNotice-modal .modal-dialog,
  .shareSingleassessment-modal .modal-dialog {
    max-width: 598px;
  }
  .bulkSharedNotice-modal .modal-dialog,
  .singleSharedNotice-modal .modal-dialog {
    max-width: 674px;
  }
}
.sharedNotice-modal .modal-body {
  padding: 32px;
}
.sharedNotice-modal .sharedNotice-modalheader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.bulkSharedNotice-modal .modal-body,
.singleSharedNotice-modal .modal-body {
  padding: 0;
}
.bulkSharedNotice-modal .bulkSharedNotice-title,
.singleSharedNotice-modal .singleSharedNotice-title {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.sharedNotice-modal .sharedNotice-modalheader i {
  font-size: 34px;
  font-weight: 300;
  color: #96cd67;
  margin-right: 18px;
}
.bulkSharedNotice-modal .bulkSharedNotice-modalHeader,
.singleSharedNotice-modal .singleSharedNotice-modalHeader {
  padding: 32px 37px 15px 32px;
  display: block;
}

.singleSharedNotice-modal .singleSharedNotice-requestModalFooter {
  padding: 0px 10px 0px 70px;
  display: block;
}

.bulkSharedNotice-modal .modal-header .close,
.singleSharedNotice-modal .modal-header .close {
  top: 37px;
  right: 37px;
}
.bulkSharedNotice-modal .bulkSharedNotice-title i,
.singleSharedNotice-modal .singleSharedNotice-title i {
  font-size: 34px;
  font-weight: 300;
  color: #96cd67;
  margin-right: 25px;
}
.sharedNotice-modal .sharedNotice-modalheader h4,
.bulkSharedNotice-modal .bulkSharedNotice-title h4,
.singleSharedNotice-modal .singleSharedNotice-title h4 {
  color: rgb(33, 43, 54);
  font-size: 28px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.49px;
  line-height: 28px;
  margin: 0;
}
.sharedNotice-modal .modal-body h5 {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 28px;
  margin-bottom: 8px;
  max-width: 351px;
}
.bulkSharedNotice-modal .desc-line,
.singleSharedNotice-modal .desc-line {
  /* width: 505px;
height: 56px; */
  color: rgb(99, 115, 129);
  font-size: 18px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.32px;
  line-height: 28px;
  padding-left: 57px;
  padding-right: 46px;
}

.singleSharedNotice-modal .footer-desc-line {
  width: 506px;
  height: 20px;
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-family: Graphik-Regular;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.sharedNotice-modal .modal-body p {
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
  margin: 0;
}
.shareSingleassessment-modal .modal-content {
  overflow: visible;
}
.shareSingleassessment-modal .modal-body {
  padding: 32px 20px 16px 31px;
}
.shareSingleassessment-modal .modalheader-title h4 {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
  margin-bottom: 8px;
}
.shareSingleassessment-modal .modalheader-title .requester-name {
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
  margin-bottom: 8px;
}
.shareSingleassessment-modal .modal-body .form-section {
  padding-right: 30px;
  /* max-height: 55vh;
  overflow-y: auto; */
}
.shareSingleassessment-modal .dropdown-box,
.permission-assessmentinfo .dropdown-box,
.people-assessmentinfo .dropdown-box {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 4px;
  padding: 17px 19px 17px 16px;
  color: rgb(145, 158, 171);
  font-family: "graphik";
  position: relative;
  margin: 8px 0px;
}
.shareSingleassessment-modal .custom-dropdown-search .search-select-list,
.permission-assessmentinfo .custom-dropdown-search .search-select-list {
  top: 56px;
}
.shareSingleassessment-modal .custom-dropdown-search ul li:hover,
.permission-assessmentinfo .custom-dropdown-search ul li:hover,
.people-assessmentinfo .custom-dropdown-search ul li:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 0px;
  color: rgb(33, 43, 54);
  font-weight: 600;
}
.shareSingleassessment-modal .custom-dropdown-search .selectedItem,
.permission-assessmentinfo .custom-dropdown-search .selectedItem,
.people-assessmentinfo .custom-dropdown-search .selectedItem {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.shareSingleassessment-modal .custom-dropdown-search .fa-times {
  position: absolute;
  right: 0;
  top: 4px;
  font-size: 12px;
  color: rgb(33, 43, 54);
  cursor: pointer;
}
.shareSingleassessment-modal .col-sm-8 .custom-dropdown-search .custom-dropdownname:after,
.people-assessmentinfo .custom-dropdown-search .custom-dropdownname:after {
  display: none;
}
.shareSingleassessment-modal .modal-footer {
  padding: 20px 32px;
  display: flex;
}
.shareSingleassessment-modal .flex-btns .btn {
  height: 40px;
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.35px;
  line-height: 16px;
}
.shareSingleassessment-modal .flex-btns .btn-default {
  width: 96px;
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(145, 158, 171);
  border-radius: 24px;
  margin-right: 8px;
}
.shareSingleassessment-modal .flex-btns .btn-primary {
  width: inherit;
  min-width: 120px;
  background: rgb(1, 142, 255);
  border-radius: 24px;
}
.shareSingleassessment-modal .title-with-icon {
  right: 2.5%;
  justify-content: flex-end;
}
.bat-modal-del-row-icon {
  position: absolute;
  top: 32%;
  left: 98%;
  color: rgb(213, 0, 47);
  cursor: pointer;
}

/* React multiselect dropdown css starts here */
.shareassessment-modal #multiselectContainerReact div:first-child {
  display: block !important;
}
.multiple-selectedListItem {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 16px;
  color: rgb(99, 115, 129);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  padding: 10px 16px;
  margin: 8px 0px 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.multiple-selectedListItem .item-ellipse {
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .multiple-selectedListItem:first-child {
  margin-left: 0;
} */
.multiple-selectedListItem .fa-times {
  margin-left: 10px;
  cursor: pointer;
}
.assessment-actiondd .dropdown .dropdown-item:focus,
.assessment-actiondd .dropdown .dropdown-item.active,
.assessment-actiondd .dropdown .dropdown-item:active,
.assessment-actiondd .dropdown .dropdown-item:hover {
  font-weight: 600;
}
.assessment-dropdown-btn.assessment-actiondd {
  margin: 0 0 0 16px;
}
.assessment-tabs-section .nav-tabs {
  margin-top: 1rem;
}
.assessment-tabs-section .nav-tabs .nav-link {
  color: rgb(109, 114, 120);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 14px;
  position: relative;
  margin-left: 39px;
  padding: 0 0 1rem 0;
  border: none;
}
.assessment-tabs-section .nav-tabs .nav-link:first-child {
  margin-left: 0;
}
.assessment-tabs-section .tabBar .nav-tabs .nav-item.show .nav-link,
.assessment-tabs-section .nav-tabs .nav-link.active,
.assessment-tabs-section .nav-tabs .nav-link:focus,
.assessment-tabs-section .nav-tabs .nav-link:hover {
  color: rgb(33, 43, 54) !important;
  border: none;
}
.assessment-tabs-section .tabBar .nav-tabs .nav-item.show .nav-link:after,
.assessment-tabs-section .nav-tabs .nav-link.active:after,
.assessment-tabs-section .nav-tabs .nav-link:focus:after,
.assessment-tabs-section .nav-tabs .nav-link:hover:after {
  content: "";
  background: #018eff;
  left: 0;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  position: absolute;
  bottom: 0;
}
.assessment-tabs-section .view-assessment-accordion {
  padding: 0;
  margin-top: 16px;
}
.bulkSharedNotice-modal .modal-userlist,
.singleSharedNotice-modal .modal-userlist {
  padding: 18px 89px;
  border-top: 1px solid rgb(223, 227, 232);
  border-bottom: 1px solid rgb(223, 227, 232);
}
.singleSharedNotice-modal .modal-userlist {
  border-bottom: none;
}
.table-heading,
.table-body-item {
  display: flex;
  align-items: center;
  width: 100%;
}
.table-body-item {
  margin-top: 8px;
}
.titleTag {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 28px;
}
.list-username-title,
.list-username {
  width: 77%;
}
.list-permission-title,
.list-permission {
  width: 23%;
}
.listItem {
  color: rgb(99, 115, 129);
  font-size: 18px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.32px;
  line-height: 28px;
}
.notePoint {
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  padding: 24px 75px 24px 89px;
}
.seletedUser-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 0 31px 0 24px;
}
.requiredNotice {
  color: rgb(213, 0, 47);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  margin-left: 13px;
}
.requestSent-modal .desc-line {
  margin-top: 12px;
}
.requestSent-modal .notePointCondition {
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  padding: 16px 37px 17px 57px;
}
.shareassessment-modal .permission-assessmentinfo .dropdown-box {
  padding: 11px 19px 11px 16px;
}
.searchBox-Container {
  margin: 8px 0px;
}
.searchBox-Container .searchFilter input[type="search"] {
  height: 44px;
  width: 349px !important;
  border: 1px solid rgb(223, 227, 232);
}
.shareassessment-modal .searchFilter ::placeholder {
  color: rgb(145, 158, 171);
  opacity: 1;
  font-family: Graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
}
.shareassessment-modal .searchFilter :-ms-input-placeholder,
.shareassessment-modal .searchFilter ::-ms-input-placeholder {
  color: rgb(145, 158, 171);
  font-family: Graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
}
.search-list-container {
  width: 100%;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 46px;
  left: 0;
  z-index: 11111;
  padding: 3px;
  padding-bottom: 12px;
  border: 1px solid #ccc;
}
.search-list-container ul {
  margin: 0;
}
.search-list-container ul li {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  padding: 10px 12px 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.searchBox-Container .search-select-list {
  position: absolute;
}
.search-list-container ul li:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 0px;
  color: rgb(33, 43, 54);
  font-weight: 600;
}
.shareassessment-modal .assessmentinfo-fields {
  margin-bottom: 0;
}
.assessment-usericons {
  display: flex;
  align-items: center;
}
.assessment-usericons .assessment-tags {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  height: 27px;
  width: 27px;
  border-radius: 50%;
  margin-left: 5px;
  display: inline-block;
  text-align: center;
  position: relative;
  margin-right: 7px;
}
.assessment-usericons .assessment-tags span {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.assessment-usericons .assessment-excess {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.access-management-screen .notificationrequest-section {
  margin-top: 0;
  padding: 1.25rem;
}
.access-management-screen .notificationrequest-table {
  margin-top: 1rem;
}
.notificationrequest-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.notificationrequest-heading .back-btn {
  border-radius: 20px;
  border: 1px solid #008eff;
  height: 40px;
  width: 95px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #008eff;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}
.notificationrequest-heading .back-btn i {
  margin-right: 10px;
}
.notificationrequest-heading {
  display: flex;
  align-items: center;
}
.notificationrequest-heading h4 {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: -0.35px;
  margin-bottom: 0;
}
.notificationrequest-heading a.navbar-brand {
  margin-right: 1rem;
  padding-right: 0;
}
.notificationrequest-heading a.navbar-brand:after {
  border-right: none;
}
.notificationrequest-header .searchFilter input[type="search"] {
  width: 280px !important;
}
.accessrequest-heading span {
  color: rgb(0, 0, 0);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding-bottom: 10px;
}
.accessrequest-heading span:after {
  content: " ";
  position: absolute;
  background: #018eff;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 5px;
  border-radius: 4px;
}
.accessrequest-heading {
  margin-bottom: 1.5rem;
}
.notificationrequest-table table {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}
.notificationrequest-table .table thead th {
  color: rgb(99, 115, 129);
  font-family: "graphik";
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.35px;
  border-bottom: none;
  padding: 1.5rem 1.5rem 0.5rem;
  position: relative;
}
.notificationrequest-table .table thead th.sortable {
  cursor: pointer;
}
.notificationrequest-table .table tbody tr:first-child td {
  border-top: none;
}
.notificationrequest-table .table tbody td {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.4px;
  padding: 1.5rem;
}
.notificationrequest-table .table tbody td.approved-status,
.notificationrequest-table .table tbody td span.approved-status {
  color: rgb(126, 195, 68);
}
.notificationrequest-table .table tbody td.pending-status,
.notificationrequest-table .table tbody td span.pending-status {
  color: #ffc82c;
}
.notificationrequest-table .table tbody td.decline-status,
.notificationrequest-table .table tbody td span.decline-status {
  color: #d5002f;
}
.assessment-editbtn {
  background: rgb(255, 255, 255);
  border-radius: 24px;
  border: 1px solid rgb(145, 158, 171);
  height: 33px;
  width: 79px;
  padding: 8px 20px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
  cursor: pointer;
}
.extraSubOffering-tooltip {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.extraSubOffering-tooltip .extraSubOffering-tooltiptext {
  visibility: hidden;
  width: 237px;
  background-color: rgb(255, 255, 255);
  color: rgb(99, 115, 129);
  text-align: left;
  border-radius: 16px;
  border: 1px solid rgb(231, 234, 238);
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  padding: 24px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 50%;
  margin-left: -25px;
}
.extraSubOffering-tooltip .extraSubOffering-tooltiptext ul {
  margin: 0;
}
.extraSubOffering-tooltip .extraSubOffering-tooltiptext ul li {
  line-height: 16px;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.4px;
  margin-bottom: 8px;
}
.extraSubOffering-tooltip .extraSubOffering-tooltiptext ul li:last-child {
  margin-bottom: 0;
}
.extraSubOffering-tooltip .extraSubOffering-tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 12%;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent rgb(255, 255, 255) transparent;
}

.extraSubOffering-tooltip:hover .extraSubOffering-tooltiptext {
  visibility: visible;
}
.assessment-tabs-section .sort-arrow {
  height: 8px;
  width: 8px;
  border: solid rgb(99, 115, 129);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  margin-left: 10px;
  position: absolute;
  bottom: 16px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.assessment-tabs-section .sort-arrow.up {
  bottom: 12px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.bat-dropdown-li-active {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: "0px";
  color: "rgb(33, 43, 54)";
  font-weight: 600 !important;
}
.user-account-icon.userlist-tooltip .userlist-tooltiptext {
  top: 135%;
  left: 50%;
  /* margin-left: -90px; */
  margin-left: -60px;
}
.user-account-icon.userlist-tooltip .userlist-tooltiptext::after {
  /* left: 38%; */
  left: 23%;
}
.bg-user.userlist-tooltip .userlist-tooltiptext::after {
  left: 33%;
}
.access-request-table tr td:first-child {
  width: 24%;
}
.access-request-table tr td:nth-child(2) {
  width: 24%;
}
.access-request-table tr td:nth-child(3) {
  width: 14%;
}
.access-request-table tr td:nth-child(4) {
  width: 12%;
}
.access-request-table tr td:nth-child(5) {
  width: 12%;
}
.access-request-table tr td:nth-child(6) {
  width: 14%;
}
.access-request-table .assessment-usericons .assessment-tags {
  display: inline-table;
}
.access-request-table .assessment-usericons span:nth-child(2) {
  word-break: break-all;
}
.access-request-table tr td:nth-child(3) .assessment-usericons {
  flex-wrap: wrap;
}
.access-request-table .extraSubOffering-tooltip .extraSubOffering-tooltiptext ul {
  margin: 0;
  max-height: 160px;
  overflow-y: auto;
}
.access-request-table .extraSubOffering-tooltip .extraSubOffering-tooltiptext ul li {
  font-size: 12px;
  line-height: 12px;
}
.access-request-table .extraSubOffering-tooltip .extraSubOffering-tooltiptext {
  z-index: 999;
  left: 0;
}
/* Assessment collaboration CSS ends here  */

/* Bat TVE Info popup fixed position styles starts here  */
.info-popup {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 7px 11px;
  background: transparent;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  cursor: pointer;
}
.info-popup .info-icon {
  margin-right: 0;
}
#Info-Overlay-popover {
  margin-left: 8px !important;
}
#Info-Overlay-popover.popover .arrow {
  left: -4px !important;
}
*/
/* Bat TVE Info popup fixed position styles ends here  */
/* New Metric Tile Changes and TVE enhancements Start*/
.custom-tilecard .bat-category-accordion .tilecard-wrapper .owl-carousel .owl-item {
  width: 280px !important;
}
.bat-category-accordion .tilecard-wrapper .owl-carousel .owl-item .item .tile-metric-value,
.bat-category-accordion .tilecard-wrapper.no-carousel .item .tile-metric-value {
  font-size: 20px;
}
.bat-category-accordion .tilecard-wrapper .owl-carousel .owl-item .item .tile-metric-value span,
.bat-category-accordion .tilecard-wrapper.no-carousel .item .tile-metric-value span {
  font-size: 14px;
}
.custom-tilecard .metric-details-icon {
  justify-content: flex-end;
  position: absolute;
  top: 12px;
  right: 10px;
}
.custom-tilecard .metric-details-icon i {
  margin-right: 0;
}
.tilequartile-performance {
  margin-bottom: 10px;
}
.tilequartile-performance p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 0;
  letter-spacing: 0px;
  line-height: 14px;
}
.tilequartile-performance span {
  font-weight: bold;
}
.newbatOffering a.subnavheader-back,
.Metric-level-view .newbatOffering a.backPush.subnavheader-back {
  color: #fff;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border: none;
  padding: 0;
  min-width: auto;
  margin-right: 16px;
}
.subnav-header.analyticsFilter .newbatOffering a.subnavheader-back {
  background: transparent;
}
.bat-offering-details-screen.subnav-header .newbatOffering .batOfferingOptions {
  background: transparent;
  margin-right: 1rem;
}
.bat-offering-details-screen.subnav-header
  .newbatOffering
  .batOfferingOptions
  .batOfferingOptions-span,
.analyticsFilter.subnav-header .chooseOption span,
.quartileSpan .optionSpan {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 16px;
  height: 32px;
  padding: 0.625rem 2rem 0.625rem 1rem;
  border: none;
  color: #fff;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  outline: none !important;
  box-shadow: none;
  display: flex;
  position: relative;
}
.bat-offering-details-screen.subnav-header .batOfferingOptions span:hover {
  border: none !important;
}
.bat-offering-details-screen.subnav-header
  .newbatOffering
  .batOfferingOptions
  .batOfferingOptions-span:after,
.analyticsFilter.subnav-header .chooseOption span:after {
  content: "";
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 12px;
  border-color: transparent #ffffff #ffffff transparent;
  border-style: solid;
  border-width: 1px 2px 2px 1px;
  height: 8px;
  width: 8px;
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  color: #212b36;
}

.subnav-header.analyticsFilter a:hover,
.subnav-header.analyticsFilter .chooseOption span:hover,
.subnav-header.analyticsFilter a.active,
.subnav-header.analyticsFilter .chooseOption span.active,
.quartileSpan span:hover,
.quartileSpan span.active {
  background: rgba(0, 0, 0, 0.35);
  border: none;
}
.bat-category-accordion .tilecard-wrapper .owl-carousel .owl-item .item .item-right,
.bat-category-accordion .tilecard-wrapper.no-carousel .item .item-right {
  padding-top: 40px;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
}
.bat-category-accordion .custom-tilecard .tilecard-wrapper {
  margin-bottom: 0;
}
.bat-category-accordion .custom-tilecard .tilecard-wrapper .card-body {
  padding-bottom: 0;
}
.bat-category-accordion .custom-tilecard .tilecard-wrapper .owl-carousel .owl-item {
  margin-bottom: 3rem;
}
.bat-main-accordion .custom-tilecard .tilecard-wrapper .owl-carousel .owl-stage {
  padding: 0;
}
.bat-main-accordion .custom-tilecard .tilecard-wrapper .dropdown .__react_component_tooltip {
  margin-top: 0;
}
.takeAssessment-view .assessment-header .btn-purple,
.metric-offering-subOffering .btn-purple,
.parameterPopupModal .btn-purple,
.batassessment-btn-purple {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-right: 0;
  padding: 0.75rem 1.5rem !important;
  box-shadow: none !important;
  outline: none !important;
}
.takeAssessment-view .assessment-header .btn-purple:hover,
.metric-offering-subOffering .btn-purple:hover,
.parameterPopupModal .btn-purple:hover,
.batassessment-btn-purple:hover {
  background: rgb(179, 51, 255) !important;
  color: rgb(255, 255, 255) !important;
  border-color: rgb(179, 51, 255) !important;
}
.batassessment-btn-purplebg {
  background: rgb(161, 0, 255);
  color: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-right: 0;
  padding: 0.75rem 1.5rem;
  box-shadow: none !important;
  outline: none !important;
}

.takeAssessment-view .assessment-header .btn-purple:disabled,
.metric-offering-subOffering .btn-purple:disabled,
.parameterPopupModal .btn-purple:disabled,
.batassessment-btn-purple:disabled {
  pointer-events: none;
}
.parameterPopupModal .btn-purple-secondary {
  background: rgb(161, 0, 255);
  color: rgb(255, 255, 255) !important;
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-right: 0;
  padding: 0.75rem 1.5rem !important;
  box-shadow: none !important;
}
.takeAssessment-view .assessment-body .rwt__tablist {
  align-items: center;
  justify-content: center;
}
.takeAssessment-view .assessment-body.assessment-counttabs .assessment-tab {
  color: rgb(196, 205, 213);
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.35px;
  margin-right: 185px;
  min-width: 87px;
}
.takeAssessment-view .assessment-body.assessment-counttabs .assessment-tab:before {
  content: " ";
  width: 185px;
  height: 1px;
  background: #979797;
  position: absolute;
  right: auto;
  top: 17px;
  left: 85px;
}
.takeAssessment-view .assessment-body.assessment-counttabs .assessment-tab.visited:before {
  background: #a100ff;
}
.takeAssessment-view .assessment-body.assessment-counttabs .assessment-tab:last-child {
  margin-right: 20px;
}
.takeAssessment-view .assessment-body.assessment-counttabs .assessment-tab:last-child:before {
  display: none;
}
.assessment-body.assessment-counttabs .assessment-tabcount {
  height: 34px;
  width: 34px;
  border: 1px solid #c4cdd5;
  border-radius: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  position: relative;
}
.assessment-body.assessment-counttabs .assessment-tab .assessment-tabcount:before {
  content: "1";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  line-height: 34px;
}
.assessment-body.assessment-counttabs .assessment-tab .assessment-tabcount.tab-one:before {
  content: "1";
}
.assessment-body.assessment-counttabs .assessment-tab .assessment-tabcount.tab-two:before {
  content: "2";
}
.assessment-body.assessment-counttabs .assessment-tab .assessment-tabcount.tab-three:before {
  content: "3";
}
.assessment-body.assessment-counttabs .assessment-tab.visited .assessment-tabcount:before {
  content: "\F00C";
  font-family: "Font Awesome 5 Pro";
}

.takeAssessment-view .assessment-body.assessment-counttabs .assessment-tab:hover {
  color: rgb(196, 205, 213);
  background-color: transparent !important;
}
/* .takeAssessment-view .assessment-body.assessment-counttabs .assessment-tab:active, */
.takeAssessment-view .assessment-body.assessment-counttabs .assessment-tab.active,
.takeAssessment-view .assessment-body.assessment-counttabs .assessment-tab:focus,
.takeAssessment-view .assessment-body.assessment-counttabs .assessment-tab.visited {
  color: rgb(161, 0, 255);
  background-color: transparent !important;
}
/* .assessment-body.assessment-counttabs .assessment-tab:hover .assessment-tabcount,  */
/* .assessment-body.assessment-counttabs .assessment-tab:active .assessment-tabcount, */
.assessment-body.assessment-counttabs .assessment-tab.active .assessment-tabcount,
.assessment-body.assessment-counttabs .assessment-tab:focus .assessment-tabcount,
.assessment-body.assessment-counttabs .assessment-tab.visited .assessment-tabcount {
  border: 1px solid rgb(161, 0, 255);
}
.assessment-body.assessment-counttabs .assessment-tab.visited .assessment-tabcount {
  background: #a100ff;
  color: #ffffff;
}
.assessment-counttab-navigation .btn-purple-secondary {
  background: rgb(161, 0, 255);
  border-radius: 20px;
  padding: 0.5rem 1.25rem;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  outline: none !important;
}
.assessment-counttab-navigation .btn-purple-secondaryoutline {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  padding: 0.5rem 1.25rem;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  min-width: 133px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none !important;
}
.assessment-counttab-navigation .btn-purple-secondaryoutline:hover,
.assessment-counttab-navigation .btn-purple-secondaryoutline:focus {
  background: rgb(161, 0, 255);
  color: #ffffff;
  box-shadow: none;
}
.assessment-counttab-navigation .btn-purple-secondary:hover,
.assessment-counttab-navigation .btn-purple-secondary:focus {
  color: rgb(255, 255, 255);
  box-shadow: none;
  outline: none;
}
.assessment-counttab-navigation .btn-purple-secondary:disabled,
.assessment-counttab-navigation .btn-purple-secondaryoutline:disabled {
  background: rgb(231, 234, 238);
  pointer-events: none;
  color: #ffffff;
  border: none;
}
.assessment-counttab-navigation.counttab-navbtns {
  display: flex;
  align-items: center;
}
.assessment-counttab-navigation.counttab-navbtns p {
  min-width: 345px;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.33px;
  line-height: 18px;
  margin-bottom: 0;
  text-align: center;
}
.assessmenttab-offeringlist .form-check .checkmark {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid rgb(196, 205, 213);
}
.assessmenttab-offeringlist .check-group label.form-check {
  padding-left: 2rem;
}
.assessmenttab-offeringlist input[type="checkbox"].form-check-input:checked ~ .checkmark {
  background-color: #a100ff;
  border-color: #a100ff;
}
.assessmenttab-offeringlist .checkmark:after {
  left: 6px;
  top: 1px;
  width: 6px;
  height: 12px;
}
.offeringCategoryModal.assessmentOfferingModal li:hover,
.offeringCategoryModal.assessmentOfferingModal .active {
  color: white !important;
  background: #a100ff;
}
.assessmentOfferingModal .assessment-suboffering .add-subOffering-button .btn-primary.btn-purple {
  border: 1.5px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
  outline: none !important;
  box-shadow: none !important;
}
.assessmentOfferingModal .assessment-suboffering .added-subOffering .btn-primary.btn-purple,
.assessmentOfferingModal
  .assessment-suboffering
  .added-subOffering
  .btn-primary.btn-purple-outline {
  background: rgb(161, 0, 255);
  border: 1.5px solid rgb(161, 0, 255);
  border-radius: 16px;
  color: #ffffff;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  margin-right: 0.4rem;
  box-shadow: none !important;
  outline: none !important;
  padding: 0.375rem 1.5rem;
}
.assessmentOfferingModal
  .assessment-suboffering
  .added-subOffering
  .btn-primary.btn-purple-outline {
  background: #ffffff;
  color: rgb(161, 0, 255);
  outline: none !important;
  box-shadow: none !important;
}
.assessmentOfferingModal
  .assessment-suboffering
  .added-subOffering
  .btn-primary.btn-purple-outline:hover {
  background: rgb(161, 0, 255);
  color: #ffffff;
}
.metric-tab-container.newmetric-tab-container .tab-header .rwt__tab {
  color: rgb(99, 115, 129);
}
.metric-tab-container.newmetric-tab-container .tab-header .rwt__tab[aria-selected="true"],
.metric-tab-container.newmetric-tab-container .tab-header .rwt__tab:hover {
  color: rgb(33, 43, 54);
}
.metric-tab-container.newmetric-tab-container .tab-header .rwt__tab:after {
  background: #a100ff;
}
.metricView-sub-accordion .header-content h6 {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.tve-box.custom-tvebox {
  min-width: 276px;
}
.custom-tvebox .tve-box-container {
  padding: 16px 12px 16px 20px;
}
.clientPerformanceView {
  padding-top: 40px;
}
.tve-card-partition {
  display: flex;
}

.tve-card-partition .tve-card-partitionleft {
  width: 48%;
}
.tve-card-partition .tve-card-partitionright {
  width: 50%;
}
.tve-card-partitionleft {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px dashed #979797;
}
.tve-card .dropdown.dropleft {
  position: absolute;
  right: 6px;
  bottom: 15px;
  display: flex;
}
.tve-card .dropdown .dropdown-toggle {
  width: 20px;
  height: 20px;
  background: transparent;
  cursor: pointer;
  position: relative;
}
.tve-card .dropdown .insights-new-icon:before {
  position: absolute;
  content: "";
  background: url(./../../Images/insights-new-icon.svg) no-repeat;
  width: 20px;
  height: 20px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border: none;
}
.tve-card:hover .dropdown .insights-new-icon:before {
  background: url(./../../Images/insights-new-icon-hover.svg) no-repeat;
  width: 20px;
  height: 20px;
}
.contractPeriod-section {
  position: absolute;
  bottom: 0;
}
.contractPeriod-section p,
.contractPeriod-section span {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-bottom: 0;
}
.rightendinfo-popover .arrow::before,
.rightendinfo-popover .arrow::after {
  border-right-color: #a100ff !important;
  left: -5px !important;
}
.info-popup-btns .dropdown-toggle {
  background: transparent;
  padding: 0;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  border: none;
  box-shadow: none !important;
}
.info-popup-btns .dropdown-toggle:hover,
.info-popup-btns .dropdown-toggle:focus,
.info-popup-btns .dropdown.show .dropdown-toggle {
  background: transparent;
  color: rgb(161, 0, 255);
  border: none;
  box-shadow: none;
}
.info-popup-btns .dropdown-toggle:after {
  border-bottom: 2px solid rgb(161, 0, 255);
  border-right: 2px solid rgb(161, 0, 255);
}
.info-popup-btns .dropdown-menu a {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 12px 16px;
}
.info-popup-btns .dropdown-menu a:hover,
.info-popup-btns .dropdown-menu a.active {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 0px;
  color: rgb(33, 43, 54);
}
.resetall-button {
  color: rgb(161, 0, 255) !important;
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.43px;
  background: transparent !important;
  padding: 0;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.resetall-button.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.resetAll {
  margin-top: 10px;
  margin-right: -6px;
  position: absolute;
  right: 50px;
  top: 12px;
}
.revenue-text {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 16px;
}
.formulatext-modalheader {
  flex-direction: column;
}
.tableft-navbtns {
  display: flex;
  align-items: center;
}
.dontremind-dropdown .dropdown-menu {
  margin-top: 11px !important;
  margin-left: 11px !important;
  border-radius: 8px;
}
.dontremind-dropdown .dropdown-toggle {
  padding-right: 10px;
}
.dontremind-dropdown.show .dropdown-toggle::after {
  transform: rotate(-135deg);
  top: 4px;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
}
.calenderPopover .popover-footer .modal-btn.temp .btn {
  outline: none !important;
  box-shadow: none !important;
}
.calenderPopover .popContent .react-datepicker__navigation {
  outline: none !important;
}
.dc-clickable-label-container {
  line-height: 12px;
}
.dc-clickable-label {
  color: rgb(1, 142, 255);
  font-size: 13px;
  font-weight: normal;
  letter-spacing: -0.3px;
  cursor: pointer;
}
.dc-clickable-label.selected {
  font-weight: bold;
}
/* DD phase2 enhancement css starts here */
.view-dropdown {
  display: inline-flex;
  margin-left: 12px;
}
.view-dropdown .down-arrow-icon,
.selectDC-span .down-arrow-icon {
  height: 8px;
  width: 8px;
  border: solid rgb(99, 115, 129);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  margin-left: 10px;
  margin-bottom: 2px;
  /* position: absolute;
  bottom: 16px; */
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.benchmarkCategoryTooltipDropdown {
  /* font-size: 14px;
  margin-left: -80px !important; */
  width: 463px !important;
}
.benchmarkCategoryTooltipDropdown.popover .arrow,
.SelectDCDropdown.popover .arrow {
  display: none;
}
.benchmarkCategoryTooltipDropdown .popover-body {
  padding: 1rem;
}
.benchmarkCategoryTooltipDropdown .popContent {
  width: inherit;
  padding: 0;
}
.benchmarkCategoryTooltipDropdown .benchmark-column {
  padding-top: 2px;
  padding-bottom: 2px;
}
.benchmarkCategoryTooltipDropdown .benchmark-column.col-lg-7 {
  padding: 2px 0px 2px 32px;
  border-left: 1px solid rgb(223, 227, 232);
  min-height: 13rem;
}

.benchmarkCategoryTooltipDropdown .benchmark-category-label {
  padding: 10px 16px;
  border-radius: 4px;
  margin-bottom: 5px;
  color: rgb(99, 115, 129);
  font-family: Graphik;
  font-weight: normal;
  cursor: pointer;
}
.benchmarkCategoryTooltipDropdown .benchmark-category-label:hover,
.benchmarkCategoryTooltipDropdown .benchmark-category-label.active {
  background: rgb(239 239 239);
  color: rgb(33, 43, 54);
  font-weight: 900;
}
.benchmarkCategoryTooltipDropdown .benchmark-category-label label {
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 0;
  bottom: 0px;
  font-weight: normal;
  cursor: pointer;
}
.benchmarkCategoryTooltipDropdown .benchmark-category-name {
  color: rgb(33, 43, 54);
  font-size: 14px;
  position: relative;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 12px 0px;
}
.benchmarkCategoryTooltipDropdown .benchmark-category-name svg {
  position: absolute;
  left: -18px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.benchmarkCategoryTooltipDropdown .form-check .checkmark {
  height: 20px;
  width: 20px;
}
.benchmarkCategoryTooltipDropdown .form-check input[type="radio"] + .checkmark:after {
  height: 12px;
  width: 12px;
  margin-left: -6px;
  margin-top: -6px;
}
.benchmarkCategoryTooltipDropdown .form-check-label {
  bottom: -1px;
  margin-left: 8px;
}
.benchmarkCategoryTooltipDropdown .popover-footer {
  padding: 15px 32px;
}
.benchmarkCategoryTooltipDropdown .update-btn {
  width: 73px;
  height: 32px;
  background: rgb(1, 142, 255);
  border-radius: 16px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
}
.benchmarkCategoryTooltipDropdown .clear-btn {
  width: 62px;
  height: 32px;
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(99, 115, 129);
  border-radius: 16px;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
}
.analyticsFilter .chooseOption span.selectDC-span {
  margin-right: 1rem;
}
.SelectDCDropdown {
  margin-left: 90px !important;
}
.SelectDCDropdown .popover-footer {
  padding: 16px;
}
.SelectDCDropdown .popover-body {
  padding: 24px 10px 16px;
}
.SelectDCDropdown .popContent {
  width: 437px;
  padding: 0px 14px;
  max-height: 136px;
  overflow-y: auto;
}
.SelectDCDropdown .check-group {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
}
.SelectDCDropdown .check-group label.form-check {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  /* line-height: 27px; */
  margin-bottom: 0;
  padding-left: 1.75rem;
}
.SelectDCDropdown .check-group input[type="checkbox"] {
  margin-right: 8px;
  height: 20px;
  width: 20px;
}
.SelectDCDropdown .check-group .form-check .checkmark {
  height: 20px;
  width: 20px;
  border-radius: 4px;
}
.SelectDCDropdown .checkmark:after {
  left: 6px;
  top: 1.5px;
  width: 6px;
  height: 12px;
}
.SelectDCDropdown .clear-btn {
  width: 75px;
  height: 40px;
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(145, 158, 171);
  border-radius: 24px;
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.35px;
}
.SelectDCDropdown .update-btn {
  width: 90px;
  height: 40px;
  background: rgb(1, 142, 255);
  border-radius: 24px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.35px;
}
.peerAlignCenter {
  align-items: center;
}
.peerAlignCenter .vline {
  margin-left: 24px;
  padding-left: 2px;
}
.peerToggleGroup {
  display: inline-block;
}
.peerAlignCenter .withLabel .toggle-group label {
  font-family: Graphik-Medium;
}
.CalenderWithError .popover-body {
  max-width: 300px;
}
.CalenderWithError .peerCalenderError {
  color: rgb(213, 0, 47);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 12px 0 2px;
  align-items: center;
  display: flex;
}
.CalenderWithError .peerCalenderError .fa-exclamation-circle {
  font-size: 15px;
  margin-right: 5px;
  color: rgb(213, 0, 47);
  font-weight: 400;
  background: rgba(213, 0, 47, 0.2);
  border-radius: 50%;
}
.CalenderWithError .react-datepicker {
  width: 100%;
}
.CalenderWithError .calenderPopoverSpanActive,
.CalenderWithError .calenderPopoverSpan {
  width: 47%;
}
.peerToPeer-graph-title {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 20px;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 10px;
}
.graph-pagination {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  text-align: right;
  letter-spacing: -0.35px;
  line-height: 14px;
}
.hr-solid-line {
  border-top: 3px solid;
  width: 30px;
  margin-right: 8px;
}
.hr-dashed-line {
  border-top: 3px dashed;
  width: 30px;
  margin-right: 8px;
}
.purple-border {
  border-color: #a100ff;
}
.lightBlue-border {
  border-color: #9fe5ff;
}
.gray-dashed-border {
  border-color: #7c8a95;
}
.peerToPeer-legends-list ul {
  display: flex;
  align-items: center;
}
.peerToPeer-legends-list ul li {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.33px;
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.page-arrow-right {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 8px;
  margin-right: 10px;
  vertical-align: middle;
  border-top: 8px solid transparent;
  border-left: 8px solid;
  border-bottom: 8px solid transparent;
}
.page-arrow-left {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 10px;
  margin-right: 8px;
  vertical-align: middle;
  border-top: 8px solid transparent;
  border-right: 8px solid;
  border-bottom: 8px solid transparent;
}
.ddbenchmark-tooltip-body {
  display: flex;
  border-radius: 8px;
  background: linear-gradient(#a100ff 0%, #00baff 100%);
  padding: 15px 0;
}
.ddbenchmark-tooltip-body .white-background {
  background: #ffffff;
  color: black;
  padding: 15px 0;
}

.ddbenchmark-tooltip-body .benchmark-tooltip {
  padding: 0px 14px;
  border-left: 1px solid rgb(255, 255, 255);
}
.ddbenchmark-tooltip-body .benchmark-tooltip:first-child {
  border-left: none;
}
.ddbenchmark-tooltip-body .benchmark-tooltip-title {
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-bottom: 4px;
}
.ddbenchmark-tooltip-body .day-count {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 28px;
}
.ddbenchmark-tooltip-body .day-label {
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-left: 2px;
}
.ddbenchmark-tooltip-body .benchmark-tooltip-footer-label {
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-left: 2px;
  margin-top: 5px;
}
.ddbenchmark-tooltip-body .view-tooltip-detail {
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-top: 8px;
}
#chartjs-tooltip.worse,
#chartjs-tooltip.worst,
#chartjs-tooltip.better {
  padding: 0 !important;
  min-width: 125px;
}
#chartjs-tooltip.worse .ddbenchmark-tooltip-body {
  background: linear-gradient(
    -225deg,
    rgb(247, 218, 134) 0%,
    rgb(255, 200, 44) 52%,
    rgb(230, 176, 25) 100%
  );
}
#chartjs-tooltip.worst .ddbenchmark-tooltip-body {
  background: linear-gradient(
    -225deg,
    rgb(242, 178, 192) 0%,
    rgb(213, 0, 47) 52%,
    rgb(170, 0, 37) 100%
  );
}
#chartjs-tooltip.better .ddbenchmark-tooltip-body {
  background: linear-gradient(
    -225deg,
    rgb(184, 214, 158) 0%,
    rgb(126, 195, 68) 51%,
    rgb(126, 195, 68) 100%
  );
}
.peer-tooltip-body-wrapper {
  max-height: 86px;
  overflow: scroll;
}
.peer-tooltip-body-wrapper .tooltip-title {
  margin-bottom: 5px;
}
.peer-tooltip-body-wrapper .tooltip-content .border {
  border-bottom: 1px solid #c3c3c3;
  padding: 5px 10px;
}
.peer-tooltip-body-wrapper .day-count {
  /* color: rgb(255, 255, 255); */
  font-size: 24px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 28px;
}
#chartjs-tooltip.peer-to-peer-tooltip {
  max-width: 125px;
  min-width: 125px;
}
#chartjs-tooltip.worse:after {
  z-index: -1 !important;
  border-color: rgb(255, 200, 44);
}
#chartjs-tooltip.worse .ddbenchmark-tooltip-body .benchmark-tooltip-title,
#chartjs-tooltip.worse .ddbenchmark-tooltip-body .day-count,
#chartjs-tooltip.worse .ddbenchmark-tooltip-body .day-label,
#chartjs-tooltip.worse .ddbenchmark-tooltip-body .benchmark-tooltip-footer-label {
  color: rgb(33, 43, 54);
}
#chartjs-tooltip.worst:after {
  z-index: -1 !important;
  border-color: #d5002f;
}
#chartjs-tooltip.better:after {
  z-index: -1 !important;
  border-color: #7ec344;
}
#chartjs-tooltip.more-than-two-points {
  max-width: 200px;
  min-width: unset;
  transform: translate(-50%, -110%);
}
.more-than-two-points .ddbenchmark-tooltip-body {
  display: flex;
  flex-wrap: wrap;
}
.more-than-two-points .ddbenchmark-tooltip-body .benchmark-tooltip {
  flex: 50%;
  margin-bottom: 6px;
}
.popContent label.form-check.disabled {
  background: transparent;
  opacity: 1;
}
.overflowInherit .modal-content {
  overflow: unset;
}
/* DD phase2 enhancement css ends here */
.bat-cancel.btn-default,
.takeAssessment-view .assessment-header button.btn-default {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(181, 192, 202);
  color: rgb(99, 115, 129);
  box-shadow: none;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-right: 0;
  padding: 0.75rem 1.5rem !important;
}
.bat-cancel.btn-default:hover,
.bat-cancel.btn-default:focus,
.bat-cancel.btn-default:active,
.bat-cancel.btn-default:active:focus,
.takeAssessment-view .assessment-header button.btn-default:hover,
.takeAssessment-view .assessment-header button.btn-default:active,
.takeAssessment-view .assessment-header button.btn-default:focus,
.takeAssessment-view .assessment-header button.btn-default:focus:active {
  background-color: rgb(239, 242, 245);
  border-color: rgb(181, 192, 202);
  box-shadow: none;
  outline: none;
  color: rgb(99, 115, 129);
}
.bat-cancel.btn-default:disabled,
.bat-cancel.btn-default:disabled:hover,
.takeAssessment-view .assessment-header button.btn-default:disabled {
  opacity: 0.25;
  background-color: rgb(239, 242, 245);
  border-color: rgb(181, 192, 202);
}
.metric-offering-subOffering .btn-purple:disabled {
  opacity: 0.25;
}
.dontremind-dropdown .btn-primary:not(:disabled):not(.disabled):active,
.dontremind-dropdown .btn-primary:not(:disabled):not(.disabled):active:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #a100ff;
}
.custom-carousel-padding.bat-carousel-padding .owl-carousel {
  padding: 0 1.6rem;
}

#tveChart{
  display: block;
  flex-direction: column;
  align-items: left;
}
