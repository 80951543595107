@media screen and (width: 1366px) {
    .BO-delete {
        right: -3px !important;
   }
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 550px;
    }
}
@media(min-width: 641px) {
    .timeline-row {
        position: relative;
        padding: 0 15px;
        display: flex;
        /* justify-content: center; */
        /* display: inline-block;   */      
    }
    .timeline-row:before {
        content: "";
        height: 2px;
        width: calc(100% - 180px);
        left: 90px;
        background: #dfe3e8;
        position: absolute;
        top: 50%;
    }
    .timeline-row:first-child:before {
        width: calc(100% - 150px);
        left: 22px;
    }
    .timeline-row:last-child:before {
        width: calc(100% - 140px);
        left: 22px;
    }
    .timeline-row:nth-of-type(odd):last-child:before {
        width: calc(100% - 112px);
        left: 90px;
    }
    .timeline-row:nth-of-type(even):last-child {
        justify-content: flex-end;
    }
    /* .timeline-row:last-child:before {
        width: calc(100% - 112px);
        left: 90px;
    } */
    .timeline-row:after {
        width: 150px;
        height: 97.7%;
        border: 2px solid #dfe3e8;
        border-left: 0;
        border-radius: 0 50% 50% 0;
        -moz-border-radius: 0 50% 50% 0;
        -webkit-border-radius: 0 50% 50% 0;
        position: absolute;
        right: 0;
        content: "";
        top: 50%;
    }
    .timeline-row:nth-child(even):after {
        border-radius: 50% 0 0 50%;
        -moz-border-radius: 50% 0 0 50%;
        -webkit-border-radius: 50% 0 0 50%;
        right: auto;
        left: 0;
        border-left: 2px solid #dfe3e8;
        border-right: 0;
    }
    .timeline-row:last-child:after {
        display: none;
    }
    .timeline-row:first-child, .timeline-row:last-child {
        background: url(../Images/circle.png) no-repeat scroll left center;
    }
    /* .timeline-row:last-child {
        background-position: right center;
    } */
    .timeline-row:nth-of-type(odd):last-child {
        background-position: right center;
    }
}
@media (min-width: 768px) {
    .pb-md-11, .py-md-11 {
        padding-bottom: 6rem!important;
   }
    .pt-md-11, .py-md-11 {
        padding-top: 6rem!important;
   }
    .pb-md-8, .py-md-8 {
        padding-bottom: 3rem!important;
   }
    .pt-md-8, .py-md-8 {
        padding-top: 3rem!important;
   }
    .ml-md-n3, .mx-md-n3 {
        margin-left: -.75rem!important;
   }
    .wrapper .main-sidebar {
        display: block;
   }
    .profile-large-icon{
        float: right;
   }
    .profile-large-icon > img{
        border-radius: 100px;
   }
    .navbar-toggle.hamburger{
        display: none;
   }
    .outcome-table + .outcome-table {
        margin-top: 12px;
   }
    div.login-img, div.login-img + a {
        display: none;
   }
   /* div.login-img + .profile-large-icon{
        display: none;
   }
    */
    .dashbord-search{
        float: right;
        margin-right: 80px;
        margin-top: 10px;
   }
    .navbar-right > .login-img{
        width: 260px !important;
   }
    li.logged {
        min-height: 78px;
   }
}
@media(min-width: 1025px) and (max-width:1220px) {
    /* .outcome-tab li .tab-link span {
        font-size: 13px;
   }
    .outcome-tab li .tab-link {
        padding: 16px 6px 15px !important;
        min-width: inherit;
   }
    .outcome-tab li:first-child .tab-link {
        padding: 15px 8px !important;
   }
    .outcome-tab li:first-child .tab-link .bu-title-text h3 {
        font-size: 20px;
   }
    .outcome-tab li:first-child .tab-link .bu-title-text p {
        color: #ffffff;
   }
    .without-bo.outcome-tab li .tab-link {
        padding: 17px 12px 25px !important;
   } */
    .bu-outcomes-title .bu-title-img {
        padding-right: 12px;
        padding-left: 3px;
   }
}
@media(min-width: 992px) {    
    .modal-lg, .modal-xl {
        max-width: 800px;
    }
    .outcome-list.sq-right-br {
        padding-left: 23px;
        padding-right: 42px;
   }
    .outcome-list.sq-top-right-br {
        padding: 34px 42px 30px 23px;
   }
    .outcome-list.sq-bottom-left-br {
        padding-left: 42px;
        padding-right: 23px;
   }
    .outcome-list.sq-top-left-br {
        padding: 34px 23px 30px 42px;
   }
}
@media(min-width: 1025px) {
    /* .outcome-tab {
        margin: 0 -15px;
   }
    .outcome-tab li .tab-link.active {
        border: 1px solid #018fff;
   }
    .outcome-tab li .tab-link.active:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #018fff;
        border-width: 12px;
        margin-left: -12px;
   }
    .outcome-tab li .tab-link.active:before {
        position: absolute;
        content: "";
        background: #018fff;
        width: 100%;
        border-radius: 0 0 10px 10px;
        height: 8px;
        bottom: 0;
        left: 0;
   }
    .outcome-tab li .blu-hlt.tab-link {
        margin-bottom: 0;
        background: #00baff;
        color: #fff;
        border-radius: 0;
        padding: 16px 30px 17px 15px;
        border-color: #00baff;
   }
    .outcome-tab li .blu-hlt.tab-link.active:after {
        border-top-color: #00baff;
        display: none;
   }
    .outcome-tab li .blu-hlt.tab-link.active:before {
        background: #00baff;
   }
    .outcome-tab li:first-child .tab-link .bu-title-text h3, .outcome-tab li:first-child .tab-link .bu-title-text p {
        color: #ffffff;
   }
    .outcome-tab.without-bo {
        padding: 0 15px;
   }
    .without-bo.outcome-tab li {
        margin: 0 15px;
   }
    .without-bo.outcome-tab li .tab-link {
        margin-bottom: 0;
        background: transparent;
        color: inherit;
        border-radius: 10px;
        padding: 17px 12px 25px;
        border: 0 none;
   }
    .outcome-tab.without-bo li .tab-link.active span {
        color: #4f555b;
   }
    .outcome-tab.without-bo li .tab-link.active:after {
        display: none;
   }
    .outcome-tab.without-bo li .tab-link.active:before {
        display: block;
        bottom: 15px;
        border-radius: 10px;
        background: #018fff;
        height: 7px;
        width: 90%;
        left: 5%;
        position: absolute;
        content: " ";
   }
    .without-bo.outcome-tab li .tab-link.active {
        border: 0 none;
   }
    .outcome-tab.synops-tab li .tab-link {
        min-width: 110px;
        border-color: transparent;
   }
    .outcome-tab.synops-tab li .tab-link.active:before {
        bottom: 12px;
        border-radius: 6px;
        height: 6px;
        width: 86%;
        left: 50%;
        margin-left: -43%;
   }
    .outcome-tab.synops-tab li .tab-link.active:after {
        display: none;
   } */
}
@media(min-width: 992px) and (max-width: 1199px) {
    .timeline-box {
        margin: 23px 5px;
   }
    .timeline-content, .timeline-box {
        width: 170px;
   }
}
@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}
@media(max-width: 1250px) {
    .big-tab .insights {
        margin: 10px 0;
        padding: 10px;
   }
    .big-tab .insights-img {
        float: none;
        display: inline-block;
   }
    .big-tab .insights-header {
        text-align: center;
   }
    .big-tab .insights-title {
        margin-left: 0px;
        float: none;
        clear: both;
   }
    .insight-graph-spot li {
        margin: 8px;
   }
}
@media(max-width: 1199px) {
    .outcome-highlight.dash-settings.lg-lt-arrow:after, .outcome-highlight.dash-settings.lg-rt-arrow:before {
        display: none;
   }
    .insights-header {
        padding: 0 0 10px;
        overflow: hidden;
        text-align: center;
   }
    .insights-img {
        float: none;
        width: 64px;
        height: 64px;
        border: 1px solid #a5aeb6;
        border-radius: 50%;
        padding: 15px 13px;
        text-align: center;
        margin: 5px auto 0;
   }
    .insights-title {
        margin: 10px 0px 0px 0px;
        float: none;
   }
    /* .outcome-tab li {
        margin: 0 4px;
   } */
    .hlt-blue-bg {
        padding: 15px;
   }
}
@media(max-width: 1100px) {
    .filter-drop {
        display: block !important;
    }
    .filter-drop .corner-stable {
        padding-left: 0;
        /* border-top: 1px solid #dfe3e8;
        padding-top: 4px; */
        margin: 4px 5px 0 !important;
    }
    .filter-drop .corner-stable:before {
        display: none;
    }
    .main-component {
        padding: 0 4px;
    }
}
@media(max-width: 1024px) {
    /* .select-data select {
        width: 48%;
   } 
    .center-text {
        margin-top: 10px !important;
   }
    .para-heading {
        font-size: 16px;
   }
    .select-info {
        border-bottom: 0 none;
        margin-top: 20px;
   }
    .select-info:after, .select-info:before {
        display: none;
   }
   .business-outcomes {
       box-shadow: none;
    }*/
    /* .outcome-tab {
        margin: 0 -15px;
   }
    .outcome-tab li:first-child {
        width: auto;
        float: none;
        display: block;
   }
    .outcome-tab li {
        margin: 0;
        width: 50%;
   }
    .outcome-tab li .tab-link {
        padding: 10px 8px;
        border-radius: 0;
        text-align: left;
        min-width: inherit;
        border-bottom: 1px solid #c9d1d6;
        border-collapse: collapse;
   }
    .outcome-tab li:nth-child(odd) .tab-link {
        border-left: 1px solid #c9d1d6;
   }
    .outcome-tab li:first-child .tab-link {
        border-left: 0 none;
   }
    .outcome-tab li .tab-link img, .outcome-tab.without-bo li:first-child .tab-link img {
        float: left;
        margin-right: 12px;
   }
    .outcome-tab li:first-child .tab-link img {
        margin-right: 0;
        width: 26px;
        height: 28px;
   }
    .outcome-tab li .tab-link span {
        margin-top: 5px;
        display: inline-block;
   }
    .outcome-tab li .tab-link.active {
        background: #efefef;
   }
    .outcome-tab li .blu-hlt.tab-link.active {
        background: #00baff;
   }
    .main-component {
        padding: 0;
   }
    .main-sidebar {
        padding-bottom: 75px;
   }
    .footer-logo {
        float: none;
        padding: 5px 0;
   }
    p.copyright {
        text-align: left;
        padding: 5px 0 10px;
   }
    p.copyright a {
        margin: 5px 8px 3px 0;
        display: inline-block;
   }
    .main-container {
        padding-bottom: 0px;
        width: 80%;
   }
    .big-tab-content {
        padding: 15px 0;
   }
    .wrapper::after {
        height: 90px;
   } */
}
@media(max-width: 991px) {
    .navbar {
        padding: 1.1845rem 0.9231em;
    }
    a.navbar-brand {
        padding: 5px 12px;
        margin: 0 1rem;
    }
    a.navbar-brand:before {
        border-right: 1.5px solid #dfe3e8;
        width: 1px;
        height: 40px;
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -20px;
    }
    .navbar .select-data {
        padding-right: 12px;
        margin-right: 16px;
    }
    .search-nav .nav-item {
        margin-right: 24px;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-toggler {
        padding: 0;
        font-size: 1.25rem;
        border: 0 none;
    }
    .navbar-toggler-icon .fa-bars {
        margin: 3px 0 0;
        font-size: 26px;
        width: 1em !important;
    }
    .navbar-nav.ml-auto li.nav-item {
        margin-left: 4px;
    }
    .main-container {
        width: auto;
        margin: 0;
    }
    .main-sidebar {
        left: -256px;
        -webkit-transition: left .5s;
        -moz-transition: left .5s;
        -o-transition: left .5s;
        transition: left .5s;
    }
    .main-sidebar.show {
        left: 0;
        -webkit-transition: left .5s;
        -moz-transition: left .5s;
        -o-transition: left .5s;
        transition: left .5s;
    }
    p.copyright a {
        margin: 0 0 0 10px;
    }
    span.copyright {
        margin-left: 24px;
    }
    .navbar .select-data > div {
        max-width: 130px;
    }


    .para-heading br {
        display: none;
   }
    .title-card {
        float: left;
   }
    .timeline-box {
        margin: 23px 10px;
   }
    .timeline-content, .timeline-box {
        width: 160px;
        height: 170px;
   }
    .timeline-row:nth-of-type(odd):after, .timeline-row:nth-of-type(even):before {
        width: 300px;
   }
    .insights:after {
        display: none;
   }
    .insights {
        margin: 15px 0 !important;
   }
    .big-tab .insights {
        margin: 10px 0 !important;
   }
    .insight-graph {
        margin-bottom: 25px;
   }
    .outcome-list.sq-right-br:after, .outcome-list.sq-right-br:before, .outcome-list.sq-top-right-br:after, .outcome-list.sq-top-right-br:before, .outcome-list.sq-bottom-left-br:after, .outcome-list.sq-bottom-left-br:before, .outcome-list.sq-top-left-br:after, .outcome-list.sq-top-left-br:before {
        display: none;
   }
    .outcome-list.sq-right-br, .outcome-list.sq-top-right-br, .outcome-list.sq-bottom-left-br, .outcome-list.sq-top-left-br {
        margin: 0 0 30px 
   }
    .outcome-sq-br .img-title {
        margin-bottom: 15px;
   }
    .hlt-blue-bg .outcome-divider {
        display: inline-block;
   }
    .outcome-highlight h4.title-icon {
        font-size: 15px;
   }
}
@media(max-width: 800px) {
    /* .main-sidebar {
        padding-bottom: 110px;
   }
    .wrapper::after {
        height: 115px;
   } */
}
@media(max-width: 767px) {
    .navbar-nav.search-nav.mr-auto {
        display: none;
    }
    .display-sm {
        display: block;
    }
    .filter-drop {
        margin: 0 -5px;
    }
    .select-data .select-custom {
        margin: 0 5px;
        width: 47%;
    }
    .select-data select {
        width: 97%;
    }
    .navbar .select-data:after {
        display: none;
    }
    .filter-drop .select-data {
        margin: 0 -5px 4px;
    }
    .footer-logo {
        float: none;
    }
    p.copyright {
        padding: 4px 0 10px 0;
        text-align: left;
    }
    p.copyright a {
        margin: 0 10px 0 0;
    }
    body {
        padding: 0 0 78px;
    }
    .navbar.fixed-top {
        position: relative;
    }
    .navbar .select-data {
        padding-right: 8px;
        margin-right: 12px;
    }
    .navbar .select-data > div {
        padding-right: 0px;
    }
   /* .center-text {
        font-size: 15px;
   }
    .profile-large-icon{
        display: none;
   }
    .select-info {
        margin-top: 50px;
   }
    .navbar-toggle.hamburger{
        display: block;
   }
    ul.nav.navbar-nav.navbar-right > li {
        margin-top: 0;
   }
    .btn {
        width: 100%;
        margin-top: 15px;
   }.navbar-default .navbar-toggle {
        margin-top: 24px;
   }
    .navbar-default .navbar-brand {
        width: 56px;
   }
    .navbar-header {
        position: relative;
        padding-left: 120px;
        padding-bottom: 5px;
   }
    .navbar-brand img {
        width: 59px;
   }
    .navbar-toggle {
        position: absolute;
        margin: 0;
        top: 26px;
        left: 20px;
   }
    .login-img span {
        margin-right: 0;
        margin-bottom: 3px;
        display: block;
   }
    .login-img {
        width: auto;
        padding: 12px 60px 5px 15px;
   }
    .login-img select {
        width: 100%;
   }
    .login-img img {
        margin-left: 10px;
        left: auto;
        margin-right: 0;
        right: 6px;
        top: 15px;
   }
    li.logged {
        padding: 5px 15px;
   }
    a.logged-hdr {
        position: absolute;
        right: 61px;
        top: 2px;
        width: 30px;
        border: 1px solid #c9d1d6;
        border-radius: 50%;
        padding: 3px;
   }
    a.logged-hdr img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
   } */
    .owl-carousel .owl-nav [class*='owl-'] {
        margin-top: -35px;
        width: 35px;
        height: 70px;
   }
    .owl-carousel .owl-nav [class*='owl-'] {
        right: -35px;
   }
    .owl-carousel .owl-nav .owl-prev {
        left: -35px;
   }
    /* .main-container {
        margin-top: 0;
        margin-left: 0;
        padding-bottom: 0px;
        padding-top: 78px;
        width: 100% !important;
   } 
    .gocpage.main-container {
        padding-top: 0;
   }
    .toggled .main-sidebar {
        display: block;
   }
    .main-sidebar {
        width: auto;
   }
    .wrapper::before {
        content: "";
        position: fixed;
        display: none;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #344451;
        background-color: rgba(52, 68, 81, 0.3);
        z-index: 1;
   }
    .wrapper.toggled:before {
        display: block;
   }
    .main-sidebar ul {
        margin-bottom: 0;
   }
    .main-sidebar li a, .main-sidebar li .link-span {
        font-size: 12px;
        text-align: left;
        display: block;
        padding: 12px;
   }
    .main-sidebar li img {
        width: 24px;
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 0;
        max-height: 24px;
   }
    .main-sidebar li {
        border-bottom: 1px solid #3a3a3a;
   }
    .main-sidebar li span {
        display: inline-block;
   }
    .corner-stable {
        float: none;
        padding-top: 10px;
        margin: 0;
        padding-bottom: 10px;
   }
    .corner-stable p {
        margin: 0;
        font-size: 15px;
        float: left;
        position: relative;
        margin-right: 8px;
   }
    .corner-stable p:after {
        content: " :";
   }
    .corner-stable h4 {
        margin: 0;
        display: inline-block;
   }*/
    .outcome-highlight {
        padding: 20px 15px 10px;
   }
    .outcome-table {
        margin: 15px 0;
   }
    .outcome-highlight h4.title-icon {
        margin: 30px 0 15px;
   }
    .orch-sidebar .rainbow-tools {
        padding: 0;
   }
    .insight-graph-spot li {
        display: block;
        text-align: left;
   }
    /* .big-tab-container > h3 {
        font-size: 24px;
   }
    .bu-title-text h3, .insights-title h3 {
        font-size: 20px;
   }
    .title-card h3, .outcome-highlight.dash-settings h2, .modal-title {
        font-size: 18px;
   }
    .para-heading {
        font-size: 16px;
   }
    .corner-stable h4, .outcome-highlight h2 {
        font-size: 16px;
   }
    .outcome-highlight .outcome-list h2 {
        font-size: 15px;
   }
    .form-section h3, .quicklinks_grid .radius-tools span, .corner-stable p, .rainbow-tools td p, .form-section .form-group p, .rainbow-tools p {
        font-size: 14px;
   }
    body, .select-data select, .bu-title-text p, .outcome-tab li .tab-link span {
        font-size: 13px;
   } */
}
@media(max-width: 640px) {
    .outcome-highlight h2 + a {
        float: none;
        top: 0;
        margin-top: 8px;
        display: inline-block;
   }
   /* .main-container {
        padding-bottom: 100px;
   }
    */
    .timeline-box {
        width: 46%;
        margin: 10px 2%;
        height: auto;
   }
    .timeline-content {
        width: 100%;
        height: auto;
        min-height: 190px;
   }
    .timeline-row {
        border-top: 5px solid #d2d7dd;
        padding: 10px 0;
   }
    .file-upload {
        width: 100%;
   }
    .wrapper::after {
        height: auto;
   }
}
@media(max-width: 480px) {
    p.copyright {
        padding: 2px 0 10px 0;
    }
    span.copyright {
        margin-left: 0;
        display: block;
        text-align: left;
        padding: 5px 0 0;
    }
    body {
        padding: 0 0 96px;
    }
   /* .login-img {
        width: 220px;
   }
    .login-img select {
        width: 160px;
   }
    */
    .select-data {
        margin: 0;
   }
    /* .select-data select {
        margin: 2px 0 10px;
        width: 100%;
   } */
    /* .outcome-tab li {
        width: auto;
        float: none;
   } */
    .col-xxs {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        float: left;
        width: 100%;
   }
    .outcome-table {
        display: block;
        table-layout: unset;
        margin: 15px 0;
   }
    .outcome-row {
        display: block;
        margin: 15px 0;
   }
    .outcome-divider {
        display: block;
        margin: 15px 0;
        width: auto;
   }
    .outcome-row + br {
        display: none;
   }
    .outcome-row.singleFlex {
        padding: 0 !important;
        margin: 0;
   }
    .hlt-blue-bg {
        padding: 0px 15px;
   }
    .hlt-blue-bg .outcome-divider {
        display: block;
   }
    .outcome-list.border-rt {
        border-right: 0 none;
   }
    .navbar-brand img {
        width: 55px;
   }
    .login-img {
        width: 100%;
   }
    .login-img img {
        margin-left: 10px;
        left: auto;
        margin-right: 0;
        right: 42px;
        width: 30px;
        height: 30px;
        top: 3px;
   }
    .login-img {
        width: 100%;
        padding: 12px 5px 5px 10px;
        float: right;
   }
    a.logged-hdr {
        right: 6px;
        top: 3px;
        width: 30px;
   }
    p.copyright {
        font-size: 11px;
   }
}
@media(max-width: 420px) {
   /* .login-img {
        width: 195px;
   }
    .login-img select {
        width: 130px;
   }
    */
    p.copyright {
        font-size: 12px;
   }
    .wrapper::after {
        height: 130px;
   }
    .timeline-box {
        width: 100%;
        margin: 10px 0;
        height: auto;
   }
    .timeline-content {
        min-height: inherit;
   }
    .main-sidebar {
        padding-bottom: 128px;
   }
}
/* IE6,7,8 */
@media \0screen\,screen\9 {
    .search-nav .nav-item a.nav-link:not(.dropdown-toggle):after {
        left: 0;
    }
    .float-icon span {
        line-height: 26px;
    }
    .float-icon {
        top: -14px;
    }   
    .rwt__tab:hover, .rwt__tab:focus, [aria-selected=false].rwt__tab:hover, .ag-table.ag-theme-balham {
        background-color: inherit !important;
    }
    .rwt__tab:after {
        left: 0;
    }
    .chartjs-render-monitor {
        width: 100% !important;
    }
    .rd-progress *, .rd-progress *::after, .rd-progress *::before {
        box-sizing: border-box !important;
    }
}
/* IE9,10 */
@media screen and (min-width:0\0) {
    .search-nav .nav-item a.nav-link:not(.dropdown-toggle):after {
        left: 0;
    }
    .float-icon span {
        line-height: 26px;
    }
    .float-icon {
        top: -14px;
    }   
    .rwt__tab:hover, .rwt__tab:focus, [aria-selected=false].rwt__tab:hover, .ag-table.ag-theme-balham {
        background-color: #ffffff !important;
    }
    .rwt__tab:after {
        left: 0;
    }
    .chartjs-render-monitor {
        width: 100% !important;
    }
    .rd-progress *, .rd-progress *::after, .rd-progress *::before {
        box-sizing: border-box !important;
    }
}
/* fix for IE10+ */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .select-data input {
        padding: 5px 8px;
   }
    .nav-tabs > li > a{
        font-size: 14px !important;
   }
    .modal-footer > .row >.form-group{
        flex: none !important;
   }
   .search-nav .nav-item a.nav-link:not(.dropdown-toggle):after {
       left: 0;
   }
   .float-icon span {
       line-height: 26px;
   }
   .float-icon {
       top: -14px;
   }   
    .rwt__tab:hover, .rwt__tab:focus, [aria-selected=false].rwt__tab:hover, .ag-table.ag-theme-balham {
        background-color: #ffffff !important;
    }
    .rwt__tab:after {
        left: 0;
    }
    .chartjs-render-monitor {
        width: 100% !important;
    }
    .rd-progress *, .rd-progress *::after, .rd-progress *::before {
        box-sizing: border-box !important;
    }
}

.apiload{
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    opacity: 1;
    z-index: 998;
    height: 100%;
    width: 100%;
  
}