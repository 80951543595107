.pulse-dashboard {
  position: relative;
  width: 100%;
  padding: 40px 6px;
}
.pulse-header-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}
.pulse-header-controls ul {
  display: flex;
  align-items: center;
  margin: 0;
}
.pulse-header-controls .pulse-pagename {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 34px;
}
.pulse-header-controls .pulse-btns ul li {
  color: rgb(161, 0, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(161, 0, 255);
  border-radius: 16px;
  padding: 8.5px 14px;
  margin-left: 16px;
  cursor: pointer;
}
.pulse-header-controls .pulse-btns ul li:hover {
  background: rgb(179, 51, 255);
  color: rgb(255, 255, 255);
  border: 1.5px solid rgb(179, 51, 255);
}
.pulse-header-controls .pulse-btns ul li.pulse-qcscore {
  border: none !important;
  background: transparent !important;
  padding: 8.5px 14px 8.5px 1px !important;
  position: relative;
}
.pulse-header-controls .pulse-btns ul li.pulse-qcscore::after,
.pulse-header-controls .pulse-btns ul li.help-notification::after {
  content: "";
  position: absolute;
  background-color: #dfe3e8;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: 90%;
}
.pulse-modules ul,
.pulse-modules-wrapper .selected-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
.pulse-modules ul li {
  width: 33.33%;
  height: 68px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  border-radius: 8px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  cursor: pointer;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.31px;
  line-height: 18px;
  position: relative;
}
.pulse-modules ul li:hover,
.pulse-modules ul li.active {
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(117, 0, 192) 52%,
    rgb(70, 0, 115) 100%
  );
  color: rgb(255, 255, 255);
}
.pulse-modules ul li:hover {
  background: linear-gradient(-225deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%);
}
.pulse-modules ul li:last-child {
  margin-right: 0;
}
.pulse-modules ul li span {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  right: 16px;
  margin: auto;
  width: 36px;
  height: 32px;
}
.pulse-modules ul li .human-machine {
  background: url("../Images/humanm-icon.svg") no-repeat;
}
.pulse-modules ul li:hover .human-machine,
.pulse-modules ul li.active .human-machine {
  background: url("../Images/humanm-icon-hover.svg") no-repeat;
}
.pulse-modules ul li .work-orch {
  background: url("../Images/worko-icon.svg") no-repeat;
}
.pulse-modules ul li:hover .work-orch,
.pulse-modules ul li.active .work-orch {
  background: url("../Images/worko-icon-hover.svg") no-repeat;
}
.pulse-modules ul li .insights-inte {
  background: url("../Images/insightsi-icon.svg") no-repeat;
}
.pulse-modules ul li:hover .insights-inte,
.pulse-modules ul li.active .insights-inte {
  background: url("../Images/insightsi-icon-hover.svg") no-repeat;
}
.pulse-modules ul li .diverse-data {
  background: url("../Images/dirversed-icon.svg") no-repeat;
}
.pulse-modules ul li:hover .diverse-data,
.pulse-modules ul li.active .diverse-data {
  background: url("../Images/diversed-icon-hover.svg") no-repeat;
}
/* newly added style found in casestudy and opertaion review on pulse page branch*/
.pulsehub-wrapper .synops-hub {
  /* background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  border-radius: 8px; */
  background: rgb(230, 220, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 1.65rem 1.65rem;
  display: flex;
  align-items: center;
}
.synops-hub .hub-imgsection {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.synops-hub .hub-btnsection {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.synops-hub .hub-btnsection h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.23px;
  line-height: 20px;
  margin-bottom: 1rem;
}
/* newly added style found in casestudy and opertaion review on pulse page branch*/
.pulsehub-wrapper .synops-story {
  height: 160px;
  background: linear-gradient(-225deg, rgb(249, 250, 251) 0%, rgb(196, 205, 213) 100%);
  border-radius: 8px;
  margin: 26px 0 16px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pulsehub-wrapper .synops-hub .top-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 42px 24px;
  background: url(../Images/synops-hub-bg.png) no-repeat;
  flex-direction: column;
  background-position: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-size: cover;
}
.pulsehub-wrapper .synops-hub .top-section span {
  color: rgb(33, 43, 54);
  font-size: 28px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 32px;
  text-align: center;
  padding: 24px 40px 0 40px;
}
.pulsehub-wrapper .synops-story p {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.2px;
  line-height: 32px;
  margin: 0;
  padding: 38px 0 16px 0;
}
.pulsehub-wrapper .synops-hub .bottom-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
}
.pulsehub-wrapper .synops-story span,
.pulsehub-wrapper .synops-hub .bottom-section a,
.pulsehub-wrapper .hub-btnsection a,
.addcasestudy-pulsewrapper span {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 14px;
  background: rgb(161, 0, 255);
  border-radius: 20px;
  padding: 13px 24px;
  cursor: pointer;
}
.pulsehub-wrapper .synops-story span:hover,
.pulsehub-wrapper .synops-hub .bottom-section a:hover,
.pulsehub-wrapper .hub-btnsection a:hover,
.addcasestudy-pulsewrapper span:hover {
  background: rgb(179, 51, 255);
}
.synops-hub.procurement-section .hub-btnsection {
  width: 100%;
  align-items: flex-start;
}

.pulsehub-wrapper .hub-btnsection a,
.addcasestudy-pulsewrapper span {
  font-size: 12px;
  border-radius: 16px;
  padding: 10px 16px;
  margin-top: 49px;
}

.pulsehub-wrapper .synops-hub .bottom-section span {
  line-height: 40px;
}
.pulse-modules-wrapper .selected-head {
  margin: 24px 0;
}
.pulse-modules-wrapper .selected-head p,
.pulse-modules-wrapper .selected-head .assets-info p span {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin: 0;
  position: relative;
}

.pulse-modules-wrapper .selected-head .human-grey::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url("../Images/human-grey.svg") no-repeat;
  width: 24px;
  height: 20px;
}
.pulse-modules-wrapper .selected-head .worko-grey::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url("../Images/worko-grey.svg") no-repeat;
  width: 24px;
  height: 20px;
}
.pulse-modules-wrapper .selected-head .insightsi-grey::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url("../Images/insightsi-grey.svg") no-repeat;
  width: 24px;
  height: 20px;
}
.pulse-modules-wrapper .selected-head .diversed-grey::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url("../Images/diversed-grey.svg") no-repeat;
  width: 24px;
  height: 20px;
}
.pulse-modules-wrapper .selected-head > p {
  padding-left: 40px;
}
.pulse-modules-wrapper .selected-head .assets-info p {
  font-size: 10px;
  margin: 0 0 0 32px;
  display: flex;
  align-items: center;
}
.pulse-modules-wrapper .selected-head .assets-info p img {
  margin-right: 14px;
  width: 24px;
  height: 24px;
}
/* .pulse-modules-wrapper .selected-head .assets-info p:first-child img{    
    border: 1px solid rgb(223, 227, 232);
    border-radius: 50%;
} */
.pulse-modules-wrapper .selected-head .assets-info p span {
  font-size: 14px;
  padding-left: 8px;
}
.pulse-modules-wrapper .selected-head .assets-info {
  display: flex;
  align-items: center;
}
.pulse-modules-wrapper .selected-head .assets-info > span {
  background: rgb(161, 0, 255);
  border-radius: 16px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  padding: 10px 22px;
  cursor: pointer;
  margin-left: 30px;
}
.pulse-modules-wrapper .selected-head .assets-info > span:hover {
  background: rgb(179, 51, 255);
}
.pulse-carousel-container {
  display: flex;
  padding: 0 18px 30px 26px;
  width: 100%;
}
.pulse-carousel-container .owl-carousel .owl-stage-outer {
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.pulse-carousel-container .owl-carousel .owl-item .item {
  width: 284px !important;
}
.pulse-carousel-container .owl-carousel .owl-item .item:last-child {
  margin-right: 0px !important;
}
.pulse-carousel-container .owl-carousel .owl-item .item .item-details:first-child {
  margin-bottom: 16px;
}
.pulse-carousel-container .owl-carousel .owl-item .item .item-details {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
  height: 176px;
  margin: 0;
}
.pulse-carousel-container .owl-carousel .owl-item .top-content {
  padding: 14px 6px 6px 12px;
  height: calc(100% - 40px);
}
.pulse-carousel-container .owl-carousel .owl-item .top-content svg {
  color: red;
  width: 34px;
  height: 34px;
  margin-bottom: 8px;
  cursor: initial;
}
.pulse-carousel-container .owl-carousel .owl-item .bottom-content {
  height: 40px;
  border-top: 1px solid rgb(223, 227, 232);
  padding: 0px 6px 0px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pulse-carousel-container .owl-carousel .owl-item .bottom-content p img {
  width: 24px;
  height: 24px;
  /* border: 1px solid rgb(223, 227, 232);
    border-radius: 50%; */
}
.pulse-carousel-container .owl-carousel .owl-item .bottom-content .dropdown {
  display: flex;
  align-items: center;
}
.pulse-carousel-container .owl-carousel .owl-item .bottom-content .dropdown .dropdown-toggle {
  width: 44px;
  height: 40px;
  background: transparent;
  cursor: pointer;
  position: relative;
}
.pulse-carousel-container
  .owl-carousel
  .owl-item
  .bottom-content
  .dropdown
  .dropdown-toggle::before {
  position: absolute;
  content: "";
  background: url("../Images/ellipsis.svg") no-repeat;
  width: 16px;
  height: 4px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border: none;
}
.pulse-carousel-container
  .owl-carousel
  .owl-item
  .bottom-content
  .dropdown
  .dropdown-toggle::after {
  border: none;
}
.pulse-carousel-container .owl-carousel .owl-item .bottom-content .dropdown .dropdown-menu {
  left: auto !important;
  right: 0 !important;
  padding: 10px !important;
}
.pulse-carousel-container .owl-carousel .owl-item .bottom-content .dropdown .dropdown-menu a {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  position: relative;
  padding: 4px 0px 4px 30px;
}
.pulse-carousel-container .owl-carousel .owl-item .bottom-content .dropdown .dropdown-menu a:hover {
  color: rgb(21, 24, 27);
  font-family: Graphik-Medium;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.2)
    linear-gradient(-225deg, rgba(161, 0, 255, 0.2) 0%, rgba(0, 186, 255, 0.2) 100%);
  border-radius: 4px;
}
.pulse-carousel-container .owl-carousel .dropdown-menu a span {
  position: relative;
}
.pulse-carousel-container .owl-carousel .dropdown-menu a .edit-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url(../Images/edit-normal.svg) no-repeat;
  width: 14px;
  height: 14px;
  left: -22px;
  top: -1px;
}
.pulse-carousel-container .owl-carousel .dropdown-menu a:hover .edit-icon::before {
  background: url(../Images/edit-gradient.svg) no-repeat;
}
.pulse-carousel-container .owl-carousel .dropdown-menu a .link-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url(../Images/link-normal.svg) no-repeat;
  width: 14px;
  height: 14px;
  left: -22px;
  top: -1px;
}
.pulse-carousel-container .owl-carousel .dropdown-menu a:hover .link-icon::before {
  background: url(../Images/link-gradient.svg) no-repeat;
}
.pulse-carousel-container .owl-carousel .dropdown-menu a .remove-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url(../Images/delete-normal.svg) no-repeat;
  width: 14px;
  height: 14px;
  left: -22px;
  top: -1px;
}
.pulse-carousel-container .owl-carousel .dropdown-menu a:hover .remove-icon::before {
  background: url(../Images/delete-gradient.svg) no-repeat;
}
.pulse-carousel-container .owl-carousel .dropdown-menu a .summary-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url(../Images/summary-normal.svg) no-repeat;
  width: 14px;
  height: 14px;
  left: -22px;
  top: -1px;
}
.pulse-carousel-container .owl-carousel .dropdown-menu a:hover .summary-icon::before {
  background: url(../Images/summary-gradient.svg) no-repeat;
}

.pulse-carousel-container .owl-carousel .owl-item .bottom-content .dropdown .dropdown-toggle:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
}
.pulse-carousel-container .owl-carousel .owl-item .top-content img {
  width: auto !important;
  margin-bottom: 8px;
}
.custom-tool {
  border-radius: 16px;
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 1px 12px;
  background: rgba(156, 106, 222, 0.2);
  color: rgb(80, 35, 143);
}
.custom-tool.top-right {
  position: absolute;
  margin-top: -44px;
  right: 20px;
}
.pulse-carousel-container .owl-carousel .owl-item .top-content .item-name {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 16px;
  height: 34px;
  margin-bottom: 10px !important;
}
.pulse-carousel-container .owl-carousel .owl-item .top-content .item-desc {
  color: rgb(119, 136, 153);
  font-size: 12px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 15px;
  height: 30px;
}
.pulse-carousel-container .owl-carousel .owl-nav [class*="owl-"] {
  box-shadow: none;
  width: auto;
  height: auto;
  background: transparent;
}
.pulse-carousel-container .owl-carousel .owl-nav .disabled {
  opacity: 1;
}
.pulse-carousel-container .owl-carousel .owl-nav .owl-prev.disabled:before,
.pulse-carousel-container .owl-carousel .owl-nav .owl-next.disabled:before {
  content: "";
  background: url("../Images/carousel-arrow-disable.svg");
  height: 21px;
  width: 13px;
  position: absolute;
  top: 18px;
  left: -14px;
  opacity: 1;
  transform: rotate(0deg);
}
.pulse-carousel-container .owl-carousel .owl-nav .owl-prev:before {
  content: "";
  background: url("../Images/carousel-right.svg");
  height: 21px;
  width: 13px;
  position: absolute;
  top: 18px;
  left: -14px;
  opacity: 1;
  transform: rotate(180deg);
}
.pulse-carousel-container .owl-carousel .owl-nav .owl-next:before {
  content: "";
  background: url("../Images/carousel-right.svg");
  height: 21px;
  width: 13px;
  position: absolute;
  top: 18px;
  left: 25px;
}
.pulse-carousel-container .owl-carousel .owl-nav .owl-next.disabled:before {
  transform: rotate(180deg);
  top: 18px;
  left: 25px;
}
.pulse-carousel-container .owl-carousel .owl-nav [class*="owl-"]:hover {
  background: transparent;
}
.pulse-carousel-container .no-data-found {
  position: relative;
  min-height: 285px;
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 7%;
}
.pulse-carousel-container .no-data-found .nodata-name,
.no-data-found .nodata-info {
  color: rgb(82, 101, 127);
  font-size: 24px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.86px;
  text-align: center;
  padding-top: 20px;
  margin: 0;
}
.pulse-carousel-container .no-data-found .nodata-info {
  font-size: 18px;
  font-family: graphik;
  padding: 0;
}
.pulse-dashboard .select-filter-msg {
  display: block;
  text-align: center;
  min-height: 390px;
  padding-top: 12%;
}
.pulse-dashboard .select-filter-msg img {
  width: 32px;
}
.pulse-dashboard .select-filter-msg p {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.52px;
  line-height: 28px;
  padding-top: 16px;
}
.qcscore-pulse-page {
  cursor: initial !important;
}
@media screen and (min-width: 1290px) {
  .pulse-carousel-container .owl-carousel .owl-item .item {
    width: 318px !important;
  }
}
/* Operation Review and CaseStudy Links CSS Start */
.opereationreview-wrapper {
  background: linear-gradient(-225deg, rgb(249, 250, 251) 0%, rgb(196, 205, 213) 100%);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem 1.25rem;
}
.opereationreview-wrapper h3,
.casestudy-pulsewrapper h3 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.22px;
  line-height: 24px;
  margin-bottom: 0;
}
.or-carousel-container {
  position: relative;
  margin-bottom: 2rem;
}
.or-carousel-container .carousel-control-next-icon,
.or-carousel-container .carousel-control-prev-icon {
  background-image: none;
  position: relative;
}
.or-carousel-container .carousel-control-next-icon::before,
.or-carousel-container .carousel-control-prev-icon::before {
  content: "\F104";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  top: 0;
  font-size: 20px;
  color: #919eab;
}
.or-carousel-container .carousel-control-next-icon::before {
  content: "\F105";
}
.or-carousel-container .carousel-indicators {
  bottom: -2.5rem;
}
.or-carousel-container .carousel-indicators li {
  width: 5px;
  height: 5px;
  background: rgb(145, 158, 171);
  border-radius: 50%;
  border: none;
}
.or-carousel-container .carousel-indicators li.active,
.or-carousel-container .carousel-indicators li:hover {
  background: rgb(1, 142, 255);
}
.or-carousel-container .carousel-item {
  padding: 0 12px;
}
.or-carousel-container .carousel-control-next,
.or-carousel-container .carousel-control-prev {
  width: 10px;
  height: 10px;
  top: 42%;
}
.or-carousel-container .carousel-control-prev {
  left: -10px;
}
.or-carousel-container .carousel-control-next {
  right: -2px;
}
.or-carousel-container .morcard-grid {
  position: relative;
}
.or-carousel-container .morcard-gridfooter {
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 4px 4px;
  padding: 8px;
  border-top: none;
  position: absolute;
  width: 100%;
  bottom: 0;
  justify-content: flex-start;
}
.or-carousel-container .morcard-gridfooter p {
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-bottom: 0;
  line-height: 14px;
}
.or-carousel-container .morcard-grid .morcard-image {
  padding: 0;
  height: 135px;
}
.or-carousel-container .morcard-grid .morcard-image img {
  margin-bottom: 32px;
}
.oritems-seeall {
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  position: absolute;
  right: 10px;
  bottom: -30px;
  cursor: pointer;
}
.casestudy-pulsewrapper {
  background: linear-gradient(-225deg, rgb(249, 250, 251) 0%, rgb(196, 205, 213) 100%);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.casestudy-pulsewrapper .cscard-image img {
  height: 126px;
  width: 100%;
  border-radius: 8px 8px 0 0;
}
.casestudy-pulsewrapper .cscard-heading {
  padding: 1rem;
  position: relative;
}
.or-carousel-container .carousel-control-next-icon:hover::before,
.or-carousel-container .carousel-control-prev-icon:hover::before,
.or-carousel-container .carousel-control-next-icon.active::before,
.or-carousel-container .carousel-control-prev-icon.active::before {
  color: #018eff;
}
.pulsemoritem-modal .modal-dialog {
  max-width: 1155px;
}
.pulsemoritem-modal .main-component {
  margin: 0;
  padding: 0;
  background: transparent;
}
.pulsemoritem-modal .modal-body {
  padding-top: 0px;
}
.pulsemor-title {
  display: flex;
  align-items: center;
}
.pulsemor-title h4 {
  margin-bottom: 0;
}
.pulsemor-title span {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  padding: 10px 16px;
  margin-left: 1rem;
  cursor: pointer;
}
.addcasestudy-pulsewrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
}
.cscard-heading.common-ellipsis-dropdown .dropdown {
  right: 6px;
  bottom: 8px;
}
.mor-view-more,
.mor-view-more:hover,
.mor-view-more:focus,
.mor-view-more:active {
  color: rgb(161, 0, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(161, 0, 255);
  border-radius: 16px;
  padding: 8px 16px;
  margin-left: 16px;
}
.pulsemoritem-modal .morcards-gridlist .morcard-grid {
  margin: 0 30px 16px 30px;
}
.pulsemoritem-modal .morcards-listgrid,
.pulsemoritem-modal .morcards-gridlist {
  position: relative;
  max-height: 390px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 4px;
  min-height: 300px;
}
.pulsemoritem-modal .mor-gridtable td span.doc-img-align::before {
  z-index: 0;
}
.pulsemoritem-modal .mor-gridtable td span.doc-img-align img {
  position: relative;
}

.item.morcard-grid .pdf-color {
  background: linear-gradient(-225deg, rgb(170, 0, 37) 0%, rgb(213, 0, 47) 100%);
  border-radius: 4px 4px 4px 4px;
}
.item.morcard-grid .img-color {
  background: linear-gradient(-225deg, rgb(190, 130, 255) 0%, rgb(235, 6, 117) 100%);
  border-radius: 4px 4px 4px 4px;
}
.item.morcard-grid .video-color {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(2, 186, 255) 100%);
  border-radius: 4px 4px 4px 4px;
}
.item.morcard-grid .docx-color {
  background: linear-gradient(-45deg, rgb(0, 186, 255) 0%, rgb(0, 65, 240) 100%);
  border-radius: 4px 4px 4px 4px;
}
.item.morcard-grid .folder-color {
  background: linear-gradient(-225deg, rgb(193, 94, 11) 0%, rgb(244, 147, 66) 100%);
  border-radius: 4px 4px 4px 4px;
}
.item.morcard-grid .excel-color {
  background: linear-gradient(-225deg, rgb(82, 134, 38) 0%, rgb(126, 195, 68) 100%);
  border-radius: 4px 4px 4px 4px;
}
.item.morcard-grid .pblx-color {
  background: linear-gradient(-225deg, rgb(230, 176, 25) 0%, rgb(255, 200, 44) 100%);
  border-radius: 4px 4px 4px 4px;
}
.item.morcard-grid .docs-color {
  background: linear-gradient(-225deg, rgb(99, 115, 129) 0%, rgb(145, 158, 171) 100%);
  border-radius: 4px 4px 4px 4px;
}
.item.morcard-grid .ppt-color {
  background: linear-gradient(-225deg, rgb(171, 87, 90) 0%, rgb(249, 130, 133) 100%);
  border-radius: 4px 4px 4px 4px;
}

.pulsehub-wrapper .hub-btnsection a {
  color: rgba(255, 255, 255, 0.8) !important;
}
#caseStudy-tooltip {
  max-width: 290px;
}

.pulsemoritem-modal .modal-content {
  min-height: 600px;
}
.addcasestudy-pulsewrapper h2 {
  font-size: 16px;
  position: absolute;
  margin-bottom: 41px;
  text-transform: uppercase;
  font-family: Graphik-Medium;
  font-weight: 500;
  color: rgb(33, 43, 54);
}
.pulsemoritem-modal .morcards-listgrid .no-data-message {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*------------------------------*/

.graphical {
  position: absolute;
  right: 30px;
  top: 60px;
  font-size: 18px !important;
  color: #a100ff;
}

.powerBI-modal .modal-dialog {
  width: 90vw;
  max-width: none !important;
}

.pulse-carousel-container .owl-carousel .owl-item .bottom-content .pulse-tool-summary img {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-left: 5px;
}
.pulse-carousel-container .owl-carousel .dropdown-menu a .tool-summary-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url(../Images/tool-summary-normal.svg) no-repeat;
  width: 14px;
  height: 14px;
  left: -22px;
  top: -1px;
}
.pulse-carousel-container .owl-carousel .dropdown-menu a:hover .tool-summary-icon::before {
  background: url(../Images/tool-summary-gradient.svg) no-repeat;
}
.pulse-carousel-container .__react_component_tooltip {
  background: rgb(28, 38, 47);
  border-radius: 2px;
}
.pulse-carousel-container .__react_component_tooltip.place-bottom {
  margin-left: 20px;
}
.pulse-carousel-container .__react_component_tooltip.place-bottom:after {
  left: 42%;
}
.pulse-carousel-container .__react_component_tooltip.place-right.type-dark {
  margin-top: 2.5rem;
  margin-left: -4rem;
}
.pulse-carousel-container .__react_component_tooltip span {
  width: 207px;
  height: 11px;
  color: rgb(255, 255, 255);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 11px;
}
.pulse-carousel-container .__react_component_tooltip.type-dark.place-right:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 20%;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  border: 8px solid transparent;
  border-top-color: rgb(28, 38, 47);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
.__react_component_tooltip.place-right:after {
  border-top: 6px solid transparent;
  border-bottom: 8px solid transparent;
}
.summary-header .filtered-value .iwgph-tooltip {
  display: inline-block;
}
.groups-names-tooltip {
  margin-left: 5px;
}
.iwgph-filters .formsection-fields.form-section {
  width: 50%;
}
.iwgph-filters .formsection-fields.form-section:first-child {
  margin-right: 1rem;
}
.iwgph-filters .show > .btn-primary.dropdown-toggle,
.iwgph-filters .show > .btn-primary.dropdown-toggle:focus,
.iwgph-filters .show > .btn-primary.dropdown-toggle:active,
.iwgph-filters .formsection-fields.form-section button:focus,
.iwgph-filters .formsection-fields.form-section button:active,
.iwgph-filters .formsection-fields.form-section button:active:focus {
  background-color: transparent !important;
  color: #919eab;
}
.iwgph-filters .formsection-fields .form-group.border-group {
  width: 100%;
}
.summary-header .filtered-value .iwgph-tooltip {
  display: inline-block;
}
.groups-names-tooltip {
  margin-left: 5px;
}
.iwgph-filters .formsection-fields.form-section {
  width: 50%;
}
.iwgph-filters .formsection-fields.form-section:first-child {
  margin-right: 1rem;
}
.iwgph-filters .show > .btn-primary.dropdown-toggle,
.iwgph-filters .show > .btn-primary.dropdown-toggle:focus,
.iwgph-filters .show > .btn-primary.dropdown-toggle:active,
.iwgph-filters .formsection-fields.form-section button:focus,
.iwgph-filters .formsection-fields.form-section button:active,
.iwgph-filters .formsection-fields.form-section button:active:focus {
  background-color: transparent !important;
  color: #919eab;
}
.iwgph-filters .formsection-fields .form-group.border-group {
  width: 100%;
}
.pulsemoritem-modal .modal-body {
  padding-left: 0.5rem;
}
.pulsemoritem-modal .modal-body .mor-gridtable.table thead th:nth-child(3),
.pulsemoritem-modal .modal-body .mor-gridtable.table tbody tr td:nth-child(3) {
  padding-left: 0px !important;
}
.reportClass {
  height: 500px;
}
.reportClass iframe {
  border-style: none;
}
