.rect-auto,
.rd-progress.p51 .rd-slice,
.rd-progress.p52 .rd-slice,
.rd-progress.p53 .rd-slice,
.rd-progress.p54 .rd-slice,
.rd-progress.p55 .rd-slice,
.rd-progress.p56 .rd-slice,
.rd-progress.p57 .rd-slice,
.rd-progress.p58 .rd-slice,
.rd-progress.p59 .rd-slice,
.rd-progress.p60 .rd-slice,
.rd-progress.p61 .rd-slice,
.rd-progress.p62 .rd-slice,
.rd-progress.p63 .rd-slice,
.rd-progress.p64 .rd-slice,
.rd-progress.p65 .rd-slice,
.rd-progress.p66 .rd-slice,
.rd-progress.p67 .rd-slice,
.rd-progress.p68 .rd-slice,
.rd-progress.p69 .rd-slice,
.rd-progress.p70 .rd-slice,
.rd-progress.p71 .rd-slice,
.rd-progress.p72 .rd-slice,
.rd-progress.p73 .rd-slice,
.rd-progress.p74 .rd-slice,
.rd-progress.p75 .rd-slice,
.rd-progress.p76 .rd-slice,
.rd-progress.p77 .rd-slice,
.rd-progress.p78 .rd-slice,
.rd-progress.p79 .rd-slice,
.rd-progress.p80 .rd-slice,
.rd-progress.p81 .rd-slice,
.rd-progress.p82 .rd-slice,
.rd-progress.p83 .rd-slice,
.rd-progress.p84 .rd-slice,
.rd-progress.p85 .rd-slice,
.rd-progress.p86 .rd-slice,
.rd-progress.p87 .rd-slice,
.rd-progress.p88 .rd-slice,
.rd-progress.p89 .rd-slice,
.rd-progress.p90 .rd-slice,
.rd-progress.p91 .rd-slice,
.rd-progress.p92 .rd-slice,
.rd-progress.p93 .rd-slice,
.rd-progress.p94 .rd-slice,
.rd-progress.p95 .rd-slice,
.rd-progress.p96 .rd-slice,
.rd-progress.p97 .rd-slice,
.rd-progress.p98 .rd-slice,
.rd-progress.p99 .rd-slice,
.rd-progress.p100 .rd-slice {
  clip: rect(auto, auto, auto, auto);
}
.pie,
.rd-progress .rd-bar,
.rd-progress.p51 .rd-fill,
.rd-progress.p52 .rd-fill,
.rd-progress.p53 .rd-fill,
.rd-progress.p54 .rd-fill,
.rd-progress.p55 .rd-fill,
.rd-progress.p56 .rd-fill,
.rd-progress.p57 .rd-fill,
.rd-progress.p58 .rd-fill,
.rd-progress.p59 .rd-fill,
.rd-progress.p60 .rd-fill,
.rd-progress.p61 .rd-fill,
.rd-progress.p62 .rd-fill,
.rd-progress.p63 .rd-fill,
.rd-progress.p64 .rd-fill,
.rd-progress.p65 .rd-fill,
.rd-progress.p66 .rd-fill,
.rd-progress.p67 .rd-fill,
.rd-progress.p68 .rd-fill,
.rd-progress.p69 .rd-fill,
.rd-progress.p70 .rd-fill,
.rd-progress.p71 .rd-fill,
.rd-progress.p72 .rd-fill,
.rd-progress.p73 .rd-fill,
.rd-progress.p74 .rd-fill,
.rd-progress.p75 .rd-fill,
.rd-progress.p76 .rd-fill,
.rd-progress.p77 .rd-fill,
.rd-progress.p78 .rd-fill,
.rd-progress.p79 .rd-fill,
.rd-progress.p80 .rd-fill,
.rd-progress.p81 .rd-fill,
.rd-progress.p82 .rd-fill,
.rd-progress.p83 .rd-fill,
.rd-progress.p84 .rd-fill,
.rd-progress.p85 .rd-fill,
.rd-progress.p86 .rd-fill,
.rd-progress.p87 .rd-fill,
.rd-progress.p88 .rd-fill,
.rd-progress.p89 .rd-fill,
.rd-progress.p90 .rd-fill,
.rd-progress.p91 .rd-fill,
.rd-progress.p92 .rd-fill,
.rd-progress.p93 .rd-fill,
.rd-progress.p94 .rd-fill,
.rd-progress.p95 .rd-fill,
.rd-progress.p96 .rd-fill,
.rd-progress.p97 .rd-fill,
.rd-progress.p98 .rd-fill,
.rd-progress.p99 .rd-fill,
.rd-progress.p100 .rd-fill {
    position: absolute;
    border: 8px solid #7ec344;
    width: 80px;
    height: 80px;
    clip: rect(0px, 40px, 80px, 0);
    border-radius: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.pie-fill,
.rd-progress.p51 .rd-bar:after,
.rd-progress.p51 .rd-fill,
.rd-progress.p52 .rd-bar:after,
.rd-progress.p52 .rd-fill,
.rd-progress.p53 .rd-bar:after,
.rd-progress.p53 .rd-fill,
.rd-progress.p54 .rd-bar:after,
.rd-progress.p54 .rd-fill,
.rd-progress.p55 .rd-bar:after,
.rd-progress.p55 .rd-fill,
.rd-progress.p56 .rd-bar:after,
.rd-progress.p56 .rd-fill,
.rd-progress.p57 .rd-bar:after,
.rd-progress.p57 .rd-fill,
.rd-progress.p58 .rd-bar:after,
.rd-progress.p58 .rd-fill,
.rd-progress.p59 .rd-bar:after,
.rd-progress.p59 .rd-fill,
.rd-progress.p60 .rd-bar:after,
.rd-progress.p60 .rd-fill,
.rd-progress.p61 .rd-bar:after,
.rd-progress.p61 .rd-fill,
.rd-progress.p62 .rd-bar:after,
.rd-progress.p62 .rd-fill,
.rd-progress.p63 .rd-bar:after,
.rd-progress.p63 .rd-fill,
.rd-progress.p64 .rd-bar:after,
.rd-progress.p64 .rd-fill,
.rd-progress.p65 .rd-bar:after,
.rd-progress.p65 .rd-fill,
.rd-progress.p66 .rd-bar:after,
.rd-progress.p66 .rd-fill,
.rd-progress.p67 .rd-bar:after,
.rd-progress.p67 .rd-fill,
.rd-progress.p68 .rd-bar:after,
.rd-progress.p68 .rd-fill,
.rd-progress.p69 .rd-bar:after,
.rd-progress.p69 .rd-fill,
.rd-progress.p70 .rd-bar:after,
.rd-progress.p70 .rd-fill,
.rd-progress.p71 .rd-bar:after,
.rd-progress.p71 .rd-fill,
.rd-progress.p72 .rd-bar:after,
.rd-progress.p72 .rd-fill,
.rd-progress.p73 .rd-bar:after,
.rd-progress.p73 .rd-fill,
.rd-progress.p74 .rd-bar:after,
.rd-progress.p74 .rd-fill,
.rd-progress.p75 .rd-bar:after,
.rd-progress.p75 .rd-fill,
.rd-progress.p76 .rd-bar:after,
.rd-progress.p76 .rd-fill,
.rd-progress.p77 .rd-bar:after,
.rd-progress.p77 .rd-fill,
.rd-progress.p78 .rd-bar:after,
.rd-progress.p78 .rd-fill,
.rd-progress.p79 .rd-bar:after,
.rd-progress.p79 .rd-fill,
.rd-progress.p80 .rd-bar:after,
.rd-progress.p80 .rd-fill,
.rd-progress.p81 .rd-bar:after,
.rd-progress.p81 .rd-fill,
.rd-progress.p82 .rd-bar:after,
.rd-progress.p82 .rd-fill,
.rd-progress.p83 .rd-bar:after,
.rd-progress.p83 .rd-fill,
.rd-progress.p84 .rd-bar:after,
.rd-progress.p84 .rd-fill,
.rd-progress.p85 .rd-bar:after,
.rd-progress.p85 .rd-fill,
.rd-progress.p86 .rd-bar:after,
.rd-progress.p86 .rd-fill,
.rd-progress.p87 .rd-bar:after,
.rd-progress.p87 .rd-fill,
.rd-progress.p88 .rd-bar:after,
.rd-progress.p88 .rd-fill,
.rd-progress.p89 .rd-bar:after,
.rd-progress.p89 .rd-fill,
.rd-progress.p90 .rd-bar:after,
.rd-progress.p90 .rd-fill,
.rd-progress.p91 .rd-bar:after,
.rd-progress.p91 .rd-fill,
.rd-progress.p92 .rd-bar:after,
.rd-progress.p92 .rd-fill,
.rd-progress.p93 .rd-bar:after,
.rd-progress.p93 .rd-fill,
.rd-progress.p94 .rd-bar:after,
.rd-progress.p94 .rd-fill,
.rd-progress.p95 .rd-bar:after,
.rd-progress.p95 .rd-fill,
.rd-progress.p96 .rd-bar:after,
.rd-progress.p96 .rd-fill,
.rd-progress.p97 .rd-bar:after,
.rd-progress.p97 .rd-fill,
.rd-progress.p98 .rd-bar:after,
.rd-progress.p98 .rd-fill,
.rd-progress.p99 .rd-bar:after,
.rd-progress.p99 .rd-fill,
.rd-progress.p100 .rd-bar:after,
.rd-progress.p100 .rd-fill {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rd-progress *, .rd-progress *::after, .rd-progress *::before {
    box-sizing: border-box !important;
}
.rd-progress {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* margin: 0 0.1em 0.1em 0; */
    background-color: #fafafa;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.02) inset;
    display: inline-block;
    vertical-align: top;
}
.rd-progress:after {
    position: absolute;
    top: 8px;
    left: 8px;
    display: block;
    content: " ";
    border-radius: 50%;
    background-color: #ffffff;
    width: 64px;
    height: 64px;
    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-in;
    -moz-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}
.rd-progress > span {
    font-size: 18px;
    letter-spacing: -0.35px;
    font-family: graphik-medium;
    padding-top: 28px;
    color: #212b36;
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    display: block;
    text-align: center;
    white-space: nowrap;
    -webkit-transition-property: all;
    -moz-transition-property: all;
    -o-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.rd-progress .rd-slice {
    position: absolute;
    width: 80px;
    height: 80px;
    clip: rect(0, 80px, 80px, 40px);
}
/* .rd-progress {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  background-color: #cccccc;
}
.rd-progress.center {
  float: none;
  margin: 0 auto;
}
.rd-progress.big {
  font-size: 240px;
}
.rd-progress.small {
  font-size: 80px;
}
.rd-progress > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: #cccccc;
  display: block;
  text-align: center;
  white-space: nowrap;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.rd-progress:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: #f5f5f5;
  width: 0.84em;
  height: 0.84em;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.rd-progress .rd-slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
} */
.rd-progress.p1 .rd-bar {
  -webkit-transform: rotate(3.6deg);
  -moz-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  -o-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.rd-progress.p2 .rd-bar {
  -webkit-transform: rotate(7.2deg);
  -moz-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  -o-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.rd-progress.p3 .rd-bar {
  -webkit-transform: rotate(10.8deg);
  -moz-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  -o-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.rd-progress.p4 .rd-bar {
  -webkit-transform: rotate(14.4deg);
  -moz-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  -o-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.rd-progress.p5 .rd-bar {
  -webkit-transform: rotate(18deg);
  -moz-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  -o-transform: rotate(18deg);
  transform: rotate(18deg);
}
.rd-progress.p6 .rd-bar {
  -webkit-transform: rotate(21.6deg);
  -moz-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  -o-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.rd-progress.p7 .rd-bar {
  -webkit-transform: rotate(25.2deg);
  -moz-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  -o-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.rd-progress.p8 .rd-bar {
  -webkit-transform: rotate(28.8deg);
  -moz-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  -o-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.rd-progress.p9 .rd-bar {
  -webkit-transform: rotate(32.4deg);
  -moz-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  -o-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.rd-progress.p10 .rd-bar {
  -webkit-transform: rotate(36deg);
  -moz-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  -o-transform: rotate(36deg);
  transform: rotate(36deg);
}
.rd-progress.p11 .rd-bar {
  -webkit-transform: rotate(39.6deg);
  -moz-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  -o-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.rd-progress.p12 .rd-bar {
  -webkit-transform: rotate(43.2deg);
  -moz-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  -o-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.rd-progress.p13 .rd-bar {
  -webkit-transform: rotate(46.800000000000004deg);
  -moz-transform: rotate(46.800000000000004deg);
  -ms-transform: rotate(46.800000000000004deg);
  -o-transform: rotate(46.800000000000004deg);
  transform: rotate(46.800000000000004deg);
}
.rd-progress.p14 .rd-bar {
  -webkit-transform: rotate(50.4deg);
  -moz-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  -o-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.rd-progress.p15 .rd-bar {
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  transform: rotate(54deg);
}
.rd-progress.p16 .rd-bar {
  -webkit-transform: rotate(57.6deg);
  -moz-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  -o-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.rd-progress.p17 .rd-bar {
  -webkit-transform: rotate(61.2deg);
  -moz-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  -o-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.rd-progress.p18 .rd-bar {
  -webkit-transform: rotate(64.8deg);
  -moz-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  -o-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.rd-progress.p19 .rd-bar {
  -webkit-transform: rotate(68.4deg);
  -moz-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  -o-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.rd-progress.p20 .rd-bar {
  -webkit-transform: rotate(72deg);
  -moz-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  -o-transform: rotate(72deg);
  transform: rotate(72deg);
}
.rd-progress.p21 .rd-bar {
  -webkit-transform: rotate(75.60000000000001deg);
  -moz-transform: rotate(75.60000000000001deg);
  -ms-transform: rotate(75.60000000000001deg);
  -o-transform: rotate(75.60000000000001deg);
  transform: rotate(75.60000000000001deg);
}
.rd-progress.p22 .rd-bar {
  -webkit-transform: rotate(79.2deg);
  -moz-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  -o-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.rd-progress.p23 .rd-bar {
  -webkit-transform: rotate(82.8deg);
  -moz-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  -o-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.rd-progress.p24 .rd-bar {
  -webkit-transform: rotate(86.4deg);
  -moz-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  -o-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.rd-progress.p25 .rd-bar {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rd-progress.p26 .rd-bar {
  -webkit-transform: rotate(93.60000000000001deg);
  -moz-transform: rotate(93.60000000000001deg);
  -ms-transform: rotate(93.60000000000001deg);
  -o-transform: rotate(93.60000000000001deg);
  transform: rotate(93.60000000000001deg);
}
.rd-progress.p27 .rd-bar {
  -webkit-transform: rotate(97.2deg);
  -moz-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  -o-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.rd-progress.p28 .rd-bar {
  -webkit-transform: rotate(100.8deg);
  -moz-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  -o-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.rd-progress.p29 .rd-bar {
  -webkit-transform: rotate(104.4deg);
  -moz-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  -o-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.rd-progress.p30 .rd-bar {
  -webkit-transform: rotate(108deg);
  -moz-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  -o-transform: rotate(108deg);
  transform: rotate(108deg);
}
.rd-progress.p31 .rd-bar {
  -webkit-transform: rotate(111.60000000000001deg);
  -moz-transform: rotate(111.60000000000001deg);
  -ms-transform: rotate(111.60000000000001deg);
  -o-transform: rotate(111.60000000000001deg);
  transform: rotate(111.60000000000001deg);
}
.rd-progress.p32 .rd-bar {
  -webkit-transform: rotate(115.2deg);
  -moz-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  -o-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.rd-progress.p33 .rd-bar {
  -webkit-transform: rotate(118.8deg);
  -moz-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  -o-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.rd-progress.p34 .rd-bar {
  -webkit-transform: rotate(122.4deg);
  -moz-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  -o-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.rd-progress.p35 .rd-bar {
  -webkit-transform: rotate(126deg);
  -moz-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  -o-transform: rotate(126deg);
  transform: rotate(126deg);
}
.rd-progress.p36 .rd-bar {
  -webkit-transform: rotate(129.6deg);
  -moz-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  -o-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.rd-progress.p37 .rd-bar {
  -webkit-transform: rotate(133.20000000000002deg);
  -moz-transform: rotate(133.20000000000002deg);
  -ms-transform: rotate(133.20000000000002deg);
  -o-transform: rotate(133.20000000000002deg);
  transform: rotate(133.20000000000002deg);
}
.rd-progress.p38 .rd-bar {
  -webkit-transform: rotate(136.8deg);
  -moz-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  -o-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.rd-progress.p39 .rd-bar {
  -webkit-transform: rotate(140.4deg);
  -moz-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  -o-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.rd-progress.p40 .rd-bar {
  -webkit-transform: rotate(144deg);
  -moz-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  -o-transform: rotate(144deg);
  transform: rotate(144deg);
}
.rd-progress.p41 .rd-bar {
  -webkit-transform: rotate(147.6deg);
  -moz-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  -o-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.rd-progress.p42 .rd-bar {
  -webkit-transform: rotate(151.20000000000002deg);
  -moz-transform: rotate(151.20000000000002deg);
  -ms-transform: rotate(151.20000000000002deg);
  -o-transform: rotate(151.20000000000002deg);
  transform: rotate(151.20000000000002deg);
}
.rd-progress.p43 .rd-bar {
  -webkit-transform: rotate(154.8deg);
  -moz-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  -o-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.rd-progress.p44 .rd-bar {
  -webkit-transform: rotate(158.4deg);
  -moz-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  -o-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.rd-progress.p45 .rd-bar {
  -webkit-transform: rotate(162deg);
  -moz-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  -o-transform: rotate(162deg);
  transform: rotate(162deg);
}
.rd-progress.p46 .rd-bar {
  -webkit-transform: rotate(165.6deg);
  -moz-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  -o-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.rd-progress.p47 .rd-bar {
  -webkit-transform: rotate(169.20000000000002deg);
  -moz-transform: rotate(169.20000000000002deg);
  -ms-transform: rotate(169.20000000000002deg);
  -o-transform: rotate(169.20000000000002deg);
  transform: rotate(169.20000000000002deg);
}
.rd-progress.p48 .rd-bar {
  -webkit-transform: rotate(172.8deg);
  -moz-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  -o-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.rd-progress.p49 .rd-bar {
  -webkit-transform: rotate(176.4deg);
  -moz-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  -o-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.rd-progress.p50 .rd-bar {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rd-progress.p51 .rd-bar {
  -webkit-transform: rotate(183.6deg);
  -moz-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  -o-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.rd-progress.p52 .rd-bar {
  -webkit-transform: rotate(187.20000000000002deg);
  -moz-transform: rotate(187.20000000000002deg);
  -ms-transform: rotate(187.20000000000002deg);
  -o-transform: rotate(187.20000000000002deg);
  transform: rotate(187.20000000000002deg);
}
.rd-progress.p53 .rd-bar {
  -webkit-transform: rotate(190.8deg);
  -moz-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  -o-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.rd-progress.p54 .rd-bar {
  -webkit-transform: rotate(194.4deg);
  -moz-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  -o-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.rd-progress.p55 .rd-bar {
  -webkit-transform: rotate(198deg);
  -moz-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  -o-transform: rotate(198deg);
  transform: rotate(198deg);
}
.rd-progress.p56 .rd-bar {
  -webkit-transform: rotate(201.6deg);
  -moz-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  -o-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.rd-progress.p57 .rd-bar {
  -webkit-transform: rotate(205.20000000000002deg);
  -moz-transform: rotate(205.20000000000002deg);
  -ms-transform: rotate(205.20000000000002deg);
  -o-transform: rotate(205.20000000000002deg);
  transform: rotate(205.20000000000002deg);
}
.rd-progress.p58 .rd-bar {
  -webkit-transform: rotate(208.8deg);
  -moz-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  -o-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.rd-progress.p59 .rd-bar {
  -webkit-transform: rotate(212.4deg);
  -moz-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  -o-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.rd-progress.p60 .rd-bar {
  -webkit-transform: rotate(216deg);
  -moz-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  -o-transform: rotate(216deg);
  transform: rotate(216deg);
}
.rd-progress.p61 .rd-bar {
  -webkit-transform: rotate(219.6deg);
  -moz-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  -o-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.rd-progress.p62 .rd-bar {
  -webkit-transform: rotate(223.20000000000002deg);
  -moz-transform: rotate(223.20000000000002deg);
  -ms-transform: rotate(223.20000000000002deg);
  -o-transform: rotate(223.20000000000002deg);
  transform: rotate(223.20000000000002deg);
}
.rd-progress.p63 .rd-bar {
  -webkit-transform: rotate(226.8deg);
  -moz-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  -o-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.rd-progress.p64 .rd-bar {
  -webkit-transform: rotate(230.4deg);
  -moz-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  -o-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.rd-progress.p65 .rd-bar {
  -webkit-transform: rotate(234deg);
  -moz-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  -o-transform: rotate(234deg);
  transform: rotate(234deg);
}
.rd-progress.p66 .rd-bar {
  -webkit-transform: rotate(237.6deg);
  -moz-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  -o-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.rd-progress.p67 .rd-bar {
  -webkit-transform: rotate(241.20000000000002deg);
  -moz-transform: rotate(241.20000000000002deg);
  -ms-transform: rotate(241.20000000000002deg);
  -o-transform: rotate(241.20000000000002deg);
  transform: rotate(241.20000000000002deg);
}
.rd-progress.p68 .rd-bar {
  -webkit-transform: rotate(244.8deg);
  -moz-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  -o-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.rd-progress.p69 .rd-bar {
  -webkit-transform: rotate(248.4deg);
  -moz-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  -o-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.rd-progress.p70 .rd-bar {
  -webkit-transform: rotate(252deg);
  -moz-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  -o-transform: rotate(252deg);
  transform: rotate(252deg);
}
.rd-progress.p71 .rd-bar {
  -webkit-transform: rotate(255.6deg);
  -moz-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  -o-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.rd-progress.p72 .rd-bar {
  -webkit-transform: rotate(259.2deg);
  -moz-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  -o-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.rd-progress.p73 .rd-bar {
  -webkit-transform: rotate(262.8deg);
  -moz-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  -o-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.rd-progress.p74 .rd-bar {
  -webkit-transform: rotate(266.40000000000003deg);
  -moz-transform: rotate(266.40000000000003deg);
  -ms-transform: rotate(266.40000000000003deg);
  -o-transform: rotate(266.40000000000003deg);
  transform: rotate(266.40000000000003deg);
}
.rd-progress.p75 .rd-bar {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.rd-progress.p76 .rd-bar {
  -webkit-transform: rotate(273.6deg);
  -moz-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  -o-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.rd-progress.p77 .rd-bar {
  -webkit-transform: rotate(277.2deg);
  -moz-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  -o-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.rd-progress.p78 .rd-bar {
  -webkit-transform: rotate(280.8deg);
  -moz-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  -o-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.rd-progress.p79 .rd-bar {
  -webkit-transform: rotate(284.40000000000003deg);
  -moz-transform: rotate(284.40000000000003deg);
  -ms-transform: rotate(284.40000000000003deg);
  -o-transform: rotate(284.40000000000003deg);
  transform: rotate(284.40000000000003deg);
}
.rd-progress.p80 .rd-bar {
  -webkit-transform: rotate(288deg);
  -moz-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  -o-transform: rotate(288deg);
  transform: rotate(288deg);
}
.rd-progress.p81 .rd-bar {
  -webkit-transform: rotate(291.6deg);
  -moz-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  -o-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.rd-progress.p82 .rd-bar {
  -webkit-transform: rotate(295.2deg);
  -moz-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  -o-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.rd-progress.p83 .rd-bar {
  -webkit-transform: rotate(298.8deg);
  -moz-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  -o-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.rd-progress.p84 .rd-bar {
  -webkit-transform: rotate(302.40000000000003deg);
  -moz-transform: rotate(302.40000000000003deg);
  -ms-transform: rotate(302.40000000000003deg);
  -o-transform: rotate(302.40000000000003deg);
  transform: rotate(302.40000000000003deg);
}
.rd-progress.p85 .rd-bar {
  -webkit-transform: rotate(306deg);
  -moz-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  -o-transform: rotate(306deg);
  transform: rotate(306deg);
}
.rd-progress.p86 .rd-bar {
  -webkit-transform: rotate(309.6deg);
  -moz-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  -o-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.rd-progress.p87 .rd-bar {
  -webkit-transform: rotate(313.2deg);
  -moz-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  -o-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.rd-progress.p88 .rd-bar {
  -webkit-transform: rotate(316.8deg);
  -moz-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  -o-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.rd-progress.p89 .rd-bar {
  -webkit-transform: rotate(320.40000000000003deg);
  -moz-transform: rotate(320.40000000000003deg);
  -ms-transform: rotate(320.40000000000003deg);
  -o-transform: rotate(320.40000000000003deg);
  transform: rotate(320.40000000000003deg);
}
.rd-progress.p90 .rd-bar {
  -webkit-transform: rotate(324deg);
  -moz-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  -o-transform: rotate(324deg);
  transform: rotate(324deg);
}
.rd-progress.p91 .rd-bar {
  -webkit-transform: rotate(327.6deg);
  -moz-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  -o-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.rd-progress.p92 .rd-bar {
  -webkit-transform: rotate(331.2deg);
  -moz-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  -o-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.rd-progress.p93 .rd-bar {
  -webkit-transform: rotate(334.8deg);
  -moz-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  -o-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.rd-progress.p94 .rd-bar {
  -webkit-transform: rotate(338.40000000000003deg);
  -moz-transform: rotate(338.40000000000003deg);
  -ms-transform: rotate(338.40000000000003deg);
  -o-transform: rotate(338.40000000000003deg);
  transform: rotate(338.40000000000003deg);
}
.rd-progress.p95 .rd-bar {
  -webkit-transform: rotate(342deg);
  -moz-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  -o-transform: rotate(342deg);
  transform: rotate(342deg);
}
.rd-progress.p96 .rd-bar {
  -webkit-transform: rotate(345.6deg);
  -moz-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  -o-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.rd-progress.p97 .rd-bar {
  -webkit-transform: rotate(349.2deg);
  -moz-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  -o-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.rd-progress.p98 .rd-bar {
  -webkit-transform: rotate(352.8deg);
  -moz-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  -o-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.rd-progress.p99 .rd-bar {
  -webkit-transform: rotate(356.40000000000003deg);
  -moz-transform: rotate(356.40000000000003deg);
  -ms-transform: rotate(356.40000000000003deg);
  -o-transform: rotate(356.40000000000003deg);
  transform: rotate(356.40000000000003deg);
}
.rd-progress.p100 .rd-bar {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
/* .rd-progress:hover {
  cursor: default;
}
.rd-progress:hover > span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  color: #307bbb;
}
.rd-progress:hover:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}
.rd-progress.dark {
  background-color: #777777;
}
.rd-progress.dark .rd-bar,
.rd-progress.dark .rd-fill {
  border-color: #c6ff00 !important;
}
.rd-progress.dark > span {
  color: #777777;
}
.rd-progress.dark:after {
  background-color: #666666;
}
.rd-progress.dark:hover > span {
  color: #c6ff00;
}
.rd-progress.green .rd-bar,
.rd-progress.green .rd-fill {
  border-color: #4db53c !important;
}
.rd-progress.green:hover > span {
  color: #4db53c;
}
.rd-progress.green.dark .rd-bar,
.rd-progress.green.dark .rd-fill {
  border-color: #5fd400 !important;
}
.rd-progress.green.dark:hover > span {
  color: #5fd400;
}
.rd-progress.orange .rd-bar,
.rd-progress.orange .rd-fill {
  border-color: #dd9d22 !important;
}
.rd-progress.orange:hover > span {
  color: #dd9d22;
}
.rd-progress.orange.dark .rd-bar,
.rd-progress.orange.dark .rd-fill {
  border-color: #e08833 !important;
}
.rd-progress.orange.dark:hover > span {
  color: #e08833;
}
 */ 
.gray-clr .rd-progress .rd-bar, .gray-clr .rd-progress .rd-fill {
    border-color: #919eab;
}
.dark-gray-clr .rd-progress .rd-bar, .dark-gray-clr .rd-progress .rd-fill {
    border-color: #333333;
}
.blue-clr .rd-progress .rd-bar, .blue-clr .rd-progress .rd-fill {
    border-color: #008eff;
}
.lt-blue-clr .rd-progress .rd-bar, .lt-blue-clr .rd-progress .rd-fill {
    border-color: #00baff;
}
.lightblue-clr .rd-progress .rd-bar, .lightblue-clr .rd-progress .rd-fill {
    border-color: #00BBFF;
}
.dark-blue-clr .rd-progress .rd-bar, .dark-blue-clr .rd-progress .rd-fill {
    border-color: #034a85;
}
.orange-clr .rd-progress .rd-bar, .orange-clr .rd-progress .rd-fill {
    border-color: #f58430;
}
.red-clr .rd-progress .rd-bar, .red-clr .rd-progress .rd-fill {
    border-color: #d5002f;
}