.mor-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.mor-header h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
}
.morsearch-section {
  display: flex;
}
.morsearch-section .searchFilter input[type="search"] {
  width: 310px !important;
}
.morsearch-section input::-webkit-input-placeholder {
  /* Edge */
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.morsearch-section input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.morsearch-section input::placeholder {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.additem-btn .btn.btn-doublegradient {
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(117, 0, 192) 52%,
    rgb(70, 0, 115) 100%
  );
  border-radius: 20px;
  height: 40px;
  width: 103px;
  color: #ffffff;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.additem-btn .btn.btn-doublegradient:focus,
.additem-btn .btn.btn-doublegradient:hover {
  box-shadow: none;
  background: linear-gradient(-225deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%);
}
.additem-btn .btn.btn-doublegradient span i {
  color: #fff;
  padding-right: 10px;
}
.morfilter-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.mor-leftfilters {
  display: flex;
  align-items: center;
}
.mor-selectedclient span {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 32px;
  color: #a100ff;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 10px 16px;
}
.mor-selectedclient span:hover {
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
}
.mor-selectedclient span i {
  margin-right: 6px;
  font-weight: 500;
}
.mor-offeringdropdown-value {
  position: relative;
}
.mor-offeringdropdown-value span {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(196, 205, 213);
  height: 32px;
  padding: 10px 16px;
  position: relative;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 10px 40px 10px 16px;
  cursor: pointer;
}
.mor-offeringdropdown-value span i {
  margin-right: 6px;
  font-weight: 500;
}
.mor-offeringdropdown-value .selected {
  border: 1.5px solid rgb(161, 0, 255);
  color: #a100ff;
}
.mor-offeringdropdown-value span:after {
  content: "\F078";
  font-family: "Font awesome\ 5 Pro";
  font-weight: 700;
  position: absolute;
  right: 8px;
  top: 8px;
}
.moroffering-dropdown-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 14%;
  left: 0;
  bottom: 0;
  right: 0;
}
.moroffering-dropdownlist {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1), 1px 0px 0px 0px rgb(244, 246, 248);
  max-height: 269px;
  overflow-y: auto;
  width: 224px;
  padding: 10px;
  position: absolute;
  top: 31px;
  left: 0;
  z-index: 11111;
}
.moroffering-dropdownlist ul {
  margin: 0;
}
.moroffering-dropdownlist ul li {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 10px 20px;
}
.moroffering-dropdownlist ul li:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  cursor: pointer;
}
.mor-sortby {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.mor-sortby .mor-resultcount {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin-right: 1rem;
}
.mor-sortby:before {
  position: absolute;
  content: " ";
  left: -10px;
  background: rgb(216, 216, 216);
  border-radius: 0.5px;
  height: 100%;
  width: 1px;
  top: 0px;
  margin: auto;
}

.mor-sortby .sortby-listicon i {
  color: #a2a9af;
  cursor: pointer;
  font-size: 24px;
  margin-right: 16px;
}
.mor-sortby .sortby-listicon:hover i {
  color: #018eff;
}
.mor-sortby .sortby-listicon.active i {
  color: #018eff;
}
.mor-rightfilters {
  display: flex;
  align-items: center;
}
.morcards-gridlist {
  display: flex;
  flex-wrap: wrap;
}
.morcards-gridlist .morcard-grid {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  height: 199px;
  width: 290px;
  position: relative;
  margin: 0 8px 16px 8px;
}
.morcard-grid .morcard-image {
  padding: 0 10px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}
/* .morcard-image img{
  margin-bottom: 1.5rem;
} */

.morcard-gridfooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-top: 1px solid #dfe3e8;
}
.morcard-gridfooter p {
  margin-bottom: 0;
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  white-space: nowrap;
}
.morcard-nameblock,
.mornewitem-field {
  background-color: rgba(181, 192, 202, 0.08);
  height: 43px;
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 6px 0px;
  position: absolute;
  bottom: 56px;
  border-radius: 8px;
  left: 10px;
}
.mornewitem-field {
  background-color: #fff;
}
.mornewitem-field input {
  border-radius: 8px;
  border: 1.5px solid rgb(1, 142, 255);
  height: 43px;
}
.mornewitem-field input::-webkit-input-placeholder {
  /* Edge */
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
}
.mornewitem-field input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
}
.mornewitem-field input::placeholder {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
}
.morcard-nameblock span {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 16px;
}
.mor-gridactions {
  display: flex;
}
.mor-gridactions span {
  color: rgb(1, 142, 255);
  font-size: 15px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  cursor: pointer;
  padding-right: 32px;
  padding-left: 20px;
  position: relative;
}
.mor-gridactions span::before {
  content: "";
  position: absolute;
  top: -2px;
  bottom: 0;
  left: -2px;
  margin: auto;
  width: 16px;
  height: 16px;
}
.mor-gridactions span:hover {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mor-gridactions span.comments::before {
  background: url("../Images/comments.svg") no-repeat;
}
.mor-gridactions span.comments:hover::before,
.mor-gridactions span.comments.active::before {
  background: url("../Images/comments-hover.svg") no-repeat;
}
.mor-gridactions span.archive::before {
  background: url("../Images/archive.svg") no-repeat;
}
.mor-gridactions span.archive:hover::before,
.mor-gridactions span.archive.active::before {
  background: url("../Images/archive-hover.svg") no-repeat;
}
.mor-gridactions span.favourite::before {
  background: url("../Images/favorite.svg") no-repeat;
  top: -4px;
}
.mor-gridactions span.favourite:hover::before,
.mor-gridactions span.favourite.active::before {
  background: url("../Images/favorite-hover.svg") no-repeat;
}
.mor-gridactions span.delete-doc::before {
  background: url("../Images/delete.svg") no-repeat;
  top: -4px;
}
.mor-gridactions span.delete-doc:hover::before {
  background: url("../Images/delete-hover.svg") no-repeat;
}
.mor-gridactions span.select-all-doc::before {
  background: url("../Images/selectall.svg") no-repeat;
  top: -4px;
}
.mor-gridactions span.select-all-doc:hover::before {
  background: url("../Images/selectall-hover.svg") no-repeat;
}
.mor-gridactions span.deselect-all-doc::before {
  background: url("../Images/deselect.svg") no-repeat;
  top: -4px;
}
.mor-gridactions span.deselect-all-doc:hover::before {
  background: url("../Images/deselect-hover.svg") no-repeat;
}
.mor-gridactions span.edit-doc::before {
  background: url("../Images/edit.svg") no-repeat;
  top: -4px;
}
.mor-gridactions span.edit-doc:hover::before {
  background: url("../Images/edit-hover.svg") no-repeat;
}

.additem-btn .dropdown-menu,
.morcard-morelink .dropdown-menu {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
  min-width: 168px;
}

.additem-btn .dropdown-menu .dropdown-item,
.morcard-morelink .dropdown-menu .dropdown-item {
  color: #637381 !important;
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  padding: 6px 6px 6px 27px;
}
.additem-btn .dropdown-menu .dropdown-item i,
.morcard-morelink .dropdown-menu .dropdown-item i {
  margin-right: 10px;
}
.additem-btn .dropdown-menu .dropdown-item:hover,
.morcard-morelink .dropdown-menu .dropdown-item:hover {
  color: #212b36 !important;
  font-family: Graphik-Medium;
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 4px;
}
.additem-btn .dropdown-menu .dropdown-item.disabled-item,
.morcard-morelink .dropdown-menu .dropdown-item.disabled-item {
  pointer-events: none;
  opacity: 0.4;
}
.dropdown-menu .newfolder-icon,
.dropdown-menu .newitem-icon,
.dropdown-menu .edit-icon,
.dropdown-menu .delete-icon {
  position: relative;
}
.dropdown-menu .newfolder-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url("../Images/newfolder-normal.svg") no-repeat;
  width: 14px;
  height: 11px;
  left: 6px;
  top: 9px;
}
.dropdown-menu .newfolder-icon:hover::before {
  background: url("../Images/newfolder-gradient.svg") no-repeat;
}
.dropdown-menu .newitem-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url("../Images/newitem-normal.svg") no-repeat;
  width: 11px;
  height: 14px;
  left: 7px;
  top: 9px;
}
.dropdown-menu .newitem-icon:hover::before {
  background: url("../Images/newitem-gradient.svg") no-repeat;
}
.dropdown-menu .edit-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url("../Images/edit-normal.svg") no-repeat;
  width: 14px;
  height: 14px;
  left: 7px;
  top: 8px;
}
.dropdown-menu .edit-icon:hover::before {
  background: url("../Images/edit-gradient.svg") no-repeat;
}
.dropdown-menu .delete-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url("../Images/delete-normal.svg") no-repeat;
  width: 14px;
  height: 14px;
  left: 7px;
  top: 8px;
}
.dropdown-menu .delete-icon:hover::before {
  background: url("../Images/delete-gradient.svg") no-repeat;
}
.addmoritem-modal .modal-dialog {
  max-width: 658px;
  /* margin: 8rem auto; */
}
.addmoritem-modal .form-group {
  position: relative;
  border-radius: 8px;
}
.addmoritem-modal .form-section .form-group:last-child {
  margin-bottom: 8px !important;
}
/* .fileupload-linkicon:after{
      content: "\f0c1";
      font-family: Font Awesome\ 5 Pro;
      font-weight: 700;
      position: absolute;
      right: 8px;
      top: 23px;
      color: #919eab;
    } */
.additem-embedlink span {
  color: rgb(161, 0, 255);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.24px;
  cursor: pointer;
}
.addmoritem-btns.modal-btn,
.deletemoritem-btns.modal-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deletemoritem-btns .btn {
  width: 100px;
  height: 40px;
}
.addmoritem-title.modal-title {
  font-size: 20px;
  letter-spacing: -0.42px;
}
.addmoritem-modal .modal-header .close {
  top: 32px;
}
.additem-embedlink {
  margin-bottom: 1rem;
}
.deletemoritem-modal .modal-header .close {
  top: 32px;
}
.successNotification-modal .successnotification-body,
.deletemoritem-modal .deletenotification-body {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.successNotification-modal .successnotification-icon,
.deletemoritem-modal .deletenotification-icon {
  margin-right: 1.5rem;
}
.deletenotification-icon i {
  font-size: 40px;
  color: #d5002f;
}
.successnotification-icon i {
  font-size: 40px;
  color: #7ec344;
}
.successNotification-modal p,
.deletemoritem-modal p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  margin-bottom: 0;
}
.successNotification-modal .modal-header .close {
  top: 31px;
}
.successNotification-modal .modal-dialog {
  max-width: 623px;
}
.embedlink-modal .modal-dialog {
  max-width: 1096px;
}
.embedlink-carousel h3 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 28px;
  margin-bottom: 1.5rem;
}
.embedlink-modal .modal-header {
  padding: 8px;
}
.embedlink-modal .modal-header .close {
  top: 35px;
  z-index: 999;
}
.embedlink-carousel .carousel {
  padding: 0 16px;
}
.embedlink-carousel .carousel-item img {
  border-radius: 8px;
  height: 545px;
  width: 100%;
}
.embedlink-carousel .carousel-control-next,
.embedlink-carousel .carousel-control-prev {
  color: #919eab;
  width: 15px;
  height: 30px;
  top: 50%;
}
.embedlink-carousel .carousel-control-next {
  right: -10px;
}
.embedlink-carousel .carousel-control-prev {
  left: -10px;
}
.embedlink-carousel .carousel-control-next i,
.embedlink-carousel .carousel-control-prev i {
  font-size: 25px;
}
.embedlink-carousel .carousel-control-next:focus,
.embedlink-carousel .carousel-control-next:hover,
.embedlink-carousel .carousel-control-prev:focus,
.embedlink-carousel .carousel-control-prev:hover {
  color: #919eab;
}
.embedlink-carousel .carousel-indicators {
  position: relative;
  margin-top: 2rem;
}
.embedlink-carousel .carousel-indicators li {
  background: rgb(145, 158, 171);
  height: 14px;
  width: 14px;
  border-radius: 100%;
  border: none;
}
.embedlink-carousel .carousel-indicators li.active {
  background: #018eff;
}
.morcards-listgrid .list-grid {
  height: 52px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
}
.morcards-listgrid .list-grid .left {
  display: flex;
  align-items: center;
}
.morcards-listgrid .list-grid .left .file-names,
.morcards-listgrid .list-grid .left .file-names input {
  padding-left: 16px;
  color: rgb(33, 43, 54);
  font-size: 13px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
}
.morcards-listgrid .list-grid .left .file-names input {
  padding-left: 0;
}
.morcards-listgrid .list-grid .right .dropdown-menu.show {
  top: 10px !important;
  left: 10px !important;
}
.archive-section h2 {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.mor-comment-overlay .comments-header .title-with-icon h5 {
  color: #212b36;
  font-size: 14px;
}
.mor-comment-overlay .comment-section .title-with-icon.action-links .sortby {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.mor-comment-overlay .comments-body .user-comment {
  padding: 16px;
  background: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}
.mor-comment-overlay.comment-overlay:after {
  opacity: 0.1;
}
.mor-comment-overlay .comments-footer .comment-area {
  align-items: center;
}
.embedlink-tabs .nav-tabs .nav-link {
  border: none;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  position: relative;
  padding: 1rem 0;
  margin-right: 32px;
}
.embedlink-tabs .nav-tabs .nav-link.active {
  color: #212b36 !important;
  font-weight: 500;
  border-bottom: none;
}
.embedlink-tabs .nav-tabs .nav-link.active:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 4px;
  height: 8px;
  background-color: #018eff;
}
.embedlink-tabs .tab-content {
  background: transparent;
}
.embedlink-tabs .nav-tabs {
  margin-bottom: 2rem;
}
.commentry.morCommentry {
  width: 100%;
}
.mor-selectedclient span.inActive {
  border: 1.5px solid rgb(196, 205, 213);
  color: rgb(99, 115, 129);
}
.commentry.morCommentry .dropdown {
  padding: 10px 16px;
}
.commentry.morCommentry .dropdown:focus,
.commentry.morCommentry .dropdown {
  border: 1.5px solid rgb(161, 0, 255);
  border-radius: 24px;
  outline: none;
  color: rgb(161, 0, 255);
  min-width: auto;
  max-width: auto;
  padding-right: 20px;
}
.commentry.morCommentry .selectdropdown-list li .select-custom::after {
  border-bottom-color: rgb(161, 0, 255);
  border-right-color: rgb(161, 0, 255);
}

.container-section .backButton {
  border-radius: 20px;
  border: none;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  background: transparent;
  padding: 12px 18px;
}
.backButton:focus {
  outline: none;
}
.morcard-nameblock span input {
  text-align: center;
}
.commentry.morCommentry .backButton,
.commentry.morCommentry .sortby {
  color: rgb(161, 0, 255);
  border-color: rgb(161, 0, 255);
}
.commentry.morCommentry .backButton {
  color: rgb(1, 142, 255);
  border-color: rgb(1, 142, 255);
}
.commentry.morCommentry .backButton:hover {
  color: rgb(255, 255, 255);
  border-color: rgb(1, 142, 255);
  background-color: rgb(1, 142, 255);
}
.commentry.morCommentry .sortby-icon-down {
  background: url(../Images/sort-amount-down-alt-new.svg) no-repeat right;
  cursor: pointer;
}
.morMaximize .modal-body-header {
  margin-bottom: 14px;
  float: right;
}
.morMaximize button:hover,
.morMaximize button:focus,
.morMaximize button:active,
.morMaximize button:active:hover {
  outline: none;
  box-shadow: none;
  border-color: rgb(161, 0, 255);
  background-color: rgb(161, 0, 255);
}
.no-data-message {
  min-height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #52657f;
  font-family: graphik;
  font-size: 18px;
  font-weight: 400;
}
.addmoritem-modal .fileupload-linkicon {
  padding-right: 25px !important;
}

.mor-dropdown-wrapper,
.mor-custom-tooltip {
  position: relative;
}
.mor-leftfilters .mor-dropdown-wrapper .mor-custom-tooltip {
  position: absolute;
  top: -14px;
  right: 15px;
  height: auto;
}
.mor-leftfilters .mor-dropdown-wrapper .mor-custom-tooltip {
  border: none;
  padding: 0;
  position: absolute;
  top: -15px;
  right: 17px;
  height: 16px;
  width: 16px;
  background: #f9fafb;
}
.mor-dropdown-wrapper .mor-leftfilters .mor-dropdown-wrapper .mor-custom-tooltip svg {
  color: rgba(161, 0, 255, 0.9);
  cursor: pointer;
  height: auto;
  background: transparent;
}
.mor-leftfilters .mor-dropdown-wrapper .mor-custom-tooltip:hover span {
  width: 200px;
  left: -92px;
  bottom: 14px;
}
.mor-leftfilters .mor-dropdown-wrapper .mor-custom-tooltip:hover span:after {
  left: unset;
  right: 48%;
}
.mor-leftfilters .mor-dropdown-wrapper .mor-custom-tooltip::after {
  display: none;
}
.mor-leftfilters .mor-dropdown-wrapper .mor-custom-tooltip svg {
  color: rgb(161, 0, 255, 0.9);
  cursor: pointer;
}
.mor-custom-tooltip span {
  display: none;
}
.mor-custom-tooltip:hover span {
  position: absolute;
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.5) 0%, rgba(161, 0, 255, 0.9) 100%);
  color: #fff;
  font-family: graphik-medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  left: -24px;
  width: 76px;
  padding: 4px;
  right: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  bottom: 30px;
  border-radius: 4px;
}
.mor-custom-tooltip:hover span:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: rgb(161, 0, 255);
}
/* MOR New UI Enhancement CSS starts here */
.mor-doc-count {
  margin-bottom: 1rem;
  color: rgb(99, 115, 129);
  font-size: 15px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
}
.mor-doc-nav {
  position: relative;
  cursor: pointer;
  color: rgb(1, 142, 255);
  padding-right: 32px;
}
.mor-doc-nav:after {
  content: "\f105";
  position: absolute;
  right: 14px;
  font-family: "Font Awesome 5 Pro";
  top: 0px;
  color: rgb(223, 227, 232);
}
.mor-doc-nav:last-child:after {
  display: none;
}
.ellipses-blue {
  position: relative;
  background: url("../Images/ellipses-blue.svg") top center no-repeat;
  width: 16px;
  height: 4px;
  display: inline-block;
  top: -2px;
}
.mor-doc-nav.ellipses .dropdown[aria-expanded="true"] .ellipses-blue {
  background: url("../Images/ellipsis_gradient.svg") top center no-repeat;
}
.mor-doc-nav.ellipses .dropdown[aria-expanded="true"] .ellipses-blue::before {
  position: absolute;
  content: "";
  width: 32px;
  height: 32px;
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 0px;
  top: -15px;
  left: -8px;
}
.ellipses-blue .dropdown.show {
  width: 32px;
  text-align: center;
}
.mor-doc-nav.ellipses .dropdown-menu.show {
  top: 8px !important;
  left: -8px !important;
  padding: 0px !important;
  width: 206px !important;
  max-height: 200px;
  overflow: auto;
}
.mor-doc-nav.ellipses .dropdown-menu h2,
.mor-doc-nav.ellipses .dropdown-menu ul li {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: graphik;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  border-radius: 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  padding: 12px;
  white-space: nowrap;
}
/* .mor-doc-nav.ellipses .dropdown-menu ul li:nth-child(2) {
  padding-left: 24px;
}
.mor-doc-nav.ellipses .dropdown-menu ul li:nth-child(3) {
  padding-left: 32px;
} */
.mor-doc-nav.ellipses .dropdown-menu h2.active,
.mor-doc-nav.ellipses .dropdown-menu ul li.active,
.mor-doc-nav.ellipses .dropdown-menu h2:hover,
.mor-doc-nav.ellipses .dropdown-menu ul li:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  font-family: Graphik-Medium;
}
.mor-sortby .gridview::before,
.mor-sortby .tableview::before {
  content: "";
  width: 24px;
  height: 22px;
  display: flex;
  cursor: pointer;
  margin-left: 10px;
}
.mor-sortby .gridview::before {
  background: url("../Images/grid-grey.svg");
}
.mor-sortby .gridview:hover::before,
.mor-sortby .gridview.active::before {
  background: url("../Images/grid-hover.svg");
}
.mor-sortby .tableview::before {
  background: url("../Images/list-table-grey.svg");
}
.mor-sortby .tableview:hover::before,
.mor-sortby .tableview.active::before {
  background: url("../Images/list-table-hover.svg");
}
.morsearch-section .dropdown-menu .dropdown-item:hover {
  box-shadow: none;
}
.mor-rightfilters .mor-filter-drodown {
  background: url("../Images/FilterCategorydisabled.svg") no-repeat;
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 16px;
  margin-right: 20px;
}
.mor-rightfilters .mor-filter-drodown:hover,
.mor-rightfilters .mor-filter-drodown.active {
  background: url("../Images/FilterSelected.svg") no-repeat;
}
.mor-rightfilters .mor-filter-drodown.filter-applied,
.mor-rightfilters .mor-filter-drodown.filter-applied:hover {
  background: url("../Images/filter-applied.svg") no-repeat;
  width: 22px;
  height: 22px;
}
.addmoritem-modal {
  padding-right: 20px;
}
.fileupload-linkicon .embed-info {
  background: url("../Images/info-new.svg");
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  right: -30px;
  bottom: 0px;
  top: 0;
  margin: auto;
  cursor: pointer;
}
.addmoritem-modal .addmoritem-btns {
  justify-content: flex-end;
}
.addmoritem-modal .addmoritem-btns .btn {
  padding: 5px 20px;
  margin-left: 20px;
}
.backtoMain {
  background: url("../Images/arrow-left.svg") no-repeat;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  cursor: pointer;
}
.addmoritem-modal .addmoritem-btns .btn:disabled {
  opacity: 0.35;
}
/* MOR New UI Enhancement CSS ends here */
.mor-gridtable.table th {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  border-top: none;
  position: relative;
}
.mor-gridtable.table th.allMonths,
.mor-gridtable.table td.allMonths {
  text-align: center;
}
.mor-gridtable tbody tr:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.05) 0%, rgba(0, 186, 255, 0.05) 100%);
  border-radius: 0px;
}
.mor-gridtable tbody tr:hover .mor-docname span {
  color: rgb(1, 142, 255);
  cursor: pointer;
}
.mor-gridtable td {
  padding: 0.95rem 0.75rem !important;
  max-width: 165px;
  position: relative;
}
.mor-gridtable td span {
  display: block;
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  white-space: nowrap;
}
.mor-gridtable td.mor-docname {
  max-width: 260px;
}
.mor-gridtable tr .form-check .checkmark {
  top: -17px;
  width: 20px;
  height: 20px;
}
.mor-gridtable tr th input[type="checkbox"].form-check-input:checked ~ .checkmark {
  background-color: #ffffff;
  border: 1.5px solid #018eff;
}
.mor-gridtable tr th .checkmark:after {
  background: rgb(1, 142, 255);
  border-radius: 2px;
  height: 10px;
  width: 10px;
  border: none;
  transform: none;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}
.mor-gridtable tr td .checkmark:after {
  left: 0;
  right: 0;
  margin: auto;
  top: -2px;
  bottom: 0;
  width: 6px;
  height: 12px;
}
.mor-gridtable tr th i {
  margin-left: 8px;
}
.mor-gridtable tr th .sortupdown-icons i {
  position: absolute;
  top: 16px;
}
.mor-gridtable tr th .dropdown {
  display: inline-block;
}
.mor-gridtable tr th .dropdown-toggle,
.mor-gridtable .selectsuboffering-dropdown .dropdown-toggle {
  background: transparent !important;
  border: none !important;
  box-shadow: none;
  outline: none !important;
  color: rgb(99, 115, 129) !important;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  padding: 0 1.5rem 0 0;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.mor-gridtable tr th .dropdown-toggle:active,
.mor-gridtable tr th .dropdown-toggle:focus,
.mor-gridtable tr th .dropdown.show .dropdown-toggle {
  background-color: transparent !important;
  color: rgb(99, 115, 129) !important;
  box-shadow: none !important;
}
.mor-gridtable tr th .dropdown-toggle:after {
  border-bottom: 2px solid #637381;
  border-right: 2px solid #637381;
  height: 8px;
  width: 8px;
  right: 5px;
  position: absolute;
  top: 7px;
}
.mor-gridtable .dropdown-menu {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1), 1px 0px 0px 0px rgb(244, 246, 248);
  max-height: 240px;
  overflow-y: auto;
  width: 224px;
}
.mor-gridtable .dropdown-item {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 0.75rem 1rem;
  white-space: normal;
}
.mor-gridtable .dropdown-menu .dropdown-item:hover {
  color: #212b36 !important;
  font-family: Graphik-Medium;
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 4px;
}
.mor-gridtable .dropdown-menu .dropdown-item.active {
  color: #018eff !important;
  font-family: Graphik-Medium;
  border-radius: 0px;
  background: transparent;
}
.tooltipellipsis-text {
  position: relative;
}
.tooltipellipsis-text .initiativetooltip-text {
  display: none;
}
.tooltipellipsis-text:hover .initiativetooltip-text {
  position: absolute;
  background: rgb(33, 43, 54);
  color: rgb(255, 255, 255);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  text-align: center;
  width: 228px;
  padding: 10px 16px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  top: 40px;
  z-index: 999 !important;
  left: 50%;
  transform: translate(-50%, 0px);
  word-break: break-word;
  white-space: break-spaces;
}

.tooltipellipsis-text:hover .initiativetooltip-text::after {
  content: "";
  position: absolute;
  left: 30%;
  top: -10px;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: #000;
  transform: rotate(180deg);
}
.pagination-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pagination-list .select-custom select {
  border-radius: 3px;
  border: none;
  height: 30px;
  font-size: 12px;
  width: 50px;
  padding: 0 10px 0 5px;
  color: rgb(99, 115, 129);
  outline: none !important;
}
.pagination-list .select-custom::after {
  right: 1.2rem;
  height: 7px;
  width: 7px;
}
.pagination-list ul {
  margin-bottom: 0;
}
.pagination-list ul li {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 30px;
  text-align: center;
  margin-right: 5px;
  cursor: pointer;
}
.pagination-list ul li .page-link {
  border: none;
  color: rgb(99, 115, 129);
  background: transparent;
  padding: 0.5rem 0.6rem;
  font-family: graphik;
  font-weight: normal;
}
.pagination-list li .page-link:hover,
.pagination-list li .page-link.active {
  background: #018fff;
  color: #fff;
  font-family: graphik;
  font-size: 12px;
  font-weight: 600;
}
.pagination-list ul li .pagination-icon {
  background: rgb(255, 255, 255);
  border-radius: 3px;
  border: 1px solid rgb(220, 226, 231);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-list span {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.morgridview-select,
.morcard-eyeicon {
  display: none;
}
.morgridview-select.active {
  display: block;
}
/* .morcard-gridbody:hover .morcard-image:after, */
.morcard-gridbody.active .morcard-image:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.54);
  border-radius: 0px;
}
.morcard-gridbody {
  position: relative;
}
.morcard-gridbody:hover .morcard-eyeicon,
.morcard-gridbody.active .morcard-eyeicon {
  display: block;
}
.morcard-eyeicon img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.morgridview-select .form-check {
  position: absolute;
  top: 0;
  margin-top: 10px;
  margin-left: 10px;
}
.morgridview-select .form-check .checkmark {
  background: transparent;
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 100%;
}
.morcard-grid:hover .form-check .checkmark {
  border: 1.5px solid rgb(249, 250, 251);
}
.morgridview-select input[type="checkbox"].form-check-input:checked ~ .checkmark {
  background-color: rgb(255, 255, 255);
  border-color: rgb(181, 184, 186);
}
.morgridview-select .form-check .checkmark:after {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.54);
  left: 0;
  right: 0;
  top: -3px;
  bottom: 0;
  margin: auto;
  width: 7px;
  height: 14px;
  border-width: 0 2.5px 2.5px 0;
}
.favouritebtn-toggle i {
  color: rgb(145, 158, 171);
}
.favouritebtn-toggle i.active {
  color: #ffc82c;
  font-weight: 600;
}
.createfolder-modal .modal-dialog {
  max-width: 626px;
}
.createfolder-modal .modal-title,
.addmoritem-modal .addmoritem-title.modal-title {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 28px;
}
.createfolder-modal .dropdown-toggle,
.addmoritem-modal .dropdown-toggle,
.morcategory-dropdown .featuresearch-dropdownname > span {
  background: transparent !important;
  color: rgb(145, 158, 171) !important;
  font-family: "graphik";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 12px;
  border: none;
  box-shadow: none;
  padding: 0;
  width: 100%;
  text-align: left;
}
.filter-popovercontent .dropdown-toggle,
.filter-popovercontent .selectsuboffering-dropdown .dropdown-toggle {
  background: transparent;
  border-radius: 8px;
  border: 1px solid rgb(196, 205, 213) !important;
  color: rgb(145, 158, 171) !important;
  font-family: "graphik";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  width: 100%;
  text-align: left;
  padding: 14px 24px 14px 16px;
  margin-bottom: 0.5rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filter-popovercontent .dropdown-toggle:hover,
.filter-popovercontent .dropdown-toggle:active,
.filter-popovercontent .dropdown-toggle:focus,
.filter-popovercontent .dropdown.show .dropdown-toggle {
  background-color: transparent !important;
  color: rgb(145, 158, 171) !important;
  box-shadow: none !important;
}
.filter-popovercontent .dropdown-toggle::after,
.filter-popovercontent .selectsuboffering-dropdown .dropdown-toggle:after {
  border-bottom: 2px solid rgb(196, 205, 213);
  border-right: 2px solid rgb(196, 205, 213);
  height: 10px;
  width: 10px;
  position: absolute;
  right: 10px;
  top: 12px;
}
.addmoritem-modal .createfolder-modal .dropdown-toggle:hover,
.createfolder-modal .dropdown-toggle:active,
.createfolder-modal .dropdown-toggle:focus,
.createfolder-modal .dropdown.show .dropdown-toggle,
.addmoritem-modal .dropdown-toggle:hover,
.addmoritem-modal .dropdown-toggle:active,
.addmoritem-modal .dropdown-toggle:focus,
.addmoritem-modal .dropdown.show .dropdown-toggle {
  background-color: transparent !important;
  color: rgb(145, 158, 171) !important;
  box-shadow: none !important;
}
.createfolder-modal .dropdown-toggle::after,
.addmoritem-modal .dropdown-toggle::after,
.morcategory-dropdown .featuresearch-dropdownname:after,
.createfolder-modal .selectsuboffering-dropdown .dropdown-toggle:after {
  border-bottom: 2px solid rgb(145, 158, 171);
  border-right: 2px solid rgb(145, 158, 171);
  height: 9px;
  width: 9px;
  position: absolute;
  right: 6px;
}
.createfolder-modal .selectsuboffering-dropdown .dropdown-toggle:after {
  top: -4px;
}
.createfolder-modal .selectsuboffering-dropdown .dropdown-toggle {
  line-height: 20px;
}
.morcategory-dropdown .featuresearch-dropdownname:after {
  font-family: unset;
  transform: rotate(45deg);
  top: 6px;
  content: "";
}
.createfolder-modal .dropdown-menu,
.addmoritem-modal .dropdown-menu,
.filter-popovercontent .dropdown-menu {
  width: 100%;
  max-height: 240px;
  overflow-y: auto;
}
.createfolder-modal .dropdown-menu .dropdown-item,
.addmoritem-modal .dropdown-menu .dropdown-item,
.filter-popovercontent .dropdown-menu .dropdown-item {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 0.75rem 1rem;
  white-space: normal;
}
.createfolder-modal .dropdown-menu .dropdown-item:hover,
.addmoritem-modal .dropdown-menu .dropdown-item:hover,
.filter-popovercontent .dropdown-menu .dropdown-item:hover {
  color: #212b36 !important;
  font-family: Graphik-Medium;
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 4px;
}

.createfolder-modal .dropdown-menu .dropdown-item.active,
.addmoritem-modal .dropdown-menu .dropdown-item.active,
.filter-popovercontent .dropdown-menu .dropdown-item.active {
  color: rgb(1, 142, 255);
  background-color: transparent;
}
.bs-popover-bottom.filter-popover {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  width: 260px;
  border: none;
  z-index: 999;
}
.filter-popover .popover-body {
  padding: 1rem 1rem 0;
}
.filter-popoverheading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.filter-popoverheading h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.21px;
  line-height: 20px;
  margin-bottom: 0;
}
.filter-popoverheading .close {
  position: absolute;
  right: 16px;
  top: 12px;
}
.morfooter-popover {
  text-align: right;
}
.morfooter-popover .btn {
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  min-width: 60px;
  padding: 9px 16px;
}
.morfooter-popover .btn:focus,
.morfooter-popover .btn:hover {
  outline: none;
  box-shadow: none;
}
.morcategory-dropdown .featuresearch-dropdown {
  position: relative;
}
.morcategory-dropdown .featuresearch-dropdown .featuresearch-list {
  top: 20px;
  height: 180px;
}
.morcategory-dropdown .featuresearch-dropdown .featuresearch-list ul {
  max-height: calc(180px - 15px);
  margin: 0px;
}
.morviewer-modal .modal-header {
  background: rgb(128, 0, 204);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 16px 24px;
}
.morviewer-modal .modal-content {
  border: none;
}
.morviewer-modal .modal-header .close {
  top: 22px;
  right: 24px;
}
.morviewer-modal .casestudy-title {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.26px;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.morviewer-modal .casestudy-title img {
  margin-right: 16px;
}
.morviewer-modal .modal-header .close {
  background: url("../Images/close-white.svg") no-repeat;
  width: 14px;
  height: 14px;
}
.morviewer-modal .modal-header .close:active {
  outline: none;
}
.morviewer-modal .csupdated-info p {
  position: relative;
}
.morviewer-modal .csupdated-info p span {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
}
.morviewer-modal .csupdated-info p span.email-ids {
  font-family: "graphik";
  font-weight: 100;
  color: rgb(1, 142, 255);
}
.morviewer-modal .casestudy-controllers span.left-controller {
  left: -22px;
}
.morviewer-modal .casestudy-controllers span.right-controller {
  right: -22px;
}
.morviewer-modal .casestudy-controllers span i {
  color: rgb(145, 158, 171);
}
.morviewer-modal .casestudy-controllers span.active i {
  color: rgb(1, 142, 255);
}
.morviewer-modal .casestudy-modalimg {
  position: relative;
}
.morviewer-modal .casestudy-controllers span i:before {
  font-size: 32px;
}
.morviewer-modal .common-ellipsis-dropdown .dropdown {
  top: -7px;
  right: 0px;
}

.mor-maximise {
  margin-bottom: 15px;
  text-align: right;
}
.mor-gridtable tr th .show.dropdown .dropdown-toggle:after {
  transform: rotate(-136deg);
  top: 10px;
}
.mor-gridtable td span i {
  margin-left: 6px;
}

.sortupdown-icons {
  position: relative;
}
.sortupdown-icons span {
  position: absolute;
  left: 5px;
}
.increment-arrow-down {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #7d7878;
  cursor: pointer;
  bottom: 2px;
}
.increment-arrow-up.active {
  border-bottom: 5px solid rgb(1, 142, 255);
}
.increment-arrow-down.active {
  border-top: 5px solid rgb(1, 142, 255);
}
.increment-arrow-up {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #7d7878;
  cursor: pointer;
  top: 2px;
}

.morviewer-modal .csupdated-info p span .lastupdated-on {
  color: rgb(33, 43, 54);
  font-family: "graphik";
  font-size: 14px;
  font-weight: normal;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 26px;
}
.morviewer-modal .csupdated-info {
  border: none !important;
}
.mor-doc-nav.hover-tooltip.last-breadcrummb {
  color: rgb(99, 115, 129);
  cursor: default;
}
.addmoritem-modal .fileupload-linkicon.mor-help {
  padding-right: 0px !important;
}
.fileupload-linkicon.mor-help .embed-info {
  right: -25px;
}
.morcards-gridlist .morcard-gridbody.pdf-color {
  background: linear-gradient(-225deg, rgb(170, 0, 37) 0%, rgb(213, 0, 47) 100%);
  border-radius: 8px 8px 0px 0px;
}
.morcards-gridlist .morcard-gridbody.img-color {
  background: linear-gradient(-225deg, rgb(190, 130, 255) 0%, rgb(235, 6, 117) 100%);
  border-radius: 8px 8px 0px 0px;
}
.morcards-gridlist .morcard-gridbody.video-color {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(2, 186, 255) 100%);
  border-radius: 8px 8px 0px 0px;
}
.morcards-gridlist .morcard-gridbody.docx-color {
  background: linear-gradient(-45deg, rgb(0, 186, 255) 0%, rgb(0, 65, 240) 100%);
  border-radius: 8px 8px 0px 0px;
}
.morcards-gridlist .morcard-gridbody.folder-color {
  background: linear-gradient(-225deg, rgb(193, 94, 11) 0%, rgb(244, 147, 66) 100%);
  border-radius: 8px 8px 0px 0px;
}
.morcards-gridlist .morcard-gridbody.excel-color {
  background: linear-gradient(-225deg, rgb(82, 134, 38) 0%, rgb(126, 195, 68) 100%);
  border-radius: 8px 8px 0px 0px;
}
.morcards-gridlist .morcard-gridbody.pblx-color {
  background: linear-gradient(-225deg, rgb(230, 176, 25) 0%, rgb(255, 200, 44) 100%);
  border-radius: 8px 8px 0px 0px;
}
.morcards-gridlist .morcard-gridbody.docs-color {
  background: linear-gradient(-225deg, rgb(99, 115, 129) 0%, rgb(145, 158, 171) 100%);
  border-radius: 8px 8px 0px 0px;
}
.morcards-gridlist .morcard-gridbody.ppt-color {
  background: linear-gradient(-225deg, rgb(171, 87, 90) 0%, rgb(249, 130, 133) 100%);
  border-radius: 8px 8px 0px 0px;
}
/* More about MOR */
.pulsemor-title span {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  padding: 10px 16px;
  margin-right: 35em;
  /* margin-left: 1rem; */
  cursor: pointer;
}
/* More about MOR */
.mor-gridtable td span.doc-img-align {
  text-align: left !important;
}
.mor-gridtable td span.doc-img-align > img {
  margin-left: 0.5rem !important;
}
.mor-gridtable td span.doc-img-align::before {
  content: "";
  position: absolute;
  left: 4px;
  width: 52px;
  height: 34px;
  z-index: -1;
  border-radius: 4px;
  top: 9px;
}
.mor-gridtable.table thead th:nth-child(2) {
  padding-left: 0px;
  left: -5px;
}
.mor-gridtable td span.doc-img-align.pdf-color::before {
  background: linear-gradient(-225deg, rgb(170, 0, 37) 0%, rgb(213, 0, 47) 100%);
}
.mor-gridtable td span.doc-img-align.img-color::before {
  background: linear-gradient(-225deg, rgb(190, 130, 255) 0%, rgb(235, 6, 117) 100%);
}
.mor-gridtable td span.doc-img-align.video-color::before {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(2, 186, 255) 100%);
}
.mor-gridtable td span.doc-img-align.docx-color::before {
  background: linear-gradient(-45deg, rgb(0, 186, 255) 0%, rgb(0, 65, 240) 100%);
}
.mor-gridtable td span.doc-img-align.folder-color::before {
  background: linear-gradient(-225deg, rgb(193, 94, 11) 0%, rgb(244, 147, 66) 100%);
}
.mor-gridtable td span.doc-img-align.excel-color::before {
  background: linear-gradient(-225deg, rgb(82, 134, 38) 0%, rgb(126, 195, 68) 100%);
}
.mor-gridtable td span.doc-img-align.pblx-color::before {
  background: linear-gradient(-225deg, rgb(230, 176, 25) 0%, rgb(255, 200, 44) 100%);
}
.mor-gridtable td span.doc-img-align.docs-color::before {
  background: linear-gradient(-225deg, rgb(99, 115, 129) 0%, rgb(145, 158, 171) 100%);
}
.mor-gridtable td span.doc-img-align.ppt-color::before {
  background: linear-gradient(-225deg, rgb(171, 87, 90) 0%, rgb(249, 130, 133) 100%);
}
.mor-gridtable .allMonths .dropdown .dropdown-menu {
  width: 130px;
}
/* .mor-docinfo{
  position: relative;
  left: 24px;
} */
.mor-emptyCell {
  padding: 0px 48px;
}
.__react_component_tooltip.infoiconmor-tooltip {
  max-width: 230px;
  opacity: 1;
}
.infoiconmor-tooltip span {
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  text-align: center;
  word-break: break-word;
  white-space: break-spaces;
}
.morcards-listgrid .table.mor-gridtable {
  border-collapse: unset;
  border-spacing: 0;
}
.morcards-listgrid .table.mor-gridtable thead tr th {
  border: none;
}
.morcards-listgrid .table.mor-gridtable tbody tr:first-child td {
  border-top: 2px solid #b9b6b6;
}
.morcards-listgrid .table.mor-gridtable tbody .archiveFav-info {
  padding-right: 20px !important;
}
.mor-gridtable .selectsuboffering-dropdown .dropdown-menu {
  overflow-y: unset;
}
.filter-popovercontent .offering-droplistwrapper {
  max-height: unset;
  overflow: unset;
}
.filter-popovercontent #nested-dropdown-container {
  padding: 0;
}
