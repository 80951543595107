.adminform-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.adminform-row .filter-search {
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
  max-width: 306px;
  background: #fff url(../Images/search-filter.png) no-repeat scroll 20px;
  background-size: 14px;
  padding: 8px 30px 8px 40px;
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  color: #212b36;
  box-shadow: none !important;
  outline: none;
}
.adminform-row .filter-search::-webkit-input-placeholder {
  /* Edge */
  color: #212b36;
}
.adminform-row .filter-search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #212b36;
}
.adminform-row .filter-search::placeholder {
  color: #212b36;
}
.adminform-row .btn {
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  padding: 9px 16px;
  line-height: 12px;
  box-shadow: none !important;
  outline: none;
  background-color: #018eff !important;
  border-color: #018eff !important;
  color: #fff !important;
}
.addadminModal .modal-dialog {
  max-width: 994px;
}
.addadminModal .modal-header {
  padding: 24px 24px 8px;
}
.addadminModal .modal-header .close {
  right: 24px;
  top: 24px;
}
.addadminModal .form-group.border-group {
  border-radius: 8px;
}
.useractions-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.useractions-block .form-section {
  width: 85%;
  margin-right: 1rem;
}

.useractions-block .input-group-append {
  position: absolute;
  right: -10px;
  top: -26px;
  height: 60px;
  z-index: 9;
}
.useractions-block .input-group-append .input-group-text {
  border-radius: 8px;
}
.useractions-block .form-group.border-group {
  border: 1px solid rgb(223, 227, 232);
}

.addadminModal .form-section label.form-label {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}
.useractions-block .form-control,
.offering-inputfields .form-control {
  background: transparent !important;
}
.custom-toggleswitch.toggle-group {
  height: 24px;
  display: flex;
  align-items: center;
}
.custom-toggleswitch .onoffswitch {
  width: 36px;
}
.custom-toggleswitch .onoffswitch .onoffswitch-label {
  border: none;
  border-radius: 12px;
}
.custom-toggleswitch .onoffswitch .onoffswitch-inner:before,
.custom-toggleswitch .onoffswitch .onoffswitch-inner:after {
  width: 36px;
  height: 24px;
}
.custom-toggleswitch .onoffswitch .onoffswitch-inner:before {
  background: rgb(1, 142, 255);
}
.custom-toggleswitch .onoffswitch .onoffswitch-inner:after {
  border-radius: 12px;
  border: 1px solid rgb(181, 192, 202);
}
.custom-toggleswitch .onoffswitch .onoffswitch-switch {
  background: rgb(181, 192, 202);
  height: 16px;
  width: 16px;
  box-shadow: none;
  right: 15px;
  top: 4px;
}
.custom-toggleswitch.toggle-group
  input[type="checkbox"]:checked
  ~ .onoffswitch
  .onoffswitch-label
  .onoffswitch-switch {
  box-shadow: none;
  background: rgb(255, 255, 255);
  right: 3px;
}
.custom-toggleswitch.toggle-group
  input[type="checkbox"]:checked
  ~ .onoffswitch
  .onoffswitch-label
  .onoffswitch-switch:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: 3px;
  top: 1px;
  font-size: 11px;
  color: #212b36;
}
.custom-toggleswitch .toggleswitch-text {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  height: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-left: 8px;
}
.custom-toggleswitch.toggle-group input[type="checkbox"]:checked ~ .toggleswitch-text {
  font-family: Graphik-Medium;
  font-weight: 500;
  color: rgb(21, 24, 27);
}

.role-label {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
  margin-right: 1rem;
}

.roleactions-block {
  display: flex;
  align-items: center;
}
.addadminModal .roleactions-block .form-check .checkmark {
  border: 1px solid rgb(181, 192, 202);
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.roleactions-block .form-check .checkmark {
  border: 1px solid rgb(181, 192, 202);
  height: 20px;
  width: 20px;
}
.roleactions-block label.form-check {
  margin-right: 2rem;
  margin-top: 5px;
}
.roleactions-block .form-check-label {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 26px;
  margin-left: 8px;
}
.roleactions-block .form-check input[type="radio"] + .checkmark:after {
  background: rgb(1, 142, 255);
  height: 14px;
  width: 14px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 1.5px solid rgb(1, 142, 255);
}
.roleactions-block input[type="radio"].form-check-input:checked ~ .form-check-label {
  color: rgb(21, 24, 27);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.useroffering-fields {
  display: flex;
  align-items: center;
}
.useroffering-fields {
  width: 90%;
  display: flex;
  align-items: center;
}
.adminrole-notify {
  display: flex;
  align-items: center;
}
.adminrole-notify span {
  color: rgb(1, 142, 255);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 24px;
}
.formsection-fieldsection .formsection-fields {
  display: flex;
  align-items: center;
}
.formsection-fields .form-group.border-group {
  width: 22.5%;
  margin-right: 8px;
}
.formsection-fieldsection {
  height: 320px;
  overflow-y: auto;
}
.customform-dropdown .dropdown {
  width: 100%;
  position: relative;
  height: 20px;
  outline: none;
}
.customform-dropdown .dropdown .dropdown-toggle {
  width: 100%;
  font-size: 1rem;
  color: #919eab;
  padding: 0;
  text-align: left;
  background: transparent;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 25px;
  outline: none !important;
  box-shadow: none !important;
}
.customform-dropdown .dropdown .dropdown-toggle:after {
  position: absolute;
  color: #919eab;
  right: 1rem;
  content: "\F078";
  font-family: "Font awesome\ 5 Pro";
  border: none;
  transition: none;
  -webkit-transform: none;
  transform: none;
  top: 0px;
}
.customform-dropdown .dropdown-menu {
  width: calc(100% + 16px);
  left: -8px !important;
  /* top: 6px !important; */
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  padding: 8px 6px;
}
.customform-dropdown .offering-droplistwrapper,
.customform-multidropdown .search-select-list ul {
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
}
.customform-dropdown .offering-droplistwrapper .dropdown-item-text {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: "graphik";
  white-space: normal;
  font-weight: normal;
  letter-spacing: -0.25px;
  padding: 0rem;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.customform-dropdown .offering-droplistwrapper .dropdown-item-text .form-check-label {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
  padding: 0.75rem;
  bottom: 0px;
}
.customform-dropdown .offering-droplistwrapper .dropdown-item-text label.form-check {
  width: 100%;
  position: relative;
}
.customform-dropdown .offering-droplistwrapper .dropdown-item-text .form-check .checkmark {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
}
.customform-dropdown .offering-droplistwrapper .dropdown-item-text .form-check .checkmark:after {
  left: 7px;
  top: 2px;
}
.customform-multidropdown .search-select-list ul {
  margin: 0;
}

.customform-dropdown .dropdown-item {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  white-space: normal;
  font-weight: normal;
  letter-spacing: -0.25px;
  padding: 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.customform-dropdown .dropdown-menu .dropdown-item:hover,
.customform-multidropdown .search-select-list li:hover,
.customform-dropdown .dropdown-menu .dropdown-item:focus,
.customform-dropdown .offering-droplistwrapper .dropdown-item-text:hover {
  background: rgba(255, 255, 255, 0.2)
    linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 0px;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
  border: none;
}
.customform-dropdown .dropdown-menu .dropdown-item.active {
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.customform-multidropdown {
  position: relative;
}
.customform-multidropdown .custom-dropdownname {
  color: #919eab;
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 20px;
  padding-right: 24px;
  cursor: pointer;
  position: relative;
}
.customform-multidropdown .custom-dropdownname:after {
  content: "\F078";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: 5px;
  top: -2px;
}
.addadminModal .modal-content {
  overflow: initial;
}
.customform-multidropdown .search-select-list {
  width: calc(100% + 16px);
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  position: absolute;
  top: 32px;
  left: -8px;
  z-index: 2;
  border: none;
  padding: 8px 4px;
}
.addadminModal .custom-dropdown-search-overlay {
  z-index: 1;
}
.customform-multidropdown .search-select-list li {
  padding: 8px 12px;
}
.customform-multidropdown .search-select-list label.form-check {
  padding: 0 0 8px 30px;
  margin-top: 8px;
}
.customform-multidropdown .search-select-list li .checkmark {
  top: -6px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 4px;
}
.customform-multidropdown .search-select-list li .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 12px;
}
.customform-multidropdown .search-select-list li .form-check-label {
  color: #637381;
  font-size: 14px;
  font-family: graphik;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 14px;
  cursor: pointer;
}
.customform-multidropdown .search-select-list li:hover .form-check-label,
.customform-multidropdown .search-select-list li.active .form-check-label,
.customform-dropdown .offering-droplistwrapper .dropdown-item-text:hover .form-check-label {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.form-fieldactions span {
  color: rgb(0, 142, 255);
  font-size: 20px;
  cursor: pointer;
}
.form-fieldactions span.disabled {
  color: #b5c0ca;
}
.form-fieldactions span.trash-icon {
  color: rgb(213, 0, 47);
}
.form-fieldactions {
  margin-bottom: 15px;
}
.adminpopup-footer .btn {
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none !important;
  line-height: 14px;
  outline: none !important;
  padding: 0.75rem 1.5rem !important;
}
.adminpopup-footer .btn.disabled {
  background: rgb(231, 234, 238);
  border-color: rgb(231, 234, 238);
}
.addadminModal .form-group.border-group:focus-within {
  background: #f4fbff;
  border: 1.5px solid #018eff;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
}
.addadminModal .form-group.border-group:focus-within .dropdown-toggle {
  background: #f4fbff;
}
.addadminModal .useractions-block .form-group.border-group .form-control:focus,
.addadminModal .customform-dropdown .dropdown .dropdown-toggle:focus,
.addadminModal .show > .btn-primary.dropdown-toggle {
  background: #f4fbff !important;
}
.addadminModal .customform-dropdown .dropdown .dropdown-toggle:after {
  color: #212b36;
}
.addadminModal .customform-dropdown .dropdown .dropdown-toggle.not-selected:after {
  color: #919eab;
}
.addadminModal .useractions-block .form-group.border-group label {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
}
.addadminModal .useractions-block .form-group.border-group .form-control,
.addadminModal .customform-dropdown .dropdown .dropdown-toggle,
.addadminModal .customform-multidropdown .custom-dropdownname {
  color: rgb(21, 24, 27) !important;
  font-family: "graphik";
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.35px;
  background: none !important;
}
.addadminModal .customform-dropdown .dropdown .dropdown-toggle.not-selected,
.addadminModal .customform-multidropdown .custom-dropdownname.not-selected {
  color: #767f88 !important;
}
.useractions-block .form-group.border-group:focus-within .input-group-append {
  height: 54px;
  top: -24px;
}
.addadminModal .roleactions-block .checkmark:after {
  top: 3px;
  left: 7px;
}
.addadminModal .border-group.onFocusColor .form-control {
  background-color: transparent !important;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
}
.addadminModal .border-group.onFocusColor .form-label {
  color: rgb(0, 142, 255);
}
.addadminModal.addoffering-modal .modal-dialog {
  max-width: 835px;
}
.offering-inputfields {
  background: rgb(249, 250, 251);
  border-radius: 8px;
  border: 1px solid rgb(231, 234, 238);
  height: 560px;
  padding: 1rem;
}
.addadminModal .form-group.border-group:focus-within .dropdown-toggle {
  background: #f4fbff;
}
.addadminModal .customform-dropdown .dropdown .dropdown-toggle:after {
  color: #212b36;
}
.addadminModal .customform-dropdown .dropdown .dropdown-toggle.not-selected:after {
  color: #919eab;
}
.addadminModal .customform-dropdown .dropdown .dropdown-toggle.not-selected,
.addadminModal .customform-multidropdown .custom-dropdownname.not-selected {
  color: #767f88;
}
.offering-inputfields li {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}
.offering-inputfields li:last-child .l-shapelink {
  display: none;
}
.offering-inputfields .form-group.border-group {
  width: 90%;
  margin-right: 1rem;
}
.charcount-label {
  color: rgb(145, 158, 171);
  font-family: "graphik";
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.19px;
  text-align: right;
  display: block;
  bottom: 3px;
  right: 10px;
}
.offering-inputfields .charcount-label,
.remarks-textarea .charcount-label {
  bottom: 0;
  line-height: 11px;
}
.addoffering-modal .common-formgrouplabel .form-group.border-group.disabled label.form-label,
.addoffering-modal .common-formgrouplabel .form-group.border-group.disabled .form-control {
  color: rgb(62 68 74);
}
.l-shapelink {
  height: 1px;
  width: 18px;
  position: absolute;
  background: #e7eaee;
  display: block;
  bottom: -42px;
  left: 0;
}
.l-shapelink .l-shapelink-inner {
  display: block;
  background: #e7eaee;
  height: 60px;
  width: 1px;
  position: absolute;
  bottom: 0px;
  left: 1px;
}
.common-formgrouplabel textarea {
  resize: none;
}
.togglegroup-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addoffering-modal .roleactions-block {
  flex-direction: column;
  align-items: flex-start;
}
.remove-sublevel-icon {
  position: absolute;
  top: 0;
  right: 10px;
}
.remove-sublevel-icon img {
  width: 10px;
  cursor: pointer;
}
.remove-sublevel-icon .__react_component_tooltip.place-bottom {
  margin-top: 4px;
}
.sublevelerrMsg {
  position: absolute;
  font-size: 12px;
  bottom: -5px;
  color: #721c24;
  left: 10px;
  font-family: "Graphik-Medium";
}
.customform-dropdown .user-profile-search input[type="search"] {
  width: 99% !important;
  height: 32px;
  margin-bottom: 4px;
}

.addadminModal .customform-dropdown .dropdown .dropdown-toggle,
.addadminModal .customform-multidropdown .custom-dropdownname,
.addadminModal .useractions-block .form-group.border-group .form-control {
  color: #15181b;
  font-family: graphik;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.35px;
}

.addadminModal .useractions-block .form-group.border-group label {
  color: #637381;
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
}

/* OFFERING POPUP DROPDOWN */
.offering-popup-dropdown {
  height: 56px;
  margin-bottom: 15px;
  width: 100%;
}

.offering-popup-dropdown.offering-popup-dropdown-placeholder .dropdown-toggle,
.offering-popup-dropdown.offering-popup-dropdown-placeholder .dropdown-toggle:hover,
.offering-popup-dropdown.offering-popup-dropdown-placeholder .dropdown-toggle:active,
.addoffering-modal
  .form-section
  .offering-popup-dropdown.show.offering-popup-dropdown-placeholder
  .dropdown-toggle {
  color: #acbac8 !important;
  background: #fff !important;
  background-color: #fff !important;
}

.offering-popup-dropdown .dropdown-toggle,
.offering-popup-dropdown .dropdown-toggle:hover,
.offering-popup-dropdown .dropdown-toggle:active {
  height: 100%;
  width: 100%;
  position: relative;
  background: #fff;
  background-color: #fff !important;
  border-radius: 8px;
  border: 1px solid #b5c0ca !important;

  color: #637381 !important;
  font-family: Graphik-Medium;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 11px;
  text-align: left;
}

.addoffering-modal .form-section .offering-popup-dropdown.show .dropdown-toggle {
  background: #fff !important;
  box-shadow: none !important;
  border: 1px solid #b5c0ca;
  color: #637381 !important;
}

.offering-popup-dropdown .dropdown-toggle:after {
  position: absolute;
  right: 14px;
  top: 47%;
  transform: rotate(45deg) translateY(-50%);
}

.offering-popup-dropdown-container {
  width: 90%;
}

.offering-popup-dropdown .dropdown-menu {
  padding: 16px 4px 16px 8px;
  border-radius: 16px;
  border: 1px solid #dfe3e8;
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
}

.offering-popup-dropdown .dropdown-menu .offering-popup-dropdown-wrapper {
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 8px 0 4px;
}

.offering-popup-dropdown .dropdown-menu .offering-popup-dropdown-wrapper a {
  color: #637381;
  font-family: graphik;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  background: transparent;
  padding: 0.75rem;
  display: block;
  position: relative;
}

.offering-popup-dropdown .dropdown-menu .offering-popup-dropdown-wrapper a span {
  white-space: wrap;
  word-break: break-all;
}

.offering-popup-dropdown .dropdown-menu .offering-popup-dropdown-wrapper a:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1), rgba(0, 186, 255, 0.1)) !important;
  color: #15181b;
  font-family: Graphik-Medium;
  border-radius: 5px;
}
