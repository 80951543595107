.comments-wrapper {
  position: relative;
  height: 100%;
  min-height: 310px;
}
.comments-wrapper .comments-header h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.39px;
  margin-bottom: 2px;
}
.comments-wrapper .comments-header .comments-filter {
  position: relative;
}
.comments-wrapper .comments-header input[type="search"] {
	color: #919eab;
  font-size: 12px;
  letter-spacing: -.25px;
  font-family: graphik-medium;
  border: 1px solid rgb(231, 234, 238);
  margin-bottom: 0;
  background: #fff url(../Images/search-filter.png) no-repeat;
  background-size: 16px;
  background-position: 8px;
  padding: 6px 8px 6px 30px;
  width: 100%;
  cursor: auto;
  border-radius: 16px;
}
.comments-wrapper .comments-header {
  margin-bottom: 0.5rem;
}
.comments-wrapper .comments-container {
  height: calc(100% - 164px);
  overflow-y: scroll;
  padding-right: 2px;
}
.comments-wrapper .to-message,
.comments-wrapper .from-message {
  display: flex;
  margin-bottom: 0.5rem;
  position: relative;
}
.comments-wrapper .to-message.new-message::after {
  content: '';
  position: absolute;
  background: rgb(1, 142, 255);
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-flex;
  top: 38px;
  left: 12px;
}
.comments-wrapper .from-message {
  max-width: 100%;
  justify-content: flex-end;
}
.comments-wrapper .from-message .message-text,
.comments-wrapper .reply-back .message-text {
  max-width: 87%;
}
.comments-wrapper .reply-back .message-text {
  text-align: right;
  padding: 6px 0;
}
.comments-wrapper .reply-back {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
}
.comments-wrapper .reply-back .message-text.to-reply-back {
  display: flex;
}
.comments-wrapper .reply-back .message-text.to-reply-back .message-reply-content{
  width: calc(100% - 36px);
}
.comments-wrapper .reply-back .reply-name {
  color: #018eff;
  font-family: Graphik-Medium;
}
.comments-wrapper .message-text{
  color: rgb(99, 115, 129);
  font-family: 'graphik';
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 14px;  
  background: rgb(239, 242, 245);
  border-radius: 7px;
  padding: 6px 8px;
  width: calc(100% - 36px);
}
.message-text span{
  color: rgb(145, 158, 171);
  font-family: 'graphik';
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 14px;
}
.reply-back .message-text span {
  text-align: left;
  display: inline-block;
}
.message-text span.name {
  color: rgb(21, 24, 27);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
  padding-right: 4px;
}
.message-text .reply span{  
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  display: inline-flex;
  cursor: pointer;
}
.message-text p{
  position: relative;
  text-align: left;
}
.message-text p .dropdown{
  position: absolute;
  top: 6px;
  right: 0;
  width: 10px;
  height: 4px;
  line-height: normal;
}
.message-text p .dropdown .dropdown-toggle::after {
  display: none;
}
.message-text p .dropdown .dropdown-toggle,
.message-text p .dropdown .dropdown-toggle:focus,
.message-text p .dropdown .dropdown-toggle:active,
.message-text p .dropdown .dropdown-toggle:focus:active{
  padding: 0;
  background: transparent;
  box-shadow: none;
  border: none;
  width: 10px;
  height: 4px;
}
.message-text p .dropdown .dropdown-toggle {
  width: 10px;
  height: 4px;
  background: url('../Images/ellipsis-v.svg') no-repeat !important;
  display: inline-flex;
  position: absolute;
  top: 2px;
  right: 0;
}
.message-text p .dropdown.show .dropdown-toggle {
  background: url('../Images/ellipsis-v-selected-icon.svg') no-repeat !important;
}
.message-text .dropdown-menu{
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  /* transform: translate(-150px, 10px) !important; */
}
.message-text .dropdown-menu a{
  color: rgb(99, 115, 129);
  font-family: 'graphik';
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 12px 8px 12px 36px;
  position: relative;
}
.message-text .dropdown-menu a:hover {
  background-color: rgb(1, 142, 255);
  color: #fff;
}
.message-text .dropdown-menu a.comments-edit::after {
  content: '';
  position: absolute;
  background: url('../Images/edit-comment.svg') no-repeat;
  width: 16px;
  height: 16px;  
  display: inline-flex;
  left: 8px;
  top: -2px;
  bottom: 0;
  margin: auto;
}
.message-text .dropdown-menu a.comments-delete::after {
  content: '';
  position: absolute;
  background: url('../Images/delete-comment.svg') no-repeat;
  width: 14px;
  height: 16px;
  display: inline-flex;
  left: 8px;
  top: -2px;
  bottom: 0;
  margin: auto;
}
.message-text .dropdown-menu a.comments-edit:hover::after {
  background: url('../Images/editSelected-icon.svg') no-repeat;
}
.message-text .dropdown-menu a.comments-delete:hover::after {
  background: url('../Images/deleteSelected-icon.svg') no-repeat;
}
.comments-footer .comments-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comments-footer textarea,
.comments-footer textarea:focus {
  resize: none;
  border-radius: 8px;
  border: 1px solid rgb(1, 142, 255);
  margin-bottom: 0.3rem;
  box-shadow: none;
}
.comments-wrapper .comments-footer {
  border: none;
  padding: 0;
  display: block;
}
.comments-wrapper .comments-footer p{
  color: rgb(99, 115, 129);
  font-family: 'graphik';
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 14px;
}
.comments-wrapper .comments-footer button,
.comments-wrapper .comments-footer button:focus,
.comments-wrapper .comments-footer button:active,
.comments-wrapper .comments-footer button:focus:active{
  background: rgb(1, 142, 255);
  border-radius: 20px;
  height: 32px;
  width: 89px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.comments-wrapper .comments-footer button:hover{
  background: rgb(51, 164, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
}
.comments-editor {
  border-radius: 8px;
  border: 1px solid rgb(1, 142, 255);
  margin-bottom: 0.3rem;
  box-shadow: none;
  background-color: rgb(255, 255, 255);
  padding: 0px 8px;
  position: relative;
}
.comments-editor .public-DraftStyleDefault-ltr span span {
  color: rgb(99, 115, 129);
  font-family: 'graphik';
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 16px !important;
  white-space: normal;
  word-break: break-all;
  /* display: block; */
}
.MentionsStyles_mention__2-Uks span span {
  font-weight: 600;
  color: rgb(1, 142, 255);
}
.comments-editor .public-DraftStyleDefault-block {
  margin: 0;
  line-height: 14px;
}
.comments-reply-btns {
  display: flex;
  justify-content: flex-end;
}
.comments-reply-btns .btn {
  padding: 0rem 0.75rem;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.comments-reply-btns .btn-default {
  border: 1.5px solid rgb(181, 192, 202)
}
.comments-reply-btns .btn-primary {
  background: rgb(1, 142, 255);
  border-radius: 16px;
}
.comments-editor .comments-controls {
  position: absolute;
  right: 6px;
  bottom: 1px;
  display: inline-flex;
  z-index: 1;
}
.comments-editor .comments-controls span {
  width: 10px;
  height: 10px;
  margin-right: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.comments-editor .comments-controls span i {
  font-size: 15px;
  color:  rgb(145, 158, 171);
  font-weight: 300;
}
.no-comments-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.no-comments-section img{
  width: 57px;
  height: 57px;
}
.no-comments-section h6,
.no-comments-section p.comments-metric-name{
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 20px;
  padding: 0;
  box-shadow: none;
}
.no-comments-section h6 {
  padding-bottom: 0.35rem;
}
.no-comments-section p {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: 'graphik';
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 20px;
} 
.default-profile, 
.comments-editor .default-profile,
.message-text .default-profile {
  width: 32px;
  height: 32px;
  background: rgb(38, 193, 201);
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}
.comments-editor .mention-entry-wrapper .default-profile {
  width: 24px;
  height: 24px;
  font-size: 10px;
}
.message-text .logged-user-redtext {
  color: rgb(168, 26, 95);
  font-weight: 600;
}
.message-text .other-user-bluetext {
  color: rgb(1, 142, 255);
  font-weight: 600;
}
.user-comments-text.search-style{
  font-weight: 700 !important;
  background-color: #E6DCFF;
}
.message-text .view-hide-btn {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
}
.mention-entry-wrapper>div{
  transform: translate3d(0px, 30px, 0px) !important;
  left: 0 !important;
  right: 0 !important;
  overflow-x: hidden;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
  z-index: 111;
}
.metion-entry-list {
  display: flex;
  align-items: center;
}
.metion-entry-list .entry-name{
  color: rgb(21, 24, 27);
  font-size: 10px;
  font-family: 'graphik';
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 14px;
}