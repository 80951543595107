/* MindMap CSS Start */
.mindmap-section {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
  margin-bottom: 1rem;
  height: 550px;
  overflow-y: auto;
}
.beforafterUI.mindmap-section {
  padding-top: 20px;
  height: 800px;
}
.beforafterUI.IOscore-margin {
  padding-top: 70px;
}
.mindmap-parentsection {
  width: 1096px;
  min-height: 550px;
  position: relative;
  margin: 0 auto;
}
.mindmap-links {
  position: absolute;
  top: 10px;
}
.mindmap-btnsection {
  position: relative;
  text-align: center;
  width: 100%;
}
.mindmap-links .mindmap-linkname {
  position: relative;
  display: flex;
  flex-direction: column;
}
.mindmap-name {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(1, 142, 255);
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 12px 16px;
  display: inline-block;
  min-width: 165px;
  text-align: center;
}
.mindmap-btnsection .btn-gradient-common {
  position: relative;
  top: 120px;
  width: 210px;
  font-size: 12px;
  cursor: auto !important;
}
.mindmap-links .topleft-child {
  left: 280px;
  top: 20px;
}
.mindmap-links .topright-child {
  left: 625px;
  top: -20px;
}
.mindmap-links .bottomleft-child {
  top: 190px;
  left: 135px;
}
.mindmap-links .bottom-child {
  left: 460px;
  top: 100px;
}
.mindmap-links .bottomright-child {
  left: 779px;
  top: 108px;
}
.mindmap-namelinks:before,
.mindmap-namelinks:after {
  content: " ";
  background: rgb(1, 142, 255);
  height: 14px;
  width: 14px;
  position: absolute;
  border-radius: 100%;
  border: 2px solid rgb(255, 255, 255);
}
.mindmap-namelinks {
  content: "";
  display: block;
  position: absolute;
  border: solid white 0;
  border-width: 0.18em 0.18em 0 0;
  width: 5em;
  height: 100px;
  border-radius: 0 2em 0 0;
  border-color: rgb(1, 142, 255);
  right: -90px;
  left: auto;
  bottom: auto;
  top: 20px;
}
.topleft-childlink.mindmap-namelinks {
  right: -89px;
  width: 5em;
  height: 57px;
}
.topleft-child .mindmap-namelinks:before {
  left: -8px;
  top: -8px;
}
.topleft-child .mindmap-namelinks:after {
  right: -9px;
  bottom: -13px;
}
.topright-childlink.mindmap-namelinks {
  left: -32px;
  border-width: 0.18em 0 0 0.18em;
  width: 1.5em;
  height: 3.5em;
  border-radius: 1em 0 0 0;
}
.topright-child .mindmap-namelinks:before {
  left: 14px;
  top: -8px;
}
.topright-child .mindmap-namelinks:after {
  right: 16px;
  bottom: -13px;
}
.bottomleft-childlink.mindmap-namelinks {
  right: -131px;
  top: -140px;
  border-width: 0.18em 0 0 0.18em;
  width: 3em;
  height: 10em;
  border-radius: 2em 0 0 0;
}
.bottomleft-child .mindmap-namelinks:before {
  left: 43px;
  top: -9px;
}
.bottomleft-child .mindmap-namelinks:after {
  right: 116px;
  bottom: -6px;
}
.bottom-childlink.mindmap-namelinks {
  left: 84px;
  border-width: 0 0 0 0.18em;
  width: 0;
  height: 3.25em;
  top: -60px;
}
.bottom-child .mindmap-namelinks:before {
  left: -8px;
  top: -13px;
}
.bottom-child .mindmap-namelinks:after {
  right: -6px;
  bottom: -7px;
}
.mindmap-links .doublelinkelement {
  content: "";
  display: block;
  position: absolute;
  border: solid white 0;
  border-width: 0 0.18em 0.18em 0;
  width: 4.9em;
  height: 0px;
  border-radius: 0 3em 0 0;
  border-color: rgb(1, 142, 255);
  bottom: -1px;
  right: 47px;
}
.bottomright-childlink.mindmap-namelinks {
  left: -115px;
  top: -140px;
  border-width: 0.18em 0.18em 0 0;
  width: 3em;
  height: 10em;
  border-radius: 0 2em 0 0;
}
.bottomright-child .mindmap-namelinks:before {
  left: -10px;
  top: -8px;
}
.bottomright-child .mindmap-namelinks:after {
  left: 100px;
  bottom: -6px;
}
.mindmap-links .bottomright-childlink .doublelinkelement {
  left: 46px;
  width: 3.7em;
  bottom: -2px;
}
.addmindmap-links {
  position: absolute;
  right: -25px;
  top: 9px;
}
.beforafterUI .addmindmap-links {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  right: -68px;
  bottom: 0;
  top: 0;
  z-index: 1;
}
.beforafterUI .addmindmap-links span {
  width: 24px;
  height: 24px;
  background: #018eff;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
}
.beforafterUI .addmindmap-links span i {
  color: #fff;
  font-size: 14px;
}
/* .topleft-child .mindmaplevel-dropdownvalueadd, 
.bottomleft-child .mindmaplevel-dropdownvalueadd{
  flex-direction: row-reverse;
} */
.topleft-child .addmindmap-links,
.bottomleft-child .addmindmap-links {
  left: -25px;
  top: 9px;
  right: auto;
}
.addmindmap-links span {
  color: #018eff;
  cursor: pointer;
}
.mindmap-links .mindmap-linkname ul {
  position: absolute;
  left: auto;
}
.beforafterUI .mindmap-links .mindmap-linkname ul {
  right: 0;
}
.beforafterUI .mindmap-links .mindmap-linkname.bottom-child ul.mapping-timeline {
  left: -75px;
}
.bottom-child .mapping-commentstimeline .mappinglabel-unit {
  text-align: right;
  padding-left: 140px;
}
.mindmap-links .topright-child ul.submenu-treelinks {
  position: absolute;
  right: -253px;
  top: 0;
}
.mindmap-links .topleft-child ul.submenu-treelinks {
  left: -247px;
  top: 0px;
}
.mindmap-links .topleft-child ul.submenu-treelinks:before {
  right: -40px;
  left: auto;
  top: 20px;
  height: calc(100% - 48px);
}
.mindmaplevel-dropdownaction {
  position: absolute;
  right: -63px;
  top: 9px;
}
.topleft-child .mindmaplevel-dropdownaction,
.bottomleft-child .mindmaplevel-dropdownaction {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  left: -75px;
  top: 9px;
  right: auto;
}
.mindmap-links .topleft-child ul.submenu-treelinks li:before {
  right: -15px;
  left: auto;
}
.mindmap-links .topleft-child ul.submenu-treelinks li::after {
  right: -40px;
  left: auto;
  top: 18px;
}
.mindmap-links .bottomleft-child ul.submenu-treelinks {
  left: -105px;
  top: 60px;
  right: auto;
}

.mindmap-links .bottomleft-child ul.submenu-treelinks:before {
  right: -40px;
  left: auto;
  top: -20px;
  height: calc(100% - 10px);
}
.mindmap-links .bottomleft-child ul.submenu-treelinks li:after {
  right: -40px;
  left: auto;
  top: 18px;
}
.mindmap-links .bottomleft-child ul.submenu-treelinks li:before {
  right: -14px;
  left: auto;
}
.mindmap-links .bottomright-child ul.submenu-treelinks {
  right: -110px;
  top: 60px;
}
.mindmap-links .bottom-child ul.submenu-treelinks {
  top: 60px;
  right: -20px;
}
.mindmap-links .bottom-child ul.submenu-treelinks:before {
  top: -39px;
  height: calc(100% + 10px);
}
.mindmap-links .bottom-child ul.submenu-treelinks:after {
  content: " ";
  position: absolute;
  border: solid white 0;
  border-width: 0 2px 2px 0;
  background: rgb(1, 142, 255);
  width: 2.45em;
  height: 0px;
  border-radius: 0 0em 0 0;
  border-color: rgb(1, 142, 255);
  top: -39px;
  left: -40px;
}
.mindmap-links ul.submenu-treelinks:before {
  content: " ";
  background: rgb(1, 142, 255);
  position: absolute;
  width: 2px;
  height: calc(100% - 48px);
  left: -40px;
  top: 18px;
}
/* .mindmaplevel-dropdown{
  display: flex;
  align-items: center;
} */
/* .topleft-child .mindmaplevel-dropdown,
.bottomleft-child .mindmaplevel-dropdown{
  flex-direction: row-reverse;
} */
.mindmaplevel-dropdown .dropdown-toggle {
  background: rgb(244, 251, 255) !important;
  border-radius: 16px;
  border: 1.5px solid rgb(1, 142, 255) !important;
  color: rgb(33, 43, 54) !important;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  width: 184px;
  text-align: left;
  box-shadow: none !important;
  outline: none !important;
  padding: 0.6rem 0.75rem;
  display: flex;
  align-items: center;
  height: 40px;
}
.mindmaplevel-dropdown .dropdown-toggle span {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: initial;
}
.mindmaplevel-dropdown .dropdown-toggle:after {
  position: absolute;
  right: 1rem;
  top: 15px;
  border-bottom: 2px solid rgb(33, 43, 54);
  border-right: 2px solid rgb(33, 43, 54);
}
.mindmaplevel-dropdownaction span {
  background: rgb(196, 205, 213);
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  margin-left: 8px;
  cursor: pointer;
}
.mindmaplevel-dropdownaction span:hover {
  background: rgb(1, 142, 255);
  color: #fff;
}
.mindmaplevel-dropdownaction span i {
  font-size: 12px;
}
.mindmaplevel-dropdown .dropdown-menu {
  max-width: 184px;
}
.mindmaplevel-dropdown .dropdown-item {
  color: rgb(21, 24, 27);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 14px;
  white-space: normal;
  padding: 0.4rem 0.5rem;
}
.mindmaplevel-dropdown .dropdown-item:hover {
  background: rgb(0, 142, 255);
  border-radius: 4px;
  color: #fff;
}
.mindmap-links ul.submenu-treelinks li:before {
  content: " ";
  background: rgb(1, 142, 255);
  border: 2px solid rgb(255, 255, 255);
  height: 14px;
  width: 14px;
  position: absolute;
  border-radius: 100%;
  left: -15px;
  top: 12px;
  z-index: 2;
}
.mindmap-links ul.submenu-treelinks li {
  margin-bottom: 8px;
  position: relative;
  clear: both;
}
.mindmap-links ul.submenu-treelinks li:after {
  content: " ";
  background: rgb(1, 142, 255);
  height: 2px;
  width: 2rem;
  position: absolute;
  left: -40px;
  top: 17px;
}
.mindmap-links .topright-child ul.submenu-treelinks li:first-child:after {
  width: 4rem;
  left: -69px;
}
.mindmap-links .topleft-child ul.submenu-treelinks li:first-child:after {
  width: 3.5rem;
  right: -63px;
}
.topleft-child .mindmaplevel-dropdownvalue,
.bottomleft-child .mindmaplevel-dropdownvalue {
  /* float:right; */
  margin-bottom: 8px;
}
.mindmaplevel-dropdownvalue {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  width: 184px;
  border-radius: 16px;
  border: 1px solid rgb(196, 205, 213);
  padding: 0.35rem 0.75rem;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mindmaplevel-dropdownvalue span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.mindmaplevel-dropdownvalue .edit-delete-dropdown .dropdown-toggle {
  transform: rotate(90deg);
  position: relative;
  top: 3px;
}
.mindmap-links .bottomright-child ul.submenu-treelinks:before {
  height: calc(100% - 10px);
  top: -20px;
}
/* .mindmaplevel-dropdownvalueadd{
  display: flex;
  align-items: center;
} */
/* MindMap CSS End*/
.mindmap-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #919eab;
  margin-bottom: 1rem;
}
.mindmap-header h4 {
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.25px;
  color: #000;
  margin-bottom: 0;
}

.save-mindmap .btn,
.edit-mindmap .btn {
  border-radius: 20px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 6px 16px;
  outline: none !important;
  box-shadow: none !important;
}
.edit-mindmap .btn {
  background: rgb(255, 255, 255) !important;
  border: 1.5px solid rgb(1, 142, 255);
  color: rgb(1, 142, 255) !important;
}

.edit-mindmap .btn:hover,
.edit-mindmap .btn:active {
  background: rgb(1, 142, 255) !important;
  color: rgb(255, 255, 255) !important;
  border: 1.5px solid rgb(1, 142, 255) !important;
}

/* .save-mindmap .btn,
.save-mindmap .btn:hover,
.save-mindmap .btn:active{
  background: rgb(1, 142, 255) !important;
  color: rgb(255, 255, 255) !important;
  border: 1.5px solid rgb(1, 142, 255)!important;
} */

.save-mindmap .btn.btn-default {
  color: #919eab;
}
.offering-droplistwrapper .droplist-wrapper {
  max-height: 176px;
  overflow-y: scroll;
}

.note-msg {
  font-family: Graphik-Medium;
  font-size: 12px;
  position: absolute;
  right: -21px;
  margin-top: 6px;
}

.kpi-name {
  line-height: 12px;
  padding: 12px !important;
  margin-top: 10px !important;
  margin-left: 10px !important;
}
.mindmap-links .bottomright-child ul.submenu-treelinks {
  right: -110px;
  top: 60px;
}

/* BeforeAfter UI CSS Start */
.beforafterUI .mindmap-btnsection .btn-gradient-common {
  top: 375px;
  height: 40px;
  padding: 4px;
}
.mindmap-links .top-child {
  left: 455px;
  top: -10px;
}
.beforafterUI .mindmap-links .topleft-child {
  left: 200px;
  top: 2px;
}
.beforafterUI .topleft-childlink.mindmap-namelinks {
  height: 18em;
}
.beforafterUI .topright-childlink.mindmap-namelinks {
  width: 3.5em;
  height: 18em;
  border-radius: 2em 0 0 0;
  left: -67px;
}
.beforafterUI .topright-child .mindmap-namelinks:before {
  left: 49px;
}
.beforafterUI .topright-child .mindmap-namelinks::after {
  right: 48px;
}
.beforafterUI .mindmap-links .topright-child {
  left: 700px;
  top: -40px;
}
.beforafterUI .mindmap-links .bottomleft-child {
  top: 452px;
}
.beforafterUI .mindmap-links .bottomright-child {
  top: 369px;
}
.beforafterUI .bottomleft-child .mindmap-namelinks:after {
  right: 103px;
}
.beforafterUI .mindmap-links .bottomleft-childlink .doublelinkelement {
  right: 45px;
  width: 4em;
}
.beforafterUI .mindmap-links .bottomright-childlink .doublelinkelement {
  left: 45px;
}
.beforafterUI .bottomright-child .mindmap-namelinks:after {
  left: 98px;
}
.mindmap-links .middleleft-child {
  top: 225px;
  left: 150px;
}
.mindmap-links .middleright-child {
  left: 779px;
  top: 183px;
}
.beforafterUI .mindmap-links .bottom-child {
  top: 410px;
}
.top-childlink.mindmap-namelinks {
  left: 90px;
  border-width: 0 0 0 0.18em;
  width: 0;
  height: 19em;
  top: 56px;
}
.beforafterUI .mindmap-links .bottom-child ul.submenu-treelinks li:after {
  width: 3rem;
  left: -51px;
  top: 18px;
}
.beforafterUI .mindmap-links .bottom-child ul.submenu-treelinks:after {
  left: -53px;
}
.beforafterUI .mindmap-links .bottom-child ul.submenu-treelinks:before {
  left: -53px;
  top: -38px;
}
.top-child .mindmap-namelinks:after {
  right: -6px;
  bottom: -14px;
}
.top-child .mindmap-namelinks:before {
  left: -8px;
  top: -13px;
}
.middleleft-childlink.mindmap-namelinks {
  left: 185px;
  border-width: 0.18em 0em 0 0;
  width: 6em;
  height: 0;
  top: 20px;
  border-radius: 0;
}
.beforafterUI .middleleft-child .mindmap-namelinks:before {
  left: -6px;
  top: -8px;
}
.beforafterUI .middleleft-child .mindmap-namelinks::after {
  right: -13px;
  top: -8px;
}
.middleright-childlink.mindmap-namelinks {
  left: -116px;
  border-width: 0.18em 0em 0 0;
  width: 6.5em;
  height: 0;
  top: 20px;
  border-radius: 0;
}
.beforafterUI .middleright-child .mindmap-namelinks:before {
  left: -8px;
  top: -8px;
}
.beforafterUI .middleright-child .mindmap-namelinks::after {
  right: -11px;
  top: -8px;
}
.beforafterUI .bottomleft-childlink.mindmap-namelinks {
  right: -119px;
  top: -263px;
  height: 17.8em;
}
.beforafterUI .bottomright-childlink.mindmap-namelinks {
  left: -114px;
  top: -262px;
  height: 17.8em;
}

.commenticon-block {
  width: 32px;
  height: 32px;
  padding: 5px;
  background: linear-gradient(to right, #a100ff 0%, #00baff 100%);
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: -38px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.commenticon-blockcircle {
  background: #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: absolute;
  left: 2px;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.commenticon-blockcircle i {
  background: linear-gradient(-225deg, #a100ff, #018eff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline-block;
}
.commenticon-block:hover .commenticon-blockcircle {
  background: linear-gradient(to right, #a100ff 0%, #00baff 100%);
  color: #fff;
}
.commenticon-block:hover .commenticon-blockcircle i {
  background: none;
  -webkit-background-clip: revert;
  -webkit-text-fill-color: revert;
}
.bottomleft-child .commenticon-block,
.topleft-child .commenticon-block,
.middleleft-child .commenticon-block {
  left: -38px;
  right: auto;
}
.newcomment-notification {
  content: " ";
  position: absolute;
  background: rgb(170, 0, 37);
  height: 10px;
  width: 10px;
  top: 0;
  right: 0;
  border-radius: 50%;
  z-index: 2;
}
.mapping-timeline {
  display: flex;
  list-style-type: none;
  background: linear-gradient(to right, #a100ff 0%, #00baff 100%);
  height: 4px;
  border-radius: 8px;
  position: absolute;
  width: 372px;
}
.mapping-timeline li {
  position: relative;
  /* margin: 0 10px; */
  flex-grow: 1;
}
.mapping-timeline > li:nth-child(2) {
  flex-grow: 2;
}
.timeline-series {
  display: flex;
}
.timeline-series ul > li {
  flex-grow: 1;
  cursor: pointer;
}
.timeline-series ul {
  width: 100%;
  display: flex;
  height: 4px;
}
.mapping-timeline > li::before,
.mapping-timeline > li::after,
.timeline-series ul > li::before,
.timeline-series ul > li::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
  border-radius: 50%;
}
.mapping-timeline > li::after,
.timeline-series ul > li::after {
  width: 12px;
  height: 12px;
  background: rgb(255, 255, 255);
}
.timeline-series::before,
.timeline-series::after {
  display: none;
}
.time-series-top {
  color: rgb(33, 43, 54);
  font-size: 0.6875rem;
  font-family: "graphik";
  font-weight: normal;
  text-align: center;
  letter-spacing: 0px;
  line-height: 17.12px;
  position: absolute;
  top: -24px;
  left: 0;
  right: 0;
  margin: auto;
}
.time-series-bottom {
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: auto;
}
.clientstory-section .time-series-bottom p {
  color: rgb(99, 115, 129);
  font-size: 0.54rem;
  font-family: "graphik";
  font-weight: normal;
  text-align: center;
  letter-spacing: 0px;
  line-height: 17.12px;
  padding: 1.7px 0;
  white-space: nowrap;
}
.mapping-timeline .timeline-heading {
  position: relative;
  bottom: 30px;
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 17.12px;
}
.timeline-datablock {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 17.12px;
  position: absolute;
  top: 1rem;
}
.beforafterUI .timeline-datablock p {
  margin-bottom: 5px;
  text-align: center;
  font-family: graphik !important;
  font-size: 12px;
}
.time-series-bottom p.month-line {
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 0px;
}
.time-series-bottom p.month-line:after {
  content: " ";
  background: #a100ff;
  height: 1px;
  width: 20px;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.middleleft-child .mapping-timeline {
  /* top: 75px; */
  width: 372px;
}
.beforafterUI .topleft-child .addmindmap-links,
.beforafterUI .bottomleft-child .addmindmap-links,
.beforafterUI .middleleft-child .addmindmap-links {
  left: -68px;
  top: 1px;
}
/* .beforafterUI .topright-child .addmindmap-links,
.beforafterUI .bottomright-child .addmindmap-links{
right: -60px;
top: 1px;
} */
/* .beforafterUI .addmindmap-links{
  display: none;
} */
.beforafterUI .mindmap-name {
  cursor: pointer;
  width: 178px;
  display: flex;
  align-items: center;
  padding: 2px;
  height: 41px;
  justify-content: center;
  font-size: 12px;
}
.beforafterUI .mindmap-name:hover {
  color: #fff;
  background: rgb(1, 142, 255);
}
.beforafterUI .mindmaplevel-dropdownaction {
  right: -33px;
}
.beforafterUI .mindmaplevel-dropdown .mindmaplevel-dropdownaction {
  right: -60px;
}
.timelinevalues-form {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 0.5px solid rgba(99, 115, 129, 0.56);
  width: 400px;
  padding: 16px 8px;
  margin-top: 10px;
  position: absolute;
  left: 0px;
  top: 35px;
  z-index: 2;
}
.topleft-child .timelinevalues-form,
.middleleft-child .timelinevalues-form,
.bottomleft-child .timelinevalues-form {
  right: 0;
  left: auto;
}

.bottomleft-child .timelinevalues-form {
  top: 100px;
}
.top-child .timelinevalues-form {
  top: -260px;
}
.beforafterUI .mindmap-parentsection.ioscore-topmargin {
  margin-top: 260px;
}
.bottom-child .timelinevalues-form {
  left: -65px;
  top: 160px;
}

.timelinevalues-formheading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.timelinevalues-formheading h5 {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 0;
}
.beforafterUI .timelinevalues-formheading .mindmaplevel-dropdownaction {
  position: relative;
  right: 0;
  top: 0;
  left: auto;
  flex-direction: row;
}
.timelinevalues-formfilelds {
  max-height: 155px;
  overflow-y: auto;
  padding-right: 5px;
  margin-bottom: 10px;
  padding-bottom: 2px;
}
.timelinevalues-formrow {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.timelinevalues-formrow:last-child {
  margin-bottom: 0;
}
.timelinevalues-formrow label {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 0;
}
.timelinevalues-formrow .dropdown-toggle {
  background: rgb(255, 255, 255) !important;
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232) !important;
  color: rgb(0, 0, 0) !important;
  font-family: graphik;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  width: 100%;
  text-align: left;
  box-shadow: none !important;
  outline: none !important;
  padding: 0.6rem 0.25rem;
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 6px;
  padding-right: 1.5rem;
}
.timelinevalues-formrow .dropdown-toggle span {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: initial;
}
.timelinevalues-formrow .dropdown-toggle:after {
  position: absolute;
  right: 1rem;
  top: 10px;
  border-bottom: 1.5px solid rgb(99, 115, 129);
  border-right: 1.5px solid rgb(99, 115, 129);
}
.timelinevalues-formrow .dropdown-menu {
  min-width: 115px;
  width: 100%;
}
.timelinevalues-formrow .dropdown-item {
  color: rgb(21, 24, 27);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  white-space: normal;
  padding: 0.4rem 0.4rem;
}
.timelinevalues-formrow .dropdown-item:hover {
  background: rgb(0, 142, 255);
  border-radius: 4px;
  color: #fff;
}
.timelinevalues-input input {
  background: rgb(255, 255, 255) !important;
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232) !important;
  color: rgb(0, 0, 0) !important;
  font-family: graphik;
  font-size: 11px;
  font-weight: 500;
  height: 32px;
  outline: none !important;
  box-shadow: none !important;
  width: 100%;
  padding: 0 4px;
}
.timelinevalues-input .datepicker-label-wrapper input {
  padding-right: 1.5rem;
}
.timeline-clicktext span {
  margin-bottom: 0;
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  cursor: pointer;
}
.timeline-clicktext span i {
  color: #018eff;
  margin-right: 4px;
}
.timeline-circle {
  width: 16px;
  height: 16px;
  padding: 2px;
  background: linear-gradient(to right, #a100ff 0%, #00baff 100%);
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.timeline-circlegrad {
  background: #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 2px;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menulist-tooltips {
  background: rgb(21, 24, 27);
  border-radius: 16px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  width: 186px;
}
.timelinedigit-tooltips {
  background: rgb(21, 24, 27);
  border-radius: 16px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  width: 167px;
  text-align: center;
}
.mapping-comments {
  position: absolute;
  left: auto;
  right: 0;
  display: flex;
  flex-direction: column;
  top: 115px;
}
.nonconfiguremode-mindmaplinks .mapping-comments {
  top: 75px;
}
.topright-child .mapping-comments,
.middleright-child .mapping-comments {
  right: auto;
  left: 0;
}
.onclick-timelinedata .mapping-comments {
  right: 0;
  left: 0;
  top: 145px;
}
.topright-child .mapping-timeline,
.middleright-child .mapping-timeline {
  left: 0 !important;
}
.bottomright-child .mapping-timeline {
  left: 0 !important;
}
.mapping-commentstimeline {
  height: 60px;
}
.mindmap-commentryform {
  position: relative;
  margin-bottom: 8px;
  min-width: 300px;
}
.mindmap-commentrysection textarea {
  min-width: 300px;
  position: relative;
  resize: none;
  padding: 0.8rem 0.75rem;
  padding-right: 16px;
}
.mindmaplevel-dropdownvalue::after {
  content: "";
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 7px;
  height: 33px;
  background: transparent;
}
.bottomleft-child .mindmaplevel-dropdownvalue::after,
.topleft-child .mindmaplevel-dropdownvalue::after {
  left: -0.5px;
  right: auto;
  transform: rotateY(150deg);
}
.mindmaplevel-dropdownvalue.ragstatus-red::after {
  background: url("../Images/Red-RAGStatus.svg");
}
.mindmaplevel-dropdownvalue.ragstatus-amber::after {
  background: url("../Images/Yellow-RAGStatus.svg");
}
.mindmaplevel-dropdownvalue.ragstatus-green::after {
  background: url("../Images/Green-RAGStatus.svg");
}
.mindmaplevel-dropdownvalue.ragstatus-grey::after {
  background: url("../Images/Grey-RAGStatus.svg");
}
.beforafterUI .bottom-childlink.mindmap-namelinks {
  height: 13.8em;
  top: -236px;
}
.beforafterUI .mindmap-parentsection {
  margin-top: 20px;
  margin-bottom: 20px;
}
.beforafterUI.IOscore-timeline-margin .mindmap-parentsection {
  margin-top: 100px;
}
.beforafterUI.IOscore-margin .mindmap-parentsection {
  margin-top: 200px;
}
.beforafterUI .top-child .mapping-comments {
  top: -180px;
  left: -60px;
  right: 0;
}
.beforafterUI .top-child .mapping-comments.no-commentsblock {
  top: -80px;
}
.beforafterUI .top-child .mapping-timeline {
  left: -28px;
}
.subtree-timelineclose {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: -10px;
}
.subtree-timelineclose span {
  font-size: 16px;
  color: #919eab;
}
/* .mindmap-links .mindmap-linkname ul.submenu-treelinks{
      max-height: 250px;
      overflow-y: auto;
    } */
.beforeafter-detail {
  padding: 1rem 1.5rem;
}
.timeline-btngradient {
  min-width: 230px;
  display: inline-flex;
  /* margin-bottom: 1rem; */
}
.beforeafterlever-carousel {
  margin: 16px 0;
}
.beforeafterlever-carousel .owl-carousel {
  padding: 0 30px;
}
.beforeafterlever-name {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(1, 142, 255);
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
  padding: 12px 30px;
  display: inline-block;
  min-width: 150px;
  cursor: pointer;
}
.beforeafterlever-name:hover,
.beforeafterlever-name.active {
  background: rgb(1, 142, 255);
  color: #fff;
}
.beforeafterlever-carousel .owl-carousel .owl-nav [class*="owl-"] {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  height: 20px;
  width: 12px;
}
.beforeafterlever-carousel .owl-carousel .owl-nav .owl-next:before,
.beforeafterlever-carousel .owl-carousel .owl-nav .owl-prev:before {
  width: 12px;
  content: "";
  height: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 8px 0 0 -6px;
  background-size: 100%;
  transform: none;
}
.beforeafterlever-carousel .owl-carousel .owl-nav .owl-prev:before {
  background: transparent url(../Images/Left_arrow.svg) no-repeat scroll center;
  left: 5px;
}
.beforeafterlever-carousel .owl-carousel .owl-nav .owl-next:before {
  background: transparent url(../Images/Right_arrow.svg) no-repeat scroll center;
}
.beforeafter-detailcontent {
  display: flex;
  margin-top: 1rem;
}
.beforeafter-detailcontentleft {
  width: calc(73% - 1rem);
  margin-right: 1rem;
}
.beforeafter-detailcontentleft h3 {
  color: rgb(99, 115, 129) !important;
  font-family: Graphik-Medium;
  font-size: 20px !important;
  font-weight: 500;
  letter-spacing: 0px;
}
.beforeafter-detailcontentright {
  width: 27%;
  background: rgb(255, 255, 255);
  border-radius: 0px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.33);
  padding: 8px;
  height: 520px;
}
.comments-headersection h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.39px;
  margin-bottom: 8px;
}
.beforeafter-detailcontentright .empty-commentsection h6 {
  box-shadow: none;
  padding: 0;
  padding-top: 1rem;
}
.beforeafter-detailtimeline {
  position: relative;
  padding-top: 60px;
  padding-bottom: 150px;
}
.beforeafter-detailtimeline .mapping-timeline {
  height: 12px;
  width: 100%;
}
.beforeafter-detailtimeline .time-series-top {
  font-size: 16px;
  top: -45px;
}
.beforeafter-detailtimeline .mapping-timeline > li::before,
.beforeafter-detailtimeline .timeline-series ul > li::before {
  width: 40px;
  height: 40px;
}
.beforeafter-detailtimeline .mapping-timeline > li::after,
.beforeafter-detailtimeline .timeline-series ul > li::after {
  width: 28px;
  height: 28px;
}
.beforeafter-detailtimeline .mapping-timeline > li.active-timeline::before,
.beforeafter-detailtimeline .timeline-series ul > li.active-timeline::before {
  width: 56px;
  height: 56px;
}
.beforeafter-detailtimeline .mapping-timeline > li.active-timeline::after,
.beforeafter-detailtimeline .timeline-series ul > li.active-timeline::after {
  width: 40px;
  height: 40px;
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
  border: 2px solid #fff;
}
.beforeafter-detailtimeline .time-series-bottom {
  top: 40px;
}
.clientstory-section .beforeafter-detailtimeline .time-series-bottom p {
  font-size: 14px;
  padding-bottom: 10px;
  margin-bottom: 8px;
  line-height: 11px;
}
.beforeafter-detailcontentright .charts-commentsection .comments-body .profile-info h6 {
  padding: 0;
  background: transparent;
  box-shadow: none;
}
.beforeafter-detailcontentright .charts-commentsection .searchFilter input[type="search"] {
  width: 279px !important;
  height: 32px;
}
.growthreasons-section {
  background: rgb(249, 250, 251);
  border-radius: 16px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 1.5rem;
  position: relative;
  box-sizing: border-box;
}
.growthreasons-section::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -0.5em;
  top: 0;
  left: 50%;
  box-sizing: border-box;
  border: 1em solid #eee;
  border-color: #f9fafb #f9fafb transparent transparent;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
}
.growthreasons-sectionheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.growthreasons-section h5 {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.39px;
  margin-bottom: 0;
}
.growthreasons-content {
  display: flex;
}
.growthreasons-contentleft {
  width: 25%;
  margin-right: 16px;
  border-right: 1px solid rgb(145, 158, 171);
}
.growthreasons-contentright {
  width: 75%;
}
.growthreasons-list li {
  margin-bottom: 10px;
}
.growthreasons-list {
  position: relative;
}
.growthreasons-list label.form-check {
  padding-left: 1.5rem;
  display: inline;
}
.growthreasons-list .form-check .checkmark {
  height: 24px;
  width: 24px;
}
.growthreasons-list .form-check input[type="radio"] + .checkmark:after {
  margin-left: -8px;
  margin-top: -8px;
  height: 16px;
  width: 16px;
}
.growthreasons-list .form-check-label {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  vertical-align: middle;
  position: relative;
  margin-left: 8px;
  line-height: 16px;
  bottom: 0px;
}
.growthreasons-list .form-check-label.active {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.form-check mr-3 .disabled {
  opacity: 0.5;
  pointer-events: none;
}
.growthreasons-contentright .form-check .checkmark {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.growthreasons-contentright .form-check .checkmark:after {
  left: 7px;
  top: 3px;
}
.growthreasons-contentright .form-check-label {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-top: 5px;
  margin-left: 12px;
}
.growthreasons-contentright .form-check-label.active,
.growthreasons-contentright .form-check-label.label-greyout {
  color: rgb(21, 24, 27);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.growthreasons-contentright ul {
  display: flex;
  flex-wrap: wrap;
  max-height: 160px;
  overflow: auto;
}
.growthreasons-contentright ul li {
  margin-bottom: 10px;
  width: 50%;
}
/* BeforeAfter UI CSS End */
.purpleborder-dropdown {
  display: flex;
}
.purpleborder-dropdown .dropdown-toggle {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255) !important;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0.75rem 1rem;
  text-align: left;
  outline: none !important;
  box-shadow: none !important;
  position: relative;
}
.purpleborder-dropdown .dropdown-toggle:hover,
.purpleborder-dropdown .dropdown-toggle:active,
.purpleborder-dropdown .dropdown-toggle:focus,
.purpleborder-dropdown .show .dropdown-toggle {
  background: rgb(179, 51, 255) !important;
  color: #fff !important;
  border: 1.5px solid rgb(179, 51, 255);
}
.purpleborder-dropdown .dropdown-toggle:after {
  border-bottom: 2px solid rgb(161, 0, 255);
  border-right: 2px solid rgb(161, 0, 255);
  position: absolute;
  right: 1rem;
  top: 14px;
}
.purpleborder-dropdown .dropdown-toggle:hover:after,
.purpleborder-dropdown .show .dropdown-toggle:after,
.purpleborder-dropdown .dropdown-toggle:active:after,
.purpleborder-dropdown .dropdown-toggle:focus:after {
  border-bottom: 2px solid rgb(255, 255, 255);
  border-right: 2px solid rgb(255, 255, 255);
}
.purpleborder-dropdown .metrictoggle-btn .dropdown-toggle {
  min-width: 325px;
}
/* .purpleborder-dropdown .dropdown-menu .dropdown-item,
  .purpleborder-dropdown .dropdown-menu .dropdown-item span{
    white-space: break-spaces;
  } */
.purpleborder-dropdown .dropdown-menu .dropdown-item span.form-check-label {
  margin-left: 0;
}
.purpleborder-dropdown .metrictoggle-btn .dropdown-menu {
  width: 100%;
}
.purpleborder-dropdown .searchFilter input[type="search"] {
  width: 310px !important;
}
.metrictoggle-btn {
  min-width: 325px;
}
.dropdownlist-wrapper {
  max-height: 200px;
  overflow-y: auto;
}
.timeline-activeborder {
  position: absolute;
  background: #018eff;
  width: 100%;
  height: 2.5px;
  top: 116px;
}
.active-timeline .timeline-activeborder:before {
  content: "";
  display: block;
  position: absolute;
  width: 0em;
  height: 20px;
  border-radius: 0 0 0 0;
  border: 0 solid #018eff;
  border-width: 0.15em 0.15em 0 0;
  right: -90px;
  left: 0;
  bottom: auto;
  top: -20px;
}
.active-timeline .timeline-activeborder.last-element:before {
  right: 0px;
  left: auto;
}
.active-timeline .timeline-activeborder:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: -4.5px;
  width: 0;
  height: 0;
  margin: 25px auto;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 9px solid #018eff;
}
.active-timeline .timeline-activeborder.last-element:after {
  right: -4.5px;
  left: auto;
}
.active-timeline .timeline-activeborder.first-element {
  width: 50%;
  right: 0;
}
.active-timeline .timeline-activeborder.last-element {
  width: 50%;
}
.beforeafter-detailcontentleft .dropdownlist-wrapper .rag-status-color,
.beforeafter-detailcontentleft .dropdownlist-wrapper.metric-dropdown-main .rag-status-color {
  position: relative;
  white-space: normal;
}
.beforeafter-detailcontentleft .dropdownlist-wrapper .rag-status-color:before,
.metrictoggle-btn .rag-status-color:before {
  content: " ";
  position: absolute;
  width: 4px;
  height: 14px;
  border-radius: 2px;
  left: 6px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.beforeafter-detailcontentleft
  .dropdownlist-wrapper.metric-dropdown-main
  .rag-status-color::before {
  height: 60%;
}
.timelinevalues-form.compliance-Form {
  top: 166px !important;
}

.timelinevalues-form.editMetric-Form {
  top: 50px !important;
}

.timelinevalues-form.addCustom-Form {
  top: 95px !important;
}

.timelinevalues-formcollabel {
  width: 25%;
  margin-right: 8px;
}
.timelinevalues-formcol {
  width: 75%;
  display: flex;
}
.timelinevalues-formcol .timelinevalues-input {
  width: 45%;
  margin-right: 8px;
}
.beforeAfterErrormsg {
  color: #721c24;
  letter-spacing: -0.25px;
  font-size: 9px;
  text-align: center;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 4px 3px;
  border-radius: 4px;
  margin: 6px 0;
  display: block;
}
.mappinglabel-unit {
  font-size: 14px;
  font-family: graphik !important;
  font-weight: normal;
}
.mapping-commentstimeline .mappinglabel-unit {
  position: relative;
  top: 55px;
  font-size: 12px;
  font-family: graphik !important;
  white-space: nowrap;
  text-align: right;
}
.top-child .mapping-commentstimeline .mappinglabel-unit,
.topright-child .mapping-commentstimeline .mappinglabel-unit,
.middleright-child .mapping-commentstimeline .mappinglabel-unit,
.bottomright-child .mapping-commentstimeline .mappinglabel-unit {
  text-align: right;
  width: 372px;
}
.top-child .mapping-commentstimeline .mappinglabel-unit {
  right: 28px;
}
.mapping-commentstimeline .mappinglabel-unit span,
.mappinglabel-unit span {
  color: #a100ff;
}
.timelinevalues-formrow .ragstatus-circle .dropdown-toggle:before {
  content: " ";
  position: absolute;
  left: 10px;
  height: 16px;
  width: 16px;
  border-radius: 100%;
}

.timelinevalues-formrow .ragstatus-circle.redColor .dropdown-toggle:before {
  background: #aa0025;
}
.timelinevalues-formrow .ragstatus-circle.amberColor .dropdown-toggle:before {
  background: #f49342;
}
.timelinevalues-formrow .ragstatus-circle.greenColor .dropdown-toggle:before {
  background: #7ec344;
}
.timelinevalues-formrow .ragstatus-circle.greyColor .dropdown-toggle:before {
  background: #7b8e98;
}
.timelinevalues-form .datepicker-label-wrapper {
  position: relative;
}
.timelinevalues-form .datepicker-label-wrapper .calender-placment {
  font-size: 13px;
  top: 7px;
  right: 8px;
}
.timelinevalues-form .datepicker-label-wrapper .react-datepicker-wrapper {
  width: 100%;
}
.pyramids-block {
  display: flex;
  justify-content: center;
}
.pyramid-block1 .pyramid-chart {
  left: 70%;
}
.pyramid-block2 .pyramid-chart {
  right: -16%;
  left: auto;
}
.pyramids-block .human-label span {
  color: #a100ff !important;
}
.pyramids-block .machine-label span {
  color: #018eff !important;
}
.pyramids-block .human-label,
.pyramids-block .machine-label {
  margin-right: -45px;
}
.pyramid-block1 .human-machine-labels {
  float: left;
  position: relative;
  left: 50%;
  top: 10px;
}
.pyramid-block2 .human-machine-labels {
  right: 45%;
  position: relative;
}
.pyramidblock-label {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  position: relative;
}
.pyramid-block1 .pyramidblock-label {
  left: 65%;
}
.pyramid-block2 .pyramidblock-label {
  left: 10%;
}

.submenu-treeleveldropdown {
  margin-top: 2rem;
}
.mindmap-links ul.submenu-treelinks .submenu-treeleveldropdown li:before {
  top: -16px;
  left: 45%;
}
.beforafterUI .mindmap-links ul.submenu-treelinks .submenu-treeleveldropdown li:after {
  left: 88.45px;
  top: -30px;
  height: 1rem;
  width: 2px;
}
.beforafterUI .submenu-treelinks .mindmaplevel-dropdown .dropdown-toggle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 2rem;
}
.mindmaplevel-dropdownvalue.mindmapdropdownvalue-deleted {
  border: 1px solid #aa0025;
}
.mindmaplevel-dropdownvalue.mindmapdropdownvalue-deleted > span {
  color: #aa0025;
}
.metric-name {
  line-height: 12px;
  padding: 12px !important;
  margin-top: 10px !important;
  margin-left: 10px !important;
  width: 150px;
}

.timelinevalues-input .react-datepicker__month-container {
  width: 127px;
}

.timelinevalues-form.IOScore-timelineForm {
  width: 500px;
}
input[type="checkbox"].form-check-input.greyout ~ .checkmark {
  background-color: white !important;
  border-color: rgb(181, 192, 202) !important;
  pointer-events: none;
}
.form-check-input.greyout ~ .checkmark:after {
  border: 1px solid rgb(181, 192, 202) !important;
  border-width: 0 2px 2px 0 !important;
}

.growthreasons-contentright .form-check-label.label-removed {
  color: #aa0025;
  font-family: Graphik-Medium;
  font-weight: 500;
}
.timelinevalues-formcol.timeseries-labelrow {
  width: 70%;
}
.timeseries-deleterow {
  width: 5%;
}
.timeseries-deleterow span {
  color: #aa0025;
}
.timelinevalues-input.timelinevalue-hm {
  display: flex;
}

.timelinevalues-input.timelinevalue-hm input {
  width: 50%;
}

.timelinevalues-input.timelinevalue-hm input:first-child {
  margin-right: 8px;
}

.timelinevalues-input .dropdown-titleValue .dropdown-toggle {
  color: #a7a7a7 !important;
}
/* Non Configurator Mode CSS Start */
.beforafterUI .mindmap-btnsection .btn-gradient-common.nonconfiguremode-btn {
  top: 225px;
}
.beforafterUI .nonconfiguremode-mindmaplinks .topleft-childlink.mindmap-namelinks {
  height: 8.5em;
}
.nonconfiguremode-mindmaplinks .top-childlink.mindmap-namelinks {
  height: 9.5em;
}
.beforafterUI .nonconfiguremode-mindmaplinks .topright-childlink.mindmap-namelinks {
  height: 8.5em;
}
.mindmap-links.nonconfiguremode-mindmaplinks .middleleft-child {
  top: 80px;
}
.mindmap-links.nonconfiguremode-mindmaplinks .middleright-child {
  top: 38px;
}
.beforafterUI .mindmap-links.nonconfiguremode-mindmaplinks .bottomleft-child {
  top: 168px;
}
.beforafterUI .nonconfiguremode-mindmaplinks .bottomleft-childlink.mindmap-namelinks {
  height: 9em;
  top: -122px;
}
.beforafterUI .nonconfiguremode-mindmaplinks.mindmap-links .bottom-child {
  top: 127px;
}
.beforafterUI .nonconfiguremode-mindmaplinks .bottom-childlink.mindmap-namelinks {
  height: 6em;
  top: -104px;
}
.beforafterUI .nonconfiguremode-mindmaplinks.mindmap-links .bottomright-child {
  top: 86px;
}
.beforafterUI .nonconfiguremode-mindmaplinks .bottomright-childlink.mindmap-namelinks {
  top: -123px;
  height: 9em;
}
/* Non Configurator Mode CSS End */
/* Submenu Tree Pagination Start */
.submenu-paginationlinks {
  display: flex;
  position: absolute;
  left: 33%;
}
.submenu-paginationlinks .submenu-treenav {
  font-size: 10px;
  color: rgb(181, 192, 202);
  line-height: 19px;
  cursor: pointer;
}
.submenu-paginationlinks .submenu-treenumber {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  margin: 0 10px;
}
.submenu-paginationlinks .submenu-treenav.active,
.submenu-paginationlinks .submenu-treenav:hover {
  color: #008eff;
}
.submenu-paginationlinks .submenu-treenav.disabled {
  pointer-events: none;
  color: rgb(99, 115, 129);
  opacity: 0.75;
}
/* Submenu Tree Pagination End */

.timelinevalues-formcol.timelinevalue-formcol-hm .timelinevalues-input {
  width: 28%;
}

.timelinevalues-formcol.timelinevalue-formcol-hm .timelinevalues-input.timelinevalue-hm {
  display: flex;

  width: 72%;

  margin: 0;
}

.timelinevalues-formcol.timelinevalue-formcol-hm
  .timelinevalues-input.timelinevalue-hm
  > div
  input {
  width: 100%;
}

.timelinevalues-formcol.timelinevalue-formcol-hm
  .timelinevalues-input.timelinevalue-hm
  div:first-child {
  margin-right: 8px;
}
/* Comments Sections dynamic scenarios css starts here  */
.mindmap-commentrysection {
  position: relative;
  padding-top: 20px;
  min-width: 300px;
}
.beforafterUI .dsp-dpo-comments .mindmap-links .topleft-child {
  top: -121px;
}
.beforafterUI .dsp-dpo-comments.single-box .mindmap-links .topleft-child {
  top: -73px;
}
.beforafterUI
  .dsp-dpo-comments
  .nonconfiguremode-mindmaplinks
  .topleft-child
  .topleft-childlink.mindmap-namelinks {
  height: 16.2em;
}
.beforafterUI
  .dsp-dpo-comments.single-box
  .nonconfiguremode-mindmaplinks
  .topleft-child
  .topleft-childlink.mindmap-namelinks {
  height: 13.2em;
}
.beforafterUI .dsp-dpo-comments .mindmap-links .top-child {
  top: -125px;
}
.beforafterUI .dsp-dpo-comments .nonconfiguremode-mindmaplinks .top-childlink.mindmap-namelinks {
  height: 16.7em;
}
.beforafterUI
  .dsp-dpo-comments.single-box
  .nonconfiguremode-mindmaplinks
  .top-childlink.mindmap-namelinks,
.beforafterUI
  .nps-csat-comments.single-box
  .nonconfiguremode-mindmaplinks
  .top-childlink.mindmap-namelinks {
  height: 14em;
}
.beforafterUI .dsp-dpo-comments .top-child .mapping-comments.no-commentsblock {
  top: -104px;
}
.beforafterUI.IOscore-timeline-margin .mindmap-parentsection.dsp-dpo-comments {
  margin-top: 230px;
}
.beforafterUI .ioscore-comments .top-child .mapping-comments.no-commentsblock {
  top: -206px;
}
.beforafterUI .ioscore-comments.single-box .top-child .mapping-comments.no-commentsblock {
  top: -158px;
}
.beforafterUI.IOscore-timeline-margin .mindmap-parentsection.ioscore-comments {
  margin-top: 216px;
}
/* .beforafterUI .nps-csat-comments .nonconfiguremode-mindmaplinks .topright-childlink.mindmap-namelinks {
  height: 16.3em;
}
.beforafterUI .nps-csat-comments.single-box .nonconfiguremode-mindmaplinks .topright-childlink.mindmap-namelinks {
  height: 13.3rem;
} */
.nps-csat-comments .mindmap-links .top-child {
  top: -130px;
}
.dsp-dpo-comments.single-box .mindmap-links .top-child,
.nps-csat-comments.single-box .mindmap-links .top-child {
  top: -82px;
}
.nps-csat-comments .nonconfiguremode-mindmaplinks .top-childlink.mindmap-namelinks {
  height: 17em;
}
/* .beforafterUI.IOscore-timeline-margin .mindmap-parentsection.nps-csat-comments {
  margin-top: 214px;
} */
.beforafterUI
  .working-capital-comments
  .mindmap-links.nonconfiguremode-mindmaplinks
  .bottomleft-child {
  top: 284px;
}
.beforafterUI
  .working-capital-comments.single-box
  .mindmap-links.nonconfiguremode-mindmaplinks
  .bottomleft-child {
  top: 236px;
}
.beforafterUI
  .working-capital-comments
  .nonconfiguremode-mindmaplinks
  .bottomleft-childlink.mindmap-namelinks {
  height: 16.3em;
  top: -238px;
}
.beforafterUI
  .working-capital-comments.single-box
  .nonconfiguremode-mindmaplinks
  .bottomleft-childlink.mindmap-namelinks {
  height: 13.3em;
  top: -190px;
}
.beforafterUI
  .human-machine-comments
  .mindmap-links.nonconfiguremode-mindmaplinks
  .bottomright-child {
  top: 204px;
}
.beforafterUI
  .human-machine-comments.single-box
  .mindmap-links.nonconfiguremode-mindmaplinks
  .bottomright-child {
  top: 156px;
}
.beforafterUI
  .human-machine-comments
  .nonconfiguremode-mindmaplinks
  .bottomright-childlink.mindmap-namelinks {
  height: 16.3em;
  top: -240px;
}
.beforafterUI
  .human-machine-comments.single-box
  .nonconfiguremode-mindmaplinks
  .bottomright-childlink.mindmap-namelinks {
  height: 13.3em;
  top: -192px;
}
.bottomleft-child .metric-scenario-commentsblock {
  position: absolute;
  right: 0;
}
.bottom-child .metric-scenario-commentsblock {
  position: absolute;
  left: 0;
}
.bottomright-child .metric-scenario-commentsblock {
  position: absolute;
  left: 0;
}
.comments-editor .mindmap-commentrysection .public-DraftStyleDefault-block {
  line-height: 10px;
}
.mindmap-commentrysection .textarea-submiticon {
  position: absolute;
  right: 3px;
  color: #018eff;
  top: 50%;
  left: auto;
  transform: translate(0%, -50%);
  cursor: pointer;
  z-index: 1;
}
.comments-wrapper .comments-footer .comments-editor {
  padding: 0 8px;
}
.mindmap-commentryform #custom-comments-undefined,
.mindmap-commentryform .comments-editor {
  border-radius: 8px;
  border: 1px solid rgb(1, 142, 255);
  margin-bottom: 0.3rem;
  box-shadow: none;
  background-color: rgb(255, 255, 255);
  padding: 0px 16px 0 8px;
  position: relative;
  width: 100%;
  display: block;
  height: 48px;
}
.mindmap-commentryform #custom-comments-undefined {
  padding: 0px 8px;
  font-size: 12px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: rgb(99, 115, 129);
}
.logged-user-redtext {
  color: rgb(168, 26, 95);
  font-weight: 600;
}
.other-user-bluetext {
  color: rgb(1, 142, 255);
  font-weight: 600;
}
.mindmap-commentrysection .view-all-comments {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  color: rgb(1, 142, 255);
  font-family: "graphik";
  font-size: 12px;
  font-weight: normal;
  bottom: -10px;
  left: 0;
  z-index: 1;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
.bottomleft-child .mindmap-commentrysection .view-all-comments,
.bottom-child .mindmap-commentrysection .view-all-comments,
.bottomright-child .mindmap-commentrysection .view-all-comments {
  bottom: -18px;
}
/* configure form section dynamic css starts here */

.beforafterUI .dsp-dpo-form .mindmap-links .topleft-child {
  top: -140px;
}

.beforafterUI .dsp-dpo-form .nonconfiguremode-mindmaplinks .topleft-childlink.mindmap-namelinks {
  height: 17.3em;
}

.beforafterUI .nps-csat-form .mindmap-links .topright-child {
  top: -180px;
}

/* .beforafterUI .nps-csat-form .nonconfiguremode-mindmaplinks .topright-childlink.mindmap-namelinks {
	height: 17.3em;
} */

.dsp-dpo-form .mindmap-links .top-child,
.nps-csat-form .mindmap-links .top-child {
  top: -150px;
}

.beforafterUI.IOscore-timeline-margin .mindmap-parentsection.dsp-dpo-form,
.beforafterUI.IOscore-timeline-margin .mindmap-parentsection.nps-csat-form {
  margin-top: 238px;
}

.ioscore-form .top-child .timelinevalues-form {
  top: -226px;
}

.dsp-dpo-form .nonconfiguremode-mindmaplinks .top-childlink.mindmap-namelinks,
.nps-csat-form .nonconfiguremode-mindmaplinks .top-childlink.mindmap-namelinks {
  height: 18.2em;
}

.beforafterUI .working-capital-form .mindmap-links.nonconfiguremode-mindmaplinks .bottomleft-child {
  top: 300px;
}
.beforafterUI
  .working-capital-form
  .nonconfiguremode-mindmaplinks
  .bottomleft-childlink.mindmap-namelinks {
  height: 17.4em;
  top: -254px;
}

.beforafterUI .human-machine-form .mindmap-links.nonconfiguremode-mindmaplinks .bottomright-child {
  top: 216px;
}

.beforafterUI
  .human-machine-form
  .nonconfiguremode-mindmaplinks
  .bottomright-childlink.mindmap-namelinks {
  height: 17em;
  top: -250px;
}

/* node alignment changes for IO score and nps starts here  */
.beforafterUI .mindmap-links .topleft-child.center-top {
  top: -114px;
  left: 280px;
}
.beforafterUI .dsp-dpo-form .mindmap-links .topleft-child.center-top {
  top: -254px;
}
.beforafterUI
  .dsp-dpo-form
  .nonconfiguremode-mindmaplinks
  .center-top
  .topleft-childlink.mindmap-namelinks {
  height: 27em;
}
.beforafterUI .ioscore-form .mindmap-links .topleft-child.center-top {
  top: -260px;
}
.beforafterUI
  .ioscore-form
  .nonconfiguremode-mindmaplinks
  .center-top
  .topleft-childlink.mindmap-namelinks {
  height: 27.3em;
}
.beforafterUI .nonconfiguremode-mindmaplinks .center-top .topleft-childlink.mindmap-namelinks {
  height: 18.3em;
}
.beforafterUI .ioscore-comments .mindmap-links .topleft-child.center-top,
.beforafterUI .dsp-dpo-comments .mindmap-links .topleft-child.center-top {
  top: -238px;
}
.beforafterUI .ioscore-comments.single-box .mindmap-links .topleft-child.center-top,
.beforafterUI .dsp-dpo-comments.single-box .mindmap-links .topleft-child.center-top {
  top: -180px;
}
.beforafterUI
  .ioscore-comments
  .nonconfiguremode-mindmaplinks
  .center-top
  .topleft-childlink.mindmap-namelinks,
.beforafterUI
  .dsp-dpo-comments
  .nonconfiguremode-mindmaplinks
  .center-top
  .topleft-childlink.mindmap-namelinks {
  height: 26em;
}
.beforafterUI
  .ioscore-comments.single-box
  .nonconfiguremode-mindmaplinks
  .center-top
  .topleft-childlink.mindmap-namelinks,
.beforafterUI
  .dsp-dpo-comments.single-box
  .nonconfiguremode-mindmaplinks
  .center-top
  .topleft-childlink.mindmap-namelinks {
  height: 22.4em;
}
.beforafterUI .mindmap-links .topright-child ul.submenu-treelinks {
  right: -110px;
  top: 60px;
}
.beforafterUI .mindmap-links .topright-child ul.submenu-treelinks:before {
  height: calc(100% - 10px);
  top: -20px;
}
.beforafterUI .mindmap-links .topright-child ul.submenu-treelinks li:first-child:after {
  width: 2rem;
  left: -40px;
  top: 18px;
}
.beforafterUI .mindmap-links .topright-child .timelinevalues-form {
  top: 100px;
}
.beforafterUI .mindmap-links .topright-child .metric-scenario-commentsblock {
  position: absolute;
}
.beforafterUI .mindmap-parentsection {
  margin-top: 120px;
}
.beforafterUI .topright-child .mindmap-commentrysection .view-all-comments {
  bottom: -18px;
}
.beforafterUI .three-metrics .mindmap-links .topright-child {
  top: -90px;
}
.beforafterUI .four-metrics .mindmap-links .topright-child {
  top: -140px;
}
.beforafterUI .five-metrics .mindmap-links .topright-child {
  top: -205px;
}
.boi-comments.five-metrics .mindmap-commentrysection {
  padding-top: 30px;
}

.beforafterUI.IOscore-timeline-margin .mindmap-parentsection.boi-comments.five-metrics,
.beforafterUI .mindmap-parentsection.boi-comments.five-metrics {
  margin-top: 228px;
}
.beforafterUI.IOscore-timeline-margin .mindmap-parentsection.boi-comments.four-metrics,
.beforafterUI .mindmap-parentsection.boi-comments.four-metrics {
  margin-top: 180px;
}
.beforafterUI.IOscore-timeline-margin .mindmap-parentsection.boi-comments.three-metrics {
  margin-top: 132px;
}
.beforafterUI .boi-metric-time-series .mindmap-links .topright-child {
  top: -130px;
}
.beforafterUI .boi-metric-time-series .topright-childlink.mindmap-namelinks {
  height: 14.2em;
}
.beforafterUI .boi-comments.boi-metric-time-series .mindmap-links .topright-child {
  top: -230px;
}
.beforafterUI .three-metrics .topright-childlink.mindmap-namelinks {
  height: 11.7em;
}
.beforafterUI .four-metrics .topright-childlink.mindmap-namelinks {
  height: 14.8em;
}
.beforafterUI .five-metrics .topright-childlink.mindmap-namelinks {
  height: 18.8em;
}
.beforafterUI .mindmap-links .mindmap-linkname.topright-child ul {
  z-index: 2;
}
.beforafterUI .boi-comments.boi-metric-time-series .topright-childlink.mindmap-namelinks {
  height: 20.5em;
}
.beforafterUI.IOscore-timeline-margin .mindmap-parentsection.boi-comments.boi-metric-time-series {
  margin-top: 130px;
}
.beforafterUI .single-box.boi-comments.one-metrics .mindmap-links .topright-child {
  top: -60px;
}
.beforafterUI .single-box.boi-comments.one-metrics .topright-childlink.mindmap-namelinks {
  height: 9.8em;
}
.beforafterUI .boi-comments.one-metrics .mindmap-links .topright-child {
  top: -118px;
}
.beforafterUI .boi-comments.one-metrics .topright-childlink.mindmap-namelinks {
  height: 13.4em;
}
.beforafterUI .single-box.boi-comments.two-metrics .mindmap-links .topright-child {
  top: -110px;
}
.beforafterUI .single-box.boi-comments.two-metrics .topright-childlink.mindmap-namelinks {
  height: 13em;
}
.beforafterUI .boi-comments.two-metrics .mindmap-links .topright-child {
  top: -168px;
}
.beforafterUI .boi-comments.two-metrics .topright-childlink.mindmap-namelinks {
  height: 16.5em;
}
.beforafterUI .single-box.boi-comments.three-metrics .mindmap-links .topright-child {
  top: -160px;
}
.beforafterUI .single-box.boi-comments.three-metrics .topright-childlink.mindmap-namelinks {
  height: 16em;
}
.beforafterUI .boi-comments.three-metrics .mindmap-links .topright-child,
.beforafterUI .single-box.boi-comments.four-metrics .mindmap-links .topright-child {
  top: -218px;
}
.beforafterUI .boi-comments.three-metrics .topright-childlink.mindmap-namelinks,
.beforafterUI .single-box.boi-comments.four-metrics .topright-childlink.mindmap-namelinks {
  height: 19.7em;
}
.beforafterUI .boi-comments.four-metrics .mindmap-links .topright-child,
.beforafterUI .boi-comments.five-metrics .mindmap-links .topright-child {
  top: -268px;
}
.beforafterUI .boi-comments.four-metrics .topright-childlink.mindmap-namelinks,
.beforafterUI .boi-comments.five-metrics .topright-childlink.mindmap-namelinks {
  height: 22.8em;
}
.beforafterUI .boi-comments.five-metrics .mindmap-links .topright-child {
  top: -328px;
}
.beforafterUI .boi-comments.five-metrics .topright-childlink.mindmap-namelinks {
  height: 26.5em;
}
.beforafterUI .boi-form .mindmap-links .topright-child {
  top: -250px;
}
.beforafterUI .boi-form .topright-childlink.mindmap-namelinks {
  height: 21.7em;
}
.beforafterUI.IOscore-timeline-margin .boi-form.mindmap-parentsection {
  margin-top: 150px;
}
.DraftEditor-root {
  line-height: 14px;
}
.beforafterUI .mindmap-commentryform {
  min-width: 330px;
}
.public-DraftEditorPlaceholder-root {
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
}
.public-DraftEditor-content[contenteditable="true"]:first-child {
  min-height: 48px;
  display: flex;
  align-items: center;
  text-indent: 2px;
  line-height: 17px;
  font-size: 0.875rem;
}
.comments-wrapper .reply-back .message-text.to-reply-back .message-reply-content > span {
  display: inline-block;
  text-align: left;
}
.nps-csat-form .bottomleft-child .timelinevalues-form {
  top: 40px;
}
.quality-form .bottom-child .timelinevalues-form {
  top: 172px;
}
.beforafterUI .bottom-child .mindmap-commentryform,
.bottom-child .mindmap-commentrysection .view-all-comments {
  right: 30px;
}
.boi-metric-time-series .mapping-commentstimeline {
  z-index: 1;
}
.beforafterUI .newcomment-notification {
  z-index: 1;
}
/* node alignment changes for IO score and nps ends here  */
/* configure form section dynamic css starts here */
/* Comments Sections dynamic scenarios css ends here  */
/* no timeline series scenarios css starts here */
.beforafterUI
  .ioscore-comments.single-box.no-timeline-series
  .mindmap-links
  .topleft-child.center-top {
  top: -114px;
}
.beforafterUI .ioscore-comments.no-timeline-series .mindmap-links .topleft-child.center-top {
  top: -134px;
}
.no-timeline-series.single-box .nonconfiguremode-mindmaplinks .mapping-comments {
  top: 48px;
}
.no-timeline-series .nonconfiguremode-mindmaplinks .mapping-comments {
  top: 32px;
}
.beforafterUI
  .no-timeline-series.single-box
  .nonconfiguremode-mindmaplinks
  .center-top
  .topleft-childlink.mindmap-namelinks {
  height: 18.3em;
}
.beforafterUI
  .no-timeline-series.ioscore-comments
  .nonconfiguremode-mindmaplinks
  .center-top
  .topleft-childlink.mindmap-namelinks {
  height: 19.5em;
}
.beforafterUI .boi-comments.no-timeline-series .mindmap-links .topright-child {
  top: -74px;
}
.beforafterUI
  .boi-comments.no-timeline-series
  .nonconfiguremode-mindmaplinks
  .topright-childlink.mindmap-namelinks {
  height: 10.7em;
}
.beforafterUI .dsp-dpo-comments.single-box.no-timeline-series .mindmap-links .topleft-child {
  top: 10px;
}
.beforafterUI
  .dsp-dpo-comments.single-box.no-timeline-series
  .nonconfiguremode-mindmaplinks
  .topleft-child
  .topleft-childlink.mindmap-namelinks {
  height: 8em;
}
.beforafterUI
  .dsp-dpo-comments.single-box.no-timeline-series
  .mindmap-links
  .topleft-child.center-top {
  top: -100px;
}
.beforafterUI
  .dsp-dpo-comments.single-box.no-timeline-series
  .nonconfiguremode-mindmaplinks
  .topleft-child.center-top
  .topleft-childlink.mindmap-namelinks {
  height: 17.4em;
}
.beforafterUI .dsp-dpo-comments.no-timeline-series .mindmap-links .topleft-child {
  top: -28px;
}
.beforafterUI .dsp-dpo-comments.no-timeline-series .mindmap-links .topleft-child.center-top {
  top: -100px;
}
.beforafterUI
  .dsp-dpo-comments.no-timeline-series
  .nonconfiguremode-mindmaplinks
  .topleft-child
  .topleft-childlink.mindmap-namelinks {
  height: 10.3em;
}
.beforafterUI
  .dsp-dpo-comments.no-timeline-series
  .nonconfiguremode-mindmaplinks
  .topleft-child.center-top
  .topleft-childlink.mindmap-namelinks {
  height: 17.3em;
}
.beforafterUI
  .no-timeline-series.ioscore-comments.single-box
  .nonconfiguremode-mindmaplinks
  .center-top
  .topleft-childlink.mindmap-namelinks {
  height: 18.3em;
}
.beforafterUI .boi-comments.no-timeline-series.single-box .mindmap-links .topright-child {
  top: -32px;
}
.beforafterUI
  .boi-comments.no-timeline-series.single-box
  .nonconfiguremode-mindmaplinks
  .topright-childlink.mindmap-namelinks {
  height: 8em;
}
.beforafterUI .mindmap-parentsection.boi-form {
  margin-top: 154px;
}
.beforafterUI .mindmap-parentsection.dsp-dpo-form {
  margin-top: 232px;
}

/* no timeline series scenarios css ends here */
@media screen and (min-width: 960px) and (max-width: 1366px) {
  .timelinevalues-form {
    width: 365px;
  }
}
.zoom-controls {
  position: absolute;
  display: inline-flex;
  right: 10px;
  top: 6px;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 8%);
  border-radius: 4px;
}

.zoom-controls span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  border-right: 1px solid #eee;
}

.zoom-controls span:hover {
  background: #f4f4f4;
}

.zoom-controls span i {
  color: #707072;
}
.mindmap-linkname .__react_component_tooltip {
  max-width: 200px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.beforeafter-detail > button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.beforafterUI .middleleft-child .addmindmap-links {
  right: auto;
}
.beforafterUI .mindmap-parentsection.ioscore-comments.two-metrics,
.beforafterUI .mindmap-parentsection.ioscore-comments.three-metrics,
.beforafterUI .mindmap-parentsection.ioscore-comments.four-metrics,
.beforafterUI .mindmap-parentsection.ioscore-comments.five-metrics {
  margin-top: 218px;
}
.beforafterUI .mindmap-parentsection.dsp-dpo-comments {
  margin-top: 220px;
}
.beforafterUI .boi-comments .topright-childlink.mindmap-namelinks {
  height: 10.5em;
}

.beforafterUI .boi-comments .mindmap-links .topright-child {
  top: -73px;
}

.beforafterUI .mindmap-parentsection.ioscore-comments {
  margin-top: 220px;
}

.beforafterUI .mindmap-parentsection.ioscore-comments.single-box {
  margin-top: 220px;
}
