/* One Time Setup CSS Starts here */
.oneTime-section-right {
  display: flex;
  align-items: center;
}
.setup-download {
  display: flex;
  justify-content: flex-end;
}


.tableFilter .setup-Btn {
  border: none;
  background: #fff;
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  text-align: center;
  align-items: center;
  padding: 0 10px;
  outline: none;
}
.tableFilter .setup-Btn i {
  margin-right: 10px;
}
.mapping-dropdown-btn .dropdown-toggle {
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
  padding: 8px 13px;
}
.mapping-dropdown-btn .dropdown-toggle:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
.mapping-dropdown-btn .dropdown-item {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 12px 11px;
}
.mapping-dropdown-btn .dropdown-item i {
  margin-right: 10px;
}
.mapping-dropdown-btn .dropdown-item.active,
.mapping-dropdown-btn .dropdown-item:active,
.mapping-dropdown-btn .dropdown-item:hover {
  color: rgb(99, 115, 129);
  background-color: #dfe3e8;
}
.addMappingModal .modal-dialog {
  max-width: 1000px;
}
.addMappingModal .toggleForm .nav-tabs a:nth-last-child(2)::after {
  width: 227%;
}
.synopsDetails-tabcontent {
  display: flex;
  padding: 0 44px 0 24px;
}
/* Tab steps custom css starts here.  */
.addMappingModal .toggleForm .nav-tabs a::before {
  border-color: rgb(162, 185, 196);
  color: #fff;
  background: rgb(162, 185, 196);
  height: 27px;
  width: 27px;
  box-shadow: 0px 0px 0px 8px #d4e3e8;
  padding: 0;
  margin-bottom:10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addMappingModal .toggleForm .nav-tabs a.active::before,
.addMappingModal .toggleForm .previous-active.nav-tabs a.active::before {
  border-color: rgb(66, 180, 238);
  color: #fff;
  background: rgb(66, 180, 238);
  height: 27px;
  width: 27px;
  box-shadow: 0px 0px 0px 8px rgb(212, 235, 247);
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addMappingModal .toggleForm .nav-tabs a::after {
  height: 20px;
  width: 210%;
  top: 12px;
  background: rgb(219, 230, 233);
}
.addMappingModal .tabs-small .nav-tabs .nav-link {
  color: rgb(107, 137, 151);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  margin: 0 80px 12px;
  cursor: default;
}
.addMappingModal .tabs-small .nav-tabs .nav-link.active,
.addMappingModal .tabs-small .previous-active.nav-tabs .nav-link.active {
  color: rgb(66, 180, 238);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}
.addMappingModal .tabs-small .previous-active.nav-tabs .nav-link {
  color: rgb(126, 195, 68);
}
.addMappingModal .toggleForm .previous-active.nav-tabs a::before {
  background: rgb(126, 195, 68);
  border-color: rgb(162, 185, 196);
  box-shadow: 0px 0px 0px 8px rgb(229, 243, 218);
  margin-bottom: 10px;
}
.addMappingModal .toggleForm .previous-active.nav-tabs a::after {
  background-image: -webkit-linear-gradient(to right, rgb(229, 243, 218) 50%, rgb(221, 238, 243) 50%);
  background-image: linear-gradient(to right, rgb(229, 243, 218) 50%, rgb(221, 238, 243) 50%);
  background-image: -ms-linear-gradient(to right, rgb(229, 243, 218) 50%, rgb(221, 238, 243) 50%);
}
/* Tab steps custom css ends here. */
.addMappingModal .modal-footer .btn {
  width: 120px;
  height: 48px;
  font-size: 16px;
}
.addMappingModal .modal-footer .modalFooter-btn {
  display: flex;
  justify-content: space-between;
}
.synopsfields-section .detail-group .form-control {
  height: 18px;
  font-size: 16px;
}
.detail-group .form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: transparent;
}
.form-group.detail-group {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0 10px 10px;
  margin-bottom: 36px !important;
}
.detail-group label.form-label {
  font-size: 11px;
  margin-bottom: 0px;
  letter-spacing: 0.05px;
}
.detail-group .form-control {
  padding: 0;
  border: 0 none;
  height: auto;
}
.detail-group select.form-control {
  margin-left: -4px;
  width: calc(100% + 4px);
}
.detail-group.disable {
  background: rgba(230, 230, 230, 0.3);
}
.detail-group .form-control:disabled {
  background: transparent;
  color: rgba(30, 30, 30, 0.5);
}
.toggleForm .tab-content {
  padding: 15px 0 0;
  background: transparent;
  border-radius: 0;
  margin-top: 0;
}
.toggleForm .nav-tabs {
  counter-reset: tabCount;
}
.toggleForm .nav-tabs a::before {
  counter-increment: tabCount;
  content: counter(tabCount);
  background: #fff;
  color: #999999;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  margin-bottom: 5px;
  border: 1px solid #bbb;
  position: relative;
  z-index: 10;
  padding-top: 1px;
}
.toggleForm .nav-tabs a.active::before {
  background: #018fff;
  border-color: #018fff;
  color: #fff;
}
.toggleForm .nav-tabs a::after {
  content: "";
  background-color: #ccc;
  width: 150%;
  height: 1.5px;
  position: absolute;
  left: 50%;
  top: 18px;
}
.tabs-small .nav-tabs .nav-link:last-child:after {
  display: none;
}
.toggleForm .nav-tabs a:nth-last-child(2)::after {
  width: 115%;
}
.tabs-small .nav-tabs {
  border-bottom: 0;
  position: relative;
  display: flex;
  justify-content: center;
}
.tabs-small .nav-tabs .nav-link {
  color: #333333;
  display: flex;
  align-items: center;
  flex-flow: column;
  font-family: graphik;
  margin: 0 30px 12px;
  position: relative;
  border: 0;
}
.tabs-small .nav-tabs .nav-item.show .nav-link,
.tabs-small .nav-tabs .nav-link.active {
  color: #018eff !important;
  background-color: transparent !important;
  border: 0 none;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: 0 none;
}

.synopsfields-section {
  width: 100%;
}
.modalFooter-btn {
  display: flex;
  justify-content: flex-end;
}
.tab-desc h5 {
  color: rgb(53, 64, 83);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 20px;
}
.tab-desc p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.19px;
  margin-bottom: 36px;
}
.form-group.last-dropdown {
  margin-bottom: 20px !important;
}
.disabled {
  background: rgb(249, 250, 251);
  pointer-events: none;
}
.modalFooter-btn .mr-2 {
  margin-right: 1rem !important;
}
.source-system-details {
  display: block;
  padding: 0 24px;
}
.source-system-details .synopsDetail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.source-system-details .synopsDetail-header h5 {
  color: rgb(53, 64, 83);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 20px;
}
.source-system-details .synopsDetail-header p {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.19px;
  margin-bottom: 0;
}
.source-system-details .synopsDetail-header h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.19px;
  margin-bottom: 0;
}
/* accordion css starts here */
.sourceSystemDetails-accordion {
  position: relative;
  padding-top: 8px;
  margin-left: -12px;
  max-height: 380px;
  overflow-y: auto;
}
.sourceSystemDetails-accordion .mainAccordion,
.subAccordion {
  border: none !important;
  max-height: none !important;
}
.sourceSystemDetails-accordion .mainAccordion .card {
  background: rgb(255, 255, 255);
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  margin: 10px 16px;
}
.subAccordion .card {
  background: rgb(255, 255, 255) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  box-shadow: none !important;
}

.subAccordion .card:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.subAccordion .card:last-child {
  margin-bottom: 24px !important;
}
.sourceSystemDetails-accordion .mainAccordion .card .card-header {
  border: none;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 16px 47px;
}
.subAccordion .card .card-header {
  border: none;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 20px 9px 25px !important;
  width: 100%;
}
.collapseType2 .subAccordion .collapsed {
  width: 60%;
}
.collapseType2 .subAccordion .header-info-right {
  width: 40%;
  justify-content: space-between;
  position: relative;
}

.sourceSystemDetails-accordion .mainAccordion .card .card-body,
.subAccordion .card .card-body {
  padding: 0 20px 0px 25px;
  position: relative;
}
.source-system-details .header-content {
  display: flex;
  align-items: center;
}
.source-system-details .header-content h6 {
  margin: 0;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  display: flex;
  cursor: pointer;
}
.source-system-details .header-right-info {
  display: flex;
  align-items: center;
}
.sourceSystemDetails-accordion .sourceSystemGroup {
  padding-bottom: 16px;
  padding-right: 4px;
}
.sourceSystemDetails-accordion .form-group.detail-group {
  margin-bottom: 0 !important;
}
.source-system-details .collapsed {
  pointer-events: none;
}
.source-system-details .card-header .card-link {
  position: relative;
}
.source-system-details .card-link {
  pointer-events: fill;
}

.source-system-details .card-link::before,
.source-system-details .card-link.collapsed::before {
  content: "\F0DA";
  position: absolute;
  top: 45%;
  font-family: "Font Awesome\ 5 Pro";
  font-weight: 600;
  color: rgb(1, 142, 255);
  line-height: 2px;
  left: -22px;
  transform: rotate(0deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
  cursor: pointer;
  display: block;
}
.source-system-details .card-link::before {
  transform: rotate(90deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}
.source-system-details .header-info-right {
  display: flex;
  align-items: center;
}
.sourceSystemDetails-accordion .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.source-system-details .header-info-right .mapping-status {
  border-radius: 16px;
  height: 26px;
  min-width: 90px;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.status-inprogress,
.status-awaitingApproval {
  color: rgb(230, 176, 25);
  background: rgb(255, 244, 213);
}
.status-rejected {
  color: rgb(170, 0, 37);
  background: rgb(254, 230, 231);
}
.status-completed,
.status-approved {
  background: rgb(229, 243, 218);
  color: rgb(82, 134, 38);
}
.synopsDetails-tabcontent .form-group.disabled,
.synopsDetails-tabcontent .form-group.disabled .form-control {
  background-color: #dfe3e8;
  pointer-events: none;
}
.source-system-details .form-group.disabled .form-control {
  color: rgb(33, 43, 54);
}
.sourceSystemDetails-accordion .mainAccordion {
  overflow-y: auto;
}
.subAccordion {
  max-height: 365px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* ONoff switch css */
.header-info-right .onoffswitch {
  width: 36px;
  height: inherit;
}
.header-info-right .onoffswitch .onoffswitch-inner:before,
.header-info-right .onoffswitch .onoffswitch-inner:after {
  height: 23px;
}
.header-info-right .onoffswitch .onoffswitch-inner:before {
  background: #018eff;
}

.header-info-right .onoffswitch .onoffswitch-switch {
  height: 20px;
  top: 3px;
  width: 20px;
  margin: 0;
  background: hsl(0, 0%, 100%);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 15px;
  -webkit-transition: right 0.15s ease-in-out;
  transition: right 0.15s ease-in-out;
}
.header-info-right
  .toggle-group
  input[type="checkbox"]:checked
  ~ .onoffswitch
  .onoffswitch-label
  .onoffswitch-switch:before {
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: 0;
  top: -7px;
  font-size: 23px;
  color: #018eff;
  display: block;
}
.header-info-right .toggle-group {
  display: flex;
  align-items: center;
  height: 23px;
  /* margin-left: 10px; */
}
.header-info-right .toggle-group input[type="checkbox"] {
  top: 10px;
}

.save-btnlink {
  background: none;
  border: none;
  outline: none !important;
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
}
.save-btnlink.active,
.btnCancel.active,
.btnUpdate.active {
  color: rgb(1, 142, 255);
}

.notifySection {
  display: flex;
  padding: 0 16px;
}
.notifyIcon i {
  font-size: 48px;
  color: #7ec344;
}
.notifyIcon {
  padding-right: 25px;
}
.notifyDesc h5 {
  color: rgb(33, 43, 54);
  font-size: 18px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
}
.notifyDesc p {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 28px;
  margin-bottom: 0;
}
.notifyDesc .toolsection {
  padding: 16px 0px 10px 0px;
}
.submitNotifyModal .modal-content {
  width: 642px;
}
.submitNotifyModal .modal-footer {
  display: flex;
}
.submitNotifyModal .modal-footer button {
  background: rgb(126, 195, 68);
  border-radius: 24px;
  height: 48px;
  width: 134px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
}
.draftModal .modal-header {
  padding: 58px 28px 8px;
}
.draftModal .modal-body {
  padding: 16px 28px;
}
.draftHeader h6 {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
}
.draftHeader ul {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  display: flex;
  align-items: center;
  margin: 0;
}

.draftBody {
  border: none;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 25px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  margin-bottom: 24px;
}
.draftBody .draftHeader ul li {
  padding: 0px 20px;
  position: relative;
}
.draftBody .draftHeader ul li:first-child {
  padding-left: 0;
}
.draftBody .draftHeader ul li::after {
  content: "";
  position: absolute;
  height: 16px;
  width: 2px;
  background-color: #ddd;
  display: inline-block;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.draftBody .draftHeader ul li:last-child::after {
  display: none;
}
.draftBody .header-info-right {
  justify-content: space-between;
}
.draftModal .modal-footer {
  display: flex;
}
.edit-delete-icons span {
  margin-left: 1rem;
}
.draftModal .modal-dialog {
  max-width: 1000px;
}
.draftModal .draftHeader{
  width: 90%;
}

.subAccordion .selecttooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.subAccordion .selecttooltip .selecttooltiptext {
  visibility: hidden;
  min-width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  /* padding: 5px 0; */
  font-size: 12px;
  letter-spacing: -0.19px;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  position: absolute;
  z-index: 1;
  top: -4px;
  right: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  line-height: 14px;
}

.subAccordion .selecttooltip .selecttooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -6px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
  transform: rotate(-90deg);
}

.subAccordion .selecttooltip:hover .selecttooltiptext {
  visibility: visible;
  opacity: 1;
}
.tbleview {
  color: #018eff;
}
.toolCount {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: right;
  letter-spacing: -0.22px;
  margin-bottom: 0;
}
.header-link .toolDesc {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  margin-bottom: 0%;
  display: flex;
  pointer-events: none;
}
.noTool-data {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.29px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 48px 0;
}
.collapseType1 .subAccordion .header-info-right .mapping-status {
  margin-right: 187px;
  /* margin-right: 150px; */
}
/* .collapseType2 .subAccordion .header-info-right .mapping-status {
  margin-right: 132px;
} */
.mainAccordion .subAccordion .card {
  margin: 0px 0px 0px 25px;
}
.approverRemark h5 {
  color: rgb(33, 43, 54);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 14px;
}
.approverRemark p {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 16px;
}
.mapping-dropdown-btn .show > .btn-primary.dropdown-toggle,
.mapping-dropdown-btn .btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.mapping-dropdown-btn .dropdown-menu {
  min-width: 154px;
}
.btnUpdate,
.btnCancel {
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
  padding: 10px;
  border: none;
  background: none;
  outline: none !important;
}
.btnUpdate {
  margin-left: 8px;
}
.modalFooter-btn .btn-primary:disabled {
  color: #fff;
  background-color: rgb(99, 115, 129);
  border-color: rgb(99, 115, 129);
}
.toggle-btn-group .save-btnlink {
  width: 46px;
}
/* One Time Setup CSS Ends here */
.source-system-details .tab-desc p{
  margin-bottom: 5px;
}
.source-system-details .card-body .header-info-right {
  position: relative;
  width:41%;
  display: flex;
  /* align-items: center; */
}
.toggle-group-container {
    position: relative;
    width: 100%;
    text-align: right;
}
.source-system-details .form-group.disabled .form-control, .source-system-details .form-group .form-control {
  height: 18px;
}