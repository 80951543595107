.help-info-dropdown{
    padding-right: 28px;
    position: relative;
  
    
}

.navbar-nav.right-navbar{
    align-items: center;
}
.help-info-icon span{
    border: 1.5px solid rgba(255, 255, 255, 0.7);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
}
.help-info-icon span i{
    font-size: 12px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
}
.infomore-dropdown span i{
    color: #637381;
}
.infomore-dropdown.show span i{
    color: #018eff;
}
.help-info-dropdown.show .help-info-icon span{
    border: 1.5px solid rgb(255,255,255);
}
.help-info-dropdown.show .help-info-icon span i{
    color: #ffffff;
}
.help-info-dropdown .dropdown-menu{
    right: 0 !important;
    left: auto !important;
    width: 125px;
    min-width: 125px;
    padding: 8px 0;
    margin-top: 1px;
}
.infomore-dropdown .dropdown-menu{
    right: 0 !important;
    left: auto !important;
    width: 125px;
    min-width: 125px;
    padding: 8px 0;
    background: rgb(255, 255, 255);
border-radius: 8px;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.help-info-dropdown .dropdown-item,
.infomore-dropdown .dropdown-item{
    color: #637381 !important;
font-family: graphik;
font-size: 14px;
font-weight: normal;
cursor: pointer;
}
.help-info-dropdown .dropdown-item i,
.infomore-dropdown .dropdown-item i{
    margin-right: 5px;
}
.help-info-dropdown .dropdown-item:hover,
.infomore-dropdown .dropdown-item:hover{
    color: #637381;
    background-color: #f4f6f8;
}
.support-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.support-content .support-data{
    width: 50%;
}
.support-content .support-data p{
    color: rgb(99, 115, 129);
    font-family: graphik;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.35px;
    line-height: 22px;
    margin-bottom: 30px;
}
.support-content .support-data p a{
    color: #00baff;
}
.support-content .support-image{
    width: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.support-pop .modal-dialog{
    max-width: 800px;
}
.helptopics-section{
    margin-top: 0;
    padding: 2rem 3em;
}
.helptopic-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.helptopic-heading h5{
color: rgb(33, 43, 54);
font-family: Graphik-Medium;
font-size: 20px;
font-weight: 500;
letter-spacing: -0.29px;
}
.helptopic-close .close-icon{
    color: #637381;
    font-size: 21px;
}
.search-functionality {
    display: flex;
    margin: 30px 0;
    align-items: center;
}
.search-functionality .searchFilter{
    margin-right: 15px;
}
.search-functionality .searchFilter input[type="search"]{
    width: 240px !important;
}
.addfunc-btn .btn.btn-doublegradient{
    background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(0, 186, 255) 100%);
    border-radius: 24px;
    height: 40px;
    width: 163px;
    color: #ffffff;
    font-family: Graphik-Medium;
font-size: 14px;
font-weight: 500;
letter-spacing: -0.25px;
line-height: 14px;
}
.btn-doublegradient:hover{
    color: #ffffff;
    border: none;
    outline: none;
}
.helptopic-list{
    display: flex;
    flex-wrap: wrap;
}
.helptopic-listitem{
    width: 31.4%;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
    padding: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    position: relative;
    height: 220px;
}
.helptopic-listitem:nth-child(3n){
    margin-right: 0;
}
.helptopic-listitem .listitem-heading{
    display: flex;
    justify-content: space-between;
}
.helptopic-listitem .listitem-heading h6{
    font-family: Graphik-Semibold;
font-size: 16px;
font-weight: 600;
color: #212b36;
letter-spacing: -0.33px;
margin-bottom: 15px;
}
.helptopic-listitem .listitem-content p{
    color: #637381;
    font-family: graphik;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: -0.25px;
}
.helptopic-listitem .listitem-footer{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 28px;
}
.helptopic-listitem .listitem-footer span{
    color: rgb(99, 115, 129);
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    margin-right: 15px;
}
.helptopic-listitem .listitem-footer span i{
    margin-right: 5px;
}
.helptopic-listitem .listitem-footer span.active{
    color: #018eff;
}
/* .helptopic-listitem .listitem-footer span:hover{
    color: #018eff;
} */
.flex-btns .btn-default{
    color: #637381;
}
.help-info-dropdown .help-info-icon.active-icon span {
    border: none;
    background-color: #018eff;
}
.help-info-dropdown .help-info-icon.active-icon span i{
    color: #fff;
}
.searchFilter input[type="search"]{
    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAIAAABL1vtsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAJUSURBVDhPnZPfb9JQFMcPraysrjAmjIQSNohOQF4gBk1EnPFhTxr9J01MTGaiMVFjRDZHgmT4QJSBQpgDAbtWVgYrpcFbejFQVwx+Hnrv+d7e7/11jknqy6Bj0BX5RlWQACgn61y+yFAmPHIuUxaK8CW5Vy4J06Ym0s5evhcPOi5gQccfC7n2KfU6L6Kl0RyKWXJZFwD63eMO11PUcRMduBPf9NBqfxrNQml+fLN9cAZAOq5e24r4mckFO9XdnXz+WAaT2Xfr7ta63kW1kMqpx3u8ZPDHCLmWefu8eAYLK5v3E4FFrGoQAPV0jkf7d0WM5iPMbCwRd5HQ59PZMtbGEFCtlHoAi+5YyGi+Bh2+4bEDSIdHhSGWNAiuJaLrYrw+FivGWP2+ZYCh2GhgQYPgTtAtgtXu1OKZ2OxL6CuLp1qIQXcxBzRlxr0JCIZW1e5pW4tnoggdNYnI6XUJ1smgRmjW1aSazfBrpYUahvVoMYYAr9OFSqBVTv8YZaExXLZSQ2+x6g6gvJ2AgIXg7aAFXVJh50MBva4B3aPMK5RaYAlHNyisYdRjOSKRMDpNn0++eLevqzEVmTtIPX1fF4dAr29EL5FYHjMus0F992Umf6J2abvTv7bCrrrpXv2n8KvwrcmNdkdRpCQpwHgePbiunn3MRLEPxUo2kyyK0nTyqVBM+GYsKueeoFJC4bTLhIXGoH1YrZa/i10ttDB+79oVt220e0X4nHq239a5/GXxD85xmdcCoXeZL8FHkPZQ4mHUpj5thyvz/7MLDUUo5kp0KOahfwPCFwI2lQhyzAAAAABJRU5ErkJggg==) no-repeat scroll 20px !important;
    padding: 5px 30px 5px 40px !important;
    background-size: 14px !important;
    font-size: 14px !important;;
    height: 40px;
    line-height: 13px;
}
.helptopic-close .close-icon::after{
    display: none;
}