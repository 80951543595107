.log-out-poup >.modal-body {
    padding: 1em 2em 2em;
}
.log-out-poup >.modal-content{
    width: 503px;
    height: 226px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(223, 227, 232);
    box-shadow: 0px 2px 64px 0px rgb(0 0 0 / 15%);
    border-radius: 16px;
    margin-top: 166px;
}

.session-message h6 {
  width: 160px;
  height: 20px;
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
  margin-top: 14px;
}
.session-message h4 {
  width: 434px;
  height: 48px;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 24px;
  margin-top: 22px;
  padding-right: 3rem;
}
.modal-btn>.log-out {
    color: rgb(0, 142, 255);
    font-size: 12px;
    font-family: Graphik-Medium;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.25px;
    line-height: 12px;
    width: 96px;
    height: 32px;
    background: rgb(255, 255, 255);
    border: 1.5px solid rgb(1, 142, 255);
    border-radius: 16px;
}
.modal-btn>.continue {
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-family: Graphik-Medium;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.25px;
    line-height: 12px;
    width: 96px;
    height: 32px;
    background: rgb(1, 142, 255);
    border-radius: 16px;
    border: none;
}
