.querybuilder-content .metricBtn{
    position: absolute;
    right: 0;
    top: -5px;
}
.querybuilder-content .metricBtn button{
    width: 115px;
    height: 40px;
    color: rgb(1, 142, 255);
font-family: Graphik-Medium;
font-size: 14px;
font-weight: 500;
letter-spacing: -0.25px;
}
.querybuilder-content .metricBtn button:hover{
    color: #ffffff;
}
.querybuilder-content .business-filter{
background: transparent;
box-shadow: none;
border-bottom: none;
}
.querybuilder-content .query-builder-sec .form-group.border-group select{
    height: 30px;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    border: 1px solid rgb(223, 227, 232);
    height: 30px;
    padding: 6px 8px;
    width: 185px;
}
.query-builder-sec .searchBtn .form-group.border-group{
    margin: 23px 0 0 6px !important;
}
.querybuilder-content .searchBtn .has-search input{
    min-width: 200px;
    width: 200px;
    min-height: 32px;
    padding-left: 1.8rem;
}
.querybuilder-content .searchBtn .form-control-feedback {
    padding: 4px 2px 3px 0px;
    line-height: 1.6rem !important;
    font-weight: normal;
    font-size: 14px;
    width: 30px;
}
.metriccard-accordion .card-link{
    display: flex;
}
#accordion.metriccard-accordion{
    border-top: none;
}
.metriccard-accordion .card{
    border: none;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
    margin-bottom: 1rem;
}
.metriccard-accordion .card-header{
    background: rgb(255, 255, 255);
    border-radius: 8px !important;
    border-bottom: none;
}
.metric-outcomeslist{
    display: flex;
    margin-left: 16px;
    align-items: center;
    justify-content: space-between;
}
.metric-outcomeslist ul{
    margin-bottom: 0;
}
.metric-outcomeslist ul li{
    display: inline-block;
    position: relative;
    margin-right: 20px;
    font-family: graphik;
    font-size: 14px;
    font-weight: normal;
    color: #637381;
    letter-spacing: -0.29px;
}
.metric-outcomeslist ul li:after{
    content: " ";
    width: 1px;
    height: 12px;
    position: absolute;
    right: -10px;
    top: 4px;
    background-color: #637381;
}
.metric-outcomeslist .metricdeal-percent{
border-radius: 16px;
height: 32px;
width: 64px;
font-family: Graphik-Medium;
font-size: 11px;
font-weight: 500;
letter-spacing: -0.25px;
background-color: #fff4d5;
display: block;
text-align: center;
margin-right: 30px;
padding: 8px;
}
.metric-outcomeslist .metricdeal-percent.draft-status{
    background-color: #fff4d5;
    color: #e6b019;
}
.metric-outcomeslist .metricdeal-percent.active-status{
    background-color: #e5f3da;
    color: #528626;
}

.metric-outcomeslist ul li:last-child:after{
    display: none;
}
.metriccard-accordion .header-link h6{
color: rgb(33, 43, 54);
font-family: Graphik-Medium;
font-size: 16px;
font-weight: 500;
letter-spacing: -0.35px;
margin-bottom: 0;
}
.metriccard-accordion .card-linkarrow{
    margin-right: 10px;
}
.metriccard-accordion  .card-link::before,
.metriccard-accordion  .card-link.collapsed::before {
  content: "\F0DA";
  position: absolute;
  top: 23px;
  color: rgb(33, 43, 54);
  font-weight: 600;
  left: -4px;
  transform: rotate(0deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
  cursor: pointer;
  display: block;
}
.metriccard-accordion  .card-link::before {
    transform: rotate(90deg);
    -ms-transition: all 0.2s ease 0.2s;
    transition: all 0.2s ease 0.2s;
  }
.metriccard-accordion .card-body{
    padding: 0 1.25rem 0 2.25rem;
}
.metriccard-accordion .card-body p{
color: rgb(33, 43, 54);
font-family: Graphik-Medium;
font-size: 12px;
font-weight: 500;
letter-spacing: 0px;
line-height: 14px;
}
.metriccard-accordion .card-body p span{
    color: #637381;
    margin-left: 12px;
}
.metriccard-accordion .metricoutcome-right{
    display: flex;
    align-items: center;
}
.metricdeal-dropdown a{
    color: #637381;
}
.metricdeal-dropdown a:hover{
    color: #637381;
}
.metricdeal-dropdown .dropdown-menu{
    width: 160px;
    min-width: 160px;
    padding: 8px 0;
    background: rgb(255, 255, 255);
border-radius: 8px;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.metricdeal-dropdown .dropdown-item{
    color: #637381 !important;
font-family: graphik;
font-size: 14px;
font-weight: normal;
cursor: pointer;
}
.metricdeal-dropdown .dropdown-item i{
    margin-right: 5px;
}
.metricdeal-dropdown .dropdown-item:hover{
    color: #637381;
    background-color: #f4f6f8;
}
.querybuilder-footer .btn{
    width: 120px;
    height: 48px;
    font-size: 16px;
}
.formula-builder h5.card-header{
background-color: #f9fafb;
    color: rgb(44, 54, 64);
font-family: Graphik-Medium;
font-size: 16px;
font-weight: 500;
}

/* Tab steps custom css starts here. created by Srihari 2nd Dec 2020 */
.queryBuilderModal .toggle-form.tabsSmall .nav-tabs a::before, .queryBuilderModal .toggle-form.tabsSmall .previousActive.nav-tabs a:last-child::before {
    border-color: rgb(162, 185, 196);
    color: #fff;
    background: rgb(162, 185, 196);
    height: 27px;
    width: 27px;
    box-shadow: 0px 0px 0px 8px #d4e3e8;
    padding: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
 
.queryBuilderModal .toggle-form.tabsSmall .nav-tabs a.active::before, .queryBuilderModal .toggle-form.tabsSmall .previousActive.nav-tabs a.active::before, .queryBuilderModal .toggle-form.tabsSmall .lastActive.nav-tabs a.active::before {
    border-color: rgb(66, 180, 238);
    color: #fff;
    background: rgb(66, 180, 238);
    height: 27px;
    width: 27px;
    box-shadow: 0px 0px 0px 8px rgb(212, 235, 247);
    padding: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
 
.queryBuilderModal .toggle-form.tabsSmall .nav-tabs a::after {
    height: 20px;
    width: 224%;
    top: 12px;
    background: rgb(219, 230, 233);
}
.queryBuilderModal .toggle-form.tabsSmall .nav-tabs a:nth-last-child(2)::after {
    width: 180%;
}
 
.queryBuilderModal .toggle-form.tabsSmall .nav-tabs .nav-link, .queryBuilderModal .toggle-form.tabsSmall .previousActive.nav-tabs .nav-link:last-child{
    color: rgb(107, 137, 151);
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    margin: 0 80px 12px;
    cursor: default;
}
 
    .queryBuilderModal .toggle-form.tabsSmall .nav-tabs .nav-link.active, .queryBuilderModal .toggle-form.tabsSmall .previousActive.nav-tabs .nav-link.active {
        color: rgb(66, 180, 238);
        font-family: Graphik-Medium;
        font-size: 14px;
        font-weight: 500;
    }
 
.queryBuilderModal .toggle-form.tabsSmall .previousActive.nav-tabs .nav-link, .queryBuilderModal .toggle-form.tabsSmall .lastActive.nav-tabs.nav-tabs .nav-link {
    color: rgb(126, 195, 68);
}
 
.queryBuilderModal .toggle-form.tabsSmall .previousActive.nav-tabs a::before, .queryBuilderModal .toggle-form.tabsSmall .lastActive.nav-tabs a::before {
    background: rgb(126, 195, 68);
    border-color: rgb(162, 185, 196);
    box-shadow: 0px 0px 0px 8px rgb(229, 243, 218);
    margin-bottom: 10px;
}
.queryBuilderModal .toggle-form.tabsSmall .lastActive.nav-tabs.nav-tabs a:first-child::after {
    background-image: -webkit-linear-gradient( to right,rgb(229, 243, 218) 50%,rgb(229, 243, 218) 50%);
    background-image: linear-gradient( to right,rgb(229, 243, 218) 50%,rgb(229, 243, 218) 50%);
    background-image: -ms-linear-gradient( to right,rgb(229, 243, 218) 50%,rgb(229, 243, 218) 50%);
}
.queryBuilderModal .toggle-form.tabsSmall .previousActive.nav-tabs a:nth-child(1)::after, .queryBuilderModal .toggle-form.tabsSmall .lastActive.nav-tabs a::after {
    background-image: -webkit-linear-gradient( to right,rgb(229, 243, 218) 50%,rgb(221, 238, 243) 50%);
    background-image: linear-gradient( to right,rgb(229, 243, 218) 50%,rgb(221, 238, 243) 50%);
    background-image: -ms-linear-gradient( to right,rgb(229, 243, 218) 50%,rgb(221, 238, 243) 50%);
}

.tabSteps {
    position: relative;
}
.custom-tab-change {
    position: absolute;
    right: 2%;
    top: 15px;
    width: 48px;
    display: flex;
    justify-content: space-between;   
}
.custom-tab-change .left{
    width: 0px;
    height: 0px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 8px solid rgb(99, 115, 129);;
    display: inline-block;
    cursor: pointer;
}
.custom-tab-change .right{
    width: 0px;
    height: 0px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 8px solid rgb(99, 115, 129);;
    transform: rotate(180deg);
    cursor: pointer;
    display: inline-block;
}
.custom-tab-change span.disabled {
    border-right: 8px solid rgb(99, 115, 129);
    pointer-events: none;
  }
 
/* Tab steps custom css ends here. created by Srihari 2nd Dec 2020 */
.formula-builder .toggle-group input[type=checkbox]{
    top:2px;
}
.formula-builder .onoffswitch{
    width: 36px;
}
.formula-builder .onoffswitch .onoffswitch-switch{
    width: 20px;
    height: 20px;
    top: 2px;
    right: 16px;
}
.clearformula-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.clearformula-section .card-text{
color: rgb(99, 115, 129);
font-family: Graphik-Medium;
font-size: 12px;
letter-spacing: -0.27px;
font-weight: 500;
}
.clearformula-section span{
    color: rgb(1, 142, 255);
font-family: Graphik-Medium;
font-size: 10px;
font-weight: 500;
letter-spacing: -0.21px;
}
.calculator-dropdown .calculator-input{
background: rgb(255, 255, 255);
border-radius: 4px 4px 0px 0px;
border: 1px solid rgb(223, 227, 232);
height: 56px;
padding: 1rem;
position: relative;
}
.calculator-dropdown .calculator-input div{
    position: absolute;
    right: 0;
    top: 0;
    background: rgb(255, 255, 255);
    border-radius: 0px 4px 4px 0px;
    border-left: 1px solid rgb(223, 227, 232);
    height: 54px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.calculator-dropdown .calculator-input div i{
    color: #637381;
    cursor: pointer;
}
.calculator-dropdown.open .calculator-input div i{
    transform: rotate(180deg);
}
.calculator-dropdown .calculator-select-list{
    background: rgb(255, 255, 255);
    border: 1px solid rgb(223, 227, 232);
    border-top: none;
    padding: 15px 25px;
    overflow-y: auto;
    position: relative;
    top: 0px;
    left: 0;
    z-index: 11111;
}
.calculator-select-list .calculator-btnlist{
    background: rgb(255, 255, 255);
    border-radius: 0px;
    border: 1px solid rgb(223, 227, 232);
    border-right: 0;
    border-bottom: 0;
    width: 100%;
}
.calculator-list-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.calculator-btnlist .calculator-value{
    border-bottom: 1px solid  rgb(223, 227, 232);
    min-width: 95px;
    height: 32px;
    width: 25%;
    color: rgb(95, 115, 131);
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    border-right: 1px solid  rgb(223, 227, 232);
    border-radius: 0;
    border-top: none;
    border-left: none;
}
.calculator-btnlist .calculator-value.large-button{
    width: 75%;
}
.calculator-btnlist .calculator-value:hover,
.calculator-btnlist .calculator-value:focus{
    background-color: #d4ebf7 !important;
    color: rgb(95, 115, 131) !important;
    border-color:  #d4ebf7 !important;
    box-shadow: none !important;
}
.calculator-btnlist .calculator-value.disabled{
    background: rgb(249, 250, 251);
    pointer-events: none;
}
.queryBuilderModal .toggle-form.tabsSmall .sub-tabslist .nav-tabs a::before{
    display: none;
}
 .queryBuilderModal .toggle-form.tabsSmall .sub-tabslist .nav-tabs a::before{
     content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 3px 3px 0 0;
    height: 4px;
    background-color: #018eff;
} 
.queryBuilderModal .toggle-form.tabsSmall .sub-tabslist .nav-tabs .nav-link.active{
border-bottom: 4px solid #018eff;
padding-right: 0;
    border-radius: 3px 3px 0px 0px;
}
.queryBuilderModal .toggle-form.tabsSmall .sub-tabslist .nav-tabs .nav-link{
    margin: 0 20px;
    padding: .5rem 0;
}
.queryBuilderModal .toggle-form .tabSteps.sub-tabslist .nav-tabs a:first-child{
    margin-left: 0;
}
.queryBuilderModal .toggle-form.tabsSmall .sub-tabslist .nav-tabs{
    border-bottom: 1px solid rgb(223, 227, 232);
}
.queryBuilderModal .fields-notification{
    color: rgb(145, 159, 171);
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.27px;
    text-align: right;
    margin-right: 5px;
}
.field-condition-set{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.queryBuilderModal .addIconCircle{
font-size: 16px;
}
.addIconCircle i{
    color:#637381;
}
.toggle-rdio-checkbtns{
    display: flex;
    align-items: center;
    height: 100%;
}
.queryBuilderModal .resultSetClone .validate-btn{
    background: rgb(255, 255, 255);
    border-radius: 16px;
    color: rgb(1, 142, 255);
    border: 1.5px solid rgb(1, 142, 255);
    height: 32px;
    width: 100px;
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.25px;
    margin-top: 8px;
}
.queryBuilderModal .resultSetClone .validate-btn:hover{
    background:rgb(1, 142, 255);
    color: #fff;
}
.setup-description{
    color: rgb(33, 43, 54);
font-family: Graphik-Medium;
font-size: 12px;
font-weight: 500;
height: 18px;
letter-spacing: 0px;
line-height: 16px;
}
.setup-description span{
    color: rgb(1, 142, 255);
    margin-right: 10px;
}
.custom-multi-select.qb-custom-multiselect .multi-select{
    height: 15px;
}
.custom-multi-select.qb-custom-multiselect .multi-select .dropdown-container{
    border: none;
}
.qb-custom-multiselect.dropdown-heading-dropdown-arrow{
    padding-top: 0 !important;
}
.qb-custom-multiselect.dropdown-heading-dropdown-arrow svg{
    width: 20px;
    height: 20px;
}
.rowWrapper.qb-rowWrapper{
    overflow-x: visible;
    max-height: none;
}
.qb-custom-multiselect .dropdown-heading{
    height: 15px;
    padding: 0 !important;
    font-size: 14px;
}
.qb-custom-multiselect .dropdown-content{
    width: 178px;
left: -10px;
}