.overallqcscore-section{
    margin-top: 0;
    padding: 0rem 3em 1rem 3rem;
}
.overallqcscore-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.overallqcscore-heading h5{
color: rgb(33, 43, 54);
font-family: Graphik-Medium;
font-size: 20px;
font-weight: 500;
letter-spacing: -0.29px;
}
.overallqcscore-close .close-icon{
    color: #637381;
    font-size: 21px;
}
.overallqcscore-close .close-icon::after {
    display: none;
}
.qcscore-accordions {
    position: relative;
}
.qcscore-accordions #accordion.qcscore-accordion{
    border: none;
    max-height: none;
    overflow: initial;
}
.qcscore-accordion .card{
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    border: none;
    padding: 1.25rem 2rem;
    margin-bottom: 1rem;
}
.qcscore-accordion .card-body{
    padding: 0;
}
.qcscore-accordion .card-header{
    background: #ffffff;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px !important;
}
.qcscore-accordion .card-link{
    background:-moz-linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(1, 142, 255) 100%); 
    background: -webkit-linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(1, 142, 255) 100%); 
    background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(1, 142, 255) 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;  
    -ms-background-clip: text;
    background-clip: text;
   -webkit-text-fill-color:transparent;
   -ms-text-fill-color:transparent;
   display: flex;
}
.qcscore-accordion .card-link i,
.qcscore-accordion .card-link.collapsed i {
  margin-right: 10px;
  cursor: pointer;
  display: block;
}
.qcscore-accordion .card-link[aria-expanded="true"] i:before{
  content: "\f0dd";
}
.qcscore-percent{
color: #637381;
font-family: Graphik-Medium;
font-size: 11px;
font-weight: 500;
letter-spacing: -0.25px;
background: #f4f6f8;
border-radius: 16px;
height: 32px;
padding: 12px 8px;
cursor: pointer;
}
.qcscore-percent.red-status{
color: #b50028;
background-color: #ffdfdf;
}
.qcscore-percent.orange-status{
    color:#4b3700;
    background-color:#ffe18d;
    }
.qcscore-percent.green-status{
  color: #528626;
  background-color: #e5f3da;
}

.qcscore-accordions .qcscore-status .qcscore-percent,
.qcscore-graphstatus .qcscore-graphdata .qcscore-percent {
    cursor: initial !important;
}

 .qualityscore-section{
    display: flex;
} 
.qualityscore-graph{
    width: 60%;
    margin-right: 30px;
}
.score-summary-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.score-summary-heading span i{
    font-size: 20px;
    color: #212b36;
    cursor: pointer;
}
.score-summary-heading h5{
color: rgb(74, 74, 74);
font-family: Graphik-Medium;
font-size: 16px;
font-weight: 500;
letter-spacing: -0.23px;
margin-bottom: 1.5rem;
}

.qualityscore-data{
    width: 40%;
    display: flex;
    align-items: center;
}
.empty-qualityscore-data,
.empty-qualityscore-section{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
    text-align: center;
}
.empty-qualityscore-section{
    height: 350px;
    width: 50%;
}
.empty-qualityscore-data p,
.empty-qualityscore-section p{
color: rgb(99, 115, 129);
font-family: graphik;
font-size: 24px;
font-weight: normal;
letter-spacing: -0.35px;
}
.automation-summary-section{
    display: flex;
    width: 100%;
}
.automation-summary{
    background: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid rgb(223, 227, 232);
    padding: 1rem;
    margin-bottom: 1.25rem;
    height: 184px;
}
.automation-summary.summary-large{
    width: 70%;
    margin-right: 1rem;
}
.automation-summary.summary-large.left-summary{
    border: none;
    padding: 0;
    display: flex;
}
.automation-summary.summary-large.left-summary .automation-summary.summary-small{
width: 50%;
}
.automation-summary.summary-small{
width: 28%;
height: 184px;
margin-right: 20px;
}
.summary-three-section .automation-summary.summary-small{
    width:33.3%
}

.automation-heading{
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
}
.automation-heading img{
    margin-right: 1rem;
}
.automation-heading h5{
color: rgb(102, 102, 102);
font-family: Graphik-Medium;
font-size: 16px;
font-weight: 500;
letter-spacing: -0.29px;
line-height: 14px;
margin-bottom: 0;
}
.automation-categories ul{
    margin-left: 1.5rem;
}
.automation-categories ul.two-columnlayout{
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}
.automation-categories li{
    margin-bottom: 1rem;
}
.automation-categories li p{
    color: rgb(145, 158, 171);
    font-family: 'graphik';
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.25px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.automation-categories li p .automation-icon{
    margin-right: 1rem;
}
.automation-categories li p .automation-icon i{
    font-size: 20px;
}
.automation-categories li p .automation-status{
    margin-left: 1.25rem;
    color: #666666;
    position: relative;
    display: block;
}
.automation-categories .automation-status:after{
    content:" ";
     font-family: "Font Awesome 5 Pro";
     font-weight: bold;
     position: absolute;
     right: -21px;
    top: 0px;
}
.automation-categories .automation-status.done-status:after{
    content: "\f058";
    color: #7ec344;
}
.automation-categories .automation-status.notdone-status:after{
content: "\f057";
color: #d5002f;
}
.automation-categories .automation-status.integrated-status:after{
    content: "\f0ab";
    color: #d5002f;
    font-weight: normal;
    transform: rotate(-60deg);

}
.score-summary ul{
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}
.score-summary ul li{
margin-bottom: 1.25rem;
color: rgb(33, 43, 54);
font-family: graphik;
font-size: 14px;
font-weight: normal;
letter-spacing: -0.2px;
position: relative;
padding-left: 1.5rem;
}
.score-summary li:before{
    content: " ";
    width: 10px;
    height: 10px;
    background-color: #e6b019;
    position: absolute;
    left: 0;
    border-radius: 100%;
    top: 5.5px;
}
.score-summary li.orange-percent:before{
    background-color: #e6b019;
}
.score-summary li.red-percent:before{
    background-color: #d5002f;
}
.score-summary li.green-percent:before{
    background-color: #7ec344;
}

.helpsummary-modal .modal-dialog{
    max-width: 1210px;
}
.helpsummary-modal .modal-title{
font-size: 18px;
}
.helpsummary-list .helpsummary-item{
    list-style-type: disc;
color: rgb(51, 51, 51);
font-family: Graphik-Medium;
font-size: 16px;
font-weight: 500;
letter-spacing: -0.35px;
line-height: 30px;
margin-bottom: 20px;
}
.main-helpsummary-list{
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}
.helpsummary-list .helpsummary-item li{
color: rgb(51, 51, 51);
font-family: graphik;
font-size: 16px;
font-weight: normal;
letter-spacing: -0.35px;
}
.qcscorepercent-modal .modal-dialog{
    max-width: 606px;
}
.qcscorepercent-modal .modal-title{
    font-size: 18px;
}
/* 11/30/2020 */
.qcscorestatus-modal .modal-dialog{
 max-width: 735px;
}
.qcscore-graphstatus{
    display: flex;
    margin-bottom: 20px;
}
.qcscore-graphstatus .qcscore-graph{
    width: 75%;
    margin-right: 20px;
}
.qcscore-graphdata .qcscore-percent{
    width: 135px;
    display: block;
    height: 54px;
    text-align: center;
    border-radius: 27px;
    margin-bottom: 24px;
}
.qcscore-graphdata .qcscore-percent span{
    display: block;
}
.qcscorestatus-help h6{
color: rgb(51, 51, 51);
font-family: Graphik-Medium;
font-size: 14px;
font-weight: 500;
letter-spacing: -0.31px;
}
.qcscorestatus-help p{
color: rgb(99, 115, 129);
font-family: graphik;
font-size: 16px;
font-weight: normal;
letter-spacing: -0.35px;
}
.qcscore-percent.blue-status{
    color: #078ccd;
    background-color: #f1f5fb;
}
.qcscore-outputdata .qcscore-graphdata .qcscore-percent{
display: inline-block;
margin-right: 1.25rem;
}
.qcscore-pulse-page{
    float: left;
    margin-right: 20px;
}
.qcscore-graph.tab-graph canvas {
    height: 225px !important;
}
.qcscore-outputdata{
    width: 100%;
}
.qcscore-graphhelp h6{
    color: rgb(51, 51, 51);
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.26px;
    position: relative;
    padding-left: 15px;
}
.qcscore-graphhelp h6:before{
    content: " ";
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: rgb(51, 51, 51);
    border-radius: 50%;
    left: 0px;
    top: 5px;
}
.qcscore-graphhelp ul li{
color: rgb(99, 115, 129);
font-family: graphik;
font-size: 12px;
font-weight: normal;
letter-spacing: 0px;
line-height: 30px;
}