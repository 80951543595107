.messageboard-header {
  justify-content: space-between;
  align-items: center;
}
.message-typestatus {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.message-typestatus .message-type {
  display: flex;
}
.message-typestatus h6 {
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.29px;
  margin-right: 15px;
}
.message-typestatus .form-check-label {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  position: relative;
  top: -4px;
}
.message-status {
  display: flex;
}
/* toggle switch css */

.message-status .onoffswitch .onoffswitch-inner:before,
.message-status .onoffswitch .onoffswitch-inner:after {
  height: 24px;
}

.message-status .onoffswitch .onoffswitch-inner:before {
  background: #018eff;
}

.message-status .onoffswitch .onoffswitch-switch {
  height: 24px;
  top: 1px;
  width: 24px;
}

.message-status
  .toggle-group
  input[type="checkbox"]:checked
  ~ .onoffswitch
  .onoffswitch-label
  .onoffswitch-switch:before {
  content: "\F058";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: 0px;
  top: -7px;
  font-size: 25px;
  color: #018eff;
}
/* toggle switch css end */
.date-fields-wrapper .date-wrapper .add-on {
  position: absolute;
}
.date-fields-wrapper,
.time-fields-wrapper {
  margin-bottom: 15px;
}
.modal-btn.messageboard-btns {
  justify-content: flex-end !important;
}
.modal-btn.messageboard-btns .btn {
  min-width: 120px;
  height: 48px;
}
.successnotification-modal .modal-dialog {
  width: 574px;
  position: absolute;
  right: 20px;
  top: 56px;
}
.notification-data h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.35px;
}
.successnotification-modal .notification-data p {
  line-height: 21px;
  letter-spacing: -0.35px;
}
.addMessageModal .modal-dialog {
  max-width: 936px;
}
.date-fields-wrapper .date-wrapper .input-append::after {
  content: "\f073";
  font-weight: 100;
  display: none;
}
.date-fields-wrapper .date-wrapper .input-append label {
  position: absolute;
  right: 0;
}
.active-status {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin-left: 10px;
  margin-top: 3px;
}

#startTimePicker:disabled,
#endTimePicker:disabled,
.ui-timepicker-select:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.ui-timepicker-select option {
  background: rgb(255, 255, 255);
  border-radius: 0px;
  border: 1px solid rgb(223, 227, 232);
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  padding: 6px 0px 6px 10px;
  border-bottom: 0px;
}
