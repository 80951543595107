/* BAT Tile changes start */

.tilecard-border.outcome-highlight .outcome-divider {
    padding: 0 !important;
}

.tilecard-border .outcome-indicate {
    padding: 16px 5px 16px 16px;
}

.tilecard-border .card-overlay {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    flex-wrap: wrap;
}

.tilecard-border .outcome-indicate:before {
    left: 0;
    border-radius: 8px 0px 0px 8px;
}

.tilecard-border .outcome-indicate:before {
    background: -webkit-linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 100%);
    background: -moz-linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 100%);
    background: -o-linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 100%);
    background: -ms-linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 100%);
    background: linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 100%);
}

.tilecard-border .gray-clr.outcome-divider .outcome-indicate:before {
    background: -webkit-linear-gradient(-45deg, rgb(196, 205, 213) 0%, rgb(223, 227, 232) 100%);
    background: -moz-linear-gradient(-45deg, rgb(196, 205, 213) 0%, rgb(223, 227, 232) 100%);
    background: -o-linear-gradient(-45deg, rgb(196, 205, 213) 0%, rgb(223, 227, 232) 100%);
    background: -ms-linear-gradient(-45deg, rgb(196, 205, 213) 0%, rgb(223, 227, 232) 100%);
    background: linear-gradient(-45deg, rgb(196, 205, 213) 0%, rgb(223, 227, 232) 100%);
}

.tilecard-border .red-clr.outcome-divider .outcome-indicate:before {
    background: -webkit-linear-gradient(-225deg, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
    background: -moz-linear-gradient(-225deg, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
    background: -o-linear-gradient(-225deg, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
    background: -ms-linear-gradient(-225deg, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
    background: linear-gradient(-225deg, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
}

.tilecard-border .orange-clr.outcome-divider .outcome-indicate:before {
    background: -webkit-linear-gradient(-225deg, rgb(247, 218, 134) 0%, rgb(230, 176, 25) 100%);
    background: -moz-linear-gradient(-225deg, rgb(247, 218, 134) 0%, rgb(230, 176, 25) 100%);
    background: -o-linear-gradient(-225deg, rgb(247, 218, 134) 0%, rgb(230, 176, 25) 100%);
    background: -ms-linear-gradient(-225deg, rgb(247, 218, 134) 0%, rgb(230, 176, 25) 100%);
    background: linear-gradient(-225deg, rgb(247, 218, 134) 0%, rgb(230, 176, 25) 100%);
}

.tilecard-border .owl-carousel.owl-drag .owl-item {
    width: 256px !important;
}

/* BAT Tile changes End */

/* Tile Card Enchancements Start */

.tilecard-new .owl-carousel.owl-drag .owl-item {
    width: 256px !important;
}

.tilecard-new.outcome-highlight .outcome-divider {
    padding: 0 !important;
}

.tilecard-new .outcome-indicate {
    padding: 16px 5px 16px 16px;
    height: 147px;
    min-height: auto !important;
}

.tilecard-new .outcome-indicate:before {
    left: 0;
    border-radius: 8px 0px 0px 8px;
}

.tilecard-new .outcome-bottom {
    position: absolute;
    bottom: 0;
}

.tilecard-new .card-overlay {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    flex-wrap: wrap;
    flex-direction: column;
}

.tilecard-new .outcome-indicate:before {
    background: -webkit-linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 100%);
    background: -moz-linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 100%);
    background: -o-linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 100%);
    background: -ms-linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 100%);
    background: linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 100%);
}

.tilecard-new .gray-clr.outcome-divider .outcome-indicate:before {
    background: -webkit-linear-gradient(-45deg, rgb(196, 205, 213) 0%, rgb(223, 227, 232) 100%);
    background: -moz-linear-gradient(-45deg, rgb(196, 205, 213) 0%, rgb(223, 227, 232) 100%);
    background: -o-linear-gradient(-45deg, rgb(196, 205, 213) 0%, rgb(223, 227, 232) 100%);
    background: -ms-linear-gradient(-45deg, rgb(196, 205, 213) 0%, rgb(223, 227, 232) 100%);
    background: linear-gradient(-45deg, rgb(196, 205, 213) 0%, rgb(223, 227, 232) 100%);
}

.tilecard-new .red-clr.outcome-divider .outcome-indicate:before {
    background: -webkit-linear-gradient(-225deg, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
    background: -moz-linear-gradient(-225deg, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
    background: -o-linear-gradient(-225deg, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
    background: -ms-linear-gradient(-225deg, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
    background: linear-gradient(-225deg, rgb(251, 180, 181) 0%, rgb(186, 97, 99) 100%);
}

.tilecard-new .orange-clr.outcome-divider .outcome-indicate:before {
    background: -webkit-linear-gradient(-225deg, rgb(247, 218, 134) 0%, rgb(230, 176, 25) 100%);
    background: -moz-linear-gradient(-225deg, rgb(247, 218, 134) 0%, rgb(230, 176, 25) 100%);
    background: -o-linear-gradient(-225deg, rgb(247, 218, 134) 0%, rgb(230, 176, 25) 100%);
    background: -ms-linear-gradient(-225deg, rgb(247, 218, 134) 0%, rgb(230, 176, 25) 100%);
    background: linear-gradient(-225deg, rgb(247, 218, 134) 0%, rgb(230, 176, 25) 100%);
}

.tilecard-new .blue-cat {
    height: 29px;
    margin-top: 6px;
}

.tilecard-new .blue-cat span {
    background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(70, 0, 115, 0.1) 100%);
    border-radius: 14.5px;
    color: rgb(117, 0, 192);
    font-family: Graphik-Medium;
    font-size: 8px;
    font-weight: 500;
    height: auto;
    padding: 4px 10px;
    line-height: 8px;
}

.tilecard-new .blue-cat span.no-tool {
    display: none;
}

.tilecard-new .card-overlay .metric-details {
    height: auto;
    padding: 1px 10px;
    white-space: normal;
    line-height: 16px;
    width: 100%;
    text-align: center;
    -ms-text-overflow: ellipsis !important;
    font-size: 12px;
    font-family: graphik;
    color: #637381;
    display: block;
    letter-spacing: -0.25px;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tilecard-new .card-overlay .metric-details b {
    display: inline-block;
    font-weight: 600;
}

.tilecard-new .parent-metric-details span.metric-details {
    font-size: 8px;
}

.tilecard-new .parent-metric-details span.metric-details b {
    display: inline-block;
    font-weight: 600;
    color: #212b36;
}

.tilecard-new.insightsNew .outcome-divider span.metric-digit {
    color: #212b36;
    font-family: Graphik-Medium;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
}

/*
    .tilecard-new .outcome-divider span.metric-digit-text{
        color: #212b36;
        font-family: Graphik-Medium;
font-size: 14px;
font-weight: 500;
        line-height: 30px;
    }*/

/* Tile Card Enchancement End */

/* Metric Tile New Design Css Starts here */

.custom-tilecard .tilecard-wrapper {
    margin-bottom: 24px;
}

.custom-tilecard.ddtile-wrapper .tilecard-wrapper, .custom-tilecard.hm-tilecard .tilecard-wrapper {
    display: flex;
    flex-wrap: wrap;
}

/* .custom-tilecard .tilecard-wrapper .owl-carousel .owl-stage {
    padding: 9px 0;
} */

.custom-tilecard .tilecard-wrapper .owl-carousel .owl-item {
    width: 286px !important;
    height: 160px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
    border-radius: 8px;
    margin-bottom: 1.5rem;
}

.custom-tilecard .tilecard-wrapper.no-carousel .item {
    width: 268px !important;
    height: 160px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
    border-radius: 8px;
    margin: auto;
    margin-bottom: 24px;
}

.custom-tilecard.ddtile-wrapper .tilecard-wrapper.no-carousel .item, .custom-tilecard.hm-tilecard .tilecard-wrapper.no-carousel .item {
    margin: 0 16px 16px 0;
    width: calc(286px - 6px) !important;
}

.custom-tilecard .tilecard-wrapper h2 {
    color: rgb(33, 43, 54);
    font-size: 16px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-tilecard .tilecard-wrapper .owl-carousel .owl-item .item, .tilecard-wrapper.no-carousel .item {
    display: flex;
    height: 100%;
    position: relative;
}

.custom-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .item-left, .custom-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .item-right, .tilecard-wrapper.no-carousel .item .item-left, .tilecard-wrapper.no-carousel .item .item-right {
    width: 48.4%;
    padding: 30px 8px 16px 16px;
}

.custom-tilecard.ddtile-wrapper .tilecard-wrapper.no-carousel .item .item-right {
    padding-top: 30px !important;
}

.custom-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .item-right, .tilecard-wrapper.no-carousel .item .item-right {
    width: 51.6%;
}

.custom-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .item-right, .tilecard-wrapper.no-carousel .item .item-right {
    padding-left: 26px;
}

.tilecard-wrapper .owl-carousel .owl-item .item .tile-metric-value, 
.tilecard-wrapper.no-carousel .item .tile-metric-value {
    font-size: 20px;
    font-family: Graphik-Semibold;
    font-weight: 600;
    letter-spacing: -0.46px;
    line-height: 18px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow-wrap: break-word;
}

.tilecard-wrapper .owl-carousel .owl-item .item.amber-color .tile-metric-value, .tilecard-wrapper.no-carousel .item.amber-color .tile-metric-value {
    color: rgb(192, 87, 23);
}

.tilecard-wrapper .owl-carousel .owl-item .item.green-color .tile-metric-value, .tilecard-wrapper.no-carousel .item.green-color .tile-metric-value {
    color: rgb(82, 134, 38);
}

.tilecard-wrapper .owl-carousel .owl-item .item.red-color .tile-metric-value, .tilecard-wrapper.no-carousel .item.red-color .tile-metric-value {
    color: rgb(213, 0, 47);
}

.tilecard-wrapper .owl-carousel .owl-item .item.gray-color .tile-metric-value, .tilecard-wrapper.no-carousel .item.gray-color .tile-metric-value {
    color: rgb(145, 158, 171);
}

.owl-carousel .owl-item .item:hover .item-left .tile-metric-value, 
.owl-carousel .owl-item .item:hover .item-left .tile-metric-desc, 
.tilecard-wrapper.no-carousel .item:hover .item-left .tile-metric-value, 
.tilecard-wrapper.no-carousel .item:hover .item-left .tile-metric-desc,
.owl-carousel .owl-item .item.toggle-on .item-left .tile-metric-value, 
.owl-carousel .owl-item .item.toggle-on .item-left .tile-metric-desc, 
.tilecard-wrapper.no-carousel .item.toggle-on .item-left .tile-metric-value, 
.tilecard-wrapper.no-carousel .item.toggle-on .item-left .tile-metric-desc{
    color: rgb(255, 255, 255);
}

.tilecard-wrapper.no-carousel .item.amber-color.toggle-on .item-left, .owl-carousel .owl-item .item.amber-color.toggle-on .item-left, .owl-carousel .owl-item .item.amber-color:hover .item-left, .tilecard-wrapper.no-carousel .item.amber-color:hover .item-left, .owl-carousel .owl-item .item.amber-color:hover .item-top {
    background: linear-gradient(-225deg, rgb(255, 197, 139) 0%, rgb(244, 147, 66) 50%, rgb(192, 87, 23) 100%);
    border-radius: 8px 0px 0px 8px;
}

.owl-carousel .owl-item .item.green-color.toggle-on .item-left,
.owl-carousel .owl-item .item.green-color:hover .item-left,
.tilecard-wrapper.no-carousel .item.green-color:hover .item-left,
.owl-carousel .owl-item .item.green-color:hover .item-top,
.tilecard-wrapper.no-carousel .item.green-color.toggle-on .item-left {
    background: linear-gradient(-225deg, rgb(184, 214, 158) 0%, rgb(126, 195, 68) 51%, rgb(126, 195, 68) 100%);
    border-radius: 8px 0px 0px 8px;
}

.tilecard-wrapper.no-carousel .item.red-color.toggle-on .item-left, .owl-carousel .owl-item .item.red-color.toggle-on .item-left, .owl-carousel .owl-item .item.red-color:hover .item-left, .tilecard-wrapper.no-carousel .item.red-color:hover .item-left, .owl-carousel .owl-item .item.red-color:hover .item-top {
    background: linear-gradient(-225deg, rgb(242, 178, 192) 0%, rgb(213, 0, 47) 52%, rgb(170, 0, 37) 100%);
    border-radius: 8px 0px 0px 8px;
}

.tilecard-wrapper.no-carousel .item.gray-color.toggle-on .item-left, .owl-carousel .owl-item .item.gray-color.toggle-on .item-left, .owl-carousel .owl-item .item.gray-color:hover .item-left, .tilecard-wrapper.no-carousel .item.gray-color:hover .item-left, .owl-carousel .owl-item .item.gray-color:hover .item-top {
    background: linear-gradient(-225deg, rgb(145, 158, 171) 0%, rgb(33, 43, 54) 100%);
    border-radius: 8px 0px 0px 8px;
}

.tilecard-wrapper .owl-carousel .owl-item .item .tile-metric-value span, .tilecard-wrapper.no-carousel .item .tile-metric-value span {
    font-size: 12px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.33px;
    line-height: 20px;
}

.tilecard-wrapper .owl-carousel .owl-item .item .tile-metric-desc, .tilecard-wrapper.no-carousel .item .tile-metric-desc {
    color: rgb(33, 43, 54);
    font-size: 12px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 16px;
    margin: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow-wrap: break-word;
}

.tilecard-wrapper .owl-carousel .owl-item .item .tile-metric-name,
.tilecard-wrapper.no-carousel .item .tile-metric-name{
    color: rgb(99, 115, 129);
    font-size: 12px;
    font-family: 'graphik';
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 14px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow-wrap: break-word;

}
.tilecard-wrapper .owl-carousel .owl-item .item .metric-lead-lagg p,
.tilecard-wrapper.no-carousel .item .metric-lead-lagg p {
    color: rgb(99, 115, 129);
    font-size: 12px;
    font-family: 'graphik';
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 14px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tilecard-wrapper.no-carousel .item .metric-lead-lagg p {
    margin-bottom: 9px;
}

.ddtile-wrapper .tilecard-wrapper.no-carousel .item .metric-lead-lagg p {
    align-items: baseline;
}

.tilecard-wrapper .owl-carousel .owl-item .item .metric-lead-lagg p span.lead-lagg-val, .tilecard-wrapper.no-carousel .item .metric-lead-lagg p span.lead-lagg-val {
    font-family: Graphik-Medium;
    font-weight: 500;
    width: 50%;
}
.tilecard-wrapper .owl-carousel .owl-item .item .metric-lead-lagg p span.lead-lagg-label,
 .tilecard-wrapper.no-carousel .item .metric-lead-lagg p span.lead-lagg-label{
     width: 50%;
     margin-right: 8px;
 }

.tilecard-wrapper .owl-carousel .owl-item .item .dropdown .dropdown-toggle:hover, 
.tilecard-wrapper .owl-carousel .owl-item .item .dropdown.show .dropdown-toggle, 
.tilecard-wrapper.no-carousel .item .dropdown .dropdown-toggle:hover, 
.tilecard-wrapper.no-carousel .item .dropdown.show .dropdown-toggle,
 .service-component-list .service-component .dropdown .dropdown-toggle:hover, 
.service-component-list .service-component .dropdown.show .dropdown-toggle,
.toolsListCard .dropdown.show  .dropdown-toggle,
.toolsListCard .dropdown  .dropdown-toggle:hover,
.common-ellipsis-dropdown .dropdown .dropdown-toggle:hover,
.common-ellipsis-dropdown .dropdown.show .dropdown-toggle  {
    background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
    border-radius: 2px;
}

.tilecard-wrapper .owl-carousel .owl-item .item .dropdown .dropdown-toggle,
 .tilecard-wrapper.no-carousel .item .dropdown .dropdown-toggle, 
 .service-component-list .service-component .dropdown .dropdown-toggle,
 .toolsListCard .dropdown  .dropdown-toggle,
 .common-ellipsis-dropdown .dropdown .dropdown-toggle {
    width: 44px;
    height: 40px;
    background: transparent;
    cursor: pointer;
    position: relative;
}

.tilecard-wrapper.kpiimpacts-list.no-carousel .dropdown {
    width: 44px;
    height: 40px;
    background: transparent;
    cursor: pointer;
    position: relative;
}
.tilecard-wrapper.kpiimpacts-list.no-carousel .dropdown .insights-new-icon::before {
    position: absolute;
    content: "";
    background: url('../Images/insights-new-icon.svg') no-repeat;
    width: 16px;
    height: 16px;
}
.tilecard-wrapper.kpiimpacts-list.no-carousel .dropdown:hover .insights-new-icon::before {
    background: url('../Images/insights-new-icon-hover.svg') no-repeat;
    width: 16px;
    height: 16px;
}

.tilecard-wrapper.kpiimpacts-list.no-carousel .dropdown:hover{
    background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
    border-radius: 2px;
}
.tilecard-wrapper .owl-carousel .owl-item .dropdown .dropdown-toggle:before,
 .tilecard-wrapper.no-carousel .dropdown .dropdown-toggle:before,
  .service-component-list .service-component .dropdown .dropdown-toggle:before,
  .toolsListCard .dropdown .dropdown-toggle:before,
  .common-ellipsis-dropdown .dropdown .dropdown-toggle:before {
    position: absolute;
    content: "";
    background: url('../Images/ellipsis.svg') no-repeat;
    width: 16px;
    height: 4px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border: none;
}

.tilecard-wrapper .owl-carousel .owl-item .dropdown .dropdown-toggle:hover:before,
 .tilecard-wrapper .owl-carousel .owl-item .dropdown.show .dropdown-toggle:before,
  .tilecard-wrapper.no-carousel .dropdown .dropdown-toggle:hover:before,
   .tilecard-wrapper.no-carousel .dropdown.show .dropdown-toggle:before, 
   .service-component-list .service-component .dropdown.show .dropdown-toggle:before, 
   .service-component-list .service-component .dropdown .dropdown-toggle:hover:before,
   .toolsListCard .dropdown .dropdown-toggle:hover:before,
   .toolsListCard .dropdown.show .dropdown-toggle:before,
   .common-ellipsis-dropdown .dropdown .dropdown-toggle:hover:before,
  .common-ellipsis-dropdown .dropdown.show .dropdown-toggle:before {
    background: url('../Images/ellipsis_gradient.svg') no-repeat;
}

.tilecard-wrapper .owl-carousel .owl-item .item .dropdown, 
.tilecard-wrapper.no-carousel .item .dropdown, 
.service-component-list .service-component .dropdown,
.toolsListCard .dropdown,
.common-ellipsis-dropdown .dropdown {
    position: absolute;
    right: 6px;
    bottom: 0px;
    display: flex;
}

.tilecard-wrapper .dropup .dropdown-toggle:empty::after,
 .service-component-list .service-component .dropup .dropdown-toggle:empty::after {
    display: none;
}

.tilecard-wrapper .dropdown .dropdown-menu.show,
 .service-component-list .service-component .dropdown .dropdown-menu.show,
 .toolsListCard .dropdown .dropdown-menu.show,
 .common-ellipsis-dropdown .dropdown .dropdown-menu.show {
    top: auto !important;
    bottom: 8px !important;
    left: -4px !important;
    border-radius: 8px;
    min-width: 130px;
    z-index: 1;
}
.wo-tabcontent .stepdetails-content .metric-datalist:last-child .tilecard-wrapper .dropdown .dropdown-menu.show {
    bottom: auto !important;
    transform: translate3d(-132px, -94px, 0px) !important;
    z-index: 1;
}
.tilecard-wrapper .dropdown .dropdown-menu a, 
.service-component-list .service-component .dropdown .dropdown-menu a,
.toolsListCard .dropdown .dropdown-menu a,
.common-ellipsis-dropdown .dropdown .dropdown-menu a {
    color: #637381;
    font-size: 14px;
    font-family: graphik;
    font-weight: 400;
    letter-spacing: -.25px;
    line-height: 20px;
    position: relative;
    padding: 8px 8px 8px 30px;
}

.tilecard-wrapper .dropdown-menu a span,
 .service-component-list .service-component .dropdown-menu a span,
 .toolsListCard .dropdown .dropdown-menu a span,
 .common-ellipsis-dropdown .dropdown .dropdown-menu a span {
    position: relative;
}

.tilecard-wrapper .item .dropdown .dropdown-menu a:hover, 
.service-component-list .service-component .dropdown .dropdown-menu a:hover,
.toolsListCard .dropdown .dropdown-menu a:hover,
.common-ellipsis-dropdown .dropdown .dropdown-menu a:hover  {
    color: rgb(21, 24, 27);
    font-family: Graphik-Medium;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.1) linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
    border-radius: 4px;
}

.tilecard-wrapper .dropdown .dropdown-menu a .info-icon::before,
.toolsListCard .dropdown .dropdown-menu a .info-icon::before {
    position: absolute;
    content: "";
    display: inline-flex;
    background: url('../Images/summary-normal.svg') no-repeat;
    width: 14px;
    height: 14px;
    left: -22px;
    top: -1px;
}

.tilecard-wrapper .dropdown .dropdown-menu a:hover .info-icon::before,
.toolsListCard .dropdown .dropdown-menu a:hover .info-icon::before {
    background: url('../Images/summary-gradient.svg') no-repeat;
}

.tilecard-wrapper .dropdown .dropdown-menu a .delete-icon::before, 
.service-component-list .service-component .dropdown-menu a .delete-icon::before,
.toolsListCard .dropdown .dropdown-menu a .delete-icon::before,
.common-ellipsis-dropdown .dropdown .dropdown-menu a .delete-icon::before  {
    position: absolute;
    content: "";
    display: inline-flex;
    background: url('../Images/delete-normal.svg') no-repeat;
    width: 14px;
    height: 14px;
    left: -23px;
    top: -1px;
}
.common-ellipsis-dropdown .dropdown .dropdown-menu a .delete-icon::before {
    top: 0;
}
.tilecard-wrapper .dropdown .dropdown-menu a:hover .delete-icon::before,
 .service-component-list .service-component .dropdown .dropdown-menu a:hover .delete-icon::before,
 .toolsListCard .dropdown .dropdown-menu a:hover .delete-icon::before,
 .common-ellipsis-dropdown .dropdown .dropdown-menu a:hover .delete-icon::before {
    background: url('../Images/delete-gradient.svg') no-repeat;
}

.tilecard-wrapper .dropdown .dropdown-menu a .edit-icon::before,
.toolsListCard .dropdown .dropdown-menu a .edit-icon::before,
.common-ellipsis-dropdown .dropdown .dropdown-menu a .edit-icon::before,
.common-ellipsis-dropdown .dropdown .dropdown-menu a.edit-icon::before {
    position: absolute;
    content: "";
    display: inline-flex;
    background: url('../Images/edit-normal.svg') no-repeat;
    width: 14px;
    height: 14px;
    left: -22px;
    top: -1px;
}

.common-ellipsis-dropdown .dropdown .dropdown-menu a .eye-icon-slash::before {
    position: absolute;
    content: "";
    display: inline-flex;
    background: url('../Images/eye-icon-slash.svg') no-repeat;
    width: 16px;
    height: 12px;
    left: -22px;
    top: 0px;
}
.drag-drop-columns .common-ellipsis-dropdown .dropdown .dropdown-menu a span.eye-icon-slash::before {
    top: 1px !important;
}

.common-ellipsis-dropdown .dropdown .dropdown-menu a .edit-icon::before {
    top: 0px;
}

.toolsListCard .dropdown .dropdown-menu a .link-icon::before,
.metric-datalist .tilecard-wrapper .dropdown .dropdown-menu a .link-icon::before,
.modal-tilecard-wrapper .tilecard-wrapper .dropdown .dropdown-menu a .link-icon::before {
    position: absolute;
    content: "";
    display: inline-flex;
    background: url('../Images/link-normal.svg') no-repeat;
    width: 14px;
    height: 14px;
    left: -22px;
    top: -1px;
}
.tilecard-wrapper .dropdown .dropdown-menu a:hover .edit-icon::before,
.toolsListCard .dropdown .dropdown-menu a:hover .edit-icon::before,
.common-ellipsis-dropdown .dropdown .dropdown-menu a:hover .edit-icon::before {
    background: url('../Images/edit-gradient.svg') no-repeat;
}

.toolsListCard .dropdown .dropdown-menu a:hover .link-icon::before,
.metric-datalist .tilecard-wrapper .dropdown .dropdown-menu a:hover .link-icon::before,
.modal-tilecard-wrapper .tilecard-wrapper .dropdown .dropdown-menu a:hover .link-icon::before{
    background: url('../Images/link-gradient.svg') no-repeat;
}

.tilecard-wrapper .dropdown .dropdown-menu a .insights-new-icon::before {
    position: absolute;
    content: "";
    display: inline-flex;
    background: url('../Images/insights-new-icon.svg') no-repeat;
    width: 16px;
    height: 15px;
    left: -22px;
    top: -1px;
}

.tilecard-wrapper .dropdown .dropdown-menu a:hover .insights-new-icon::before {
    background: url('../Images/insights-new-icon-hover.svg') no-repeat;
}

.tilecard-wrapper .dropdown .dropdown-menu a .comments-new-icon::before {
    position: absolute;
    content: "";
    display: inline-flex;
    background: url('../Images/comments-new-icon.svg') no-repeat;
    width: 16px;
    height: 15px;
    left: -22px;
    top: -1px;
}

.tilecard-wrapper .dropdown .dropdown-menu a:hover .comments-new-icon::before {
    background: url('../Images/comments-new-icon-hover.svg') no-repeat;
}

.service-component-list .service-component .dropdown .dropdown-menu a .eye-icon::before,
.common-ellipsis-dropdown .dropdown .dropdown-menu a .eye-icon::before  {
    position: absolute;
    content: "";
    display: inline-flex;
    background: url('../Images/eye-grey.svg') no-repeat;
    width: 16px;
    height: 12px;
    left: -23px;
    top: 0px;
}

.service-component-list .service-component .dropdown .dropdown-menu a:hover .eye-icon::before,
.common-ellipsis-dropdown .dropdown .dropdown-menu a:hover .eye-icon::before  {
    background: url('../Images/eye-gradient.svg') no-repeat;
}

.ii-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .tile-metric-name {
    justify-content: center;
}

.tilecard-wrapper .owl-carousel .owl-item .item .metric-lead-lagg p {
    margin-bottom: 9px;
}

.ii-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .item-right,
 .hm-tilecard .tilecard-wrapper .item .item-right{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ii-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .item-right.isRelatorBlock {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
}

.tilecard-wrapper .item .tile-metric-name span {
    font-size: 11px;
    font-family: Graphik-Medium;
    background: rgb(244, 246, 248);
    border-radius: 16px;
    padding: 8px 12px;
    display: inline-block;
}
.tilecard-wrapper .item .tile-metric-name span.toolname-span p{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-bottom: 0;
}

.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item {
    flex-direction: column;
}

.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item .item-top {
    height: 65px;
    border-radius: 8px 8px 0px 0px;
}

.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item .item-bottom {
    height: 80px;
}

.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item {
    width: 220px !important;
    height: 145px;
}

.custom-tilecard.dd-insights-card .tilecard-wrapper .dropdown .dropdown-menu.show {
    top: auto !important;
    bottom: 23px !important;
    left: 32px !important;
    padding: 4px;
}

.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item .item-top p {
    color: rgb(33, 43, 54);
    font-size: 16px;
    font-family: Graphik-Semibold;
    font-weight: 600;
    letter-spacing: -0.46px;
    line-height: 16px;
    padding: 24px 10px 24px 14px;
    margin: 0;
}

.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item:hover .item-top p, .custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item:hover .item-top p span {
    color: rgba(255, 255, 255, 0.9);
}

.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item .item-top p span {
    font-size: 14px;
}

.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item .item-bottom p {
    color: rgb(99, 115, 129);
    font-size: 12px;
    font-family: 'graphik';
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 16px;
    margin: 0;
    padding: 8px 10px 10px 14px;
}

.custom-tilecard .item.service-component {
    padding: 0;
    align-items: unset;
}

.item.service-component .insights-new-icon {
    position: absolute;
}

.item.service-component .insights-new-icon::before {
    position: absolute;
    content: '';
    background: url('../Images/insights-new-icon.svg');
    width: 15px;
    height: 15px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.item.service-component .insights-new-icon:hover::before {
    background: url('../Images/insights-new-icon-hover.svg');
}

.item.service-component .view-btn:hover span {
    width: auto;
    left: -14%;
    z-index: 99;
}

.tilecard-tooltip {
    max-width: 237px;
    color: rgb(255, 255, 255);
    font-family: Graphik-Medium;
    font-size: 11px;
    font-weight: 500;
    opacity: 1 !important;
}

/* Metric Tile New Design Css Starts here */

/* HM New Design CSS Start */

.hmtab-layout {
    position: relative;
}

.hmtab-layout .nav-tabs .nav-link {
    border: none;
    color: rgb(99, 115, 129);
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.25px;
    position: relative;
    padding: 1rem 0.6rem;
    margin-right: 26px;
}

.hmtab-layout .nav-tabs .nav-link.active {
    color: #212b36 !important;
    font-weight: 500;
    border-bottom: none;
}

.hmtab-layout .nav-tabs .nav-link.active:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 4px;
    height: 8px;
    background-color: #018eff;
}

.hmtab-layout .tab-content {
    background: transparent;
}

.hmtab-layout .nav-tabs {
    margin-bottom: 1.5rem;
}

.hmtab-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    margin-top: 14px;
}

.hmtab-btns .btn-gradient,
.btn-gradient-common {
    background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(117, 0, 192) 52%, rgb(70, 0, 115) 100%);
    border-radius: 20px;
    color: rgb(255, 255, 255) !important;
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 14px;
    padding: .75rem 1.25rem;
    outline: none !important;
    box-shadow: none !important;
}
.hmtab-btns .btn-gradient:hover,
.btn-gradient-common:hover {
    background: linear-gradient(-225deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%);

}
.livefeed-modal .modal-dialog {
    max-width: 642px;
}

.livefeed-modal .modal-body {
    padding: 0rem 2rem 1.5rem;
    min-height: 450px;
}

.livefeed-header h4 {
    color: rgb(99, 115, 129);
    font-family: Graphik-Medium;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.35px;
    line-height: 20px;
}

.livefeed-header h4 span {
    font-family: graphik;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.29px;
    margin-left: 10px;
    position: relative;
    top: -2px;
}

.livefeed-period {
    position: absolute;
    right: 0px;
    top: 10px;
}

.livefeed-period span {
    color: rgb(33, 43, 54);
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 20px;
}

.livefeed-dropdowns {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}
.livefeed-dropdowns .dropdown{
    width: 50%;
    margin-right: 20px;
}

.livefeed-dropdowns .dropdown-toggle {
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #c4cdd5;
    color: #919eab;
    font-family: graphik;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.29px;
    outline: none;
    min-width: 280px;
    text-align: left;
    position: relative;
    max-width: 280px;
}
.livefeed-dropdowns .dropdown-toggle option{
    color: #212b36;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 15px;
}
.livefeed-dropdowns .dropdown-toggle:after {
    position: absolute;
    right: 0.75rem;
    top: .6rem;
}

.livefeed-dropdowns .dropdown-toggle:active, .livefeed-dropdowns .dropdown-toggle:focus, .livefeed-dropdowns .dropdown-toggle:hover, .livefeed-dropdowns .dropdown.show .dropdown-toggle {
    background-color: transparent !important;
    color: #919eab !important;
    box-shadow: none !important;
    border: 1px solid #c4cdd5;
}
.livefeed-dropdowns .dropdown-menu{
    width: 100%;
    padding: 4px 0;
    padding-right: 4px;
}
.livefeed-dropdowns .dropdown-menu .offering-droplistwrapper{
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 0;
}
.livefeed-dropdowns .dropdown-menu .dropdown-item{
    color: #637381;
    font-size: 14px;
    font-family: graphik;
    font-weight: 400;
    letter-spacing: -.25px;
    line-height: 20px;
}
.livefeed-dropdowns .dropdown-menu .dropdown-item:hover {
    color: rgb(21, 24, 27);
    font-family: Graphik-Medium;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.2) linear-gradient(-225deg, rgba(161, 0, 255, 0.2) 0%, rgba(0, 186, 255, 0.2) 100%);
    border-radius: 4px;
}
.livefeed-records {
    display: flex;
    flex-wrap: wrap;
    max-height: 310px;
    overflow-y: auto;
    padding-right: 4px;
}

.livefeed-recordblock {
    position: relative;
    padding: 0rem 1rem;
    margin-bottom: 1.5rem;
    width: 50%;
}

.livefeed-recordblock:before {
    position: absolute;
    content: "";
    background: #919eab;
    width: 8px;
    height: 100%;
    border-radius: 4px;
    left: 0px;
    top: 0;
}

.livefeedrecord-heading h4 {
    color: #212b36;
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 14px;
    min-height: 50px;
}

.livefeedrecord-digit {
    font-size: 24px;
    font-family: Graphik-Medium;
    color: #212b36;
    letter-spacing: -.35px;
}

.livefeedrecord-digit-text {
    color: #637381;
    font-family: graphik;
    font-size: 12px;
    letter-spacing: -.25px;
    margin-left: 5px;
}
.hybrid-link span{
color: rgb(161, 0, 255);
font-family: Graphik-Medium;
font-size: 12px;
font-weight: 500;
letter-spacing: 0px;
line-height: 18px;
}
.dashboard-component .hybrid-link>span {
    padding: 14px 8px;
    cursor: pointer;
}
.configurator-references-dropdown-tabs .hybrid-link>span{
    cursor: pointer;
}
.dashboard-component .hybrid-link>span:hover,
.dashboard-component .hybrid-link>span.active {
    background: linear-gradient(-225deg,rgba(161,0,255,.1),rgba(0,186,255,.1));        
    border-radius: 24px;
}
.dropdown-menu.explore-tools-menu{
background: rgb(255, 255, 255);
border-radius: 8px;
border: 1px solid rgb(223, 227, 232);
box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.2);
max-height: 344px;
overflow-y: auto;
min-width: 310px;
}
.dropdown-menu.explore-tools-menu .dropdown-item{
color: rgb(99, 115, 129);
font-family: graphik;
font-size: 14px;
font-weight: normal;
letter-spacing: -0.35px;
line-height: 20px;
padding: 0.5rem 1rem;
}
.dropdown-menu.explore-tools-menu .dropdown-item:hover{
background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
border-radius: 5px;
}
.dropdown-menu.explore-tools-menu.toolsmenu-conf .explore_tools{
    padding: 0.5rem 1rem;
}
.dropdown-menu.explore-tools-menu.toolsmenu-conf .explore_tools .dropdown-item{
padding: 0 1rem 0 0;
}
.dropdown-menu.explore-tools-menu.toolsmenu-conf .explore_tools:hover{
    background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
    border-radius: 5px;
    }
.dropdown-menu.explore-tools-menu.toolsmenu-conf .dropdown-item:hover{
    background: transparent !important;
border-radius: none;
}
.hmratio-toggle{
    float: right;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding-right: 1rem;
    position: relative;
    top:2px
}
.hmratio-toggle:after{
    content: "";
    background-color: #dfe3e8;
    height: 24px;
    width: 1px;
    position: absolute;
    right: 0px;
    top: 0;
}
.hmratio-toggle p{
color: rgb(99, 115, 129);
font-family: graphik;
font-size: 14px;
font-weight: normal;
letter-spacing: -0.25px;
line-height: 14px;
margin-right: 8px;
margin-bottom: 0;
}
.hmratio-toggle .onoffswitch{
    width: 40px;
}
.hmratio-toggle .toggle-group.toggle-tick input[type="checkbox"]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch:before{
    left: -1px;
    top: -7px;
    font-size: 26px;
}
.hmratio-toggle .onoffswitch .onoffswitch-label{
    border: none;
}
.hmratio-toggle .onoffswitch .onoffswitch-inner:after{
background-color: rgb(196, 205, 213);
}
.hmratio-toggle .onoffswitch .onoffswitch-switch{
    right: 15px;
    top: 1px;
}
.toggleswitchTooltip{
max-width: 210px;
color: rgb(255, 255, 255);
font-family: Graphik-Medium;
font-size: 14px;
font-weight: 500;
letter-spacing: -0.25px;
line-height: 18px;
padding: 6px;
text-align: center;
}
.explore-tools-menu .explore_tools .onoffswitch{
    width: 34px;
}
.explore-tools-menu .explore_tools .onoffswitch .onoffswitch-inner:before,
.explore-tools-menu .explore_tools .onoffswitch .onoffswitch-inner:after{
     height: 24px;
 }
 .explore-tools-menu .explore_tools .onoffswitch .onoffswitch-switch{
    top: 1px;
    width: 20px;
    height: 20px;
    right: 15px;
 }
 .explore-tools-menu .toggle-group.toggle-tick input[type="checkbox"]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch:before{
     font-size: 24px;
 } 

.hmtab-layout .title-with-icon{
    /* justify-content: flex-end;
      margin-right: 32px;
      margin-bottom: 10px; */
      align-items: center;

}
.hybridworkflow-popover{
    background: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid rgb(223, 227, 232);
    box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.2);
    /* height: 316px; */
    width: 348px;
    z-index: 999;
 }
 .hybridworkflow-popover .close{
     position: absolute;
     right: 16px;
     top: 2px;
     outline: none !important;
 }
 .hybridworkflow-popover .arrow{
     display: none;
 }
 .hybridworkflow-popover .popover-body{
     padding: 1.25rem;
 }
 .hybridworkflow-popover .popover-body h4{
    color: rgb(33, 43, 54);
    font-family: Graphik-Medium;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 16px;
    margin-bottom: 12px;
 }
 .hm-ratio-display{
     display: flex;
      align-items: center;
     justify-content: space-between;
     margin-bottom: 1rem;;
 }
 .hm-ratio-display p{
    color: rgb(33, 43, 54);
    font-family: graphik;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
  
    margin-bottom: 0;
 }
 .hm-ratio-display span{
    color: rgb(33, 43, 54);
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
  
 }

.category-references .dropdown-menu{
    padding: 8px !important;
}
.category-references .dropdown-item{
    color: #637381;
    font-size: 14px;
    font-family: graphik;
    font-weight: 400;
    letter-spacing: -.25px;
    line-height: 20px;
    position: relative;
    padding: 4px 2px 4px 30px;
}
.category-references .dropdown-item span{
    position: relative;
}

.category-references .dropdown-item:hover {
    color: rgb(21, 24, 27) !important;
    font-family: Graphik-Medium;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.2) linear-gradient(-225deg, rgba(161, 0, 255, 0.2) 0%, rgba(0, 186, 255, 0.2) 100%);
    border-radius: 4px;
}
.category-references .dropdown-item .referenceview-icon::before,
.category-references .dropdown-item .referenceadd-icon::before{
    position: absolute;
    content: " ";
    display: inline-flex;
    width: 14px;
    height: 17px;
    left: -22px;
    top: -2px;
}
.category-references .dropdown-item .referenceview-icon::before {
    background: url('../Images/reference-view-normal.svg') no-repeat;
}
.category-references .dropdown-item:hover .referenceview-icon::before {
    background: url('../Images/reference-view-hover.svg') no-repeat;
}
.category-references .dropdown-item .referenceadd-icon::before {
    background: url('../Images/reference-add-normal.svg') no-repeat;
}
.category-references .dropdown-item:hover .referenceadd-icon::before {
    background: url('../Images/reference-add-hover.svg') no-repeat;
}
/* HM New Design CSS End */
/* MTC TileCard Css Start */
.customtilecard-mtc .list-icon.mtclist-icons{
    display: flex;
    align-items: center;
}
.mtclist-icons .onoffswitch{
width: 40px;
}
.mtclist-icons .onoffswitch .onoffswitch-label{
    border:none
}
.mtclist-icons .onoffswitch .onoffswitch-inner:after{
    background-color: rgb(212 212 213);
}
.mtclist-icons .toggle-tick .onoffswitch .onoffswitch-switch{
    height: 22px;
    top: 2px;
    width: 22px;
    right: 16px;
}
.mtclist-icons .toggle-tick input[type="checkbox"]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch:before{
font-size: 24px;
}
.list-icon.mtclist-icons .fa-edit{
    color: #018eff;
}
.list-icon.mtclist-icons span{
    margin: 0 0 0 18px;
}
.custom-tilecard.customtilecard-mtc .tilecard-wrapper h2{
    margin-bottom: 0;
}
.custom-tilecard.customtilecard-mtc .title-with-icon{
    margin-bottom: .75rem;
}
.custom-tilecard.customtilecard-mtc .tilecard-wrapper .owl-carousel .owl-item .item,
.customtilecard-mtc .tilecard-wrapper.no-carousel .item{
    flex-direction: column;
}
.custom-tilecard.customtilecard-mtc .tilecard-wrapper .fourwidget-layout .owl-carousel .owl-item .item,
.customtilecard-mtc .tilecard-wrapper.no-carousel .fourwidget-layout .item{
    flex-direction: row;
}

.custom-tilecard.dd-insights-card .tilecard-wrapper .twowidget-layout .owl-carousel .owl-item,
.custom-tilecard.dd-insights-card .tilecard-wrapper .threewidget-layout .owl-carousel .owl-item{
    height: 120px;
    margin-bottom: 1rem;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .fourwidget-layout .owl-carousel .owl-item{
    height: auto !important;
    width: auto !important;
    margin-bottom: 1rem;
}

.custom-tilecard.dd-insights-card .tilecard-wrapper .twowidget-layout .owl-carousel .owl-item .item .item-top,
.custom-tilecard.dd-insights-card .tilecard-wrapper .threewidget-layout .owl-carousel .owl-item .item .item-top{
height: 48px;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .fourwidget-layout .owl-carousel .owl-item .item .item-top{
    height: 80px;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .twowidget-layout .owl-carousel .owl-item .item .item-top p{
padding: 16px 16px 8px 16px;
font-size: 20px;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .threewidget-layout .owl-carousel .owl-item .item .item-top p{
font-size: 16px;
padding: 16px 16px 8px 16px;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .twowidget-layout .owl-carousel .owl-item .item  .item-bottom,
.custom-tilecard.dd-insights-card .tilecard-wrapper .threewidget-layout .owl-carousel .owl-item .item  .item-bottom{
    height: 72px;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .fourwidget-layout .owl-carousel .owl-item .item  .item-bottom{
    height: 80px;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .twowidget-layout .owl-carousel .owl-item .item  .item-bottom p,
.custom-tilecard.dd-insights-card .tilecard-wrapper .threewidget-layout .owl-carousel .owl-item .item  .item-bottom p{
color: rgb(33, 43, 54);
font-family: Graphik-Medium;
font-size: 12px;
font-weight: 500;
letter-spacing: 0px;
line-height: 16px;
}

.customtilecard-mtc .owl-carousel .owl-item .item.gray-color:hover .item-top{
    background: linear-gradient(-45deg, rgb(99, 115, 129) 0%, rgb(145, 158, 171) 100%);
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .doughnutwidget-layout .item{
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
    height: 124px;
    width: 248px;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}
.doughnutwidget-layout .item .item-left{
    width: 45%;
    padding: 1rem;
   
}
.doughnutwidget-layout .item .item-right{
    width: 55%;
}

.doughnutwidget-insight .pie,
.doughnutwidget-insight .rd-progress .rd-bar,
.doughnutwidget-insight .rd-progress.p51 .rd-fill,
.doughnutwidget-insight .rd-progress.p52 .rd-fill,
.doughnutwidget-insight .rd-progress.p53 .rd-fill,
.doughnutwidget-insight .rd-progress.p54 .rd-fill,
.doughnutwidget-insight .rd-progress.p55 .rd-fill,
.doughnutwidget-insight .rd-progress.p56 .rd-fill,
.doughnutwidget-insight .rd-progress.p57 .rd-fill,
.doughnutwidget-insight .rd-progress.p58 .rd-fill,
.doughnutwidget-insight .rd-progress.p59 .rd-fill,
.doughnutwidget-insight .rd-progress.p60 .rd-fill,
.doughnutwidget-insight .rd-progress.p61 .rd-fill,
.doughnutwidget-insight .rd-progress.p62 .rd-fill,
.doughnutwidget-insight .rd-progress.p63 .rd-fill,
.doughnutwidget-insight .rd-progress.p64 .rd-fill,
.doughnutwidget-insight .rd-progress.p65 .rd-fill,
.doughnutwidget-insight .rd-progress.p66 .rd-fill,
.doughnutwidget-insight .rd-progress.p67 .rd-fill,
.doughnutwidget-insight .rd-progress.p68 .rd-fill,
.doughnutwidget-insight .rd-progress.p69 .rd-fill,
.doughnutwidget-insight .rd-progress.p70 .rd-fill,
.doughnutwidget-insight .rd-progress.p71 .rd-fill,
.doughnutwidget-insight .rd-progress.p72 .rd-fill,
.doughnutwidget-insight .rd-progress.p73 .rd-fill,
.doughnutwidget-insight .rd-progress.p74 .rd-fill,
.doughnutwidget-insight .rd-progress.p75 .rd-fill,
.doughnutwidget-insight .rd-progress.p76 .rd-fill,
.doughnutwidget-insight .rd-progress.p77 .rd-fill,
.doughnutwidget-insight .rd-progress.p78 .rd-fill,
.doughnutwidget-insight .rd-progress.p79 .rd-fill,
.doughnutwidget-insight .rd-progress.p80 .rd-fill,
.doughnutwidget-insight .rd-progress.p81 .rd-fill,
.doughnutwidget-insight .rd-progress.p82 .rd-fill,
.doughnutwidget-insight .rd-progress.p83 .rd-fill,
.doughnutwidget-insight .rd-progress.p84 .rd-fill,
.doughnutwidget-insight .rd-progress.p85 .rd-fill,
.doughnutwidget-insight .rd-progress.p86 .rd-fill,
.doughnutwidget-insight .rd-progress.p87 .rd-fill,
.doughnutwidget-insight .rd-progress.p88 .rd-fill,
.doughnutwidget-insight .rd-progress.p89 .rd-fill,
.doughnutwidget-insight .rd-progress.p90 .rd-fill,
.doughnutwidget-insight .rd-progress.p91 .rd-fill,
.doughnutwidget-insight .rd-progress.p92 .rd-fill,
.doughnutwidget-insight .rd-progress.p93 .rd-fill,
.doughnutwidget-insight .rd-progress.p94 .rd-fill,
.doughnutwidget-insight .rd-progress.p95 .rd-fill,
.doughnutwidget-insight .rd-progress.p96 .rd-fill,
.doughnutwidget-insight .rd-progress.p97 .rd-fill,
.doughnutwidget-insight .rd-progress.p98 .rd-fill,
.doughnutwidget-insight .rd-progress.p99 .rd-fill,
.doughnutwidget-insight .rd-progress.p100 .rd-fill {
    border: 6px solid #919eab;
    width: 72px;
    height: 72px;
    clip: rect(0px, 36px, 72px, 0);
}
.doughnutwidget-insight .rd-progress .rd-slice{
    width: 72px;
    height: 72px;
}
.doughnutwidget-insight .rd-progress{
    width: 72px;
    height: 72px;
}
.doughnutwidget-insight .rd-progress:after{
    width: 56px;
    height: 56px;
}
.doughnutwidget-insight .rd-progress > span{
    padding-top: 24px;
    font-family: Graphik-Semibold;
}
.doughnutwidget-insight .rd-progress > span .metric-digit-text,
.doughnutwidget-insight .rd-progress > span .rdmetric-digit-text{
    font-family: Graphik-Semibold;
    font-size: 12px;
}
.doughnutwidget-layout .item .item-right .doughnut-name{
    color: rgb(33, 43, 54);
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 16px;
    display: inline-block;
}
.tilecard-wrapper .fourwidget-layout .owl-carousel .owl-item .item.gray-color .tile-metric-value,
.tilecard-wrapper.no-carousel .fourwidget-layout  .item.gray-color .tile-metric-value{
font-size: 20px;
color: rgb(33, 43, 54);
}
.fourwidget-layout .owl-carousel .owl-item .item:hover .item-left .tile-metric-value,
.fourwidget-layout .owl-carousel .owl-item .item:hover .item-left .tile-metric-desc,
  .tilecard-wrapper.no-carousel .fourwidget-layout .item:hover .item-left .tile-metric-value, 
.tilecard-wrapper.no-carousel .fourwidget-layout .item:hover .item-left .tile-metric-desc{
    color: rgb(255, 255, 255);
}
.custom-tilecard.dd-insights-card.ddinsights-dashboard .tilecard-wrapper .owl-carousel .owl-item{
    width: auto !important;
    height: 100%;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.doughnutwidget-insight{
    flex-direction: row;
    /* align-items: center; */
    width: 248px !important;
    height: 120px;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.doughnutwidget-insight .item-left,
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.doughnutwidget-insight .item-right{
display: flex;
align-items: center;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.doughnutwidget-insight .item-right{
    padding: 8px 16px 8px 8px;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.twoitem-widget,
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.threeitem-widget{
    width: 220px !important;
    height: 125px;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.twoitem-widget .item-top p{
    font-size: 16px !important;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget,
.custom-tilecard.dd-insights-card .tilecard-wrapper.no-carousel .item.fourlayout-widget{
    flex-direction: row;
    width: 310px !important;
    height: 136px;
   
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget .item-right,
.custom-tilecard.dd-insights-card .tilecard-wrapper.no-carousel .item.fourlayout-widget .item-right {
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget .tile-metric-value,
.custom-tilecard.dd-insights-card .tilecard-wrapper.no-carousel .item.fourlayout-widget .tile-metric-value{
    color: rgb(33, 43, 54);
font-family: Graphik-Semibold;
font-size: 20px;
font-weight: 600;
letter-spacing: -0.42px;
line-height: 24px;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget .tile-metric-value span,
.custom-tilecard.dd-insights-card .tilecard-wrapper.no-carousel .item.fourlayout-widget  .tile-metric-value span{
    font-family: Graphik-Medium;
font-size: 14px;
font-weight: 500;
letter-spacing: -0.29px;
line-height: 20px;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget .tile-metric-desc,
.custom-tilecard.dd-insights-card .tilecard-wrapper.no-carousel .item.fourlayout-widget .tile-metric-desc{
    color: rgb(33, 43, 54);
font-family: Graphik-Medium;
font-size: 12px;
font-weight: 500;
letter-spacing: 0px;
line-height: 16px;
display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow-wrap: break-word;
}
.custom-tilecard.dd-insights-card .tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget .tile-metric-name,
.custom-tilecard.dd-insights-card .tilecard-wrapper.no-carousel .item.fourlayout-widget .tile-metric-name{
    color: rgb(99, 115, 129);
font-family: graphik;
font-size: 12px;
font-weight: normal;
letter-spacing: 0px;
line-height: 16px;
}

.tilecard-wrapper .owl-carousel .owl-item  .item.fourlayout-widget:hover .item-left .tile-metric-value,
 .tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget:hover .item-left .tile-metric-desc{
    color: rgb(255, 255, 255);
 }
 /* MTC Pages Metric CSS Start */
 .mtcexport-download{
    position: absolute;
    right: 0;
    top:-40px
  }
 .mtcexport-download .btn{
    color: #018eff;
    font-size: 24px;
  }
  .mtclayout-title{
 color: rgb(33, 43, 54);
font-family: Graphik-Medium;
font-size: 20px;
font-weight: 500;
letter-spacing: 0px;
line-height: 34px;
margin-bottom: 0;
  }
  .mtc-list-icon.list-icon{
   top: -30px;
   margin-top: 0;
   position: absolute;
   right: 55px;
  }
  .mtc-list-icon.list-icon i{
    font-size: 24px;
  }
  .mtclayout-tilecard .tilecard-wrapper .list-icon span i{
font-size: 18px;
  }
  .custom-tilecard.mtclayout-tilecard  .tilecard-wrapper .owl-carousel .owl-item {
   height: 160px;
   width: 248px !important;
   margin-right: 16px !important;
  }
  .custom-tilecard.mtclayout-tilecard .tilecard-wrapper .owl-carousel .owl-item .item,
  .mtclayout-tilecard .tilecard-wrapper.no-carousel .item{
    flex-direction: column;
  }
  .mtclayout-tilecard .tilecard-wrapper.no-carousel .item{
    box-shadow: 0 2px 8px 0 rgb(33 43 54 / 10%);
  }
  .custom-tilecard.mtclayout-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .item-left, 
  .mtclayout-tilecard .tilecard-wrapper.no-carousel .item .item-left{
   width: 100%;
   padding: 16px 16px 8px 16px;
   height: 80px;
  }
  .custom-tilecard.mtclayout-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .item-right, 
  .mtclayout-tilecard .tilecard-wrapper.no-carousel .item .item-right{
   width: 100%;
   padding: 8px 16px 40px 16px;
   height: 80px;
  }
  .mtclayout-tilecard .owl-carousel .owl-item .item.gray-color:hover .item-left,
  .mtclayout-tilecard .tilecard-wrapper.no-carousel .item.gray-color:hover .item-left{
   background: linear-gradient(-45deg, rgb(99, 115, 129) 0%, rgb(145, 158, 171) 100%);
   border-radius: 8px 8px 0px 0px;
  }
  .mtclayout-tilecard .owl-carousel .owl-item .item.gray-color .item-left .tile-metric-value,
  .mtclayout-tilecard .tilecard-wrapper.no-carousel .item.gray-color .item-left .tile-metric-value{
   color: rgb(33, 43, 54);
   font-family: Graphik-Medium;
   font-size: 14px;
   font-weight: 500;
   letter-spacing: 0px;
   line-height: 16px;
  }
  .mtclayout-tilecard .owl-carousel .owl-item .item.gray-color .item-left .tile-metric-desc,
  .mtclayout-tilecard .tilecard-wrapper.no-carousel .item.gray-color .item-left .tile-metric-desc{
 color: rgb(33, 43, 54);
font-family: Graphik-Medium;
font-size: 12px;
font-weight: 500;
letter-spacing: 0px;
line-height: 16px;
display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   word-break: break-word;
   overflow-wrap: break-word;
  }
  .mtclayout-tilecard .owl-carousel .owl-item .item:hover .item-left .tile-metric-value, 
  .mtclayout-tilecard .owl-carousel .owl-item .item:hover .item-left .tile-metric-desc, 
  .mtclayout-tilecard .tilecard-wrapper.no-carousel .item:hover .item-left .tile-metric-value,
  .mtclayout-tilecard .tilecard-wrapper.no-carousel .item:hover .item-left .tile-metric-desc{
   color: rgb(255, 255, 255);
  }
  .mtclayout-tilecard .owl-carousel .owl-item .item.gray-color .item-right .tile-metric-name,
  .mtclayout-tilecard .tilecard-wrapper.no-carousel .item.gray-color .item-right .tile-metric-name{
   color: rgb(99, 115, 129);
   font-family: graphik;
   font-size: 12px;
   font-weight: normal;
   letter-spacing: 0px;
   line-height: 16px;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   word-break: break-word;
   overflow-wrap: break-word;
  }
  .custom-tilecard .owl-carousel .owl-nav [class*="owl-"],
  .batView .tilecard-border .custom-tilecard  .owl-carousel .owl-nav [class*="owl-"]{
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      height: 20px;
      width: 12px;
  }
  .custom-tilecard .owl-carousel .owl-nav .owl-next:before,
  .custom-tilecard .owl-carousel .owl-nav .owl-prev:before,
  .batView .tilecard-border .custom-tilecard .owl-carousel .owl-nav .owl-next:before,
  .batView .tilecard-border .custom-tilecard .owl-carousel .owl-nav .owl-prev:before {
	width: 12px;
	content: "";
	height: 20px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -6px 0 0 -6px;
	background-size: 100%;
    transform: none;
  }
  .batView .tilecard-border .custom-tilecard .owl-carousel .owl-nav .owl-next:before,
  .batView .tilecard-border .custom-tilecard .owl-carousel .owl-nav .owl-prev:before {
    margin: -20px 0 0 -6px;
  }
  .custom-tilecard .owl-carousel .owl-nav .owl-prev:before,
  .batView .tilecard-border .custom-tilecard  .owl-carousel .owl-nav .owl-prev:before {
	background: transparent url(../Images/Left_arrow.svg) no-repeat scroll center;
  }
  .custom-tilecard .owl-carousel .owl-nav .owl-next:before,
  .batView .tilecard-border .custom-tilecard  .owl-carousel .owl-nav .owl-next:before{
    background: transparent url(../Images/Right_arrow.svg) no-repeat scroll center;
  }
  .custom-tilecard.mtclayout-tilecard .tilecard-wrapper.no-carousel{
      display: flex;
      flex-wrap: wrap;
  }
  .custom-tilecard.mtclayout-tilecard .tilecard-wrapper.no-carousel .item{
    margin: 0rem 1.5rem 1.5rem 0rem;
    width: 248px !important;
  }
  /* Dashboard Metric Tile Changes */
  .dashboard-httitle.ht-title{
    color: rgb(33, 43, 54);
    font-family: Graphik-Medium;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .category-references.dashboard-category-references.bo-references{
     top:-48px;
     right: 40px;
}
.category-references.dashboard-category-references.bo-config-references {
    top: -14px;
    right: 18px;
}
.configurator-bo .custom-tilecard:nth-child(2) {
    padding-top: 18px;
}
.dashboard-references-dropdown{
    position: absolute;
    left: auto;
    right: 40px;
    top: -50px;
    display: flex;
    align-items: center;
}

.custom-tilecard.dd-insights-card.ddinsights-dashboard .tilecard-wrapper .owl-carousel .owl-item .item .item-top,
.custom-tilecard.dd-insights-card.ddinsights-dashboard .tilecard-wrapper .owl-carousel .owl-item .item .item-bottom{
    height: 52px;
}
.custom-tilecard.dd-insights-card.ddinsights-dashboard .tilecard-wrapper .owl-carousel .owl-item .item .item-top p{
    font-size: 20px;
    padding: 16px 16px 8px 16px;
}
.custom-tilecard.dd-insights-card.ddinsights-dashboard .tilecard-wrapper .owl-carousel .owl-item .item .item-bottom p{
    font-family: Graphik-Medium;
    font-weight: 500;
    padding: 8px 16px 16px 16px;
    color: rgb(33, 43, 54);
}
.custom-tilecard.ddinsights-dashboard .tilecard-wrapper .owl-carousel .owl-item .item .item-left,  
.ddinsights-dashboard .tilecard-wrapper.no-carousel .item .item-left{
     padding: 16px 8px 16px 16px;
 }
 .custom-tilecard.ddinsights-dashboard .tilecard-wrapper .owl-carousel .owl-item .item .item-right,
 .ddinsights-dashboard .tilecard-wrapper.no-carousel .item .item-right{
    padding: 16px 16px 16px 8px;
 }
 .custom-tilecard .tilecard-wrapper.kpiimpacts-list.no-carousel .item{
     margin: 0 16px 16px 0;
 }
 .tilecard-wrapper .owl-carousel .owl-item .item .metric-lead-lagg p span.lead-lagg-label,
.tilecard-wrapper.no-carousel .item .metric-lead-lagg p span.lead-lagg-label{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow-wrap: break-word;
}
.tilecard-wrapper .owl-carousel .owl-item .item .metric-lead-lagg p span.lead-lagg-val,
.tilecard-wrapper.no-carousel .item .metric-lead-lagg p span.lead-lagg-val{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow-wrap: break-word;
}
.configurator-references-dropdown-tabs{
    position: absolute;
    right: 0;
    top: 12px;
    z-index: 123;
}
.configurator-references-dropdown{
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 123;
}
.custom-tilecard .tilecard-wrapper .owl-carousel .owl-item .item.mtc-otherinsights .item-left,
 .custom-tilecard .tilecard-wrapper .owl-carousel .owl-item .item.mtc-otherinsights .item-right, 
.tilecard-wrapper.no-carousel .item.mtc-otherinsights .item-left, 
.tilecard-wrapper.no-carousel .item.mtc-otherinsights .item-right{
padding: 24px 8px 16px 16px;
}
.tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget .mtcinsight-value,
.tilecard-wrapper.no-carousel .item.fourlayout-widget .mtcinsight-value{
    color: #212b36;
    font-family: Graphik-Semibold;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -.42px;
    line-height: 20px;
    margin-bottom: 8px;
}
.tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget .mtcinsights-desc,
.tilecard-wrapper.no-carousel .item.fourlayout-widget .mtcinsights-desc{
    color: #212b36;
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 8px;
}
.tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget .mtcinsight-value span,
.tilecard-wrapper.no-carousel .item.fourlayout-widget .mtcinsight-value span{
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -.29px;
    line-height: 20px;
    margin-bottom: 8px;
}
.tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget .mtcinsights-name,
.tilecard-wrapper.no-carousel .item.fourlayout-widget .mtcinsights-name{
    color: #637381;
    font-family: graphik;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 8px;
}
.tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget:hover .mtcinsight-value,
.tilecard-wrapper.no-carousel .item.fourlayout-widget:hover .mtcinsight-value,
.tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget:hover .mtcinsights-desc, 
.tilecard-wrapper.no-carousel .item.fourlayout-widget:hover .mtcinsight-desc
.tilecard-wrapper .owl-carousel .owl-item .item.fourlayout-widget:hover .mtcinsight-value span,
.tilecard-wrapper.no-carousel .item.fourlayout-widget:hover .mtcinsight-value span{
    color: #fff;
}
.ddinsights-dashboard .doughnutwidget-insight .doughnut-name{
    color: rgb(33, 43, 54);
    font-family: Graphik-Medium;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 16px;
    display: inline-block;
}
.metricright-aligncenter{
display: flex;
align-items: center;
}

.custom-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .item-right.middle-align,
 .tilecard-wrapper.no-carousel .item .item-right.middle-align{
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .custom-tilecard.dd-insights-card.ddinsights-dashboard .tilecard-wrapper .owl-carousel .owl-item .data-processed.ddnew-empty{
    min-width: 240px;
}
/* Pyramid UI starts */
.pyramid-chart{
    height:200px;
    left:35%;
    padding:1px 2px 25px;
    position:relative;
    top:60px;
    transform: translate(-50%,-50%) skewy(0deg) scale(0.5);
    width:500px;
}
.pyramid-chart::before,.pyramid-chart::after{
    background-origin:border-box;
    border-top:335px solid #fff;
    bottom:0;
    content:"";
    pointer-events:none;
    position:absolute;
    top:0;
}
.pyramid-chart::before{
    border-right:250px solid transparent;
    left:0;
    z-index:5;
}
.pyramid-chart::after{
    border-left:250px solid transparent;
    right:0;
    z-index:5;
}
.pyramid-chart>div{
    position:relative;
    margin-bottom: 50px;
}
.pyramid-chart>div::before,.pyramid-chart>div::after{
    border-top:63px solid;
    bottom:-62px;
    content:"";
    pointer-events:none;
    position:absolute;
}
.pyramid-chart>div::before{
    border-left:250px solid transparent;
    left:0;
}
.pyramid-chart>div::after{
    border-right:248px solid transparent;
    right:0;
}
.pyramid-chart #level1{
    background:linear-gradient(90deg,rgb(117, 0, 192) 50%,rgb(161, 0, 255) 50%);
    z-index:4;
}
.pyramid-chart #level1::before{
    color:rgb(117, 0, 192);
}
.pyramid-chart #level1::after{
    color:rgb(161, 0, 255);
}
.pyramid-chart #level2{
    z-index:3;
}
.pyramid-chart #level2::before{
    color:rgb(1, 142, 255);
    transform: skewY(16deg);
    top: -10px;
    left: 0;
    border-top: 78px solid;
    border-left: 246px solid;
}
.pyramid-chart #level2::after{
    color:rgb(0, 186, 255);
    transform: skewY(-16deg);
    top: -10px;
    border-top: 78px solid;
    border-right: 250px solid;
}
.pyramid-chart>div>*{
    transform:skewy(5deg);
}
 .machine-label{
    right: 62px !important;
}
.hm-ratio-display .input-radio-value{
    background: rgb(255, 255, 255);
    border-radius: 4px;
    border: 1.5px solid rgb(214, 218, 222);
    height: 32px;
    width: 52px;
    color: rgb(33, 43, 54);
    font-family: Graphik;
    font-size: 14px;
    padding: 8px 0 8px 8px;
}
.input-radio-value::-webkit-inner-spin-button, 
.input-radio-value::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.hm-ratio-display .input-radio-value:focus{
    background: rgba(1, 142, 255, 0.05);
    border: 1.5px solid rgb(1, 143, 255);
}
.hm-ratio-display .ratio-updated-text{
    color: rgb(126, 195, 68);
    font-family: 'Graphik-Medium';
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
}
   .human-label,
   .machine-label{
    color: rgb(33, 43, 54);
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    height: 34px;
    right: 60px;
    position: relative;
    z-index: 6;
    margin-right:-24px;
  }
.human-machine-labels div>span {
    position: absolute;
    color: rgb(33, 43, 54);
    font-size: 14px;
    font-family: Graphik-Medium;
    font-weight: 500;
    letter-spacing: 0px;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
}
.hm-ratio-display .ratio-value{
    color: rgb(1, 142, 255);
    font-size: 16px;
    font-family: Graphik-Medium;
    font-weight: 500;
    top: 1.5px;
    position: relative;
  }
  .machine-label span{
    color: rgb(1, 142, 255)
  }
  .hm-ratio-display img{
    width: 14.6px
  }
  .popover-body .close img{
    width: 10px;
  }
.hm-ratio-display .ratio-update{
      top:10px;
  }
.ratioupdate-btns img{
    cursor: pointer;
}
  .human-machine-labels{
      float:right;
      height:175px;
  }
  .hmradio-btns label.form-check {
    padding-left: 1.5rem;
    display: inline;
  }
  .hmradio-btns .form-check .checkmark {
    height: 22px;
    width: 22px;
    border: 1.5px solid #c4cdd5;
  }
  .hmradio-btns .form-check input[type="radio"] + .checkmark:after {
    height: 14px;
    width: 14px;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .hmradio-btns .form-check-label {
    color: rgb(99, 115, 129);
    font-size: 14px;
    font-family: graphik;
    font-weight: normal;
    letter-spacing: -0.25px;
    vertical-align: middle;
    position: relative;
    line-height: 22px;
    bottom: 0;
  }
  .hmradio-btns .form-check-label.active {
    color: rgb(33, 43, 54);
    font-family: Graphik-Medium;
    font-weight: 500;
  }
  .hmradio-btns{
      margin: 1rem 0;
  }
  .hmradio-btns h5{
    color: rgb(99, 115, 129);
    font-family: Graphik-Medium;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    letter-spacing: -0.29px;
    line-height: 12px;
  }
  .hm-ratio-display .refresh-icon{
    position: relative;
    top: -2px;
    margin-left: 10px !important;
    cursor: pointer;
  }
  .hmratio-toggle-btn{
      display: flex;
      margin-right: 1rem;
      align-items: center;
  }
  .hmratio-toggle-btn p{
    color: rgb(99, 115, 129);
    font-family: graphik;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.25px;
    line-height: 14px;
    margin-bottom: 0;
    margin-right: 8px;
  }
  .custom-toggle-tickbtn .onoffswitch{
      width: 36px;
  }
  .custom-toggle-tickbtn .onoffswitch .onoffswitch-label {
    border: none;
}
.custom-toggle-tickbtn  .onoffswitch .onoffswitch-inner:before, 
.custom-toggle-tickbtn  .onoffswitch .onoffswitch-inner:after {
    height: 24px;
}
.custom-toggle-tickbtn .onoffswitch .onoffswitch-switch {
    top: 2px;
    width: 20px;
    height: 20px;
    right: 14px;
}
.custom-toggle-tickbtn.toggle-group.toggle-tick input[type="checkbox"]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch:before {
    font-size: 24px;
    left: -2px;
    top: -8px;
}
.custom-toggle-tickbtn  .onoffswitch .onoffswitch-inner:after {
    background-color: #ddd;
}
.hmratio-toggle-btn .__react_component_tooltip{
    max-width: 210px;
    text-align: center;
    padding: 8px;
}
.hmratio-toggle-btn .__react_component_tooltip span{
color: rgb(255, 255, 255);
font-family: Graphik-Medium;
font-size: 14px;
font-weight: 500;
letter-spacing: -0.25px;
line-height: 18px;
text-align: center;
}
.hm-ratio-display .__react_component_tooltip{
    min-width: 190px;
    padding: 8px !important;
}
.hm-ratio-display .__react_component_tooltip span{
    color: #fff;
}
.hmratioedit-btn{
    color :#919eab;
    margin-left:10px;
    cursor: pointer;
}
.legend-strike-through{
    text-decoration: line-through;
}
.doughnutwidget-insight .rd-progress.p1 .rd-bar,
.doughnutwidget-insight .rd-progress.p2 .rd-bar,
.doughnutwidget-insight .rd-progress.p3 .rd-bar,
.doughnutwidget-insight .rd-progress.p4 .rd-bar,
.doughnutwidget-insight .rd-progress.p5 .rd-bar,
.doughnutwidget-insight .rd-progress.p6 .rd-bar,
.doughnutwidget-insight .rd-progress.p7 .rd-bar,
.doughnutwidget-insight .rd-progress.p8 .rd-bar,
.doughnutwidget-insight .rd-progress.p9 .rd-bar,
.doughnutwidget-insight .rd-progress.p10 .rd-bar,
.doughnutwidget-insight .rd-progress.p11 .rd-bar,
.doughnutwidget-insight .rd-progress.p12 .rd-bar,
.doughnutwidget-insight .rd-progress.p13 .rd-bar,
.doughnutwidget-insight .rd-progress.p14 .rd-bar,
.doughnutwidget-insight .rd-progress.p15 .rd-bar,
.doughnutwidget-insight .rd-progress.p16 .rd-bar,
.doughnutwidget-insight .rd-progress.p17 .rd-bar,
.doughnutwidget-insight .rd-progress.p18 .rd-bar,
.doughnutwidget-insight .rd-progress.p19 .rd-bar,
.doughnutwidget-insight .rd-progress.p20 .rd-bar,
.doughnutwidget-insight .rd-progress.p21 .rd-bar,
.doughnutwidget-insight .rd-progress.p22 .rd-bar,
.doughnutwidget-insight .rd-progress.p23 .rd-bar,
.doughnutwidget-insight .rd-progress.p24 .rd-bar,
.doughnutwidget-insight .rd-progress.p25 .rd-bar,
.doughnutwidget-insight .rd-progress.p26 .rd-bar,
.doughnutwidget-insight .rd-progress.p27 .rd-bar,
.doughnutwidget-insight .rd-progress.p28 .rd-bar,
.doughnutwidget-insight .rd-progress.p29 .rd-bar,
.doughnutwidget-insight .rd-progress.p30 .rd-bar,
.doughnutwidget-insight .rd-progress.p31 .rd-bar,
.doughnutwidget-insight .rd-progress.p32 .rd-bar,
.doughnutwidget-insight .rd-progress.p33 .rd-bar,
.doughnutwidget-insight .rd-progress.p34 .rd-bar,
.doughnutwidget-insight .rd-progress.p35 .rd-bar,
.doughnutwidget-insight .rd-progress.p36 .rd-bar,
.doughnutwidget-insight .rd-progress.p37 .rd-bar,
.doughnutwidget-insight .rd-progress.p38 .rd-bar,
.doughnutwidget-insight .rd-progress.p39 .rd-bar,
.doughnutwidget-insight .rd-progress.p40 .rd-bar,
.doughnutwidget-insight .rd-progress.p41 .rd-bar,
.doughnutwidget-insight .rd-progress.p42 .rd-bar,
.doughnutwidget-insight .rd-progress.p43 .rd-bar,
.doughnutwidget-insight .rd-progress.p44 .rd-bar,
.doughnutwidget-insight .rd-progress.p45 .rd-bar,
.doughnutwidget-insight .rd-progress.p46 .rd-bar,
.doughnutwidget-insight .rd-progress.p47 .rd-bar,
.doughnutwidget-insight .rd-progress.p48 .rd-bar,
.doughnutwidget-insight .rd-progress.p49 .rd-bar,
.doughnutwidget-insight .rd-progress.p50 .rd-bar {
   clip: rect(0px, 40px, 80px, 0);
}
.__react_component_tooltip.wo-metrictooltip{
   max-width: 268px;
}

/* Media queries for upto 1420px Resolution Start*/
@media screen and (max-width: 1280px) {
    .custom-tilecard .tilecard-wrapper .owl-carousel .owl-item{
        height: 150px;
        width: 255px !important;
    }
    .custom-tilecard.ddtile-wrapper .tilecard-wrapper.no-carousel .item,
    .custom-tilecard.hm-tilecard .tilecard-wrapper.no-carousel .item{
        height: 150px;
        width: calc(274px - 6px) !important;
    }
    .dashboard-component .insightsNew .custom-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .item-right,
    .dashboard-component .insightsNew .tilecard-wrapper.no-carousel .item .item-right{
        padding: 16px 8px 16px 16px;
    }
    .dashboard-component .insightsNew .custom-tilecard .tilecard-wrapper .owl-carousel .owl-item .item .item-right.middle-align,
    .dashboard-component .insightsNew .tilecard-wrapper.no-carousel .item .item-right.middle-align{
        padding: 30px 8px 16px 16px;
    }
    .tilecard-wrapper .owl-carousel .owl-item .item .dropdown .dropdown-toggle, 
    .tilecard-wrapper.no-carousel .item .dropdown .dropdown-toggle, 
    .service-component-list .service-component .dropdown .dropdown-toggle,
    .toolsListCard .dropdown .dropdown-toggle, 
    .common-ellipsis-dropdown .dropdown .dropdown-toggle{
        width: 36px;
        height: 36px;
    }
    .tilecard-wrapper.kpiimpacts-list.no-carousel .dropdown:hover {
        background: transparent;
    }
    .initiative-kpiimpacts.custom-tilecard .tilecard-wrapper.no-carousel .item{
        width: 252px !important;
        height: 150px;
    }
   .custom-tilecard.mtclayout-tilecard .tilecard-wrapper .owl-carousel .owl-item {
    height: 150px;
    width: 256px !important;
   }
}
/* Media queries for upto 1420px Resolution End*/
.customtilecard-tooltip{
    display: none;
    position: absolute;
    bottom: -35px;
    background-color: #1c262f!important;
    color: #fff!important;
    max-width: 268px;
    width: 100%;
    font-family: graphik;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    padding: 8px 12px;
    margin-top: 10px;
    z-index: 9999;
    }
    .customtilecard-tooltip:after{
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: #000;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    }
    .metric-datalist .tilecard-wrapper .item:hover .customtilecard-tooltip{
    display: block;
    }