/* Work Orchestration Enchanements Start */
.work-orchestration .nav-tabs .nav-link {
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  color: #919eab;
  position: relative;
  padding: 0.5rem 0rem;
  margin-right: 2rem;
  border: none;
}
.work-orchestration .nav-tabs .nav-link.active {
  border-bottom: none;
  border: none;
}
.work-orchestration .nav-tabs .nav-link.active:after {
  content: " ";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-radius: 3px 3px 0px 0px;
  height: 4px;
  background-color: #018eff;
}
.work-orchestration .tab-content {
  border-radius: 8px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}
.work-orchestration .wo-tabcontent {
  margin: 15px 0;
  padding: 20px;
}
.configurator-timeline .timeline-overlay {
  width: 100%;
}
.work-orchestration .wo-tabcontent .timeline-highlight {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1300px) {
  .work-orchestration .timeline-box {
    width: 215px;
  }
  .work-orchestration .timeline-content {
    width: 215px;
  }
  .work-orchestration .timeline-box.active .timeline-content .float-icon {
    top: 12%;
  }
}
.work-orchestration .timeline-box {
  width: calc(25% - 40px);
  margin: 4px 20px 50px;
}

.work-orchestration .configurator-timeline .timeline-box {
  width: calc(33% - 40px);
}
.work-orchestration .timeline-content {
  /* width: 200px; */
  width: auto;
  max-width: 250px;
  padding: 15px;
  height: 112px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}
.work-orchestration .timeline-content.timeline-active {
  background: rgb(161, 0, 255);
  color: #ffffff;
}
.work-orchestration .timeline-content.timeline-active span.num-tools {
  background-color: #fff;
  color: rgb(161, 0, 255);
}
.work-orchestration .timeline-content h6 {
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  color: #212b36;
  letter-spacing: -0.25px;
  line-height: 16px;
  word-break: break-word;
  overflow-wrap: break-word;
}
.work-orchestration .timeline-content.timeline-active h6 {
  color: #ffffff;
}
.work-orchestration .timeline-content.timeline-active .rainbow-tools span {
  background: rgb(161, 0, 255);
  color: #fff;
  border: 1px solid #fff;
}
.work-orchestration .timeline-content p {
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  color: #919eab;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 100%;
  word-break: normal;
}
.work-orchestration .timeline-content .float-icon {
  display: none;
}
.work-orchestration .title-with-icon {
  position: relative;
}
.work-orchestration .configurator-timeline.front-door .timeline-box {
  pointer-events: none;
}
.work-orchestration .configurator-timeline.front-door .timeline-content {
  pointer-events: none;
}
@media (min-width: 641px) {
  .work-orchestration .timeline-row:first-child,
  .work-orchestration .timeline-row:last-child {
    background: url(../Images/circle.png) no-repeat scroll left 35%;
  }
  .work-orchestration .timeline-row:nth-of-type(odd):last-child {
    background-position: right 35%;
  }
  .work-orchestration .timeline-row:before {
    top: 37%;
  }
  .work-orchestration .timeline-row:after {
    width: 130px;
    height: 100.7%;
    top: 37%;
  }
  .work-orchestration .configurator-timeline .timeline-row:after {
    width: 190px;
    height: 100.9%;
    top: 37%;
  }
}
.work-orchestration .timeline-content .text-center img {
  display: none;
}
.work-orchestration .timeline-box.active .timeline-content {
  background: #ffffff;
  padding: 0px;
}
.work-orchestration .timeline-box.active .timeline-content .metric-content {
  display: none;
}
.work-orchestration .timeline-box.active .timeline-content .float-icon {
  display: block;
  position: relative;
  text-align: center;
  right: inherit;
  margin-right: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  top: 1%;
}
.work-orchestration .timeline-box.active .timeline-content .float-icon span {
  color: #a2a3a5;
}
.work-orchestration .timeline-box.active .timeline-content .float-icon span:first-child {
  margin-left: 0;
}
.work-orchestration .timeline-box.active .timeline-content .float-icon span svg {
  margin-top: 8px;
  font-size: 18px;
}
.work-orchestration .float-icon span {
  width: 48px;
  height: 48px;
  margin-left: 10px;
  background: rgb(161, 0, 255) !important;
  box-shadow: none;
  border: 1px solid #dfe3e8;
}
.work-orchestration .timeline-box.active .timeline-content span.num-tools {
  background: rgb(161, 0, 255);
  color: #ffffff;
}
.work-orchestration .timeline-box.disabled span.num-tools {
  background: #919eab;
  color: #fff;
}
.work-orchestration
  .timeline-box.disabled
  .timeline-content.timeline-active
  .rainbow-tools
  span.disable {
  background-color: #fff;
  border: 1px solid #919eab;
  color: #919eab;
}
.work-orchestration
  .timeline-box.disabled
  .timeline-content.timeline-active
  .rainbow-tools
  span.active {
  background-color: #fff;
  border: 1px solid #919eab;
  color: #919eab;
}

.work-orchestration .configurator-timeline .timeline-box.disabled {
  opacity: 1;
  background: transparent;
}
.work-orchestration .configurator-timeline .timeline-box.conf-timeline-box.disabled {
  pointer-events: all;
}
.work-orchestration .timeline-box.disabled .timeline-content {
  background: #f9fafb;
}
.work-orchestration .timeline-box.disabled .timeline-content h6 {
  color: #212b36;
  opacity: 0.5;
}
.work-orchestration .timeline-box.disabled .rainbow-tools span {
  color: #919eab;
  border: 1px solid #919eab;
}
/* Step Details Start */
.step-details {
  border-bottom: 1px solid #dfe3e8;
}
.stepdetails-carousel {
  width: 90%;
  margin: 0 auto;
  background-color: #fff;
}
.step-details .stepdetails-carousel .owl-carousel .owl-nav .owl-prev {
  left: -50px;
}
.step-details .stepdetails-carousel .owl-carousel .owl-nav .owl-next {
  right: -50px;
}
.step-details .stepdetails-carousel .owl-carousel .owl-stage-outer {
  margin: 0px;
}
.step-details .stepdetails-carousel .carousel-content p {
  white-space: normal;
}
.step-details .stepdetails-carousel .owl-stage {
  white-space: nowrap;
}
.step-details .stepdetails-carousel .owl-stage .owl-item {
  position: static !important;
  float: none !important;
  display: inline-block;
  vertical-align: top;
}
.step-details .owl-carousel .owl-nav [class*="owl-"] {
  border: 1px solid #c4cdd5;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  top: 34.5px;
  box-shadow: none;
}
.step-details .owl-carousel .owl-nav .owl-next {
  right: 0;
}
.step-details .owl-carousel .owl-nav .owl-prev {
  left: 0;
}
.step-details .owl-carousel .owl-nav [class*="owl-"] {
  top: 80px;
}

.step-details .owl-carousel .owl-stage-outer {
  margin: 0px 50px;
  padding: 13px 0px;
}

.step-details .carousel-content {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  font-family: graphik;
  color: #212b36;
  padding: 10px 10px;
  border: transparent;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 16px;
  height: 100px;
  width: 100%;
}
.step-details .carousel-content span.num-tools {
  float: left;
  position: relative;
  left: inherit;
  top: inherit;
  margin-right: 10px;
  background: transparent;
  padding: 0;
}
.step-details .carousel-content span.num-tools span {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background: rgb(161, 0, 255);
  align-items: center;
  color: #fff;
}
.step-details .carousel-content.active span.num-tools span {
  background: #fff;
  color: rgb(161, 0, 255);
}
.step-details .carousel-content:hover span.num-tools span {
  background: #fff;
  color: rgb(161, 0, 255);
}
.step-details .carousel-content p {
  margin-bottom: 0;
  font-size: 12px;
  width: calc(100% - 24px);
}
.step-details .carousel-content.active,
.step-details .carousel-content:hover,
.backArrow:hover {
  color: #ffffff;
  background: rgb(161, 0, 255);
  border: transparent;
  cursor: pointer;
}

.step-details .owl-carousel .owl-nav.disabled,
.step-details .owl-carousel .owl-dots.disabled {
  display: block;
}

.step-details .owl-dots {
  display: none;
}

.step-details .owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  /* padding: 0 6px; */
  width: 205px !important;
  margin-right: 14px !important;
}
.stepdetail-data {
  margin: 20px 0;
}
.stepdetail-data .stepdetails-content .card-link:before {
  display: none;
}
.stepdetail-data .stage-heading .num-tools {
  position: relative;
  float: left;
  top: -2px;
  left: inherit;
  margin-right: 10px;
  z-index: 1;
}
.wo-title {
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  color: #212b36;
}
.stepdetail-data .list-icon {
  top: 4%;
  right: 0;
}
.stepdetail-data .list-icon .help-icon,
.form-section .list-icon .help-icon,
.wo-tabcontent .list-icon .help-icon {
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  color: rgb(161, 0, 255);
  cursor: pointer;
}
.stepdetail-data .list-icon .help-icon svg,
.form-section .list-icon .help-icon svg,
.wo-tabcontent .list-icon .help-icon svg {
  font-size: 12px !important;
  margin-right: 3px;
  top: -1px;
  position: relative;
}
.tool-border {
  border-right: 1px solid #dfe3e8;
}
.tool-data,
.metric-data {
  text-align: center;
  display: table;
  height: 350px;
  width: 100%;
}
.wo-tabcontent .tool-data {
  height: auto;
}
.add-toolicon {
  display: table-cell;
  vertical-align: middle;
}
.add-toolicon .show-pop {
  background: rgb(244, 246, 248);
  padding: 25px;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}
.add-toolicon .btn-primary:hover,
.add-toolicon .btn-primary:active,
.add-toolicon .btn-primary:focus,
.add-toolicon .btn-primary:active:focus {
  background-color: rgb(244, 246, 248);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}
.add-toolicon .btn-primary:disabled {
  background-color: rgb(244, 246, 248);
  border-color: rgb(244, 246, 248);
  box-shadow: none;
}
.add-toolicon h6 {
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  color: #212b36;
}
.add-toolicon p,
.add-toolicon h6.tool-metric-empty {
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
  margin-bottom: 10px;
}
.add-toolicon .site-link {
  color: rgb(0, 147, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
}
.add-toolicon .show-pop .fa-plus {
  color: #637381;
}
.tool-popup .form-check svg.fa-info-circle {
  margin-left: 7px;
  top: -7px;
  position: relative;
}
@media (min-width: 768px) {
  .tooldata-pop .modal-dialog {
    max-width: 690px;
  }
}
.selecttool-list ul {
  margin: 0;
}
.selecttool-list .wo-title {
  margin-bottom: 0;
}
.selecttool-list ul li {
  border-top: 1px solid #dfe3e8;
}
.selecttool-list ul li ul li {
  border-top: none;
}
.selecttool-list ul .form-check {
  margin: 20px 0;
  padding-left: 2rem;
}
.selecttool-list ul .form-check .checkmark {
  top: 10px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.selecttool-list ul .form-check .checkmark:after {
  left: 7px;
  top: 2.5px;
}
.wo-title.active {
  color: #018eff;
}

.selecttool-list .form-check-label p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  margin-bottom: 0;
}
.tool-sublist li {
  display: inline-block;
  color: #637381;
  font-size: 12px;
  padding: 0 10px;
  position: relative;
}
.tool-sublist li:first-child {
  padding-left: 0;
}
.tool-sublist li:after {
  content: " ";
  position: absolute;
  width: 1px;
  height: 13px;
  right: 0;
  top: 2px;
  background: #637381;
}
.tool-sublist li:last-child::after {
  display: none;
}
.modal-btn.tool-btn {
  justify-content: space-between;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  border-style: dashed;
}

.upload-btn-wrapper .btn {
  font-family: Graphik-Medium;
  font-size: 14px;
  color: #018eff;
  position: absolute;
  top: 0;
  right: 0;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.metric-datalist .outcome-divider {
  width: 100%;
  margin-bottom: 15px;
}
.metric-datalist .outcome-divider h6 {
  color: #212b36;
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
}
.metric-datalist .outcome-bottom span {
  font-size: 12px;
}
/* Step Details End */
.metric-datalist {
  /* min-height: 300px;
    height: 300px;
    overflow: auto;
    padding: 10px; */
}
.toolsList {
  display: flex;
  flex-wrap: wrap;
  min-height: 300px;
  /* overflow: auto; */
}

.toolsListCard {
  width: 48%;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 188px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
  margin: 5px 0;
  padding: 0x;
  box-sizing: border-box;
  position: relative;
}
@media (min-width: 1366px) {
  .toolsListCard {
    width: 326px;
    margin-right: 15px;
  }
}

.toolsListCard:nth-child(odd) {
  margin-right: 15px;
}
.toolsListCardHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
}
.toolsCarrdInfo {
  padding: 8px 16px 0 16px;
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-bottom: 6px;
}
.toolsListCardHead .tools-name {
  color: rgb(1, 142, 255);
  font-family: graphik-semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.29px;
  line-height: 16px;
}
.toolsListCardHead .tools-type span {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(1, 142, 255);
  color: rgb(1, 142, 255);
  font-family: graphik-medium;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.25px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toolsListCard:hover .card-overlay,
.metric-datalist .outcome-divider:hover .card-overlay {
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius: 3px;
}
.toolsListCard .card-overlay button span {
  left: -15px;
}
.tooldata-pop .modal-btn .brder-2 {
  color: #637381;
}
/* WO Configurator Start */
.wo-tabcontent .orch-sidebar .rainbow-tools {
  padding: 0;
  display: flex;
}
.wo-tabcontent .rainbow-tools p {
  margin-right: 25px;
  margin-bottom: 15px;
}
.wo-tabcontent .timeline-box.active .timeline-content .rainbow-tools,
.wo-tabcontent .timeline-box:hover .timeline-content .rainbow-tools {
  opacity: 0;
}
.work-orchestration .configurator-timeline .timeline-content {
  height: 136px;
}
.wo-tabcontent .sidebar-cont .outcome-highlight {
  border: 1px solid #dce2e7;
  padding: 12px;
  margin-top: 10px;
}
.wo-tabcontent .sidebar-cont .radius-tools span {
  font-size: 12px;
}
.wo-tabcontent .sidebar-cont .radius-tools p {
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  color: #637381;
  letter-spacing: -0.25px;
  line-height: 22px;
}
.catalog-list {
  margin: 10px 0 20px;
}
.catalog-list h6 {
  color: #212b36;
  font-family: Graphik-Semibold;
  font-size: 12px;
  font-weight: 600;
}
.foundation-tools {
  display: flex;
}
.foundation-tools h6 {
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  color: #212b36;
  margin-right: 25px;
}
.foundation-tools p {
  border-radius: 20px;
  padding: 0px 15px 0px;
  border: 1px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
  margin-right: 10px;
  font-size: 12px;
  font-family: Graphik-Medium;
  display: flex;
  align-items: center;
  justify-content: center;
}
.work-orchestration .sidebar-cont .radius-tools i {
  margin-left: 5px;
  font-size: 16px;
  color: #a2b9c4;
  position: relative;
  top: 2px;
}
.work-orchestration .sidebar-cont .radius-tools span {
  font-family: Graphik-Medium;
}
.work-orchestration .sidebar-cont .radius-tools span.disabled-tool {
  color: #c9cfd4;
  border: 1px solid #c9cfd4;
}
.tool-popup .react-datepicker-wrapper {
  width: 100%;
}
.tool-popup .calender-placment {
  top: 22px;
}
.toolsListCard button.card-link {
  background: #f4f6f8;
  border: 1px solid #dfe3e8;
  border-radius: 50%;
}
.toolsListCard button.card-link::after {
  content: "\F0C1";
  font-family: "Font Awesome 5 Pro";
  font-size: 26px;
  color: rgb(99, 115, 129);
  line-height: 46px;
}
.metric-datalist .outcome-bottom span.metric-digit {
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  color: #212b36;
  letter-spacing: -0.35px;
  line-height: 28px;
}
.more-tools {
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  color: #018eff;
  display: flex;
  justify-content: flex-end;
}
.add-step {
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  color: #018eff;
  cursor: pointer;
}
.timeline-active {
  background: #018eff;
  color: #ffffff;
}

.step-details .carousel-content.disabled {
  background: #f9fafb;
  color: #212b36;
  opacity: 0.5;
  pointer-events: none;
}

.step-details .stepdetails-carousel .owl-carousel .fd-stepdetails.disabled {
  pointer-events: none;
}

.step-details .carousel-content.disabled span.num-tools span {
  background: #919eab;
}
.stepdetail-data.disabled {
  background: #f9fafb;
  opacity: 0.5;
  pointer-events: none;
}
.stepdetails-content.disabled {
  background: #f9fafb;
  opacity: 0.5;
  pointer-events: none;
}
.tooldata-pop .modal-footer .btn.btn-default,
.tooldata-pop .modal-footer .btn.btn-primary {
  width: 120px;
  height: 42px;
}
.tooldata-pop .modal-footer .tool-btn > button:first-child {
  width: 160px;
}
.add-definition-delete-toggleBtn {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
.add-definition-delete-toggleBtn .onoffswitch .onoffswitch-label {
  border: none;
}
.add-definition-delete-toggleBtn .onoffswitch .onoffswitch-switch {
  top: -1px;
}
/* WO Configurator End */
.moretool-link {
  color: rgb(161, 0, 255) !important;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  display: block;
  cursor: pointer;
}
.moretool-popup .modal-title {
  display: block;
  width: 100%;
  margin-top: 15px;
}
.moretool-popup .title-with-icon h4 {
  margin-top: 8px;
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
}
.searchFilter input[type="search"] {
  background: #fff
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAIAAABL1vtsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAAJUSURBVDhPnZPfb9JQFMcPraysrjAmjIQSNohOQF4gBk1EnPFhTxr9J01MTGaiMVFjRDZHgmT4QJSBQpgDAbtWVgYrpcFbejFQVwx+Hnrv+d7e7/11jknqy6Bj0BX5RlWQACgn61y+yFAmPHIuUxaK8CW5Vy4J06Ym0s5evhcPOi5gQccfC7n2KfU6L6Kl0RyKWXJZFwD63eMO11PUcRMduBPf9NBqfxrNQml+fLN9cAZAOq5e24r4mckFO9XdnXz+WAaT2Xfr7ta63kW1kMqpx3u8ZPDHCLmWefu8eAYLK5v3E4FFrGoQAPV0jkf7d0WM5iPMbCwRd5HQ59PZMtbGEFCtlHoAi+5YyGi+Bh2+4bEDSIdHhSGWNAiuJaLrYrw+FivGWP2+ZYCh2GhgQYPgTtAtgtXu1OKZ2OxL6CuLp1qIQXcxBzRlxr0JCIZW1e5pW4tnoggdNYnI6XUJ1smgRmjW1aSazfBrpYUahvVoMYYAr9OFSqBVTv8YZaExXLZSQ2+x6g6gvJ2AgIXg7aAFXVJh50MBva4B3aPMK5RaYAlHNyisYdRjOSKRMDpNn0++eLevqzEVmTtIPX1fF4dAr29EL5FYHjMus0F992Umf6J2abvTv7bCrrrpXv2n8KvwrcmNdkdRpCQpwHgePbiunn3MRLEPxUo2kyyK0nTyqVBM+GYsKueeoFJC4bTLhIXGoH1YrZa/i10ttDB+79oVt220e0X4nHq239a5/GXxD85xmdcCoXeZL8FHkPZQ4mHUpj5thyvz/7MLDUUo5kp0KOahfwPCFwI2lQhyzAAAAABJRU5ErkJggg==)
    no-repeat scroll 20px !important;
  padding: 5px 30px 5px 40px !important;
  background-size: 14px !important;
  font-size: 14px;
  height: 32px;
  line-height: 13px;
}
.moretool-data {
  height: 400px;
  overflow-y: auto;
  min-height: 400px;
}
.moretool-data .catalog-list {
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
  padding: 15px;
  margin-bottom: 10px;
}
.stepdetails-content .comment-area {
  margin-bottom: 15px;
}
.comment-area textarea {
  resize: none;
  color: #919eab;
}

/* Work Orchestration Enchanements End */
.applicable-radio {
  margin-top: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.attribute-formrow {
  align-items: center;
}
.mt-10 {
  margin-top: 10px;
}
.timeline-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  z-index: 10;
}
.configurator-timeline .timeline-content:hover .timeline-overlay {
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius: 3px;
}
.configurator-timeline .timeline-content:hover {
  background: transparent;
}
.timeline-overlay p {
  line-height: 16px;
}
.timeline-content:hover .timeline-data span.num-tools {
  background: rgb(161, 0, 255);
  color: #ffffff;
}
.timeline-box.active .timeline-content .timeline-data span.num-tools {
  background: rgb(161, 0, 255);
  color: #ffffff;
}
.link-button {
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  color: #018eff;
}
.datepicker-label {
  width: 100%;
}
.toolCardFooter {
  display: flex;
  flex-direction: inherit;
  align-items: start;
  flex-wrap: nowrap;
  align-items: inherit;
  bottom: 0px;
  width: 100%;
  position: absolute;
  left: 0px;
  border-top: 1px solid #dfe3e8;
  height: 36px;
  right: 0;
}
.toolCardFooter .date-label {
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  padding-right: 8px;
}
.deployed-date {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
}
.work-orchestration .timeline-box.hoverIn .timeline-content {
  display: flex;
  justify-content: center;
}

/* Metric Migration Start */

.metric-migrationdata {
  display: flex;
}
.metric-migrationdata .tab-content {
  margin: 0;
  box-shadow: none;
  padding: 15px 0;
}
.work-orchestration .wo-tabcontent.metric-migration {
  padding: 0;
}
.metric-migrationdata-left {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid rgb(220, 226, 231);
}
.metric-migrationdata-right {
  width: 100%;
}
.metric-migrationdata-left .searchFilter input[type="search"] {
  width: 225px !important;
}
.metric-migrationdata-right .searchFilter input[type="search"] {
  width: 273px !important;
}
.allstep-data {
  padding: 15px 0;
  width: 250px;
  height: 450px;
  overflow-y: auto;
}
.allsteps-content {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  font-family: graphik;
  color: #212b36;
  padding: 10px 10px;
  border: transparent;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 16px;
  min-height: 60px;
  margin-bottom: 10px;
  width: 225px;
}

.step-count span {
  font-family: graphik-medium;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 10px;
  text-align: center;
  color: #637381;
  border-radius: 50%;
  border: 1px solid #c4cdd5;
}

.allsteps-content p {
  margin-bottom: 0;
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
}
.allsteps-content:hover,
.allsteps-content.active {
  background-color: #018eff;
}
.allsteps-content:hover p,
.allsteps-content.active p {
  color: #ffffff;
}
.allsteps-content:hover .step-count span,
.allsteps-content.active .step-count span {
  background-color: #ffffff;
  color: #018eff;
  border: 1px solid #ffffff;
}
.allsteps-content.disabled {
  background-color: #f9fafb;
  border: 1px solid #dfe3e8;
  pointer-events: none;
}
.allsteps-content.disabled p {
  opacity: 0.5;
}
.allsteps-content.disabled .step-count span {
  color: #ffffff;
  position: relative;
  border: none;
}
.allsteps-content.disabled .step-count span::after {
  content: "\F058";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: 0;
  right: 0;
  color: #7ec344;
  font-size: 20px;
}
.metric-checkheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.metric-checkheader h6,
.metric-checklistwrapper h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}
.metric-checklistwrapper {
  padding: 0 20px 20px;
}
.metric-checklist {
  height: 450px;
  overflow-y: auto;
}

.metric-checklist ul {
  column-count: 2;
}
.metric-checklist li label {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
}
.metric-checklist li label input {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1.5px solid rgb(196, 205, 213);
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}
.metric-checklist label input:checked + span {
  font-weight: bold;
  color: #212b36;
}
.metric-checklist .form-check .checkmark {
  height: 20px;
  width: 20px;
}
.metric-checklist label input:checked + span {
  font-weight: bold;
  color: #212b36;
}
.metric-checklist .form-check-label {
  margin-left: 10px;
  bottom: 1px;
}
.metric-checklist .checkmark:after {
  left: 6px;
  top: 3px;
}
.metricfooter-btn {
  display: flex;
  padding: 10px;
  border: 1px solid #dfe3e8;
  justify-content: flex-end;
  border-left: none;
  border-right: none;
}
.metricfooter-btn .btn {
  width: 120px;
  border-radius: 20px;
  height: 40px;
}
.work-orchestration .metric-migrationdata-left .nav-tabs .nav-link {
  margin-right: 1rem;
}
.metrics-list {
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0;
}
.metric-list-item {
  height: 86px;
  position: relative;
  width: 33.3%;
  padding: 0 4px 0 16px;
  margin-bottom: 32px;
}
.metric-list-item::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 86px;
  background-color: #919eab;
  border-radius: 4px;
}
.metric-list-item h6 {
  margin: 0;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.22px;
}
.metric-list-item p {
  margin: 0;
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.metric-list-item span {
  position: absolute;
  bottom: 0;
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
}
.metricfooter-btn.migrate-btns {
  justify-content: space-between;
}
.submit-popup .modal-dialog {
  max-width: 480px;
}
.submit-popup .modal-header {
  padding: 20px 24px;
}
.submit-popup .modal-header .close {
  top: 20px;
}
.submit-popup .modal-body h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
}
.submit-popup .modal-footer {
  border: none;
  background-color: #fff;
}
.submit-popup .modal-btn .btn {
  height: 40px;
  width: 94px;
}
.addmetric-popup .modal-dialog {
  max-width: 799px;
}
.addmetric-popup .modal-header .close {
  display: none;
}
.modal-body .metric-checkheader {
  padding: 0 20px 20px;
}
.addmetric-popup .modal-body {
  padding: 1rem;
}
.btn-primary.button-get-data:disabled {
  background-color: #e9ebed;
}
.work-orchestration .step-details .stepdetails-carousel .carousel-content p {
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-family: "graphik";
}
.work-orchestration .step-details .stepdetails-carousel .carousel-content:hover p,
.step-details .carousel-content.active p {
  color: #fff;
  font-family: "graphik";
}
.datepicker-label-wrapper {
  display: block;
}
.wo-tabcontent .outcome-divider span.metric-digit-text {
  font-weight: normal;
}
.item.metricdisabled {
  pointer-events: none;
}
span.edit-step {
  position: absolute;
  top: -11px;
  right: -7px;
  width: 28px;
  cursor: pointer;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a2a3a5;
  background: #f4f6f8;
  border: 1px solid #dfe3e8;
}
.wo-tooldata-popup .modal-content {
  overflow: unset;
}
.select-tool-datepicker {
  text-align: right;
  margin: 12.5px 0;
}
.select-tool-datepicker p {
  color: rgb(95, 115, 130);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: right;
  letter-spacing: -0.25px;
  margin: 0;
}
.select-tool-datepicker .custom-date {
  position: absolute;
  right: 90px;
}
.select-tool-datepicker .react-datepicker-wrapper {
  line-height: 1.2;
}

.disabled {
  background: rgb(249, 250, 251);
  pointer-events: none;
}

.select-tool-datepicker .react-datepicker-wrapper .example-custom-input svg {
  height: auto;
  color: #018eff;
}
.selecttool-modal .modal-footer {
  padding: 1rem 2rem;
}
.selecttool-modal .modal-footer .btn {
  box-shadow: none;
  outline: none;
}
.selecttool-modal .modal-body {
  padding: 1rem;
}
.selecttool-modal .selecttool-list {
  max-height: 400px;
  overflow: scroll;
  padding: 0 1rem;
}
.selecttool-modal .modal-footer p span i {
  padding-left: 0px;
  padding-right: 8px;
}
.selecttool-modal .modal-header {
  padding: 22px 32px 6px;
}
.selecttool-modal .modal-content {
  overflow: unset;
}
.selecttool-modal .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.selecttool-modal .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  left: 50%;
}
.selecttool-modal .modal-footer p span {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.25px;
}
@media (min-width: 1366px) {
  .step-details .owl-carousel .owl-item {
    width: 256px !important;
  }
}
.selecttool-modal .modal-content {
  min-height: 510px;
}
.notool-message {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wotool-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 4px 1rem; */
}
.wotool-date .date-label {
  color: rgb(145, 158, 171);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: right;
  letter-spacing: -0.23px;
}
.disabled-calender .example-custom-input {
  opacity: 0.5;
  pointer-events: none;
}
.lighter-disabled .example-custom-input {
  opacity: 0.8;
}

/* New Z-Curve Changes Start*/
.wo-tabcontent .or-carousel-container .carousel-item {
  padding: 0;
}
.wo-tabcontent .or-carousel-container .carousel-control-next-icon,
.wo-tabcontent .or-carousel-container .carousel-control-prev-icon {
  display: none;
}
.wo-tabcontent .custom-tilecard .tilecard-wrapper {
  margin: 0;
}
.wo-tabcontent .custom-tilecard .tilecard-wrapper.no-carousel .item,
.wo-tabcontent .custom-tilecard.mtclayout-tilecard .tilecard-wrapper.no-carousel .item {
  width: 95% !important;
  margin: 7px auto;
}
.wo-tabcontent .or-carousel-container .carousel-indicators {
  bottom: -1.65rem;
}
.wo-tabcontent .oritems-seeall {
  color: #018eff;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  position: absolute;
  right: 6px;
  bottom: -16px;
  cursor: pointer;
}
.tools-wo-wrapper {
  padding: 12px;
}
.tools-wo-wrapper .tools-name {
  color: rgb(1, 142, 255);
  font-family: graphik-semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.29px;
}
.tools-wo-wrapper .tools-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 14px;
}
.tools-wo-wrapper .tools-type span {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(1, 142, 255);
  color: rgb(1, 142, 255);
  font-family: graphik-medium;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.25px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tools-wo-wrapper .tools-desc {
  padding: 8px 0 0;
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-bottom: 6px;
}
.wo-tabcontent .wotool-date-wrapper {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  bottom: 32px;
  padding: 0 12px;
}
.wo-viewAll-modal .modal-dialog {
  max-width: 72%;
}
.wo-viewAll-modal .modal-header {
  padding-top: 1rem;
}
.wo-viewAll-modal .modal-body {
  padding: 0rem 1em 1em;
}
.wo-viewAll-modal .custom-tilecard {
  overflow-y: auto;
  height: 80vh;
}
.wo-viewAll-modal .custom-tilecard .tilecard-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.wo-viewAll-modal .custom-tilecard .tilecard-wrapper.no-carousel .item {
  margin: 12px;
}
.wo-viewAll-modal .tilecard-wrapper .dropdown .dropdown-menu.show {
  /* bottom: auto!important;
    transform: translate3d(-132px,-94px,0)!important; */
}
.work-orchestration .wo-tabcontent .timeline-highlight.substep-container {
  padding: 10px;
}
.substep-container
  .conf-timeline-box.disabled
  .timeline-content:hover
  .timeline-data
  span.num-tools {
  background-color: #919eab;
  color: #fff;
}
.substep-container .conf-timeline-box.disabled .timeline-content:hover span {
  color: #a2a3a5;
}
.configurator-timeline.substep-container .timeline-content:hover {
  background-color: #fff;
}
.wo-tabcontent .substep-container .timeline-box:hover .timeline-content .rainbow-tools {
  opacity: 1;
}
.substep-container .timeline-content:hover span.edit-step,
.timeline-box.active .timeline-content span.edit-step {
  z-index: 11;
}
.configurator-timeline.substep-container .timeline-content.timeline-active:hover {
  background: rgb(161, 0, 255);
  color: #ffffff;
}
.mtc-component .configurator-timeline.substep-container .timeline-content.timeline-active:hover {
  background-color: #fff;
}
.mtc-component
  .work-orchestration
  .substep-container
  .timeline-content.timeline-active:hover
  span.num-tools {
  background: #008eff;
  color: #ffffff;
}
.work-orchestration .substep-container .timeline-content.timeline-active:hover span.num-tools {
  background-color: #fff;
  color: #008eff;
}
.tilecard-wrapper .tools-wo-wrapper {
  width: 100%;
}
.metric-datalist .dropdown-menu.show {
  z-index: 1;
}
.single-item-carousel .carousel-indicators {
  display: none;
}
/* New Z-Curve Changes End*/
.stepdetails-content .btn-primary:disabled:hover {
  background-color: transparent !important;
}

.EditWoStepDescription {
  background-color: #e9ecef;
}
