.calendar-header,
.react-datepicker,
a,
body,
input,
label,
p,
span,
td,
textarea {
  -moz-osx-font-smoothing: unset;
  -webkit-font-smoothing: unset;
  font-family: unset;
  font-weight: unset;
}

.custom-tooltip {
  position: relative;
}

.applicable-radio {
  margin-top: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.attribute-formrow {
  align-items: center;
}

.dropdown-menu.custom-dropdown-menu {
  left: auto !important;
  right: 0;
  transform: none !important;
  top: 26px !important;
}

.ioj-initiative p {
  word-break: normal;
}

.mt-10 {
  margin-top: 10px;
}

.custom-tooltip + span {
  position: absolute;
  background: #1c262f;
  padding: 10px 8px;
  border-radius: 2px;
  color: #fff;
  font-size: 11px;
  left: 0px;
  top: -40px;
  display: none;
}

.custom-tooltip + span:after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #1c262f;
  position: absolute;
  left: calc(20% - 10px);
  bottom: -9px;
  top: 35px;
}

.custom-tooltip:hover + span {
  display: block;
}

/* .calender-placment-wrapper {
    position: relative;
    border: 2px solid #e4e4e4;
    padding: 5px 5px 5px 10px;
    border-radius: 7px;
    width: 43%;
    display: inline-block;
    margin-right: 10px;
    height: 60px;
} */

.calender-placment {
  color: #a7a7a7;
  position: absolute;
  top: 18px;
  right: 30px;
  font-size: 25px;
}

/* .MuiInput-underline:before, .MuiInput-underline:after {
    display: none;
} */

.ioj-deal-report .outcome-highlight {
  padding: 25px 20px 25px 20px;
  margin: 12px 0;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.ioj-deal-report .outcome-highlight.dropdown-menu {
  padding: 8px 20px 8px 20px;
  margin: 0px !important;
  width: 92%;
}

.ioj-deal-report .form-check {
  width: 50%;
  display: inline-block;
}

.ioj-deal-report .form-check.width-alignmet {
  width: 100%;
  pointer-events: none;
}

.ioj-deal-report label.form-check {
  margin-bottom: 0px;
}

.initiativesButton {
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
  border: 2px solid #37a7ff;
  color: #008fff;
  background: rgb(255, 255, 255);
  font-weight: 400;
}

.IBWithBackground {
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
  border: 2px solid #37a7ff;
  color: white;
  background: #008fff;
  font-weight: 400;
  display: inline-block;
}

.IBDWithBackground {
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
  border: 2px solid #dee2e6;
  color: white;
  background: #dee2e6;
  font-weight: 400;
  display: inline-block;
}

.initiativesDisabledButton {
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
  border: 2px solid #dee2e6;
  color: #dee2e6;
  background: rgb(255, 255, 255);
  font-weight: 400;
}

.client-card .card-body {
  padding: 1.25rem 1.5rem;
}

.p-30 {
  padding: 30px;
}

.ioj-deal-report .textbox {
  height: 60px;
  width: 100%;
  border: 2px solid #dee2e6;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 10px;
  color: #695e5e;
}

.ioj-deal-report .text-area {
  height: 100px;
  width: 100%;
  border: 2px solid #dee2e6;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 10px;
  color: #695e5e;
}

.ioj-deal-report .text-dropdown {
  height: 60px;
  width: 100%;
  border: 2px solid #dee2e6;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 10px;
  color: #695e5e;
}

.ioj-deal-report .selectbox {
  border-radius: 20px;
  width: 100%;
  padding: 5px 15px 5px 15px;
  border: 2px solid #dee2e6;
  color: #695e5e;
  margin-bottom: 10px;
}

.ioj-deal-report .text-area:focus,
.ioj-deal-report .textbox:focus,
.ioj-deal-report .textbox.focus {
  border: 2px solid #37a7ff !important;
  outline: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .modal-header {
    display: block !important;
  }
}

.textareaDesign:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.category-status {
  padding-left: 35px;
}

.category-status.green:before,
.report-data .outcome-highlight > .green::before {
  position: absolute;
  content: "";
  background: #7ec344;
  width: 8px;
  height: 50%;
  border-radius: 15px;
  left: 10px;
  top: 8px;
}

.category-status.orange-clr:before,
.report-data .outcome-highlight > .orange-clr::before {
  background: #f58430;
  position: absolute;
  content: "";
  width: 8px;
  height: 50%;
  border-radius: 15px;
  left: 10px;
  top: 8px;
}

.color-alignment.orange-clr::before,
.color-alignment.red-clr::before,
.color-alignment.green::before {
  height: 70% !important;
  top: 10px !important;
}

.category-status.red-clr:before,
.report-data .outcome-highlight > .red-clr::before {
  background: #d5002f;
  position: absolute;
  content: "";
  width: 8px;
  height: 50%;
  border-radius: 15px;
  left: 10px;
  top: 8px;
}

.category-status-item-selected,
.category-status-item {
  padding-top: 5px;
  padding-bottom: 1px;
  position: relative;
}

.category-status-item:hover,
.category-status-item.active {
  background-color: #37a7ff;
  border-radius: 5px;
}

p.category-status {
  margin: 0px;
}

.category-status-item-selected::after {
  content: "\F107";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  right: 8px;
  top: 5px;
}

.ioj-deal-report .downArrow::after {
  content: "\F107";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  float: right;
}

.ioj-deal-report .downArrow1::after {
  content: "\F107";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  top: 0px;
  right: 0px;
}

.ioj-deal-report .outcome-highlight a {
  color: #695e5e !important;
}

.ioj-deal-report #categoryStatusmenu {
  margin: 0px;
}

.ioj-deal-report .checkmark {
  border: 1px solid #dee2e6;
  pointer-events: all !important;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-2 {
  padding-top: 2px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-r-20 {
  padding-right: 20px;
}

.m-l-12p {
  margin-left: 12%;
}

.m-l-20 {
  margin-left: 20px;
}

.p-l-5 {
  padding-left: 5px;
}

.m-15 {
  margin: 15px;
}

.display-block {
  display: block !important;
  margin-left: 3px;
}

.position-relative {
  position: relative;
}

.border-rgiht {
  border-right: 1px solid #dee2e6;
}

.report-data .trash-icon-alignment {
  float: right;
  margin-right: 25px;
  margin-top: 4px;
  color: #37a7ff;
}

.ioj-deal-report #accordionTwo h6 {
  font-weight: bold;
}

.ioj-deal-report .suggestions {
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dee2e6;
}

.ioj-deal-report .suggestions a {
  font-size: 14px;
  color: #695e5e;
  display: block;
  position: relative;
  padding-right: 15px;
}

.date-picker-holder {
  position: relative;
  width: 46%;
  display: inline-block;
  outline: none;
}

.custom-date-picker {
  background: none;
  position: relative;
  border: 2px solid #e4e4e4;
  padding: 5px 5px 5px 10px;
  border-radius: 7px;
  display: inline-block;
  margin-right: 10px;
  height: 60px;
  width: 100%;
}

.dp-hover {
  position: absolute;
  top: 18px;
  left: 15px;
  opacity: 1px;
}

.dP-withData {
  position: absolute;
  top: 5px;
  left: 12px;
  opacity: 1px;
  font-size: 12px;
}

.date-picker-holder:focus-within .dp-hover {
  top: 5px;
  left: 12px;
  font-size: 12px;
}

/* .date-picker-holder .react-datepicker-wrapper {
    width: 100%;
}

.date-picker-holder .react-datepicker__input-container, .react-datepicker__month-container {
    width: 100%;
    margin: 0px;
}

.ioj-deal-report .date-picker-holder .react-datepicker {
    width: 118% !important;
} */
.dateTime .react-datepicker-popper {
  width: 330px;
}

.report-box {
  height: 50px;
  width: 100%;
  background: #dfe9f0;
  position: relative;
  float: left;
  border: 1px solid #d8dde1;
  box-shadow: 0px 0px 10px 1px #c3c3c3;
}

@media screen and (min-width: 768px) {
  .report-box.deal-foundation::after,
  .report-box.deal-insights::after,
  .report-box.deal-automation::after {
    padding: 15px;
    content: "";
    height: 80px;
    width: 47px;
    background: #dfe9f0;
    transform: rotate3d(12, -3, 6, 228deg);
    position: absolute;
    top: -30px;
    right: -37px;
    border: 1px solid #c1cad0;
    box-shadow: 0px 0px 10px 1px #c3c3c3;
  }

  .deal-detail-foundation {
    margin-top: 235px;
    padding-right: 0px !important;
  }

  .deal-detail-insights {
    margin-top: 78px;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .deal-detail-automation {
    margin-top: 156px;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .deal-detail-intelligent {
    padding-left: 0px !important;
  }

  .deal-detailed-report {
    margin-top: -75px;
  }

  .detailed-report-box.right-box-1 {
    margin-top: 54px;
    height: 433px !important;
  }

  .detailed-report-box.right-box-2 {
    margin-top: 27px;
    height: 460px !important;
  }

  .detailed-report-box.right-box-3 {
    height: 487px !important;
  }
}

.report-box.deal-intelligent {
  margin-top: -11px;
}

.report-box.deal-insights {
  margin-top: 16px;
}

.report-box.deal-automation {
  margin-top: 43px;
  background: #f8fcff !important;
}

.report-box.deal-foundation {
  margin-top: 70px;
}

.report-box.deal-foundation > p,
.report-box.deal-insights > p,
.report-box.deal-intelligent > p {
  font-weight: bold;
  padding: 12px;
}

.detailed-report-header {
  text-align: center;
}

.report-box.deal-automation > p {
  font-weight: bold;
  padding: 12px;
  color: #1f91ec;
}

.report-box + div {
  margin-top: 120px;
  box-shadow: 5px 0 30px -22px #888;
  background: rgb(255, 255, 255);
  min-height: 400px;
  padding-top: 20px;
}

.deal-automation + div {
  margin-top: 85px;
}

.deal-summary + p {
  font-size: 13px;
}

.deal-insights + div {
  margin-top: 65px;
}

.deal-intelligent + div {
  margin-top: 40px;
}

.deal-summary.green {
  position: relative;
}

.deal-summary.green::before {
  color: #7ec344;
  height: 8px;
  width: 8px;
  background: #7ec344;
  content: "";
  position: absolute;
  border-radius: 20px;
  left: 3px;
  top: 6px;
}

.deal-summary.orange-clr::before {
  color: #f58430;
  height: 8px;
  width: 8px;
  background: #f58430;
  content: "";
  position: absolute;
  border-radius: 20px;
  left: 3px;
  top: 6px;
}

.deal-summary.red-clr::before {
  color: #d5002f;
  height: 8px;
  width: 8px;
  background: #d5002f;
  content: "";
  position: absolute;
  border-radius: 20px;
  left: 3px;
  top: 6px;
}

p.deal-summary {
  position: relative;
  font-size: 14px;
  color: #708a9c;
  font-weight: bold;
  margin-bottom: 5px !important;
}

.report-box > p > span > img {
  height: 20px;
  width: 17px;
}

.report-box > p > span.imageAlignment {
  padding: 5px;
  border-radius: 20px;
  border: 2px solid skyblue;
  box-shadow: 0px 0px 0px 3px skyblue inset;
}

.report-box > p > span.imageAlignment + span {
  padding-left: 10px;
}

.detailed-report-box {
  width: 100%;
  min-height: 250px;
  border: 1px solid #3aa2dd;
  position: relative;
  background: #3aa2dd;
  border: 1px solid #83bcdd;
  cursor: pointer;
}

.deal-detail-foundation > .detailed-report-box {
  background: #53b4eb;
}

.deal-detail-insights > .detailed-report-box {
  background: #008dd3;
}

.deal-detail-automation > .detailed-report-box {
  background: #3aa2dd;
}

.deal-detail-intelligent > .detailed-report-box {
  background: #006ba8;
}

.detailed-report-box > p {
  padding: 10% 5% 5% 10%;
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-bottom: 0px;
}

.detailed-report-box > div > p {
  color: white;
  padding-left: 25px;
}

.detailed-report-box-cross {
  height: 48px;
  width: 98px;
  transform: rotate(127deg);
  position: absolute;
  top: 0px;
  left: -39px;
  background: white;
  border-top: 1px solid #c3c3c3;
}

.detailed-report-box-cross::before {
  height: 4px;
  width: 94px;
  transform: rotate(180deg);
  background: #0067a2;
  position: absolute;
  content: "";
  left: 3px;
  top: 0px;
}

.detailed-report-box-cross > span {
  height: 0px;
  width: 0px;
  border-left: 14px solid transparent;
  border-bottom: 14px solid #0067a2;
  transform: rotate(275deg);
  position: absolute;
  top: 2px;
  left: 1px;
}

.holds-the-iframe {
  /* background:url(../Images/iFrameLoading.gif);  phani comented */
  padding: -300px;
  flex-grow: 4;
  position: relative;
  height: 1000px;
  background-position: center;
  background-repeat: no-repeat;
}

.main-container2 {
  padding: -300px;
  flex-grow: 4;
  position: relative;
  padding-top: -100px;
  height: 1560px;
  display: flex;
  margin-bottom: 100px;
}

.dashFrame {
  /* background:url(../Images/iFrameLoading.gif);   phani comented*/
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  padding-left: 5rem;
  padding-right: 12rem;
  margin-left: 12rem;
  position: absolute;
  height: 100%;
  width: 100%;
}

.detailed-report-details-header > p {
  font-size: 20px;
  color: #1c5978;
  font-weight: bold;
}

.detailed-report-details-header > p > span {
  font-size: 14px;
}

.detailed-report-details-header > h3 .subtitle {
  position: absolute;
  left: 82px;
  top: 50px;
  font-weight: bold;
  font-size: 14px;
  color: #343a40;
}

.detailed-report-details-header > h3 > span > img {
  padding: 10px;
  border-radius: 50px;
  height: 65px;
  width: 65px;
  border: 2px solid #c3c3c3;
  box-shadow: 0px 0px 13px 2px #c3c3c3;
}

.detailed-report-details-header > h3 {
  position: relative;
  color: #008eff;
  font-size: 24px;
  padding-top: 10px;
}

.detailed-report-details-header > h3 > span.p-l-5 {
  position: absolute;
  top: 20px;
  left: 78px;
}

.detailed-report-details .detailed-report-box {
  width: 40px;
  float: left;
  height: 100%;
}

.detailed-report-details .detailed-report-box-cross {
  width: 32px;
  height: 19px;
  top: -3px;
  left: -14px;
  border: none;
}

.detailed-report-details .detailed-report-box-cross::before {
  display: none;
}

.detailed-report-box.right-box-1 {
  background: #3aa2dd;
}

.detailed-report-box.right-box-2 {
  background: #008dd3;
}

.detailed-report-box.right-box-3 {
  background: #006ba8;
}

.detailed-report-box.right-box-1 > p:first-of-type,
.detailed-report-box.right-box-3 > p:first-of-type,
.detailed-report-box.right-box-2 > p:first-of-type {
  padding-top: 20px;
  padding-left: 5px !important;
}

.detailed-report-box.right-box-1 > p,
.detailed-report-box.right-box-3 > p,
.detailed-report-box.right-box-2 > p {
  padding-left: 13px;
  font-size: 20px;
}

.detailed-report-left-box {
  box-shadow: 5px 0 30px -22px #888;
  background: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  height: 190px;
  overflow-y: auto;
  margin-bottom: 25px;
}

.detailed-report-left-box.green {
  border-left: 10px solid #7ec344;
}

.detailed-report-left-box.orange-clr {
  border-left: 10px solid #f58430;
}

.detailed-report-left-box.red-clr {
  border-left: 10px solid #d5002f;
}

.detailed-report-left-box > p:first-of-type {
  font-size: 16px;
  font-weight: bold;
}

.detailed-report-left-box > p {
  font-size: 13px;
}

.card-detail::before {
  border: 12px solid #7ec344;
  height: 360px;
  width: 165%;
  border-radius: 220px;
  content: "";
  position: absolute;
  top: 0px;
  left: -100px;
}

.green.card-detail::before {
  border: 12px solid #7ec344;
}

.orange-clr.card-detail::before {
  border: 12px solid #f58430 !important;
}

.red-clr.card-detail::before {
  border: 12px solid #d5002f !important;
}

.card-detail-holder {
  width: 380px;
  height: 380px;
  border-radius: 50%;
  border: 10px solid transparent;
  padding: 40px 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-left: 0;
  position: relative;
  left: -120px;
  -webkit-animation: fadeInLeft;
  animation: fadeInLeft;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.card-detail-holder.green-clr {
  border-color: #7ec344;
}

.card-detail-holder.orange-clr {
  border-color: #f58430;
}

.card-detail-holder.red-clr {
  border-color: #d5002f;
}

/*  .card-detail>span {
    position: absolute;
    left: -32px;
    border-left: 30px solid white;
    height: 390px;
    width: 20px;
    top: 0px;
} */
.curve-hidden {
  overflow: hidden;
}

.card-detail-holder-category {
  width: 200px;
  max-height: 250px;
  position: relative;
  overflow: auto;
  -webkit-animation: fadeInDown;
  animation: fadeInDown;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.card-detail-holder-category .ht-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.card-detail-holder-category p {
  font-size: 15px;
}

.card-detail-holder-category p:last-child {
  margin-bottom: 0;
}

/* .card-detail-holder>div {
    overflow: hidden;
    height: 300px;
    width: 88%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    
} */

.dealReportModal.modal-dialog {
  max-width: 75% !important;
}

.card-initiative-alignment-0 {
  margin-top: -5px;
  margin-left: -30px;
  padding-bottom: 15px;
}

.card-initiative-alignment-3 {
  margin-top: 20px;
  padding-bottom: 15px;
  margin-left: -20px;
}

.card-initiative-alignment-1,
.card-initiative-alignment-2 {
  padding-left: 30px;
  padding-bottom: 15px;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.font-color-grey {
  color: #708a9c;
  font-weight: bold;
}

.deal-report-page-left::after {
  content: "\003C";
  border: 1px solid grey;
  padding: 3px 15px 3px 15px;
  border-radius: 5px;
  color: grey;
  font-weight: bold;
}

.deal-report-page-right::after {
  content: "\003E";
  border: 1px solid grey;
  padding: 3px 15px 3px 15px;
  border-radius: 5px;
  color: grey;
  font-weight: bold;
  margin-left: 10px;
}

.active.deal-report-page-right::after {
  color: #008eff;
  border: 1px solid #008eff;
}

.active.deal-report-page-left::after {
  color: #008eff;
  border: 1px solid #008eff;
}

.deal-report-pagination {
  text-align: center;
  padding-left: 20%;
}

.deal-report-pagination > div {
  display: inline-block;
}

.height-450 {
  min-height: 450px;
}

.height-100 {
  min-height: 100px;
}

.height-50 {
  min-height: 50px;
}

.card-initiatives,
.detailed-report-details {
  animation: fadeIn;
  animation-duration: 4s;
}

/* .card-detail-holder>div{
   animation: fadeInDown;
   animation-duration: 2s; 
}

.card-detail::before {
    animation: fadeInLeft;
    animation-duration: 2s;
} */

@keyframes fadeInLeft {
  from {
    width: 0px;
    height: 0px;
    transform: translate3d(0, 0, 0);
  }

  to {
    width: 380px;
    height: 380px;
    border-radius: 50%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    top: -50px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}

.p-l-40 {
  padding-left: 40px;
}

.card-initiatives .value-font {
  font-size: 22px;
  font-weight: bold;
  display: inline-block;
}

.card-initiatives .value-font + span {
  font-size: 16px;
  display: inline-block;
}

.select-data2 select {
  padding: 8px 10px;
  border-radius: 20px;
  color: #000;
  /* font-family: graphik; */
  font-size: 12px;
  font-weight: 700;
  /* border: 0 solid transparent; */
  /* letter-spacing: -.25px; */
  /* width: 100%; */
  background: #ecf3f9;
  border-color: #87c1f0;
  margin: 2px 1% 10px;
}

.modal-title + .column.p-l-10 {
  margin-top: -3px;
  display: inline-block;
}

.float-right {
  float: right;
}

.card-initiatives {
  display: table;
}

.card-initiatives-centre-align {
  display: table-cell;
  height: 375px;
  vertical-align: middle;
}

.card-initiative-alignment-0-0 {
  padding-bottom: 15px;
  padding-left: 20px;
}

.card-initiative-alignment-1-1 {
  padding-bottom: 15px;
  padding-left: 40px;
}

.card-initiative-alignment-2-2 {
  padding-bottom: 15px;
  padding-left: 20px;
}

.detailed-report-left-box > p:last-of-type {
  margin-bottom: 0px;
}

.over-flow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* HUMAN-MACHINE JOURNEY STYLES */
.human-machine-journey-container {
  padding-left: 0;
  padding-right: 0;
}

.human-section {
  padding-left: 10px !important;
  padding-right: 16px !important;
}

.machine-section {
  padding-right: 16px !important;
}

.hybrid-section {
  padding-right: 14px !important;
}

.hybrid-section .ratio-label {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  text-align: center;
}

.hybrid-section .ratio-value-label {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -0.35px;
  line-height: 52px;
  text-align: center;
  padding: 32px 0 80px 0;
}

/*  */

/* START - FAQ */
.faqs-accordion .card-header {
  cursor: pointer;
}

.faqs-accordion.accordion > .card:first-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.faqs-accordion.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

/* END - FAQ */
.ioj-deal-report .form-check-input {
  pointer-events: all !important;
}

.category-status-item-selected > p {
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 240px;
  text-overflow: ellipsis;
}

.ioj-deal-report .form-check {
  pointer-events: none;
}

.font-bold-13 {
  font-weight: bold;
  font-size: 13px;
}

.card-body-custom {
  padding-top: 20px;
}

/* START - MASTER TEMPLATE CONFIGURATOR */
.master-template-configurator .del-row-icon {
  float: right;
}

.master-template-configurator .modal-dialog .modal-body .alert-danger {
  font-size: 14px;
}

/* END - MASTER TEMPLATE CONFIGURATOR */
.chat-box-alignment {
  bottom: 1px;
  right: 10px;
  z-index: 999 !important;
  opacity: 1;
  background: white;
  border: none;
}

.chat-box-close-alignment {
  position: fixed;
  right: 295px;
  top: 110px;
  color: white;
  font-weight: bolder;
  cursor: pointer;
  z-index: 2;
}

/*Formula Builder*/
/* ReviewMetricstep popup Formula section CSS starts */
.formulaGroup.list-group.list-group-horizontal .list-group-item {
  margin-right: 56px;
  width: 144px;
  height: 157px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  border-radius: 4px;
  padding: 16px 10px 16px 16px;
}

.formulaGroup .list-group-item h6 {
  color: rgb(99, 115, 129);
  font-size: 24px;
  font-family: HelveticaNeue-Light;
  font-weight: 300;
  letter-spacing: -0.55px;
  line-height: 26px;
  margin: 0;
}

.formulaListInfo {
  color: rgb(99, 115, 129);
  font-size: 10px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.23px;
  line-height: 14px;
  padding: 8px 0 4px 0;
}

.formulaDays {
  color: rgb(95, 115, 131);
  font-size: 10px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 28px;
  position: absolute;
  bottom: 8px;
}

.formulaDays var {
  font-size: 14px;
  font-style: normal;
  display: inline;
}

.formulaGroup.list-group.list-group-horizontal .list-group-item:last-child {
  margin-right: 0px;
}

.formulaGroup.list-group.list-group-horizontal .list-group-item p {
  margin-bottom: 0px;
}

.addition-icon::before {
  content: "+";
  position: absolute;
  display: block;
  color: rgba(98, 115, 130, 0.5);
  font-family: Helvetica-Light;
  font-size: 38px;
  text-align: center;
  width: 24px;
  top: 4px;
  left: -40px;
}

.minus-icon::before {
  content: "-";
  position: absolute;
  display: block;
  color: rgba(98, 115, 130, 0.5);
  font-family: Helvetica-Light;
  font-size: 38px;
  text-align: center;
  width: 24px;
  top: 4px;
  left: -40px;
}

.multiply-icon::before {
  content: "×";
  position: absolute;
  display: block;
  color: rgba(98, 115, 130, 0.5);
  font-family: Helvetica-Light;
  font-size: 38px;
  text-align: center;
  width: 24px;
  top: 4px;
  left: -40px;
}

.division-icon::before {
  content: "/";
  position: absolute;
  display: block;
  color: rgba(98, 115, 130, 0.5);
  font-family: Helvetica-Light;
  font-size: 38px;
  text-align: center;
  width: 24px;
  top: 4px;
  left: -40px;
}

.tooltipStyle {
  background: rgb(28, 38, 47);
  border-radius: 2px;
  min-height: 63px;
  width: 194px;
  color: rgb(255, 255, 255);
  font-family: graphik;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.line-clamp-ellipsis {
  width: 105px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.lineTabs .result-calc {
  padding: 0 0 12px 0;
  margin: 0px;
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-family: Helvetica;
  letter-spacing: -0.33px;
  line-height: 12px;
}

.lineTabs .fields-notification {
  margin: 0;
  text-align: right;
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-family: Helvetica;
  letter-spacing: -0.25px;
  line-height: 12px;
}

.fields-notification span {
  font-size: 16px;
  color: rgb(220, 53, 70);
  vertical-align: middle;
}

.customtooltip-hover {
  position: relative;
}

.custom-tooltip-query {
  display: none;
}

.customtooltip-hover:hover .custom-tooltip-query {
  display: block;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 4px;
  padding: 5px;
  width: 150px;
  top: 68px;
  left: -25px;
  position: absolute;
  z-index: 2;
  font-size: 10px;
  min-height: 63px;
  font-family: graphik;
  font-weight: normal;
  word-break: normal;
  color: #fff;
  text-align: center;
}

.customtooltip-hover:hover .custom-tooltip-query::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  border: 5px solid transparent;
  border-top-color: #000;
}

.customtooltip-hover.formulaDays {
  position: absolute;
}

.customtooltip-hover.formulaDays:hover .custom-tooltip-query {
  top: 40px;
}

.customtooltip-hover .formulaListInfo {
  padding: 8px 0 2px 0;
}

/* ReviewMetricstep popup Formula section CSS ends here */
/* DealMetricConfigure styles starts here*/
.query-builder-sec {
  margin-bottom: 10px;
  padding: 14px 0px;
}

.query-builder-sec .form-group.border-group {
  margin: 0 6px !important;
  padding: 0;
  border: 0;
}

.query-builder-sec .form-group.border-group select {
  background: transparent;
  margin: 0 !important;
  height: auto;
}

.query-builder-sec .col-lg-4 .form-control,
.selectsuboffering-dropdown.dropdown > button {
  width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.searchBtn .has-search input {
  width: 423px;
  height: 32px;
  border-radius: 24px;
  border: 1px solid rgb(196, 205, 213);
}

.searchBtn .has-search input.form-control:focus {
  outline: none;
  box-shadow: none;
}

.searchBtn .has-search .form-control {
  padding-left: 2.375rem;
}

.searchBtn .has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.metricBtn button {
  border-radius: 24px;
  padding: 4px;
}

.ioj-full {
  margin-top: 0px;
}

.queryContent .ioj-full .outcome-divider {
  margin: 0px;
}

.queryContent .ioj-full.metricCardList .outcome-divider {
  margin-bottom: 16px;
}

.metricGroup h1 {
  margin-bottom: 8px;
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: graphik;
  font-weight: 500;
  letter-spacing: -0.35px;
}

.metricGroup p {
  padding-top: 16px;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 16px;
}

.metricGroup .list-group .list-group-item:first-child {
  padding-left: 0px;
}

.metricGroup .list-group .list-group-item {
  padding: 0 16px;
  margin: 0px;
  border: 0px;
  border-right: 1px solid #ddd;
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.metricGroup .list-group .list-group-item:last-child {
  border: none;
}

.overlay-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.2s ease;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}

.outcome-divider:hover .overlay-item {
  opacity: 0.97;
}

.hover-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.hover-center span {
  display: inline-grid;
  align-items: center;
  width: 48px;
  position: relative;
  font-size: 20px;
  height: 48px;
  background: rgb(244, 246, 248);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 50%;
  margin: 0 16px;
  cursor: pointer;
}

.rowWrapper {
  max-height: 200px;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}

.rowWrapper > .row:first-child {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

/* DealMetricConfigure styles ends here*/

.blue-cat span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 180px;
  height: 25px;
}

.blue-cat span.no-tool {
  background-color: #fff;
}

.modal-btn.querybuilder-footer {
  justify-content: space-between;
}

.border-group :disabled.form-control {
  padding-bottom: 6px;
}

.tabsSmall .tabSteps .nav-tabs {
  justify-content: flex-start;
  width: 100%;
}

/*BO2_popup css starts here*/
.bo-configurator-modal.enhance .modal-dialog,
.human-machine-configurator-modal .modal-dialog,
.wo-configurator-modal .modal-dialog {
  max-width: 1000px;
}

.bo-dashboard-modal .modal-content {
  padding: 0 1rem;
}

.bo-dashboard-modal .modal-content .modal-header {
  padding: 1rem 0rem 1rem 1rem;
}

.metric-deep-dive .dropdown-heading-value span {
  line-height: 1.5;
  color: #495057;
  font-size: 0.85rem;
}

.metric-deep-dive .multi-select .dropdown-heading {
  height: 34px;
}

.custom-multi-select.metric-deep-dive .multi-select .dropdown-content label {
  padding: 9px 15px;
  margin: 0;
  background: #fff;
  font-family: graphik-medium;
}

/* .custom-multi-select.metric-deep-dive .multi-select .dropdown-content label[aria-selected="true"] span {
  color: #018eff;
} */
.bo-dashboard-modal .modal-content .modal-body {
  max-height: 500px;
  overflow-y: scroll;
  padding: 0 1rem;
}

.bo-dashboard-modal .modal-content .modal-header .close {
  right: 0px;
}

.right-wrapper {
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  min-height: 590px;
  margin-top: 25px;
}

.catergory-metric-sub {
  border: 0;
  background: rgb(249, 250, 251);
  border-bottom: 1px solid rgb(223, 227, 232);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 17px;
}

.catergory-metric-sub i {
  padding-right: 8px;
}

.catergory-metric-sub .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: rgb(126, 195, 68);
}

.sub-level {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: graphik;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 24px;
}

.disable-sub-level {
  display: flex;
  align-items: center;
  min-height: -webkit-fill-available;
  justify-content: center;
  flex-direction: column;
  margin-top: 50%;
}

.disable-sub-level i {
  font-size: 72px;
}

.form-section .disable-sub-level p {
  color: rgb(99, 115, 129);
  font-size: 14px !important;
  font-family: graphik;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.31px;
  line-height: 20px;
  margin: 0;
  border: 0 !important;
  padding: 0;
}

.form-section .toggle-switch .custom-control-label::before {
  background: #c4cdd5;
  border: none;
}

.form-section .custom-control-label::before {
  background: #fff;
  border: 1.5px solid #ced4da;
}

/* .custom-checkbox .custom-control-input:checked~.custom-control-label::after{
  background-color: #ced4da;
} */
.form-section .custom-switch .custom-control-label::after {
  background-color: #fff;
}

.disable-sub-level-none {
  display: none;
}

.enable-sub-level-none {
  display: none;
}

.check-box-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 17px 16px 24px;
}

.check-box-info .fa-info-circle:before {
  color: rgb(145, 158, 171);
}

.form-section .check-box-info label {
  color: rgb(34, 32, 32);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 22px;
}

.add-category-btn,
.add-category-btn:hover,
.add-category-btn:active {
  color: rgb(1, 142, 255);
  position: relative;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  float: right;
  padding-top: 0;
}

.add-category-btn:focus {
  outline: none;
  box-shadow: none;
}

.add-category-btn::before {
  content: "+";
  height: 15px;
  width: 15px;
  font-family: "FontAwesome";
  display: inline-block;
  position: absolute;
  left: -4px;
  font-size: 16px;
  top: 2px;
}

.disable-sub-level .fa-sitemap:before {
  content: "\F0E8";
  color: rgb(196, 205, 213);
}

.add-sub-level-metrics {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 308px;
  width: 100%;
}

.form-section .add-sub-level-metrics p {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.31px;
  line-height: 20px;
  position: relative;
  margin: 0;
  border: 0;
  padding: 10px 0 0 0;
}

.form-section .add-sub-level-metrics p span {
  color: rgb(220, 53, 70);
  position: absolute;
  left: -8px;
  top: 12px;
}

.add-sub-level-metrics .fa-file-plus:before {
  content: "\F319";
  font-size: 75px;
  color: rgb(196, 205, 213);
}

.addCategoryModal .modal-header {
  justify-content: center;
}

.addCategoryModal .modal-footer {
  display: flex;
}

.addCategoryModal .modal-footer .btn {
  width: 120px;
  height: 48px;
}

.addCategoryModal .modal-footer .btn.btn-outline-secondary:hover {
  background-color: transparent;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: graphik;
  font-weight: 500;
  text-align: center;
}

.add-metric-head-none,
.category-lists-none {
  display: none;
}

.category-lists ul li {
  border-bottom: 1px solid rgb(223, 227, 232);
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  color: rgb(51, 52, 54);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.29px;
  position: relative;
}

.category-lists ul li:last-child {
  justify-content: center;
  cursor: pointer;
}

.item-list-hover {
  display: none;
}

.category-lists ul li:hover .item-list-hover {
  display: flex;
  position: absolute;
  background: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.16);
}

.category-lists ul li:last-child i {
  /* font-size: 20px; */
}

.item-list-hover span i {
  background: rgb(244, 246, 248);
  border: 1px solid rgb(223, 227, 232);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: relative;
  margin: 0 7px;
  cursor: pointer;
}

.item-list-hover span {
  display: flex;
  align-items: center;
}

.item-list-hover span .fa-times:before {
  position: absolute;
  top: 5px;
  left: 7px;
}

.item-list-hover span .fa-edit:before {
  position: absolute;
  left: 5px;
  top: 4px;
}

.category-header .category-actions span i {
  width: 24px;
  height: 24px;
  background: rgb(244, 246, 248);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 50%;
  position: relative;
  margin-right: 0px;
  cursor: pointer;
  line-height: 24px;
}

.category-lists ul li:first-child span i:last-child {
  margin-right: 0;
}

.category-lists ul li:first-child span .fa-edit:before {
  position: absolute;
  left: 3px;
  top: 4px;
}

.category-lists ul li:first-child span .fa-trash-alt:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.newlyadded {
  color: rgb(145, 158, 171);
  font-family: Helvetica;
  font-size: 12px;
  height: 12px;
  letter-spacing: -0.25px;
  width: 100%;
  padding: 0 12px;
}

.approvalModal .modal-header {
  padding-top: 15px;
}

.approvalModal .modal-content {
  min-height: 200px;
}

.approvalInfo {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
  padding-left: 12px;
}

.frontDoorView div label {
  color: rgb(145, 158, 171);
  font-size: 12px;
  font-family: graphik-medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.categoryType ul li span,
.frontDoorView p {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 2px 0px;
}

.categoryType ul li {
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

/*BO2_popup css ends here*/

.infoModalText,
.landBox-grid:hover .tooltiptext {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

/*Add user modal popup and reports small css change starts here */
.addUserModal .modal-dialog {
  max-width: 720px;
}

.clientMultipleSelect.form-control[multiple],
.summaryReportMultipleSelect select.form-control[multiple],
.reportExtractMultipleSelect select.form-control[multiple] {
  height: 140px !important;
}

.reportMultipleSelect select.form-control[multiple] {
  height: 140px !important;
}

/*Add user modal popup and reports small css change ends here */

/* Multi Select React Dropdown CSS starts here */
#multiselectContainerReact div:first-child {
  display: none !important;
}

.optionListContainer {
  display: block;
  position: relative !important;
}

.optionContainer {
  max-height: 150px !important;
}

.optionContainer li {
  font-size: 14px !important;
  font-family: graphik !important;
  background-color: #fff;
  color: rgb(111, 120, 128) !important;
  padding: 4px 14px !important;
}

.optionContainer li:hover {
  background-color: #fff;
  color: rgb(111, 120, 128) !important;
}

/* Multi Select React Dropdown CSS ends here */

/* React "react-multi-select-component" Dropdown starts here */
/* .custom-multi-select .multi-select .dropdown-heading-value span{
   color: #495057;
   font-size: 14px; 
   font-family: graphik;
} */
.custom-multi-select .multi-select .dropdown-content .item-renderer span {
  color: #495057;
  font-weight: 400;
}

/* .custom-multi-select .multi-select .dropdown-content label{
   padding: 5px 15px;
} */
.custom-multi-select .multi-select .dropdown-content label {
  padding: 2px 15px;
}

.custom-multi-select .multi-select .dropdown-container {
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.custom-multi-select .multi-select .dropdown-heading {
  padding: 0 8px 0 15px;
}

.custom-multi-select .multi-select .dropdown-content .panel-content .select-panel > div {
  border-bottom: 1px solid #ced4da;
}

.custom-multi-select .multi-select .dropdown-content .panel-content .select-panel div input:focus {
  color: #000;
}

.custom-multi-select .multi-select .dropdown-content .panel-content .select-panel {
  /* padding: 4px 4px 4px 15px; */
  background-color: #fff;
}

.custom-multi-select .multi-select .dropdown-content .item-renderer {
  display: flex;
  align-items: center;
}

.custom-multi-select .multi-select .dropdown-content .panel-content {
  max-height: 225px;
}

.custom-multi-select .multi-select .dropdown-content {
  background: #fff !important;
  z-index: 999;
}

.custom-multi-select .multi-select .dropdown-content .panel-content .select-panel > div input {
  height: 24px !important;
}

.custom-intelligent .custom-multi-select .multi-select .dropdown-heading {
  height: 32px !important;
}

/* React "react-multi-select-component" Dropdown ends here */
/* React "react-dropdown-select" Dropdown starts here */
.single-select-dropdown .css-wmw4vi-ReactDropdownSelect {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0 8px 0 10px;
  min-height: 40px;
}

.single-select-dropdown .css-1ngods0-InputComponent {
  font-size: inherit;
}

.single-select-dropdown .css-1yc4zyy-DropdownHandleComponent {
  margin-top: 6px;
}

.single-select-dropdown .css-1yc4zyy-DropdownHandleComponent svg,
.single-select-dropdown .css-1aarvou-DropdownHandleComponent svg {
  width: 24px;
  height: 24px;
  color: #aaa;
}

.single-select-dropdown .css-148o527-ItemComponent {
  padding: 2px 15px;
  margin-bottom: 0.5rem;
  color: #495057;
  font-weight: 400;
  font-size: 13px;
}

.single-select-dropdown .css-34vyi-DropDown {
  top: 42.999996px;
  padding: 5px 0px;
}

.single-select-dropdown .css-wmw4vi-ReactDropdownSelect:focus,
.single-select-dropdown .css-wmw4vi-ReactDropdownSelect:hover {
  border-color: #ced4da;
}

.single-select-dropdown .css-wmw4vi-ReactDropdownSelect:focus-within {
  box-shadow: #4285f4 0px 0px 0px 1px;
  border-color: #4285f4;
}

.single-select-dropdown .css-148o527-ItemComponent.react-dropdown-select-item-selected {
  background: #e2e6ea;
  color: #495057;
}

.single-select-dropdown .css-148o527-ItemComponent:hover,
.single-select-dropdown .css-148o527-ItemComponent:focus {
  background: #f1f3f5;
}

.single-select-dropdown .css-v1jrxw-ContentComponent {
  overflow: hidden;
}

.single-select-dropdown .css-v1jrxw-ContentComponent span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.single-select-dropdown ::placeholder {
  color: #aaa;
  opacity: 1;
}

.single-select-dropdown :-ms-input-placeholder,
.single-select-dropdown ::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aaa;
}

.single-select-dropdown .react-dropdown-select-dropdown {
  z-index: 999;
}

/* React "react-dropdown-select" Dropdown ends here */
/* H & M start */
.hmConfigHead {
  margin: 10px 0px 20px 19px;
  color: rgb(37, 49, 62);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.hmConfigTab {
  margin-left: 0px;
  margin-right: 16px;
}

.hmConfigTab ul {
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
}

.hmConfigTab ul li {
  width: 32%;
  height: 56px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 32px 0px rgba(33, 43, 54, 0.16);
  border-radius: 8px;
  padding: 18px 18px 18px 30px;
}

.hmConfigTab ul li a {
  width: 31px;
  height: 22px;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 22px;
}

.hmConfigTab ul li p {
  width: 31px;
  height: 22px;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 22px;
  cursor: pointer;
}

.hmParameter {
  background: rgb(249, 250, 251);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 10px;
}

.hmParameter select {
  border: none;
  background: rgb(249, 250, 251);
}

.hmParameter span {
  color: rgb(1, 142, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
  /* margin-left: 30rem; */
  display: inline-flex;
  align-items: center;
}

.hmParameter .plusIcon {
  margin-right: 6px;
  cursor: pointer;
  font-size: 12px !important;
}

.btn-default {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(181, 192, 202);
  color: rgb(99, 115, 129);
  box-shadow: none;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  background-color: rgb(239, 242, 245);
  border-color: rgb(181, 192, 202);
  box-shadow: none;
  outline: none;
  color: rgb(99, 115, 129);
}

.hmConfigList {
  margin-top: 10px;
  margin-left: 0px;
}

.hmConfigList .nav-tabs .nav-link {
  border: none;
  color: rgb(145, 158, 171);
  font-family: graphik-medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 8px 22px;
}

.hmConfigList .nav-tabs {
  border: none;
}

.hmConfigList .nav-tabs .nav-link.active {
  position: relative;
  background-color: #fff;
  color: rgb(1, 142, 255);
  font-family: graphik-medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  border: none;
}

.hmConfigList .nav-tabs .nav-link:focus,
.hmConfigList .nav-tabs .nav-link:hover {
  border-color: transparent;
  outline: none;
}

.hmConfigList .nav-tabs .nav-link.active::after {
  background: rgb(1, 142, 255);
  border-radius: 3px 3px 0px 0px;
  height: 4px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 58%;
  margin: 0 auto;
}

.parameterRow {
  margin-left: 20px;
  margin-top: 15px;
  width: 97%;
  height: 64px;
  background: rgb(255, 255, 255);
  box-shadow: 0px -1px 0px 0px rgb(223, 227, 232), 0px 1px 0px 0px rgb(223, 227, 232);
  border-radius: 0px;
  border-bottom: 1px solid;
  display: Flex;
}

.parameterRowTitle {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  display: flex;
}

.parameterRowSubTitle {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  display: flex;
}

.parameterRow span {
  width: 38px;
  height: 38px;
  background: rgb(244, 246, 248);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 50%;
}

.parameterRow ul {
  display: flex;
}

.parameterRow ul li {
  margin-right: 10px;
}

.parameterRowTitle .parameterRowLeft {
  padding-left: 10px;
  border-left: 2px solid rgb(33, 43, 54);
}

.parameterRowSubTitle .parameterRowLeft {
  padding-left: 10px;
  border-left: 1px solid rgb(99, 115, 129);
}

.parametersList {
  background: rgb(255, 255, 255);
  box-shadow: 0px -1px 0px 0px rgb(223, 227, 232), 0px 1px 0px 0px rgb(223, 227, 232);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 30px;
}

.parametersList .list-params .main-list {
  display: flex;
  margin: 0;
  padding: 14px 0 7px 0;
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
}

.parametersList .list-params .main-list li {
  padding: 0 12px;
  border-right: 1px solid #ddd;
}

.parametersList .list-params .main-list li:last-child {
  border-right: 0px solid #ddd;
}

.parametersList .list-params .main-list li:first-child {
  padding-left: 0px;
}

.parametersList .list-params .sub-list {
  display: flex;
  display: -ms-flexbox;
  margin: 0;
  padding: 0 0 10px 0;
  color: rgb(99, 115, 129);
  font-family: graphik-regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.parametersList .list-params {
  width: 100%;
}

.parametersList .list-params .sub-list li {
  white-space: pre-line;
  word-wrap: break-word;
}

.hmContent {
  margin-left: 15px;
  margin-top: 25px;
  width: 98%;
}

.params-edit-delete {
  padding-right: 35px;
  display: flex;
  justify-content: space-around;
  margin-top: 45px;
}

.params-edit-delete span {
  background: rgb(244, 246, 248);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 50%;
  margin: 7px;
  padding: 5px 10px 5px 10px;
}

.parameterSelect {
  margin-left: 26px;
}

.parameterIcon {
  width: 75%;
  margin-right: 43px;
  display: flex;
  justify-content: flex-end;
}

.hmNoParameter {
  text-align: center;
  display: block;
  padding-top: 12rem;
}

.hmNoParameter b {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
}

.hmNoParameterspan {
  color: rgb(109, 126, 141);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.25px;
  width: 300px;
  display: inline-block;
  line-height: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.hmNoParameter a {
  color: rgb(1, 142, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
}

.hmConfigList {
  min-height: 581px;
}

/* H & M End */

/* welcome message starts here*/
.search-nav .nav-item.searchFilter {
  margin-right: 15px;
}

.welcome-message {
  position: absolute;
  height: 56px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1090;
}

.welcome-message .message-data {
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
  padding: 20px 80px;
  position: relative;
}

.welcome-message p {
  margin-bottom: 0;
  color: #919eab;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}

.welcome-message p span {
  color: #0091ff;
}

.message-data .message-close {
  position: absolute;
  right: 10px;
  top: 18px;
  color: #919eab;
  font-size: 16px;
}

/* welcome message ends here*/

.outcome-highlight.no-table-layout h2 {
  white-space: pre;
}

.inline-block {
  display: inline-block;
}

/* pulse -landing page css */
.assets-radio-align {
  font-size: 12px;
  margin-top: 2px;
}

.assetsFilter input[type="search"] {
  color: #919eab;
  font-size: 12px;
  letter-spacing: -0.25px;
  font-family: graphik-medium;
  border: 1px solid #c3c3c3;
  margin-bottom: 0;
  background: transparent url(../Images/search-filter.png) no-repeat scroll 10% center;
  background-size: 16px;
  padding-right: 24px;
  width: 230px;
  padding-left: 45px !important;
  padding: 5px;
  border-radius: 15px;
}

#addPulseAssets.modal-dialog {
  max-width: 650px;
}

.addPulseAssets-margin {
  padding-bottom: 20px;
  border-bottom: 1px solid #dee2e6;
}

.brder-2 {
  border: 2px solid #c3c3c3;
}

.landbox-overflow {
  height: 245px;
}

.noDataSec {
  width: 100%;
}

.noDataSec .nodatatop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noDataSec .nodatatop span {
  background: rgb(244, 246, 248);
  height: 74px;
  width: 74px;
  display: block;
  border-radius: 50%;
}

.noDataSec .nodatatop p {
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 16px;
  margin: 6px 0 10px 0;
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 14px;
  text-align: center;
}

.noDataSec .nodatatop .information {
  margin: 0px;
  width: 58%;
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 15px;
  text-align: center;
}

.noDataSec .nodatatop button.btn-primary {
  color: rgb(1, 142, 255);
  font-family: graphik-medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 14px;
  text-align: center;
  background-color: #fff;
  border: none;
}

.noDataSec .nodatatop button.btn-primary:hover,
.noDataSec .nodatatop button.btn-primary:focus {
  outline: none;
  background-color: #fff;
  color: rgb(1, 142, 255);
  box-shadow: none;
}

.noDataSec .nodatabottom {
  border-radius: 8px;
  border: 1px solid rgb(222, 222, 222);
  margin: 10px;
}

.noDataSec .nodatabottom h1 {
  color: rgb(145, 158, 171);
  font-family: graphik-medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 13px;
  margin: 0px;
  margin: 4px 0 6px 0;
}

.noDataSec .nodatabottom ul li {
  color: rgb(145, 158, 171);
  font-family: graphik-medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 16px;
  padding: 0 8px;
  text-align: left;
}

.noDataSec .nodatabottom .row div:first-child ul {
  border-right-style: dotted;
  border-color: rgb(145, 158, 171);
  border-right-width: thin;
}

.landBox-grid .landbox-grid-hover {
  display: none;
}

.landBox-grid:hover .landbox-grid-hover {
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  /* border: 1px solid rgb(1, 142, 255); */
  background: rgba(255, 255, 255, 0.9);
}

.landbox-grid-hover .hover-icons span.sub-span {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgb(244, 246, 248);
  border: 1px solid rgb(223, 227, 232);
  margin-right: 16px;
  cursor: pointer;
  position: relative;
}

.landbox-grid-hover .hover-icons span.sub-span .span-tooltip {
  display: none;
}

.landbox-grid-hover .hover-icons span.sub-span:hover .span-tooltip {
  position: absolute;
  top: -38px;
  background: rgb(28, 38, 47);
  border-radius: 2px;
  height: 32px;
  color: rgb(255, 255, 255);
  font-family: graphik-medium;
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  text-align: center;
  left: -8px;
  width: 48px;
  right: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.landbox-grid-hover .hover-icons span.sub-span:hover .span-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.landbox-grid-hover .hover-icons span.sub-span i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.landbox-grid-hover .hover-icons span.sub-span i::before {
  color: #212b36;
  opacity: 0.5;
}

.landbox-grid-hover .hover-head {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 13px;
}

.landbox-grid-hover .hover-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 33px);
}

.addAssetModal .custom-file label {
  margin-left: 12px;
}

#addPulseAssets .addAssetModal .custom-file-css input,
.custom-file-css label {
  font-size: 13px;
  font-family: graphik;
}

#addPulseAssets .addAssetModal .custom-file-css label {
  margin-left: 0;
}

#addPulseAssets .addAssetModal .custom-file-css p {
  margin-bottom: 6px;
  font-size: 13px;
  font-family: graphik;
  font-weight: 700;
}

.row.addPulseAssets-margin {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.row.addPulseAssets-margin:first-child {
  padding-top: 0;
}

.row.addPulseAssets-margin:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.row.addPulseAssets-margin .form-check .checkmark {
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  left: 0;
}

.row.addPulseAssets-margin .form-check h4 {
  font-family: graphik-medium;
  font-size: 16px;
  font-weight: 500;
  height: 19px;
  letter-spacing: -0.35px;
}

.row.addPulseAssets-margin .form-check p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.landBox.custom-landbox .outcome-highlight h2 {
  min-height: 24px;
}

.landBox.custom-landbox .outcome-highlight h2 svg {
  height: 24px !important;
}

.custom-landbox .landBox-main {
  padding-top: 0px !important;
  margin: 0px;
}

/* new css starts here created date 10/20/2020 */
.landBox.custom-landbox.custom-landbox-new .landBox-grid {
  height: 98px;
  padding: 16px 10px 16px 16px;
  width: calc(50% - 16px);
}

.landBox.custom-landbox.custom-landbox-new .outcome-highlight {
  padding-left: 0px;
  padding-right: 0px;
}

.landBox.custom-landbox.custom-landbox-new .title-with-icon {
  padding: 0 32px;
}

.landBox.custom-landbox.custom-landbox-new .landbox-overflow {
  padding: 10px 6px 16px 24px;
  margin-right: 11px;
}

.landBox.custom-landbox.custom-landbox-new .landBox-grid p {
  color: rgb(119, 136, 153);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 15px;
  text-overflow: initial !important;
  white-space: initial !important;
  margin: 0px;
}

.custom-landbox-new .head-spce {
  padding-left: 3px;
}

.custom-landbox-new .landBox-grid a {
  color: rgb(33, 43, 54) !important;
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 0px;
  padding-bottom: 8px;
}

.custom-landbox-new .landBox-grid a span.head-spce {
  color: rgb(33, 43, 54) !important;
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.custom-landbox-new .landBox-grid:hover {
  box-shadow: 0px 2px 15px 0px rgba(33, 43, 54, 0.25);
  background: rgb(255, 255, 255);
  border: 1px solid #fff;
}

.custom-landbox-new .hover-icons a {
  padding-bottom: 0px;
}

.custom-landbox-new .head-spce.font-color-black {
  color: #212b36;
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.landBox.custom-landbox.custom-landbox-new .outcome-highlight h2 {
  color: #212b36;
  font-size: 16px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.25px;
  display: flex;
  align-items: center;
}

.landBox.custom-landbox.custom-landbox-new .title-with-icon img {
  width: 24px;
  height: 20px;
  margin-right: 7px;
}

.landBox.custom-landbox.custom-landbox-new .noLinkEle {
  padding-bottom: 8px;
}

.synops-header {
  display: flex;
  align-items: center;
}

/* crossmetricanalysis css */
.baseHover {
  position: relative;
}

.baseHover span {
  display: none;
}

.baseHover:hover span {
  position: absolute;
  background: rgb(28, 38, 47);
  color: rgb(255, 255, 255);
  font-family: graphik-medium;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  left: -63px;
  width: 140px;
  padding: 8px;
  right: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  bottom: 18px;
  border-radius: 4px;
}

.baseHover:hover span::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: #000;
}

/* new css ends here created date 10/20/2020 */
/* pulse landing css end */
/* Synops story css starts here */
.synOpsStory-modal .modal-content {
  width: 850px;
}

.search-nav a.nav-link.story-link {
  font-size: 14px;
  letter-spacing: -0.25px;
  font-family: graphik-medium;
  line-height: 26px;
  cursor: pointer;
  color: rgb(1, 142, 255) !important;
  border: 1px solid rgb(1, 142, 255);
  border-radius: 20px;
  padding: 6px 22px !important;
}

.search-nav a.nav-link.story-link.active {
  color: #919eab !important;
  border: none;
  padding: 6px 0px !important;
}

.search-nav a.nav-link.story-link:hover::after {
  display: none;
}

.search-nav a.nav-link.story-link.active:hover::after {
  display: inline-block;
}

.storyFull {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 24px 0px rgba(33, 43, 54, 0.25);
  height: 140px;
  margin-bottom: 32px;
  padding: 24px 10px;
  text-align: center;
  border: 2px solid #fff;
}

.storyFull:hover {
  cursor: pointer;
  border: 2px solid #018eff;
  box-shadow: 0 2px 24px 0 rgba(33, 43, 54, 0.25);
}

.storyFull.grayed-out,
.storyFull.grayed-out:hover {
  cursor: default;
  opacity: 0.5;
  border-color: transparent;
  pointer-events: none;
}

.storyFull.grayed-out .storyIcon img {
  opacity: 0.2;
}

.storyTitle h4 {
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 18px;
  margin: 0px;
}

.storyTitle p span {
  color: rgb(99, 115, 129);
  font-family: graphik-regular;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 0px;
  letter-spacing: -0.3px;
  line-height: 18px;
  position: relative;
}

.storyTitle p span::before {
  content: "";
  display: inline-block;

  height: 4px;
  width: 4px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  margin-left: -5px;
}

.storyTitle p span.blue-dot::before {
  background: rgb(0, 122, 255);
}

.storyTitle p span.grey-dot::before {
  background: rgb(133, 146, 157);
}

.storyItemLinks ul li {
  display: flex;
  flex-direction: column;
  line-height: 18px;
  padding: 18px 0px;
  border-top: 1px solid rgb(223, 227, 232);
}

.storyItemLinks ul li a {
  color: rgb(1, 142, 255);
  font-family: graphik-medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
}

.storyItemLinks ul li span {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.storyItemLinks h4 {
  color: rgb(33, 43, 54);
  font-family: graphik-light;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -0.6px;
  line-height: 18px;
  margin-bottom: 22px;
}

.storyItemLinks ul li.disabled {
  color: rgb(133, 146, 157) !important;
}

.row.owlCarouselMain {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.backArrow {
  color: rgb(99, 115, 129);
  font-family: graphik-medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
  border: 1px solid #c4cdd5;
  padding: 3px 10px;
  border-radius: 20px;
  display: inline-block;
}

.chooseOptionStory .owl-carousel .owl-nav .owl-prev {
  left: -45px;
}

.chooseOptionStory .owl-carousel .owl-nav .owl-next {
  right: -10px;
}

.chooseOptionStory .owl-carousel .owl-stage-outer {
  padding: 13px 8px;
  margin: 0px;
}

.chooseOptionStory .owl-carousel .owl-nav [class*="owl-"] {
  border: 1px solid #c4cdd5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 34.5px;
  box-shadow: none;
}

.chooseOptionStory span {
  border-radius: 20px;
  color: rgb(33, 43, 54);
  font-family: graphik-medium;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  /* height: 16px; */
  letter-spacing: -0.25px;
  line-height: 16px;
  border: 1px solid #c4cdd5;
  border-radius: 20px;
  padding: 7px 7px;
  cursor: pointer;
}

.chooseOptionStory span.active,
.chooseOptionStory span:hover,
.backArrow:hover {
  line-height: 16px;
  background: rgba(1, 142, 255, 0.04);
  border: 1px solid rgb(1, 142, 255);
  cursor: pointer;
}

.chooseOptionStory span.grayed-out {
  cursor: default;
  opacity: 0.6;
  border: 1px solid #c9cfd4 !important;
}

.chooseOptionStory .owl-carousel .owl-nav.disabled,
.chooseOptionStory .owl-carousel .owl-dots.disabled {
  display: block;
}

.chooseOptionStory .owl-dots {
  display: none;
}

/*.chooseOptionStory .owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  padding: 0 6px;
  width: auto !important;
}*/

.chooseOptionStory .owl-carousel .owl-nav .disabled {
  opacity: 1;
}

.someOfferings {
  margin: 6px 0 0 0;
  display: flex;
  padding: 0 14px;
  justify-content: space-between;
}

/* Synops story css ends here */
/* Landing Page Client Grouping Start */
.clientgrouping-list {
  width: 265px;
  position: absolute;
  height: 72vh;
  overflow: auto;
  left: -12px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  margin: 1.3% 0;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}

.clientgrouping-list h5 {
  color: #212b36;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  padding: 1.5rem 1rem 1rem;
  margin-bottom: 0;
}

.clientgrouping-list li {
  color: #212b36;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  padding: 8px 30px;
}

.clientgrouping-list li:hover {
  background: rgba(1, 142, 255, 0.08);
  color: #018eff;
  font-family: Graphik-Medium;
}

/* .client-card .client-listing {
  margin-left: 250px;
} */
.client-card .client-listing .card {
  width: 22%;
}

.clientgrouping-list li.active {
  background: rgba(1, 142, 255, 0.08);
  color: #018eff;
  font-family: Graphik-Medium;
}

/* Landing Page Client Grouping End*/

/* Query Builder formula calculator css starts here */
.queryBuilderModal .modal-dialog {
  max-width: 80% !important;
}

.queryBuilderModal .toggle-form .tabSteps .nav-tabs a::after {
  /* width: 150%; */
  display: none;
}

.queryBuilderModal .toggle-form .tabSteps .nav-tabs a:first-child {
  margin-left: 0;
  padding-left: 0;
}

.formulaBtnGroup button {
  background-color: #fff;
  margin-right: 12px;
  border-radius: 6px;
  border: 1.5px solid rgb(1, 142, 255);
  height: 40px;
  width: 40px;
  color: rgb(1, 142, 255);
}

.formulaBtnGroup button:hover,
.formulaBtnGroup button:active {
  background-color: #fff;
  outline: none;
  color: rgb(1, 142, 255);
}

.formulaBtnGroup .btn-primary.disabled:active,
.formulaBtnGroup .btn-primary:disabled:hover {
  background-color: #fff;
  outline: none;
  color: rgb(99, 115, 129);
}

.formulaBtnGroup .btn-primary.disabled,
.formulaBtnGroup .btn-primary:disabled {
  border: 1.5px solid rgb(99, 115, 129);
  color: rgb(99, 115, 129);
  background-color: #fff;
}

.queryContent .btn-outline-primary:disabled,
.querybuilder-footer .btn-outline-secondary.disabled,
.querybuilder-footer :disabled.btn-outline-secondary {
  background-color: #edf0f3;
}

.queryContent .btn-outline-primary:disabled:hover,
.querybuilder-footer .btn-outline-secondary.disabled:hover,
.querybuilder-footer :disabled.btn-outline-secondary:hover {
  background-color: #edf0f3;
}

.conditionBox .rowWrapper .form-section .col-lg-3 .border-group input.form-control {
  height: 17px;
}

.calcResult {
  display: flex;
  justify-content: space-between;
}

.calcClose {
  cursor: pointer;
}

.queryBuilderModal .tabsSmall .nav-tabs {
  display: inline-flex;
}

.queryBuilderModal .toggle-form.tabsSmall {
  text-align: center;
}

.queryBuilderModal .toggle-form .tab-content {
  text-align: left;
}

.formulaBuild .form-section .form-group input.form-control {
  height: 17px;
}

/* Query Builder formula calculator css ends here */
#addPulseAssets .addAssetModal .custom-file-css input,
.custom-file-css label {
  font-size: 13px;
  font-family: graphik;
}

#addPulseAssets .addAssetModal .custom-file-css label {
  margin-left: 0;
}

#addPulseAssets .addAssetModal .custom-file-css p {
  margin-bottom: 6px;
  font-size: 13px;
  font-family: graphik;
  font-weight: 700;
}

.row.addPulseAssets-margin {
  padding-top: 10px;
  padding-bottom: 10px;
}

.row.addPulseAssets-margin:first-child {
  padding-top: 0;
}

.row.addPulseAssets-margin:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.row.addPulseAssets-margin .form-check .checkmark {
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  left: 0;
}

.row.addPulseAssets-margin .form-check h4 {
  font-family: graphik-medium;
  font-size: 16px;
  font-weight: 500;
  height: 19px;
  letter-spacing: -0.35px;
}

.row.addPulseAssets-margin .form-check p {
  color: rgb(99, 115, 129);
  font-family: graphik-regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin: 0;
}

.custom-file-css p {
  color: #4f555b;
  margin-bottom: 4px;
  font-family: graphik;
  font-weight: bold;
  font-size: 13px;
}

.addAssetModal .custom-file .custom-file-label {
  margin-left: 0;
}

#addPulseAssets .addAssetModal .custom-file-css label span {
  white-space: nowrap;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.font-color-black {
  color: black;
  font-weight: bold;
}

.landBox-grid p svg {
  color: #ff0000;
  margin-right: 3px;
}

/* chat bot logo css starts here */
.chatbotLogo {
  position: fixed;
  left: 0;
  bottom: 52px;
  margin: 0 !important;
  animation: fadein 5s;
  -moz-animation: fadein 5s;
  /* Firefox */
  -webkit-animation: fadein 5s;
  /* Safari and Chrome */
  -o-animation: fadein 5s;
  /* Opera */
}

.chatbotLogo:hover {
  left: 10px;
  bottom: 58px;
}

.chatbotLogo:hover img {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  background: rgba(234, 229, 229, 0.7);
  border-radius: 4px;
}

.chatbot-iframe {
  position: fixed;
  display: flex;
  height: 550px;
  width: 480px;
  bottom: 0px;
  right: 10px;
  z-index: 999 !important;
}

.chatbot-iframe p {
  position: absolute;
  right: 2px;
  top: -20px;
  z-index: 9999 !important;
}

.chatbot-iframe p::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url("../Images/Close-Purple.svg");
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* chat bot logo css ends here */

.outcome-indicate.no-indicator {
  text-align: center;
}

.outcome-indicate.no-indicator p {
  margin: 8px 0 0;
  color: #637381;
  font-family: graphik;
}

/* Offering Dropdown Start */
.offering-dropdown {
  cursor: pointer;
  position: relative;
}

.offering-dropdown:before {
  content: "\f0da";
  position: absolute;
  left: 18px;
  top: 8px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #637381;
}

.offering-dropdown.open:before {
  content: "\f0dd";
  top: 5px;
}

.offering-dropdown-menu {
  display: none;
}

.offering-dropdown.open .offering-dropdown-menu {
  display: block;
}

.offering-dropdown .offering-dropdown-menu li {
  padding: 5px 12px;
}

.clientgrouping-list li.offering-dropdown:hover {
  background: transparent;
  color: #212b36;
  font-family: graphik;
}

.clientgrouping-list li.offering-dropdown li:hover {
  background: transparent;
  color: #212b36;
  font-family: graphik;
}

.clientgrouping-list li.offering-dropdown li:hover {
  background: transparent;
  color: #212b36;
  font-family: graphik;
}

.clientgrouping-list li.offering-dropdown li:hover {
  background: rgba(1, 142, 255, 0.08);
  color: #018eff;
  font-family: Graphik-Medium;
}

.clientgrouping-list li.offering-dropdown.active-offering {
  background: rgba(1, 142, 255, 0.08);
  color: #018eff;
  font-family: Graphik-Medium;
}

/* Offering Dropdown End */

/* Commentary Window Start */
.commentry .history-header {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 24px;
  font-weight: normal;
  height: 24px;
  letter-spacing: -0.38px;
}

.commentry .backButton {
  border-radius: 20px;
  border: 1.5px solid rgb(0, 142, 255);
  height: 40px;
  width: 96px;
  color: rgb(0, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.commentry .inline {
  display: inline-block;
}

.commentry .dropdown {
  background: rgb(255, 255, 255);
  border-radius: 24px;
  border: 1px solid rgb(196, 205, 213);
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 14px 36px 12px 16px;
  max-width: 300px;
  outline: none;
}

.commentry .dropdown:focus {
  border: 1px solid rgb(1, 142, 255);
  border-radius: 24px;
}

.commentry .sortby {
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  height: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
}

.sortby-icon-up {
  background: url(../Images/sort-amount-up-alt.svg) no-repeat right;
  cursor: pointer;
}

.sortby-icon-down {
  background: url(../Images/sort-amount-down-alt.svg) no-repeat right;
  cursor: pointer;
}

.commentry .comment-details {
  background: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  padding: 10px 16px;
}

.commentry .comment-details > p {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  height: 14px;
  letter-spacing: -0.21px;
  line-height: 14px;
  margin-bottom: 0px;
}

.commentry p {
  margin-bottom: 0px;
}

.commentry p.date {
  color: rgb(26, 134, 140);
  font-family: graphik;
  font-size: 10px;
  font-weight: normal;
  height: 14px;
  letter-spacing: -0.18px;
  line-height: 14px;
}

.commentry p.user {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.commentry p > span.sky-blue {
  color: #1a868c;
}

.red-circle {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  width: 25px;
  border: 1px solid #d5002f;
  border-radius: 20px;
  font-size: 10px;
  padding: 5px;
  background: #d5002f;
  color: white;
  text-align: center;
}

.comment-window {
  position: fixed;
  width: 293px;
  height: 350px;
  opacity: 0.9;
  right: 10px;
  bottom: 50px;
  z-index: 9999;
  background: #ffffff;
  border-radius: 0px;
  box-shadow: inset 1px 0px 0px 0px rgb(244, 246, 248), 1px 0px 0px 0px rgb(223, 227, 232),
    2px 2px 16px 0px rgba(33, 43, 54, 0.1);
}

.comment-window .comments-header {
  padding: 16px;
}

.comments-header .title-with-icon h5 {
  color: #637381;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.comments-header .title-with-icon .list-icon {
  color: #919eab;
  margin-top: -10px;
}

.comments-header .outcome-name {
  color: #212b36;
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 16px;
  margin-bottom: 0px;
}

.comment-section .title-with-icon.action-links {
  border-bottom: 1px solid #dfe3e8;
  margin: 0 16px;
}

.comment-section .title-with-icon.action-links p {
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  cursor: pointer;
}

.comments-footer {
  border-top: 1px solid #dfe3e8;
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px 10px;
  justify-content: space-between;
  color: #919eab;
}

.comment-window .user-profile {
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  height: 32px;
  width: 32px;
  border-radius: 50%;
  text-align: center;
  border-radius: 50%;
}

.comments-footer .user-profile span {
  line-height: 26px;
  color: #919eab;
}

.comments-footer .comment-area {
  display: flex;
  align-items: center;
}

.comments-footer .comment-area textarea {
  margin-right: 10px;
  resize: none;
}

.comments-footer .comment-area textarea + .enable-button {
  color: #018eff;
}

.comments-body {
  padding: 5px 16px;
  height: 190px;
  overflow-y: scroll;
}

.user-specific-comment {
  margin-bottom: 20px;
}

.user-specific-comment .user-info {
  display: flex;
}

.user-specific-comment .user-info.usermessage {
  justify-content: flex-end;
}

.user-info.usermessage .user-profile {
  display: none;
}

.charts-commentsection .comments-body .user-info.usermessage .user-comment {
  background: rgb(234, 239, 251);
  border-radius: 8px;
  position: relative;
}

.charts-commentsection .comments-body .user-info.usermessage .profile-info {
  position: absolute;
  right: 10px;
}

.charts-commentsection .comments-body .user-info.usermessage .user-comment p {
  text-align: left;
}

.charts-commentsection .comments-body .user-info.usermessage .profile-info h6 {
  display: none;
}

.user-specific-comment .profile-info h6 {
  color: #637381;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-bottom: 0;
}

.user-specific-comment .profile-info {
  margin-left: 10px;
}

.user-specific-comment .profile-info span {
  color: #637381;
  font-family: graphik;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.18px;
  line-height: 14px;
}

.user-specific-comment .user-comment {
  background: rgb(255, 255, 255);
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}

.commentry .user-specific-comment .user-comment {
  margin: 10px 2px 0px 22px;
}

.user-specific-comment .user-comment.deleted {
  background: rgb(249, 250, 251);
}

.user-specific-comment .user-comment.deleted p {
  color: rgb(99, 115, 129);
}

.user-specific-comment .user-comment p {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 16px;
}

.user-specific-comment .user-actions {
  margin-left: auto;
  color: #018eff;
}

.user-specific-comment .user-actions span {
  margin-left: 10px;
}

.user-comment .time-info li {
  display: inline-block;
  position: relative;
  padding: 0 6px;
}

.user-comment .time-info li:first-child {
  padding-left: 0;
}

.user-comment .time-info li:last-child:after {
  display: none;
}

.user-comment .time-info .edited-label {
  color: #1a868c;
}

.comments-footer.radius-btn {
  justify-content: flex-end;
}

.comments-footer.radius-btn .btn-primary {
  color: #fff;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  border: none;
}

.comments-footer.radius-btn .btn-outline-secondary {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
}

.edit-links {
  padding: 0 16px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
}

.edit-links span {
  color: #018eff;
}

/* .comment-editsection{
   display: none;
} */

/* DD Screen CSS start */

.tabBar .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: transparent !important;
  color: #018eff !important;
  border-bottom: 2px solid #018eff;
}

.tabBar .nav-tabs > li > a.active,
.nav-tabs > li > a.active:hover {
  border-right: none;
  border-left: none;
  border-top: none;
}

.tabBar .nav-tabs {
  border: none;
}

.tabBar .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: transparent !important;
  color: #018eff !important;
  border-bottom: 2px solid #018eff;
}

.tabBar.nav-tabs .tabs {
  width: 100%;
}

.tabBar.nav-tabs {
  padding: 20px 10px 0 10px;
  display: inline-block;
}

.tabBar .title-with-icon {
  flex-wrap: wrap;
}

.tabBar .nav > li > a {
  color: #919eab !important;
  padding: 10px 0;
  text-decoration: none;
  margin-right: 20px;
}

.tabBar .tab-content {
  background: transparent;
}

.tabBar .nav > li,
.nav > li > a {
  float: left;
}

.tabBar .nav-tabs {
  padding: 0px 0 20px 0;
}

.tabBar .tab-content .modal-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.tabBar .show-pop {
  color: #007bff;
  background: transparent;
  box-shadow: none;
  border: none;
  font-weight: 800;
}

.tabBar .show-pop svg {
  margin-right: 10px;
}

.tabBar.show-pop:hover .fa-plus {
  color: #fff;
}

.tabBar .outcome-highlight h2 {
  display: inline-block;
}

.tabBar .list-icon {
  display: inline-block;
  float: right;
}

.widget-pop l.modal-footer {
  text-align: right;
}

.ddrwidget-col {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.ddrwidget-col .outcome-divider {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  padding: 20px 25px 20px 30px;
  box-shadow: none;
}

.ddrwidget-col h6 {
  font-weight: 600;
  color: #000000;
  font-size: 0.75rem;
  margin-bottom: 10px;
}

.ddrwidget-col .widget-activated {
  border-radius: 4px;
  border: 1.5px solid #018eff;
  position: relative;
  text-align: center;
}

.ddrwidget-col .widget-activated:after {
  content: "\F058";
  position: absolute;
  top: -10px;
  right: -4px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #7ec344;
  font-size: 18px;
  width: 17px;
  height: 18px;
  background: #fff;
}

.widget-activated .outcome-indicate:before {
  display: none;
}

.form-widget .form-control {
  height: 56px;
  font-size: 1rem;
  color: rgb(33, 43, 54);
}

.widget-pop .modal-footer {
  display: flex;
}

.widget-pop .modal-footer button.btn.btn-primary,
.widget-pop .modal-footer button.btn.btn-secondary {
  width: 120px;
  height: 48px;
}

.widgetconfig-data {
  height: 350px;
  display: table;
  width: 100%;
  text-align: center;
}

.widgetconfig-data .modal-data {
  display: table-cell;
  vertical-align: middle;
}

.widgetconfig-data .show-pop {
  background: rgb(244, 246, 248);
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 50%;
}

.widgetconfig-data .show-pop .fa-plus {
  color: #637381;
}

.widgetconfig-data .show-pop span {
  border: 1px solid #637381;
  border-radius: 100%;
  padding: 6px;
}

.widgetconfig-data .show-pop svg {
  margin-right: 0;
  margin-top: -2px;
}

.widgetconfig-data .modal-data h6 {
  font-size: 1rem;
  color: rgb(33, 43, 54);
  font-weight: 700;
}

.widgetconfig-data .modal-data p {
  color: #637381;
  font-size: 0.875rem;
}

.label-animation {
  position: relative;
}

.form-widget .select-custom {
  display: block;
}

.form-widget .select-custom:after {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 1.3rem;
}

.animated-input,
.animated-select {
  display: block;
  width: 100%;
}

.animated-input:focus,
.animated-select:focus {
  outline: none;
}

.circle-icons {
  top: 0;
  right: 12px;
  display: flex !important;
  align-items: center;
  float: none;
}

.circle-icons span {
  display: inline-block;
  border: 1px solid #dfe3e8;
  background: #f4f6f8;
  border-radius: 50%;
  padding: 6px;
  text-align: center;
  height: 32px;
  width: 32px;
  line-height: 16px;
  position: relative;
  top: -10px;
  margin-left: 15px;
  cursor: pointer;
}

.circle-icons span i {
  font-size: 14px;
  color: #7b8e98 !important;
}

.circle-icons .toggle-group {
  margin-left: 15px;
}

.addwidget-list .outcome-highlight .outcome-divider {
  padding: 10px 10px 10px 20px;
}

.addwidget-list .title-with-icon {
  margin-bottom: 15px;
}

.addwidget-list .outcome-divider p {
  color: #212b36;
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
}

.addwidget-list .outcome-divider.item p {
  color: #637381;
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
}

.processing-block .outcome-table {
  margin-bottom: 7px !important;
}

.outcome-indicate span small {
  font-size: 9px;
  color: #637381;
}

.input-information {
  color: #919eab;
  font-family: Graphik-Medium;
  font-size: 9px;
  font-weight: 500;
  margin-top: 5px;
}

.form-widget select.form-control {
  color: #919eab;
}

.form-widget select:checked,
.form-widget select:focus {
  color: rgb(33, 43, 54);
}

.form-widget .form-control:-internal-autofill-selected {
  background-color: transparent !important;
}

.delete-type .circle-icons span {
  top: 12px;
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  .dataprocessed-modal .modal-dialog {
    width: 590px;
  }

  .delete-type {
    padding: 0;
  }
}

.modal-btn .footer-btn {
  min-width: 100px;
}

.title-with-icon {
  position: relative;
}

.data-processed {
  text-align: center;
}

.data-processed h6 {
  font-size: 1rem;
  color: rgb(33, 43, 54);
  font-weight: 500;
  font-family: Graphik-Medium;
}

.data-processed p {
  color: #637381;
  font-size: 0.875rem;
}

.dataprocessed-icon {
  height: 64px;
  width: 64px;
  background: #f4f6f8;
  margin: 10px auto;
  border-radius: 100%;
}

.widget-height {
  max-height: 300px;
  overflow: auto;
}

.widget-height h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

.outcome-indicate.no-indicator:before {
  display: none;
}

.outcome-indicate.no-indicator {
  text-align: center;
}

.outcome-indicate.no-indicator p {
  margin: 8px 0 0;
  color: #637381;
  font-family: graphik;
}

.title-with-icon.align-icon {
  display: block;
  width: 100%;
  text-align: right;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}

.title-with-icon.align-icon svg {
  margin-right: 5px;

  position: relative;
  top: -2px;
}

/*DD Screen End*/
/* Commentary Window Start */
.commentry .history-header {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: -0.38px;
}

.commentry .backButton {
  border-radius: 20px;
  border: 1.5px solid rgb(0, 142, 255);
  color: rgb(0, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  background: #ffffff;
  padding: 12px 18px;
}

.commentry .inline {
  display: inline-block;
}

.commentry .dropdown {
  background: rgb(255, 255, 255);
  border-radius: 24px;
  border: 1px solid rgb(196, 205, 213);
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 14px 36px 12px 16px;
  max-width: 270px;
  min-width: 200px;
}

.commentry .dropdown:focus {
  border: 1px solid rgb(1, 142, 255);
  border-radius: 24px;
}

.commentry .sortby {
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  height: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
}

.sortby-icon-up {
  background: url(../Images/sort-amount-up-alt.svg) no-repeat right;
  cursor: pointer;
}

.sortby-icon-down {
  background: url(../Images/sort-amount-down-alt.svg) no-repeat right;
  cursor: pointer;
}

.commentry .comment-details {
  background: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  padding: 10px 16px;
}

.commentry .comment-details > p {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  height: 14px;
  letter-spacing: -0.21px;
  line-height: 14px;
  margin-bottom: 0px;
}

.commentry p {
  margin-bottom: 0px;
}

.commentry p.date {
  color: rgb(26, 134, 140);
  font-family: graphik;
  font-size: 10px;
  font-weight: normal;
  height: 14px;
  letter-spacing: -0.18px;
  line-height: 14px;
}

.commentry p.user {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.commentry p > span.sky-blue {
  color: #1a868c;
}

.red-circle {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 25px;
  width: 25px;
  border: 1px solid #018eff;
  border-radius: 20px;
  font-size: 10px;
  padding: 5px;
  background: #018eff;
  color: white;
  text-align: center;
}

.comment-window {
  position: fixed;
  width: 293px;
  height: 350px;
  right: 10px;
  bottom: 50px;
  z-index: 9999;
  background: #ffffff;
  border-radius: 0px;
  box-shadow: inset 1px 0px 0px 0px rgb(244, 246, 248), 1px 0px 0px 0px rgb(223, 227, 232),
    2px 2px 16px 0px rgba(33, 43, 54, 0.1);
}

.comment-window .comments-header {
  padding: 16px;
}

.comments-header .title-with-icon h5 {
  color: #637381;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.comments-header .title-with-icon .list-icon {
  color: #919eab;
  margin-top: -10px;
}

.comments-header .outcome-name {
  color: #212b36;
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 16px;
  margin-bottom: 0px;
}

.comment-section .title-with-icon.action-links {
  border-bottom: 1px solid #dfe3e8;
  margin: 0 16px;
}

.comment-section .title-with-icon.action-links p {
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  cursor: pointer;
}

.comments-footer {
  border-top: 1px solid #dfe3e8;
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px 10px;
  justify-content: space-between;
  color: #919eab;
}

.dashboard-component .comment-overlay .comments-footer {
  background: #fff;
}

.comment-window .user-profile {
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  height: 32px;
  width: 32px;
  border-radius: 50%;
  text-align: center;
  border-radius: 50%;
}

.comments-footer .user-profile span {
  line-height: 26px;
  color: #919eab;
}

.comments-footer .comment-area {
  display: flex;
}

.comments-footer .comment-area textarea {
  margin-right: 10px;
  resize: none;
}

.comments-footer .comment-area textarea + .enable-button {
  color: #018eff;
}

.comments-body {
  padding: 5px 16px;
  height: 190px;
  overflow-y: scroll;
}

.user-specific-comment {
  margin-bottom: 20px;
}

.comments-body .user-info {
  display: flex;
}

.comments-body .profile-info h6 {
  color: #637381;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-bottom: 0;
}

.comments-body .profile-info {
  margin-left: 10px;
}

.comments-body .profile-info span {
  color: #637381;
  font-family: graphik;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.18px;
  line-height: 14px;
}

.comments-body .user-comment {
  background: rgb(255, 255, 255);
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
}

.comments-body .user-comment p {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 16px;
}

.user-specific-comment .user-actions {
  margin-left: auto;
  color: #018eff;
}

.user-specific-comment .user-actions span {
  margin-left: 10px;
}

.user-comment .time-info li {
  display: inline-block;
  position: relative;
  padding: 0 6px;
}

.user-comment .time-info li:after {
  content: "";
  position: absolute;
  top: 1px;
  right: 0px;
  width: 1px;
  height: 10px;
  background: rgb(99, 115, 129);
}

.user-comment .time-info li:first-child {
  padding-left: 0;
}

.user-comment .time-info li:last-child:after {
  display: none;
}

.user-comment .time-info .edited-label {
  color: #1a868c;
}

.comments-footer.radius-btn {
  justify-content: flex-end;
}

.comments-footer.radius-btn .btn-primary {
  color: #fff;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  border: none;
}

.comments-footer.radius-btn .btn-outline-secondary {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
}

.edit-links {
  padding: 0 16px;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
}

.edit-links span {
  color: #018eff;
}

.comments-footer.radius-btn .btn-outline-secondary:hover,
.comments-footer.radius-btn .btn-outline-secondary:active {
  background: transparent;
  border: 1px solid #ced4da;
  color: #637381;
}

.comment-overlay:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.5;
  top: 0;
  z-index: 9;
}

.overlay-comment {
  z-index: 999;
}

.commentry .user-comment .time-info ul {
  margin-bottom: 0;
}

.commentry .selectdropdown-list li {
  display: inline-block;
}

.selectdropdown-list li .select-custom::after {
  height: 8px;
  width: 8px;
  right: 1em;
  border-bottom: 2px solid #919eab;
  border-right: 2px solid #919eab;
}

.commentry .dropdown {
  background: rgb(255, 255, 255);
  border-radius: 24px;
  border: 1px solid rgb(196, 205, 213);
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 12px 18px 12px 8px;
}

.commentry .dropdown:focus {
  border: 1px solid rgb(1, 142, 255);
  border-radius: 24px;
}

.tile-card-border .card-overlay button span {
  width: 110px !important;
}

/* Commentry css added by Srihari 20-Aug-20 Starts*/

.comment-editsection .edit-links h6 {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.31px;
  line-height: 20px;
}

.comment-editsection .edit-links h6 span svg {
  width: 23px;
  height: 23px;
  margin-right: 10px;
}

.comment-editsection .comments-footer button {
  width: 96px;
  height: 32px;
  line-height: 3px;
  margin: 4px;
}

/* Commentry css added by Srihari 20-Aug-20 Ends*/

/* Commentry css ends*/

/** Start - Changes for DD Widget Configurator**/
.outcome-bottom > span.metric-digit-text {
  margin-bottom: 5px;
  max-height: none;
}

.metric-digit-text.additional-metric {
  margin-left: 3px;
}

/** End - Changes for DD Widget Configurator**/

.processing-block .title-with-icon {
  flex-wrap: nowrap;
}

.outcome-highlight.no-table-layout .processing-block h2 {
  white-space: normal;
}

.addwidget-list .outcome-divider.item p {
  word-break: normal;
}

/* Category List Styles */
/* .category-lists ul li:first-child span {
  font-weight: 500;
  font-family: Graphik-Medium;
  font-size: 16px;
  line-height: 20px;
} */
.category-lists ul li span.contentName {
  width: 40%;
  line-height: 16px;
}

.category-lists ul li span.contentValues {
  width: 60%;
  text-align: right;
}

.categoryType ul.info-metric-span li {
  width: 95%;
}

.info-metric-span li .info-categoryname {
  width: 65%;
  margin-right: 10px;
}

/* Column Layout Implementation in MTC */
.addwidget-list.custom-columnlayout .row {
  column-count: 2;
  display: block;
  flex-wrap: nowrap;
}

.addwidget-list.custom-columnlayout .row .col-lg-6 {
  max-width: 100%;
  flex: none;
  break-inside: avoid-column;
  break-inside: avoid;
  -webkit-column-break-inside: avoid;
  overflow: hidden;
  margin-bottom: 0px;
}

.custom-columnlayout .circle-icons .form-group {
  margin-bottom: 0;
  margin-top: 15px;
}

.addwidget-list.custom-columnlayout .title-with-icon {
  margin-bottom: 0;
  align-items: center;
}

.custom-columnlayout .circle-icons span {
  top: 3px;
}

.custom-columnlayout .outcome-highlight {
  margin: 5px 0 12px;
}

.widget-height .outcome-divider p.minht.fd-heading {
  color: #212b36 !important;
  height: 34px;
}

.widget-height .outcome-divider .outcome-bottom .metric-details.mb-10 {
  margin-bottom: 15px;
}

.outcome-indicate p.mtc-minht {
  font-family: Graphik-Medium !important;
  color: #212b36 !important;
  height: 34px;
}

.mtc-columnlayout span.metric-details.mb-10 {
  margin-bottom: 10px;
}

.widget-height .outcome-divider .outcome-bottom .metric-digit {
  font-size: 18px;
}

.mtc-columnlayout span.metric-digit {
  font-size: 18px;
}

.widget-height .outcome-divider .outcome-bottom .metric-digit span {
  margin-left: 5px;
  line-height: 20px;
}

.mtc-columnlayout .metric-digit span {
  margin-left: 5px;
  line-height: 20px;
}

.widget-height .outcome-divider p {
  word-break: break-word;
}

/* React-Select library css starts here  // Created by Srihari 24-Aug-2020 */
/* .reactCustom-Select-Container {
   display: flex;
}
.reactCustom-select {
   width: 270px;
   margin-right: 12px;
   cursor: pointer;
}
.reactCustom-select .css-1okebmr-indicatorSeparator {
   display: none;
}
.reactCustom-select .css-tlfecz-indicatorContainer, .reactCustom-select .css-11unzgr, .reactCustom-select .css-4ljt47-MenuList, .reactCustom-select .css-1gtu0rj-indicatorContainer {
   padding:0
}

.reactCustom-select .css-4ljt47-MenuList, .reactCustom-select .css-11unzgr, .reactCustom-select .css-1wa3eu0-placeholder{
   color: rgb(99, 115, 129);
   font-size: 14px;
   font-family: Graphik-Medium;
   font-weight: 500;
   letter-spacing: -0.25px;
   line-height: 14px;
   border-radius: 4px;
   max-height: 280px;
   word-break: break-word;
   overflow-wrap: break-word;
}
.reactCustom-select .css-1uccc91-singleValue {
   color: rgb(99, 115, 129);
   font-size: 14px;
   font-family: Graphik-Medium;
   font-weight: 500;
}
.reactCustom-select .css-yk16xz-control,  .reactCustom-select .css-1pahdxg-control{
   background: rgb(255, 255, 255);
   border: 1px solid rgb(196, 205, 213);
   border-radius: 24px;
   padding: 2px 0px;
}

.reactCustom-select .css-26l3qy-menu{
    z-index:999;
    margin-top: 4px;
    word-break: break-word;
    overflow-wrap: break-word;
}
.reactCustom-select .css-1pahdxg-control:hover, .reactCustom-select .css-yk16xz-control:hover{
   border: 1px solid rgb(196, 205, 213);
   cursor: pointer;
}
.reactCustom-select .css-1n7v3ny-option{
   cursor: pointer;
} */
.commentry .selectdropdown-list {
  display: flex;
  align-items: center;
}

/* React-Select library css ends here */

/* DD UAT Fixes */
.dd-widget .nav-item .navlink {
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}

.dd-widget .nav-item .nav-link {
  border: none;
  cursor: pointer !important;
}

.dd-widget .nav-item .nav-link.active {
  border-bottom: none;
  border: none;
}

.dd-widget .nav-item .nav-link.active:after {
  content: " ";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-radius: 3px 3px 0px 0px;
  height: 4px;
  background-color: #018eff;
}

.dd-widget .show-pop {
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
}

.dd-widget .widgetconfig-data .show-pop {
  width: 64px;
  height: 64px;
  text-align: center;
  padding: 0;
}

.dd-widget .widgetconfig-data .show-pop span {
  width: 22px;
  display: block;
  height: 22px;
  margin: 0 auto;
  padding: 0;
}

.dd-widget .widgetconfig-data .show-pop .fa-plus {
  font-size: 12px;
  margin-top: 0;
}

.custom-columnlayout .outcome-highlight {
  min-height: 138px;
  height: auto !important;
  height: 138px;
}

.custom-columnlayout .outcome-divider span.metric-details {
  line-height: 24px;
  height: auto;
}

/* DD UAT Fixes end */

.period-info {
  margin: 4px 0px 12px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.period-info p {
  font-size: 14px;
  font-family: Graphik-medium;
  font-weight: 800;
  color: #212b36;
  position: relative;
  top: -8px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  width: 60%;
  letter-spacing: -0.25px;
}

.outcome-highlight.ratioData.hybridEle {
  padding: 8px;
}

.period-info h2 {
  width: 40%;
}

.period-info p span {
  font-size: 16px;
  font-family: graphik;
  font-weight: 500;
}

/*css for warning msg - start*/
.warningMsg {
  color: #e7a024;
  letter-spacing: -0.25px;
  font-size: 14px;
  text-align: center;
  background: #fbf0dc;
  border: 1px solid #f09f13;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  margin: 8px 0;
  display: block;
}

.remarks-icon svg,
.remarks-popup a .fa-info-circle {
  font-size: 20px;
  color: red;
}

/*css for warning msg - end*/
/* get lastmonth changes */
.button-get-data,
.button-get-data:focus {
  color: rgb(161, 0, 255) !important;
  background-color: rgb(255, 255, 255) !important;
  font-weight: bold;
  padding: 7px 14px 7px 14px;
  border-radius: 20px;
  font-size: 12px;
  border: 1.5px solid rgb(161, 0, 255) !important;
  box-shadow: none;
}

.button-get-data:hover,
.button-get-data:active,
.button-get-data:focus:active {
  color: rgb(255, 255, 255) !important;
  background: rgb(179, 51, 255) !important;
  border-radius: 24px;
  border: 1.5px solid rgb(179, 51, 255) !important;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
}

.button-reject,
.button-reject:hover {
  color: #aa0025 !important;
  background-color: white;
  padding: 7px 16px 7px 16px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 12px;
  border: 1.5px solid #aa0025;
}

.custom-datepicker-header > p {
  margin: 0px;
}

.custom-datepicker-header > p > span:first-of-type,
.custom-datepicker-header > p > span:last-of-type {
  cursor: pointer;
}

.custom-datepicker-header {
  padding: 10px;
  width: 255px;
  border: 1px solid rgb(223, 227, 232);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.custom-datepicker {
  border: 1px solid rgb(223, 227, 232);
  height: 203px;
  width: 257px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.custom-datepicker-body > p {
  width: 85px;
  height: 40px;
  margin: 0px;
  float: left;
  padding: 10px 20px 10px 20px;
  font-size: 14px;
  color: rgb(99, 115, 129);
  font-weight: bold;
  border: 1px solid rgb(223, 227, 232);
  position: relative;
  cursor: pointer;
}

.custom-datepicker-body > p.disabled {
  color: rgb(196, 205, 213);
}

.custom-datepicker-body > p > span {
  position: absolute;
  right: 5px;
  top: 3px;
}

.custom-datepicker-body > p > span > p {
  margin: 0px;
}

.custom-datepicker-body > p > span > p.green {
  color: #7ec344;
  font-size: 12px;
}

.custom-datepicker-body > p > span > p.orange {
  color: #f49342;
  margin-top: -5px;
  font-size: 12px;
}

.custom-picker-wrapper {
  width: 270px;
  padding: 6px;
  /* background: rgb(255, 255, 255);
border-radius: 8px;
border: 1px solid rgb(223, 227, 232);
box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1); */
  height: 290px;
}

.custom-picker-wrapper .form-group.border-group {
  padding: 0 !important;
  margin-bottom: 10px !important;
  border: none;
}

.custom-picker-wrapper .customform-dropdown .dropdown {
  height: unset;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #ccc;
}

.custom-picker-wrapper .customform-dropdown .dropdown.show {
  background: rgb(244, 251, 255);
  border: 1.5px solid rgb(1, 142, 255);
  box-shadow: 0px 0px 8px 0px rgb(102 187 255 / 50%);
}

.custom-picker-wrapper .customform-dropdown .dropdown .dropdown-toggle:after {
  top: 10px;
  right: 16px;
  color: rgb(21, 24, 27);
}

.custom-picker-wrapper .customform-dropdown .dropdown.show .dropdown-toggle:after {
  top: 24px;
  right: 10px;
  transform: rotate(180deg);
}

.custom-picker-wrapper
  .customform-dropdown
  .offering-droplistwrapper
  .dropdown-item-text
  .form-check-label {
  padding: 8px 12px;
}

.custom-picker-wrapper .customform-dropdown .dropdown .dropdown-toggle span {
  color: rgb(21, 24, 27);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-picker-wrapper .form-group.border-group .dropdown.show .dropdown-toggle {
  background: rgb(244, 251, 255);
}

.custom-picker-wrapper .customform-dropdown .dropdown-menu {
  top: 10px !important;
  width: 102% !important;
  left: -4% !important;
}

.custom-datepicker-footer > p > .badge-icon {
  font-size: 16px;
  color: #7ec344;
  padding-left: 5%;
}

.custom-datepicker-footer > p > .badge-icon > span,
.custom-datepicker-footer > p > .eye-icon > span {
  font-size: 10px;
  padding-left: 10px;
}

.custom-datepicker-footer > p > .eye-icon {
  font-size: 16px;
  color: #f49342;
  padding-left: 30%;
}

.p-l-40p {
  padding-left: 40%;
}

.p-l-35p {
  padding-left: 35%;
}

.f-s-12 {
  font-size: 12px;
}

.m-t-2-n {
  margin-top: -2px;
}

.f-s-20 {
  font-size: 20px;
}

.p-l-30p.year {
  position: relative;
}

.p-l-30p.year > span {
  padding-left: 5px;
  position: absolute;
}

.m-t-5 {
  margin-top: 5px;
}

.p-t-15 {
  padding-top: 15px;
}

.customCalender {
  z-index: 222;
}

.succes-tick {
  font-size: 40px;
  color: #7ec344;
}

.pipe-end::after {
  content: "";
  widows: 5px;
  height: 10px;
  background-color: black;
}

.border-line-end {
  background-color: #dfe3e8;
  padding: 3px 1px;
  margin: 0px 12px;
}

.border-line-end.publish-border {
  height: 22px;
}

/* get lastmonth changes  end*/
/* DD UAT Fixes end */

.period-info {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  font-family: graphik-medium;
  font-weight: 800;
  color: #212b36;
}

.period-info span {
  font-size: 16px;
  font-family: graphik;
  font-weight: 500;
}

.colour-red > svg {
  color: #dc1641 !important;
}

#custom-date-picker-popup {
  width: 690px;
}

#custom-date-picker-popup p:first-of-type {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
  height: 20px;
  letter-spacing: -0.35px;
  line-height: 20px;
}

#custom-date-picker-popup p:last-of-type {
  color: rgb(99, 115, 129);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 32px;
  letter-spacing: -0.35px;
  line-height: 28px;
}

#custom-date-picker-popup .form-check-label {
  color: rgb(33, 43, 54);
  font-family: Graphik-Regular;
  font-size: 16px;
  font-weight: normal;
  height: 14px;
  letter-spacing: -0.33px;
  line-height: 20px;
}

/* Terms of Use CSS starts here */
.termsContainer .modal-dialog {
  max-width: 60% !important;
}

.termsUse {
  font-size: 24px;
  font-family: graphik-medium;
  letter-spacing: -0.25px;
  margin: 0;
}

.termsList p {
  font-size: 16px;
  font-family: graphik;
  letter-spacing: -0.25px;
}

/* Terms of USe CSS ends here */
.delete-iconcss {
  position: relative;
}

.delete-iconcss span .del-row-icon {
  position: absolute;
  top: 0;
  right: 12px;
}

.info-tooltipdata svg {
  top: -8px;
  position: absolute;
  right: -24px;
}

/* Pulse Summary CSS Start */
.summary-modal .modal-dialog {
  max-width: 690px;
}

.summary-modal .modal-body {
  padding: 0;
}

.summary-modal .summary-header {
  padding: 2rem;
}

.summary-header h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
  margin-bottom: 20px;
}

.summary-header .filtered-value span {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  display: block;
  margin-bottom: 5px;
}

.summary-header .filtered-value span:last-child {
  margin-bottom: 0;
}

.summary-body #filter-accordion {
  border: none;
  overflow: inherit;
}

.summary-body #filter-accordion .card {
  border: none;
  border-radius: 0;
}

.summary-body #filter-accordion .card-header {
  border: none;
  border-radius: 0;
  padding: 0.5rem 2rem;
  background: #f9fafb;
}

.summary-body #filter-accordion .card-link {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
  position: relative;
  cursor: pointer;
}

.summary-body #filter-accordion .card-link::before,
.summary-body #filter-accordion .card-link.collapsed::before {
  display: none;
}

/* new code */
.summary-body #filter-accordion .card-link:after {
  content: "\f067";
  position: absolute;
  right: 0;
  top: 0;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
}

.summary-body #filter-accordion .card-link[aria-expanded="true"]:after {
  content: "\f068";
}

.filtered-records {
  padding: 1.5rem 2rem;
  height: 320px;
  overflow-y: auto;
}

.filtered-records .records-row {
  display: flex;
  flex-wrap: wrap;
}

.filtered-records .record-divider {
  padding: 5px 10px 5px 16px;
  margin: 5px 0 10px;
  width: 33%;
}

.filtered-records .record-indicate {
  position: relative;
  height: 88px;
  padding-top: 5px;
}

.filtered-records .record-indicate:before {
  position: absolute;
  content: "";
  background: #919eab;
  width: 8px;
  height: 100%;
  border-radius: 4px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  left: -16px;
  top: 0;
}

.record-indicate h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.35px;
  margin-bottom: 20px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.record-indicate .record-digit {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.25px;
}

.record-indicate .record-digit .record-digit-text {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 20px;
}

.summary-modal .login-btn {
  padding: 0.5em 2em;
  margin-bottom: 20px;
}

.summary-modal .login-btn .btn {
  border-radius: 24px;
  border: 1.5px solid rgb(1, 142, 255);
  height: 48px;
  width: 133px;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.summary-modal .new-dashboard-ui-login-btn {
  display: inline-block;
  margin-right: 0.5rem !important;
  padding: 0;
  margin-bottom: 0;
}

.summary-modal .new-dashboard-ui-login-btn .btn {
  height: 34px;
  background-color: #018eff;
  border-color: #018eff;
  color: #fff;
  font-family: Graphik-Medium;
  font-size: 14px;
  box-shadow: none;
  border-radius: 0px;
}

.gph-stat-modal-container .modal-content {
  background-color: #0058ff;
  height: 90vh;
}

.access-denied-header {
  color: #b926b9b8;
  font-weight: 600;
  font-size: 20px;
  font-family: Graphik-Medium;
}

.access-denied-description {
  font-family: graphik;
  text-align: center;
}

.gph-dashboard-header {
  padding-top: 0.5rem !important;
}

#gph-dashboard-container {
  zoom: 0.75;
  height: 500px;
  overflow-y: scroll;
}

#gph-dashboard-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;
  background: #0242c6 !important;
}

.new-dashboard-ui-summary-modal .modal-content::-webkit-scrollbar,
#gph-dashboard-container::-webkit-scrollbar {
  border-radius: 5px;
  height: 7px;
  width: 10px;
}

#gph-dashboard-container::-webkit-scrollbar-thumb {
  background: #000663 !important;
  border-radius: 10px;
}

.sisense-css-module .popup-host.simple {
  top: calc(90vh - 38%) !important;
}

.new-dashboard-ui-summary-modal .modal-content {
  height: 90vh;
  overflow-y: scroll;
}

.new-dashboard-ui-summary-modal .close {
  margin-top: 5px;
}

.new-dashboard-ui-casestudy-modaling .maximized-view-zoom {
  zoom: 0.95;
}

.new-dashboard-ui-casestudy-modaling .minimized-view-zoom {
  zoom: 0.86;
}

.new-dashboard-ui-casestudy-modaling #row1 {
  zoom: 0.75;
}

.filters-section h6 {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 20px;
}

.location-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.location-dropdown .location-check-list {
  width: 256px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 11111;
  padding-top: 10px;
}

.location-check-list ul {
  margin: 0;
  height: 170px;
  overflow-y: auto;
}

.location-check-list ul li {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  padding: 10px 12px 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.location-check-list ul li:hover {
  background: rgb(244, 246, 248);
}

.location-dropdown .selected-location,
.select-filters .select-custom select {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  top: 5px;
  display: inline-block;
  border-radius: 6px;
  border: 1px solid rgb(151, 151, 151);
  height: 35px;
  min-width: 132px;
  width: auto;
  padding: 6px 10px;
}

.location-dropdown .selected-location:after {
  content: "\F107";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 6px;
  color: #212b36;
  font-size: 14px;
}

.location-footer {
  background: #f4f6f8;
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.location-footer .btn {
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  width: 65px;
}

.location-check-list .form-check .checkmark {
  width: 20px;
  height: 20px;
}

.location-check-list .checkmark:after {
  left: 6px;
  top: 3px;
}

.location-check-list .form-check-label {
  bottom: 0;
  margin-left: 10px;
}

.summary-body .card-body {
  background: #f9fafb;
  padding: 0.5rem 2rem 1rem;
}

.records-accordion .card {
  border: none;
}

.records-accordion .card-header {
  background: transparent;
  padding: 5px 0px;
  border: none;
}

.records-accordion .card-header .card-link {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}

.card-link::before,
.card-link.collapsed::before {
  display: none;
}

.records-accordion .card-link:after {
  content: "\f107";
  position: absolute;
  right: 0;
  top: 0;
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  color: #212b36;
}

.records-accordion .card-link.collapsed:after {
  content: "\f106";
}

.records-accordion .card-link[aria-expanded="true"]:after {
  content: "\f107";
}

.records-accordion .card-body {
  padding: 0.5em 0em;
}

.filters-section {
  display: flex;
}

.select-filters {
  margin: 0 5px;
  /* new code */
}

.select-filters .select-custom:after {
  border: 1px solid transparent;
  border-bottom: 2px solid #212b36;
  border-right: 2px solid #212b36;
  height: 7px;
  width: 7px;
  right: 1.2em;
  top: 2px;
}

.location-filter {
  margin-right: 10px;
  width: 132px;
}

.location-filter.singlefilter {
  margin-right: 5px;
  width: auto;
}

.select-filters .select-custom select:focus {
  outline: none;
  border: 1px solid rgb(151, 151, 151);
}

.location-dropdown-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 14%;
  left: 0;
  bottom: 0;
  right: 0;
}

.record-heading-tooltip {
  position: absolute;
  left: 5px;
  top: 22px;
  background: #fff;
  border: 1px solid #919eab;
  opacity: 0;
  z-index: 10;
  font-size: 12px;
  padding: 8px;
  font-family: graphik;
  font-weight: normal;
  line-height: 16px;
  border-radius: 8px;
}

.record-heading {
  position: relative;
  margin-right: 25px;
}

.record-heading:hover .record-heading-tooltip {
  opacity: 1;
}

.selected-location-list {
  margin-top: 10px;
}

.selected-location-list ul {
  margin: 0;
  line-height: 12px;
}

.selected-location-list ul li {
  display: inline-block;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 12px;
  position: relative;
  margin: 0 5px;
}

.selected-location-list ul li:after {
  content: "|";
  position: absolute;
  right: -6px;
  top: 0px;
}

.selected-location-list ul li:last-child:after {
  display: none;
}

.filter-disabled .card-header {
  pointer-events: none;
  opacity: 0.75;
}

.summary-body #filter-accordion.filter-disabled .card-link {
  color: rgba(33, 43, 54, 0.75);
}

.filter-disabled .card-header {
  pointer-events: none;
  opacity: 0.75;
}

.filtered-records .score-status {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  text-align: right;
}

.filtered-records .pmm-recordsrow .record-divider {
  width: 30%;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  padding: 5px 10px 0px 16px;
  margin: 5px 10px 10px;
}

.filtered-records .pmm-recordsrow .record-indicate:before {
  width: 4px;
}

/* Header Client  Selection css starts here*/
.header-selectclient .client-select-dropdown {
  /* display: inline-block; */
  max-width: none !important;
  display: flex;
  align-items: center;
}

.navbarBat.newBanner .header-selectclient .client-select-dropdown::after {
  right: 0;
  top: 4px;
}

.header-selectclient .client-select-dropdown .client-name {
  overflow: hidden;
  white-space: nowrap;
  /* top: 4px; */
  cursor: pointer;
  display: inline-block;
  color: #212b36;
  padding-left: 0px;
  font-size: 16px;
  letter-spacing: -0.25px;
  font-family: graphik-medium;
}

.header-selectclient .client-select-dropdown .client-name:after {
  top: 2px;
  font-size: 16px;
  cursor: pointer;
}

.header-selectclient .client-select-list ul {
  max-height: 281px;
  overflow: scroll;
  overflow-x: hidden;
}

/* width */
.header-selectclient .client-select-list ul::-webkit-scrollbar,
.main-sidebar.newsidebar-menu:hover::-webkit-scrollbar,
.main-sidebar.newsidebar-menu.sidebar-open::-webkit-scrollbar,
.custombg-headerdropdown .dropdown-::-webkit-scrollbar,
.custom-headerdropdown .dropdown-menu .offering-droplistwrapper::-webkit-scrollbar,
.livefeed-dropdowns .dropdown-menu .offering-droplistwrapper::-webkit-scrollbar,
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu::-webkit-scrollbar,
.clientstories-modal .card-row::-webkit-scrollbar,
.snapshot-popover
  .snapshot-select
  .snapshot-links::-webkit-scrollbar
  .sidebarmenu-childmenu-block::-webkit-scrollbar,
.custom-screen-scroll::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.header-selectclient .client-select-list ul::-webkit-scrollbar-track,
.main-sidebar.newsidebar-menu:hover::-webkit-scrollbar-track,
.main-sidebar.newsidebar-menu.sidebar-open::-webkit-scrollbar-track,
.custombg-headerdropdown .dropdown-::-webkit-scrollbar-track,
.custom-headerdropdown .dropdown-menu .offering-droplistwrapper::-webkit-scrollbar-track,
.livefeed-dropdowns .dropdown-menu .offering-droplistwrapper::-webkit-scrollbar-track,
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu::-webkit-scrollbar-track,
.clientstories-modal .card-row::-webkit-scrollbar-track,
.snapshot-popover .snapshot-select .snapshot-links::-webkit-scrollbar-track,
.sidebarmenu-childmenu-block::-webkit-scrollbar-track,
.custom-screen-scroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.header-selectclient .client-select-list ul::-webkit-scrollbar-thumb,
.main-sidebar.newsidebar-menu:hover::-webkit-scrollbar-thumb,
.main-sidebar.newsidebar-menu.sidebar-open::-webkit-scrollbar-thumb,
.custombg-headerdropdown .dropdown-menu::-webkit-scrollbar-thumb,
.custom-headerdropdown .dropdown-menu .offering-droplistwrapper::-webkit-scrollbar-thumb,
.livefeed-dropdowns .dropdown-menu .offering-droplistwrapper::-webkit-scrollbar-thumb,
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu::-webkit-scrollbar-thumb,
.clientstories-modal .card-row::-webkit-scrollbar-thumb,
.snapshot-popover .snapshot-select .snapshot-links::-webkit-scrollbar-thumb,
.sidebarmenu-childmenu-block::-webkit-scrollbar-thumb,
.custom-screen-scroll::-webkit-scrollbar-thumb {
  background: #dfe3e8;
  border-radius: 1px;
}

/* Handle on hover */
.header-selectclient .client-select-list ul::-webkit-scrollbar-thumb:hover,
.main-sidebar.newsidebar-menu:hover::-webkit-scrollbar-thumb:hover,
.main-sidebar.newsidebar-menu.sidebar-open::-webkit-scrollbar-thumb:hover,
.custombg-headerdropdown .dropdown-menu::-webkit-scrollbar-thumb:hover,
.custom-headerdropdown .dropdown-menu .offering-droplistwrapper::-webkit-scrollbar-thumb:hover,
.livefeed-dropdowns .dropdown-menu .offering-droplistwrapper::-webkit-scrollbar-thumb:hover,
.offering-dropdownsubmenu .dropdown-submenu .dropdown-menu::-webkit-scrollbar-thumb:hover,
.clientstories-modal .card-row::-webkit-scrollbar-thumb:hover,
.snapshot-popover .snapshot-select .snapshot-links::-webkit-scrollbar-thumb:hover,
.sidebarmenu-childmenu-block::-webkit-scrollbar-thumb:hover,
.custom-screen-scroll::-webkit-scrollbar-thumb:hover {
  background: #dfe3e8;
}

.select-client-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px 4px 20px;
  margin: 0;
}

.select-client-text span {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
}

.select-client-text span.clear {
  color: rgb(1, 142, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 0px;
  line-height: 12px;
  cursor: pointer;
}

.header-selectclient .client-select-list ul li {
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.header-selectclient .client-select-list ul li:hover,
.header-selectclient .client-select-list ul li.active {
  color: rgb(33, 43, 54);
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
}

.header-selectclient .client-select-list {
  padding-bottom: 6px;
}

.header-selectclient .client-select-list-overlay {
  position: fixed !important;
  max-width: 100% !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.header-selectclient .client-select-list-overlay::after {
  display: none;
}

.header-selectclient .client-select-dropdown .client-select-list {
  left: 0;
  box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 38px;
  width: 290px;
  border-radius: 8px;
  z-index: 9999 !important;
  background-color: #fff;
  padding-right: 4px;
}

.header-selectclient .searchFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  line-height: 10px;
}

/* Header Client  Selection css ends here*/
.open-clientgrouping-list {
  display: none;
}

.isActiveCSS {
  position: relative;
  top: 32px;
}

span.publishedOn {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.19px;
  display: flex;
  padding-bottom: 24px;
  padding-left: 2px;
  min-height: 42px;
}

/* .stage-heading {
  margin-bottom: 12px;
} */
/* Client Onboarding Fixes */
.search-nav .nav-item.groupby-filter {
  margin-right: 15px;
}

.addUserModal .modal-content {
  overflow: initial;
}

.summary-header h4 img {
  margin-right: 15px;
}

.summary-header h4 .svg-inline--fa {
  margin-right: 15px;
  width: 50px;
  height: 50px;
  cursor: default;
}

.summary-header h4 .svg-inline--fa.fa-gem {
  color: red;
}

/* Notification-Button Start */
.notification-modal .modal-dialog {
  width: 384px;
  position: absolute;
  right: 20px;
  top: 60px;
  margin: 0px;
}

.notification-modal .modal-content {
  border-radius: 8px;
  padding: 4px;
  /* max-height: 420px;
  overflow-y: auto; */
}

.notification-modal .notifications-list ul {
  max-height: 420px;
  overflow-y: auto;
}

.notification-modal .modal-body {
  padding: 0;
}

.notifications-list h4 {
  color: #5f7383;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
}

.notifications-list .new-notification h4 {
  color: #008dff;
}

.notifications-list h4 sup {
  /* position: absolute; */
  /* right: -25px; */
  /* top: -5px; */
  font-family: Graphik-Bold;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -0.18px;
  top: -6px;
  left: 2px;
}

.notifications-list li {
  padding: 10px 14px;
  border-bottom: 1px solid #dee3e9;
}

.notifications-list li:last-child {
  border-bottom: none;
}

.notifications-list li ul li,
.notifications-list li ul li span {
  padding: 0;
  list-style-type: disc;
  border-bottom: none;
  margin-left: 15px;
  color: rgb(99, 115, 129) !important;
  font-size: 12px !important;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 16px;
}

.notifications-list li ul li span {
  margin-left: 0px;
}

.notifications-list p.seeAll {
  text-align: right;
  padding-right: 10px;
}

.notifications-list p.seeAll span {
  cursor: pointer;
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 12px;
  letter-spacing: -0.25px;
  line-height: 12px;
}

.notifications-list p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin-bottom: 5px;
  margin-left: 3px;
}

.notifications-list p.seeAll {
  text-align: right;
  padding-right: 10px;
}

.notifications-list p.seeAll span {
  cursor: pointer;
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  height: 12px;
  letter-spacing: -0.25px;
  line-height: 12px;
}

.notifications-list li ul li p {
  margin-bottom: 0;
}

.empty-notification {
  display: flex;
  height: 145px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-notification span {
  margin-bottom: 10px;
}

.empty-notification p {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 22px;
}

.modal-btn.flex-btns {
  justify-content: space-between;
}

.flex-btns .btn {
  height: 48px;
  width: 120px;
}

.notification-link .active-notification,
span.homepage-notification,
.notification-link > span {
  position: relative;
}

.search-nav .nav-item a.notification-link:not(.dropdown-toggle):hover:after {
  top: 20px;
  right: 6.5px;
  top: -3.5px;
}

.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link.notification-link {
  opacity: 1;
}

.nav-item .notification-link span i {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
}

.time-dropdown .client-name {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  height: 34px;
  padding: 0.375rem 0.75rem;
  position: relative;
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.time-dropdown .client-name:after {
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  right: 8px;
  top: 5px;
}

.time-select-list {
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 195px;
  overflow-y: auto;
  padding: 15px 25px;
  position: relative;
  top: 0px;
  left: 0;
  z-index: 11111;
}

.time-select-list ul {
  border: 1px solid rgb(223, 227, 232);
  margin: 0;
}

.time-select-list ul li {
  padding: 5px 9px;
  border-bottom: 1px solid rgb(223, 227, 232);
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}

.time-select-list ul li:last-child {
  border-bottom: none;
}

.time-list-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.description-editor .wrapperClassName {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.border-group .description-editor .wrapperClassName {
  border: 0px solid;
}

.description-editor .toolbarClassName {
  /* position: absolute;

        bottom: 0; */
  margin-bottom: 0;
  border: none;
  font-size: 12px;
}

.toggle-tick .onoffswitch .onoffswitch-inner:before,
.toggle-tick .onoffswitch .onoffswitch-inner:after {
  height: 25px;
}

.toggle-tick .onoffswitch .onoffswitch-inner:before {
  background: #018eff;
}

.toggle-tick .onoffswitch .onoffswitch-switch {
  height: 24px;
  top: 2px;
  width: 24px;
}

.toggle-group.toggle-tick
  input[type="checkbox"]:checked
  ~ .onoffswitch
  .onoffswitch-label
  .onoffswitch-switch:before {
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: -1px;
  top: -7px;
  font-size: 26px;
  color: #018eff;
}

.switch-status {
  top: 4px;
  position: absolute;
  left: 55px;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.date-wrapper .react-datepicker-wrapper {
  display: block;
}

.date-wrapper .calender-placment {
  top: 25px;
}

.date-wrapper .picker-input,
.time-picker {
  position: relative;
}

.date-wrapper .input-append input,
.time-picker input,
.ui-timepicker-select {
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  height: 34px;
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 16px;
}

.date-wrapper .input-append::after {
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  right: 8px;
  top: 5px;
}

.ui-timepicker-wrapper.custom-timepicker {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 25px;
  /* width: 24.6%; */
  max-height: 197px;
}

.time-picker input {
  padding-left: 10px;
}

.ui-timepicker-select {
  padding-left: 6px;
}

.custom-timepicker .ui-timepicker-list li {
  background: rgb(255, 255, 255);
  border-radius: 0px;
  border: 1px solid rgb(223, 227, 232);
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  padding: 6px 0px 6px 10px;
  border-bottom: 0px;
}

.custom-timepicker .ui-timepicker-list li:last-child {
  border-bottom: 1px solid rgb(223, 227, 232);
}

.time-picker input::-ms-clear,
.date-wrapper input::-ms-clear,
.ui-timepicker-select::-ms-clear {
  display: none;
}

.custom-timepicker .ui-timepicker-list li.ui-timepicker-selected {
  background: #1980ec;
  color: #fff;
}

.date-wrapper .picker__container .calendar {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 25px;
}

.date-wrapper .picker__container .calendar__list {
  border-radius: 4px 4px 0px 0px;
  font-family: Graphik-Medium;
  font-weight: 500;
}

.date-wrapper .calendar__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 9px;
  border: 1px solid rgb(223, 227, 232);
}

.date-wrapper .calendar__head .calendar__head--button {
  position: relative;
  border: 1px solid rgb(196, 205, 213);
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 50%;
}

.date-wrapper .calendar__head .calendar__head--button:after {
  content: "\f053";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  right: 7px;
  top: 0px;
  color: #c4cdd5;
}

.date-wrapper .calendar__head--next .calendar__head--button:after {
  content: "\f054";
  right: 5px;
}

.date-wrapper .calendar__head--prev .calendar__head--button:after {
  content: "\f053";
}

.date-wrapper .calendar__head .calendar__head--button svg {
  display: none;
}

.date-wrapper .calendar__head h2 {
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0px;
}

.date-wrapper .calendar__body table {
  width: 100%;
}

.date-wrapper .calendar__body--table thead th {
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  border: 1px solid rgb(223, 227, 232);
  padding: 5px 0;
}

.date-wrapper .calendar__day,
.date-wrapper .calendar__month,
.date-wrapper .calendar__year {
  font-size: 14px;
  font-family: graphik;
  text-align: center;
  border: 1px solid rgb(223, 227, 232);
  padding: 5px 0;
  cursor: pointer;
}

.date-wrapper .calendar__month {
  font-family: Graphik-Medium;
}

.date-wrapper .calendar__head .calendar__head--button [class^="icon"] {
  background-image: none;
}

/* Carousel Implementation for Master List */
.tablist-carousel {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.tablist-carousel .owl-carousel .owl-item .carousel-tablink {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  border-right: 1px solid #dfe3e8;
  padding-right: 15px;
  cursor: pointer;
  position: relative;
}

.tablist-carousel .owl-carousel .owl-item .item {
  height: 36px;
}

.tablist-carousel .owl-carousel .owl-item .carousel-tablink:after {
  content: "";
  background: #018eff;
  width: 0;
  height: 5px;
  border-radius: 4px;
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 0;
}

.tablist-carousel .owl-carousel .owl-item .carousel-tablink:hover:after {
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  width: calc(100% - 15px);
}

.tablist-carousel .owl-carousel .owl-nav [class*="owl-"] {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-top: -25px;
}

.tablist-carousel .owl-carousel .owl-nav .owl-prev {
  left: -10px;
}

.tablist-carousel .owl-carousel .owl-nav .owl-next {
  right: -10px;
}

.tablist-carousel .owl-carousel .owl-nav [class*="owl-"]:hover {
  opacity: 1;
}

.tablist-carousel .owl-carousel .owl-nav .owl-next:before,
.tablist-carousel .owl-carousel .owl-nav .owl-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Pro";
  background: none;
  font-size: 16px;
  color: #919eab;
  left: 20px;
  top: 14px;
  font-weight: 600;
}

.tablist-carousel .owl-carousel .owl-nav .owl-next:before {
  content: "\f054";
}

.welcomeMessageModal .modal-dialog {
  max-width: 890px;
}

.welcomeMessageModal .modal-body {
  background-image: url("../Images/MessageBoardbg.svg");
  padding: 2em;
  min-height: 450px;
  max-height: 520px;
  overflow-y: auto;
}

.welcomeMessageModal .message-board-header {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}

.message-board-header h3 {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: -0.39px;
}

.message-board-messages h4 {
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.31px;
  margin-bottom: 4px;
}

.message-board-messages p {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.33px;
  line-height: 20px;
  margin-left: 20px;
}

.message-board-messages li {
  margin-bottom: 30px;
}

.message-checkbox.modal-btn {
  justify-content: space-between;
  align-items: center;
}

.message-checkbox .form-check-label {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  margin-left: 15px;
  margin-top: 5px;
}

.message-checkbox .form-check .checkmark {
  width: 24px;
  height: 24px;
}

.message-checkbox .checkmark:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 14px;
}

.message-board-messages ul li ul li {
  margin-bottom: 0px;
  margin-left: 40px;
  font-size: 14px;
  position: relative;
}

.message-board-messages ul li ul li::after {
  content: "";
  position: absolute;
  width: 8px;
  background-color: transparent;
  border: 1px solid #4f555b;
  height: 8px;
  border-radius: 100%;
  left: -20px;
  top: 7px;
}

.tblview .fa-eye {
  color: #018eff;
  cursor: pointer;
}

.description-editor.disabled-editor {
  position: relative;
  background: #e9ecef;
  font-size: 0.875rem;
  line-height: 17px !important;
}

/* .description-editor.disabled-editor:before {
  content: " ";
  position: absolute;
  width: 100%;
  background: #e9ecef;
  opacity: 1;
  z-index: 1;
} */
.description-editor.disabled-editor .wrapperClassName,
.description-editor.disabled-editor .rdw-option-wrapper,
.description-editor.disabled-editor .toolbarClassName {
  background-color: #e9ecef;
  pointer-events: none;
}

.description-editor.disabled-editor .public-DraftEditor-content {
  color: #495057;
}

/* Landing Page  Release 2.0*/
.client-listing.client-list-offering .card {
  width: 30%;
  padding: 0;
  text-align: left;
  z-index: 1;
}

.client-card .client-list-offering .card-body {
  padding: 0;
  height: 220px;
}

.client-card .client-list-offering .card-text {
  padding: 1rem 1.25rem;
}

.client-list-offering .card-body img {
  margin-bottom: 4px;
}

.client-list-offering .card-title {
  font-size: 16px;
  letter-spacing: -0.29px;
}

.client-card .client-list-offering .card-footer {
  background-color: #ffffff;
  padding: 1rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.client-list-offering .card:hover .card-footer {
  width: 100%;
  left: 0;
}

.client-card .client-listing.client-list-offering .card:hover::after {
  border-radius: 6px;
}

.client-list-offering button.favorite-button {
  position: relative;
  right: 0;
  bottom: 0;
  padding: 0;
  outline: none;
  box-shadow: none;
}

.client-list-offering .total-offerings p {
  margin-bottom: 0;
  color: #637381;
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.43px;
}

.client-list-offering .total-offerings p .offering-count {
  background: rgb(161, 0, 255);
  height: 18px;
  width: 18px;
  color: #ffffff;
  border-radius: 50%;
  border-radius: 50%;
  font-size: 10px;
  display: inline-block;
  margin-left: 5px;
  text-align: center;
  line-height: 18px;
}

.client-list-offering .__react_component_tooltip {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  opacity: 1;
  width: 260px;
  padding: 0;
}

.client-list-offering .__react_component_tooltip.type-light.place-right::after {
  border-right-color: rgba(161, 0, 255, 0.8);
}

.client-list-offering .__react_component_tooltip.type-light.place-top::after {
  border-top-color: rgba(161, 0, 255, 0.8);
}

.client-list-offering .__react_component_tooltip.type-light.place-bottom::after {
  border-bottom-color: rgba(161, 0, 255, 0.8);
}

.client-list-offering .__react_component_tooltip.type-light.place-left::after {
  border-left-color: rgba(161, 0, 255, 0.8);
}

.client-list-offering .__react_component_tooltip .offering-tooltip {
  max-height: 250px;
  overflow: auto;
  padding: 8px 12px;
}

.client-list-offering .__react_component_tooltip ul {
  margin: 5px 0;
}

.client-list-offering .__react_component_tooltip ul li {
  margin-bottom: 15px;
  display: flex;
}

.client-list-offering .__react_component_tooltip ul li img {
  margin-right: 8px;
}

.client-list-offering .__react_component_tooltip h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.29px;
  margin-bottom: 0;
}

.client-list-offering .__react_component_tooltip .offeringtooltip-heading {
  border-bottom: 1px solid #ccc;
  padding: 16px 5px;
}

.client-list-offering .offeringtooltip-body {
  padding: 8px 10px;
}

.client-list-offering .offeringtooltip-body p {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.31px;
  margin-bottom: 0;
}

.client-list-offering .offeringtooltip-body span {
  color: rgb(99, 115, 129);
  font-size: 12px;
}

.fd-config-wrapper .custom-headerdropdown.selected .dropdown-toggle {
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.fd-config-wrapper .custom-headerdropdown {
  margin-right: 0.75rem !important;
}

.fd-config-wrapper .offeringfilter-dropdowns > span {
  margin-right: 1rem !important;
}

@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .client-list-offering .card-title {
    font-size: 14px;
  }

  .fd-config-wrapper .custom-headerdropdown {
    margin-right: 0.5rem !important;
  }

  .fd-config-wrapper .custom-headerdropdown.selected .dropdown-toggle {
    max-width: 170px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    padding: 0.625rem 1.6rem 0.625rem 0.7rem;
  }

  .fd-config-wrapper .offeringfilter-dropdowns > span {
    margin-right: 0.5rem !important;
    font-size: 0.7rem;
  }

  .fd-config-wrapper .filterdata-options p,
  .fd-config-wrapper .custom-headerdropdown .dropdown-toggle.snapshot-toggle {
    font-size: 0.7rem;
  }
}

/* Stage CSS Fixes */
.tab-list .search-nav .nav-item {
  margin-right: 35px;
}

/* Notification Calender start*/
.date-wrapper .input-append {
  position: relative;
}

.date-wrapper .input-append input {
  padding: 10px;
}

.datepicker-dropdown.dropdown-menu {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 20px;
  margin-top: 20px;
  max-width: 315px;
  width: 100%;
}

.datepicker-dropdown:before,
.datepicker-dropdown:after {
  display: none;
}

.datepicker-dropdown table {
  width: 100%;
  border: 1px solid rgb(220, 226, 231);
  border-radius: 4px 4px 0px 0px;
  font-family: Graphik-Medium;
  font-weight: 500;
  padding: 10px;
}

.datepicker-dropdown table thead th.prev,
.datepicker-dropdown table thead th.next {
  position: relative;
  border: 1px solid rgb(196, 205, 213);
  height: 24px;
  width: 24px;
  background: #fff;
  border-radius: 50%;
  color: #fff;
  font-size: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
}

.datepicker-dropdown table thead tr {
  border-top: 1px solid rgb(196, 205, 213);
  border-bottom: 1px solid rgb(196, 205, 213);
}

.datepicker-dropdown table thead th.prev:after,
.datepicker-dropdown table thead th.next:after {
  content: "\f053";
  font-family: "Font Awesome 5 Pro";
  font-weight: bold;
  position: absolute;
  right: 7px;
  top: 0px;
  color: #c4cdd5;
  font-size: 14px;
}

.datepicker-dropdown table thead th.prev {
  margin-left: 10px;
}

.datepicker-dropdown table thead th.next:after {
  content: "\f054";
  right: 5px;
}

.datepicker-dropdown table thead th {
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
}

.datepicker-dropdown table tbody td {
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  border: 1px solid rgb(223, 227, 232);
  padding: 8px;
}

.date-wrapper .calendar__day,
.date-wrapper .calendar__month,
.date-wrapper .calendar__year {
  font-size: 14px;
  font-family: graphik;
  text-align: center;
  border: 1px solid rgb(223, 227, 232);
  padding: 5px 0;
  cursor: pointer;
}

.date-wrapper .calendar__month {
  font-family: Graphik-Medium;
}

.date-wrapper .calendar__head .calendar__head--button [class^="icon"] {
  background-image: none;
}

/* Notification Calender  end*/
/* Heuristics Evaluation – Front Door Set 1​ starts here*/
.search-nav.pulseScreen .active a.nav-link,
.search-nav.pulseScreen .active a.nav-link:hover,
.search-nav.pulseScreen .nav-item.active {
  color: rgb(33, 43, 54) !important;
  font-size: 18px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.44px;
  padding: 0 !important;
  pointer-events: none;
}

.search-nav.pulseScreen .nav-item a.nav-link:not(.dropdown-toggle):after {
  display: none;
}

.exploreTools .fa-info-circle {
  font-size: 22px;
  color: rgb(99, 115, 129);
  position: relative;
  top: 4px;
  right: 6px;
  cursor: pointer;
}

.exploreTools,
.selectSnapShotHover {
  position: relative;
}

.exploreTools span,
.selectSnapShotHover span {
  display: none;
}

.exploreTools:hover span,
.selectSnapShotHover:hover span,
.hover-tooltip:hover span {
  position: absolute;
  background: rgb(33, 43, 54);
  color: rgb(255, 255, 255);
  font-family: Graphik-Regular;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  left: -80px;
  width: 172px;
  padding: 8px;
  right: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  top: 24px;
  z-index: 999 !important;
}

.hover-tooltip span {
  display: none;
}

.hover-tooltip:hover span {
  width: 170px;
  left: 0px;
  margin: auto;
  display: block;
}

.mor-doc-nav.ellipses li.hover-tooltip:hover span {
  top: 32px;
}

.selectSnapShotHover:hover span {
  left: 15px;
  white-space: normal;
  top: 28px;
}

.exploreTools:hover span::after,
.selectSnapShotHover:hover span::after,
.hover-tooltip:hover span::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: #000;
  transform: rotate(180deg);
}

.owl-carousel .owl-nav [class*="owl-"] {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px rgba(0, 186, 255, 0.78);
}

.owl-carousel .owl-nav .owl-next:before,
.owl-carousel .owl-nav .owl-prev:before {
  background: transparent url(../Images/car-arrow-new.svg) no-repeat scroll center;
  width: 12px;
  content: "";
  height: 12px;
  position: absolute;
  left: 48%;
  top: 50%;
  margin: -6px 0 0 -6px;
  background-size: 100%;
}

.owl-carousel .owl-nav .owl-prev:before {
  transform: rotate(180deg);
  left: 53%;
}

/* Heuristics Evaluation – Front Door Set 1​ ends here*/
/* Case Study Link CSS Start */
.casestudy-linknav {
  position: relative;
}

.casestudy-linknav .casestudy-hoverlinks {
  display: none;
  position: absolute;
  right: 0;
  top: 7px;
}

.casestudy-linknav .casestudy-hoverlinks span {
  margin-right: 10px;
  cursor: pointer;
}

.casestudy-linknav .casestudy-hoverlinks span i {
  font-size: 14px;
  color: #018eff;
  font-weight: normal;
}

.casestudy-linknav:hover {
  color: #018eff !important;
  font-family: Graphik-Medium;
}

.main-sidebar li.casestudy-linknav a:hover,
.main-sidebar li.casestudy-linknav a.active {
  background-color: transparent;
}

.casestudy-linknav:hover .casestudy-hoverlinks {
  display: block;
}

.casestudylink-modal .modal-footer .btn {
  width: 120px;
  height: 48px;
}

/* Case Study Link CSS End */
/* Explore Tools Enhancement Css Start */
.explore_tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0px;
}

.explore_tools .onoffswitch .onoffswitch-inner:before,
.explore_tools .onoffswitch .onoffswitch-inner:after {
  height: 17px;
}

.explore_tools .onoffswitch .onoffswitch-switch {
  top: 0.5px;
  width: 16.5px;
  height: 16.5px;
}

.explore_tools .onoffswitch {
  width: 34px;
}

.explore_tools .toggle-group {
  display: inline-flex;
  align-items: center;
}

.explore_tools .onoffswitch .onoffswitch-switch {
  right: 17px;
}

.explore_tools .toggle-group input[type="checkbox"] {
  visibility: hidden;
}

.dropdown-menu.explore_tools_root {
  /* height: calc(100vh - 253px); */
  max-height: calc(100vh - 253px);
  min-height: 36px;
  overflow-x: hidden;
  overflow-y: auto;
}

.explore_tools .onoffswitch .onoffswitch-inner:after {
  background-color: #ddd;
}

.explore_tools .onoffswitch .onoffswitch-label {
  border: none;
}

.explore_tools_noCursor {
  cursor: default;
}

/* Explore Tools Enhancement Css End */
/*test*/
/* Bat Assessment Header Icon Css Start */
.assessment-notifylink .notification-link {
  cursor: pointer;
}

.assessment-notifylink .notification-link .active-notification:after {
  /* top: 20px;
    right: 6.5px; */
  top: -3.5px;
}

.assessment-notifylinks h6 {
  color: rgb(109, 114, 120);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  padding: 1.25rem 1.25rem 0;
  margin-bottom: 0;
}

.assessment-notifylinks li h4 {
  color: rgb(33, 43, 54) !important;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}

.assessment-notifylinks li p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  margin-bottom: 5px;
}

.assessment-notificationlink {
  color: rgb(0, 186, 255);
  font-family: Graphik-Medium;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
}

.assessment-notification-modal.notification-modal .modal-dialog {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: none;
  width: 384px;
}

.today-notification span {
  color: rgb(109, 114, 120);
  font-family: graphik;
  font-size: 10px;
  font-weight: normal;
}

.assessment-notifylinks li {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #dfe3e8;
}

.assessment-notifylinks.notifications-list h4 span {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(161, 0, 255);
  border-radius: 7.5px;
  height: 15px;
  width: 35px;
  text-align: center;
  color: #fff;
  top: 0;
  font-family: Graphik-Medium;
  font-size: 10px;
  letter-spacing: -0.18px;
  line-height: 14px;
  font-weight: 500;
  right: -40px;
}

.notifications-list li ul li *,
.assessment-notifylinks li p * {
  color: #637381 !important;
  font-size: 12px !important;
  background-color: transparent !important;
}

/*  Over All QC SCocre Css Start  */
.overall-qcscore-link p {
  color: rgb(1, 143, 255);
  font-family: Graphik-Semibold;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.22px;
  line-height: 26px;
  margin-bottom: 0;
  cursor: pointer;
}

.overall-qcscore-link p .green-percentval {
  color: #528626;
}

.overall-qcscore-link p .red-percentval {
  color: #b50028;
}

.overall-qcscore-link p .orange-percentval {
  color: #4b3700;
}

/*  Over All QC SCocre Css end  */
.business-filter .select-custom select#Locations,
.business-filter .select-custom select#Month {
  width: 120px;
}

.nolandingHome .navbar-right-section li a.nav-link,
.nolandingHome .navbar-right-section .select-data select {
  padding: 0px !important;
}

.nolandingHome .navbar-right-section li {
  padding: 0px 19px;
  margin: 0px !important;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
}

.nolandingHome .navbar-right-section li:first-child {
  padding-left: 0px;
}

.nolandingHome .navbar-right-section li:last-child,
.nolandingHome .navbar-right-section .assessment-notifylink {
  padding-right: 0px;
}

.nolandingHome .navbar-right-section .help-info-icon span {
  width: 22px;
  height: 22px;
  margin-top: 0px;
}

.nolandingHome .navbar-right-section li.help-info-dropdown::after,
.nolandingHome .navbar-right-section li.select-data::after {
  background: #dfe3e8;
  content: "";
  border-radius: 0.5px;
  border: none;
  height: 24px;
  width: 0.5px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
}

/* Filter Location Omid styles starts here */
.select-custom .frontdoor-select-Loc-Omid,
.select-custom .configurator-select-Loc-Omid {
  padding: 3px 5px;
  color: #637381;
  font-family: "graphik";
  font-size: 12px;
  border: 0px solid transparent;
  letter-spacing: -0.25px;
  padding-right: 22px;
  display: inline-flex;
  word-wrap: normal;
  position: relative;
  cursor: pointer;
}

.select-custom .custom-location-omid {
  position: absolute;
  top: 25px;
  width: 250px;
  height: auto;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
  cursor: initial;
  z-index: 999;
}

.select-custom .custom-location-omid .top-section {
  padding: 12px 16px;
}

.select-custom .custom-location-omid .top-section .location-omid-filter input {
  width: 218px !important;
  height: 32px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 16px;
  /* margin-bottom: 8px; */
}

.location-omid-filter {
  margin-bottom: 8px;
}

.location-omid-filter .clear-search {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translate(-50%, -50%);
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
}

.select-custom .custom-location-omid .action-controls {
  height: 38px;
  background: rgb(239, 242, 245);
  border-radius: 0px 0px 8px 8px;
  padding: 12px 16px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-custom .custom-location-omid .action-controls span {
  color: rgb(1, 142, 255);
  font-size: 12px;
  font-family: "Graphik-Medium";
  font-weight: 500;
  letter-spacing: -0.27px;
  cursor: pointer;
}

.select-custom .custom-location-omid .action-controls span:last-child {
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(1, 142, 255);
  border-radius: 16px;
  padding: 2px 13px;
}

.select-custom .custom-location-omid ul li label {
  display: inline-block;
  font-weight: normal;
  font-size: 14px;
  font-family: "graphik";
  padding: 10px 0px 10px 26px;
  margin: 0;
  position: relative;
  cursor: pointer;
}

.select-custom .custom-location-omid .location-wrapper {
  height: 186px;
  overflow: auto;
}

.select-custom .custom-location-omid ul li label input {
  top: 4.5px;
  position: absolute;
  width: 19px;
  height: 19px;
  margin-right: 10px;
  left: 20px;
  cursor: pointer;
}

.select-custom .custom-location-omid .location-category,
.select-custom .custom-location-omid .location-category .individual-category {
  padding-left: 26px;
}

.select-custom .custom-location-omid ul li label input[type="checkbox"]:checked {
  background-color: red;
}

.select-custom .configurator-select-Loc-Omid .custom-location-omid .location-wrapper {
  max-height: 220px;
  margin-bottom: 0;
  height: unset;
}

.select-custom .configurator-select-Loc-Omid .custom-location-omid ul li label {
  padding: 0px;
  font-family: "graphik-medium";
  cursor: initial;
}

.select-custom
  .configurator-select-Loc-Omid
  .custom-location-omid
  .location-wrapper
  .branch-category
  ul
  li {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 8px 0 8px 16px;
  cursor: pointer;
}

.select-custom
  .configurator-select-Loc-Omid
  .custom-location-omid
  .location-wrapper
  .branch-category
  ul
  li:hover {
  background-color: rgb(1, 142, 255, 0.8);
  color: #ffffff;
}

.configurator-select-Loc-Omid .location-dropdown-overlay,
.frontdoor-select-Loc-Omid .location-dropdown-overlay {
  z-index: 9;
  top: 0px;
}

/* Filter Location Omid styles starts here */

.listOfAddCriteria .table tbody tr td span i.disabled {
  opacity: 0.2;
  cursor: none;
}

/* FilterHeader New Implementation Start */
.subnav-header {
  background: #460073;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 2px 16px 0px rgba(33, 43, 54, 0.3);
  padding: 1rem 1.5rem;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: fixed;
  top: 63px;
  width: 100%;
  z-index: 999;
}

.custom-headerdropdown {
  position: relative;
}

.offeringfilter-dropdowns > span {
  color: rgba(255, 255, 255, 0.7);
}

.custom-headerdropdown .dropdown-toggle {
  /* background-color: rgba(0, 0, 0, 0.35); */
  background-color: transparent;
  border-radius: 16px;
  height: 32px;
  padding: 0.625rem 2rem 0.625rem 1rem;
  border: none;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  outline: none !important;
  box-shadow: none;
  display: flex;
  position: relative;
}

.custom-headerdropdown .dropdown-toggle.snapshot-toggle {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 2rem;
  display: block;
  position: relative;
  margin-left: 4px;
  padding-left: 0.75rem;
}

.custom-headerdropdown .dropdown-toggle.snapshot-toggle img {
  position: relative;
  top: -2px;
}

.snapshot-toggle .__react_component_tooltip {
  z-index: 9999;
}

.custom-headerdropdown.selected .dropdown-toggle,
.custom-headerdropdown.selected .dropdown-toggle:focus,
.custom-headerdropdown.selected .dropdown-toggle:active:focus {
  background-color: rgba(0, 0, 0, 0.35);
  color: #ffffff !important;
}

.custom-headerdropdown .dropdown-toggle:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.custom-headerdropdown .dropdown-toggle:active,
.custom-headerdropdown .dropdown-toggle:active:focus,
.custom-headerdropdown.show .dropdown-toggle {
  background-color: rgba(0, 0, 0, 0.35) !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.custom-headerdropdown .dropdown-toggle:after {
  border-bottom: 2px solid rgb(255, 255, 255);
  border-right: 2px solid rgb(255, 255, 255);
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 12px;
}

.custom-headerdropdown .react-datepicker-wrapper .dropdown-toggle {
  max-width: 130px;
}

.custom-headerdropdown .react-datepicker-wrapper:after {
  content: "";
  border: 1px solid transparent;
  height: 8px;
  width: 8px;
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 12px;
  transition: all 0.5s;
}

.filterdata-options p {
  margin-bottom: 0;
  color: rgb(255, 255, 255);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 20px;
}

.main-sidebar.newsidebar-menu {
  border-radius: 8px;
  box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1), 1px 0px 0px 0px rgb(244, 246, 248);
  width: 76px;
  padding: 0.625rem;
  margin: 1rem 1rem;
  height: calc(100vh - 210px);
  overflow: hidden;
}

.main-sidebar.newsidebar-menu:hover,
.main-sidebar.newsidebar-menu.sidebar-open {
  overflow: auto;
}

.wrapper .main-container .on .main-component {
  padding: 0;
  margin-left: 120px;
  padding: 34px 12px 0 0;
}

.newsidebar-menu .sidebarchild-menu li a {
  padding: 16px;
  background: transparent;
}

.newsidebar-menu .sidebarchild-menu {
  position: relative;
  padding-bottom: 0px;
  margin-bottom: 10px;
}

.newsidebar-menu .sidebarchild-menu:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* .newsidebar-menu:hover .sidebarchild-menu{
    padding-bottom: 0;
  } */
.newsidebar-menu .sidebarchild-menu::after {
  content: " ";
  background: rgb(223, 227, 232);
  border-radius: 0px;
  border: none;
  height: 1px;
  width: 20px;
  position: absolute;
  /* top: auto; */
  right: auto;
  left: 30%;
  bottom: -15px;
}

.newsidebar-menu .sidebarchild-menu:last-child:after {
  display: none;
}

.newsidebar-menu:hover .sidebarchild-menu::after,
.newsidebar-menu.sidebar-open .sidebarchild-menu::after {
  display: none;
}

.newsidebar-menu .sidebarchild-menu li a:hover {
  background: transparent;
  border-radius: 4px;
  color: #018eff;
  font-family: Graphik-Medium;
  font-weight: 500;
}

.newsidebar-menu .sidebarchild-menu li a.active {
  background: transparent;
  border-radius: 0px;
  font-family: Graphik-Medium;
  font-weight: 500;
}

.main-sidebar.newsidebar-menu:hover {
  width: 328px;
}

.newsidebar-menu .sidebarchild-menu li:hover h5 {
  color: #018eff;
  font-family: Graphik-Medium;
  font-weight: 500;
}

.newsidebar-menu .sidebarchild-menu li a.active h5 {
  color: #018eff;
  font-family: Graphik-Medium;
  font-weight: 500;
}

.newsidebar-menu h4 {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.55px;
  line-height: 11px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 8px;
  /* display: none; */
  font-size: 0;
  height: 10px;
}

.sidebarmenu-childmenu-block h4 {
  font-size: 12px !important;
  margin-bottom: 12px;
}

.newsidebar-menu h5 {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 14px;
  margin-bottom: 0;
  display: none;
}

.newsidebar-menu:hover h4,
.newsidebar-menu.sidebar-open h4 {
  font-size: 12px;
}

.newsidebar-menu:hover h5,
.newsidebar-menu.sidebar-open h5,
.newsidebar-menu.sidebar-open .leftpanel-dropdownmenu .dropdown-toggle,
.newsidebar-menu:hover .leftpanel-dropdownmenu .dropdown-toggle {
  display: block;
}

.newsidebar-menu:hover h5,
.newsidebar-menu.sidebar-open h5 {
  padding-left: 1rem;
}

.leftpanelmodal-link {
  cursor: pointer;
}

.leftpanelmodal-link span {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 12px;
  margin-left: 8px;
  cursor: auto;
}

.leftpanel-modal .modal-dialog {
  background: rgb(255, 255, 255);
  border-radius: 0px 8px 8px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  max-width: 310px;
  position: fixed;
  width: 100%;
  top: 0;
  margin: 0;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.leftpanel-modal.left.fade .modal-dialog {
  left: -310px;
  -webkit-transition: opacity 0.5s linear, left 0.5s ease-out;
  -moz-transition: opacity 0.5s linear, left 0.5s ease-out;
  -o-transition: opacity 0.5s linear, left 0.5s ease-out;
  transition: opacity 0.5s linear, left 0.5s ease-out;
}

.leftpanel-modal.left.fade.show .modal-dialog {
  left: 0;
}

.leftpanel-modal .modal-content {
  box-shadow: none;
  border: none;
  padding: 0 1rem;
}

.leftpanelheader-block .userprofile-data h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.29px;
  margin-bottom: 0;
}

.leftpanelheader-block .close {
  right: 0;
}

.leftpanelheader-block .close:focus {
  outline: none;
}

.leftpanelheader-block span {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
}

.leftpanelheader-block p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 0;
}

.leftpanelheader-block .userprofile-info {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.leftpanelheader-block .userprofile-img {
  width: 40px;
  height: 40px;
}

.leftpanel-modal .modal-header {
  padding: 3.5rem 0.5rem 1.75rem 0.5rem;
  border-bottom: 1px solid rgb(227, 227, 227);
}

.leftpanel-modal .modal-body {
  padding: 0;
}

.leftpanel-modal .leftpanelheader-block .close {
  right: 0;
}

.leftmenu-navlinks li a {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 20px;
  display: block;
  padding: 0.75rem 1rem;
}

.leftmenu-navlinks .leftmenu-listlinks li a.active {
  color: rgb(1, 142, 255);
  font-family: Graphik-Semibold;
  font-weight: 600;
}

.leftmenu-navlinks .leftmenu-listlinks li a:hover {
  background: transparent;
  border-radius: 4px;
  color: rgb(1, 142, 255);
}

.leftmenu-navlinks .leftmenu-listlinks {
  padding: 12px 0;
  border-bottom: 1px solid rgb(227, 227, 227);
}

.leftmenu-navlinks .leftmenu-listlinks:last-child {
  border-bottom: none;
}

.leftmenu-navlinks .custom-screen-scroll {
  height: calc(100vh - 170px);
  overflow-y: auto;
}

.assessment-notifylink .notification-link {
  padding-left: 13px;
}

.assessment-notifylink .notification-link span {
  display: flex;
  align-items: center;
}

.assessment-notifylink .notification-link .active-notification:after {
  top: -3.5px;
}

.notification-count {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgb(235, 42, 65);
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 9px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  right: -2px;
}

.notification-link .notification-count {
  top: -6px;
  right: -8px;
}

.language-select {
  position: relative;
}

.language-select:after {
  content: " ";
  background: #dfe3e8;
  border-radius: 0.5px;
  border: none;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 1px;
}

.language-select .custom-headerdropdown .dropdown-menu a {
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.language-select .custom-headerdropdown .dropdown-menu a.active,
.language-select .custom-headerdropdown .dropdown-menu a:hover,
.custom-headerdropdown .dropdown-menu a.active,
.custom-headerdropdown .dropdown-menu a:hover {
  color: rgb(33, 43, 54);
  background: rgba(255, 255, 255, 0.2)
    linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
}

.language-select .custom-headerdropdown .dropdown-menu a img {
  margin-right: 6px;
}

.language-select .custom-headerdropdown .dropdown-item-text {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 18px;
  padding: 6px 12px 12px;
}

.custombg-headerdropdown .dropdown-toggle {
  background-color: transparent;
  padding: 0rem 2rem 0rem 1.375rem;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.29px;
  outline: none !important;
  box-shadow: none;
  display: flex;
  line-height: 64px;
  border-radius: 0;
}

.custombg-headerdropdown .dropdown-toggle:hover,
.custombg-headerdropdown .dropdown-toggle:active,
.custombg-headerdropdown .dropdown-toggle:focus,
.custombg-headerdropdown.show .dropdown-toggle,
.custombg-headerdropdown.active .dropdown-toggle {
  opacity: 1;
  background: rgba(255, 255, 255, 0.2) !important;
  line-height: 64px;
  box-shadow: none !important;
  color: #ffffff !important;
}

.custombg-headerdropdown .dropdown-toggle:after {
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  position: absolute;
  top: 26px;
  bottom: 0;
  right: 12px;
}

.custombg-headerdropdown .dropdown-menu,
.custom-headerdropdown .dropdown-menu {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  min-width: 198px;
  /* padding: 0.75rem 0;
max-height: 240px;
overflow-y: auto;
overflow-x: hidden; */
}

.offeringfilter-dropdowns .custom-headerdropdown .dropdown-menu {
  padding: 16px 4px 16px 0;
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.2);
}

.offeringfilter-dropdowns .custom-headerdropdown .dropdown-menu .offering-droplistwrapper {
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
}

.offeringfilter-dropdowns .custom-headerdropdown .dropdown-menu a {
  padding: 0.5rem 1rem;
  background: transparent !important;
}

.offeringfilter-dropdowns .custom-headerdropdown .dropdown-menu a .form-check-label {
  margin-bottom: 0;
  bottom: 0;
}

.offeringfilter-dropdowns .custom-headerdropdown .dropdown-menu a:hover,
.offeringfilter-dropdowns .custom-headerdropdown .dropdown-menu a.active {
  background: linear-gradient(
    -225deg,
    rgba(161, 0, 255, 0.1) 0%,
    rgba(0, 186, 255, 0.1) 100%
  ) !important;
  color: rgb(21, 24, 27);
  font-family: Graphik-Medium;
  border-radius: 5px;
}

.offeringfilter-dropdowns .custom-headerdropdown .dropdown-menu a:hover span,
.offeringfilter-dropdowns .custom-headerdropdown .dropdown-menu a.active span {
  font-family: Graphik-Medium;
}

.custombg-headerdropdown .dropdown-menu {
  top: -2px !important;
}

.custombg-headerdropdown .dropdown-menu a,
.custom-headerdropdown .dropdown-menu a,
.offering-dropdownsubmenu .dropdown-menu a {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 18px;
  background: transparent;
  padding: 0.75rem;
  display: block;
  position: relative;
}

.custombg-headerdropdown .dropdown-menu a:hover,
.custom-headerdropdown .dropdown-menu a:hover,
.offering-dropdownsubmenu .dropdown-menu a:hover,
.offering-dropdownsubmenu .dropdown-menu a.hover-active {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 0px;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}

.offering-dropdownsubmenu .dropdown-menu a.active {
  color: #018eff;
  font-family: Graphik-Medium;
  font-weight: 500;
  /* background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%); */
  border-radius: 0px;
}

.headeroffering-dropdowns {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.2);
  top: -4px !important;
  min-width: 500px;
}

.headeroffering-dropdowns .arrow {
  display: none;
}

.headeroffering-dropdowns .popover-footerbtn {
  display: flex;
  justify-content: space-between;
}

.headeroffering-dropdowns .popover-footerbtn .btn {
  border-radius: 20px;
  padding: 0.75rem 1.5rem;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  height: 40px;
}

.headeroffering-dropdowns .popover-body {
  padding: 2rem 2.5rem;
}

.headeroffering-dropdowns .popover-body ul {
  margin: 0;
}

.threecol-layoutmenu .dropdown-menu {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}

.twocol-layoutmenu .dropdown-menu {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.headeroffering-dropdowns .popover-body li {
  margin-bottom: 1rem;
}

.custom-headerdropdown li label,
.custom-headerdropdown a label {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  padding-left: 2rem;
  white-space: nowrap;
}

.offeringselect-menu li label .form-check-label,
.custom-headerdropdown a label .form-check-label {
  bottom: 0;
  top: 1px;
}

.offeringselect-menu li label .form-check-label.label-active,
.custom-headerdropdown a label .form-check-label.label-active {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}

.offeringselect-menu .form-check .checkmark,
.custom-headerdropdown .form-check .checkmark {
  width: 22px;
  height: 22px;
  border: 1.5px solid #c4cdd5;
}

.offeringselect-menu .form-check input[type="radio"] + .checkmark:after,
.custom-headerdropdown .form-check input[type="radio"] + .checkmark:after {
  width: 14px;
  height: 14px;
  top: 5px;
  left: 5.2px;
}

.dropdown-toggle::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
}

.dropdown-toggle::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}

.dropdown-toggle:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
}

.dropdown-toggle:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}

.snapshot-popover {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
  max-width: 440px;
  min-width: 250px;
  top: -5px !important;
}

.snapshot-popover .snapshot-select-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.snapshot-popover .snapshot-select .snapshot-links {
  max-height: 280px;
  overflow-y: auto;
  margin-bottom: 0;
  overflow-x: hidden;
}

.snapshot-popover .arrow {
  display: none;
}

.snapshot-popover .popover-body {
  padding: 1rem 1.5rem;
}

/* .snapshot-select-header{
  margin-bottom: 1rem;
} */
.snapshot-select-header h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.29px;
  margin-bottom: 0;
}

.newsnapshot-link span a {
  color: #637381;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  cursor: pointer;
}

.newsnapshot-link span i {
  color: #637381;
  font-size: 13px;
}

.newsnapshot-link span a:hover,
.newsnapshot-link span a:hover i {
  color: rgb(1, 142, 255);
}

.snapshot-links li {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.snapshot-links .snapshot-name {
  display: flex;
  align-items: center;
  padding-left: 3rem;
  padding-right: 1rem;
}

.snapshot-links .snapshot-name:before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: url("../Images/LeftPanelIcons/camera-active.svg") no-repeat;
}

.snapshot-links .snapshot-linklist:hover .snapshot-name:before,
.snapshot-links .snapshot-linklist.default-snapshot .snapshot-name:before {
  background: url("../Images/LeftPanelIcons/camera-normal.svg") no-repeat;
}

.snapshot-links .snapshot-name p {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 40px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.snapshotnav-links li {
  display: none;
}

.snapshot-linklist {
  margin-top: 1rem;
  justify-content: space-between;
  max-width: 408px;
  min-width: 250px;
  padding-right: 0px;
  /* padding-right: 10px; */
}

/* .snapshot-linklist:last-child{
  margin-bottom: 0;
} */

.snapshot-linklist:hover .snapshotnav-links li {
  display: block;
}

.snapshot-linklist:hover p,
.snapshot-linklist.default-snapshot p {
  color: rgb(1, 142, 255);
}

.snapshotnav-links ul {
  display: flex;
}

.snaplink-icon {
  position: relative;
  width: 16px;
  height: 16px;
}

.snaplink-icon a:before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.snaplink-icon a.bookmark-icon:before {
  background: url("../Images/LeftPanelIcons/bookmark-icon.svg") no-repeat;
}

.snaplink-icon:hover a.bookmark-icon:before,
.default-tagicon .snaplink-icon a.bookmark-icon:before {
  background: url("../Images/LeftPanelIcons/bookmark-icon-active.svg") no-repeat;
}

.snaplink-icon a.delete-icon:before {
  background: url("../Images/delete-normal.svg") no-repeat;
}

.snaplink-icon:hover a.delete-icon:before {
  background: url("../Images/delete-gradient.svg") no-repeat;
}

.snaplink-icon a.edit-icon:before {
  background: url("../Images/edit-normal.svg") no-repeat;
}

.snaplink-icon:hover a.edit-icon:before {
  background: url("../Images/edit-gradient.svg") no-repeat;
}

.snaplink-icon .snap-tooltip {
  margin-top: 0;
  min-width: 70px;
  max-width: 100px;
  text-align: center;
  padding: 7px 12px;
}

.snapshot-name .snap-tooltip {
  max-width: 260px;
  min-width: 260px;
}

.snaplink-icon .snap-tooltip span {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
}

.filterdata-options .automation-text {
  position: relative;
  font-weight: 500;
  font-family: "Graphik-Medium";
}

.filterdata-options .overallscore-link {
  position: relative;
  cursor: pointer;
}

.filterdata-options .overallscore-link span {
  font-weight: 600;
}

.filterdata-options .automation-text:after,
.filterdata-options .overallscore-link::after {
  content: " ";
  background: rgba(223, 227, 232, 0.7);
  border-radius: 0.5px;
  border: none;
  height: 14px;
  width: 1px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.sidebarmenu-childmenu-block {
  display: none;
  background: rgb(255, 255, 255);
  border-radius: 0px;
  box-shadow: 2px 0px 24px 0px rgba(33, 43, 54, 0.1), 1px 0px 0px 0px rgb(244, 246, 248);
  position: fixed !important;
  left: 344px !important;
  top: 142px !important;
  width: 256px;
  padding: 0.875rem;
  height: auto;
  max-height: calc(100vh - 210px);
  overflow: auto;
}

.sidebarmenu-icon.dropdown-list:hover .sidebarmenu-childmenu-block {
  display: block;
}

.newsidebar-menu .sidebarmenu-childmenu-block li a {
  color: rgb(99, 115, 129) !important;
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 14px 10px !important;
  height: auto !important;
}

.newsidebar-menu .sidebarmenu-childmenu-block li a:hover {
  background: transparent;
  border-radius: 4px;
  color: #018eff !important;
  font-family: Graphik-Medium;
  font-weight: 500;
}

.newsidebar-menu .sidebarmenu-childmenu-block li a.active {
  color: #018eff !important;
}

.newsidebar-menu .sidebarchild-menu .sidebarmenu-icon a {
  padding: 1.325rem 1rem 1.325rem 2rem;
  height: 56px;
}

.newsidebar-menu .sidebarchild-menu .sidebarmenu-icon .sidebarmenu-iconnav {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.sidebarmenu-icon .sidebarmenu-iconnav:before {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
  top: 0;
  bottom: 0;
  left: 16px;
  margin: auto;
}

.leftpanel-dropdownmenu .dropdown-toggle {
  background-color: transparent;
  padding: 0;
  outline: none !important;
  box-shadow: none;
  border: none;
  border-radius: 0;
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 14px;
  display: none;
  padding: 1.325rem 1rem 1.325rem 3rem;
  height: 56px;
  width: 100%;
  text-align: left;
}

.leftpanel-dropdownmenu .dropdown-toggle:hover {
  color: rgb(33, 43, 54) !important;
  font-family: Graphik-Medium;
  font-weight: 500;
}

.sidebarmenu-icon .sidebarmenu-iconnav:before {
  background-position: center !important;
}

.sidebarmenu-icon .sidebarmenu-iconnav.master-icon:before {
  background: url("../Images/LeftPanelIcons/master-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.master-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.master-icon:before {
  background: url("../Images/LeftPanelIcons/master-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.mtc-icon:before {
  background: url("../Images/LeftPanelIcons/mtc-icon.svg") no-repeat;
  height: 16px;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.mtc-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.mtc-icon:before {
  background: url("../Images/LeftPanelIcons/mtc-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.csa-icon:before {
  background: url("../Images/LeftPanelIcons/csa-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.csa-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.csa-icon:before {
  background: url("../Images/LeftPanelIcons/csa-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.approval-icon:before {
  background: url("../Images/LeftPanelIcons/approval-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.approval-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.approval-icon:before {
  background: url("../Images/LeftPanelIcons/approval-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.reports-icon:before {
  background: url("../Images/LeftPanelIcons/reports-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.reports-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.reports-icon:before {
  background: url("../Images/LeftPanelIcons/reports-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.bo-icon:before {
  background: url("../Images/LeftPanelIcons/BO-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.bo-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.bo-icon:before {
  background: url("../Images/LeftPanelIcons/BO-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.hm-icon:before {
  background: url("../Images/LeftPanelIcons/HM-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.hm-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.hm-icon:before {
  background: url("../Images/LeftPanelIcons/HM-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.wo-icon:before {
  background: url("../Images/LeftPanelIcons/WO-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.wo-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.wo-icon:before {
  background: url("../Images/LeftPanelIcons/WO-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.ii-icon:before {
  background: url("../Images/LeftPanelIcons/II-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.ii-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.ii-icon:before {
  background: url("../Images/LeftPanelIcons/II-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.dd-icon:before {
  background: url("../Images/LeftPanelIcons/DD-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.dd-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.dd-icon:before {
  background: url("../Images/LeftPanelIcons/DD-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.ji-icon:before {
  background: url("../Images/LeftPanelIcons/JI-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.ji-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.ji-icon:before {
  background: url("../Images/LeftPanelIcons/JI-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.bullseye:before {
  background: url("../Images/LeftPanelIcons/bullseye-active.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.bullseye:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.bullseye:before {
  background: url("../Images/LeftPanelIcons/bullseye-inactive.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.vr-icon:before {
  background: url("../Images/LeftPanelIcons/VR-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.vr-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.vr-icon:before {
  background: url("../Images/LeftPanelIcons/VR-icon-active.svg") no-repeat;
}

.sidebarmenu-icon .sidebarmenu-iconnav.mp-icon:before {
  background: url("../Images/LeftPanelIcons/MP-icon.svg") no-repeat;
}

.sidebarmenu-icon:hover .sidebarmenu-iconnav.mp-icon:before,
.sidebarmenu-icon a.active .sidebarmenu-iconnav.mp-icon:before {
  background: url("../Images/LeftPanelIcons/MP-icon-active.svg") no-repeat;
}

.sidebarmenu-sublist.dropdown-toggle:after {
  content: "\f105";
  font-family: "Font awesome\ 5 Pro";
  position: absolute;
  right: 10px;
  font-size: 16px;
  top: 18px;
  color: rgb(99, 115, 129);
  border: none;
  transform: none;
  display: none;
}

.sidebarmenu-sublist:hover.dropdown-toggle:after {
  display: block;
}

.leftpanelheader-block .close {
  right: 0;
}

.leftpanelheader-block .close:focus {
  outline: none;
}

.clientstories-modal .card-row {
  max-height: 500px;
  overflow-y: auto;
}

.offeringselect-menu .form-check input[type="radio"] + .checkmark:after,
.custom-headerdropdown .form-check input[type="radio"] + .checkmark:after {
  width: 14px;
  height: 14px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  border: none;
}

.dropdown-item-text .searchFilter input[type="search"] {
  width: 218px !important;
  height: 32px;
  position: relative;
}

.dropdown-item-text .searchFilter .clear-search {
  position: absolute;
  bottom: 0;
  top: 0;
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  right: 10px;
  cursor: pointer;
}

.omid-loc-label.dropdown-item-text {
  color: rgb(99, 115, 129);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 11px;
}

.custom-headerdropdown a label .form-check-label.active {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
}

/* FilterHeader New Implementation End */

/* AddTool Popup Fix WO */
.custom-file-label.noborder-custom-filelabel {
  border: none;
}

.sidebarmenu-childmenu-block.dropdown-menu.show {
  transform: none !important;
  position: fixed !important;
}

.newsidebar-menu:hover .sidebarmenu-sublist.dropdown-toggle:after,
.newsidebar-menu.sidebar-open .sidebarmenu-sublist.dropdown-toggle:after {
  display: block;
}

.sidebarmenu-childmenu-block.dropdown-menu {
  transform: none !important;
  position: fixed !important;
  display: none;
}

.sidebarmenu-childmenu-block.dropdown-menu.show {
  display: block !important;
}

.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link.disabled-navlink {
  pointer-events: none;
}

.disabled-headernavlink {
  /* background: rgba(255, 255, 255, 0.2) !important; */
  cursor: auto;
  pointer-events: none;
}

.leftpanel-modal .modal-header .close {
  top: 2rem;
}

.trendGraphModal .__react_component_tooltip.show {
  opacity: 1;
  margin-top: -16px;
}

.search-nav .nav-item.disabled-cursor {
  cursor: auto;
}

a.navbar-brand.leftpanelmodal-link {
  padding: 5px 23px 5px 0;
  cursor: auto;
}

a.navbar-brand.leftpanelmodal-link img {
  cursor: pointer;
}

.navbar .select-data > div.client-select-dropdown {
  padding-right: 21px;
}

.casestudytitle.modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
}

.casestudy-linkfields .form-control {
  font-size: 14px;
}

.add-data.adddata-icon {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.add-data.adddata-icon svg {
  position: relative;
  top: -2px;
}

.assessment-notification-modal .close {
  position: absolute;
  right: 10px;
  top: 14px;
}

/* OPRIA Iframe CSS Start */
.ops-insightsbtn {
  top: -10px;
  right: 15px;
  z-index: 2;
  position: absolute;
}

.opsinsightiframe-modal iframe {
  border: none;
  overflow-y: auto;
  min-height: 350px;
  max-height: 100vh;
  margin-bottom: 2rem;
}

.maximise-dashboard .modal-dialog {
  max-width: 100% !important;
  top: 0px !important;
}

.opsinsightiframe-modal .modal-dialog {
  max-width: 1240px;
  top: 38px;
  min-height: 350px;
}

.opsinsightiframe-modal .modal-content .close {
  position: absolute;
  right: 32px;
  top: 2.6rem;
  font-size: 40px;
}

.opsinsightiframe-modal .modal-header {
  justify-content: space-between;
  align-items: center;
}

/* OPRIA Iframe CSS End */
.custom-carousel-padding .owl-carousel {
  padding: 0 2rem;
}

.ddinsights-dashboard .tilecard-wrapper .tilecard-tooltip,
.customtilecard-mtc .tilecard-wrapper .tilecard-tooltip {
  margin-top: 0;
  padding: 6px;
  max-width: 150px;
}

.ddinsights-dashboard .tilecard-wrapper .__react_component_tooltip.tilecard-tooltip span,
.customtilecard-mtc .tilecard-wrapper .__react_component_tooltip.tilecard-tooltip span {
  font-size: 11px !important;
  line-height: 12px !important;
}

.navbarBat.newBanner.navbar-default .navbar-nav > li > a.nav-link.disabled-headernavlink {
  padding: 0px 22px 0px 0px !important;
  color: #fff !important;
}

.custombg-headerdropdown.nocaret .dropdown-toggle:after {
  display: none;
}

.home-admin .main-sidebar.newsidebar-menu,
.home-admin .main-sidebar.newsidebar-menu.sidebar-open {
  top: 64px;
  height: calc(100vh - 144px);
}

.onPageScroll .home-admin .main-sidebar.newsidebar-menu,
.onPageScroll .home-admin .main-sidebar.newsidebar-menu.sidebar-open {
  top: 0px;
  height: calc(100vh - 80px);
}

.home-admin .main-container .main-component {
  margin-top: -5px;
}

.configurator-admin .main-container .main-component {
  padding-top: 34px;
}

.home-admin .sidebarmenu-childmenu-block,
.home-admin .sidebarmenu-childmenu-block {
  top: 78px !important;
  max-height: calc(100vh - 146px) !important;
}

.main-sidebar.newsidebar-menu.sidebar-open {
  display: block;
  width: 328px;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 2px 0px 24px 0px rgb(33 43 54 / 10%), 1px 0px 0px 0px rgb(244 246 248);
  padding: 0.625rem;
  margin: 1rem 1rem;
  height: calc(100vh - 210px);
  z-index: 9999;
}

.onPageScroll .main-sidebar.newsidebar-menu {
  top: 0px !important;
  height: calc(100vh - 80px);
  /* transition: 0.5s; */
}

.onPageScroll .sidebarmenu-childmenu-block {
  top: 14px !important;
  max-height: calc(100vh - 146px);
}

.stage-heading.stageheading-lineheight {
  line-height: 36px;
}

.casestudytitle .casestudydelete i {
  color: #d5002f;
  cursor: pointer;
}

.helpfeature-addclose {
  display: flex;
  align-items: center;
}

.emptymetric-msg img {
  margin-bottom: 2rem;
}

.emptymetric-msg h6 {
  color: rgb(82, 101, 127);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.86px;
}

.category-references.ioj-references {
  top: -46px;
  right: 32px;
}

.category-references.ioj-maturityreferences {
  top: 34px;
  right: 32px;
  padding-right: 14px !important;
}

.emptymetric-msg {
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dashboard-references-dropdown .exploreTools:hover span,
.configurator-references-dropdown-tabs .exploreTools:hover span,
.configurator-references-dropdown .exploreTools:hover span {
  left: 0;
  width: 145px;
  top: -65px;
  font-family: graphik;
  white-space: break-spaces;
}

.dashboard-references-dropdown .exploreTools:hover span::after,
.configurator-references-dropdown-tabs .exploreTools:hover span::after,
.configurator-references-dropdown .exploreTools:hover span::after {
  bottom: -10px;
  transform: rotate(0deg);
  top: auto;
}

.sidebarmenu-open-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: rgb(255, 255, 255);
  z-index: 9999;
}

.dropdown-menu.explore-tools-menu p,
.no-references-text p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 20px;
  margin-bottom: 0;
  text-align: center;
}

.sidebarmenu-childmenu-block .close {
  top: -8px;
  position: relative;
}

.ddinsights-dashboard .custom-carousel-padding .owl-carousel {
  padding: 0 26px;
}

.ddinsights-dashboard .owl-carousel .owl-stage-outer {
  padding: 0 14px;
}

.category-references.ioj-maturityreferences {
  top: 34px;
}

.custom-widget-tooltip .tilecardname-tooltip:nth-child(2) {
  margin-left: 16px;
}

.onPageScroll .main-sidebar.newsidebar-menu {
  top: 0px !important;
  height: calc(100vh - 80px);
  /* transition: 0.5s; */
}

.qcScore-IOJ {
  position: absolute;
  top: -2.75rem;
}

.qc-mr {
  margin-right: 1rem;
}

.casestudytitle .casestudydelete i {
  color: #d5002f;
}

.sidebarmenu-open-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: rgb(255, 255, 255);
  z-index: 9999;
}

.dropdown-menu.explore-tools-menu p,
.no-references-text p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 20px;
  margin-bottom: 0;
  text-align: center;
}

.sidebarmenu-childmenu-block .close {
  top: -8px;
  position: relative;
}

.ddinsights-dashboard .custom-carousel-padding .owl-carousel {
  padding: 0 26px;
}

.ddinsights-dashboard .owl-carousel .owl-stage-outer {
  padding: 0 14px;
}

.custom-widget-tooltip .tilecardname-tooltip:nth-child(2) {
  margin-left: 16px;
}

.label-adjust.margin-labeladjust {
  margin-top: 32px;
}

.errorMsg {
  color: #721c24;
  letter-spacing: -0.25px;
  font-size: 14px;
  text-align: center;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  margin: 8px 0;
  display: block;
}

.error-word-break {
  word-break: break-word;
}

.form-control + .errorMsg {
  margin-bottom: 0;
}

/* .errorMsg {
	  color: #721c24;
	  margin-bottom: 0;
	  letter-spacing: -.25px;
	  font-size: 13px;
	  text-align: center;
	  background: #f8d7da;
	  border: 1px solid #f5c6cb;
	  padding: .4rem .75rem .3rem;
	  border-radius: 4px;
	  margin: 8px 0;
	  display: block;
  } */
.errorMsg:empty {
  display: none;
}

.required {
  color: #dc3545;
}

/* OMID Lite Css changes start */

.omid-flagtext {
  color: rgb(30, 154, 160);
  font-family: Graphik-Medium;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.23px;
  line-height: 16px;
  position: absolute;
  right: 1rem;
  top: 11px;
  cursor: auto;
}

/* OMID Lite Css changes end */
/* Trend Graph Commentary CSS Start */
.trendGraph-comments .modal-dialog.trendGraphModal {
  max-width: 98% !important;
}

.linechart-commentry {
  display: flex;
}

.linechart-division {
  width: 70%;
  margin-right: 1.5rem;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  position: relative;
}

.linechart-division.nocommentary-section {
  width: 100%;
  margin-right: 0;
}

.commentrychat-division {
  width: 30%;
}

.showcomments-tab {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  top: -10px;
  cursor: pointer;
}

.showcomments-tab span {
  color: rgb(1, 142, 255);
  font-family: Graphik-Semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.33px;
  line-height: 24px;
}

.charts-commentsection .comments-header {
  border-bottom: 1px solid rgb(240, 240, 240);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.charts-commentsection .title-with-icon {
  align-items: center;
}

.charts-commentsection .searchFilter input[type="search"] {
  width: 200px !important;
  font-size: 12px !important;
}

.charts-commentsection .comments-header .title-with-icon h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.33px;
  line-height: 24px;
  margin-bottom: 0;
}

.charts-commentsection .comments-body {
  height: 360px;
  padding: 8px 5px 24px 5px;
  margin-top: 16px;
}

.comment-yeardropdown .dropdown-toggle,
.comment-yeardropdown .show > .btn-primary.dropdown-toggle {
  background: transparent !important;
  border-radius: 16px;
  border: 1.5px solid rgb(181, 192, 202);
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  outline: none;
  box-shadow: none !important;
  padding: 10px 16px;
  min-width: 120px;
  text-align: left;
  position: relative;
}

.comment-yeardropdown .show > .btn-primary.dropdown-toggle:active,
.comment-yeardropdown .btn-primary.dropdown-toggle:active {
  color: rgb(99, 115, 129) !important;
}

.comment-yeardropdown .dropdown-toggle:after {
  border-bottom: 2px solid #637381;
  border-right: 2px solid #637381;
  position: absolute;
  top: 9px;
  right: 16px;
}

.comment-yeardropdown .dropdown-menu {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
}

.comment-yeardropdown .offering-droplistwrapper {
  max-height: 220px;
  overflow-y: auto;
}

.comment-yeardropdown .dropdown-menu .dropdown-item {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  padding: 0.5rem 0.75rem;
}

.comment-yeardropdown .dropdown-menu .dropdown-item:hover {
  color: #212b36 !important;
  font-family: Graphik-Medium;
  background: linear-gradient(
    -225deg,
    rgba(161, 0, 255, 0.1) 0%,
    rgba(0, 186, 255, 0.1) 100%
  ) !important;
  border-radius: 4px;
}

.comment-yeardropdown .dropdown-menu .dropdown-item.active {
  color: #018eff !important;
  font-family: Graphik-Medium;
  border-radius: 0px;
  background: transparent;
}

.empty-commentsection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.empty-commentsection h6 {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.empty-commentsection p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
  text-align: center;
}

.charts-commentsection .comments-footer {
  border-top: none;
  flex-direction: column;
  position: relative;
}

.charts-commentsection .comments-footer .comment-area {
  width: 100%;
  display: block;
  margin-bottom: 16px;
}

.charts-commentsection .comments-footer .comment-area textarea {
  margin-right: 0px;
  resize: none;
  border-radius: 8px;
  border: 1px solid rgb(1, 142, 255);
  padding: 1rem;
}

.commentarea-actions {
  display: flex;
  justify-content: space-between;
}

.commentarea-actions p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 18px;
}

.commentarea-actions .btn {
  background-color: #018eff !important;
  color: #fff !important;
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  outline: none !important;
  box-shadow: none !important;
  padding: 6px 16px;
}

.commentarea-actions .btn:hover {
  background-color: rgb(51, 164, 255) !important;
}

.charts-commentsection .comments-body .user-profile {
  background: rgb(38, 193, 201);
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  height: 32px;
  width: 32px;
  border-radius: 50%;
  text-align: center;
  margin-right: 8px;
}

.charts-commentsection .comments-body .user-comment {
  background: rgb(239, 242, 245);
  border-radius: 7px;
  padding: 8px;
  width: calc(100% - 45px);
}

.charts-commentsection .comments-body .profile-info {
  display: flex;
  align-items: center;
  margin-left: 0;
  margin-bottom: 0px;
  justify-content: space-between;
}

.charts-commentsection .comments-body .profile-info h6 {
  color: rgb(21, 24, 27);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 18px;
  margin-right: 4px;
  margin-bottom: 0;
}

.charts-commentsection .comments-body .time-info {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 18px;
  margin-bottom: 4px;
}

.charts-commentsection .comments-body .time-info ul {
  margin: 0;
}

.charts-commentsection .comments-body .user-comment p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 18px;
  overflow-wrap: break-word;
}

.custom-chartlegend {
  margin: 2rem 0 2rem 1rem;
}

.custom-chartlegend ul {
  display: flex;
  align-items: center;
}

.custom-chartlegend ul li {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: -0.23px;
  line-height: 11px;
  margin-right: 1.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-chartlegend ul li span {
  margin-right: 8px;
  display: block;
  position: relative;
  cursor: auto;
}

.trendGraph-comments .modal-body .graphtitle {
  color: rgb(33, 43, 54);
  font-family: Graphik-medium;
  font-size: 16px;
  letter-spacing: -0.35px;
  margin-bottom: 1.25rem;
  display: inline-flex;
}

.trendGraph-comments .analyticsFilter.no-whiteShadow {
  border: none;
  padding: 0 !important;
}

.trendGraph-comments .analyticsFilter.no-whiteShadow::before,
.trendGraph-comments .analyticsFilter.no-whiteShadow::after {
  display: none;
}

.trendGraph-comments .analyticsFilter.no-whiteShadow .chooseOption {
  padding: 0;
}

.edit-delete-dropdown .dropdown-toggle {
  background: transparent;
  border: none;
  box-shadow: none !important;
  background: none !important;
  border-color: transparent !important;
  width: 16px;
  padding: 0;
  outline: none !important;
}

.edit-delete-dropdown .dropdown-toggle:after {
  display: none;
}

.edit-delete-dropdown .dropdown-menu {
  min-width: 124px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 0;
}

.edit-delete-dropdown .dropdown-item {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  line-height: 14px;
  padding: 10px 12px;
}

.edit-delete-dropdown .dropdown-item:hover {
  color: rgb(255, 255, 255);
  background: rgb(1, 142, 255);
}

.delete-comment-selected {
  display: none;
}

.delete-comment {
  margin-right: 10px;
  margin-bottom: 3px;
}

.edit-delete-dropdown .dropdown-item:hover .delete-comment {
  display: none;
}

.edit-delete-dropdown .dropdown-item:hover .delete-comment-selected {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 3px;
}

.edit-delete-dropdown .dropdown-item:hover .edit-comment {
  display: none;
}

.edit-delete-dropdown .dropdown-item:hover .edit-comment-selected {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 3px;
}

.edit-comment {
  margin-right: 10px;
  margin-bottom: 3px;
}

.edit-comment-selected {
  display: none;
}

.default-ellipsis {
  display: flex;
  margin-top: 2px;
}

.ellpsis-selected {
  display: none;
}

.edit-delete-dropdown:hover .default-ellipsis {
  display: none;
}

.edit-delete-dropdown:hover .ellpsis-selected {
  display: flex;
  margin-top: 2px;
}

.newcomment-notify {
  background: rgb(220, 6, 6);
  height: 8px;
  width: 8px;
  border-radius: 100%;
  margin-bottom: 0;
  margin-top: 10px;
  margin-left: 10px;
}

.commentry-edit {
  position: relative;
}

.commentry-edit textarea {
  resize: none;
}

.commentry-edit .commentedit-btns {
  position: absolute;
  bottom: 0px;
  right: 5px;
}

.commentry-edit .commentedit-btns span {
  cursor: pointer;
  color: #919eab;
  font-size: 12px;
}

.calender-placment.baseline-calender-icon {
  color: #a7a7a7;
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 20px;
}

.Baseline-month {
  margin: 0 0 0 1rem;
  font-size: 12px;
  font-family: graphik;
}

/* Trend Graph Commentary CSS End */
/* Clear Button for Period date picker Start */

.period-date-controls span {
  color: #637381 !important;
  display: -webkit-inline-flex;
  display: inline-flex;
  padding: 2px 12px;
  border: 1.5px solid #637381;
  border-radius: 16px;
  font-size: 12px;
  font-family: graphik-medium;
  margin: 6px 6px 11px;
  font-weight: 400;
  cursor: pointer;
}

.custom-multi-select.metric-deep-dive.disabled .multi-select .dropdown-heading {
  background-color: #e9ecef;
  opacity: 1;
}

.custom-multi-select.metric-deep-dive.disabled {
  opacity: 1;
}

.metric-sub-info .category-lists {
  max-height: 496px;
  overflow-y: scroll;
}

.modal-body.metric-deep-dive {
  min-height: 320px;
}

.height-130 {
  min-height: 130px;
}

.height-75 {
  min-height: 75px;
}

.category-actions {
  display: flex;
  align-items: center;
}

.category-actions .category-add {
  font-weight: 500;
  font-family: Graphik-Medium;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  position: relative;
  top: 1px;
}

.category-header {
  border-bottom: 1px solid rgb(223, 227, 232);
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  color: rgb(51, 52, 54);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.29px;
  position: relative;
}

.category-header .category-headername {
  width: 80%;
  word-break: break-word;
}

.category-header span {
  font-weight: 500;
  font-family: Graphik-Medium;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

/* Clear Button for Period date picker End */
/* Media queries for upto 1420px Resolution Start*/
@media screen and (max-width: 1420px) {
  .wrapper .main-container .on .main-component,
  .configurator-admin .main-container .main-component {
    margin-left: 100px;
  }

  .newsidebar-menu .sidebarchild-menu .sidebarmenu-icon a {
    padding: 1rem 1rem 1rem 2rem;
    height: 48px;
  }

  .newsidebar-menu .sidebarchild-menu .sidebarmenu-icon .sidebarmenu-iconnav {
    width: 48px;
    height: 48px;
  }

  .newsidebar-menu h5 {
    font-size: 14px;
  }

  .newsidebar-menu .sidebarchild-menu li a:hover h5 {
    color: #018eff;
    font-family: Graphik-Medium;
  }

  .main-sidebar.newsidebar-menu {
    width: 65px;
    padding: 0.5rem;
  }

  /* .newsidebar-menu .sidebarchild-menu{
    padding-bottom: 1rem;
  margin-bottom: 1rem;
  } */
  .sidebarmenu-icon .sidebarmenu-iconnav:before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .main-sidebar.newsidebar-menu:hover,
  .main-sidebar.newsidebar-menu.sidebar-open {
    width: 300px;
  }

  .sidebarmenu-childmenu-block {
    left: 316px !important;
  }

  .leftpanelmodal-link img {
    width: 24px !important;
    height: 24px !important;
  }

  .sidebarmenu-sublist.dropdown-toggle:after {
    top: 14px;
  }
}

/* Media queries for upto 1420px Resolution End*/
/* BOITrends CSS Start */
.editBOITrend .rwt__tab {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-right: 2rem;
}

.editBOITrend .rwt__tab[aria-selected="true"],
.editBOITrend .rwt__tab:hover {
  color: #018eff;
}

.editBOITrend .rwt__tab:after {
  display: none;
}

.editBOITrend .rwt__tab:before {
  content: " ";
  position: absolute;
  background: rgb(223, 227, 232);
  border-radius: 0.5px;
  height: 20px;
  width: 1px;
  right: -16px;
  top: 1px;
}

.editBOITrend .rwt__tabs .rwt__tab:last-child::before {
  display: none;
}

.common-formgrouplabel .form-group.border-group {
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(181, 192, 202);
}

.common-formgrouplabel label.form-label {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

.common-formgrouplabel .border-group ::-webkit-input-placeholder {
  /* Edge */
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-weight: normal;
}

.common-formgrouplabel .border-group :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-weight: normal;
}

.common-formgrouplabel .border-group ::placeholder {
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-weight: normal;
}

.common-formgrouplabel .border-group .form-control {
  color: rgb(21, 24, 27);
  font-family: graphik;
  font-size: 15px;
  line-height: 20px;
  background: transparent;
}

.common-formgrouplabel .form-group.border-group.disabled {
  background: rgb(239, 242, 245);
  border: 1px solid rgb(231, 234, 238);
}

.common-formgrouplabel .form-group.border-group.disabled label.form-label {
  color: rgb(145, 158, 171);
}

.common-formgrouplabel .form-group.border-group.disabled .form-control {
  color: rgb(181, 192, 202);
}

.editBOI-btns {
  display: flex;
  justify-content: flex-end;
}

.editBOITrendform {
  margin-bottom: 2rem;
}

.editBOI-btns .btn {
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  padding: 0.35rem 0.9rem;
}

.editBOI-btns .btn.btn-default {
  color: #919eab;
}

.showedit-tab {
  position: absolute;
  right: 2rem;
  cursor: pointer;
  top: 60px;
}

.linechart-division.nocommentary-section .showedit-tab {
  right: 3rem;
  top: 0;
}

.common-formgrouplabel .react-datepicker-wrapper .example-custom-input,
.common-formgrouplabel .react-datepicker-wrapper .example-custom-input:active,
.common-formgrouplabel .react-datepicker-wrapper .example-custom-input:focus {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 14px;
  border: none;
  background-color: transparent;
  outline: none;
}

.common-formgrouplabel .react-datepicker-wrapper .example-custom-input svg {
  /* height: auto; */
  padding: 1px;
  margin-left: 4px;
  top: -2px;
  position: relative;
  color: #fff;
}

.common-formgrouplabel .react-datepicker__month .react-datepicker__month-text {
  border-right: 1px solid #dfe3e8;
  margin: 0;
  padding: 7px;
  width: 62px !important;
  border-radius: 0;
  border-bottom: 1px solid #dfe3e8;
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
}

.common-formgrouplabel .react-datepicker__month .react-datepicker__month-text:first-child {
  border-left: 1px solid #dfe3e8;
}

.common-formgrouplabel .react-datepicker__month .react-datepicker__month-wrapper:first-child {
  border-top: 1px solid #dfe3e8;
}

.react-datepicker__month--selected,
.react-datepicker__month-text.react-datepicker__month--selected:hover {
  background: rgb(161, 0, 255) !important;
}

.common-formgrouplabel .react-datepicker__month-container .react-datepicker-year-header {
  padding: 6px 0;
  font-size: 16px;
  font-family: "graphik-medium";
  font-weight: normal;
}

.showedit-tab span {
  color: rgb(1, 142, 255);
  font-family: Graphik-Semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.33px;
  line-height: 24px;
}

/* BOITrends CSS End */
.form-group.border-group.onFocusColor {
  background: rgb(244, 251, 255);
  border: 1.5px solid rgb(1, 142, 255);
  box-shadow: 0px 0px 8px 0px rgba(102, 187, 255, 0.5);
}

.form-group.border-group.onFocusColor .form-control {
  background: rgb(244, 251, 255);
}

.form-group.border-group.onFocusColor label.form-label {
  color: rgb(0, 142, 255);
}

/* nested-dropdown shared css started here */
.custom-headerdropdown.nested-dropdown-wrapper .dropdown-menu {
  width: 368px;
}

.selectsuboffering-dropdown.nested-dropdown-wrapper .dropdown-menu {
  /* width: 230px; */
}

.form-group .selectsuboffering-dropdown.nested-dropdown-wrapper .dropdown-menu {
  width: 320px;
}

.offeringfilter-dropdowns
  .custom-headerdropdown.nested-dropdown-wrapper
  .dropdown-menu
  .offering-droplistwrapper {
  max-height: 382px;
}

#nested-dropdown-container {
  margin: 0;
  padding: 0 12px 0 16px;
}

.custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul,
.custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li,
.custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span,
.selectsuboffering-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul,
.selectsuboffering-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li,
.selectsuboffering-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span {
  border-radius: 5px;
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 20px;
  display: block;
}

.selectsuboffering-dropdown.nested-dropdown-wrapper > button:disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}

.custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul.nested-ul > li a,
.selectsuboffering-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul.nested-ul > li a {
  padding: 0px;
  white-space: normal;
  padding: 7px 0;
}

/* .custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul.nested-ul>li {
  padding: 7px 0;
} */
.custom-headerdropdown.nested-dropdown-wrapper
  .offering-droplistwrapper
  ul.nested-ul
  > li.nested-li,
.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper
  ul.nested-ul
  > li.nested-li {
  padding: 0;
}

.custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span,
.selectsuboffering-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span {
  padding: 6px 0;
}

.custom-headerdropdown.nested-dropdown-wrapper
  .offering-droplistwrapper
  #nested-dropdown-container
  ul
  li.nested-li:hover,
.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper
  #nested-dropdown-container
  ul
  li.nested-li:hover {
  background: transparent !important;
}

.custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li a.active,
.custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li:hover,
.custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span:hover,
.selectsuboffering-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li a.active,
.selectsuboffering-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li:hover,
.selectsuboffering-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span:hover {
  background: linear-gradient(
    -225deg,
    rgba(161, 0, 255, 0.1) 0%,
    rgba(0, 186, 255, 0.1) 100%
  ) !important;
  color: rgb(21, 24, 27);
  font-family: Graphik-Medium;
  border-radius: 5px;
}

.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper.suboffering-dropdown-style
  ul
  li
  span:hover::before {
  color: rgb(255, 255, 255) !important;
}

.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper.suboffering-dropdown-style
  ul
  li:hover,
.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper.suboffering-dropdown-style
  ul.nested-dropdown-container
  > li:hover
  a,
.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper.suboffering-dropdown-style
  ul
  li
  span:hover,
.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper.suboffering-dropdown-style
  ul
  li
  a:hover,
.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper.suboffering-dropdown-style
  ul
  li
  a.active,
.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper.suboffering-dropdown-style
  ul.nested-ul
  li
  a.active {
  background: #018eff !important;
  color: rgb(255, 255, 255) !important;
}

.custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li.nested-li:hover,
.selectsuboffering-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li.nested-li:hover,
.custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul.nested-ul li a:hover,
.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper
  ul.nested-ul
  li
  a:hover,
.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper
  ul.nested-ul
  li
  a.active,
.selectsuboffering-dropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li a:hover {
  background: transparent !important;
  font-family: Graphik-Medium;
  color: rgb(21, 24, 27);
}

.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper
  ul.nested-ul
  li
  a.active {
  background: linear-gradient(
    -225deg,
    rgba(161, 0, 255, 0.1) 0%,
    rgba(0, 186, 255, 0.1) 100%
  ) !important;
}

#nested-dropdown-container .caret {
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}

.form-group.detail-group .selectsuboffering-dropdown.nested-dropdown-wrapper button.dropdown-toggle,
.form-group.border-group
  .selectsuboffering-dropdown.nested-dropdown-wrapper
  button.dropdown-toggle {
  padding: 0;
  border: none !important;
  font-size: 1rem;
  font-family: graphik;
}

.form-group .selectsuboffering-dropdown.nested-dropdown-wrapper button.dropdown-toggle {
  font-family: graphik;
}

.createfolder-modal .selectsuboffering-dropdown.nested-dropdown-wrapper .dropdown-toggle {
  background: transparent !important;
  color: rgb(145, 158, 171) !important;
  font-family: "graphik";
  font-size: 12px !important;
  font-weight: normal;
}

.form-group.detail-group
  .selectsuboffering-dropdown.nested-dropdown-wrapper
  button.dropdown-toggle:focus,
.form-group.border-group
  .selectsuboffering-dropdown.nested-dropdown-wrapper
  button.dropdown-toggle:focus {
  box-shadow: none;
}

#nested-dropdown-container .caret::before {
  content: "\F0DA";
  font-family: "Font awesome\ 5 Pro";
  display: inline-block;
  margin: 0 8px 0 0;
  font-weight: 700;
  color: rgb(99, 115, 129);
}

.custom-headerdropdown.nested-dropdown-wrapper .offering-droplistwrapper ul li span:hover::before,
.selectsuboffering-dropdown.nested-dropdown-wrapper
  .offering-droplistwrapper
  ul
  li
  span:hover::before {
  color: rgb(33, 43, 54) !important;
}

#nested-dropdown-container > .nested-li > span.caret::before {
  margin-left: 6px;
}

#nested-dropdown-container .caret-down::before {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari */
  transform: rotate(90deg);
}

#nested-dropdown-container .nested-ul {
  display: none;
}

#nested-dropdown-container .active {
  display: block;
}

.selectsuboffering-dropdown .dropdown-toggle {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  width: 100%;
  background-color: #fff !important;
  font-size: 0.85rem;
  color: #495057 !important;
  text-align: left;
  padding: 0.4rem 0.75rem;
}

.query-builder-sec .selectsuboffering-dropdown .dropdown-toggle {
  position: relative;
  color: #495057 !important;
  font-weight: 100;
  font-family: "graphik";
}

.business-filter .selectsuboffering-dropdown.dropdown:after {
  border: none;
}

.business-filter .selectsuboffering-dropdown.dropdown {
  padding: 0;
  margin: 0;
}

.business-filter .selectsuboffering-dropdown .dropdown-toggle:hover:after {
  border-bottom-color: unset;
  border-right-color: unset;
}

.selectsuboffering-dropdown .dropdown-toggle:after {
  position: absolute;
  border-bottom: 1.5px solid #495057;
  border-right: 1.5px solid #495057;
  width: 6.5px;
  height: 6.5px;
  right: 6px;
  top: 12px;
}

.modal-overflow .modal-content {
  overflow: inherit;
}

.addMappingModal .selectsuboffering-dropdown.nested-dropdown-wrapper > button:disabled {
  background-color: #fff !important;
  opacity: 0.65;
}

/* nested-dropdown shared css ends here */
.breadcrumbs span {
  color: rgb(99, 115, 129);
  font-size: 10px;
  font-family: "graphik";
  font-weight: 500;
  letter-spacing: 0px;
  display: inline-block;
  position: relative;
  padding-right: 20px;
}

.breadcrumbs span::after {
  position: absolute;
  content: ">";
  width: 5px;
  display: inline-block;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.breadcrumbs :last-child:after {
  display: none;
}

/* BOITrends CSS Start */
.editBOITrend .rwt__tab {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-right: 2rem;
}

.editBOITrend .rwt__tab[aria-selected="true"],
.editBOITrend .rwt__tab:hover {
  color: #018eff;
}

.editBOITrend .rwt__tab:after {
  display: none;
}

.editBOITrend .rwt__tab:before {
  content: " ";
  position: absolute;
  background: rgb(223, 227, 232);
  border-radius: 0.5px;
  height: 20px;
  width: 1px;
  right: -16px;
  top: 1px;
}

.editBOITrend .rwt__tabs .rwt__tab:last-child::before {
  display: none;
}

.common-formgrouplabel .form-group.border-group {
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid rgb(181, 192, 202);
}

.common-formgrouplabel label.form-label {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
}

.common-formgrouplabel .border-group ::-webkit-input-placeholder {
  /* Edge */
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-weight: normal;
}

.common-formgrouplabel .border-group :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-weight: normal;
}

.common-formgrouplabel .border-group ::placeholder {
  color: rgb(145, 158, 171);
  font-size: 16px;
  font-weight: normal;
}

.common-formgrouplabel .border-group .form-control {
  color: rgb(21, 24, 27);
  font-family: graphik;
  font-size: 15px;
  line-height: 20px;
  background: transparent;
}

.common-formgrouplabel .form-group.border-group.disabled {
  background: rgb(239, 242, 245);
  border: 1px solid rgb(231, 234, 238);
}

.common-formgrouplabel .form-group.border-group.disabled label.form-label {
  color: rgb(145, 158, 171);
}

.common-formgrouplabel .form-group.border-group.disabled .form-control {
  color: rgb(181, 192, 202);
}

.editBOI-btns {
  display: flex;
  justify-content: flex-end;
}

.editBOITrendform {
  margin-bottom: 2rem;
}

.editBOI-btns .btn {
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  padding: 0.35rem 0.9rem;
}

.editBOI-btns .btn.btn-default {
  color: #919eab;
}

.showedit-tab {
  position: absolute;
  right: 2rem;
  cursor: pointer;
}

.common-formgrouplabel .react-datepicker-wrapper .example-custom-input,
.common-formgrouplabel .react-datepicker-wrapper .example-custom-input:active,
.common-formgrouplabel .react-datepicker-wrapper .example-custom-input:focus {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 14px;
  border: none;
  background-color: transparent;
  outline: none;
}

.common-formgrouplabel .react-datepicker-wrapper .example-custom-input svg {
  /* height: auto; */
  padding: 1px;
  margin-left: 4px;
  top: -2px;
  position: relative;
  color: #fff;
}

.common-formgrouplabel .react-datepicker__month .react-datepicker__month-text {
  border-right: 1px solid #dfe3e8;
  margin: 0;
  padding: 7px;
  width: 64px;
  border-radius: 0;
  border-bottom: 1px solid #dfe3e8;
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
}

.common-formgrouplabel .react-datepicker__month .react-datepicker__month-text:first-child {
  border-left: 1px solid #dfe3e8;
}

.common-formgrouplabel .react-datepicker__month .react-datepicker__month-wrapper:first-child {
  border-top: 1px solid #dfe3e8;
}

.react-datepicker__month--selected,
.react-datepicker__month-text.react-datepicker__month--selected:hover {
  background: rgb(161, 0, 255) !important;
}

.common-formgrouplabel .react-datepicker__month-container .react-datepicker-year-header {
  padding: 6px 0;
  font-size: 16px;
  font-family: "graphik-medium";
  font-weight: normal;
}

/* BOITrends CSS End */
/* help section notification css start */
.help-notification {
  position: relative;
  border: none !important;
  background: transparent !important;
  display: flex;
  align-items: center;
  padding: 0px 14px !important;
  cursor: default !important;
}

.configurator-component .help-notification,
.ioj-component .help-notification {
  padding: 0px 0px 0px 20px !important;
}

.configurator-component .help-notification a::before,
.ioj-component .help-notification a::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 74%;
  left: -15px;
  background-color: #dfe3e8;
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
}

.help-notification a {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url("../Images/help-notification.svg") no-repeat top center;
  cursor: pointer;
}

.help-notification a span {
  position: absolute;
  width: 16px;
  height: 16px;
  color: rgb(255, 255, 255);
  font-size: 9px;
  font-family: Graphik-Medium;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0px;
  border-radius: 50%;
  background: rgb(235, 42, 65);
  top: -6px;
  right: -4px;
}

.ioj-header-actions {
  position: relative;
}

.ioj-header-actions .button-wrappers {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: -50px;
}

/* help section notification css ends */
.editBOITrendform .initiative-datepicker .close-icon-wrapper {
  width: 90%;
}

.editBOITrendform .initiative-datepicker .close-icon-wrapper span.close-icon {
  display: none;
}

.editBOITrendform .initiative-datepicker .close-icon-wrapper:hover span.close-icon {
  position: absolute;
  right: 36px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.editBOITrendform .initiative-datepicker .close-icon-wrapper:hover span.close-icon::after {
  content: "X";
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 1;
  right: 0px;
  top: -5px;
  bottom: 0;
  left: -3px;
  margin: 0 auto;
  padding: 0px;
  border-radius: 50%;
  text-align: center;
  color: #637381;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

/* Lever description css starts here */
.lever-description-main {
  position: relative;
  padding-left: 28px;
}

.lever-description-wrapper .__react_component_tooltip span {
  color: #fff;
  font-family: "graphik";
  font-size: 11px;
  font-weight: normal;
  line-height: 14px;
}

.lever-description-wrapper .__react_component_tooltip {
  max-width: 251px;
}

.dashboard-httitle span.lever-description,
.lever-description-main span.lever-description {
  position: absolute;
  width: 18px;
  height: 18px;
  display: inline-flex;
  background: url("../Images/lever-description-sprites.png") -48px -10px;
  top: -2px;
  bottom: 0px;
  left: 0;
  margin: auto;
}

.dashboard-httitle span.lever-description:hover,
.lever-description-main span.lever-description:hover {
  background: url("../Images/lever-description-sprites.png") -10px -10px;
}

.pulse-modules span.lever-description {
  position: absolute;
  width: 12px;
  height: 12px;
  display: inline-flex;
  background: url("../Images/lever-description-sprites.png") -10px -48px;
  top: 4px;
  right: 4px;
  margin: unset;
}

.pulse-modules span.lever-description:hover {
  background: url("../Images/lever-description-sprites.png") -42px -48px;
}

/* Lever description css ends here */

li.nav-item.log-out-select {
  padding: 0px 0 0 23px !important;
}

li.nav-item.log-out-select .__react_component_tooltip.place-bottom {
  margin-top: 18px;
  padding: 8px;
}

.nav-item .log-out i {
  color: rgba(255, 255, 255, 0.7);
  font-size: 23px;
  width: 26.5px;
  height: 13.5px;
  cursor: pointer;
  padding-top: 4px;
}

.assessment-notifylink::after {
  content: " ";
  background: #dfe3e8;
  border-radius: 0.5px;
  border: none;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 1px;
}

/* Cross ClientAnalysis CSS */
.cross-client-analysis-tabcontent {
  max-height: 77vh;
  overflow-y: auto;
}

.cross-analysisfields-row {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  padding-top: 6px;
  position: relative;
}

.cross-analysisfields-row:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 1px;
  background: rgb(240, 240, 240);
  top: -16px;
}

.cross-analysisfields-col {
  width: 38%;
  border-right: 1px solid #dfe3e8;
  margin-right: 1rem;
  display: flex;
  position: relative;
}

.cross-analysis-slider {
  width: 22%;
}

.cross-analysisfields-col h6 {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: -0.24px;
  line-height: 14px;
  position: absolute;
  top: -16px;
}

.cross-analysisfields.common-formgrouplabel .form-group.border-group {
  padding: 0 12px 6px;
  margin-right: 1rem;
  width: 50%;
  margin-bottom: 0 !important;
}

.showcompare-row {
  display: flex;
  justify-content: flex-end;
}

.custom-toggleswitch.toggle-group {
  height: 24px;
  display: flex;
  align-items: center;
}

.custom-toggleswitch .onoffswitch {
  width: 36px;
}

.custom-toggleswitch .onoffswitch .onoffswitch-label {
  border: none;
  border-radius: 12px;
}

.custom-toggleswitch .onoffswitch .onoffswitch-inner:before,
.custom-toggleswitch .onoffswitch .onoffswitch-inner:after {
  width: 36px;
  height: 24px;
}

.custom-toggleswitch .onoffswitch .onoffswitch-inner:before {
  background: rgb(1, 142, 255);
}

.custom-toggleswitch .onoffswitch .onoffswitch-inner:after {
  border-radius: 12px;
  border: 1px solid rgb(181, 192, 202);
}

.custom-toggleswitch .onoffswitch .onoffswitch-switch {
  background: rgb(181, 192, 202);
  height: 16px;
  width: 16px;
  box-shadow: none;
  right: 15px;
  top: 4px;
}

.custom-toggleswitch.toggle-group
  input[type="checkbox"]:checked
  ~ .onoffswitch
  .onoffswitch-label
  .onoffswitch-switch {
  box-shadow: none;
  background: rgb(255, 255, 255);
  right: 3px;
}

.custom-toggleswitch.toggle-group
  input[type="checkbox"]:checked
  ~ .onoffswitch
  .onoffswitch-label
  .onoffswitch-switch:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: 3px;
  top: 1px;
  font-size: 11px;
  color: #212b36;
}

.custom-toggleswitch .toggleswitch-text {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  height: 14px;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-left: 8px;
}

.custom-toggleswitch.toggle-group input[type="checkbox"]:checked ~ .toggleswitch-text,
.custom-toggleswitch.toggle-group .toggleswitch-text.checked,
.custom-toggleswitch .toggleswitchactive {
  font-family: Graphik-Medium;
  font-weight: 500;
  color: rgb(21, 24, 27);
}

.showcompare-row .form-check .checkmark {
  width: 20px;
  height: 20px;
}

.showcompare-row .checkmark:after {
  left: 6.5px;
  top: 2px;
  width: 6px;
  height: 11px;
}

.showcompare-row .form-check-label {
  margin-left: 10px;
  color: #637381;
  font-family: graphik;
  font-weight: normal;
  bottom: 1px;
}

.cross-analysis-slider h3 {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 14px;
  border-bottom: none;
}

.cross-analysis-slider h3 span {
  color: #018eff;
  font-family: Graphik-Medium;
  font-weight: 500;
  display: block;
  margin: 12px 0px -12px 0px;
}

/* Frontdoor Analytics scroll related issue fixes start*/
#root .nolandingHome.frontdoorAnalytics {
  padding: 64px 0 48px;
}

.synops-frontdoor-analytics {
  height: calc(100vh - 112px);
  position: relative;
}

.synops-frontdoor-analytics #synops-frontdoor-analytics {
  position: relative;
  top: -8px;
  height: calc(100vh - 104px);
}

/* Frontdoor Analytics scroll related issue fixes end*/

/* Frontdoor Analytics scroll related issue fixes end*/
/* @media screen and (min-width: 960px) and (max-width: 1366px){ */
/* Insights Modal Resolution changes starts here */
/* @media (-webkit-device-pixel-ratio: 1.5) { */
.trendGraphModal .modal-header {
  padding: 0.65rem;
}

.trendGraphModal .modal-body {
  padding: 0 0.65rem 0.65rem 0.65rem;
}

.trendGraphModal .rwt__tablist {
  margin-bottom: 0.25rem;
}

.trendGraphModal .editBOITrend .rwt__tablist {
  margin-bottom: 0;
}

.trendGraphModal .rwt__tabpanel {
  position: relative;
}

.trendGraphModal .editBOITrend .rwt__tabpanel {
  overflow: scroll;
  max-height: 64vh;
}

.trendGraphModal .showcomments-tab {
  position: absolute;
  right: 0;
  top: -1rem;
}

.trendGraphModal .rwt__tab {
  margin-right: 1.5rem;
}

.trendGraphModal .linechart-division.nocommentary-section,
.trendGraphModal .linechart-division {
  padding: 0 0.5rem;
}

.trendGraphModal .custom-chartlegend {
  margin: 0.4rem 0 1.5rem 1rem;
}

.trendGraphModal .custom-chartlegend ul {
  margin-bottom: 0px;
}

.trendGraphModal .cross-analysisfields-row:before {
  top: -4px;
}

.trendGraphModal .cross-analysisfields-row {
  padding-top: 1rem;
  margin-bottom: 0.5rem;
}

.trendGraphModal .cross-analysis-slider h3 span {
  margin: 0.35rem 0 0 0;
}

.trendGraphModal .cross-analysis-slider h3 {
  margin: 0;
}

.trendGraphModal .showcompare-row .form-group {
  margin-bottom: 0;
}

.trendGraph-comments .modal-dialog.trendGraphModal {
  max-width: 94% !important;
  height: 90%;
}

.trendGraph-comments .modal-dialog.trendGraphModal .modal-content {
  height: 100%;
}

.trendGraphModal .common-formgrouplabel .form-group.border-group {
  margin-bottom: 0.5rem !important;
  padding: 0.2rem 0.2rem 0.4rem 0.2rem;
}

.trendGraphModal .editBOITrendform {
  margin-bottom: 0.75rem;
  padding-right: 0.25rem;
}

.trendGraphModal .editBOITrendform .errorMsg {
  line-height: 16px;
  padding: 0.25rem;
}

.trendGraphModal .common-formgrouplabel .border-group .form-control {
  line-height: 14px;
  padding-bottom: 0;
}

.trendGraphModal .mindmap-links .bottom-child ul.submenu-treelinks:before {
  top: -39px;
}

.trendGraphModal .topleft-child .mindmaplevel-dropdownaction,
.trendGraphModal .bottomleft-child .mindmaplevel-dropdownaction {
  left: -69px;
}

.trendGraphModal .charts-commentsection .comment-section {
  height: calc(100vh - 34vh);
}

.start-end-date-picker
  .trendGraphModal
  .start-end-datepicker-monthly
  .charts-commentsection
  .comment-section {
  height: calc(100vh - 41vh);
}

.trendGraphModal .charts-commentsection .comments-body {
  height: calc(100% - 150px);
}

.trendGraphModal .charts-commentsection .comments-footer {
  padding-bottom: 0;
}

.trendGraphModal .bottomleft-child .mindmaplevel-dropdownvalue::after,
.trendGraphModal .topleft-child .mindmaplevel-dropdownvalue::after {
  left: -1px;
}

.trendGraphModal .Baseline-month {
  margin: 0;
  position: absolute;
  left: 1.25rem;
  top: 1.5rem;
}

.trendGraphModal .charts-commentsection .comments-footer .comment-area {
  margin-bottom: 0.25rem;
}

.chartjs2-wrapper {
  height: 70vh;
  width: 100%;
}

.chartjs2-wrapper .chartjs-size-monitor {
  position: relative;
}

.linechart-division,
.commentrychat-division {
  height: calc(100vh - 26vh);
}

.start-end-date-picker .start-end-datepicker-monthly .linechart-division,
.start-end-date-picker .start-end-datepicker-monthly .commentrychat-division {
  height: calc(100vh - 33vh);
}

.bo-outcome-value-wrapper {
  max-height: 42vh;
  overflow: scroll;
  overflow-x: hidden;
}

.trendGraphModal .mindmap-section {
  height: 75vh;
}

/* Insights Modal Resolution changes ends here */
/* } */
/* } */
@media screen and (min-width: 960px) and (max-width: 1366px) {
  .trendGraphModal .modal-title {
    font-size: 1rem;
  }

  .trendGraphModal .rwt__tab,
  .trendGraphModal .showcomments-tab span {
    font-size: 0.75rem;
  }

  .trendGraphModal .rwt__tab[aria-selected="true"]:after,
  .trendGraphModal .rwt__tab:hover:after {
    bottom: 4px !important;
    height: 3px;
  }

  .trendGraphModal .common-formgrouplabel .border-group .form-control {
    font-size: 0.82rem !important;
  }

  .trendGraphModal input,
  .trendGraphModal input::-webkit-input-placeholder {
    font-size: 0.75rem !important;
  }

  .bo-outcome-value-wrapper {
    max-height: 38vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  .empty-commentsection {
    margin-top: 48px;
  }

  .start-end-datepicker-monthly .empty-commentsection {
    margin-top: 10px;
  }
}

.livefeed-records.iwgph-records .filtered-records {
  padding: 0px;
  height: unset;
  width: 100%;
}

.hmtab-layout .iwgph-filters {
  display: flex;
}

.livefeed-records.iwgph-records .filtered-records .record-indicate {
  padding-top: 0px;
}

.livefeed-records.iwgph-records .record-indicate h4 {
  font-weight: 400;
  font-family: graphik-medium;
  letter-spacing: -0.25px;
  font-size: 12px;
  color: #212b36;
}

.livefeed-records.iwgph-records .record-indicate .record-digit {
  font-size: 24px;
  font-weight: 400;
  font-family: graphik-medium;
  position: absolute;
  bottom: 0px;
  line-height: 14px;
}

.livefeed-records.iwgph-records .record-indicate .record-digit .record-digit-text {
  color: #637381;
  font-family: graphik;
  font-size: 12px;
  letter-spacing: -0.25px;
  font-weight: normal;
  padding-left: 0.25rem;
}

.iwgph-filters .formsection-fields.form-section.single-dropdown {
  width: calc(50% - 8px);
}

.filtered-records .__react_component_tooltip,
.iwgph-records .__react_component_tooltip {
  max-width: 300px;
}

.iwgph-records .__react_component_tooltip p {
  color: white;
  font-family: unset;
}

.livefeed-body .machine-dropdowns {
  display: flex;
}

.hmtab-layout .machine-dropdowns .iwgph-filters,
.hmtab-layout .machine-dropdowns .iwgph-filters .formsection-fields.form-section.single-dropdown {
  width: 100%;
}

#back-to-top,
#back-to-top:focus,
#back-to-top:active,
#back-to-top:focus:active {
  position: fixed;
  bottom: 54px;
  z-index: 9999;
  right: 1.5rem;
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.19px;
  display: none;
  background: rgb(70, 0, 115);
  border-radius: 19px;
  outline: none;
  border: none;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.27);
  height: 38px;
  width: 99px;
  padding-top: 0.9rem;
}

#back-to-top:hover {
  background-color: rgb(117, 0, 192);
}

#back-to-top::after {
  content: "\F078";
  font-family: "Font awesome 5 Pro";
  position: absolute;
  right: 0;
  font-size: 11px;
  top: 1px;
  left: 0;
  font-weight: 600;
  color: rgb(255, 255, 255);
  z-index: 1;
  margin: auto;
  transform: rotate(180deg);
}

.react-datepicker__month {
  border: none;
}

.data-visualisation-container .dm-card-details ul li {
  list-style: disc;
}

/* Metric Grid View css starts here */
.metric-lever-table {
  position: relative;
}

.metric-lever-table .tableview::before {
  content: "";
  width: 24px;
  height: 22px;
  display: flex;
  cursor: pointer;
  background: url("../Images/list-table-hover.svg");
}

.metric-grid-view .modal-content {
  height: 90vh;
}

.metric-grid-view .editable-style {
  background-color: #fff;
}

.metric-grid-view .ag-theme-balham .ag-header-cell-label,
.metric-grid-view .ag-header-group-cell,
.metric-grid-view .ag-theme-balham .ag-header-group-cell-label span {
  display: flex;
  align-items: center;
}

.metric-grid-view .ag-table.ag-theme-balham .ag-cell {
  line-height: 24px;
  border-right: 1.5px solid rgb(231, 234, 238);
}

.metric-grid-view .ag-table.ag-theme-balham .ag-cell .ag-input-text-wrapper {
  width: 100%;
}

.metric-grid-view .ag-table.ag-theme-balham .ag-header-group-cell {
  display: flex;
  justify-content: center;
}

.metric-grid-view .ag-theme-balham .ag-cell-inline-editing select {
  height: 100%;
  padding-right: 0.9rem;
  white-space: nowrap;
}

.monthdrop-wrapper {
  display: flex;
  align-items: center;
}

.monthdrop-wrapper > span {
  font-family: "graphik";
  font-size: 0.8rem;
}

.purpleborder-dropdown .monthdrop-wrapper .dropdown-toggle {
  padding: 0.5rem 2rem 0.5rem 1rem;
}

.purpleborder-dropdown .monthdrop-wrapper .dropdown-toggle:after {
  top: 9px;
}

.ag-grid-custom-wrapper .ag-table.ag-theme-balham {
  height: calc(100vh - 34vh);
}

.monthdrop-wrapper .offering-droplistwrapper a:hover,
.monthdrop-wrapper .offering-droplistwrapper a.active {
  background: linear-gradient(
    -225deg,
    rgba(161, 0, 255, 0.1) 0%,
    rgba(0, 186, 255, 0.1) 100%
  ) !important;
  border-radius: 0px;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}

.metric-grid-view .ag-theme-balham .ag-header-cell-label span,
.metric-grid-view .ag-theme-balham .ag-header-group-cell-label span {
  font-family: "graphik";
  font-size: 0.75rem !important;
  color: rgb(33, 43, 54);
}

.drop-down-style::after {
  content: "";
  display: inline-block;
  content: "\F105";
  font-family: "Font awesome\ 5 Pro";
  transform: rotate(90deg);
  position: absolute;
  right: 5px;
  top: 3px;
  bottom: 0;
}

.drop-down-style .ag-cell-edit-input {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.metric-grid-view .ag-theme-balham .ag-row {
  background: #f7f9fa;
}

.metric-grid-view .ag-theme-balham .ag-root,
.metric-grid-view .ag-theme-balham .ag-header {
  border: none;
}

.metric-grid-view .ag-theme-balham .ag-header-cell::after,
.metric-grid-view .ag-theme-balham .ag-header-group-cell::after {
  display: none;
}

.metric-grid-view .ag-theme-balham .ag-header-cell:not(.ag-header-group-cell-no-group),
.metric-grid-view .ag-theme-balham .ag-header-group-cell:not(.ag-header-group-cell-no-group) {
  border-width: 0;
}

.metric-grid-view .ag-ltr .ag-body-viewport {
  border-top: 2px solid rgb(208, 210, 211);
}

.metric-grid-view .ag-theme-balham .ag-root {
  border: 1.5px solid rgb(231, 234, 238);
  border-radius: 8px 8px 0px 0px;
}

.ag-theme-balham .ag-header-viewport .ag-header-row:first-child {
  border-bottom: 1.5px solid rgb(231, 234, 238);
}

.metric-grid-view
  .ag-table.ag-theme-balham
  .ag-header-row
  .ag-header-group-cell.ag-header-group-cell-with-group {
  border-left: 1.5px solid rgb(231, 234, 238);
}

.metric-grid-view .ag-table.ag-theme-balham .ag-header-cell::after {
  content: "";
  position: absolute;
  width: 1.2px;
  height: 100%;
  right: 0px;
  background-color: rgb(231, 234, 238);
  display: inline-block;
  top: 0;
  bottom: 0;
  margin: auto;
}

.ag-cell.error-class {
  border: 1px solid red !important;
  border-color: red !important;
}

.ag-grid-custom-tooltip {
  background-color: red;
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
}

.ag-grid-custom-tooltip-no-error {
  background-color: #000000;
  color: #ffffff;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
}

.metric-grid-view .tableFilter .btn-primary.button-get-data {
  color: rgb(161, 0, 255) !important;
  background-color: rgb(255, 255, 255) !important;
  font-weight: bold;
  padding: 7px 16px 7px 16px;
  border-radius: 20px;
  font-size: 12px;
  border: 1.5px solid rgb(161, 0, 255) !important;
  box-shadow: none;
}

.metric-grid-view .offeringlist-dropdownslist .dropdown-toggle {
  min-width: 150px;
}

.metric-grid-view .copy-paste-actions button {
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}

.metric-grid-view .copy-paste-actions .btn-primary,
.metric-grid-view .copy-paste-actions .btn-primary:active,
.metric-grid-view .copy-paste-actions .btn-primary:focus:active {
  background: rgb(128, 0, 204);
  border-color: rgb(128, 0, 204);
}

.metric-grid-view .copy-paste-actions .btn-success,
.metric-grid-view .copy-paste-actions .btn-success:active,
.metric-grid-view .copy-paste-actions .btn-success:focus:active {
  background: #460073;
  border-color: #460073;
}

.metric-grid-view .copy-paste-actions button:hover {
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 20%) !important;
}

.metric-grid-view .ag-theme-balham .ag-row-selected {
  border-color: #460073;
  background-color: rgba(128, 0, 204, 0.2);
}

.metric-grid-view .ag-theme-balham .ag-row-selected .editable-style.ag-cell-focus,
.metric-grid-view .ag-theme-balham .ag-row-selected .editable-style.ag-cell-selected {
  background-color: rgb(128, 0, 204, 0.4);
  color: #fff;
}

/* Metric Grid View css ends here */

.isAAA-check-box {
  display: flex;
  align-items: center;
}

.isAAA-check-box span {
  bottom: 0px;
}

.work-orc-steps-tooltip {
  height: fit-content;
  align-self: end;
  margin-left: auto;
}

.work-orc-steps-tooltip-container {
  align-self: flex-end;
  margin-left: auto;
}

.work-orchestration .timeline-content .work-orc-steps-tooltip-container span.tooltip-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  background: #ffffff;
  color: rgb(161, 0, 255);
  border: 1px solid rgb(161, 0, 255);
  border-radius: 50%;
  font-size: 14px;
  padding: 5px 0 3px;
  font-family: graphik-medium;
  margin: 4px;
  letter-spacing: -0.25px;
  line-height: 14px;
}

.work-orchestration
  .timeline-content.timeline-active
  .work-orc-steps-tooltip-container
  span.tooltip-icon {
  background-color: #fff;
  color: rgb(161, 0, 255);
}

.work-orchestration
  .timeline-content:hover
  .timeline-data
  .work-orc-steps-tooltip-container
  span.tooltip-icon {
  background: rgb(161, 0, 255);
  color: #ffffff;
}

.work-orc-steps-tooltip-container .__react_component_tooltip {
  width: max-content;
}

.work-orc-steps-tooltip-container .__react_component_tooltip span {
  color: #fff;
  font-family: "graphik";
  font-size: 11px;
  font-weight: normal;
  line-height: 14px;
  background: none;
}

.overflow-scroll {
  overflow: scroll;
}
