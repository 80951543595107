.chat-parent {
  height: 100vh;
  max-width: 100%;
  background-color: #a100ff;
  background-image: url("../Images/chat-waves.png");
  background-blend-mode: multiply;
  background-size: 100%;
  background-repeat: no-repeat;
}

.chat-parent .chat-purple-block {
  background-color: #7500c0;
  width: 70.965%;
  height: 75%;
  margin-left: auto;
}

.chat-image-wrapper img {
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 1360px) {
  .chat-image-wrapper {
    width: 51.839%;
  }
  .chat-parent .chat-container .chat-info-container {
    margin-left: 48px;
  }
  .chat-parent .chat-container .chat-info-container .chat-arrow-image-wrapper img {
    height: 120px;
  }
}
@media only screen and (min-width: 1361px) {
  .chat-image-wrapper {
    width: 62.24%;
  }
  .chat-parent .chat-container .chat-info-container {
    margin-left: 124px;
  }
}
.chat-parent .chat-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  margin: 98px 0px 98px 40px;
  width: 80%;
  width: 80%;
  align-items: flex-start;
}
.chat-parent .chat-container .chat-info-container {
  align-self: center;
  display: flex;
  flex-direction: column;
}
.chat-parent .chat-container .chat-info-container .chat-title {
  font-family: Graphik-Bold;
  font-size: 2.625rem;
  color: white;
  letter-spacing: -0.31px;
  line-height: 1.4;
  width: 300px;
  margin-bottom: 20px;
}
.chat-parent .chat-container .chat-info-container .chat-description {
  font-family: Graphik-Medium;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.35px;
  color: white;
  width: 264px;
  margin-bottom: 11.96px;
}
.chat-parent .chat-container .chat-info-container .chat-arrow-image-wrapper {
  width: fit-content;
  align-self: center;
}
.chat-box-container {
  width: 320.21px !important;
}
