.ioj-roadmap {
  position: relative;
}
.ioj-roadmap .ioj-steps .insightsSteps {
  height: 425px;
  max-height: 515px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 8px;
  /* padding: 14px; */
}
.insightsSteps .insights-sections {
  padding: 0 14px;
  margin-bottom: 10px;
}
.ioj-roadmap .ioj-steps .insightsSteps.insights-1 {
  margin-top: 90px;
}
.ioj-roadmap .ioj-steps .insightsSteps.insights-1 .topHeader {
  background: rgb(164, 69, 227);
}
.ioj-roadmap .ioj-steps .insightsSteps.in-progress:hover .topHeader,
.ioj-roadmap .ioj-steps .insightsSteps.yetto-start:hover .topHeader,
.ioj-roadmap .ioj-steps .insightsSteps.yetto-start.partially-start:hover .topHeader {
  background: transparent;
}
.ioj-roadmap .ioj-steps .insightsSteps.insights-2 .topHeader {
  background: rgb(147, 23, 226);
}
.ioj-roadmap .ioj-steps .insightsSteps.completed:hover .topHeader {
  background: transparent;
}
.ioj-roadmap .ioj-steps .insightsSteps.insights-3 .topHeader {
  background: rgb(117, 0, 192);
}

.ioj-roadmap .ioj-steps .insightsSteps.insights-4 .topHeader {
  background: rgb(70, 0, 115);
}

.ioj-roadmap .ioj-steps .insightsSteps.insights-1 .topHeader h2 {
  color: #ffffff;
}
.ioj-roadmap .ioj-steps .insightsSteps.insights-2 {
  margin-top: 60px;
}
.ioj-roadmap .ioj-steps .insightsSteps.insights-3 {
  margin-top: 30px;
}
.ioj-roadmap .ioj-steps .insightsSteps.insights-4 {
  margin-top: 0px;
}
.ioj-roadmap .ioj-steps .insightsSteps .topHeader h2 {
  color: #ffffff;
  font-size: 0.9rem;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: -0.43px;
  margin: 0;
  padding-bottom: 0px;
  line-height: 14px;
}
.ioj-roadmap .ioj-steps .insightsSteps .topHeader div span {
  color: #ffffff;
  font-size: 0.875rem;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 14px;
}
.ioj-roadmap .ioj-steps .insightsSteps .topHeader div span.stateColor {
  /* padding-left: 20px; */
  position: relative;
}
/* .ioj-roadmap .ioj-steps .insightsSteps .topHeader div span.stateColor::before{  
  content: '';
  width: 1px;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: rgb(196, 205, 213);
} */
.ioj-roadmap .ioj-steps .insightsSteps .topHeader div .example-custom-input {
  position: relative;
  color: #fff;
  line-height: 24px;
  min-height: 10px;
  display: inline-block;
}
.ioj-roadmap .ioj-steps .insightsSteps .topHeader div .example-custom-input .dateEdited,
.ioj-roadmap .ioj-steps .insightsSteps .topHeader .stateColor .dateEdited {
  position: absolute;
  bottom: -6px;
  left: 0;
  font-size: 9px;
  font-family: "graphik-medium";
  color: #c4cdd5;
  font-style: italic;
}
.ioj-roadmap .ioj-steps .insightsSteps .topHeader div .react-datepicker-wrapper {
  padding-right: 20px;
}
.ioj-roadmap .ioj-steps .insightsSteps .topHeader .react-datepicker-popper {
  width: 90%;
  box-shadow: none;
  border: none;
}
.ioj-roadmap .ioj-steps .insightsSteps .topHeader div .example-custom-input::before {
  content: "";
  width: 1px;
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
  height: 15px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.7);
}
.ioj-roadmap .ioj-steps .insightsSteps.yetto-start .topHeader div span.stateColor::before {
  display: none;
}
/* .ioj-roadmap .ioj-steps .insightsSteps.yetto-start .topHeader div span.stateColor {
   padding-left: 0px; 
} */
.ioj-roadmap .ioj-steps .insightsSteps.completed .topHeader div span.stateColor {
  color: #fff;
  line-height: 24px;
}
.ioj-roadmap .ioj-steps .insightsSteps.in-progress .topHeader div span.stateColor,
.ioj-roadmap .ioj-steps .insightsSteps.yetto-start.partially-start .topHeader div span.stateColor {
  color: #ffffff;
  line-height: 24px;
}
.ioj-roadmap .ioj-steps .insightsSteps .topHeader {
  /* padding-bottom: 14px; */
  position: relative;
  padding: 6px 14px;
  margin-bottom: 10px;
  border-radius: 8px 8px 0 0;
  height: 51px;
}
.ioj-roadmap .ioj-steps .insightsSteps.insights-1 .topHeader::before {
  content: "";
  position: absolute;
  right: -8px;
  top: 2px;
  bottom: 0;
  /* background: url('../Images/foundation-head-icon.svg') no-repeat; */
  display: inline-flex;
  width: 32px;
  height: 28px;
}
/* .ioj-roadmap .ioj-steps .insightsSteps.insights-1.completed .topHeader::before {
  background: url('../Images/foundation-head-icon-completed.svg') no-repeat;
} */
/* .ioj-roadmap .ioj-steps .insightsSteps.insights-1.completed:hover .topHeader::before, 
.ioj-roadmap .ioj-steps .insightsSteps.insights-1.in-progress:hover .topHeader::before {
  background: url('../Images/foundation-head-icon-hover.svg') no-repeat;
  opacity: 0.9;
} */
.ioj-roadmap .ioj-steps .insightsSteps.insights-2 .topHeader::before {
  content: "";
  position: absolute;
  right: -8px;
  top: 0px;
  bottom: 0;
  /* background: url('../Images/automated-head-icon.svg') no-repeat; */
  display: inline-flex;
  width: 33px;
  height: 29px;
}
/* .ioj-roadmap .ioj-steps .insightsSteps.insights-2.completed .topHeader::before {
  background: url('../Images/automated-head-icon-completed.svg') no-repeat;
}
.ioj-roadmap .ioj-steps .insightsSteps.insights-2.completed:hover .topHeader::before, 
.ioj-roadmap .ioj-steps .insightsSteps.insights-2.in-progress:hover .topHeader::before {
  background: url('../Images/automated-head-icon-hover.svg') no-repeat;
  opacity: 0.9;
} */
.ioj-roadmap .ioj-steps .insightsSteps.insights-3 .topHeader::before {
  content: "";
  position: absolute;
  right: -8px;
  top: 2px;
  bottom: 0;
  /* background: url('../Images/insightsdriven-head-icon.svg') no-repeat; */
  display: inline-flex;
  width: 32px;
  height: 29px;
}
/* .ioj-roadmap .ioj-steps .insightsSteps.insights-3.completed .topHeader::before {
  background: url('../Images/insightsdriven-head-icon-completed.svg') no-repeat;
}
.ioj-roadmap .ioj-steps .insightsSteps.insights-3.completed:hover .topHeader::before, 
.ioj-roadmap .ioj-steps .insightsSteps.insights-3.in-progress:hover .topHeader::before {
  background: url('../Images/insightsdriven-head-icon-hover.svg') no-repeat;
  opacity: 0.9;
} */
.ioj-roadmap .ioj-steps .insightsSteps.insights-4 .topHeader::before {
  content: "";
  position: absolute;
  right: -8px;
  top: 2px;
  bottom: 0;
  /* background: url('../Images/intelligence-head-icon.svg') no-repeat; */
  display: inline-flex;
  width: 32px;
  height: 31px;
}
/* .ioj-roadmap .ioj-steps .insightsSteps.insights-4.completed .topHeader::before {
  background: url('../Images/intelligence-head-icon-completed.svg') no-repeat;
}
.ioj-roadmap .ioj-steps .insightsSteps.insights-4.completed:hover .topHeader::before, 
.ioj-roadmap .ioj-steps .insightsSteps.insights-4.in-progress:hover .topHeader::before {
  background: url('../Images/intelligence-head-icon-hover.svg') no-repeat;
  opacity: 0.9;
} */
.insights-sections .information:first-child {
  margin: 0;
}
.ioj-roadmap .ioj-steps .row > div {
  padding: 0 6px !important;
}
.insights-sections .information {
  margin: 12px 0 0 0;
  position: relative;
}
.insights-sections .information h2 {
  margin: 0;
  width: 145px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: -0.21px;
  line-height: 14px;
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(117, 0, 192) 52%,
    rgb(70, 0, 115) 100%
  );
  border-radius: 9.5px;
  display: inline-flex;
  padding: 3px 8px;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.insights-sections .information::before {
  content: "";
  position: absolute;
  display: inline-flex;
  height: 1px;
  background-color: rgb(196, 205, 213);
  top: -6px;
  width: 100%;
}
.insights-sections .information:first-child::before {
  display: none;
}
.insights-sections .information h2 span:last-child {
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.23px;
  line-height: 14px;
}
.insights-sections .information p:nth-child(2) {
  margin-top: 5px;
}
.insights-sections .information p {
  margin: 0;
  color: #374151;
  font-size: 12px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 12px;
  position: relative;
  padding: 3px 0 3px 12px;
}
.insights-sections .information p span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.insights-sections .information p::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 4px;
  border-radius: 2px;
  height: calc(100% - 10px);
}
.ioj-roadmap .ioj-steps .insightsSteps .insights-sections .information p.amberColor::before {
  background: linear-gradient(
    -225deg,
    rgb(255, 197, 139) 0%,
    rgb(244, 147, 66) 50%,
    rgb(192, 87, 23) 100%
  );
}
.ioj-roadmap .ioj-steps .insightsSteps .insights-sections .information p.greenColor::before {
  background: linear-gradient(
    -225deg,
    rgb(184, 214, 158) 0%,
    rgb(126, 195, 68) 51%,
    rgb(126, 195, 68) 100%
  );
}
.insights-sections .information.outcome-sec p::before {
  display: none;
}
.insights-sections .information.outcome-sec p {
  padding-left: 0px;
}
.ioj-roadmap .ioj-steps .insightsSteps.completed:hover,
.ioj-roadmap .ioj-steps .insightsSteps.in-progress:hover,
.ioj-roadmap .ioj-steps .insightsSteps.yetto-start:hover,
.ioj-roadmap .ioj-steps .insightsSteps.yetto-start.partially-start:hover {
  background: linear-gradient(
    -225deg,
    rgb(161, 0, 255) 0%,
    rgb(117, 0, 192) 52%,
    rgb(70, 0, 115) 100%
  );
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.ioj-roadmap .ioj-steps .insightsSteps.completed:hover .topHeader h2,
.ioj-roadmap .ioj-steps .insightsSteps.completed:hover .topHeader div span,
.ioj-roadmap .ioj-steps .insightsSteps.completed:hover .topHeader div span.stateColor,
.ioj-roadmap .ioj-steps .insightsSteps.completed:hover .insights-sections .information p,
.ioj-roadmap .ioj-steps .insightsSteps.in-progress:hover .topHeader h2,
.ioj-roadmap .ioj-steps .insightsSteps.in-progress:hover .topHeader div span,
.ioj-roadmap .ioj-steps .insightsSteps.in-progress:hover .topHeader div span.stateColor,
.ioj-roadmap .ioj-steps .insightsSteps.in-progress:hover .insights-sections .information p,
.ioj-roadmap .ioj-steps .insightsSteps.yetto-start.partially-start:hover .topHeader h2,
.ioj-roadmap .ioj-steps .insightsSteps.yetto-start.partially-start:hover .topHeader div span,
.ioj-roadmap
  .ioj-steps
  .insightsSteps.yetto-start.partially-start:hover
  .topHeader
  div
  span.stateColor,
.ioj-roadmap
  .ioj-steps
  .insightsSteps.yetto-start.partially-start:hover
  .insights-sections
  .information
  p {
  color: rgb(255, 255, 255);
}
.ioj-roadmap .ioj-steps .insightsSteps.completed:hover .insights-sections .information h2,
.ioj-roadmap .ioj-steps .insightsSteps.in-progress:hover .insights-sections .information h2,
.ioj-roadmap
  .ioj-steps
  .insightsSteps.yetto-start.partially-start:hover
  .insights-sections
  .information
  h2 {
  background: rgb(255, 255, 255);
}
.ioj-roadmap .ioj-steps .insightsSteps.completed:hover .insights-sections .information h2 span,
.ioj-roadmap .ioj-steps .insightsSteps.in-progress:hover .insights-sections .information h2 span,
.ioj-roadmap
  .ioj-steps
  .insightsSteps.yetto-start.partially-start:hover
  .insights-sections
  .information
  h2
  span {
  color: rgb(1, 142, 255);
}
.ioj-roadmap .ioj-steps .insightsSteps .start-roadmap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 115px);
  flex-direction: column;
  position: relative;
}
.ioj-roadmap .ioj-steps .insightsSteps:hover .start-roadmap img {
  opacity: 0.3;
}
.ioj-roadmap .ioj-steps .insightsSteps .start-roadmap p {
  margin: 0;
  color: rgb(167, 167, 167);
  font-size: 16px;
  font-family: Graphik-MediumItalic;
  font-weight: 500;
  font-style: italic;
  letter-spacing: -0.29px;
  padding-bottom: 10px;
}
.ioj-roadmap .ioj-steps .insightsSteps .start-roadmap .link-to-roadmap,
.assesmentTab-container .start-assesment .link-to-assesment {
  color: rgb(161, 0, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  width: 170px;
  letter-spacing: -0.25px;
  margin-top: 26px;
  line-height: 14px;
  padding: 8px 10px 8px 20px;
  background: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255);
  position: relative;
}
.ioj-roadmap .ioj-steps .insightsSteps .start-roadmap .link-to-roadmap {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.56px;
  padding: 8px 10px;
}
.ioj-roadmap .ioj-steps .insightsSteps .start-roadmap.dashboard-page::before {
  top: 38%;
}

.ioj-roadmap .ioj-steps .insightsSteps:hover .start-roadmap::before {
  background: url("../Images/IOJ-synops-logo-hover.svg") no-repeat;
}
.initiative-heading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.initiative-heading .back-navigation,
.kpiimpactgraph-heading .back-btn {
  color: #00baff;
  font-size: 20px;
  margin-right: 8px;
  cursor: pointer;
}
.initiativeheading-dropdown {
  position: relative;
}
.initiativeheading-dropdown .initiativeheading-dropdownvalue {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  position: relative;
  cursor: pointer;
}
.initiativeheading-dropdownvalue:after {
  content: "\F078";
  font-family: "Font awesome\ 5 Pro";
  position: absolute;
  right: -30px;
  font-size: 14px;
  top: 0px;
  color: #212b36;
}
.initiativeheading-dropdownlist {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  /* height: 124px; */
  width: 208px;
  position: absolute;
  top: 31px;
  left: 0px;
  z-index: 10;
  padding: 8px 0px;
  height: 240px;
  overflow-y: auto;
}
.initiativeheading-dropdownlist.stage-drop {
  max-height: 250px;
  height: auto;
}
.initiativeheading-dropdownlist.initiative-details-drop {
  max-height: none;
  overflow-y: unset;
  height: auto;
}
.initiativeheading-dropdownlist.initiative-details-drop ul {
  max-height: 250px;
  height: auto;
  overflow-y: auto;
}
.viewby-dropdownlist ul,
.initiativeheading-dropdownlist ul {
  margin: 0;
}
.initiativeheading-dropdownlist ul li {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  padding: 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.initiativeheading-dropdownlist ul li:hover,
.initiativeheading-dropdownlist ul li.selected-droplist {
  background: rgba(255, 255, 255, 0.2)
    linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.initiative-dropdown-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 14%;
  left: 0;
  bottom: 0;
  right: 0;
}
.journeyinitiative-tabs {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(242, 242, 242, 0.5);
  position: relative;
  padding: 1rem 0;
}
.journeyinitiative-tabs.initiativemanegement-tab {
  padding: 1rem;
}
.journeyinitiative-tabs.initiativemanegement-tab .addcriteria-btn {
  right: 0;
}
.journeyinitiative-tabs:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    -225deg,
    rgb(11, 76, 255) 10%,
    rgb(1, 142, 255) 44%,
    rgb(11, 175, 240) 100%
  );
  border-radius: 8px 8px 0px 0px;
  height: 8px;
  width: 100%;
}
.journeyinitiative-tabs .addcriteria-btn {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 32px;
  padding: 0 1rem;
  /* width: 169px; */
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  margin-top: 4px;
  cursor: pointer;
}
.journeyinitiative-tabs .addcancel-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  margin-top: 4px;
}
.journeyinitiative-tabs .addcancel-btns .btn {
  height: 32px;
  min-width: 72px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  border-radius: 16px;
  outline: none;
  cursor: pointer;
}
.journeyinitiative-tabs .addcancel-btns .addcritetia-outline {
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
}
.journeyinitiative-tabs .addcancel-btns .addcritetia-outline-grey {
  background: rgb(255, 255, 255);
  border: 1.5px solid #637381;
  color: #637381;
}
.journeyinitiative-tabs .addcancel-btns .addcritetia-danger {
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(213, 0, 47);
  color: rgb(213, 0, 47);
}
.journeyinitiative-tabs .addcancel-btns .addcritetia-outline:hover {
  background: rgb(161, 0, 255);
  color: #ffffff;
}
.journeyinitiative-tabs .addcancel-btns .addcritetia-primary {
  background: rgb(161, 0, 255);
  color: #fff;
}

.journeyinitiative-tabs .addcriteria-btn:hover {
  background: rgb(161, 0, 255);
  color: #ffffff;
}
.journeyinitiative-tabs .nav-tabs .nav-link {
  border: none;
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.25px;
  position: relative;
  display: inline-block;
  padding: 0.8rem 0;
  margin-right: 32px;
  cursor: pointer;
}
.journeyinitiative-tabs .nav-tabs .nav-link.active,
.journeyinitiative-tabs .nav-tabs .nav-link:hover {
  color: #018eff !important;
  font-weight: 500;
  border-bottom: none;
}
.journeyinitiative-tabs .nav-tabs .nav-link:after {
  content: "";
  display: block;
  margin: auto;
  height: 4px;
  position: relative;
  bottom: -12px;
  border-radius: 3px 3px 0px 0px;
  width: 0px;
  background-color: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}
.journeyinitiative-tabs .nav-tabs .nav-link.active:after,
.journeyinitiative-tabs .nav-tabs .nav-link:hover:after {
  background-color: #018eff;
  width: 100%;
}

.journeyinitiative-tabs .tab-content {
  background: transparent;
  padding: 0 1.2rem;
}
.journeyinitiative-tabs .nav-tabs {
  margin-bottom: 1.2rem;
  border-bottom: 1px solid rgb(223, 227, 232);
  padding: 0 1rem;
}
.emptycriteria-block {
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.emptycriteria-block img {
  margin-bottom: 2rem;
  width: 32px;
  height: 32px;
}
.emptycriteria-block h4 {
  color: rgb(82, 101, 127);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.86px;
}
.emptycriteria-block p {
  color: rgb(82, 101, 127);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.64px;
  line-height: 22px;
  max-width: 580px;
  text-align: center;
}
.assessmentcriteria-form h3,
.outcome-form h3 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.49px;
  margin-bottom: 1.5rem;
}
.listOfAddCriteria .table .thead-light th {
  background: rgb(227, 209, 255);
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.2px;
  border: none;
}
.listOfAddCriteria .table .thead-light th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.listOfAddCriteria .table .thead-light th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center;
}
.listOfAddCriteria .table tbody tr td {
  border-top: none;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 20px;
  position: relative;
  vertical-align: middle;
  white-space: normal;
}
.listOfAddCriteria .table tbody tr {
  border-bottom: 1px solid rgb(223, 227, 232);
}
.listOfAddCriteria .table tbody tr:last-child {
  border-bottom: none;
}
.listOfAddCriteria .table .thead-light th:first-child,
.listOfAddCriteria .table tbody td:first-child {
  padding-left: 40px;
  /* width: 82%; */
  text-align: left !important;
}
.listOfAddCriteria.assessmentcriteria-listtable .table .thead-light th:first-child,
.listOfAddCriteria.assessmentcriteria-listtable .table tbody td:first-child {
  width: 82%;
}
.OutcomeTableInitiative {
  text-align: left !important;
}
.criteriaName::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 14px;
  border-radius: 2px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 18px;
}
.greenColor::before {
  background: rgb(126, 195, 68);
}
.amberColor::before {
  background: rgb(245, 166, 35);
}
.redColor::before {
  background: #e02020;
}
.greyColor::before {
  background: #7b8e98;
}
.criteriaName.amberColor::before {
  background: rgb(245, 166, 35);
}
.criteriaName.greenColor::before {
  background: rgb(126, 195, 68);
}
.criteriaName.greyColor::before {
  background: #7b8e98;
}
.criteriaName.redColor::before {
  background: #e02020;
}
.listOfAddCriteria .table tbody tr td span i {
  opacity: 0.7;
  cursor: pointer;
}
.assessmentcriteria-dropdownvalue {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  height: 56px;
  width: 100%;
  position: relative;
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
  display: block;
  margin-bottom: 1rem;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 27px 16px 16px;
}
.assessmentcriteria-dropdownvalue:after {
  content: "\F078";
  font-family: "Font awesome\ 5 Pro";
  position: absolute;
  right: 16px;
  font-size: 14px;
  top: 16px;
  color: #919eab;
}
.assessmentcriteria-form {
  min-height: 400px;
}
.assessmentcriteria-formfields {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.assessmentcriteria-formfields .assessmentcriteria-dropdown {
  width: 48%;
  position: relative;
  cursor: pointer;
}
.assessmentcriteria-form .assessmentcriteria-dropdown .initiativeheading-dropdownlist {
  top: 56px;
  width: 100%;
  height: auto;
  overflow-y: auto;
  left: 0;
}
.assessmentcriteria-form .assessmentcriteria-dropdown .initiativeheading-dropdownlist ul {
  height: unset;
  max-height: 300px;
}
.assessmentcriteria-dropdown .form-control {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  min-height: 56px;
  padding: 16px;
  margin-bottom: 1rem;
}
.assessmentcriteria-dropdown input::-webkit-input-placeholder,
.assessmentcriteria-dropdown textarea::-webkit-input-placeholder {
  /* Edge */
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
}

.assessmentcriteria-dropdown
  input:-ms-input-placeholder
  .assessmentcriteria-dropdown
  textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
}

.assessmentcriteria-dropdown input::placeholder .assessmentcriteria-dropdown textarea::placeholder {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.35px;
}
.assessmentcriteria-dropdown .react-datepicker-wrapper {
  width: 100%;
  margin-bottom: 1rem;
}
.initiativeheading-dropdownlist .searchFilter {
  padding: 12px;
}
.initiativeheading-dropdownlist .searchFilter input[type="search"] {
  height: 32px;
  width: 100% !important;
}
.initiativeheading-dropdownlist .form-check-label.label-checked {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.initiativeheading-dropdownlist .form-check .checkmark {
  width: 20px;
  height: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.initiativeheading-dropdownlist .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
}
.assessmentcriteria-form .addcancel-btns {
  position: relative;
  justify-content: flex-end;
}
.pagination-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pagination-list ul {
  margin-bottom: 0;
}
.pagination-list ul li {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 30px;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
}
.pagination-list ul li .page-link {
  border: none;
  color: rgb(99, 115, 129);
}
.pagination-list li .page-link:hover,
.pagination-list li .page-link.active {
  background: #018fff;
  color: #fff;
  font-family: Graphik-Semibold;
  font-size: 12px;
}
.pagination-list ul li .pagination-icon {
  background: rgb(255, 255, 255);
  border-radius: 3px;
  border: 1px solid rgb(220, 226, 231);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-list .select-custom span {
  margin-right: 10px;
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.pagination-list .select-custom select {
  border-radius: 3px;
  border: 1px solid rgb(220, 226, 231);
  height: 30px;
  font-size: 12px;
  width: 60px;
  padding: 0 10px;
  color: rgb(99, 115, 129);
}
.listOfAddCriteria.initiativecriteria-list .table .thead-light th {
  /* min-width: 150px; */
  text-align: left;
  vertical-align: middle;
}
.listOfAddCriteria.initiativecriteria-list .table.norows-selected .thead-light th {
  min-width: 120px;
}
.listOfAddCriteria.initiativecriteria-list .table tbody tr td {
  text-align: left;
  max-width: 170px;
}
.listOfAddCriteria.initiativecriteria-list .table tbody tr td span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.initiativecriteria-list .criteriaName::before {
  left: 26px;
}
.criteriaName {
  color: rgb(1, 142, 255);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.29px;
  cursor: pointer;
  max-width: 310px;
}
.deletemoritem-modal .modal-header .close {
  top: 32px;
}
.successNotification-modal .successnotification-body,
.deletemoritem-modal .deletenotification-body {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.successNotification-modal .successnotification-icon,
.deletemoritem-modal .deletenotification-icon {
  margin-right: 1.5rem;
}
.deletenotification-icon i {
  font-size: 40px;
  color: #d5002f;
}
.successnotification-icon i {
  font-size: 40px;
  color: #7ec344;
}
.successNotification-modal p,
.deletemoritem-modal p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  margin-bottom: 0;
}
.successNotification-modal .modal-header .close {
  top: 31px;
}
.successNotification-modal .modal-dialog {
  max-width: 623px;
}
.initiative-breadcrumb.breadcrumb {
  background: transparent;
  box-shadow: none;
  border: none;
  float: none;
  padding: 0;
  margin-top: 0;
}
.initiative-breadcrumb .back-navigation {
  padding: 8px 0px;
  margin: 0;
}
.initiative-breadcrumb li {
  color: rgb(0, 145, 255);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  cursor: pointer;
}
.initiative-breadcrumb li.active {
  color: #212b36;
}
.initiative-breadcrumb li:after {
  color: #212b36;
}
.initiative-breadcrumb li:last-child::after {
  display: none;
}
.initiativesummary-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.2rem 0;
}
.initiativesummary-para {
  width: 70%;
  margin-right: 2rem;
}
.initiativesummary-para p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 18px;
}
.initiativesummary-statusbar {
  width: 30%;
}
.initiative-summary {
  margin-top: 1rem;
}
.initiative-summary h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.35px;
}
.initiatives-outcomeslist {
  display: flex;
  margin-bottom: 1.5rem;
}
.initiativesoutcomes-listitem h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-bottom: 5px;
}
.initiativesoutcomes-listitem p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 18px;
  margin-bottom: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}
.initiativesoutcomes-listitem p a {
  color: #018eff;
  word-break: break-all;
}
.initiativesoutcomes-listitem {
  margin-right: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.initiativesoutcomes-listitem a,
.initiativesoutcomes-listitem a:hover,
.initiativesoutcomes-listitem a:active {
  word-break: break-word;
  overflow-wrap: break-word;
  color: #018eff;
  font-size: 13px;
  font-family: "graphik";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.kpiimpacts-list {
  display: flex;
  flex-wrap: wrap;
}
.kpiimpacts-list .outcome-divider {
  width: 260px;
  padding: 16px;
  height: 126px;
  margin-right: 1rem;
}
.kpiimpacts-list .outcome-indicate {
  position: relative;
  padding: 16px 5px 16px 16px;
}
.kpiimpacts-list .outcome-indicate:before {
  left: 0;
}
.kpiimpacts-list .kpi-initiative {
  background: rgb(255, 255, 255);
  border-radius: 6px;
  border: 0.5px solid rgb(217, 217, 217);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
  height: 134px;
  width: 260px;
  position: relative;
  padding: 0.8rem 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.kpiimpacts-list .kpi-initiative::before {
  position: absolute;
  content: "";
  background: #919eab;
  width: 4px;
  height: 100%;
  border-radius: 4px 0px 0px 4px;
  left: 0;
  top: 0;
}
.kpiimpacts-list .kpi-initiative.grey-border:before {
  background: #919eab;
}
.kpiimpacts-list .kpi-initiative.green-border:before {
  background: #7ec344;
}
.kpiimpacts-list .kpi-initiative.red-border:before {
  background: #d5002f;
}
.kpiimpacts-list .kpi-initiative.orange-border:before {
  background: #f58430;
}
.kpi-initiative .initiative-metricvalue {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 24px;
}
.kpi-initiative .initiative-metricdigit {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.35px;
}
.kpiimpacts-list .kpiinitiative-name {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.21px;
  margin-bottom: 0;
}
.kpi-initiativeattribute {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 1rem;
  width: calc(100% - 32px);
  line-height: 14px;
}
.kpi-initiativeattributetext {
  width: 50%;
}
.kpi-initiativeattributetext p {
  color: rgb(99, 115, 129);
  margin-bottom: 0;
  font-family: graphik;
  font-size: 11px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.kpi-initiativeattributetext span {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.initiative-kpiimpacts h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 20px;
  margin-bottom: 1rem;
}
.initiativesummary-statusbar h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-bottom: 6px;
  line-height: 18px;
}
.initiativesummary-statusbar ul {
  display: flex;
  align-items: center;
  background: rgb(244, 246, 248);
  border-radius: 12.5px;
  border: 1px solid rgb(223, 227, 232);
  height: 18px;
  width: 272px;
  margin: 0;
}
.initiativesummary-statusbar li {
  margin-right: 40px;
  margin-left: 10px;
}
.initiativesummary-statusbar li:first-child {
  margin-left: 0;
}
.initiativesummary-statusbar li span {
  background: transparent;
  height: 28px;
  width: 28px;
  display: block;
  border-radius: 100%;
  text-align: center;
}
.initiativesummary-statusbar li span.active {
  background: rgb(161, 0, 255);
  color: #fff;
}
.initiativesummary-statusbar li i {
  font-size: 11px;
}
.initiativesummary-statusbar li span.active svg g {
  fill: #ffffff;
}
.initiativeheading-dropdownlist.criteriastatus-list .form-check-label {
  margin-left: 8px;
  bottom: 0px;
  line-height: 20px;
  overflow-wrap: break-word;
  word-break: break-word;
}
.ioj-roadmap .ioj-steps .react-datepicker-wrapper .example-custom-input,
.ioj-roadmap .ioj-steps .react-datepicker-wrapper .example-custom-input:active,
.ioj-roadmap .ioj-steps .react-datepicker-wrapper .example-custom-input:focus {
  color: rgb(99, 115, 129);
  font-size: 0.875rem;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
  line-height: 14px;
  border: none;
  background-color: transparent;
  outline: none;
}
.ioj-roadmap .ioj-steps .insightsSteps:hover .react-datepicker-wrapper .example-custom-input {
  color: #fff;
  opacity: 0.9;
}
.ioj-roadmap
  .ioj-steps
  .insightsSteps.yetto-start:hover
  .react-datepicker-wrapper
  .example-custom-input,
.ioj-roadmap
  .ioj-steps
  .insightsSteps.yetto-start:hover
  .react-datepicker-wrapper
  .example-custom-input
  svg {
  color: #ffffff;
  opacity: 0.9;
}
.ioj-roadmap .ioj-steps .insightsSteps:hover .react-datepicker-wrapper .example-custom-input svg,
.ioj-roadmap .ioj-steps .insightsSteps .react-datepicker-wrapper .example-custom-input svg {
  /* height: auto; */
  padding: 1px;
  margin-left: 4px;
  top: -2px;
  position: relative;
  color: #fff;
}
.ioj-roadmap .ioj-steps .insightsSteps:hover .react-datepicker-wrapper .example-custom-input svg {
  color: rgb(255, 255, 255);
}
.ioj-roadmap .ioj-steps .insightsSteps .react-datepicker__month .react-datepicker__month-wrapper {
  display: flex;
}
.ioj-roadmap .ioj-steps .insightsSteps .react-datepicker__month .react-datepicker__month-text {
  border-right: 1px solid #dfe3e8;
  margin: 0;
  padding: 7px;
  width: 64px;
  border-radius: 0;
  border-bottom: 1px solid #dfe3e8;
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
}
.ioj-roadmap
  .ioj-steps
  .insightsSteps
  .react-datepicker__month
  .react-datepicker__month-text:first-child {
  border-left: 1px solid #dfe3e8;
}
.ioj-roadmap
  .ioj-steps
  .insightsSteps
  .react-datepicker__month
  .react-datepicker__month-wrapper:first-child {
  border-top: 1px solid #dfe3e8;
}
.ioj-roadmap .ioj-steps .insightsSteps .react-datepicker__month .react-datepicker__month--selected {
  background: rgb(161, 0, 255) !important;
}
.ioj-roadmap
  .ioj-steps
  .insightsSteps
  .react-datepicker__month-container
  .react-datepicker-year-header {
  padding: 6px 0;
  font-size: 16px;
  font-family: "graphik-medium";
  font-weight: normal;
}

.kpiimpactgraph-heading h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 22px;
  margin-bottom: 0;
}
.kpiimpactgraph-heading {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.kpiimpactgraph-heading a.navbar-brand {
  margin-right: 0;
  padding: 0 10px 0 0;
}
.kpiimpactgraph-heading a.navbar-brand:after {
  display: none;
}
.initiativecriteria-dropdowns {
  display: flex;
  align-items: center;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-bottom: 1.25rem;
  margin-right: 0.5rem;
  line-height: 12px;
  position: relative;
}
.initiativecriteria-dropdownbtn span i {
  margin-right: 7px;
}
.initiativecriteria-dropdownbtn {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 32px;
  padding: 0.6rem 1.5rem 0.6rem 1rem;
  display: flex;
  align-items: center;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  margin-bottom: 1.25rem;
  margin-right: 0.5rem;
  line-height: 12px;
  position: relative;
}
.initiativecriteria-dropdownbtn span i {
  margin-right: 7px;
}
.initiativecriteria-dropdownbtn .criteria-dropdownname {
  border-right: 1px solid rgb(161, 0, 255);
  padding-right: 7px;
  margin-right: 7px;
}
.initiativecriteria-dropdownbtn .initiativeheading-dropdownlist {
  width: 100%;
}
.levers-customdropdown {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 175px;
  cursor: pointer;
  z-index: 2;
}
.initiativecriteria-dropdownbtn:after {
  content: "\F078";
  font-family: "Font awesome\ 5 Pro";
  position: absolute;
  right: 14px;
  font-size: 11px;
  top: 10px;
  font-weight: 600;
  color: rgb(161, 0, 255);
  z-index: 1;
}
.levers-customdropdown .initiativeheading-dropdownlist {
  width: 100%;
}
.kpiimpact-graphnav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.kpiimpact-graphnav span {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.35px;
  cursor: pointer;
}
.kpiimpact-graphnavprevicon {
  position: relative;
  left: -30px;
  top: 1px;
}
.kpiimpact-graphnavnexticon {
  position: relative;
  right: -30px;
  top: 1px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.initiativesummary-statusbar li span svg {
  position: relative;
  top: 1px;
}
.kpiimpacts-list .outcome-divider span.metric-digit {
  cursor: pointer;
}
.tooltipellipsis-text {
  position: relative;
}
.tooltipellipsis-text .initiativetooltip-text {
  display: none;
}
.tooltipellipsis-text:hover .initiativetooltip-text {
  position: absolute;
  background: rgb(33, 43, 54);
  color: rgb(255, 255, 255);
  font-family: graphik;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  width: 200px;
  padding: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  top: 40px;
  z-index: 999 !important;
  left: 50%;
  transform: translate(-50%, 0px);
}
.insights-sections .information-status ul {
  display: flex;
}
.insights-sections .information-status li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46%;
  margin-right: 3px;
  padding: 5px;
  border: 1px solid transparent;
}
.insights-sections .information-status li h6 {
  color: rgb(59, 59, 59);
  font-family: Graphik-Semibold;
  font-size: 16px;
  letter-spacing: -0.43px;
  margin-bottom: 4px;
  line-height: 14px;
}
.insights-sections:hover .information-status li h6 {
  color: #ffffff;
}
.insights-sections .information-status li span {
  color: rgb(59, 59, 59);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.43px;
}
.insights-sections:hover .information-status li span {
  color: #ffffff;
}

.insights-sections .information-status {
  margin: 0.61rem 0 0 0;
}
.insights-sections .information-status h4 {
  color: rgb(70, 0, 115);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 0;
}
.insightsSteps.in-progress:hover .insights-sections .information-status h4,
.insightsSteps.completed:hover .insights-sections .information-status h4,
.insightsSteps.yetto-start.partially-start:hover .insights-sections .information-status h4 {
  color: #ffffff;
}
.insights-sections .information-status ul {
  display: flex;
  margin: 0;
}
.insights-sections .information-status li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46%;
  margin-right: 3px;
}
.insights-sections .information-status li:first-child,
.insights-sections .information-status li:last-child {
  width: 27%;
}
.insights-sections .information-status li h6 {
  color: rgb(59, 59, 59);
  font-family: Graphik-Semibold;
  font-size: 16px;
  letter-spacing: -0.43px;
  margin-bottom: 4px;
  line-height: 14px;
}
.insightsSteps.in-progress:hover .insights-sections .information-status li h6,
.insightsSteps.completed:hover .insights-sections .information-status li h6,
.insightsSteps.yetto-start.partially-start:hover .insights-sections .information-status li h6 {
  color: #ffffff;
}
.insights-sections .information-status li span {
  color: rgb(59, 59, 59);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.43px;
}
.insightsSteps.in-progress:hover .insights-sections .information-status li span,
.insightsSteps.completed:hover .insights-sections .information-status li span,
.insightsSteps.yetto-start.partially-start:hover .insights-sections .information-status li span {
  color: #ffffff;
}
.emptyRowsData {
  visibility: hidden;
}
.listOfAddCriteria .table tbody tr.emptyRowsData {
  border-bottom: none !important;
}
.outcome-form .form-control {
  height: 40px;
}
.ioj-roadmap .ioj-steps .insightsSteps .react-datepicker-popper .date-controls span {
  color: rgb(99, 115, 129) !important;
  display: inline-flex;
  padding: 9px 16px;
  border: 1.5px solid rgb(99, 115, 129);
  border-radius: 16px;
  font-size: 14px;
  font-family: "graphik-medium";
  margin: 6px 6px 11px 6px;
  font-weight: normal;
  cursor: pointer;
}
.ioj-roadmap .ioj-steps .insightsSteps .react-datepicker-popper .date-controls span.disabled {
  background-color: rgba(189, 189, 189, 0.34) !important;
  opacity: initial !important;
  border: 1.5px solid rgba(99, 115, 129, 0.34);
  color: rgba(99, 115, 129, 0.34) !important;
}

.tooltipellipsis-text:hover .initiativetooltip-text::after {
  content: "";
  position: absolute;
  left: 50%;
  top: -10px;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: #000;
  transform: rotate(180deg);
}
.breadcrumb li.initiative-heading:after {
  display: none;
}
.breadcrumb li.initiative-heading {
  display: block;
  margin-bottom: 0;
}
.insights-sections .information-status {
  margin: 1rem 0 0 0;
}
.insights-sections .information-status h4 {
  color: rgb(70, 0, 115);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
}
.insights-sections:hover .information-status h4 {
  color: #ffffff;
}
.insights-sections .information-status ul {
  display: flex;
}
.insights-sections .information-status li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46%;
  margin-right: 3px;
}
.insights-sections .information-status li h6 {
  color: rgb(59, 59, 59);
  font-family: Graphik-Semibold;
  font-size: 16px;
  letter-spacing: -0.43px;
  margin-bottom: 4px;
  line-height: 14px;
}
.insights-sections:hover .information-status li h6 {
  color: #ffffff;
}
.insights-sections .information-status li span {
  color: rgb(59, 59, 59);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.43px;
}
.insights-sections:hover .information-status li span {
  color: #ffffff;
}

.insights-sections .information-status {
  margin: 1rem 0 0 0;
}
.insights-sections .information-status h4 {
  color: rgb(70, 0, 115);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
}
.insightsSteps.in-progress:hover .insights-sections .information-status h4,
.insightsSteps.completed:hover .insights-sections .information-status h4,
.insightsSteps.yetto-start.partially-start:hover .insights-sections .information-status h4 {
  color: #ffffff;
}
.insights-sections .information-status ul {
  display: flex;
}
.insights-sections .information-status li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46%;
  margin-right: 3px;
}
.insights-sections .information-status li:first-child,
.insights-sections .information-status li:last-child {
  width: 27%;
}
.insights-sections .information-status li h6 {
  color: rgb(59, 59, 59);
  font-family: Graphik-Semibold;
  font-size: 16px;
  letter-spacing: -0.43px;
  margin-bottom: 4px;
  line-height: 14px;
}
.insightsSteps.in-progress:hover .insights-sections .information-status li h6,
.insightsSteps.completed:hover .insights-sections .information-status li h6,
.insightsSteps.yetto-start.partially-start:hover .insights-sections .information-status li h6 {
  color: #ffffff;
}
.insights-sections .information-status li span {
  color: rgb(59, 59, 59);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.43px;
}
.insightsSteps.in-progress:hover .insights-sections .information-status li span,
.insightsSteps.completed:hover .insights-sections .information-status li span,
.insightsSteps.yetto-start.partially-start:hover .insights-sections .information-status li span {
  color: #ffffff;
}
.emptyRowsData {
  visibility: hidden;
}

.outcome-form .form-control {
  height: 40px;
}
.ioj-roadmap .ioj-steps .insightsSteps .react-datepicker-popper .date-controls span {
  color: rgb(99, 115, 129) !important;
  display: inline-flex;
  padding: 9px 16px;
  border: 1.5px solid rgb(99, 115, 129);
  border-radius: 16px;
  font-size: 14px;
  font-family: "graphik-medium";
  margin: 6px 6px 11px 6px;
  font-weight: normal;
  cursor: pointer;
}
.ioj-roadmap .ioj-steps .insightsSteps .react-datepicker-popper .date-controls span.disabled {
  background-color: rgba(189, 189, 189, 0.34) !important;
  opacity: initial !important;
  border: 1.5px solid rgba(99, 115, 129, 0.34);
  color: rgba(99, 115, 129, 0.34) !important;
}

.insights-sections .information-status {
  margin: 1rem 0 0 0;
}
.insights-sections .information-status h4 {
  color: rgb(70, 0, 115);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
}
.insightsSteps.in-progress:hover .insights-sections .information-status h4,
.insightsSteps.completed:hover .insights-sections .information-status h4 {
  color: #ffffff;
}
.insights-sections .information-status ul {
  display: flex;
}
.insights-sections .information-status li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  margin-right: 10px;
}
.insights-sections .information-status li h6 {
  color: rgb(59, 59, 59);
  font-family: Graphik-Semibold;
  font-size: 16px;
  letter-spacing: -0.43px;
  margin-bottom: 4px;
  line-height: 14px;
}
.insightsSteps.in-progress:hover .insights-sections .information-status li h6,
.insightsSteps.completed:hover .insights-sections .information-status li h6 {
  color: #ffffff;
}
.insights-sections .information-status li span {
  color: rgb(59, 59, 59);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.43px;
}
.insightsSteps.in-progress:hover .insights-sections .information-status li span,
.insightsSteps.completed:hover .insights-sections .information-status li span {
  color: #ffffff;
}

/*  IOJ Phase R2 CSS changes for Glidepath page starts here  */
.ioj-roadmap-container.journeyinitiative-tabs {
  background: transparent;
  box-shadow: none;
  padding-top: 0;
  position: relative;
  top: 0px;
}
/*Summary button */
.summarydashboard-btn {
  position: absolute;
  right: 24px;
  top: -64px;
  margin-top: 0px;
  background: #fff;
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 35px;
  padding: 0 1rem;
  color: #a100ff;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.summarydashboard-btn:hover,
.summarydashboard-btn:focus,
.summarydashboard-btn:active {
  background: rgb(161, 0, 255) !important;
  color: rgb(255, 255, 255) !important;
  border: 1.5px solid rgb(161, 0, 255) !important;
}
.ioj-journey-initiative .container-fluid .row .col-lg-12 > button {
  float: right;
  z-index: 99;
  position: relative;
}
.ioj-journey-initiative .container-fluid .row .col-lg-12 > button:focus {
  box-shadow: none;
}
.ioj-journey-initiative .initiative-heading,
.initiative-heading {
  margin-top: 6px;
  margin-bottom: 6px;
}
.ioj-roadmap-container.journeyinitiative-tabs::before {
  display: none;
}
.ioj-roadmap-container.journeyinitiative-tabs .nav-tabs {
  border-bottom: none;
  padding: 0px;
}
.ioj-roadmap-container.journeyinitiative-tabs .tab-content {
  padding: 0px;
}
.ioj-roadmap-container.journeyinitiative-tabs .nav-tabs .nav-link.active:after,
.ioj-roadmap-container.journeyinitiative-tabs .nav-tabs .nav-link:hover:after {
  height: 8px;
  border-radius: 4px;
}
.ioj-roadmap-container .yettostart-msg {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
}
.ioj-roadmap-container .configurator-iojrating span {
  color: rgb(1, 142, 255);
  font-size: 15px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  padding-right: 24px;
  position: relative;
}
/* .ioj-roadmap-container .configurator-iojrating>span:last-child::before{
    position: absolute;
    width: 1px;
    content: '';
    height: 90%;
    top: 0;
    bottom: 0;
    left: -12.5px;
    background-color: rgb(1, 142, 255);
  } */
.assesmentTab-container {
  display: flex;
}
.assesmentTab-container .list-cards {
  width: calc(50% - 20px);
  height: 256px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 0 10px;
  padding: 38px 24px;
  position: relative;
}
.assesmentTab-container .list-cards::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(-180deg, rgb(147, 23, 226) 0%, rgb(117, 0, 192) 100%);
  border-radius: 7.85px 7.85px 0px 0px;
  height: 14px;
  width: 100%;
}
.assesmentTab-container .list-cards h2 {
  color: rgb(132, 11, 209);
  font-size: 22px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.47px;
  line-height: 24px;
  margin: 0;
}
.assesmentTab-container .list-cards .rating-section p {
  margin: 0px;
}
.assesmentTab-container .list-cards .rating-section,
.assesmentTab-container .start-assesment {
  display: flex;
}
.assesmentTab-container .start-assesment .link-to-assesment-main,
.assesmentTab-container .start-assesment .link-to-report-main {
  width: 60%;
  /* text-align: center; */
  margin: 0;
}
.assesmentTab-container .start-assesment .link-to-report-main {
  width: 40%;
}
.assesmentTab-container .list-cards .rating-section {
  padding: 42px 0;
}
.assesmentTab-container .list-cards .rating-section p.asses-name {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.43px;
  line-height: 18px;
}
.assesmentTab-container .list-cards .rating-section p.asses-caption {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.assesmentTab-container .list-cards .rating-section .rating-left,
.assesmentTab-container .list-cards .rating-section .rating-right {
  width: 60%;
  padding: 0 0 0 44px;
  position: relative;
}
.assesmentTab-container .list-cards .rating-section .rating-right {
  width: 40%;
}
.assesmentTab-container .list-cards .rating-section .rating-left::before,
.assesmentTab-container .list-cards .rating-section .rating-right::before {
  position: absolute;
  content: "";
  background: url("../Images/ioj-insights-icon.svg") no-repeat;
  width: 32px;
  height: 32px;
  left: 0;
  top: -4px;
  bottom: 0;
  margin: auto;
}
.assesmentTab-container .list-cards .rating-section .rating-right::before {
  background: url("../Images/current-score-icon.svg") no-repeat;
}
.assesmentTab-container .start-assesment .link-to-assesment {
  margin: 0;
}
/*  IOJ Phase R2 CSS changes for Glidepath page starts here  */
/* Initiative Form CSS Start */
.initiative-formsection h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 22px;
  margin-bottom: 1rem;
}

.initiative-formtabs .nav-tabs {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  border-bottom: none;
  padding: 0;
}

.initiative-formtabs .nav-tabs .nav-link {
  background: #fff;
  color: rgb(99, 115, 129);
  font-size: 15px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  padding: 20px 40px 20px 50px;
  margin-right: 0px !important;
  min-width: 24%;
  align-items: flex-start;
  position: relative;
}
.initiative-formtabs.IOJTabs .nav-tabs .nav-link{
  min-width: 25%;
}

.initiative-formtabs.IOJTabsFE .nav-tabs .nav-link{
  min-width: 17%;
}


.initiative-formtabs .nav-tabs .nav-link:hover,
.initiative-formtabs .nav-tabs .nav-link:active,
.initiative-formtabs .nav-tabs .nav-link.active,
.initiative-formtabs .nav-tabs .nav-link:focus {
  color: rgb(1, 143, 255);
  background-color: #fff !important;
  font-weight: 900;
}

.initiative-formtabs .nav-tabs .nav-link.visited {
  color: rgb(126, 195, 68);
  font-weight: 900;
}

.initiative-formtabs .nav-tabs .nav-link:after {
  background: none !important;
}

.initiative-formtabs .nav-tabs .nav-link:after {
  top: 2% !important;
  content: "";
  position: absolute;
  right: 0 !important;
  left: auto !important;
  width: 50px !important;
  height: 50px !important;
  background: #999;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  box-shadow: -5px -4px 6px -4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -5px -4px 6px -4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -5px -4px 6px -4px rgba(0, 0, 0, 0.1);
  z-index: 99;
  border-radius: 0;
  bottom: 0;
}

.initiative-formtabs .nav-tabs .nav-link:last-child:after {
  display: none;
}

.initiative-formfields {
  padding: 1.5rem 3rem;
}

.initiative-formtabs .form-group.border-group {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  position: relative;
}

.initiative-formtabs .border-group label.form-label {
  color: #637381;
}

.initiative-formtabcontent .initiative-dropdownvalue {
  position: relative;
  width: 100%;
  font-size: 0.85rem;
  cursor: pointer;
  color: #919eab;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 18px;
}

.initiative-formtabcontent .initiative-dropdownvalue:after {
  content: "\F078";
  font-family: "Font awesome\ 5 Pro";
  position: absolute;
  right: 5px;
  top: 0;
}

.initiative-formtabs .initiative-dropdown {
  display: flex;
  align-items: center;
}

.initiative-formtabs .initiativeheading-dropdownlist {
  width: 101%;
  left: -1px;
  top: 56px;
}

.initiativecriteria-dropdownbtn .initiativeheading-dropdownlist {
  width: 100%;
}

.initiative-formtabcontent textarea {
  min-height: 90px;
}

.initiative-navbtns {
  display: flex;
  justify-content: space-between;
}

.initiative-navbtns .btn {
  border-radius: 20px;
  height: 40px;
  padding: 0.35rem 1.2rem;
}

.initiative-formtabcontent input:-moz-placeholder,
.initiative-formtabcontent textarea:-moz-placeholder {
  color: #999999;
}

.initiative-formtabcontent input:-ms-input-placeholder,
.initiative-formtabcontent textarea:-ms-textarea-placeholder {
  color: #999999;
}

.initiative-formtabcontent input::-webkit-input-placeholder,
.initiative-formtabcontent textarea::-webkit-textarea-placeholder {
  color: #999999;
}

.leftinitiative-formfields {
  width: 50%;
  border-right: 1px dashed rgb(196, 205, 213);
  padding-right: 0.5rem;
  margin-right: 1.5rem;
}
.Adoption-formfields{
  width: 100%;
  border: none;
}

.leftinitiative-formfields form {
  padding-right: 12px;
  margin-bottom: 10px;
}

.initiative-addcancel-btns {
  display: flex;
  justify-content: flex-end;
}

.initiative-addcancel-btns .btn {
  height: 32px;
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  min-width: 73px;
}

.rightinitiative-formfields h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-bottom: 1rem;
}
.rightinitiative-formfields {
  width: 50%;
}
.rightinitiative-formfields .customscroll-form {
  max-height: 360px;
  overflow-y: auto;
  padding-right: 12px;
  height: 100%;
}
.rightinitiative-formfields .metricvalues-list {
  margin: 0;
  display: flex;
  list-style-type: none;
}
.rightinitiative-formfields .metricvalues-list li {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.21px;
  line-height: 14px;
  padding-right: 7px;
  margin-right: 7px;
  border-right: 1px solid rgb(145, 158, 171);
}
.rightinitiative-formfields .metricvalues-list li:last-child {
  border-right: none;
}
.rightinitiative-formfields .form-group.border-group {
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rightinitiative-formfields .border-group label.form-label {
  color: rgb(33, 43, 54);
  font-family: "graphik";
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin-bottom: 8px;
}
.rightinitiative-formfields .border-group label.form-label span {
  padding-right: 7px;
  margin-right: 7px;
  border-right: 1px solid rgb(33, 43, 54);
}
.rightinitiative-formfields .border-group label.form-label span:last-child {
  border-right: none;
}
.mectricimpacted-morelink {
  position: relative;
}
.mectricimpacted-morelink .morelink-icon span {
  font-size: 26px;
  color: #637381;
  position: relative;
}
.mectricimpacted-morelink .dropdown-menu {
  width: 150px;
  min-width: 150px;
  left: 16px !important;
  top: 26px !important;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
  padding: 8px 0;
}
.mectricimpacted-morelink .dropdown-menu .dropdown-item {
  color: #637381 !important;
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  padding: 6px 6px 6px 27px;
}
.mectricimpacted-morelink .dropdown-menu .dropdown-item svg {
  margin-right: 10px;
}

.mectricimpacted-morelink .dropdown-menu .dropdown-item:hover {
  color: #212b36 !important;
  font-family: Graphik-Medium;
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 4px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
}
.dropdown-menu .edit-icon,
.dropdown-menu .delete-icon {
  position: relative;
}
.dropdown-menu .edit-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url("../Images/edit-normal.svg") no-repeat;
  width: 14px;
  height: 14px;
  left: 7px;
  top: 8px;
}
.dropdown-menu .edit-icon:hover::before {
  background: url("../Images/edit-gradient.svg") no-repeat;
}
.dropdown-menu .delete-icon::before {
  position: absolute;
  content: "";
  display: inline-flex;
  background: url("../Images/delete-normal.svg") no-repeat;
  width: 14px;
  height: 14px;
  left: 7px;
  top: 8px;
}
.dropdown-menu .delete-icon:hover::before {
  background: url("../Images/delete-gradient.svg") no-repeat;
}
.initiative-datepicker .datepicker-label-wrapper {
  margin-bottom: 0;
  cursor: pointer;
}
.initiative-datepicker .calender-placment {
  font-size: 18px;
  right: 16px;
}
.initiativeheading-dropdownlist ul li:hover .form-check {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.ioj-rating-info i {
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 1px solid rgb(1, 142, 255);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  top: -2px;
  left: 6px;
}
.ioj-rating-info.baseHover:hover span {
  left: -135px;
  width: 300px;
  cursor: pointer;
}
.initiative-dropdown .dropdown {
  width: 100%;
  position: relative;
  height: 20px;
  outline: none;
}
.initiative-dropdown .dropdown .dropdown-toggle:focus {
  box-shadow: none !important;
}
.initiative-dropdown .dropdown .dropdown-toggle,
.initiative-formtabcontent
  .selectsuboffering-dropdown.nested-dropdown-wrapper
  button.dropdown-toggle {
  width: 100%;
  font-size: 0.85rem;
  color: #919eab !important;
  padding: 0;
  text-align: left;
  background: transparent !important;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 25px;
}
.initiative-dropdown .dropdown .dropdown-toggle.selected-option {
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
}
.initiative-dropdown .dropdown .dropdown-toggle:after,
.initiative-formtabcontent .selectsuboffering-dropdown .dropdown-toggle:after {
  position: absolute;
  color: #919eab;
  right: 1rem;
  content: "\F078";
  font-family: "Font awesome\ 5 Pro";
  border: none;
  transition: none;
  transform: none;
  top: 3px;
}
.initiative-dropdown .dropdown-menu {
  width: calc(100% + 22px);
  left: -11px !important;
  top: 5px !important;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  max-height: 240px;
  overflow-y: auto;
  inset: 0px auto auto -10px !important;
}
.initiative-dropdown .dropdown-menu .dropdown-item {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  white-space: normal;
  font-weight: normal;
  letter-spacing: -0.25px;
  padding: 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.initiative-dropdown .dropdown-menu .dropdown-item:hover,
.initiative-dropdown .dropdown-menu .dropdown-item:focus,
.initiative-dropdown .dropdown-menu .dropdown-item.active {
  background: rgba(255, 255, 255, 0.2)
    linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 4px;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
  border: none;
}
.categoryfilter-footer {
  background: #f4f6f8;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.categoryfilter-footer .btn {
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  width: 65px;
}
/* Initiative Form CSS End */
.ioj-assessment-modal .modal-header {
  padding-top: 14px;
}
.ioj-assessment-modal .modal-dialog {
  max-width: 60%;
}

/* Add initiative dropdown button css starts here*/
.addInitiative-dropdown .dropdown-toggle:after {
  border-bottom: 2px solid rgb(161, 0, 255);
  border-right: 2px solid rgb(161, 0, 255);
  top: -2px;
  display: none;
}
.journeyinitiative-tabs .addInitiative-dropdown .addcriteria-btn:hover {
  background: #fff;
  color: rgb(161, 0, 255);
}
.journeyinitiative-tabs .addInitiative-dropdown .dropdown-menu {
  padding: 0px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 150px;
}
.journeyinitiative-tabs .addInitiative-dropdown .dropdown-menu a {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 12px 0px 12px 36px;
  background-color: #fff;
  position: relative;
}
.journeyinitiative-tabs .addInitiative-dropdown .dropdown-menu a:hover {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 0px;
  color: rgb(33, 43, 54);
  padding: 12px 0px 12px 36px;
  font-family: "Graphik-Medium";
}
.journeyinitiative-tabs .addInitiative-dropdown .dropdown-menu a::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  left: 12px;
  margin: auto;
}
.journeyinitiative-tabs .addInitiative-dropdown .dropdown-menu a.add-new::before {
  background: url("../Images/addnew-grey.svg") no-repeat;
}
.journeyinitiative-tabs .addInitiative-dropdown .dropdown-menu a.add-new:hover::before {
  background: url("../Images/addnew-hover.svg") no-repeat;
}
.journeyinitiative-tabs .addInitiative-dropdown .dropdown-menu a.drafts::before {
  background: url("../Images/drafts-grey.svg") no-repeat;
}
.journeyinitiative-tabs .addInitiative-dropdown .dropdown-menu a.drafts:hover::before {
  background: url("../Images/drafts-hover.svg") no-repeat;
}
.ioj-drafts-modal .modal-dialog {
  max-width: 60%;
  margin-top: 8%;
}
.ioj-drafts-modal .modal-title,
.ioj-drafts-modal .drafts-list .initiative-names {
  color: rgb(53, 64, 83);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 20px;
}
.ioj-drafts-modal .drafts-list .initiative-categories span {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.29px;
  position: relative;
}
.ioj-drafts-modal .drafts-list .initiative-categories span::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 1.25px;
  background: rgb(99, 115, 129);
  height: 100%;
  left: 6px;
  margin: auto;
}
.ioj-drafts-modal .drafts-list .initiative-categories span:first-child:before {
  display: none;
}
.ioj-drafts-modal .drafts-list .initiative-categories span:first-child {
  padding-left: 0px;
}
.ioj-drafts-modal .drafts-list .initiative-categories span {
  padding-left: 12px;
}
.ioj-drafts-modal .modal-footer button,
.ioj-drafts-modal .modal-footer button:active,
.ioj-drafts-modal .modal-footer button:active:focus,
.ioj-drafts-modal .modal-footer button:focus {
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(145, 158, 171);
  border-radius: 24px;
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.35px;
  line-height: 16px;
  padding: 14px 33px;
  box-shadow: none;
  outline: none;
  float: right;
}
.ioj-drafts-modal .modal-header,
.ioj-drafts-modal .modal-body,
.ioj-drafts-modal .modal-footer {
  padding-left: 24px;
  padding-right: 24px;
}
.ioj-drafts-modal .drafts-wrapper .drafts-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 8px 0px rgb(33, 43, 54, 0.1);
  height: 72px;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 16px 26px;
}
.ioj-drafts-modal .drafts-wrapper .list-actions span {
  margin-left: 24px;
  cursor: pointer;
}
.ioj-drafts-modal .drafts-wrapper .list-actions span:first-child {
  margin-left: 0px;
}
/* Add initiative dropdown button css ends here */
.suffix-text {
  position: absolute;
  bottom: 0;
  margin: auto;
  right: 14px;
  display: flex;
  align-items: center;
  font-size: 10px;
}
.suffix-text.centerEle {
  top: 14px;
  font-size: 12px;
}
.initiative-formtabcontent .initiative-datepicker .react-datepicker-wrapper {
  width: 90%;
}
/* Initative Details page New Tile design*/
.kpi-initiative .top-data-section .metric-days {
  color: rgb(33, 43, 54);
  font-size: 24px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 28px;
  margin: 0;
  padding-bottom: 4px;
}
.kpi-initiative .top-data-section .metric-days span {
  font-size: 16px;
  line-height: 20px;
}
.kpi-initiative .top-data-section .metric-suffix {
  color: rgb(99, 115, 129);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}
.kpi-initiative .bottom-data-section {
  display: flex;
  position: absolute;
  bottom: 18px;
  width: 100%;
}
.kpi-initiative .bottom-data-section .metric-val {
  width: 50%;
}
.kpi-initiative .bottom-data-section .metric-val .value-text,
.kpi-initiative .bottom-data-section .metric-val .value-suffix {
  color: rgb(99, 115, 129);
  font-size: 11px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  margin: 0;
}
.kpi-initiative .bottom-data-section .metric-val .value-suffix {
  font-family: Graphik-Semibold;
  padding-top: 2px;
}
.initiativesoutcomes-listitem {
  flex-grow: 1;
  flex-basis: 0;
}
.initiativesoutcomes-listitem {
  flex-grow: 1;
  flex-basis: 0;
}
.initiativesoutcomes-listitem.initiative-name {
  flex-grow: 2;
  flex-basis: 0;
}
.initiativesoutcomes-listitem.initiative-storylink,
.initiativesoutcomes-listitem.initiative-outcome {
  flex-grow: 3;
  flex-basis: 0;
}
.initiative-formsection h4.initiativedetail-editcase {
  margin-bottom: 2rem;
  padding-left: 1rem;
  position: relative;
  top: 10px;
  display: inline-block;
}
/* Initative Details page New Tile design END*/
/*status dropdown css start*/
.stateColor .custombg-headerdropdown {
  display: inline-flex;
}
.stateColor .custombg-headerdropdown .dropdown-toggle {
  background-color: transparent;
  padding: 0px;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.29px;
  outline: none !important;
  box-shadow: none;
  display: inline-flex;
  border-radius: 0;
  position: relative;
  line-height: 24px;
}
.stateColor .custombg-headerdropdown .dropdown-toggle:hover,
.stateColor .custombg-headerdropdown .dropdown-toggle:active,
.stateColor .custombg-headerdropdown .dropdown-toggle:focus,
.stateColor .custombg-headerdropdown.show .dropdown-toggle {
  background: transparent !important;
  box-shadow: none !important;
  color: rgba(255, 255, 255, 0.8) !important;
  line-height: 24px;
}

.stateColor .custombg-headerdropdown .dropdown-toggle:after {
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
  border-right: 2px solid rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 6px;
  bottom: 0;
  right: -13px;
}
.stateColor .custombg-headerdropdown .dropdown-menu {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  min-width: 130px;
  padding: 0.75rem 0;
  z-index: 9;
  height: 150px;
}
.stateColor .custombg-headerdropdown .dropdown-menu a {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-weight: normal;
  font-family: "graphik";
  letter-spacing: -0.25px;
  line-height: 18px;
  background: transparent;
  padding: 0.75rem;
  display: block;
}
.stateColor .custombg-headerdropdown .dropdown-menu a:hover,
.stateColor .custombg-headerdropdown .dropdown-menu a.active {
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 0px;
  font-family: Graphik-Medium;
  font-weight: 500;
}
/*status dropdown css end*/
/* Summary Dashboard CSS Start */
.summarydashboard-container {
  display: flex;
  margin-bottom: 2rem;
}
.summarydashboard-card {
  width: calc(50% - 20px);
  margin: 0 10px;
}
.summarydashboard-tilebg {
  position: relative;
  padding: 1.5rem;
  background: rgb(255, 255, 255);
  border-radius: 6px;
  border: 1px solid rgb(233, 239, 244);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}
.summrydashboard-tab h4 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.64px;
  margin-bottom: 1rem;
}
.summarytile-values {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.summarytile-values h3 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.86px;
  margin-bottom: 0;
}
.summarytile-values h3.summarytile-metricunit {
  color: rgb(99, 115, 129);
}
.summarykpiimpacted-slider .carousel {
  margin: 0 10px;
  background: rgb(222, 225, 251);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  padding: 1.5rem 2.8rem;
}
.summarykpiimpacted-slider .carousel-control-next,
.summarykpiimpacted-slider .carousel-control-prev {
  width: 5%;
}
.summarykpiimpacted-slider .carousel-control-prev-icon {
  background: transparent url(../Images/Left_arrow.svg) no-repeat scroll center;
}
.summarykpiimpacted-slider .carousel-control-next-icon {
  background: transparent url(../Images/Right_arrow.svg) no-repeat scroll center;
}
.summarykpiimpacted-slider .carousel-indicators {
  bottom: -2.5rem;
}
.summarykpiimpacted-slider .carousel-indicators li {
  width: 8px;
  height: 8px;
  background: rgb(216, 216, 216);
  border-radius: 50%;
  border: none;
}
.summarykpiimpacted-slider .carousel-indicators li.active,
.summarykpiimpacted-slider .carousel-indicators li:hover {
  background: rgb(1, 142, 255);
}
.summarykpiimpacted-slider .carousel-item ul {
  display: flex;
  margin: 0;
}
.summarykpiimpacted-slider .carousel-item li {
  width: 25%;
  margin: 0 10px;
  color: rgb(60, 72, 88);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  min-height: 28px;
  letter-spacing: 0px;
  line-height: 14px;
}
.doughnutchart-height .recharts-responsive-container {
  height: 310px !important;
}
.custom-bubblelegend {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.custom-bubblelegend ul {
  display: flex;
  margin: 0;
  align-items: center;
}
.custom-bubblelegend li {
  display: flex;
  color: rgb(0, 0, 0);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.89px;
  align-items: center;
  margin-right: 1rem;
}
.custom-bubblelegend li span {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 4px;
}
.custom-bubblelegend li span.concept-dot {
  background: rgb(156, 106, 222);
}
.custom-bubblelegend li span.flight-dot {
  background: rgb(0, 186, 255);
}
.custom-bubblelegend li span.delivered-dot {
  background: rgb(38, 193, 201);
}
.custom-bubblelegend li span.hold-dot {
  background: rgb(145, 158, 171);
}
.config-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.omid-enable-toggle > span {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: -0.22px;
  font-family: "graphik";
  display: flex;
  align-self: flex-end;
}
/* toggle switch css */
.omid-enable-toggle {
  display: flex;
  align-items: center;
}
.omid-enable-toggle .onoffswitch {
  width: 40px;
}
.omid-enable-toggle .onoffswitch .onoffswitch-switch {
  background: rgb(195 195 195);
  box-shadow: none;
}
.omid-enable-toggle .onoffswitch .onoffswitch-inner:before,
.omid-enable-toggle .onoffswitch .onoffswitch-inner:after {
  height: 24px;
}

.omid-enable-toggle .onoffswitch .onoffswitch-inner:before {
  background: #fff;
}
/* Association Tab Toggle Switch */
.association-togglegroup {
  justify-content: space-between;
}
.association-togglegroup .association-tooltip {
  max-width: 200px;
  padding: 8px 10px;
  line-height: 16px;
}
.omid-enable-toggle .onoffswitch .onoffswitch-switch {
  height: 16px;
  top: 4.5px;
  width: 16px;
  right: 20px;
}

.omid-enable-toggle
  .toggle-group
  input[type="checkbox"]:checked
  ~ .onoffswitch
  .onoffswitch-label
  .onoffswitch-switch:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  left: 2px;
  top: -1.5px;
  font-size: 12px;
  color: #fff;
}
.omid-enable-toggle
  .toggle-group
  input[type="checkbox"]:checked
  ~ .onoffswitch
  .onoffswitch-label
  .onoffswitch-switch {
  box-shadow: none;
  background: rgb(1, 142, 255);
  right: 3px;
}
.omid-enable-toggle .toggle-group {
  height: auto;
  margin-left: 12px;
  display: flex;
}
.omid-enable-toggle .toggle-group input[type="checkbox"] {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.omid-enable-toggle .toggle-group label {
  margin: 0;
  height: 100%;
  min-height: unset;
}
.omid-enable-toggle .onoffswitch .onoffswitch-label {
  border: none;
  box-shadow: none;
  right: 12px;
}
.omid-enable-toggle .selecttooltip .selecttooltiptext {
  display: none;
}
.omid-enable-toggle .selecttooltip:hover .selecttooltiptext {
  display: block;
  position: absolute;
  width: 230px;
  right: 2px;
  z-index: 999;
  bottom: -40px;
  background: rgb(50, 60, 71);
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 10%);
  border-radius: 4px;
  text-align: center;
  padding: 8px;
  font-size: 12px;
  font-family: "graphik";
  color: #fff;
}
.omid-enable-toggle .omid-toggle-tooltip:hover .omid-toggle-tooltiptext,
.omid-enable-toggle .omid-toggle-tooltip:hover .omid-toggle-tooltiptext-dashboard {
  display: block;
  position: absolute;
  width: 230px;
  right: 2px;
  z-index: 999;
  top: 100%;
  background: rgb(50, 60, 71);
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 10%);
  border-radius: 4px;
  text-align: center;
  padding: 8px;
  font-size: 12px;
  font-family: "graphik";
  color: #fff;
}
.omid-enable-toggle .selecttooltip:hover .selecttooltiptext::after,
.omid-enable-toggle .omid-toggle-tooltip:hover .omid-toggle-tooltiptext-dashboard::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 2%;
  margin-left: -5px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  border: 5px solid transparent;
  border-top-color: #000;
}

.omid-enable-toggle .omid-toggle-tooltip:hover .omid-toggle-tooltiptext-dashboard::after {
  right: 8%;
}

.omid-enable-toggle .omid-toggle-tooltip:hover .omid-toggle-tooltiptext::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 40%;
  margin-left: -5px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  border: 5px solid transparent;
  border-top-color: #000;
}
.disabled-omid {
  color: rgb(213, 0, 47);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: 0px;
  padding-left: 22px;
  position: relative;
  top: 24px;
}
.disabled-omid::before {
  content: "";
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 4px;
  left: 0;
  margin: auto;
  background: url("../Images/exclamation-triangle.svg") no-repeat;
  display: inline-flex;
  position: absolute;
}
/* toggle switch css end */
/* SummaryTab Chart Tooltip Start */
#chartjs-bubbleIOJTooltip {
  opacity: 1;
  position: absolute;
  background: rgb(50, 60, 71);
  color: rgb(255, 255, 255);
  border-radius: 2px;
  padding: 10px;
  min-width: 40px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  /* max-height: 350px; */
  /* -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease; */
  -webkit-transform: translate(-50%, -120%);
  transform: translate(-50%, -120%);
  z-index: 1;
  /* overflow: scroll; */
}

div#chartjs-bubbleIOJTooltip:after {
  content: "";
  height: fit-content;
  width: fit-content;

  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgb(50, 60, 71);
  position: absolute;
  margin-left: -6px;
  margin-top: -8px;
  /* top: 100%; */
  left: 50%;
  transform: rotate(0deg);
  z-index: 1;
  bottom: -5px;
  /* box-shadow: 2px 5px 5px 0px rgba(33, 43, 54, 0.1); */
}
.bubbleIOJTooltip-content {
  color: rgba(255, 255, 255, 0.87);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 11px;
}
.doughnutchart-height .recharts-wrapper .recharts-layer tspan {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.36px;
}

/* Initative Summary CSS Changes*/
.rightinitiative-formfields .association-togglegroup .form-group.border-group {
  width: 85%;
  margin-right: 10px;
}
.initiativesummary-accordion {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.initiativesummary-accordion .card {
  border: none;
  background: rgb(244, 246, 248);
  border-radius: 0;
}
.initiativesummary-accordion .card-header {
  border-bottom: none;
  background-color: transparent;
  padding: 1rem 1.5rem;
}
.initiativesummary-accordion .card-header .card-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.initiativesummary-accordion .card-header h3 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.35px;
  margin-bottom: 0;
}
.initiativesummary-accordion .card-header .cardlink-icon span {
  width: 24px;
  border: 1px solid #212b36;
  border-radius: 100%;
  height: 24px;
  display: block;
  text-align: center;
  position: relative;
  line-height: 24px;
}
.initiativesummary-accordion .card-header .card-link .cardlink-icon span:before {
  content: "\f068";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  font-family: "Font awesome 5 Pro";
  color: #212b36;
}
.initiativesummary-accordion .card-header .card-link.collapsed .cardlink-icon span:before,
.initiativesummary-accordion
  .card-header
  .card-link[aria-expanded="false"]
  .cardlink-icon
  span:before {
  content: "\f067";
}
.initiativesummary-accordion .card-body {
  padding: 1rem 1.5rem;
}
.initiativesummary-accordion .summaryaccordion-list {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.initiativesummary-accordion .summaryaccordion-list li {
  display: flex;
  margin-bottom: 1rem;
}
.initiativesummary-accordion li .initiativesummary-name {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.21px;
  margin-right: 10px;
  margin-bottom: 0;
  width: 50%;
  line-height: 20px;
}
.initiativesummary-accordion li .initiativesummary-value {
  color: rgb(161, 161, 161);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  width: 50%;
  line-height: 20px;
}

/* Export button css */
.iojexport-btn {
  position: absolute;
  right: 0px;
  top: 0px;
}
.listOfAddCriteria.initiativecriteria-list {
  position: relative;
}
.ioj-journey-initiative .button-get-data {
  /* position: absolute;
    right: 0;
    top: -50px; */
}
.stateColor .initiative-dropdown-overlay {
  z-index: 2;
}

/* Performance Popup Start */
.insightsSteps.completed.in-flight-activeparent:hover
  .insights-sections
  .information-status
  li.in-flight-active,
.insightsSteps.in-progress.in-flight-activeparent:hover
  .insights-sections
  .information-status
  li.in-flight-active,
.insightsSteps.yetto-start.partially-start.in-flight-activeparent:hover
  .insights-sections
  .information-status
  li.in-flight-active,
.insightsSteps.yetto-start.in-flight-activeparent:hover
  .insights-sections
  .information-status
  li.in-flight-active {
  background: linear-gradient(90deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%);
  border-radius: 8px;
  border: none;
  box-shadow: none;
}
.insightsSteps.in-progress.in-flight-activeparent:hover
  .insights-sections
  .information-status
  li.in-flight-active:hover,
.insightsSteps.completed.in-flight-activeparent:hover
  .insights-sections
  .information-status
  li.in-flight-active:hover,
.insightsSteps.yetto-start.in-flight-activeparent:hover
  .insights-sections
  .information-status
  li.in-flight-active:hover {
  background: linear-gradient(90deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%);
  border-radius: 8px;
  border: 1px solid rgb(190, 130, 255);
  box-shadow: 0px 0px 4px 0px rgb(255 255 255 / 50%);
  cursor: pointer;
}

.insights-sections .information-status li.green-performance h6,
.insights-sections .information-status li.green-performance span {
  color: rgb(100, 156, 54);
  cursor: pointer;
}
.insights-sections .information-status li.amber-performance h6,
.insights-sections .information-status li.amber-performance span {
  color: rgb(243, 116, 9);
  cursor: pointer;
}
.insights-sections .information-status li.red-performance h6,
.insights-sections .information-status li.red-performance span {
  color: rgb(213, 0, 47);
  cursor: pointer;
}
.insights-sections .information-status li.grey-performance h6,
.insights-sections .information-status li.grey-performance span {
  color: rgb(213, 0, 47);
  cursor: pointer;
}
.iojperformance-modal .modal-dialog {
  max-width: 970px;
}
.iojperformance-modal .modal-body {
  padding: 2rem;
}
.ioj-performancemodal .performance-modalheader {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.performance-breadcrumbnav {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.39px;
  line-height: 18px;
  border-left: 2px solid rgb(33, 43, 54);
  padding-left: 8px;
}
.performance-modalheader .close {
  position: absolute;
  right: 20px;
  top: 10px;
}
.performance-modalheader .dropdown-toggle {
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  color: rgb(244, 147, 66) !important;
  font-family: Graphik-Semibold;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.39px;
  line-height: 22px;
  padding: 0;
}
.performance-modalheader .dropdown-menu {
  padding: 0.5rem 0;
}
.performance-modalheader .dropdown-menu .dropdown-item {
  color: rgb(244, 147, 66);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0.8rem 1rem;
}
.performance-modalheader .dropdown-menu .dropdown-item.dropdownitem-amber {
  color: rgb(244, 147, 66) !important;
}
.performance-modalheader .dropdown-menu .dropdown-item.dropdownitem-green {
  color: rgb(126, 195, 68) !important;
}
.performance-modalheader .dropdown-menu .dropdown-item.dropdownitem-red {
  color: rgb(213, 0, 47) !important;
}
.performance-modalheader .dropdown-menu .dropdown-item.dropdownitem-grey {
  color: #919eab !important;
}
.amberperformance .performance-modalheader .dropdown-toggle {
  color: rgb(244, 147, 66) !important;
}
.greenperformance .performance-modalheader .dropdown-toggle {
  color: rgb(126, 195, 68) !important;
}
.redperformance .performance-modalheader .dropdown-toggle {
  color: rgb(213, 0, 47) !important;
}
.greyperformance .performance-modalheader .dropdown-toggle {
  color: #919eab !important;
}
.performance-modalheader .dropdown-menu .dropdown-item:hover,
.performance-modalheader .dropdown-menu .dropdown-item.active {
  background: rgb(244, 246, 248);
}
.performance-modalheader .dropdown-toggle:after {
  border-bottom: 1px solid #018eff;
  border-right: 1px solid #018eff;
}
.performance-initiativelist {
  max-height: 465px;
  overflow-y: auto;
}
.performance-initiativelist .performance-parent-ul {
  flex-wrap: wrap;
  padding: 6px 6px 0 6px;
}
.performance-initiativelist ul {
  display: flex;
  margin: 0;
}
.performance-initiativelist .performance-parent {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 1px 7px 1px rgba(208, 193, 217, 0.62);
  width: calc(50% - 16px);
  padding: 16px;
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.perfomance-initiativename {
  position: relative;
  padding-left: 1rem;
}
.perfomance-initiativename:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    -225deg,
    rgb(255, 197, 139) 0%,
    rgb(244, 147, 66) 50%,
    rgb(192, 87, 23) 100%
  );
  border-radius: 4px;
  height: 100%;
  width: 4px;
}
.amberperformance .perfomance-initiativename:before {
  background: linear-gradient(
    -225deg,
    rgb(255, 197, 139) 0%,
    rgb(244, 147, 66) 50%,
    rgb(192, 87, 23) 100%
  );
}
.greenperformance .perfomance-initiativename:before {
  background: linear-gradient(
    -225deg,
    rgb(184, 214, 158) 0%,
    rgb(126, 195, 68) 51%,
    rgb(126, 195, 68) 100%
  );
}
.redperformance .perfomance-initiativename:before {
  background: linear-gradient(
    -225deg,
    rgb(245, 135, 159) 0%,
    rgb(213, 0, 47) 50%,
    rgb(213, 0, 47) 100%
  );
}
.greyperformance .perfomance-initiativename:before {
  background: linear-gradient(
    -225deg,
    rgb(195, 225, 255) 0%,
    rgb(145, 158, 171) 50%,
    rgb(145, 158, 171) 100%
  );
}
.greenperformance .initiative-Grey .perfomance-initiativename:before {
  background: linear-gradient(
    -225deg,
    rgb(197, 210, 223) 0%,
    rgb(145, 158, 171) 50%,
    rgb(145, 158, 171) 100%
  );
}

.performance-initiativelist h6 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.31px;
  margin-bottom: 1rem;
}
.performance-initiativelist p {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.31px;
  margin-bottom: 0;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.perfomance-initiativename li {
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid rgb(145, 158, 171);
  white-space: nowrap;
}
.perfomance-initiativename li:last-child {
  border-right: none;
}

.performance-tooltip {
  max-width: 300px;
  white-space: break-spaces;
}
.performance-pointer {
  cursor: pointer;
}
.performance-body {
  min-height: 250px;
}
.performance-empty {
  font-size: 1.25rem;
  font-family: "graphik-medium";
  letter-spacing: -0.25;
  letter-spacing: -0.25px;
}
.tooltip-ioj {
  top: 70px !important;
  left: 580px !important;
}

.download-snapshot {
  position: absolute;
  right: 207px;
  top: -64px;
}
.download-snapshot .btn {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 22px;
  outline: none !important;
  box-shadow: none !important;
  padding: 10px 20px;
}
.download-snapshot .btn:hover,
.download-snapshot .btn:focus,
.download-snapshot .btn:active {
  background: rgb(161, 0, 255) !important;
  color: rgb(255, 255, 255) !important;
  border: 1.5px solid rgb(161, 0, 255) !important;
}

/* Draft Status CSS starts here */
.draft-status {
  width: 88px;
  border-radius: 16px;
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  height: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.draft-status.inconcept-color {
  background: rgba(156, 106, 222, 0.2);
  color: rgb(156, 106, 222);
}
.draft-status.onhold-color {
  background: rgba(145, 158, 171, 0.2);
  color: rgb(99, 115, 129);
}
.draft-status.indraft-color {
  background: rgba(244, 147, 66, 0.2);
  color: rgb(244, 147, 66);
}
.draft-status.inflight-color {
  background: rgba(0, 186, 255, 0.2);
  color: rgb(0, 186, 255);
}
.draft-status.delivered-color {
  background: rgba(62, 200, 207, 0.2);
  color: rgb(38, 193, 201);
}
.draft-status.rejected-color {
  background: rgb(255, 239, 242);
  color: rgb(170, 0, 37);
}
.draft-status.cancelled-color {
  background: rgb(249, 250, 251);
  color: rgb(99, 115, 129);
}
/* Draft Status CSS starts here */
/* Client Story Telling CSS start */
.storytelling-container .emptycriteria-block img {
  width: 90px;
  height: 72px;
}
.storytelling-container .emptycriteria-block h4 {
  font-size: 16px;
}
.storytelling-container .charcount-label {
  line-height: 10px !important;
  bottom: 0px;
}
.createstory-btn {
  position: absolute;
  right: 28px;
  top: 10px;
}
.btn-purplebg.btn {
  background: rgb(161, 0, 255);
  border-radius: 16px;
  color: rgb(255, 255, 255) !important;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 12px;
  padding: 9px 18px;
  box-shadow: none !important;
  outline: none;
}
.createstory-formsection {
  margin-bottom: 30px;
}
.createstory-formsection .createstory-form {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.15);
  padding: 1.25rem;
  margin-bottom: 1.25rem;
}
.createstory-form h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 24px;
}
.createstory-form .form-group.border-group {
  border-radius: 8px;
  border: 1px solid rgb(223, 227, 232);
  margin-bottom: 24px !important;
  position: relative;
}
.createstory-form .border-group label.form-label {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin-bottom: 5px;
}
.charcount-label {
  color: rgb(145, 158, 171);
  font-family: "graphik";
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.19px;
  position: absolute;
  right: 19px;
  bottom: -2px;
}
.createstory-form .border-group ::-webkit-input-placeholder {
  /* Edge */
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-weight: normal;
}

.createstory-form .border-group :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-weight: normal;
}

.createstory-form .border-group ::placeholder {
  color: rgb(145, 158, 171);
  font-size: 14px;
  font-weight: normal;
}
.createstory-form .border-group .form-control {
  color: rgb(21, 24, 27);
  font-family: graphik;
  font-size: 14px;
  line-height: 18px;
  background: transparent;
}
.createstory-form .form-group.border-group.disabled {
  background: rgb(239, 242, 245);
  border: 1px solid rgb(231, 234, 238);
}
.createstory-form .form-group.border-group.disabled label.form-label {
  color: rgb(145, 158, 171);
}
.createstory-form .form-group.border-group.disabled .form-control {
  color: rgb(181, 192, 202);
  padding-bottom: 0 !important;
}
.createstory-form .form-group.border-group .form-control:disabled {
  padding-bottom: 0 !important;
}
.customform-multidropdown {
  position: relative;
}
.customform-multidropdown .custom-dropdownname {
  color: #919eab;
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 20px;
  padding-right: 24px;
  cursor: pointer;
  position: relative;
}
.customform-multidropdown .custom-dropdownname:after {
  content: "\F078";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: 5px;
  top: -2px;
}
.customform-multidropdown .search-select-list {
  width: calc(100% + 16px);
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%);
  position: absolute;
  top: 32px;
  left: -8px;
  z-index: 2;
  border: none;
  padding: 8px 4px;
}
.createstory-form .custom-dropdown-search-overlay {
  z-index: 1;
}
.createstory-form .customform-multidropdown .search-select-list ul {
  max-height: 220px;
}
.createstory-form .customform-multidropdown .search-select-list li {
  padding: 6px 12px;
}
.createstory-form .customform-multidropdown .search-select-list label.form-check {
  padding: 0 0 8px 20px;
}
.customform-multidropdown .search-select-list li .checkmark {
  top: -6px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 4px;
}
.customform-multidropdown .search-select-list li .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 12px;
}
.createstory-form .customform-multidropdown .search-select-list li .form-check-label {
  color: #637381;
  font-size: 14px;
  font-family: graphik;
  font-weight: 400;
  letter-spacing: -0.35px;
  line-height: 14px;
  cursor: pointer;
  margin-left: 10px;
  padding-left: 0;
  padding-top: 6px;
}
.createstory-form .customform-multidropdown .search-select-list li .checkmark {
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 4px;
  left: 5px;
  top: 50%;
  transform: translate(-50%, -50%);
}
.customform-multidropdown .search-select-list li:hover {
  background: hsla(0, 0%, 100%, 0.2)
    linear-gradient(-225deg, rgba(161, 0, 255, 0.1), rgba(0, 186, 255, 0.1));
}
.customform-multidropdown .search-select-list li:hover .form-check-label,
.customform-multidropdown .search-select-list li.active .form-check-label {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.createform-errormsg {
  color: rgb(213, 0, 47);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.createstory-submit {
  display: flex;
  justify-content: flex-end;
}
.optionselect-notify {
  color: rgb(100, 156, 54);
  font-family: Graphik-Medium;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.19px;
  position: absolute;
  bottom: 8px;
}
.lateststory-updates {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
  margin-bottom: 1rem;
  display: inline-block;
}
.clientstory-card {
  background: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
  height: 201px;
  width: 370px;
  margin: 0 16px 16px 0px;
  position: relative;
}
.clientstory-card:hover,
.clientstory-card.dropshow {
  box-shadow: 0px 2px 48px 0px rgba(33, 43, 54, 0.25);
}
.clientstory-cardlist {
  display: flex;
  flex-wrap: wrap;
}
.clientstory-cardbody {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 40px);
  justify-content: center;
  padding: 1rem 1rem 0;
}
.clientstory-cardbody img {
  width: 72px;
  height: 58px;
  margin-bottom: 1rem;
}
.clientstory-cardbody h6 {
  color: rgb(21, 24, 27);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
}
.clientstory-cardbody p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 11px;
}
.ioj-component .tab-content .clientstory-card p {
  margin-bottom: 1rem;
}
.clientstory-cardfooter {
  border-top: 1px solid #dfe3e8;
  padding: 0 6px 0 12px;
}
.storytelling-modal .modal-dialog {
  max-width: 840px;
}
.storytelling-modalbody ul {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.storytelling-modalbody ul li {
  color: rgb(145, 158, 171);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 16px;
  position: relative;
  padding-right: 8px;
  margin-right: 8px;
}
.storytelling-modalbody ul li::after {
  content: " ";
  width: 1.5px;
  height: 14px;
  position: absolute;
  right: 0;
  top: 0;
  background: rgb(145, 158, 171);
}
.storytelling-modalbody ul li:last-child:after {
  display: none;
}
.storytelling-modalbody h3 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 24px;
}
.storytelling-modalbody p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.storytelling-carousel img.fullScreen {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  margin: 0 auto;
}
.close-excel-common {
  background-color: rgba(169, 169, 169, 0.7);
  top: 0;
  right: 16px;
  position: fixed;
  z-index: 9999;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}
.btn-maximize-common.btn {
  background: #a100ff;
  color: #fff !important;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  outline: none !important;
  box-shadow: none !important;
  border: none;
  padding: 10px 12px;
  position: absolute;
  right: 30px;
  top: 20px;
}
.storytelling-carousel {
  position: relative;
}
.storytelling-modal .modal-header .close {
  top: 30px;
  right: 24px;
}
.storytelling-carousel .casestudy-controllers span.left-controller {
  left: -22px;
}
.storytelling-carousel .casestudy-controllers span.right-controller {
  right: -22px;
}

.storytelling-carousel img {
  border-radius: 8px;
}
.passwordcopy-notification.storytelling {
  z-index: 9999;
  padding: 24px;
  min-width: 260px;
  width: auto;
  max-width: 420px;
}
.passwordcopy-notification.storytelling img {
  width: 31px;
  height: 31px;
}
/* Client Story Telling CSS end */

/* Start - Tooltip for OMID displayed in right corner of filter header */

.omid-enable-toggle .__react_component_tooltip {
  min-width: 240px;
}

.omid-enable-toggle .__react_component_tooltip.place-left.type-dark {
  margin-top: 2.1rem;
  margin-left: 5.6rem;
  text-align: center;
}

.omid-enable-toggle .__react_component_tooltip.place-left.type-dark::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 2.4rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  border: 8px solid transparent;
  border-top-color: rgb(28, 38, 47);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
/* End - Tooltip for OMID displayed in right corner of filter header */

/* Drag and Drop Table Structure CSS starts here */
.drag-drop-header,
.drag-drop-columns {
  display: flex; /* aligns all child elements (flex items) in a row */
}
.drag-drop-columns,
.drag-drop-columns .columns-data {
  flex: 1; /* distributes space on the line equally among items */
}
.ragTooltip {
  width: 7px;
  height: 20px;
  position: absolute;
  left: 12px;
}
.RAGtooltip {
  word-break: break-all;
  overflow-wrap: break-word;
  display: block;
}
.drag-drop-header {
  background: rgb(239, 242, 245);
  border-radius: 8px;
  padding: 17px 32px 17px 30px;
}
.drag-drop-header .drag-drop-columns {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.18px;
  padding-left: 14px;
}
.drag-drop-body .drag-drop-columns,
.dragging-helper-class {
  background: rgb(255, 255, 255);
  position: relative;
  padding: 12px 22px 12px 16px;
  border: 1.5px solid rgb(239, 242, 245);
  border-radius: 8px;
  margin: 8px 0px;
}
.drag-drop-columns.dragging-helper-class,
.drag-drop-body .drag-drop-columns:hover {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1.5px solid rgb(0, 142, 255);
  box-shadow: 0px 2px 48px 0px rgba(33, 43, 54, 0.25);
}
.drag-drop-columns .columns-data {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  user-select: none;
  position: relative;
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: "graphik";
  font-weight: normal;
  letter-spacing: -0.29px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.name-highlighted {
  color: rgb(1, 142, 255);
  cursor: pointer;
}
.drag-drop-body .drag-drop-columns .columns-data span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dragg-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.dragg-icon i {
  color: rgb(212, 213, 214);
  font-size: 14px;
}
.drag-drop-body .drag-drop-columns .columns-data:nth-child(2),
.drag-drop-columns.dragging-helper-class .columns-data:nth-child(2) {
  padding-left: 18px;
}
.drag-drop-body .drag-drop-columns .columns-data:nth-child(2) span,
.drag-drop-columns.dragging-helper-class .columns-data:nth-child(2) span {
  padding-left: 10px;
}
.drag-drop-body .drag-drop-columns .columns-data:nth-child(2)::before,
.drag-drop-columns.dragging-helper-class .columns-data:nth-child(2)::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 14px;
  border-radius: 2px;
  left: 13px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.drag-drop-columns .dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}
.drag-drop-columns .dropdown span {
  border: none;
  transform: rotate(90deg);
}
.drag-drop-columns .common-ellipsis-dropdown .dropdown .dropdown-menu.show {
  top: 0 !important;
  bottom: auto !important;
  left: 8px !important;
}
.drag-drop-columns .common-ellipsis-dropdown .dropdown.show .dropdown-toggle:hover,
.drag-drop-columns .common-ellipsis-dropdown .dropdown .dropdown-toggle:hover,
.drag-drop-columns .common-ellipsis-dropdown .dropdown span:hover,
.drag-drop-columns .common-ellipsis-dropdown .dropdown.show .dropdown-toggle {
  background: transparent;
}
.drag-drop-columns .dropdown-toggle:empty::after {
  display: none;
}
.drag-drop-columns .common-ellipsis-dropdown .dropdown .dropdown-menu a span::before {
  top: -1px !important;
}
.drag-drop-columns .common-ellipsis-dropdown .dropdown .dropdown-menu a span.eye-icon::before {
  top: 1px !important;
}
.form-group-container {
  display: flex;
  flex-wrap: wrap;
}
.form-group-container .form-group-wrapper {
  flex: 50%;
}
.form-group-container .form-group-wrapper .form-group.border-group {
  margin: 0 0.5rem 1rem !important;
}
.form-group-container .form-group-wrapper:nth-last-child(odd) {
  flex: 0 1 50%;
}
.drag-drop-columns .common-ellipsis-dropdown .dropdown .dropdown-menu a.disabled {
  background-color: rgb(255, 255, 255);
  opacity: 0.4;
  pointer-events: none;
}
/* Drag and Drop Table Structure CSS starts here */
.drag-drop-columns.hidden-row .name-highlighted,
.drag-drop-columns.hidden-row .columns-data span {
  color: #acacad !important;
  cursor: initial;
}
.ioj-roadmap .ioj-right-btn-controls,
.summary-dashboard-btns.ioj-right-btn-controls {
  display: flex;
  display: flex;
  right: 8px;
  position: absolute;
  top: -64px;
}
.ioj-roadmap .ioj-right-btn-controls > div,
.summary-dashboard-btns.ioj-right-btn-controls > div {
  background: #fff;
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 35px;
  padding: 0 1rem;
  color: #a100ff;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 4px;
}
.ioj-roadmap .ioj-right-btn-controls > div:first-child {
  margin-left: 0;
}
.ioj-roadmap .ioj-right-btn-controls > div:last-child {
  margin-right: 0;
}
.ioj-roadmap .ioj-right-btn-controls > div:hover,
.summary-dashboard-btns.ioj-right-btn-controls > div:hover {
  color: #fff;
  background: #a100ff;
}

.alp-btn {
  background: #fff;
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 35px;
  padding: 0 1rem;
  color: black !important;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 4px;
}
.alp-btn:hover {
  color: #fff !important;
  background: #a100ff;
}

/** Gantt Chart CSS starts here **/
#table-gantt-chart {
  display: flex;
}
.ganttchart-wrapper {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 2px 0px 24px 0px rgb(33 43 54 / 10%), 1px 0px 0px 0px rgb(244 246 248);
  padding: 12px;
}
.ganttchart-heading,
.ganttchart-points-color {
  display: flex;
  align-items: center;
}
.ganttchart-points-color div > span {
  color: rgb(99, 115, 129);
  font-family: "Graphik";
  font-size: 11px;
  font-weight: normal;
  padding-left: 32px;
  display: inline-flex;
  position: relative;
}
.ganttchart-points-color div > span::before {
  position: absolute;
  content: "";
  top: -3px;
  bottom: 0;
  margin: auto;
  left: 14px;
  display: inline-flex;
  width: 12px;
  height: 12px;
}
.ganttchart-points-color .points-color {
  position: relative;
}
.ganttchart-points-color .points-color::before {
  /* position: absolute;
  content: "";
  height: 86%;
  left: 7px;
  top: 0;
  bottom: 0;
  width: 1px;
  margin: auto;
  background-color: rgb(223, 227, 232);
  display: inline-flex; */
}
.ganttchart-points-color div > span.actual-start-date::before {
  background: url("../Images/actual-start-icon-grey.svg") no-repeat center center;
}
.ganttchart-points-color div > span.revised-end-date::before {
  background: url("../Images/revised-end-icon-grey.svg") no-repeat center center;
}
.ganttchart-points-color div > span.actual-end-date::before {
  background: url("../Images/actual-end-icon-grey.svg") no-repeat center center;
}
.ganttchart-points-color div > span.red-point-color::before {
  background-color: rgb(210, 23, 22);
}
.ganttchart-points-color div > span.amber-point-color::before {
  background-color: rgb(255, 200, 44);
}
.ganttchart-points-color div > span.green-point-color::before {
  background-color: rgb(126, 195, 68);
}
.ganttchart-points-color div > span.grey-point-color::before {
  background-color: rgb(181, 192, 202);
}
#gantt-left-content {
  width: 39%;
  position: relative;
}
#gantt-left-content .metrics-row .row-list {
  display: flex;
}
#gantt-left-content .metrics-row .row-list:nth-child(odd),
#gantt-middle-content .metrics-row .row-list:nth-child(odd),
#gantt-right-content .metrics-row .row-list:nth-child(odd) {
  background-color: #fff;
}
#gantt-left-content .head-row {
  border-radius: 5px 0 0 0;
}
#gantt-right-content .head-row {
  border-radius: 0 5px 0 0;
}
#gantt-left-content .metrics-row .row-list:last-child {
  border-radius: 0 0 0 5px;
}
#gantt-right-content .metrics-row .row-list:last-child {
  border-radius: 0 0 5px 0;
}
#gantt-right-content {
  width: 11%;
  position: relative;
}
#gantt-middle-content {
  width: 50%;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  scroll-behavior: smooth;
}
#gantt-middle-wrapper {
  width: 1680px;
  position: relative;
}
.head-row,
.metrics-row {
  display: flex;
}
span#slideLeft {
  position: absolute;
  background: url("../Images/gantt-left-arrow.svg") no-repeat center center;
  width: 15px;
  height: 38px;
  display: inline-flex;
  right: -15px;
  top: 2px;
  z-index: 1;
  cursor: pointer;
  background-color: #f9fafb;
}
span#slideRight {
  position: absolute;
  background: url("../Images/gantt-right-arrow.svg") no-repeat center center;
  width: 15px;
  height: 38px;
  display: inline-flex;
  left: -15px;
  top: 2px;
  z-index: 1;
  cursor: pointer;
  background-color: #f9fafb;
}
.head-row > div,
.metrics-row .row-list,
#gantt-left-content .metrics-row .row-list > div {
  flex-grow: 1;
  flex-basis: 0;
  color: rgb(99, 115, 129);
  font-family: "graphik";
  font-size: 11px;
}
#gantt-middle-wrapper .head-row > div {
  flex-grow: unset;
  flex-basis: unset;
  width: 78px;
  letter-spacing: -0.24px;
}
#gantt-middle-wrapper .head-row {
  /* padding-left: 1rem; */
}
#gantt-left-content .head-row > div:first-child,
#gantt-left-content .metrics-row .row-list > div:first-child {
  flex-grow: 2;
  font-size: 12px;
  font-family: "graphik-medium";
  color: rgb(33, 43, 54);
}
.metrics-row {
  flex-direction: column;
  position: relative;
}
.metrics-row .row-list {
  border: 1px solid rgb(231, 234, 238);
}
.head-row {
  background: rgb(249, 250, 251);
  border: 1px solid rgb(231, 234, 238);
}
#gantt-left-content .metrics-row .row-list > div,
#gantt-left-content .head-row > div {
  border-right: 1.5px solid rgb(231, 234, 238);
}
#gantt-left-content .metrics-row .row-list > div:last-child,
#gantt-left-content .head-row > div:last-child {
  border-right: none;
}
.head-row > div {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  padding: 11px 10px;
}
#gantt-middle-wrapper .head-row > div {
  padding: 11px 0px;
}
#gantt-left-content .metrics-row .row-list > div,
#gantt-right-content .metrics-row .row-list > div {
  height: 40px;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
#gantt-middle-wrapper .metrics-row .row-list {
  line-height: 42px;
  height: 42px;
  /* padding-left: 1rem; */
  position: relative;
}
#gantt-middle-wrapper .metrics-row .row-list .row-list-content > div {
  border-radius: 7.5px;
  height: 16px;
  /* vertical-align: middle; */
  display: inline-flex;
}
#gantt-middle-wrapper .metrics-row .row-list .row-list-content .filled-bar {
  position: relative;
}
#gantt-middle-wrapper .metrics-row .row-list .row-list-content > div.amber-color {
  background: rgb(255, 200, 44);
}
#gantt-middle-wrapper .metrics-row .row-list .row-list-content > div.gray-color {
  background: rgb(181, 192, 202);
}
#gantt-middle-wrapper .metrics-row .row-list .row-list-content > div.green-color {
  background: rgb(126, 195, 68);
}
#gantt-middle-wrapper .metrics-row .row-list .row-list-content > div.red-color {
  background: rgb(210, 23, 22);
}
#gantt-middle-wrapper .metrics-row .benefits {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  /* margin-left: 0; */
  bottom: -15px;
  line-height: 14px;
  width: max-content;
  right: 0;
}
#gantt-middle-wrapper .metrics-row .benefits span {
  color: rgb(99, 115, 129);
  font-family: "graphik";
  font-size: 8px;
  font-weight: normal;
  letter-spacing: 0px;
}
#gantt-middle-wrapper .metrics-row .benefits span span {
  font-family: "graphik-medium";
  color: rgb(33, 43, 54);
}
#gantt-middle-wrapper .metrics-row .today-point {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  display: inline-flex;
  border-right: 1.5px solid #a100ff;
  border-right-style: dashed;
  z-index: 1;
}
.before-after-bordername {
  position: relative;
}
.before-after-bordername span {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 8px;
  font-weight: 500;
  height: 10px;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  position: absolute;
  top: -15px;
  left: -13.5px;
  right: 0;
}
.purpleborder-dropdown {
  display: flex;
}
.purpleborder-dropdown .dropdown-toggle,
.datepicker-purpledropdown {
  background: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1.5px solid rgb(161, 0, 255) !important;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding: 0.5rem 2rem 0.5rem 1rem;
  text-align: left;
  outline: none !important;
  box-shadow: none !important;
  position: relative;
  cursor: pointer;
}
.purpleborder-dropdown .dropdown-toggle:hover,
.purpleborder-dropdown .dropdown-toggle:active,
.purpleborder-dropdown .dropdown-toggle:focus,
.purpleborder-dropdown .show .dropdown-toggle {
  background: rgb(161, 0, 255) !important;
  color: #fff !important;
  border: 1.5px solid rgb(161, 0, 255);
}
.calenderChooseOption .datepicker-purpledropdown:hover {
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 20%) !important;
}
.purpleborder-dropdown .dropdown-toggle:after {
  border-bottom: 2px solid rgb(161, 0, 255);
  border-right: 2px solid rgb(161, 0, 255);
  position: absolute;
  right: 10px;
  top: 9px;
}
.purpleborder-dropdown .dropdown-toggle:hover:after,
.purpleborder-dropdown .show .dropdown-toggle:after,
.purpleborder-dropdown .dropdown-toggle:active:after,
.purpleborder-dropdown .dropdown-toggle:focus:after {
  border-bottom: 2px solid rgb(255, 255, 255);
  border-right: 2px solid rgb(255, 255, 255);
}
.purpleborder-dropdown .dropdown-menu {
  width: 100%;
  min-width: 110px;
}
.ganttchart-dropdowns .dropdown {
  margin-right: 1rem;
}
.ganttchart-dropdowns.purpleborder-dropdown .dropdown-toggle {
  min-width: 110px;
  max-width: 200px;
  overflow: hidden;
  padding: 8px 35px 8px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}
.ganttchart-dropdowns.purpleborder-dropdown .dropdown-toggle:after {
  top: 8px;
}
.datepicker-purpledropdown {
  padding-right: 2rem !important;
}
.datepicker-purpledropdown i {
  position: absolute;
  right: 1rem;
}
.before-after-bordername::before {
  content: "";
  display: inline-flex;
  width: 10px;
  height: 1px;
  top: -3px;
  left: -4.5px;
  right: 0;
  margin: auto;
  position: absolute;
  background: rgb(161, 0, 255);
}
.before-after-bordername::after {
  content: "";
  display: inline-flex;
  width: 10px;
  height: 1px;
  bottom: 0px;
  left: -4.5px;
  right: 0;
  margin: auto;
  position: absolute;
  background: rgb(161, 0, 255);
}
.ganttchart-calender .react-datepicker__navigation-icon {
  top: 4px;
}
.ganttchart-wrapper .purpleborder-dropdown .dropdown-menu {
  max-width: 320px;
  width: unset;
}
.ganttchart-wrapper .initiative-name {
  cursor: pointer;
}
.ganttchart-wrapper .initiative-name span {
  word-break: break-all;
}
.actual-start-point {
  position: absolute;
  background: url("../Images/actual-start-icon-dark.svg") no-repeat center center;
  display: inline-flex;
  width: 13px;
  height: 13px;
  top: -8.5px;
  bottom: 0;
  margin: auto;
  left: 2px;
  cursor: pointer;
  z-index: 1;
}
.revised-end-point {
  position: absolute;
  background: url("../Images/revised-end-icon-dark.svg") no-repeat center center;
  display: inline-flex;
  width: 13px;
  height: 13px;
  top: -8.5px;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  z-index: 1;
}
.actual-end-point {
  position: absolute;
  background: url("../Images/actual-end-icon-dark.svg") no-repeat center center;
  display: inline-flex;
  width: 13px;
  height: 13px;
  top: -8.5px;
  bottom: 0;
  /* right: 2px; */
  margin: auto;
  cursor: pointer;
  z-index: 1;
}
.actual-start-point:hover,
.actual-end-point:hover,
.revised-end-point:hover {
  background-color: #fff;
  border-radius: 50%;
}
.__react_component_tooltip.gantt-point-tooltip {
  /* line-height: 24px; */
  padding-top: 4px;
  max-width: 200px;
  line-height: 14px;
  height: auto !important;
}
.__react_component_tooltip.gantt-point-tooltip span span {
  line-height: 14px;
  display: flex;
  font-family: "Graphik-Medium";
}
.summary-dashboard-btns.ioj-right-btn-controls {
  top: unset;
}
.ganttchart-dropdowns .initiativeheading-dropdownlist {
  top: 36px;
  z-index: 111;
}
#gantt-left-content .head-row > div:last-child,
#gantt-left-content .metrics-row .row-list > div:last-child {
  flex-grow: 2;
}
.row-list.not-available-row {
  border: none !important;
}
.row-list.not-available-row .row-list-content {
  background: rgb(231, 234, 238) !important;
  padding-left: 15rem !important;
  margin-left: 0;
}
.row-list-content {
  /* margin-left: 1rem; */
  position: relative;
}
.calenderPopover.ganttchart-calender .popover-footer .modal-btn.temp .btn {
  font-family: Graphik-Medium;
  font-size: 16px;
}
.calenderPopover.ganttchart-calender {
  z-index: 111;
}
/* Gantt Chart CSS Ends here */
.journeyinitiative-tabs
  .drag-drop-table
  .drag-drop-body
  .drag-drop-columns:first-child
  .common-ellipsis-dropdown
  .dropdown
  .dropdown-menu.show,
.journeyinitiative-tabs
  .drag-drop-table
  .drag-drop-body
  .drag-drop-columns:last-child
  .common-ellipsis-dropdown
  .dropdown
  .dropdown-menu.show {
  transform: translate3d(-94px, -116px, 0px) !important;
}
.ioj-roadmap-container.journeyinitiative-tabs .nav-tabs {
  margin-bottom: 0.75rem;
}
.configurator-iojrating {
  position: absolute;
  top: 0;
}
.ioj-step-cards {
  display: flex;
  align-items: center;
}
.ioj-roadmap .ioj-right-btn-controls .summary-dashboard-btn,
.ioj-roadmap .ioj-right-btn-controls .gantt-chart-btn,
.ioj-roadmap .ioj-right-btn-controls .export-roadmap-btn {
  position: relative;
  width: 60px;
  border-radius: 1.125rem;
}
.ioj-roadmap .ioj-right-btn-controls > div .__react_component_tooltip {
  z-index: 1;
}
.initiative-formfields .react-datepicker__day {
  border: none;
  line-height: 1.7rem;
  height: 1.7rem;
  background: transparent;
}
.react-datepicker__day--keyboard-selected.react-datepicker__day--today,
.react-datepicker__day--selected.react-datepicker__day--today {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}
.initiative-formfields .react-datepicker__day--keyboard-selected:hover,
.initiative-formfields .react-datepicker__day--selected,
.initiative-formfields .react-datepicker__month-text--keyboard-selected:hover,
.initiative-formfields .react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #1d5d90 !important;
}

.react-datepicker-popper,
.react-datepicker__month {
  border: none;
}
.ioj-component .stage-heading {
  margin: 0;
}
.Adoption-FormSection{
  position: relative;
  margin-bottom: 16px;

}
.Adoption-FormSection .Adoption-FormLabel {
  position: absolute;
  left: 12px;
  top: 2px;
  font-family: 'graphik-medium';
}
.Adoption-FormSection .Adoption-FormControl{
  height: 50px;
  padding: 1.375rem .75rem .375rem;


}

.Adoption-Row{
  margin-right: -50px;
  margin-left: -12px;
}
/* .Adoption-Icon{
  border-radius: 50%;
	border: 0px solid #ccc !important;
	height: 25px;
	width: 25px;
	background: white !important;
	font-family: graphik-bold;
	font-size: 20px;
	margin-left: 12px;

  
} */


.Adoption-Icon{
  border-radius: 50%;
	border: 0px solid #ccc !important;
	height: 27px;
	width: 27px;
	background: white !important;
	font-family: graphik-bold;
	font-size: 20px;
	color: #777;
	margin-left: 12px;
}

.adoption-metrics-dateDropdown{
  justify-content: end !important;
}

.offeringlist-dropdownslist.adoption-metrics-dateDropdown  .dropdown-toggle {
  min-width: unset;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 28px;
}

.purpleborder-dropdown.adoption-metrics-dateDropdown .dropdown-toggle:after {
  right: 0.7rem;
}
.adoption-metrics-dateDropdown .form-check-label {
  bottom: unset;
  white-space: normal;
}

.purpleborder-dropdown.adoption-metrics-dateDropdown .dropdown-menu {
  min-width: 165px;
}

.offeringlist-dropdownslist.adoption-metrics-dateDropdown #nested-dropdown-container li .dropdown-item:hover {
  color: #fff !important;
  font-family: Graphik-Medium;
}

.adoption-metrics-dateDropdown .searchFilter input[type="search"] {
  width: 100% !important;
}
.adoption-metrics-dateDropdown .metric-dropdown .form-check-label {
  white-space: normal;
  margin: 0;
}
