.helpfeatures-section {
  padding: 1rem;
  padding-top: 0;
}
.helpfeatures-close .helpfeaturesclose-icon {
  text-align: right;
  /* margin-bottom: 1rem; */
}
.helpfeatures-close{
  display: flex;
  justify-content: flex-end;
}
.helpfeatures-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.helpfeatures-heading h5 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
}
.helpfeatures-heading .btn-doublegradient {
  background: linear-gradient(-225deg, rgb(161, 0, 255) 0%, rgb(117, 0, 192) 52%, rgb(70, 0, 115) 100%);
  border-radius: 20px;
  height: 40px;
  color: #fff;
  width: 152px;
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.helpfeatures-heading .btn-doublegradient:hover,
.helpfeatures-heading .btn-doublegradient:focus,
.helpfeatures-heading .btn-doublegradient:active,
.helpfeatures-heading .btn-doublegradient:focus:active {
  color: #fff;
  background: linear-gradient(-225deg, rgb(117, 0, 192) 0%, rgb(161, 0, 255) 100%);
  box-shadow: none;
}
.helpfeature-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.leftfeature-filters {
  display: flex;
  align-items: center;
}
.leftfeature-filters .viewby-filter {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 32px;
  width: 230px;
  padding: 0 1rem;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
}
.leftfeature-filters .viewby-filter.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.leftfeature-filters .viewby-filter:after {
  content: " ";
  position: absolute;
}
.leftfeature-filters .viewby-filter span,
.viewby-filtervalue {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.viewby-label img {
  margin-right: 5px;
}
.leftfeature-filters .viewby-filter .viewby-label {
  padding-right: 10px;
  border-right: 1px solid #dfe3e8;
  margin-right: 10px;
}
.viewby-filtervalue i {
  position: absolute;
  right: 10px;
  top: 8px;
}
.categoryfeature-filter {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  height: 32px;
  width: 122px;
  border: 1.5px solid rgb(161, 0, 255);
  display: flex;
  align-items: center;
  padding: 0 16px 0 10px;
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
}
.categoryfeature-filter img {
  margin-right: 5px;
}
.categoryfeature-filter:before {
  content: "";
  position: absolute;
  background: rgb(223, 227, 232);
  border-radius: 0.5px;
  height: 24px;
  left: -16px;
  width: 1px;
}
.categoryfeature-filter .category-dropdown {
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.categoryfeature-filter.disabled {
  opacity: 0.5;
  pointer-events: none;
}
/* .categoryfeature-filter.disabled span {
  color: rgb(99, 115, 129);
} */
.categoryfeature-filter .category-dropdown i {
  position: absolute;
  right: 8px;
  top: 9px;
}
.rightfeature-filters {
  display: flex;
  align-items: center;
}
.rightfeature-filters .searchFilter input[type="search"] {
  width: 200px !important;
  height: 32px;
}
.rightfeature-filters .searchFilter {
  margin-right: 1rem;
}
.rightfeature-filters .addcollection-dropdown {
  margin-left: 1rem;
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(161, 0, 255);
  height: 32px;
  width: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addcollection-dropdown .addcollection-dropdownbtn {
  display: block;
  line-height: 31px;
  position: relative;
}
.addcollection-dropdown-hide .addcollection-dropdownbtn {
  display: none;
}
.addcollection-dropdown:hover {
  background: rgb(161, 0, 255);
  color: #fff;
}
.addcollection-dropdown:hover svg g {
  fill: #ffffff;
}
.downloadfeature-block {
  margin-left: 16px;
}
.featurelist-btn span {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 32px;
  padding: 0 1rem;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.featurelist-btn span i {
  margin-right: 10px;
}
.featurelist-btn svg {
  margin-right: 10px;
}
.download-dropdownbtn {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1.5px solid rgb(161, 0, 255);
  height: 32px;
  padding: 0px 10px;
  color: rgb(161, 0, 255);
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: relative;
  outline: none !important;
}
.download-dropdownbtn svg {
  margin-right: 5px;
}
.download-dropdownbtn:hover svg g {
  fill: #ffffff;
}
.download-dropdownbtn .download-chevron {
  position: absolute;
  right: 10px;
}
.download-dropdownbtn:hover {
  background: rgb(179, 51, 255);
  border-color: rgb(179, 51, 255);
  color: #fff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
}
.rightfeature-filters input::-webkit-input-placeholder {
  /* Edge */
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.rightfeature-filters input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.rightfeature-filters input::placeholder {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.welcome-casestudiesmsg {
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.welcome-casestudiesmsg span {
  margin-bottom: 2rem;
}
.welcome-casestudiesmsg h6 {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 24px;
  font-weight: 500;
  height: 16px;
  letter-spacing: -0.43px;
  margin-bottom: 1rem;
}
.welcome-casestudiesmsg p {
  color: rgb(82, 101, 127);
  font-family: graphik;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.64px;
  max-width: 580px;
  text-align: center;
  line-height: 18px;
  margin-top: 5px;
}
.welcome-casestudiesmsg span i {
  color: #00baff;
  font-size: 45px;
  margin-bottom: 1rem;
}
.features-cardlist,
.features-listgrid {
  margin-top: 20px;
}
.features-cardlist .featuresview-card {
  height: 220px;
  width: 280px;
  position: relative;
  border-radius: 8px;
  /* border: 1px solid rgb(223, 227, 232); */
  margin-bottom: 1.5rem;
}
.featureview-select {
  display: none;
}
.featureview-select.active{
  display: block;
}
.featureview-select .form-check {
  position: absolute;
  top: 0;
  margin-top: 10px;
  margin-left: 10px;
}
.featureview-select .form-check .checkmark {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(196, 205, 213);
  height: 20px;
  width: 20px;
  border-radius: 100%;
}
.featureview-select input[type="checkbox"].form-check-input:checked ~ .checkmark {
  background-color: #7ec344;
  border-color: #7ec344;
}
.featuresview-card a {
  height: 100%;
  display: block;
  position: relative;
}
.featureview-select .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: 1px solid #ffffff;
  border-width: 0 3px 3px 0;
}
.features-cardlist .featuresview-card:hover .featureview-select {
  display: block;
}
.features-cardlist .featuresview-card:hover::after,
.features-listgrid .listgrids:hover::after,
.features-listgrid .listgrids.newly-updated::after,
.features-listgrid .listgrids.newly-updated:hover::after,
.features-cardlist .featuresview-card.active::after,
.features-listgrid .listgrids.active::after,
.features-cardlist .featuresview-card.newly-updated::after,
.features-cardlist .featuresview-card.newly-updated:hover::after {
  display: block;
  content: "";
  background: linear-gradient(#a100ff, #00baff);
  position: absolute;
  left: -2px;
  top: -2px;
  bottom: -2px;
  right: -2px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  z-index: -1;
}
.features-cardlist .featuresview-card.newly-updated::after,
.features-listgrid .listgrids.newly-updated::after{
  background: rgb(0, 142, 255);
}
.features-cardlist .featuresview-card.active .form-check-input:checked ~ .checkmark:after
.features-listgrid .listgrids.active .form-check-input:checked ~ .checkmark:after {
    display: block;
}


.featurecard-status {
  position: absolute;
  top: 10px;
  right: 0px;
}
.featurecard-status>span {
  border-right: 26px solid rgb(213, 0, 47);
  border-top: 12px solid rgb(213, 0, 47);
  border-left: 6px solid transparent;
  border-bottom: 12px solid rgb(213, 0, 47);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  color: rgb(255, 255, 255);
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.43px;
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  padding-left: 8px;
}
.featurecard-status span.featurestatus-new span{
position: absolute;
width: 34px;
left: 0px;
text-align: center;
background-color: #d5002f;
}
.featurecard-status span.featurestatus-upcoming span{
  position: absolute;
  width: 71px;
  left: 0px;
  text-align: center;
  background-color: #528626;
  }
.featurecard-status span.featurestatus-new {
  width: 40px;
  height: 24px;
  border-right: 26px solid rgb(213, 0, 47);
  border-top: 12px solid rgb(213, 0, 47);
  border-left: 6px solid transparent;
  border-bottom: 12px solid rgb(213, 0, 47);
  position: relative;
}
.featurecard-status span.featurestatus-upcoming {
  width: 77px;
  height: 24px;
  border-right: 26px solid #528626;
  border-top: 12px solid #528626;
  border-left: 6px solid transparent;
  border-bottom: 12px solid #528626;
  padding-left: 10px;
  position: relative;
}
.features-cardlist .featuresview-card img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.featuresview-cardcharacter {
  position: absolute;
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
  display: flex;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  justify-content: space-between;
  height: 52px;
  width: 100%;
  bottom: 0;
  color: #fff;
  padding: 0.75rem 1rem;
}
.featuresview-cardcharacter p {
  color: rgb(255, 255, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 16px;
  margin-bottom: 0px;
}
.featuresview-cardcharacter p span{
  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.featuresview-cardcharacter .character-limittext span {
  font-family: Graphik-Medium;
  font-size: 8px;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 8px;
}
.featuresview-morelink .morelink-icon {
  color: #fff;
  width: 44px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.featuresview-morelink .morelink-icon:hover {
  background: rgba(255, 255, 255, 0.24);
  height: 40px;
}
.featuresview-morelink .dropdown-menu,
.download-dropdown .dropdown-menu {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
  width: 198px;
  right: -45px !important;
  left: auto !important;
}.featuresview-morelink .dropdown-menu,
.addfeature-formfields .featuresearch-list {
  z-index: 11;
}
.download-dropdown .dropdown-menu {
  width: 210px;
  right: -45px !important;
}
.addcollection-dropdown .dropdown-menu {
  width: 210px;
  right: auto !important;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.15);
}
.featuresview-morelink .dropdown-menu .dropdown-item,
.download-dropdown .dropdown-menu .dropdown-item,
.addcollection-dropdown .dropdown-menu .dropdown-item {
  color: #637381 !important;
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.featuresview-morelink .dropdown-menu .dropdown-item svg,
.download-dropdown .dropdown-menu .dropdown-item svg,
.addcollection-dropdown .dropdown-menu .dropdown-item svg {
  margin-right: 10px;
}
.addcollection-dropdown .dropdown-menu .dropdown-item i,
.featuresview-morelink .dropdown-menu .dropdown-item i {
  margin-right: 10px;
}
.featuresview-morelink .dropdown-menu .dropdown-item:hover,
.download-dropdown .dropdown-menu .dropdown-item:hover,
.addcollection-dropdown .dropdown-menu .dropdown-item:hover {
  color: #212b36 !important;
  font-family: Graphik-Medium;
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 4px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
}
.featuresview-morelink .dropdown-menu .dropdown-item.disabled-item,
.download-dropdown .dropdown-menu .dropdown-item.disabled-item,
.addcollection-dropdown .dropdown-menu .dropdown-item.disabled-item {
  pointer-events: none;
  opacity: 0.4;
}
.helpfeature-modal .modal-dialog {
  max-width: 1096px;
}
.helpfeaturemodal-title.modal-title {
  font-family: Graphik-Semibold;
  font-size: 24px;
  font-weight: 600;
}
.helpfeaturemodal-content p {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 20px;
}

.csupdated-info {
  border-bottom: 1px solid #c4cdd5;
  margin-bottom: 10px;
}
.csupdated-info p {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
}
.uploaded-copyrights p {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  text-align: right;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
}
.helpfeatures-sortby {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.helpfeatures-sortby .sortby-text {
  color: rgb(33, 43, 54);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.helpfeatures-sortby span {
  margin-left: 16px;
}
.helpfeatures-sortby .sortby-listicon i {
  color: #c4cdd5;
  cursor: pointer;
  font-size: 24px;
}
.helpfeatures-sortby .sortby-listicon:hover i {
  color: #018eff;
}
.helpfeatures-sortby .sortby-listicon.active i {
  color: #018eff;
}
.helpfeaturemodal-tabs .nav-tabs .nav-link {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  position: relative;
  padding: 1rem 0;
  margin-right: 2rem;
  border: none;
}
.helpfeaturemodal-tabs .nav-tabs .nav-link.active,
.helpfeaturemodal-tabs .nav-tabs .nav-link:hover {
  color: #212b36 !important;
  border: none;
}
.helpfeaturemodal-tabs {
  position: relative;
}
.helpfeaturemodal-tabs .featuresview-morelink {
  position: absolute;
  right: 0;
  top: 5px;
}
.helpfeaturemodal-tabs .nav-tabs .nav-link.active:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgb(1, 142, 255);
  border-radius: 4px;
  height: 8px;
}
.helpfeature-modalimage {
  margin: 1rem 0 2rem;
}
.helpfeaturemodal-tabs .featuresview-morelink .dropdown-menu {
  right: -35px !important;
}
.helpfeatureNotification-modal .modal-header .close {
  top: 32px;
}
.features-cardlist .featuresview-card:hover {
  border: none;
}
.helpfeaturenotification-body {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.helpfeaturenotification-body .helpfeaturenotification-icon {
  margin-right: 1rem;
  width: 10%;
}
.helpfeaturenotification-body .helpfeaturenotification-icon i {
  color: #ff9900;
  font-size: 40px;
}
.helpfeaturenotification-data{
  width: 90%;
}
.helpfeaturenotification-data p {
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.35px;
  margin-bottom: 0;
  line-height: 20px;
}
.helpfeature-accordion .card {
  border: none;
  background-color: transparent;
}
.helpfeature-accordion .card-header {
  border: none;
  background: transparent;
}
.helpfeature-accordion .features-cardlist {
  margin-top: 0;
}
.helpfeature-accordion .card-link {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.29px;
  padding: 0 10px;
  position: relative;
}
.helpfeature-accordion .card-body {
  padding: 0.8rem 0;
}
.helpfeature-accordion .card-link::before,
.helpfeature-accordion .card-link.collapsed::before {
  content: "\F0DA";
  position: absolute;
  top: 45%;
  font-family: "Font Awesome\ 5 Pro";
  font-weight: 600;
  color:  rgb(33, 43, 54);
  line-height: 2px;
  left: -15px;
  transform: rotate(0deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
  cursor: pointer;
  display: block;
}
.helpfeature-accordion .card-link::before {
  transform: rotate(90deg);
  -ms-transition: all 0.2s ease 0.2s;
  transition: all 0.2s ease 0.2s;
}
.viewby-filtervalue {
  width: 105px;
}

.viewby-filtervalue .viewby-dropdownlist {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  height: 124px;
  width: 208px;
  position: absolute;
  top: 31px;
  left: 0;
  z-index: 2;
  padding: 6px;
}
.featuresearch-list {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: absolute;
  top: 61px;
  left: 0;
  z-index: 11111;
}
.featuresearch-list .categoryfilter-footer,
.categoryfilter-dropdownlist .categoryfilter-footer{
  position: relative;
}
.viewby-dropdownlist ul {
  margin: 0;
}
.viewby-dropdownlist ul li {
  color: rgb(99, 115, 129);
  font-size: 14px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  padding: 8px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.viewby-dropdownlist ul li:hover {
  background: rgba(255, 255, 255, 0.2) linear-gradient(-225deg, rgba(161, 0, 255, 0.1) 0%, rgba(0, 186, 255, 0.1) 100%);
  border-radius: 4px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.viewby-dropdown-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 14%;
  left: 0;
  bottom: 0;
  right: 0;
}
.categoryfilter-dropdownlist {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  width: 269px;
  position: absolute;
  top: 31px;
  left: 0;
  z-index: 11;
  padding-top: 12px;
}
.categoryfilter-dropdownlist .searchFilter {
  padding: 0 12px;
}
.categoryfilter-dropdownlist .searchFilter input[type="search"] {
  height: 32px;
  width: 245px !important;
}
.categoryfilter-dropdownlist input::-webkit-input-placeholder {
  /* Edge */
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.categoryfilter-dropdownlist input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}

.categoryfilter-dropdownlist input::placeholder {
  color: rgb(145, 158, 171);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
}
.categoryfilter-dropdownlist ul {
  margin: 1rem 0;
  height: 200px;
  overflow-y: auto;
}
.featuresearch-list ul {
  margin: 1rem 0;
  max-height: 200px;
  overflow-y: auto;
}
.categoryfilter-dropdownlist ul li,
.featuresearch-list ul li {
  padding: 8px 12px;
}
.categoryfilter-dropdownlist ul li:hover,
.featuresearch-list ul li:hover {
  background: #f4f6f8;
}
.categoryfilter-dropdownlist ul .form-check-label,
.featuresearch-list ul .form-check-label {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin-left: 8px;
  bottom: 0;
}
.categoryfilter-dropdownlist .form-check-label.label-checked,
.featuresearch-list .form-check-label.label-checked {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-weight: 500;
}
.categoryfilter-dropdownlist .form-check .checkmark,
.selectfeature-item .form-check .checkmark,
.featuresearch-list .form-check .checkmark {
  width: 20px;
  height: 20px;
}
.categoryfilter-dropdownlist .checkmark:after,
.selectfeature-item .checkmark:after,
.featuresearch-list .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
}

.features-listgrid .listgrids {
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  position: relative;
  margin-bottom: 16px;
  padding: 0 10px;
}
.features-listgrid .listgrids .left-items {
  display: flex;
  align-items: center;
  width: 75%;
}
.features-listgrid .listgrids .left-items p {
  margin: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.features-listgrid .radio-btn {
  padding-right: 10px;
  width: 30px;
}
.features-listgrid .radio-btn,
.features-listgrid .listgrids .right-items {
  display: flex;
  align-items: center;
}
.features-listgrid .radio-btn .form-check .checkmark {
  bottom: 0;
  right: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  background: rgb(244, 251, 255);
  border: 1px solid rgb(151, 151, 151);
  border-radius: 50%;
}
.features-listgrid .radio-btn input[type="checkbox"].form-check-input:checked ~ .checkmark {
  background-color: #7ec344;
  border-color: #7ec344;
}
.features-listgrid .radio-btn .checkmark:after {
  left: 6px;
  top: 2px;
}
.features-listgrid .listgrids .left-items .featureName {
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Semibold;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 20px;
  padding: 0px 14px 0px 30px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 30%;
  min-width: 270px;
  cursor: pointer;
}
.features-listgrid .listgrids .left-items .featureName::before {
  content: "";
  position: absolute;
  display: inline-flex;
  width: 24px;
  height: 20px;
  background-image: url("../../Images/workflowStatus.svg");
  background-repeat: no-repeat;
  left: 0px;
}
.features-listgrid .listgrids .left-items p {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  padding-left: 14px;
  position: relative;
  width: 70%;
}
.features-listgrid .listgrids .left-items p::before {
  position: absolute;
  content: "";
  display: inline-flex;
  width: 0.5px;
  height: 24px;
  background: rgb(223, 227, 232);
  border-radius: 0.5px;
  top: -4px;
  left: 0;
  bottom: 0;
  right: 0;
}
.features-listgrid .listgrids .right-items .new-status {
  color: rgb(170, 0, 37);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  width: 55px;
  height: 32px;
  background: rgb(247, 204, 213);
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.features-listgrid .listgrids .right-items .upcoming-status {
  color: rgb(82, 134, 38);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  width: 85px;
  height: 32px;
  background: rgb(229, 243, 218);
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.features-listgrid .listgrids .right-items img {
  width: 16px;
  cursor: pointer;
}
.features-listgrid .listgrids .right-items > span {
  margin-right: 6px;
}
.features-listgrid .featuresview-morelink .morelink-icon:hover {
  height: 40px;
  background: linear-gradient(-225deg, rgba(161, 0, 255, 0.2) 0%, rgba(0, 186, 255, 0.2) 100%);
}
.features-listgrid .featuresview-morelink .dropdown-menu {
  top: 4px !important;
  left: 12px !important;
  right: auto !important;
}
.selectfeature-item {
  display: flex;
  align-items: center;
}
.selectfeature-item .form-check-label {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  position: relative;
  top: 4px;
  margin-left: 10px;
}
.selectfeature-item .form-check-label.label-checked {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
}
.selectfeature-item .selectedfeature-count {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
  position: relative;
  top: 3px;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgb(99, 115, 129);
}
.addhelpfeature-btns .btn,
.addfeature-formbtns .btn {
  border-radius: 16px;
  font-family: Graphik-Medium;
  font-size: 12px;
  font-weight: 500;
  width: 70px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.addfeature-formbtns .btn {
  width: 95px;
  height: 40px;
}
.addfeature-form {
  display: flex;
}
.upload-featurewrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.upload-featurewrapper .btn {
  cursor: pointer;
  display: block;
}
.addfeature-imgblock {
  margin-right: 2rem;
  max-width: 160px;
}
.addfeature-formfields h3 {
  color: rgb(33, 43, 54);
  font-family: Graphik-Semibold;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.35px;
  margin-bottom: 24px;
}
.upload-featurewrapper img {
  margin-bottom: 1rem;
  border-radius: 100%;
}
.upload-featurewrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 134px;
  height: 25px;
  z-index: -1;
}
.addfeature-formfields {
  width: 100%;
  max-width: 698px;
}
.addfeature-formfields .formfield-info {
  margin-bottom: 2rem;
}
.addfeature-formfields .border-group .form-control {
  background: transparent;
}
.formfield-info .form-group.border-group {
  position: relative;
}
.featuresearch-dropdownname:after {
  content: "\F078";
  font-family: "Font Awesome\ 5 Pro";
  font-weight: 700;
  position: absolute;
  right: 8px;
  top: 23px;
}
.formfield-info .fileupload-linkicon:after {
  content: "\f0c1";
  font-family: "Font Awesome\ 5 Pro";
  font-weight: 700;
  position: absolute;
  right: 8px;
  top: 23px;
  color: #919eab;
}
.replace-block span {
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.32px;
}
.replace-block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.replace-block img {
  margin-right: 10px;
  border-radius: 0;
  margin-bottom: 0;
}
.categoryfeature-filter .category-dropdown span
{
  display: inline-flex;
  background-color: rgb(161, 0, 255);
  border-radius: 50%;
  height: 22px;
  width: 22px;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Graphik-Medium;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.23px;
  margin-right: 4px;
}

.noLogoImage{
  background-color: #ffffff;
}
.maximize-btn{
  display: flex;
  justify-content: flex-end;
}
.helpfeature-accordion .features-listgrid {
  margin-top: 0px; 
}
.featuresearch-dropdownname{
  cursor: pointer;
}
.addfeature-imgblock p{
  margin-top: 6px;
  text-align: center;
}
.helpfeature-modalimage .fullScreen{
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  margin: 0 auto;
}
.helpfeature-modalimage .close-excel {
  background-color: rgba(169,169,169,0.7);
  top: 0;
  right: 0;
  position: fixed;
  z-index: 9999;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  text-align: center;
}
.replace-block .tbldel{
  color: #d5002f;
  margin: 11px;
}

.addfeature-formfields .border-group.fileupload-linkicon .form-control{
  width: calc(100% - 20px);
}
.replace-block-btn{
  cursor: pointer;
}

.featureName-description{
  width: 100%;
  display: flex;
  cursor: pointer;
}
.featuresview-morelink .dropdown-menu {
  z-index: 99;
}
.helpfeatures-section .featuresearch-list { 
  z-index: 11;
}