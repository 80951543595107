.valueRealization-container .btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;
}
.valueRealization-container .btn-container button {
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 12px;
  outline: none;
  box-shadow: none;
}
.valueRealization-container .btn-container .btn:focus,
.showCreateNewView-container .flowButton:focus,
.valueRealization-Confirmation-modal .modal-footer .confirm-btn:focus,
.valueRealization-Confirmation-modal-fd .modal-footer .confirm-btn:focus {
  outline: 0;
  box-shadow: none;
}
.valueRealization-remove-modal .modal-footer .confirm-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(255, 0, 0, 0.25);
}
.valueRealization-container .btn-container .createView {
  /* width: 127px;
  height: 32px; */
  padding: 0.531rem 0.915rem;
  color: rgb(1, 142, 255);
  background: rgb(255, 255, 255);
  border: 1.5px solid rgb(1, 142, 255);
  border-radius: 16px;
  outline:none !important;
  box-shadow: none !important;
}
.valueRealization-container .btn-container .savedView {
  color: rgb(255, 255, 255);
  padding: 0.562rem 0.95rem;
  background: rgb(1, 142, 255);
  border-radius: 16px;
  margin-left: 16px;
  font-size: 12px;
  line-height: 12px;
}
.valueRealization-container .novalue-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  margin-bottom: 30px;
}
.valueRealization-container .novalue-component img {
  margin-bottom: 12px;
}
.valueRealization-container .novalue-component h6,
.valueRealization-container .novalue-component-fd h6  {
  color: rgb(82, 101, 127);
  font-size: 24px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.86px;
  margin-bottom: 12px;
}
.valueRealization-container .novalue-component .novalue-action {
  color: rgb(82, 101, 127);
  font-size: 18px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.64px;
  margin-bottom: 12px;
}
.valueRealization-container .novalue-component .novalue-desc {
  color: rgb(82, 101, 127);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.57px;
  line-height: 18px;
  /* width: 514px; */
  width: 32.125rem;
}
.valueRealization-Notification-modal .notification-body,
.valueRealization-remove-modal .notification-body {
  display: flex;
  align-items: center;
}
.valueRealization-Notification-modal .notification-body .fa-exclamation-triangle {
  font-size: 42px;
  color: #ffc82c;
  font-weight: 300;
  margin-right: 40px;
}
.valueRealization-remove-modal .notification-body .fa-exclamation-triangle {
  font-size: 42px;
  color:#d5002f;
  font-weight: 300;
  margin-right: 40px;
}
.valueRealization-Notification-modal .notification-body p,
.valueRealization-remove-modal .notification-body p {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 28px;
  margin-bottom: 0px;
}
.valueRealization-Notification-modal .modal-title,
.valueRealization-remove-modal .modal-title  {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
}
.valueRealization-Notification-modal .modal-header .close,
.valueRealization-remove-modal .modal-header .close {
  top: 24px;
  right: 26px;
}
.valueRealization-Notification-modal .modal-body,
.valueRealization-remove-modal .modal-body {
  padding: 32px 32px 50px;
}
.valueRealization-Notification-modal .modal-footer,
.valueRealization-remove-modal .modal-footer {
  display: flex;
}
.valueRealization-Notification-modal .modal-footer button,
.valueRealization-remove-modal .modal-footer button {
  padding: 16px 55px;
  background: rgb(1, 142, 255);
  border-radius: 24px;
  margin: 0;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  line-height: 14px;
}
.showCreateNewView-container {
  padding: 8px 40px;
}
.showCreateNewView-container .rwt__tablist {
  margin-bottom: 8px;
  box-shadow: 7px 2px 16px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: transparent;
}
.showCreateNewView-container .cvt-tab {
  background: #fff;
  color: #637381;
  font-size: 16px;
  font-family: Graphik;
  font-weight: 400;
  letter-spacing: -0.35px;
  padding: 20px 40px 20px 40px;
  margin-right: 0 !important;
  min-width: 33.7%;
  align-items: center;
  position: relative;
}
.showCreateNewView-container .cvt-tab.active,
.showCreateNewView-container .cvt-tab:active,
.showCreateNewView-container .cvt-tab:hover,
.showCreateNewView-container .rwt__tab[aria-selected="true"] {
  color: #018fff;
  background-color: #fff !important;
  font-weight: 900;
}
.showCreateNewView-container .cvt-tab:after {
  background: none !important;
}
.showCreateNewView-container .cvt-tab:last-child:after {
  background: #ffffff !important;
  right: -26px !important;
  height: 47px;
  width: 47px !important;
  top: 13% !important;
}
.showCreateNewView-container .cvtBar:after {
  top: 10% !important;
  content: "";
  position: absolute;
  right: 0 !important;
  left: auto !important;
  width: 53px !important;
  height: 53px;
  background: #999;
  -webkit-transform: rotate(136deg);
  transform: rotate(136deg);
  box-shadow: -5px -4px 6px -4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -5px -4px 6px -4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -5px -4px 6px -4px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.showCreateNewView-container .visited {
  color: rgb(126, 195, 68);
  font-weight: 900;
}

.cvt-offering-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px 34px;
}
.cvt-rightMargin {
  padding: 16px 16px !important;
}

.cvt-analysisFull {
  padding: 19px 12px;
  display: flex;
  height: 100%;
  margin: 0 !important;
  border: 2px solid #fff;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}
.cvt-analysisFull-header {
  display: flex;
}
.cvt-analysisFull .cvt-analysisTitle {
  margin: 1px 10px;
}
.cvt-analysisTitle h4 {
  color: #212b36;
  font-family: graphik-medium;
  font-size: 14px;
  letter-spacing: -0.35px;
  line-height: 18px;
  margin-bottom: 5px;
}
.cvt-analysisTitle p {
  color: #637381;
  font-size: 12px;
  letter-spacing: -0.25px;
  line-height: 12px;
  margin-bottom: 0;
}
.cvt-analysisFull .cvt-subOffering-list {
  margin: 26px 0 26px 21px;
}
.cvt-offering-list .cvt-subOffering-list .cvt-check-group,
.deliveryLocation-container .cvt-check-group,
.year-list .cvt-check-group {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.cvt-analysisFull .cvt-subOffering-list input[type="checkbox"],
.deliveryLocation-container input[type="checkbox"],
.year-list input[type="checkbox"] {
  margin-right: 8px;
  height: 20px;
  width: 20px;
}
.cvt-check-group label.form-check {
  padding-left: 1.7rem;
}
.cvt-check-group .form-check .checkmark {
  height: 20px;
  width: 20px;
}
.cvt-check-group .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;  
}
.cvt-analysisFull .cvt-subOffering-list label,
.deliveryLocation-container label,
.year-list label {
  color: #222020;
  font-size: 12px !important;
  font-family: Graphik;
  font-weight: 400;
  letter-spacing: -0.25px;
  margin-bottom: 0;
}
.cvt-check-group.selected label {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
}
.showCreateNewView-container .flowBtn {
  display: flex;
  align-items: center;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(1, 142, 255);
  border-radius: 20px;
  color: rgb(1, 142, 255);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.25px;
  margin-right: 1.2rem;
  padding: 0.475rem 1.45rem;
  outline: none !important;
}
.showCreateNewView-container .nextBtn {
  background: rgb(1, 142, 255);
  color: rgb(255, 255, 255);
  margin-right: 0;
}
.showCreateNewView-container .btn-space {
  display: flex;
  justify-content: space-between;
  padding: 40px 0px;
}

.deliveryLocation-container .region-title label {
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
}
.deliveryLocation-container .country-list {
  padding-left: 25px;
}
.deliveryLocation-container .region-container {
  display: flex;
  align-items: flex-start;
  /* justify-content: center; */
  flex-wrap: wrap;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 24px 0px;
  padding: 32px;
}

.deliveryLocation-container .region-container .col-lg-4 {
  margin-bottom: 1.5rem;
}

.fiscalYear-container .yearlist-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 45px 150px;
  margin: 24px 0px;
}
.fiscalYear-container {
}
.valueRealization-Confirmation-modal .modal-title,
.valueRealization-Confirmation-modal-fd .modal-title  {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
}
.valueRealization-Confirmation-modal .modal-header,
.valueRealization-Confirmation-modal-fd .modal-header {
  padding: 32px 32px 12px;
}
.valueRealization-Confirmation-modal .modal-header .close,
.valueRealization-Confirmation-modal-fd .modal-header .close{
  top: 34px;
  right: 32px;
}
.valueRealization-Confirmation-modal .modal-body,
.valueRealization-Confirmation-modal-fd .modal-body {
  padding-bottom: 60px;
}
.valueRealization-Confirmation-modal .alert-msg,
.valueRealization-Confirmation-modal-fd .alert-msg {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.valueRealization-Confirmation-modal .alert-msg .fa-exclamation-triangle,
.valueRealization-Confirmation-modal-fd .alert-msg .fa-exclamation-triangle {
  font-size: 25px;
  color: #ffc82c;
  font-weight: 300;
  margin-right: 10px;
}
.valueRealization-Confirmation-modal .alert-msg p,
.valueRealization-Confirmation-modal-fd .alert-msg p{
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
  margin-bottom: 0px;
}
.Confirmation-body .form-group,
.Confirmation-body-fd .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.Confirmation-body-fd .form-group {	
  display: block;	
  align-items: center;	
  margin-bottom: 12px;	
}	
.valueRealization-modalHeader-fd{	
  display: block;	
  text-align: center;	
}
.Confirmation-body .form-group label {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.21px;
  margin-bottom: 0;
  width: 18%;
  margin-right: 10px;
  line-height: 18px;
}
.Confirmation-body-fd .form-group label{
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.21px;
  margin-bottom: 0;
  width: 100%;
  margin-right: 10px;
  line-height: 18px;
}
.Confirmation-body .form-group .input-text-value input[type="text"]{
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  width: 19.68rem;
  border: none;
}
.Confirmation-body-fd .form-group .input-text-value input[type="text"] {
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  color: rgb(33, 43, 54);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 16px;
  width: 100%;
  border: none;
}
.Confirmation-body .form-group .text-value,
.Confirmation-body-fd .form-group .text-value {
  color: rgb(83, 83, 83);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.29px;
}
.guidline-msg {
  line-height: 16px;
  color: rgb(83, 83, 83);
  font-size: 10px;
  font-family: Graphik-Light;
  font-weight: 300;
  letter-spacing: -0.18px;
  padding-left: 16px;
  margin-top: 2px;
}
.valueRealization-Confirmation-modal .modal-footer,
.valueRealization-Confirmation-modal-fd .modal-footer {
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.valueRealization-Confirmation-modal .modal-footer .cancel-btn,
.valueRealization-remove-modal .modal-footer .cancel-btn,
.valueRealization-Confirmation-modal-fd .modal-footer .cancel-btn{
  background: rgb(255, 255, 255);
  border-radius: 24px;
  border: 1.5px solid rgb(99, 115, 129);
  color: rgb(99, 115, 129);
  padding: 14px 33px;
}
.valueRealization-Confirmation-modal .modal-footer button,
.valueRealization-Confirmation-modal-fd .modal-footer button {
  font-size: 16px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.35px;
  line-height: 16px;
  margin: 0;
  outline: none;
}
.valueRealization-Confirmation-modal .modal-footer .confirm-btn {
  background: rgb(1, 142, 255);
  border-radius: 24px;
  color: rgb(255, 255, 255);
  margin-left: 15px;
  padding: 16px 30px;
  border: none;
}
.valueRealization-Confirmation-modal-fd .modal-footer .confirm-btn {	
  background: rgb(1, 142, 255);	
  border-radius: 24px;	
  color: rgb(255, 255, 255);	
  margin-left: 15px;	
  padding: 16px 42px;	
  border: none;	
}
.valueRealization-remove-modal .modal-footer .confirm-btn {
  background: rgb(213, 0, 47);
  border-radius: 24px;
  color: rgb(255, 255, 255);
  margin-left: 15px;
  padding: 16px 30px;
  border: none;
}
.valueRealization-Confirmation-modal .modal-footer .cancel-btn:focus,
.valueRealization-remove-modal .modal-footer .cancel-btn:focus,
.valueRealization-Confirmation-modal-fd .modal-footer .cancel-btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(99, 115, 129, 0.25);
}
.savedviews-container {
  padding: 0px 20px;
}
.savedViews-header {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 2px 8px 12px 8px;
}
.view-card-wrap {
  /* margin: 8px; */
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 0px 5px;
}
.view-title {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.29px;
  line-height: 16px;
  margin-bottom: 8px;
}

/* .view-card {
  border-bottom: 1px solid #dfe3e8;
  padding: 16px 15px 0px;
} */
.view-card .form-group {
  margin-bottom: 8px;
  position: relative;
}
.view-card .field-name {
  color: rgb(99, 115, 129);
  font-size: 12px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.25px;
  line-height: 14px;
 
}
.view-card .field-value {
  color: #637381;
  font-size: 12px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 14px;
  display: block;
  white-space: nowrap;
  /* overflow: hidden;
  text-overflow: ellipsis;
  width: 85%; */
}
.fieldvalue-count{
background: linear-gradient(-225deg, rgb(249, 250, 251) 0%, rgb(196, 205, 213) 100%);
height: 20px;
width: 20px;
position: absolute;
right: 0px;
bottom: -2px;
border-radius: 50%;
}
.fieldvalue-count span{
  display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: rgb(99, 115, 129);
font-family: Graphik-Medium;
font-size: 10px;
font-weight: 500;
letter-spacing: -0.04px;
}
.fieldvalue-tooltip{
  width: 237px;
  color: rgb(255, 255, 255);
font-family: Graphik-Medium;
font-size: 11px !important;
font-weight: 500;
line-height: 12px;
padding: 12px !important;
}
.favourite_tooltip {
  position: relative;
  display: inline-block;
  padding: 0px 10px;
  cursor: pointer;
}
.favourite_tooltiptext {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  /* width: 120px; */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 10px 13px;
  color: rgb(255, 255, 255);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 11px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -53px;
}
.favourite_tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.favourite_tooltip:hover .favourite_tooltiptext {
  visibility: visible;
}
.view-card-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem .1rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}
.view-card-footer .delete-btn {
  padding: 10px 11px;
}
.view-card-footer .delete-btn span {
  cursor: pointer;
}
.view-card-footer .fa-star.inactive {
  font-weight: 500;
}
.view-card-footer .fa-star.active {
  font-weight: 900;
  color: #ffc82c;
}
.savedviews-container .row {
  margin: 0px 0px 14px;
}
.view-card-size {
  margin: 8px;
  width: 30.55%;
padding: 1rem;
width: 30.55%;
background: rgb(255, 255, 255);
border-radius: 8px;
box-shadow: 0px 2px 8px 0px rgba(33, 43, 54, 0.1);
height: 198px;
position: relative;
}
/* 10/12/2020 */
.saved-view-list {
  width: 288px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 340px;
  overflow-y: auto;
  padding: 15px 25px;
  position: absolute;
  top: 35px;
  right: 20px;
  z-index: 3;
}
.saved-view-list-fd {	
  width: 288px;	
  background: rgb(255, 255, 255);	
  box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.1);	
  border-radius: 8px;	
  height: 340px;	
  overflow-y: auto;	
  padding: 15px 25px;	
  position: absolute;	
  top: 35px;	
  right: 0px;	
  z-index: 3;	
}
.seeall-section {
  text-align: right;
}
.seeall-section span {
  color: rgb(1, 142, 255);
  font-family: Graphik-Medium;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
  cursor: pointer;
}

.valueRealization-container .btn-container .saved-view-list .rwt__tablist button,
.valueRealization-container .btn-container .saved-view-list-fd .rwt__tablist  button{
  margin-right: 20px;
}
.saved-view-list .savedviews-list li,
.saved-view-list-fd .savedviews-list li {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  cursor: pointer;
  padding: 0.75rem;
}
.saved-view-list .savedviews-list li:hover,
.saved-view-list-fd .savedviews-list li:hover{
  background: rgb(1, 142, 255);
  border-radius: 3px;
  color: #fff;
}
.saved-view-list .savedviews-list li span,
.saved-view-list-fd .savedviews-list li span {
  color: rgb(83, 83, 83);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
}
.saved-view-list .savedviews-list li:hover span,
.saved-view-list-fd .savedviews-list li:hover span {
  color: #fff;
}
.valuerealization-accordions #accordion.valuerealization-accordion {
  border-top: none;
  max-height: none;
}
.valuerealization-accordion .card {
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border: none;
  margin-bottom: 1rem;
}
.valuerealization-accordion .card-header {
  background-color: #fff;
  border-bottom: none;
}
.valuerealization-accordion .card-link {
  display: flex;
  align-items: center;
}
.valuerealization-accordion .card-link-icon {
  margin-right: 8px;
  border: 1px solid #ccc;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  text-align: center;
}
.valuerealization-accordion .card-link-icon span {
  padding: 5px;
  font-size: 14px;
}
.valuerealization-optionbuttons,
.valuerealization-optionbuttons-fd {
  display: flex;
  align-items: center;
}
.valuerealization-optionbuttons span,
.valuerealization-optionbuttons-fd span {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  border: 1px solid rgb(223, 227, 232);
  height: 32px;
  margin-right: 1rem;
  color: rgb(99, 115, 129);
  font-family: Graphik-Medium;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.25px;
  padding: 10px 16px;
  cursor: pointer;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.valuerealization-optionbuttons > div:nth-child(1) > span,
.valuerealization-optionbuttons > div:nth-child(2) > span,
.valuerealization-optionbuttons > div:nth-child(3) > span,
.valuerealization-optionbuttons-fd > div:nth-child(1) > span,	
.valuerealization-optionbuttons-fd > div:nth-child(2) > span {
  color: rgb(187 190 193) !important;
  cursor: default;
}
.charts-container {
  margin-top: 16px;
}
.doughnut-graph-container {
  /* width: 334px;
height: 266px; */
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 8px;
  width: 31%;
  padding: 10px 5px 5px;
}
.horizontal-graph-container {
  /* width: 511px;
height: 258px; */
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 8px;
  width: 47.5%;
  padding: 7px 10px;
}
.valuerealization-optionbuttons span .fa-calendar-alt,
.valuerealization-optionbuttons-fd span .fa-calendar-alt{
  margin-left: 5px;
}
.removeasset-modal .modal-dialog {
  max-width: 620px;
}
.removeasset-modal .notification-body {
  display: flex;
  align-items: center;
}
.removeasset-modal .notification-body .fa-exclamation-triangle {
  font-size: 42px;
  color: #d5002f;
  font-weight: 300;
  margin-right: 40px;
}
.removeasset-modal .notification-body p {
  color: rgb(99, 115, 129);
  font-size: 16px;
  font-family: Graphik;
  font-weight: normal;
  letter-spacing: -0.35px;
  line-height: 28px;
  margin-bottom: 0px;
}
.removeasset-modal .modal-title {
  color: rgb(33, 43, 54);
  font-size: 20px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.35px;
  line-height: 20px;
}
.selectInitiative-modal .modal-dialog {
  max-width: 567px;
}
.selectInitiative-modal .modal-header {
  justify-content: center;
}
.selectInitiative-modal .modal-title {
  text-align: center;
  color: rgb(33, 43, 54);
  font-family: Graphik-Medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.35px;
}
.initiatives-list ul {
  height: 370px;
  overflow-y: auto;
}
.initiatives-list li {
  display: block;
  margin-bottom: 0.5rem;
}
.initiatives-list li h6 {
  color: rgb(99, 115, 129);
  font-family: graphik;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  margin-bottom: 0;
}
.selectInitiative-modal .modal-footer button {
  height: 48px;
  width: 120px;
}
.Confirmation-body .form-group .input-text-value,
.Confirmation-body-fd .form-group .input-text-value{
  border-radius: 4px;
  border: 1px solid rgb(223, 227, 232);
  padding: 10px 16px;
  display: grid;
}
.Confirmation-body-fd .form-group .input-text-value label{	
  display: block;	
  color: rgb(99, 115, 129);	
  font-size: 11px;	
  font-family: Graphik-Medium;	
  font-weight: 500;	
  letter-spacing: -0.25px;	
  line-height: 11px;	
  margin-bottom: 5px;	
  width: inherit;	
  margin-right: 0;	
}
.Confirmation-body .form-group .input-text-value label {
  color: rgb(99, 115, 129);
  font-size: 11px;
  font-family: Graphik-Medium;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 11px;
  margin-bottom: 5px;
  width: inherit;
  margin-right: 0;
}
.input-text-value{	
  display: inline-block;	
  padding: 3px 10px;	
  width: 100%;	
}
.validation-errmsg .input-text-value {
  background: rgb(255, 245, 247);
  border: 1.5px solid rgb(213, 0, 47) !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.validation-errmsg .input-text-value label {
  color: rgb(170, 0, 37) !important;
}
.validation-errmsg .input-text-value input {
  background: rgb(255, 245, 247);
}
.validation-errmsg .guidline-msg {
  color: rgb(170, 0, 37);
  font-family: graphik;
  font-size: 14px;
  line-height: 22px;
}
.calenderPopover.multiselectYearPicker {
  left: 0px !important;
  z-index: inherit;
}
.calenderPopover.multiselectYearPicker .arrow {
  left: 0px !important;
}
.calenderPopover.multiselectYearPicker .popContent {
  width: 12rem;
}
.calenderPopover.multiselectYearPicker .popContent ul {
  border: none;
}
.valuerealization-accordions .card-body {
  padding: 30px 50px 35px 20px;
}
.valuerealization-accordions .card-body .accordion-chart-container {
  width: 100%;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 320px;
}
.vr-summary-main-container {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e3eaed;
  -webkit-box-shadow: 2px 0 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0 15px 0 rgba(0, 0, 0, 0.1);
  max-height: 238px;
  width: 100%;
  padding: 25px 15px 25px 10px;
  display: -ms-flexbox;
  display: flex;
}
.vr-container {
  width: 100%;
  margin: 0 !important;
}
.vr-summary-header-container {
  color: #a100ff;
  font-family: Graphik-Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 24px;
}
.vr-summary-main-container label {
  font-family: Graphik !important;
  font-size: 16px;
}
.vr-summary-value-container {
  color: #212b36;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 400;
  letter-spacing: -0.31px;
  line-height: 22px;
  float: left;
  max-height: 160px !important;
  overflow-y: auto;
}
.vr-summary-value-container ul {
  padding-left: 16px;
  margin-bottom: 0;
}
.vr-summary-value-container ul li {
  list-style-type: disc;
}

/* RECHART CSS */
.db-header-label {	
  color: rgb(45, 58, 75);	
  font-size: 14px;	
  font-family: Graphik !important;	
  font-weight: 700;	
  letter-spacing: -0.28px;	
  line-height: 24px;	
  margin-top: 10px;	
}	

.bo-dashboard-donut-first-ul .recharts-wrapper,
.bo-dashboard-donut-second .recharts-wrapper {
  margin-top: -40px !important;
}

.bo-dashboard-donut-first-ul {
  width: 100%;
  display: inline-block;
}

.bo-dashboard-donut-first-ul .recharts-legend-wrapper {
  left: 4px !important;
}

.db-donut-label {
  width: 60px;
  height: 24px;
  color: rgb(45, 58, 75);
  font-size: 24px !important;
  font-family: Graphik-Medium;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.42px;
  line-height: 24px;
}

.bo-dashboard-container-no-records {
  font-family: "OpenSans-Regular";
  height: 330px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

 .custom-tooltip-main-container {
  z-index: 1000;
  padding: 10px;
  position: relative;
  background: rgb(2, 3, 3);
  border-radius: 4px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12),
    0px 3px 5px -1px rgba(0, 0, 0, 0.2);
  min-height: 52px;
  min-width: 140px;
  color: rgb(255, 255, 255);
  opacity: 0.95;

  color: rgb(255, 255, 255);
  font-weight: normal;
  font-family: Graphik;
  letter-spacing: -0.31px;
  line-height: 18px;
} 
.custom-tooltip-main-container:after {
  content: " ";
  position: absolute;
  bottom: 100%; 
  left: 15%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
} 

/* .cust-bar3 {
  min-height: 100px !important;
} */

.custom-tooltip-lbl-container {
  display: flex;
} 

.custom-tooltip-label-bar3 {
  /* float: left; */
  font-size: 11px;
  height: 18px;
} 

.custom-tooltip-val-container {
  padding-left: 10px;
  display: flex;
}

.custom-tooltip-val-container-bar3 {
  /* float: left; */
  padding-left: 11px;
} 

.custom-tooltip-bar3-subcontainer {
  padding-left: 11px;
} 

 .custom-tooltip-value {
  padding-left: 10px;
  font-size: 11px;
  height: 18px;
}

.custom-tooltip-box {
  float: left;
  border-radius: 2px;
  height: 12px;
  width: 11px;
}
.custom-tooltip-box3{	
  float: left;	
  border-radius: 2px;	
  height: 12px;	
  width: 11px;	
  margin-right: 5px;	
}
.custom-tooltip-label {
  padding-left: 11px;
  float: left;
  font-size: 11px;
  height: 18px;
} 

.db-sub-container {
  max-width: 32.53% !important;
  height: 340px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(227, 234, 237);
  -webkit-box-shadow: 2px 0px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 6px 20px 20px 20px;
}

.cvd,
.cvd-markets {
  margin-right: 1.2%;
}

.customLabel {
  font-size: 0.75em;
}

.db-donuts-main-container ul.recharts-default-legend,
.db-donuts-main-container tspan,
.valuerealization-dashboard tspan {
  font-size: 0.75em;
}
li.recharts-legend-item {	
  margin: 0 5px!important;	
  padding: 0 25px;	
}
li.recharts-legend-item > span {
  text-align: left;
  width: 100%;
  line-height: 15px;
  text-transform: capitalize !important;
}

li.recharts-legend-item > svg {
  border-radius: 3px;
}

ul.recharts-default-legend {
  display: flex;
  justify-content: center;
  min-height: 30px;
}

.db-yoy-sub-container {
  max-width: 49.4% !important;
  height: 340px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(227, 234, 237);
  -webkit-box-shadow: 2px 0px 25px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 6px 20px 20px 20px;
}

.yoy-cvd {
  margin-right: 0.6%;
}

.yoy-vic {
  margin-left: 0.6%;
}

.bo-dashboard-donut-first {
  width: 50%;
  display: inline-block;
}

.bo-dashboard-donut-second {
  width: 100%;
  display: inline-block;
}

.bo-dashboard-donut-third {
  width: 33.3%;
  display: inline-block;
}

.bo-dashboard-bar-first {
  width: 50%;
  display: inline-block;
}

.bo-dashboard-bar-second {
  width: 100%;
  display: inline-block;
}

.bo-dashboard-bar-third {
  width: 100%;
  display: inline-block;
}

.yoy-bar .recharts-legend-wrapper {
  margin-top: -40px !important;
  left: auto !important;
  right: auto !important;
  width: 100% !important;
}

.db-graphs-container {
  margin: 0px !important;
}

#boi.bo-dashboard-bar-second {
  overflow-y: auto;
  overflow-x: hidden;
  height: 320px;
}

.bo-dashboard-bar-first > label {
  width: 100%;
  text-align: center;
  /* font-family: Graphik-Medium; */
}
.novalue-fd{
  color: rgb(82, 101, 127);
  font-family: Graphik;
  font-size: 18px;
  font-weight: normal;
  height: 18px;
  letter-spacing: -0.64px;
}
.valueRealization-container .novalue-fd.tilldate-msg{
  font-size: 16px;
  font-family: "Graphik-Medium";
  color: #212b36;
}

.novalue-component-fd{
  display: flex;
  flex-direction: column;
  height: 250px;
  margin-bottom: 30px;
}
.bo-dashboard-bar-first {
  position: relative;
}
.valuerealization-accordions .bo-dashboard-bar-first .recharts-responsive-container::after{
  content: '';
  display: block;
  width: 0.5px;
  background: rgba(221, 221, 221, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 7px;
  height: 90%;
}
.valuerealization-accordions .bo-dashboard-bar-first:last-child .recharts-responsive-container::after{
  display: none;
}
.view-card-footer .delete-btnicon,
.view-card-footer .defaultmark-icon{
  position: relative;
  width: 16px;
  height: 16px;
  display: inline-block;
}
.view-card-footer .delete-btnicon::before,
.view-card-footer .defaultmark-icon:before{
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.view-card-footer .delete-btnicon::before{
  background: url('../Images/delete-normal.svg') no-repeat;
}
.view-card-footer .delete-btnicon:hover::before{
  background: url('../Images/delete-gradient.svg') no-repeat;
}
.view-card-footer .defaultmark-icon::before{
  background: url('../Images/bookmark-icon.svg') no-repeat;
}
.view-card-footer .defaultmark-icon:hover::before{
  background: url('../Images/bookmark-icon-active.svg') no-repeat;
}
.view-card-footer .defaultmark-icon.active::before{
  background: url('../Images/bookmark-icon-active.svg') no-repeat;
}
.vr-list-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.btn-container-buttons {
  position: relative;
}
.custom-tooltip-main-container .tool-tip-data {
  padding-right: 12px;;
}